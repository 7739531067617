import React from 'react';
import ChartsRud from "./ChartsRUD";
import {headerForReports} from "../../../../utils/Methods";
import {withTranslation} from "react-i18next";

const RawUsageData = props => {

    return (
        <div className='main-container-report'>
        <div className="main-right-header">
            <h2 style={{paddingLeft:8}}>{props.t(headerForReports())}</h2>
        </div>

        <ChartsRud/>
    </div>
      
    );
};

export default withTranslation()(RawUsageData);

