import {actions} from './Constants'
import {
    getSessionCapabilities,
    setTimeouts,
    getOrientation,
    setOrientation,
    getGeoLocation,
    setGeoLocation,
    getLogTypes,
    getLogs,
    getDeviceSettings,
    updateSettings,
    setImplicitTimeout,
    setScriptTimeout,
    openNotifications,
    unlock,
    isLocked,
    getSystemTime,
    getSystemBars,
    getClipboard,
    setClipboard,
    toggleAirplaneMode,
    toggleData,
    toggleWifi,
    toggleLocationServices,
    shake,
    lock,
    rotate,
    pressKeyCode,
    longPressKeyCode,
    hideKeyboard,
    isKeyboardShown,
    executeCommand,
    startActivity,
    currentActivity,
    currentPackage,
    isAppInstalled,
    launchApp,
    backgroundApp,
    closeApp,
    resetApp,
    removeApp,
    activateApp,
    terminateApp,
    getAppStrings,
    endTestCoverage,
    getPerformanceData,
    getPerformanceDataTypes,
    getContext,
    getAllContext,
    setContext,
    goToUrl,
    getUrl,
    webBack,
    forward,
    refresh,
    getWindowHandle,
    closeWindow,
    switchToWindow,
    getWindowHandles,
    createWindow
} from './AppiumClient'


class ActionMethodHandler {

    getMethods(actionID, subActionID) {
        let methods = []
        if(actionID <= actions?.length){
            let subActions = actions[actionID - 1].actions
            if(subActionID <= subActions?.length){
                methods = subActions[subActionID - 1].actions
            }
        }
        return methods
    }

    executeAction = (sessionId, method, params) => {
        switch(method.id){
            case '1.1.1':
                return getSessionCapabilities(sessionId).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.2.1':
                return setTimeouts(sessionId, "pageLoad", params["ms"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.2.2':
                return setScriptTimeout(sessionId, params["ms"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.2.3':
                return setImplicitTimeout(sessionId, params["ms"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.3.1':
                return getOrientation(sessionId).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.3.2':
                return setOrientation(sessionId, params["orientation"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.4.1':
                return getGeoLocation(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.4.2':
                return setGeoLocation(sessionId, params["latitude"], params["longitude"], params["altitude"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.5.1':
                return getLogTypes(sessionId).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '1.5.2':
                return getLogs(sessionId, params["logType"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response 
                })
            case '1.6.1':
                return getDeviceSettings(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response  
                })
            case '1.6.2':
                return updateSettings(sessionId, params["json"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.1.1':
                return openNotifications(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.1.2':
                return getSystemTime(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.1.3':
                return getSystemBars(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.2.1':
                return getClipboard(sessionId, params["contentType"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.2.2':
                return setClipboard(sessionId, params["content"], params["contentType"], params["label"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.3.1':
                return toggleAirplaneMode(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.3.2':
                return toggleData(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.3.3':
                return toggleWifi(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.3.4':
                return toggleLocationServices(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.4.1':
                return shake(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.4.2':
                return lock(sessionId, params["seconds"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.4.3':
                return unlock(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.4.4':
                return isLocked(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.4.5':
                return rotate(sessionId, params["x"], params["y"], params["radius"], params["rotation"], params["touchCount"], params["duration"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.5.1':
                return pressKeyCode(sessionId, params["keycode"], params["metastate"], params["flags"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.5.2':
                return longPressKeyCode(sessionId, params["keycode"], params["metastate"], params["flags"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.5.3':
                return hideKeyboard(sessionId, params["strategy"], params["key"], params["keycode"], params["keyName"]).then(res=> {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.5.4':
                return isKeyboardShown(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.6.1':
                return startActivity(sessionId, params["appPackage"], params["appActivity"], params["appWaitPackage"], params["intentAction"], params["intentCategory"], params["intentFlags"], params["optionalIntentArguments"], params["dontStopAppOnReset"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.6.2':
                return currentActivity(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.6.3':
                return currentPackage(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            // case '2.7.1':
            //     return installApp(sessionId, params["appPath"]).then(res => {
            //         return res
            //     }).catch(err => {
            //         return err.response
            //     })
            case '2.7.2':
                return isAppInstalled(sessionId, params["bundleId"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.3':
                return launchApp(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.4':
                return backgroundApp(sessionId, params["seconds"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.5':
                return closeApp(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.6':
                return resetApp(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.7':
                return removeApp(sessionId, params["appId"], params["bundleId"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.8':
                return activateApp(sessionId, params["type"], params["appId"], params["bundleId"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.9':
                return terminateApp(sessionId,  params["type"], params["appId"], params["bundleId"], params["timeout"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.10':
                return getAppStrings(sessionId, params["language"], params["stringFile"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.7.11':
                return endTestCoverage(sessionId, params["intent"], params["path"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            // case '2.8.1':
            //     return pushFile(sessionId, params["path"], params["data"]).then(res => {
            //         return res
            //     }).catch(err => {
            //         return err.response
            //     })
            // case '2.8.2':
            //     return pullFile(sessionId, params["path"]).then(res => {
            //         return res
            //     }).catch(err => {
            //         return err.response
            //     })
            // case '2.8.3':
            //     return pullFolder(sessionId, params["path"]).then(res => {
            //         return res
            //     }).catch(err => {
            //         return err.response
            //     })
            case '2.9.1':
                return getPerformanceData(sessionId, params["packageName"], params["dataType"], params["dataReadTimeout"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '2.9.2':
                return getPerformanceDataTypes(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '3.1.1':
                return executeCommand(sessionId, params["script"], params["args"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '4.1.1':
                return getContext(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '4.1.2':
                return getAllContext(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '4.1.3':
                return setContext(sessionId, params["name"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '4.2.1':
                return getWindowHandle(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '4.2.2':
                return closeWindow(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '4.2.3':
                return switchToWindow(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '4.2.4':
                return getWindowHandles(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })     
            case '4.2.5':
                return createWindow(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '5.1.1':
                return goToUrl(sessionId, params["url"]).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })     
            case '5.1.2':
                return getUrl(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })      
            case '5.1.3':
                return webBack(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '5.1.4':
                return forward(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            case '5.1.5':
                return refresh(sessionId).then(res => {
                    return res
                }).catch(err => {
                    return err.response
                })
            default:
                // do nothing
        }
    }
}


export default ActionMethodHandler
