import { useMemo } from 'react';
import { Tab } from 'semantic-ui-react';
import { getPrivileges } from '../../../utils/Methods';
import { PrivilegeConstants } from '../../../utils/PrivilegeConstants';
import { authenticationProvidersMesssages } from '../../../utils/UIMessages';
import LdapConfigPage from './LdapConfigPage';
import LocalDBPage from './LocalDBPage';
import OAuth2Page from './OAuth2Page';

const AuthenticationProviders = () => {

    const privileges = useMemo(() => getPrivileges(), []);

    const panes = [
        {
            menuItem: authenticationProvidersMesssages().LOCAL_HEADER,
            render: () => <><LocalDBPage/></>,
        },
        privileges.includes(PrivilegeConstants.LDAP_CONFIGURATION) ?  {
            menuItem: authenticationProvidersMesssages().LDAP_AD_HEADER,
            render: () => <><LdapConfigPage /></>,
        } : {},
        privileges.includes(PrivilegeConstants.OAUTH2_CONFIGURATION) ? {
            menuItem: authenticationProvidersMesssages().OAUTH2_HEADER,
            render: () => <><OAuth2Page /></>,
        } : {}
    ];

    return (
        <>
            <div>
                <div className='main-right-header'>
                    <div>
                        <h2>{authenticationProvidersMesssages().AUTHENTICATION_PROVIDERS_HEADER}</h2>
                        <small>{authenticationProvidersMesssages().AUTHENTICATION_PROVIDERS_SUB_HEADER}</small>
                    </div>
                </div>
                <div className='main-content'>
                    <Tab menu={{ pointing: true }} panes={panes} />
                </div>

            </div>
        </>
    );
}

export default AuthenticationProviders;
