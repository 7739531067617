import React from 'react'
import ChatFeed from './ChatFeed'
import ScrollToBottom from 'react-scroll-to-bottom';


const ChatFeeds = ({messages,currentUserId}) => {
  return (
    <ScrollToBottom className='messages'>
      {messages.map((msg,i)=><div key={i}><ChatFeed msg={msg} currentUserId={currentUserId}></ChatFeed></div>)}
    </ScrollToBottom>
  )
}

export default ChatFeeds;
