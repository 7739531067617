import React, { useEffect,useState } from 'react'
import { Form, Modal, Input, Header, Button, Divider, Dimmer, Loader } from 'semantic-ui-react'
import { updateNode } from '../../../api/apiCalls'
import { showError } from '../../../utils/ToastHelpers'
import { formatBytes } from '../../../utils/Methods';
import axios from 'axios'
import {otherOpsMessages, secondaryServerConfigMessages, LOADING, UPDATE_BUTTON,
    NODE_LOG_LOAD_MESSAGE } from '../../../utils/UIMessages'
import {SAVE_ICON, DOWNLOAD_ICON} from '../../../utils/UiIcons'

const SlaveModal = ({open,onClose,selectedSlave}) => {

    const [slave,setSlave] = useState({});
    const [fileSize,setFileSize] = useState(null);
    const [loadingForDownloadLog,setLoadingForDownloadLog] = useState(false);
    const [loadString,setLoadString]=useState('');

    useEffect(()=>{
        if(open){
            setSlave(selectedSlave);
        }
    },[open,selectedSlave])

    const handleOnUpdateNode = async() =>{
        try{
            await updateNode(slave?.id,slave);
            onClose(true);
        }catch(error){
            showError(error.response?.data?.message || secondaryServerConfigMessages().SLAVE_UPDATE_ERR)
        }
    }

   const onHandleChangeSlave = (event,{name,value}) =>{
        setSlave((prev)=>({...prev,[name]:value}));
    }


   const onLogClick = () => {
        let loadString = NODE_LOG_LOAD_MESSAGE(slave);
        let fileName = `${slave?.name}_system_data.zip`;

        setLoadString(loadString);
        setLoadingForDownloadLog(true)
        axios.get(`/api/v1/system/diagnosis/${slave.id}`, {
            responseType: 'arraybuffer',
            onDownloadProgress: progressEvent => {
                const {loaded} = progressEvent;
                setFileSize(formatBytes(loaded))
            }
        }).then(res => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([res.data], {type: 'application/zip'}));
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(err => {
            showError(`${otherOpsMessages().NOT_FETCH_LOGS}: ${err?.response?.message ?? err.toString()}`)
        }).finally(() => {
            setLoadingForDownloadLog(false);
            setLoadString('');
            setFileSize(null);
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            closeIcon
            closeOnDimmerClick={true}
            size={'small'}
            >
            <Modal.Content>
                <Dimmer active={loadingForDownloadLog} page>
                        <Loader active={loadingForDownloadLog}
                                indeterminate>{LOADING()} {loadString} <br/> <br/> File
                            Size: {fileSize || 0}</Loader>
                </Dimmer>
                <Header as='h3'>
                    {secondaryServerConfigMessages().SECONDARY_SERVER_FIELDS}
                </Header>
                <Divider/>
                <Form>
                    <Form.Field>
                        <label>{secondaryServerConfigMessages().SECONDARY_SERVER_IP}</label>
                        <Input
                            fluid
                            name={'ip'}
                            value={slave?.ip}
                            disabled
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{secondaryServerConfigMessages().SECONDARY_SERVER_PORT}</label>
                        <Input
                            fluid
                            name={'port'}
                            value={slave?.port}
                            disabled
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>{secondaryServerConfigMessages().SECONDARY_SERVER_NAME}</label>
                        <Input
                            fluid
                            name={'name'}
                            value={slave?.name}
                            onChange={onHandleChangeSlave}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>{secondaryServerConfigMessages().SECONDARY_SERVER_LOCATION}</label>
                        <Input
                            fluid
                            name={'location'}
                            value={slave?.location}
                            onChange={onHandleChangeSlave}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    compact 
                    icon={SAVE_ICON}
                    color='green'
                    content={UPDATE_BUTTON()}
                    onClick={()=>handleOnUpdateNode()}
                    />

                <Button 
                    compact 
                    icon={DOWNLOAD_ICON}
                    primary
                    content={secondaryServerConfigMessages().DOWNLOAD_DIAGNOSIS}
                    onClick={()=>onLogClick()}
                    />
            </Modal.Actions>
        </Modal>
    )
}

export default SlaveModal