import { CLOCK_ICON, FILE_ALTERNATE_ICON, TABLET_ALTERNATE_ICON, WORLD_ICON } from "./UiIcons";
import { actionsMessages, constantsMessages, DEVICE_HEADER, GROUP_LABEL, SESSION_HEADER, USER_HEADER } from "./UIMessages";

// General
export const DEVICEOS = {
    ANDROID: 'android',
    IOS: 'ios'
}

export const WEBSOCKET_STATES = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
}

// Device Manage
export const MANAGE_MODES = {
    DEVICE_DETAILS: 0,
    DEVICE_LOGS: 1,
    APPIUM_LOGS: 2,
    INSPECTOR: 3,
    LOCATION: 4
}

export const IDLE_TIMER = {
    STEP: 1,
    WARNING: 60,
    TERMINATE: 0
}

// Appium
export const APPIUM_REQUEST_PREFIX = '/wd/hub/session';
export const APPIUM_SESSION_ID = 'appium_session_id';


// Inspector
export const INSPECTOR_MODES = {
    MANUAL: 'manual',
    TAPELEMENTS: 'tapElements',
    SWIPE: 'swipe'
}

export const IMAGE_MODES = {
    STREAM: 'stream',
    FROZEN: 'frozen'
}

export const SELECTION_STRATEGIES = {
    NAME: 'name',
    ID: 'id',
    ACCESSIBILITYID: 'accessibility id',
    XPATH: 'xpath'
}

//Regex Patterns
export const validDeviceNames = /^[a-zA-Z0-9İÖÜŞÇĞıöüşçğ. _#-]{0,20}$/g
export const validUsername = /^[a-zA-Z0-9İÖÜŞÇĞıöüşçğ._-]{3,15}$/g
export const validFullname = /^([a-zA-ZİÖÜŞÇĞıöüşçğ.]{2,15}(\s)*)*[a-zA-ZİÖÜŞÇĞıöüşçğ.]{2,15}$/g
export const validPort = /^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/
export const validHostname = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/g
export const validIpAddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g
export const validURL = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
export const validEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
export const validSessionName = /^[^\s][a-zA-Z0-9İÖÜŞÇĞıöüşçğ.\s_-]{3,50}$/g
export const validAgentName = /^[^\s][a-zA-Z0-9İÖÜŞÇĞıöüşçğ.\s_-]{0,30}$/g
export const validLimit = /^$|^-$|^-1$|^\d$|^[1-9]\d$|^[1-9]\d{2}$|^[1-9]\d{3}$/g

// Actions
export const actions = [
    {
        id: '1',
        name: SESSION_HEADER(),
        actions: [
            {
                id: '1.1',
                name: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_SESSION_CAPABILITIES,
                actions: [
                    {
                        id: '1.1.1',
                        identifier: 'sessionCapabilities',
                        name: constantsMessages().GET_SESSION_CAPABILITIES_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    }
                ]
            },
            {
                id: '1.2',
                name: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_TIMEOUTS,
                actions: [
                    {
                        id: '1.2.1',
                        name: constantsMessages().SET_PAGELOAD_TIMEOUT_LABEL,
                        identifier: 'setPageLoadTimeout',
                        inputRequired: true,
                        outputRequired: false,
                        inputCount: 1,
                        inputNames: ['ms'],
                        icon: CLOCK_ICON
                    },
                    {
                        id: '1.2.2',
                        name: constantsMessages().SET_SCRIPT_TIMEOUT_LABEL,
                        identifier: 'setAsyncScriptTimeout',
                        inputRequired: true,
                        outputRequired: false,
                        inputCount: 1,
                        inputNames: ['ms'],
                        icon: CLOCK_ICON
                    },
                    {
                        id: '1.2.3',
                        name: constantsMessages().SET_IMPLICIT_TIMEOUT_LABEL,
                        identifier: 'setImplicitWaitTimeout',
                        inputRequired: true,
                        outputRequired: false,
                        inputCount: 1,
                        inputNames: ['ms'],
                        icon: CLOCK_ICON
                    }
                ]
            },
            {
                id: '1.3',
                name: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_ORIENTATION,
                actions: [
                    {
                        id: '1.3.1',
                        name: constantsMessages().GET_ORIENTATION_LABEL,
                        identifier: 'getOrientation',
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '1.3.2',
                        name: constantsMessages().SET_ORIENTATION_LABEL,
                        identifier: 'setOrientation',
                        inputRequired: true,
                        outputRequired: false,
                        inputCount: 1,
                        inputNames: ['orientation'],
                        icon: TABLET_ALTERNATE_ICON
                    }
                ]
            },
            {
                id: '1.4',
                name: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_GEOLOCATION,
                actions: [
                    {
                        id: '1.4.1',
                        name: constantsMessages().GET_GEOLOCATION_LABEL,
                        identifier: 'getGeoLocation',
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '1.4.2',
                        name: constantsMessages().SET_GEOLOCATION_LABEL,
                        identifier: 'setGeoLocation',
                        inputRequired: true,
                        outputRequired: false,
                        inputCount: 3,
                        inputNames: ['latitude', 'longitude', 'altitude'],
                        icon: WORLD_ICON
                    }
                ]
            },
            {
                id: '1.5',
                name: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_LOGS,
                actions: [
                    {
                        id: '1.5.1',
                        name: constantsMessages().GET_LOG_TYPES_LABEL,
                        identifier: 'logTypes',
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '1.5.2',
                        name: constantsMessages().GET_LOGS_LABEL,
                        identifier: 'log',
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['logType'],
                        icon: FILE_ALTERNATE_ICON
                    }
                ]
            },
            {
                id: '1.6',
                name: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_SETTINGS,
                actions: [
                    {
                        id: '1.6.1',
                        name: constantsMessages().GET_DEVICE_SETTINGS_LABEL,
                        identifier: 'settings',
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '1.6.2',
                        name: constantsMessages().UPDATE_SETTINGS_LABEL,
                        identifier: 'updateSettings',
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['json']
                    }
                ]
            }
        ]
    },
    {
        id: '2',
        name: DEVICE_HEADER(),
        actions: [
            {
                id: '2.1',
                name: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_SYSTEM,
                actions: [
                    {
                        id: '2.1.1',
                        identifier: 'openNotifications',
                        name: constantsMessages().OPEN_NOTIFICATIONS_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.1.2',
                        identifier: 'getSystemTime',
                        name: constantsMessages().GET_SYSTEM_TIME_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.1.3',
                        identifier: 'getSystemBars',
                        name: constantsMessages().GET_SYSTEM_BARS_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    }
                ]
            },
            {
                id: '2.2',
                name: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_CLIPBOARD,
                actions: [
                    {
                        id: '2.2.1',
                        identifier: 'getClipboard',
                        name: constantsMessages().GET_CLIPBOARD_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['contentType']
                    },
                    {
                        id: '2.2.2',
                        identifier: 'setClipboard',
                        name: constantsMessages().SET_CLIPBOARD_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 3,
                        inputNames: ['content', 'contentType', 'label']
                    }
                ]
            },
            {
                id: '2.3',
                name: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_NETWORK,
                actions: [
                    {
                        id: '2.3.1',
                        identifier: 'toggleAirplaneMode',
                        name: constantsMessages().TOGGLE_AIRPLANE_MODE_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.3.2',
                        identifier: 'toggleData',
                        name: constantsMessages().TOGGLE_DATA_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.3.3',
                        identifier: 'toggleWifi',
                        name: constantsMessages().TOGGLE_WIFI_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.3.4',
                        identifier: 'toggleLocationServices',
                        name: constantsMessages().TOGGLE_LOCATION_SERVICES_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    }
                ]
            },
            {
                id: '2.4',
                name: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_INTERACTION,
                actions: [
                    {
                        id: '2.4.1',
                        identifier: 'shake',
                        name: constantsMessages().SHAKE_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.4.2',
                        identifier: 'lock',
                        name: constantsMessages().LOCK_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['seconds']
                    },
                    {
                        id: '2.4.3',
                        identifier: 'unlock',
                        name: constantsMessages().UNLOCK_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.4.4',
                        identifier: 'isLocked',
                        name: constantsMessages().IS_LOCKED_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.4.5',
                        identifier: 'rotate',
                        name: constantsMessages().ROTATE_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 6,
                        inputNames: ['x', 'y', 'radius', 'rotation', 'touchCount', 'duration']
                    }
                ]
            },
            {
                id: '2.5',
                name: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_KEYS,
                actions: [
                    {
                        id: '2.5.1',
                        identifier: 'pressKeyCode',
                        name: constantsMessages().PRESS_KEY_CODE_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 3,
                        inputNames: ['keycode', 'metastate', 'flags']
                    },
                    {
                        id: '2.5.2',
                        identifier: 'longPressKeyCode',
                        name: constantsMessages().LONG_PRESS_KEY_CODE_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 3,
                        inputNames: ['keycode', 'metastate', 'flags']
                    },
                    {
                        id: '2.5.3',
                        identifier: 'hideKeyboard',
                        name: constantsMessages().HIDE_KEYBOARD_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 4,
                        inputNames: ['strategy', 'key', 'keycode', 'keyName']
                    },
                    {
                        id: '2.5.4',
                        identifier: 'isKeyboardShown',
                        name: constantsMessages().IS_KEYBOARD_SHOWN_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    }
                ]
            },
            {
                id: '2.6',
                name: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_ACTIVITY,
                actions: [
                    {
                        id: '2.6.1',
                        identifier: 'startActivity',
                        name: constantsMessages().START_ACTIVITY_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 8,
                        inputNames: [
                            'appPackage',
                            'appActivity',
                            'appWaitPackage',
                            'intentAction',
                            'intentCategory',
                            'intentFlags',
                            'optionalIntentArguments',
                            'dontStopAppOnReset'
                        ]
                    },
                    {
                        id: '2.6.2',
                        identifier: 'currentActivity',
                        name: constantsMessages().CURRENT_ACTIVITY_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.6.3',
                        identifier: 'currentPackage',
                        name: constantsMessages().CURRENT_PACKAGE_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    }
                ]
            },
            {
                id: '2.7',
                name: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_APP,
                actions: [
                    // {
                    //     id: '2.7.1',
                    //     identifier: 'installApp',
                    //     name: 'Install App',
                    //     inputRequired: true,
                    //     outputRequired: true,
                    //     inputCount: 1,
                    //     inputNames: ['appPath']
                    // },
                    {
                        id: '2.7.2',
                        identifier: 'isAppInstalled',
                        name: constantsMessages().IS_APP_INSTALLED_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['bundleId']
                    },
                    {
                        id: '2.7.3',
                        identifier: 'launchApp',
                        name: constantsMessages().LAUNCH_APP_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.7.4',
                        identifier: 'backgroundApp',
                        name: constantsMessages().BACKGROUND_APP_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['seconds']
                    },
                    {
                        id: '2.7.5',
                        identifier: 'closeApp',
                        name: constantsMessages().CLOSE_APP_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.7.6',
                        identifier: 'resetApp',
                        name: constantsMessages().RESET_APP_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '2.7.7',
                        identifier: 'removeApp',
                        name: constantsMessages().REMOVE_APP_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 2,
                        inputNames: ['appId', 'bundleId']
                    },
                    {
                        id: '2.7.8',
                        identifier: 'activateApp',
                        name: constantsMessages().ACTIVATE_APP_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 3,
                        inputNames: ['type',  'appId', 'bundleId']
                    },
                    {
                        id: '2.7.9',
                        identifier: 'terminateApp',
                        name: constantsMessages().TERMINATE_APP_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 4,
                        inputNames: ['type', 'appId', 'bundleId', 'timeout']
                    },
                    {
                        id: '2.7.10',
                        identifier: 'getAppStrings',
                        name: constantsMessages().GET_APP_STRINGS_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 2,
                        inputNames: ['language', 'stringFile']
                    },
                    {
                        id: '2.7.11',
                        identifier: 'endTestCoverage',
                        name: constantsMessages().END_TEST_COVERAGE_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 2,
                        inputNames: ['intent', 'path']
                    }
                ]
            },
            // {
            //     id: '2.8',
            //     name: 'File',
            //     actions : [
            //         {
            //             id: '2.8.1',
            //             identifier: 'pushFile',
            //             name: 'Push File',
            //             inputRequired: true,
            //             outputRequired: true,
            //             inputCount: 2,
            //             inputNames: ['path', 'data']
            //         },
            //         {
            //             id: '2.8.2',
            //             identifier: 'pullFile',
            //             name: 'Pull File',
            //             inputRequired: true,
            //             outputRequired: true,
            //             inputCount: 1,
            //             inputNames: ['path']
            //         },
            //         {
            //             id: '2.8.3',
            //             identifier: 'pullFolder',
            //             name: 'Pull Folder',
            //             inputRequired: true,
            //             outputRequired: true,
            //             inputCount: 1,
            //             inputNames: ['path']
            //         },
            //     ]
            // },
            {
                id: '2.9',
                name: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_PERFORMANCE_DATA,
                actions: [
                    {
                        id: '2.9.1',
                        identifier: 'getPerformanceData',
                        name: constantsMessages().GET_PERFORMANCE_DATA_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 3,
                        inputNames: ['packageName', 'dataType', 'dataReadTimeout']
                    },
                    {
                        id: '2.9.2',
                        identifier: 'getPerformanceDataTypes',
                        name: constantsMessages().GET_PERFORMANCE_DATA_TYPES_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    }
                ]
            }
        ]
    },
    {
        id: '3',
        name: actionsMessages().ACTION_GROUP_OPTIONS_EXEC_MOBILE_COMMAND,
        actions: [
            {
                id: '3.1',
                name: actionsMessages().EXEC_MOBILE_COMMAND_ACTION_GROUP,
                actions: [
                    {
                        id: '3.1.1',
                        identifier: 'execute',
                        name: constantsMessages().EXECUTE_COMMAND_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 2,
                        inputNames: ['script', 'args']
                    }
                ]
            }
        ]
    },
    {
        id: '4',
        name: actionsMessages().ACTION_GROUP_OPTIONS_CONTEXT,
        actions: [
            {
                id: '4.1',
                name: actionsMessages().ACTION_GROUP_OPTIONS_CONTEXT,
                actions: [
                    {
                        id: '4.1.1',
                        identifier: 'getContext',
                        name: constantsMessages().GET_CONTEXT_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '4.1.2',
                        identifier: 'getAllContext',
                        name: constantsMessages().GET_ALL_CONTEXT_LABEL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    }, {
                        id: '4.1.3',
                        identifier: 'setContext',
                        name: constantsMessages().SET_CONTEXT_LABEL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['name']
                    }
                ]
            },
            {
                id: '4.2',
                name: constantsMessages().ACTION_GROUP_OPTIONS_W3,
                actions: [
                    {
                        id: '4.2.1',
                        identifier: 'getWindowHandle',
                        name: constantsMessages().GET_WINDOW_HANDLE,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '4.2.2',
                        identifier: 'closeWindow',
                        name: constantsMessages().CLOSE_WINDOW,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '4.2.3',
                        identifier: 'switchToWindow',
                        name: constantsMessages().SWITCH_TO_WINDOW,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['handle']
                    },
                    {
                        id: '4.2.4',
                        identifier: 'getWindowHandles',
                        name: constantsMessages().GET_WINDOW_HANDLES,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['name']
                    },
                    {
                        id: '4.2.4',
                        identifier: 'createWindow',
                        name: constantsMessages().CREATE_WINDOW,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['type']
                    }
                ]
            }
        ]
    },
    {
        id: '5',
        name: constantsMessages().WEB,
        actions: [
            {
                id: '5.1',
                name: constantsMessages().NAVIGATE,
                actions: [
                    {
                        id: '5.1.1',
                        identifier: 'goToUrl',
                        name: constantsMessages().GO_TO_URL,
                        inputRequired: true,
                        outputRequired: true,
                        inputCount: 1,
                        inputNames: ['url']
                    },
                    {
                        id: '5.1.2',
                        identifier: 'getUrl',
                        name: constantsMessages().GET_URL,
                        inputRequired: false,
                        outputRequired: true,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '5.1.3',
                        identifier: 'webBack',
                        name: constantsMessages().BACK,
                        inputRequired: false,
                        outputRequired: false,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '5.1.4',
                        identifier: 'forward',
                        name: constantsMessages().FORWARD,
                        inputRequired: false,
                        outputRequired: false,
                        inputCount: 0,
                        inputNames: []
                    },
                    {
                        id: '5.1.5',
                        identifier: 'refresh',
                        name: constantsMessages().REFRESH,
                        inputRequired: false,
                        outputRequired: false,
                        inputCount: 0,
                        inputNames: []
                    }
                ]
            }
        ]
    }
]

export const HTTP_STATUS_CODES = [
    {
        key: '100',
        text: constantsMessages().HTTP_STATUS_CODE_100,
        value: '100'
    },
    {
        key: '101',
        text: constantsMessages().HTTP_STATUS_CODE_101,
        value: '101'
    },
    {
        key: '102',
        text: constantsMessages().HTTP_STATUS_CODE_102,
        value: '102'
    },
    {
        key: '103',
        text: constantsMessages().HTTP_STATUS_CODE_103,
        value: '103'
    },
    {
        key: '200',
        text: constantsMessages().HTTP_STATUS_CODE_200,
        value: '200'
    },
    {
        key: '201',
        text: constantsMessages().HTTP_STATUS_CODE_201,
        value: '201'
    },
    {
        key: '202',
        text: constantsMessages().HTTP_STATUS_CODE_202,
        value: '202'
    },
    {
        key: '203',
        text: constantsMessages().HTTP_STATUS_CODE_203,
        value: '203'
    },
    {
        key: '204',
        text: constantsMessages().HTTP_STATUS_CODE_204,
        value: '204'
    },
    {
        key: '205',
        text: constantsMessages().HTTP_STATUS_CODE_205,
        value: '205'
    },
    {
        key: '301',
        text: constantsMessages().HTTP_STATUS_CODE_301,
        value: '301'
    },
    {
        key: '302',
        text: constantsMessages().HTTP_STATUS_CODE_302,
        value: '302'
    },
    {
        key: '303',
        text: constantsMessages().HTTP_STATUS_CODE_303,
        value: '303'
    },
    {
        key: '304',
        text: constantsMessages().HTTP_STATUS_CODE_304,
        value: '304'
    },
    {
        key: '307',
        text: constantsMessages().HTTP_STATUS_CODE_307,
        value: '307'
    },
    {
        key: '308',
        text: constantsMessages().HTTP_STATUS_CODE_308,
        value: '308'
    },
    {
        key: '400',
        text: constantsMessages().HTTP_STATUS_CODE_400,
        value: '400'
    },
    {
        key: '401',
        text: constantsMessages().HTTP_STATUS_CODE_401,
        value: '401'
    },
    {
        key: '403',
        text: constantsMessages().HTTP_STATUS_CODE_403,
        value: '403'
    },
    {
        key: '404',
        text: constantsMessages().HTTP_STATUS_CODE_404,
        value: '404'
    },
    {
        key: '405',
        text: constantsMessages().HTTP_STATUS_CODE_405,
        value: '405'
    },
    {
        key: '410',
        text: constantsMessages().HTTP_STATUS_CODE_410,
        value: '410'
    },
    {
        key: '418',
        text: constantsMessages().HTTP_STATUS_CODE_418,
        value: '418'
    },
    {
        key: '429',
        text: constantsMessages().HTTP_STATUS_CODE_429,
        value: '429'
    },
    {
        key: '440',
        text: constantsMessages().HTTP_STATUS_CODE_440,
        value: '440'
    },
    {
        key: '500',
        text: constantsMessages().HTTP_STATUS_CODE_500,
        value: '500'
    },
    {
        key: '501',
        text: constantsMessages().HTTP_STATUS_CODE_501,
        value: '501'
    },
    {
        key: '502',
        text: constantsMessages().HTTP_STATUS_CODE_502,
        value: '502'
    },
    {
        key: '503',
        text: constantsMessages().HTTP_STATUS_CODE_503,
        value: '503'
    },
    {
        key: '504',
        text: constantsMessages().HTTP_STATUS_CODE_504,
        value: '504'
    },
    {
        key: '305',
        text: constantsMessages().HTTP_STATUS_CODE_305,
        value: '305'
    },
    {
        key: '306',
        text: constantsMessages().HTTP_STATUS_CODE_306,
        value: '306'
    }
]

export const httpMethods = [
    {
        key: 'GET',
        text: 'GET',
        value: 'GET'
    },
    {
        key: 'HEAD',
        text: 'HEAD',
        value: 'HEAD'
    },
    {
        key: 'POST',
        text: 'POST',
        value: 'POST'
    },
    {
        key: 'PUT',
        text: 'PUT',
        value: 'PUT'
    },
    {
        key: 'DELETE',
        text: 'DELETE',
        value: 'DELETE'
    },
    {
        key: 'CONNECT',
        text: 'CONNECT',
        value: 'CONNECT'
    },
    {
        key: 'OPTIONS',
        text: 'OPTIONS',
        value: 'OPTIONS'
    },
    {
        key: 'TRACE',
        text: 'TRACE',
        value: 'TRACE'
    },
    {
        key: 'PATCH',
        text: 'PATCH',
        value: 'PATCH'
    }
]

//Show info message's time out value.
export const SHOW_INFO_TIMEOUT = 1000;

export const ERROR_BASE64_SAMPLE = 'iVBORw0KGgoAAAANSUhEUgAAAN0AAAGxBAMAAAAUq668AAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY' +
    '1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiB' +
    'IMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z' +
    '+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q' +
    '5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1w' +
    'TzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlP' +
    'YZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo' +
    '6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHl' +
    'iJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df' +
    '+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR' +
    '2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjS' +
    'T8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5' +
    'QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1' +
    'N8AuKD/A/8leAvFY9bLAAAAbGVYSWZNTQAqAAAACAAEARoABQAAAAEAAAA+ARsABQAAAAEAAABGASgAAwAAAAEAAgAAh2kABAAAAAEAAABOAAAAA' +
    'AABtwYAAAYfAAad8QAAF4wAAqACAAQAAAABAAAA3aADAAQAAAABAAABsQAAAAAOZRaAAAAACXBIWXMAAAsIAAALEAFCpj2DAAAAD1BMVEXzBQrzB' +
    'gf0Pkb95uf///+oJrbHAAAA+ElEQVR42u3cQQ2AMAxA0eFggAMsIAH8a+LCtSSQdRDyvoF3b5uWsWu18Hg8Ho/H4/F4PB6Px+PxeDwej8fj8Xg8H' +
    'o/H4/F4PN4Dr5aoFK/Oa9SQ4+1BG4/H4/F4PB6Px+PxeDwej8fj8Xg8Ho/H4/F4PB6Px+PxeDwej8fj8Xg8Ho/H4/F4PB6Px+PxeDwej5fg9f2v0' +
    'ft/SIt4PB6Px+PxeDwej8fj8Xg8Hu+T3t/nrdMSZR/A4/F4PB6Px+PxeDwej8fj8Xi817yL+fWY4jXonufem8fj8Xg8Ho/H4/F4PB6Px+PxeDwej' +
    '8fj8Xg8Ho/H4/F4p3cArTFlVgFbEwwAAAAASUVORK5CYII=';

export const SELECT_APPLICATION = '<<Select An Application>>';
export const AUTOMATION_KEY = '<<Your Automation Key>>';

export const ONE_YEAR_IN_MILLIS = 31557600000;
export const ONE_YEAR_IN_SECONDS = 31557600;
export const ONE_DAY_IN_MILLIS = 86400000;
export const ONE_DAY_IN_SECONDS = 86400;
export const ONE_HOUR_IN_MILLIS = 3600000;
export const ONE_HOUR_IN_SECONDS = 3600;
export const ONE_MINUTE_IN_MILLIS = 60000;
export const ONE_MINUTE_IN_SECONDS = 60;
export const ONE_SECOND_IN_MILLIS = 1000;
export const ONE_SECOND_IN_SECONDS = 1;

export const PAD_LENGTH = 2
export const TIMEOUT_TOAST_SHOW_RATE = 0.6
export const WEBHOOK_AUTH_NONE = 0;
export const WEBHOOK_AUTH_USERNAME_PASSWORD = 1;
export const WEBHOOK_AUTH_TOKEN = 2;
export const DEVICE_LIST_FILTER_KEY_LIST = [
    'brand', 'categories', 'deviceStatus', 'favorite', 'os', 'oSVersion',
    'screenResolution', 'screenSize', 'Automation', 'Development', 'Manual', 'Reserved',
    'packageName', 'version', 'nodeList', 'groupList'
];

export const ANNOUNCEMENT_CONSTANTS = {
    ANNOUNCEMENT_HISTORY_DESCRIPTION_LENGTH: 100,
    TOP_LIMIT_ANNOUNCEMENT_NAME : 70,
    TOP_LIMIT_ANN_DESC : 500,
    TOP_LIMIT_ANN_DESCRIPTION_LINE_BREAK : 10
}

export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const MONTLY_DATETIME_FORMAT = 'YYYY-MM';
export const DAILYLY_DATETIME_FORMAT = 'YYYY-MM-DD';
export const MONTLY_DATE_FORMAT= 'MM/yyyy';
export const DAILY_DATE_FORMAT= 'dd/MM/yyyy';
export const YEARLY_DATE_FORMAT = 'yyyy/MM/dd'
export const HOUR_FORMAT = 'HH:mm'
export const CAPITAL_DAILY_FORMAT = 'DD/MM/YYYY'


export const HTTP_STATUS = {
    OK_PREFIX: '2',
    CLIENT_ERROR_PREFIX: '4',
    SERVER_ERROR_PREFIX: '5',
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    SESSION_TIMED_OUT: 440,
    CODES: HTTP_STATUS_CODES
}

export const PAGE_SIZE_1 = 1;
export const PAGE_SIZE_5 = 5;
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_20 = 20;
export const PAGE_SIZE_25 = 25;
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_100 = 100;

export const DEBOUNCE_WAIT = {
    SHORT: 200,
    MEDIUM: 500,
    LONG: 750,
    VERY_LONG: 1750
}

export const ALARM_TYPE = [
    {
        key: 'DEVICE_STATUS',
        text: 'Device status',
        value: 'DEVICE_STATUS'
    },
    {
        key: 'CONCURRENT_ACCESS_LIMIT_FOR_LICENSE',
        value: 'CONCURRENT_ACCESS_LIMIT_FOR_LICENSE',
        text: 'Concurrent access limit for license'
    },
    {
        key: 'CONCURRENT_ACCESS_LIMIT_FOR_USER_GROUP',
        value: 'CONCURRENT_ACCESS_LIMIT_FOR_USER_GROUP',
        text: 'Concurrent access limit for user group'
    }
]

export const calendarDateFormat = 'DD/MM/YYYY HH:mm:ss';
export const DEFAULT_DATETIME_SHORT = 'DD/MM/YYYY HH:mm';

export const API_USAGE = {
    MAX_ALLOWED_QUERY_INTERVAL: 30,
    DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss'
}

//Popup Positions
export const POPUP_POSITIONS = {
    BOTTOM_CENTER: 'bottom center',
    BOTTOM_RIGHT: 'bottom right',
    BOTTOM_LEFT: 'bottom left',
    TOP_LEFT: 'top left',
    TOP_RIGHT: 'top right',
    CENTER_RIGHT: 'center right',
    CENTER_LEFT: 'center left',
    RIGHT_CENTER: 'right center'
}

export const reservedByOptions = [
    {
      key: 'user',
      text: USER_HEADER(),
      value: 'user'
    },
    {
      key: 'group',
      text: GROUP_LABEL(),
      value: 'group'
    }]

export const LANGUAGE_COUNTRY_PATTERN = /^([a-z]{2}_[A-Z]{2})$/;

export const TWO_FA_MESSAGE_DISPLAY_DURATION = 15000;
export const CAPTCHA_OR_2FA_RELOAD_DURATION = 10000;

export const DEVICE_IMAGE = {
    SIZE_MULTIPLIER: .3,
    QUALITY: .4,
    MAXIMUM_HEIGHT: 480,
    MAXIMUM_WIDTH: 320
};

export const WINDOW_RELOAD_TIMEOUT = {
    ZERO: 0,
    MIN: 250,
    MEDIUM: 500,
    DEFAULT: 1000,
    EXTRA: 2500
}

export const DEFAULT_PAGINATION_PROPS = {
    PAGE_INDEX: 0,
    PAGE_SIZE: 10,
    DEFAULT_PAGE_SIZE_OPTIONS: [
        PAGE_SIZE_1,
        PAGE_SIZE_5,
        PAGE_SIZE_10,
        PAGE_SIZE_20,
        PAGE_SIZE_25,
        PAGE_SIZE_50,
        PAGE_SIZE_100
    ]
};

export const INSPECTOR_SIZE_VW = 0.268;
export const INSPECTOR_SIZE_PX = 177;

export const DEVICE_STATUS_MULTIPLIER = 100;

export const TOUCH_SCREEN = {
    NUMBER_OF_FINGERS: [
        {
            key: 2,
            text: 2,
            value: 2
        },
        {
            key: 3,
            text: 3,
            value: 3
        },
        {
            key: 4,
            text: 4,
            value: 4
        }
    ],
    NUMBER_OF_CLICKS: [
        {
            key: 1,
            text: 1,
            value: 1
        },
        {
            key: 2,
            text: 2,
            value: 2
        },
        {
            key: 3,
            text: 3,
            value: 3
        },
        {
            key: 4,
            text: 4,
            value: 4
        }
    ]
}

export const ACCEPTABLE_APP_EXTENSION = ['ipa', 'apk', 'aab', 'apks'];
export const ANDROID_APP_EXTENSION = ['apk','aab','apks'];
export const ACCEPTABLE_FILE_EXTENSION = ['txt', 'doc', 'docx', 'pdf', 'log', 'zip', 'xlsx', 'xml'];
export const ACCEPTABLE_IMAGE_EXTENSION = ['png', 'jpg', 'jpeg'];
export const ACCEPTABLE_CERT_EXTENSION = ['cer', 'crt', 'pfx', 'p12'];
export const FILTER_FIELDS = ['brand', 'categories', 'nodeList', 'os', 'favorite','reservedByMe'];
export const USAGE_STATUS_FILTER_FIELDS = ['Automation', 'Development', 'Manual', 'Reserved'];

export const TIMER = ms => new Promise(res => setTimeout(res, ms));
export const INTERVAL_BETWEEN_MULTITOUCHES = 100;

export const DEFAULT_DEBOUNCE_TIMER = 500;

export const characterCheckList = ['/', '*', '-', '+', '.', '?', ' ', '(', ')', '[', ']', '%', '#', '£', '<', '>', '!', '&', '|', '{', '}', '=', '"', ','];

//The object for all chat application constants.
export const CHAT_APP_CONSTANTS = {
    MAX_MSG_INPUT_LENGTH : 512
}

export const DEBOUNCE_INPUT_MS = 1000;

export const UPLOAD_FILE_MS = 750;

export const REDIRECT_DELAY = 3500;
export const REDIRECT_TO_HOMEPAGE_DELAY = 3500;
//View Options

export const VIEWING_OPTIONS = [{
    key: 'native',
    value: 'native',
    text: 'native'
},

{
    key: 'web_hybrid',
    value: 'web_hybrid',
    text: 'web_hybrid'
}];

export const IMPORTANT_ATTRS = ['name', 'content-desc', 'resource-id', 'AXDescription', 'AXIdentifier'];

export const UNIQUE_XPATH_ATTRIBUTES = ['name', 'content-desc', 'id', 'accessibility-id'];

export const UNIQUE_CLASS_CHAIN_ATTRIBUTES = ['label', 'name', 'value'];

export const UNIQUE_PREDICATE_ATTRIBUTES = ['label', 'name', 'value', 'type'];

export const FAVORITE_DEVICE_SHOW_INFO_TIME = 1500;

export const FAVORITE_DEVICE_SET_TIMEOUT_MS = 100;

export const dateTimeTestRunByToday = () => {
    const now = new Date();
    const dateTime = now.toLocaleString('en-US', {
        timeZone: 'UTC',
        hour12: false,
        formatMatcher: 'basic',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    }).replace(/\//g, '-').replace(', ', 'T');
    const formattedDateTime = dateTime.slice(6, 10) + '-' + dateTime.slice(0, 2) + '-' + dateTime.slice(3, 5) + 'T' + dateTime.slice(11, 16) + ':00';
    return formattedDateTime;
}

export const ASYNC_TASK_STATUS = {
    INITIALIZED:'Initialized',
    PROCESSING:'Processing',
    COMPLETED:'Completed',
    ERROR:'Error'
}

export const FILE_HASH_CHUNK_SIZE = 1024 * 1024 * 10; // 10MB
export const HOOK_NAMES = {
    APP_UPLOAD: "appUpload"
}