import { START_LOADING_SYSTEM_PARAMETERS } from '../actions/types'



const initialState ={
    loading:true,
    systemParameters:[]
}

const systemParametersReducer = (state = initialState,action) =>{
    switch(action.type){
        case START_LOADING_SYSTEM_PARAMETERS :
            return{
                ...state,
                systemParameters:[...action.payload]
            }
        default :
            return state;    
    }
}

export default systemParametersReducer;