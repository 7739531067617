import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Icon, Label, Segment } from 'semantic-ui-react';
import { deviceReservationInfoMessages, RESERVATIONS_LABEL, TODAY_LABEL } from '../utils/UIMessages';
import { ARROW_RIGHT_ICON,USER_ICON } from '../utils/UiIcons';
const DELETE_INDEX_FROM_PIVOT_START = 2;
const DELETE_INDEX_FROM_PIVOT_END = 3;

const DeviceReservationInfo = ({
  deviceReservationData,
  onShowReservationModal
})=> {
  const [closeReservations, setCloseReservations] = useState([]);

  const yesterday = moment().subtract(1, 'day');
  const today = moment();
  const tomorrow = moment().add(1, 'day');

  const dayChecker = (day, res) =>
    day.isSame(res.startTime, 'day') || day.isSame(res.endTime, 'day');

  useEffect(() => {
    const filtered = deviceReservationData
      .filter(
        // filter yesterday, today, tomorrow
        res =>
          dayChecker(yesterday, res) ||
          dayChecker(today, res) ||
          dayChecker(tomorrow, res)
      )
        // sort chronological
      .sort((a, b) => moment(a.startTime).diff(moment(b.startTime)));
    let indexOfPivotReservation = filtered.findIndex(i =>
        // find current reservation
        moment().isBetween(i.startTime, i.endTime)
    );
    if (indexOfPivotReservation === -1) {
      // if there are no reservation at the moment, find closest future reservation
      indexOfPivotReservation = filtered.findIndex(time => {
        const diff = moment(time.startTime).diff(moment(), 'minutes');
        if (diff < 0) {
          // if there are no reservation at future, send last reservation index
          return time.startTime === filtered[filtered.length - 1]?.startTime;
        } else {
          return diff >= 0;
        }
      })
    }
    //show 2 reservation before pivot and 2 reservation after pivot
    //*so show maximum 5 reservations in total*
    filtered.splice(indexOfPivotReservation + DELETE_INDEX_FROM_PIVOT_END, filtered.length);
    filtered.splice(0, indexOfPivotReservation - DELETE_INDEX_FROM_PIVOT_START);
    setCloseReservations(filtered);
  }, []);

  const timelineChecker = (startTime, endTime) => {
    const timeObject = {};
    if (moment(endTime).isBefore()) {
      timeObject.when = deviceReservationInfoMessages().TIME_OBJECT_WHEN_PAST;
      timeObject.color = 'grey';
      if (moment(endTime).isSame(today, 'day')) {
        timeObject.day = TODAY_LABEL();
      } else {
        timeObject.day = deviceReservationInfoMessages().TIME_OBJECT_DAY_YESTERDAY;
      }
    }
    if (moment(startTime).isAfter()) {
      timeObject.when = deviceReservationInfoMessages().TIME_OBJECT_WHEN_FUTURE;
      timeObject.color = 'blue';
      if (moment(startTime).isSame(today, 'day')) {
        timeObject.day = TODAY_LABEL();
      } else {
        timeObject.day = deviceReservationInfoMessages().TIME_OBJECT_DAY_TOMORROW;
      }
    }
    if (moment().isBetween(startTime, endTime)) {
      timeObject.when = deviceReservationInfoMessages().TIME_OBJECT_WHEN_CURRENT;
      timeObject.day = TODAY_LABEL();
      timeObject.color = 'red';
    }
    return (
      <span>
        <Label
          className={
            timeObject.when === deviceReservationInfoMessages().TIME_OBJECT_WHEN_CURRENT
              ? 'device-reservation-info-label-now'
              : 'device-reservation-info-label'
          }
          color={timeObject.color}
        >
          {timeObject.when}
        </Label>
        <Label className='device-reservation-info-label'>
          {timeObject.day}
        </Label>
      </span>
    )
  };

  return (
    <div>
      <h4>{RESERVATIONS_LABEL()}</h4>
      <Segment basic className='device-reservation-info-segment'>
        {closeReservations?.map((reservation, index) => {
          return (
            <div key={index}>
              {timelineChecker(reservation.startTime, reservation.endTime)}
              <Label color='teal' className='device-reservation-info-label'>
                <Icon name={USER_ICON} /> {reservation.username}
              </Label>
              <div>
                <strong>{deviceReservationInfoMessages().FROM_LABEL}: </strong>
                {moment(reservation.startTime).format('HH:mm:ss')}
                <Icon
                  style={{ marginRight: 5, marginLeft: 5 }}
                  name={ARROW_RIGHT_ICON}
                />
                <strong>{deviceReservationInfoMessages().TO_LABEL}: </strong>
                {moment(reservation.endTime).format('HH:mm:ss')}
              </div>
              {index !== closeReservations.length - 1 && <hr />}
            </div>
          )
        })}
      </Segment>

      <Button
        content={deviceReservationInfoMessages().RESERVE_THIS_DEVICE}
        fluid
        onClick={() => onShowReservationModal(true)}
      />
    </div>
  )
}

export default DeviceReservationInfo;
