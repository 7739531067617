import React, { Component } from 'react';
import { Button, Confirm, Form, Header, Icon, Modal, Dropdown, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { createAuthProvider, deleteAuthProvider, getOAuth2ProviderTypes, updateAuthProvider, getAllOAuth2, getAllRoles, getGroupList } from '../../../api/apiCalls';
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { showError, showSuccess } from '../../../utils/ToastHelpers';
import { CHECKMARK_ICON, CHECK_ICON, EDIT_ICON, EYE_ICON, EYE_SLASH_ICON, PLUS_ICON, X_ICON } from '../../../utils/UiIcons';
import {
    ACTIONS_HEADER, ACTIVE_LABEL,
    CANCEL_BUTTON, CONFIRM_BUTTON, EDIT_BUTTON, FAILED_ERROR_MESSAGE,
    oAuth2PageMessages, SAVE_BUTTON, SUCCESS_MESSAGE, AUTO_REGISTER_HEADER, 
    AUTO_REGISTER_USER_ROLES_LABEL, AUTO_REGISTER_USER_GROUPS_LABEL
} from '../../../utils/UIMessages';
import { ACCEPTABLE_IMAGE_EXTENSION } from '../../../utils/Constants';
import { extensionListToAcceptAttribute, onChangeFileInput } from '../../../utils/Methods';
import FileSelectionForm from '../../../components/FileSelectionForm';

const acceptableExtensions = extensionListToAcceptAttribute(ACCEPTABLE_IMAGE_EXTENSION);
class OAuth2Page extends Component {

    state = {
        oAuth2Servers: [],
        oAuth2Providers: [],
        serverFields: {},
        modalVisibility: false,
        confirmVisibility: false,
        loading: false,
        loadingOAuth2Servers: false,
        page: 0,
        size: 10,
        totalPages: -1,
        showClientSecret: false,
        imageExtensionErrorMsg: '',
        iconImage: undefined,
        loadingIconImages: true,
        pendingRequest: false,
        selectedRoles: [],
        selectedGroups: [],
        groupDropdown: [],
        roleDropdown: [],
    }

    //React Table V8
    columns = () => [
        {
            header: () => <div>{oAuth2PageMessages().AUTHENTICATION_NAME_LABEL}</div>,
            accessorKey: 'authName',
            id: 'authName',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{oAuth2PageMessages().REGISTRATION_ID_LABEL}</div>,
            accessorKey: 'registrationId',
            id: 'registrationId',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{oAuth2PageMessages().OAUTH2_PROVIDER_LABEL}</div>,
            accessorKey: 'providerTypes',
            id: 'providerTypes',
            cell: info => (<span className='number'>{this.state.oAuth2Providers.find(item => item.value === info.getValue())?.text}</span>)
        },
        {
            header: () => <div>{ACTIVE_LABEL()}</div>,
            accessorKey: 'active',
            id: 'active',
            cell: info => (<span style={{ textAlign: 'center' }}>
                {info.getValue()
                    ? <Icon name={CHECKMARK_ICON} color={'green'} />
                    : <Icon name={X_ICON} color={'red'} />}
            </span>)
        },
        {
            header: () => <div>{AUTO_REGISTER_HEADER()}</div>,
            accessorKey: 'autoRegister',
            id: 'autoRegister',
            cell: info => (<span style={{ textAlign: 'center' }}>
                {info.getValue()
                    ? <Icon name={CHECKMARK_ICON} color={'green'} />
                    : <Icon name={X_ICON} color={'red'} />}
            </span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            enableColumnFilter: false,
            enableSorting: false,
            cell: props => (<span className='number'>
                <Button size='tiny' icon={EDIT_ICON} onClick={() => this.onEditModalOpen(props)}></Button>
                <Button size='tiny' icon={X_ICON} color={'red'} onClick={() => this.onDeleteModalOpen(props)}></Button>
            </span>)
        }
    ];


    getAuthProviderTypes = () => {
        getOAuth2ProviderTypes().then(res => {
            const oAuth2Providers = res.data.map(oAuth2Providers => {
                return {
                    key: oAuth2Providers.key,
                    value: oAuth2Providers.key,
                    text: oAuth2Providers.value
                }
            })
            this.setState({ oAuth2Providers })
        })
    }

    getOAuth2List = (page, size) => {
        this.setState({ loadingOAuth2Servers: true });
        getAllOAuth2({ page: page === undefined ? this.state.page : page, size: size || this.state.size }).then(res => {
            this.setState(prevState => ({
                ...prevState,
                oAuth2Servers: res?.data?.content, page: res?.data?.number,
                size: res?.data?.size, totalPages: res?.data?.totalPages
            }));
        }).catch(err => {
            showError(oAuth2PageMessages().NOT_FETCH_OAUTH2_LIST + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingOAuth2Servers: false }));
        getAllRoles().then(res => {
            let roleDropdown = res.data.map(role => {
                return {
                    key: role.roleId,
                    value: role.roleId,
                    text: role.name,
                }
            })
            this.setState({roleDropdown})
        }).catch(err => {
            showError(err?.response?.data?.message ?? err.toString())
        })
        getGroupList().then(res => {
            let groupDropdown = res.data.map(group => {
                return {
                    key: group.id,
                    value: group.id,
                    text: group.name,
                }
            });
            this.setState({groupDropdown});
        }).catch(err => {
            showError(err?.response?.data?.message ?? err.toString())
        })
    }

    componentDidMount() {
        this.getAuthProviderTypes();
        this.getOAuth2List();
    }


    handleModalOpen = () => {
        this.setState({ modalVisibility: true });
    }

    handleModalClose = () => {
        this.setState({ modalVisibility: false, serverFields: {}, imageExtensionErrorMsg: '', iconImage: undefined, selectedRoles: [], selectedGroups: [] });
        this.getOAuth2List();
    }

    onToggleClientSecret = () => {
        this.setState(
            prevState => ({
                showClientSecret: !prevState.showClientSecret
            }),
        );
    }

    onHandleChangeRoles = (_e, { value }) => {
        this.setState({ selectedRoles: value })
    }

    onHandleChangeGroups = (_e, { value }) => {
        this.setState({ selectedGroups: value })
    }

    onEditModalOpen = props => {
        this.setState({
            modalVisibility: true,
            serverFields: props.row.original,
            selectedGroups: props.row.original.autoRegisterUserGroups,
            selectedRoles: props.row.original.autoRegisterUserRoles
        })
    }

    onDeleteModalOpen = props => {
        this.setState({ confirmVisibility: true, serverFields: props.row.original })
    }

    onHandleChange = (_e, { name, value, checked }) => {
        this.setState(prevState => (
            {
                serverFields: {
                    ...prevState.serverFields,
                    [name]: value
                }
            }))
        if (checked !== undefined) {
            this.setState(prevState => (
                {
                    serverFields: {
                        ...prevState.serverFields,
                        [name]: checked
                    }
                }))
        }
    }

    validationCheck = () => {
        return this.state.pendingRequest ||
            !(this.state.serverFields?.id || this.state.serverFields?.clientSecret?.trim() || this.state.iconImage) ||
            (this.state.serverFields?.providerTypes === 'AZURE_AD_V2' && !this.state.serverFields?.tenantId?.trim()) ||
            !(this.state.serverFields?.authName?.trim() && this.state.serverFields?.registrationId?.trim() && this.state.selectedRoles?.length > 0 &&
                this.state.serverFields?.providerTypes && this.state.serverFields?.clientId?.trim() && this.state.imageExtensionErrorMsg?.length === 0);
    }

    onSubmit = () => {
        this.setState({ pendingRequest: true });
        const formData = new FormData();
        formData.append('authName', this.state.serverFields.authName ?? '');
        formData.append('registrationId', this.state.serverFields.registrationId ?? '');
        formData.append('providerTypes', this.state.serverFields.providerTypes ?? []);
        formData.append('clientId', this.state.serverFields.clientId ?? '');
        formData.append('clientSecret', this.state.serverFields.clientSecret ?? '');
        formData.append('active', this.state.serverFields?.active ?? false);
        formData.append('autoRegister', this.state.serverFields?.autoRegister ?? false);
        this.state.selectedRoles?.forEach(role => {
            formData.append('autoRegisterUserRoles', role);
        });
        this.state.selectedGroups?.forEach(group => {
            formData.append('autoRegisterUserGroups', group);
        });
        if(this.state.iconImage) {
            formData.append('iconImage', this.state.iconImage.get('file'));
        }
        if(this.state.serverFields?.providerTypes === 'AZURE_AD_V2'){
            formData.append('tenantId',this.state.serverFields.tenantId ?? '');
        }
        if (this.state.serverFields?.id) {
            updateAuthProvider(this.state.serverFields?.id, formData).then(() => {
                this.onSuccess();
            }).catch(err => {
                this.onError(err);
            }).finally(() => this.setState({ pendingRequest: false }));
        } else {
            createAuthProvider(formData).then(() => {
                this.onSuccess();
            }).catch(err => {
                this.onError(err);
            }).finally(() => this.setState({ pendingRequest: false }));
        }
    }

    onSuccess = () => {
        showSuccess(SUCCESS_MESSAGE())
        this.handleModalClose()
        this.setState({ serverFields: {} });
    }

    onError = err => {
        if (err?.response?.data?.errors) {
            err.response.data.errors.map(errObject => showError(`${this.props.t(errObject.defaultMessage)}`))
        } else {
            showError(`${this.props.t(err?.response?.data?.message)}`)
        }
    }
    
    onDeleteFinish = () => {
        this.setState({ serverFields: null, confirmVisibility: false })
        this.getOAuth2List();
    }

    onDelete = () => {
        deleteAuthProvider(this.state.serverFields?.id).then(() => {
            showSuccess(oAuth2PageMessages(this.state.serverFields?.id).AUTH_PROVIDER_DELETE_SUCCESSFUL)
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data?.message ?? err.toString()}`)
        }).finally(this.onDeleteFinish)
    }

    handleAuthIconChange = async e => {
        onChangeFileInput(e.target.files[0], ACCEPTABLE_IMAGE_EXTENSION,
            imageExtensionErrorMsg => this.setState({ imageExtensionErrorMsg }),
            iconImage => this.setState({ iconImage }))
    };

    render() {
        return (
            <div>
                <div className='main-right-header'>
                    <div>
                        <h2> {oAuth2PageMessages().OAUTH2_SERVERS_HEADER}</h2>
                        <small>{oAuth2PageMessages().OAUTH2_SERVERS_SUB_HEADER}</small>
                    </div>
                    <div className='main-right-buttons'>
                        <Button primary icon={PLUS_ICON} content={oAuth2PageMessages().ADD_NEW_OAUTH2_SERVER_BUTTON}
                            onClick={() => this.handleModalOpen()} />
                    </div>
                </div>
                <ReactTableV8
                    data={this.state.oAuth2Servers}
                    columns={this.columns()}
                    loadingProp={this.state.loadingOAuth2Servers}
                    manualPaginationProp={true}
                    pageIndexProp={this.state.page}
                    pageSizeProp={this.state.size}
                    totalPagesProp={this.state.totalPages}
                />

                <Modal open={this.state.modalVisibility} onClose={this.handleModalClose} closeIcon
                    closeOnDimmerClick={true}
                    size={'small'}>
                    <Header content={oAuth2PageMessages().OAUTH2_MODAL_HEADER} />
                    <Modal.Content>
                        <Form>
                            <Form.Field required>
                                <label>{oAuth2PageMessages().AUTHENTICATION_NAME_LABEL}</label>
                                <Form.Input
                                    fluid
                                    name='authName'
                                    value={this.state.serverFields && this.state.serverFields.authName}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{oAuth2PageMessages().REGISTRATION_ID_LABEL}</label>
                                <Form.Input
                                    fluid
                                    name='registrationId'
                                    value={this.state.serverFields && this.state.serverFields.registrationId}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{oAuth2PageMessages().OAUTH2_PROVIDER_LABEL}</label>
                                <Dropdown
                                    selection
                                    fluid
                                    search
                                    clearable
                                    name='providerTypes'
                                    options={this.state.oAuth2Providers}
                                    placeholder={oAuth2PageMessages().OAUTH_PROVIDER_PLACEHOLDER}
                                    value={this.state.serverFields?.providerTypes}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            {this.state.serverFields?.providerTypes === 'AZURE_AD_V2' &&
                                <Form.Field required>
                                    <label>{oAuth2PageMessages().TENANT_ID_LABEL}</label>
                                    <Form.Input
                                        fluid
                                        name='tenantId'
                                        value={this.state.serverFields && this.state.serverFields?.tenantId}
                                        onChange={this.onHandleChange}
                                    />
                                </Form.Field>
                            }
                            <FileSelectionForm
                                fileValue={this.state.iconImage}
                                headerName={oAuth2PageMessages().OAUTH2_ICON_LABEL}
                                acceptableExtensions={acceptableExtensions}
                                errMsg={this.state.imageExtensionErrorMsg}
                                onChangeFileUpload={this.handleAuthIconChange} />

                            <Form.Field required style={{marginTop:'10px'}}>
                                <label>{oAuth2PageMessages().CLIENT_ID_KEY_LABEL}</label>
                                <Form.Input
                                    fluid
                                    name='clientId'
                                    value={this.state.serverFields && this.state.serverFields.clientId}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{oAuth2PageMessages().CLIENT_SECRET_LABEL}</label>
                                <Form.Input
                                    fluid
                                    name='clientSecret'
                                    value={this.state.serverFields && this.state.serverFields.clientSecret}
                                    onChange={this.onHandleChange}
                                    type={this.state.showClientSecret ? 'text' : 'password'}
                                    icon={
                                        <Icon
                                            name={this.state.showClientSecret ? EYE_SLASH_ICON : EYE_ICON}
                                            link
                                            onClick={this.onToggleClientSecret}
                                        />
                                    }
                                />
                            </Form.Field>
                            <Segment>
                                <Form.Field>
                                    <Form.Checkbox
                                        required
                                        label={ACTIVE_LABEL()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.serverFields && this.state.serverFields.active}
                                        name='active'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                        label={AUTO_REGISTER_HEADER()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.serverFields && this.state.serverFields.autoRegister}
                                        fluid
                                        name='autoRegister'
                                    />
                                </Form.Field>
                            </Segment>
                            <Form.Field required>
                                <label>{AUTO_REGISTER_USER_ROLES_LABEL()}</label>
                                <Dropdown
                                    selection multiple options={this.state.roleDropdown} clearable
                                    name='selectedRoles'
                                    onChange={this.onHandleChangeRoles} value={this.state.selectedRoles} />
                            </Form.Field>
                            <Form.Field>
                                <label>{AUTO_REGISTER_USER_GROUPS_LABEL()}</label>
                                <Dropdown
                                    selection multiple options={this.state.groupDropdown} clearable
                                    name='selectedGroups'
                                    onChange={this.onHandleChangeGroups} value={this.state.selectedGroups} />
                            </Form.Field>
                            
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button compact color='green' icon={this.state.serverFields?.id ? EDIT_ICON : CHECK_ICON}
                            content={this.state.serverFields?.id ? EDIT_BUTTON() : SAVE_BUTTON()}
                            onClick={this.onSubmit}
                            loading={this.state.pendingRequest}
                            disabled={this.validationCheck()}
                        />
                    </Modal.Actions>
                </Modal>
                <Confirm
                    open={this.state.confirmVisibility}
                    content={oAuth2PageMessages().DELETE_CONFIRM}
                    onCancel={() => {
                        this.setState({ confirmVisibility: false, serverFields: {} })
                    }}
                    onConfirm={this.onDelete}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />
            </div>
        );
    }
}

export default withTranslation()(OAuth2Page);