import React from 'react'
import ReactEmoji from 'react-emoji';

const ChatFeed = ({msg:{message, pubUserName, pubId, chatTime}, currentUserId}) => {

    let isSentByCurrentUser = false;

    if(pubId === currentUserId){
        isSentByCurrentUser = true;
    }

    return (
        isSentByCurrentUser ?
           (
            <div className='message-container justify-end'>
              <div className='chat-message'>
                <div className='chat-message-box'>
                  <div className='chat-message-box-text background-blue'>
                    <span className='message-text color-white'>{ReactEmoji.emojify(message)}</span>
                    <div className='chat-timestamp'>
                      <span>
                        {chatTime}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )
            : (
              <div className='message-container justify-start'>
                <div className='chat-message '>
                  <span className='chat-name'>{pubUserName} </span>
                  <div className='chat-message-box'>
                    <div className='chat-message-box-text background-light'>
                      <span className='message-text color-dark'>{ReactEmoji.emojify(message)}</span>
                      <div className='chat-timestamp'>
                        <span>
                          {chatTime}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
      )
}
export default ChatFeed

