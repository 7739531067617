import React, {Component} from 'react';
import {Button, Header, Icon, Modal, Tab} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import ManageGroupDevices from "./ManageGroupDevices";
import ManageGroupUsers from "./ManageGroupUsers";
import ManageGroupApplications from "./ManageGroupApplications";
import ManageGroupFiles from './ManageGroupFiles';
import { X_ICON } from '../../../utils/UiIcons';
import { APPLICATIONS_HEADER, CLOSE_BUTTON, DEVICES_HEADER, FILES_HEADER, USERS_HEADER } from '../../../utils/UIMessages';

class ManageGroupsModal extends Component {

    panes = () => [
        {
            menuItem: USERS_HEADER(), render: () => <>
                <ManageGroupUsers selectedGroup={this.props.selectedGroup}/>
            </>
        },
        {
            menuItem: DEVICES_HEADER(), render: () => <>
                <ManageGroupDevices selectedGroup={this.props.selectedGroup}/>
            </>
        },
        {
            menuItem: APPLICATIONS_HEADER(), render: () => <>
                <ManageGroupApplications selectedGroup={this.props.selectedGroup}/>
            </>
        },
        {
            menuItem: FILES_HEADER(), render: () => <>
                <ManageGroupFiles selectedGroup={this.props.selectedGroup}/>
            </>
        }
    ]

    render() {
        return (
            <div>
                <div>
                    <Modal
                        open={this.props.manageModalVisibility}
                        // open={true}
                        onClose={this.props.handleModalClose} closeIcon
                        closeOnDimmerClick={true}
                        size={"large"}>
                        <Header size={"small"} style={{textAlign: "center"}}>{this.props.selectedGroup?.name}</Header>
                        <Modal.Content>
                            <Tab menu={{secondary: true, fluid: true, pointing: true}} panes={this.panes()}
                                // activeIndex={0}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button.Group>

                                <Button icon labelPosition="right" color="red"
                                        onClick={this.props.handleModalClose}>
                                    <Icon name={X_ICON}/> {CLOSE_BUTTON()}</Button>
                            </Button.Group>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ManageGroupsModal);