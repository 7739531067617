import React, {Component} from 'react';
import {Grid, Header, Segment, Icon} from "semantic-ui-react";
import DateRangeDropdown from "../../../../components/DateRangeDropdown";
import {ResponsiveLine} from "@nivo/line";
import moment from "moment";
import _ from "lodash"
import axios from "axios";
import {dataRefresher, secondsToDhms} from '../../../../utils/Methods';
import {withTranslation} from 'react-i18next';
import {showError} from "../../../../utils/ToastHelpers";
import ReactTableV8 from '../../../../components/ReactTableV8/ReactTableV8';
import { DEVICE_STATUS_MULTIPLIER, ONE_HOUR_IN_SECONDS, ONE_MINUTE_IN_SECONDS } from '../../../../utils/Constants';
import { chartsReservationsMessages, DATE_LABEL, EVERY_DAY_LABEL, EVERY_MONTH_LABEL, FAILED_ERROR_MESSAGE, HOURS_LABEL, HOUR_LABEL, MINUTES_LABEL, MINUTE_LABEL, RESERVATIONS_LABEL, TIME_LABEL, TOTAL_LABEL, USED_HEADER } from '../../../../utils/UIMessages';
import { CALENDAR_ALTERNATE_OUTLINE_ICON } from '../../../../utils/UiIcons';


class ChartsRes extends Component {

    //New React Table V8 Columns
    countTableColumns = () => [
        {
            header: () => <div>{DATE_LABEL()}</div>,
            accessorKey: 'date',
            id: 'date',
            cell: info => (<span className='number'> <Icon name={CALENDAR_ALTERNATE_OUTLINE_ICON} />
                {(info.getValue().match(/-/g) || []).length === 2 ? moment(info.getValue()).format('DD/MM/YYYY')
                    : moment(info.getValue()).format('MM/YYYY')}</span>)
        },
        {
            header: () => <div>{USED_HEADER()}</div>,
            accessorKey: 'used',
            id: 'used',
            cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
        },
        {
            header: () => <div>{TOTAL_LABEL()}</div>,
            accessorKey: 'total',
            id: 'total',
            cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
        }
    ];

    ratioTableColumns = () => [
        {
            header: () => <div>{DATE_LABEL()}</div>,
            accessorKey: 'date',
            id: 'date',
            cell: info => (<span className='number'> <Icon name={CALENDAR_ALTERNATE_OUTLINE_ICON} />
                {(info.getValue().match(/-/g) || []).length === 2 ? moment(info.getValue()).format('DD/MM/YYYY')
                    : moment(info.getValue()).format('MM/YYYY')}</span>)
        },
        {
            header: () => <div>{USED_HEADER()}</div>,
            accessorFn: d => ((d.used / d.total) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
            id: 'used',
            cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
        }
    ];

    state = {
        componentDidUpdateCheck: false,
        dataForLineChart: [],
        dataForTable: [],
        startDate: new Date(),
        endDate: new Date(),
        visibility: false,
        loading: false,
        language: localStorage.getItem('lang') || 'en'
    }

    generateLines = (x, y, arr) => {
        let lineArray = []
        for (const arrKey in arr) {
            lineArray.push(
                {
                    "x": x[arrKey],
                    "y": y[arrKey]
                }
            )
        }
        return lineArray
    }

    convertDataForLineChart = (...allData) => { //parametric
        let dataForLineChart = allData.map((el, index) => {
            let c = index * 4
            return {
                id: this.props.t(_.startCase(el.y)),
                color: `hsl(${c}, 70%, 50%)`,
                data: this.generateLines(el?.response[el.x], el?.response[el.y], el?.response[el.x])
            }
        })
        this.setState({dataForLineChart, visibility: true})
    }

    convertDataForTable = data => {
        let dataForTable = []
        for (const datesKey in data?.dates) {
            dataForTable.push({
                date: data?.dates[datesKey],
                total: data?.totalReservation[datesKey],
                used: data?.usedReservation[datesKey],
            })
        }
        this.setState({dataForTable})
    }

    dailyOrMonthlyCheck = async (type, params = "") => { //server request
      this.setState({ loading: true });
       try {
           if (type === "daily") {
               const reservation_daily = (await axios.get("/api/reports/reservation-daily" + params)).data
               this.convertDataForLineChart(
                   {"response": reservation_daily, x: "dates", y: "usedReservation"}, {
                       "response": reservation_daily,
                       x: "dates",
                       y: "totalReservation"
                   })
               this.convertDataForTable(reservation_daily)
               let startDate = new Date(reservation_daily?.dates[0])
               let endDate = new Date(reservation_daily?.dates[reservation_daily?.dates?.length - 1])
               this.setState({startDate, endDate})

           }
           if (type === "monthly") {
               const reservation_monthly = (await axios.get("/api/reports/reservation-monthly" + params)).data
               this.convertDataForLineChart(
                   {"response": reservation_monthly, x: "dates", y: "usedReservation"}, {
                       "response": reservation_monthly,
                       x: "dates",
                       y: "totalReservation"
                   })
               this.convertDataForTable(reservation_monthly)
               let startDate = new Date(reservation_monthly.dates[0])
               let endDate = new Date(reservation_monthly?.dates[reservation_monthly?.dates?.length - 1])
               this.setState({startDate, endDate})
           }
       } catch (err) {
           showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
       } finally {
        this.setState({ loading: false });
       }
    }

    componentDidMount() {
        this.dailyOrMonthlyCheck(this.props.type)
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.setState({
                //sorted1:[],
                //sorted2:[],
                visibility: false,
                componentDidUpdateCheck : !this.state.componentDidUpdateCheck
            })
            this.dailyOrMonthlyCheck(this.props.type)
        }
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.dailyOrMonthlyCheck(this.props.type))
        )
    }

    onChangeDateComponent = link => {
        const params = "?" + link
        this.setState({visibility: false})
        this.dailyOrMonthlyCheck(this.props.type, params)
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <DateRangeDropdown
                                isOrderByVisibility={false}
                                isSizeVisibility={false}
                                type={this.props.type}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                callback={(link) => this.onChangeDateComponent(link)}
                                isUserRolesVisibility={true}
                                setSpecificEndDate={new Date().setDate(new Date().getDate() - 1)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered columns={1}>
                        <Grid.Column>
                            <Header textAlign={"center"}>
                                {chartsReservationsMessages(_.startCase(this.props.type === 'daily' ? 'Day' : 'Month')).ALL_RESERVATIONS_BY_HEADER}
                            </Header>
                            <Segment placeholder style={{height: 500}} loading={this.state.loading}>
                                {this.state.visibility && <ResponsiveLine
                                    data={this.state.dataForLineChart}
                                    curve={"monotoneX"}
                                    margin={{top: 50, right: 30, bottom: 70, left: 130}}
                                    // xScale={{
                                    //     type: "time",
                                    //     format: this.props.type === "daily" ? "%Y-%m-%d" : "%Y-%m"
                                    // }}
                                    // xFormat={this.props.type === "daily" ? "time:%Y-%m-%d" : "time:%Y-%m"}
                                    yFormat=">-d"
                                    enableSlices="x"
                                    yScale={{
                                        type: "linear",
                                        min: 0,
                                        max: "auto",
                                        stacked: false,
                                        reverse: false
                                    }}
                                    sliceTooltip={({slice}) => {
                                        return (
                                            <div
                                                style={{
                                                    background: 'white',
                                                    padding: '9px 12px',
                                                    border: '1px solid #ccc',
                                                }}
                                            >
                                                {slice.points.map(point => (
                                                    <div
                                                        key={point.id}
                                                        style={{
                                                            color: point.serieColor,
                                                            padding: '3px 0',
                                                        }}
                                                    >
                                                        <strong>{point.serieId}</strong>: {secondsToDhms(point.data.yFormatted)}
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisLeft={{
                                        orient: "left",
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        tickValues: 5,
                                        legend: RESERVATIONS_LABEL(),
                                        legendOffset: -125,
                                        legendPosition: "middle",
                                        format: (tick) => {
                                            tick = Number(tick);
                                            var h = Math.floor(tick / ONE_HOUR_IN_SECONDS);
                                            var m = Math.floor(tick % ONE_HOUR_IN_SECONDS / ONE_MINUTE_IN_SECONDS);
                                            var hDisplay = h > 0 ? h + (h === 1 ? ` ${HOUR_LABEL()} `  : ` ${HOURS_LABEL()} `) : '';
                                            var mDisplay = m > 0 ? m + (m === 1 ? ` ${MINUTE_LABEL()}, ` : ` ${MINUTES_LABEL()} `) : '';
                                            return hDisplay + mDisplay;
                                        },
                                    }}
                                    axisBottom={{
                                        format: this.props.type === 'daily' ? (tick) => moment(tick).format('MMM DD') : (tick) => moment(tick).format('YYYY MMM'),
                                        tickValues: this.props.type === 'daily' ? EVERY_DAY_LABEL() : EVERY_MONTH_LABEL(),
                                        tickRotation: -55,
                                        legend: TIME_LABEL(),
                                        legendOffset: 55,
                                        orient: 'bottom',
                                        tickSize: 5,
                                        tickPadding: 5,
                                        legendPosition: 'middle'
                                    }}
                                    crosshairType={"bottom-left"}
                                    colors={{scheme: 'set1'}}
                                    pointSize={10}
                                    pointColor={{from: 'color', modifiers: []}}
                                    pointBorderWidth={2}
                                    pointBorderColor={{from: "serieColor"}}
                                    pointLabel="y"
                                    pointLabelYOffset={-12}
                                    useMesh={true}
                                    legends={[
                                        {
                                            anchor: "bottom-right",
                                            direction: "row",
                                            justify: false,
                                            translateX: 5,
                                            translateY: 60,
                                            itemsSpacing: 10,
                                            itemDirection: "left-to-right",
                                            itemWidth: 130,
                                            itemHeight: 0,
                                            itemOpacity: 0.75,
                                            symbolSize: 12,
                                            symbolShape: "circle",
                                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                                            effects: [
                                                {
                                                    on: "hover",
                                                    style: {
                                                        itemBackground: "rgba(0, 0, 0, .03)",
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Segment placeholder style={{ minHeight: '22rem' }}>
                                <ReactTableV8
                                    data={this.state.dataForTable}
                                    columns={this.countTableColumns()}
                                    onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                    loadingProp={this.state.loading}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment placeholder style={{minHeight: '22rem'}}>
                                <ReactTableV8
                                    data={this.state.dataForTable}
                                    columns={this.ratioTableColumns()}
                                    onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                    loadingProp={this.state.loading}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(ChartsRes);
