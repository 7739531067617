import React, {Component} from 'react';
import {Dropdown, Button, Modal, Input, Header} from "semantic-ui-react";
import {withTranslation} from 'react-i18next';
import ActionMethodHandler from '../utils/ActionMethodHandler';
import {Flip, toast} from "react-toastify";
import {showError} from "../utils/ToastHelpers";
import { actionsMessages, CANCEL_BUTTON, DEVICE_HEADER, SESSION_HEADER } from '../utils/UIMessages';
import { COG_ICON } from '../utils/UiIcons';
class ActionsPanel extends Component {

    state = {
        selectedActionID: 1,
        selectedSubActionID: 1,
        methods: [],
        showInputBox: false,
        sendActionLoading: false,
        selectedMethod: {},
        selectedMethodInputs: {},
        responseModelOpen: false,
        responseModelMessage: ''
    }

    componentDidMount(){
        let actionMethodHandler = new ActionMethodHandler();
        let methods = actionMethodHandler.getMethods(this.state.selectedActionID, this.state.selectedSubActionID)
        this.setState({methods: methods})
    }

    getActionGroups(){
        const actionGroups = [
            { key: 1, text: SESSION_HEADER(), value: 1 },
            { key: 2, text: DEVICE_HEADER(), value: 2 },
            { key: 3, text: actionsMessages().ACTION_GROUP_OPTIONS_EXEC_MOBILE_COMMAND, value: 3 },
            { key: 4, text: actionsMessages().ACTION_GROUP_OPTIONS_CONTEXT, value: 4 },
            { key: 5, text: actionsMessages().ACTION_GROUP_OPTIONS_WEB, value: 5 }
        ]
        return actionGroups
    }
    
    getSubActionGroups = () => {
        let selectedSubActionGroups = []
        const sessionSubActionGroups = [
            { key: 1, text: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_SESSION_CAPABILITIES, value: 1 },
            { key: 2, text: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_TIMEOUTS, value: 2 },
            { key: 3, text: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_ORIENTATION, value: 3 },
            { key: 4, text: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_GEOLOCATION, value: 4 },
            { key: 5, text: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_LOGS, value: 5 },
            { key: 6, text: actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_SETTINGS, value: 6 }
        ]
        const deviceSubActionGroups = [
            { key: 1, text: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_SYSTEM, value: 1 },
            { key: 2, text: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_CLIPBOARD, value: 2 },
            { key: 3, text: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_NETWORK, value: 3 },
            { key: 4, text: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_INTERACTION, value: 4 },
            { key: 5, text: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_KEYS, value: 5 },
            { key: 6, text: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_ACTIVITY, value: 6 },
            { key: 7, text: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_APP, value: 7 },
            //{ key: 8, text: this.props.t('File'), value: 8 },
            { key: 8, text: actionsMessages().DEVICE_SUB_ACTION_GROUP_OPTIONS_PERFORMANCE_DATA, value: 8 },
        ]
        const executeMobileCommandActionGroups = [
            { key: 1, text: actionsMessages().EXEC_MOBILE_COMMAND_ACTION_GROUP, value: 1 }
        ]
        const contextCommandActionGroups = [
            { key: 1, text: actionsMessages().ACTION_GROUP_OPTIONS_CONTEXT, value: 1 },
            { key: 2, text: actionsMessages().ACTION_GROUP_OPTIONS_W3, value: 2 }
        ]
        const webSubActionGroups = [
            { key: 1, text: actionsMessages().WEB_SUB_ACTION_GROUP_OPTION_NAVIGATE, value: 1 }
        ]
        switch(this.state.selectedActionID){
            case 1:
                selectedSubActionGroups = sessionSubActionGroups
                break;
            case 2:
                selectedSubActionGroups = deviceSubActionGroups
                break;
            case 3:
                selectedSubActionGroups = executeMobileCommandActionGroups
                break;
            case 4:
                selectedSubActionGroups = contextCommandActionGroups
                break;
            case 5:
                selectedSubActionGroups = webSubActionGroups
                break;
            default:
                selectedSubActionGroups = sessionSubActionGroups
        }
        return selectedSubActionGroups
    }


    handleOnChangeActionGroups = (e, data) => {
        this.setState({selectedActionID: data.value, selectedSubActionID: 1}, ()=> {
            this.updateMethods()
        })
    }

    handleOnChangeSubActionGroups = (e, data) => {
        this.setState({selectedSubActionID: data.value}, () => {
           this.updateMethods()
        })
    }

    updateMethods = () => {
        let actionMethodHandler = new ActionMethodHandler();
        let methods = actionMethodHandler.getMethods(this.state.selectedActionID, this.state.selectedSubActionID)
        this.setState({methods: methods})
    }

    handleOnClick = (item) => {
        this.setState({selectedMethodInputs: {}, selectedMethod: item})
        if(item.inputRequired){
            this.setState({showInputBox: true})
        }
        else{
            let actionMethodHandler = new ActionMethodHandler();
            actionMethodHandler.executeAction(this.props.sessionId, item, {}).then(res => {
                this.showResult(res)
                if(this.props.isRecording){
                    this.handleRecording()
                }
            }).catch(err => {
                console.log("Execute action error: ", err)
            })
        }
    }

    showResult = (response) => {
        let statusCode = response?.status
        let message = response?.statusText
        let data = response?.data
        let result =  actionsMessages(statusCode,message,data).SHOW_RESULT_MESSAGE
        if(statusCode === 200){
            toast.success(result, {
                className: 'toast_custom',
                position: 'top-right',
                autoClose: 5000,
                transition: Flip
        });
        }
        else{
            showError(result);
        }
    }

    handleTextChange = (event) => {
        let selectedMethodInputs = this.state.selectedMethodInputs
        selectedMethodInputs[event.target.name] = event.target.value
        this.setState({selectedMethodInputs: selectedMethodInputs})
    }
    
    handleSendAction = () => {
        let actionMethodHandler = new ActionMethodHandler();
        this.setState({sendActionLoading: true})
        actionMethodHandler.executeAction(this.props.sessionId, this.state.selectedMethod, this.state.selectedMethodInputs).then(res => {
            this.setState({showInputBox: false, sendActionLoading: false})
            this.showResult(res)
            if(this.props.isRecording){
                this.handleRecording()
            }
        }).catch(err => {
            console.log("Execute action error: ", err)
        })
    }

    handleRecording = () => {
        let currentActions = this.props.recordedActions
        let updatedList = currentActions.concat({action: this.state.selectedMethod.identifier, params: this.state.selectedMethodInputs})
        this.props.updateRecordedActions(updatedList)
    }


    inputBox = () => (
        <Modal size={'mini'} open={this.state.showInputBox} onClose={this.close}>
        <Header icon={this.state.selectedMethod.icon? this.state.selectedMethod.icon : COG_ICON} content={this.state.selectedMethod.name} />
        <Modal.Content>
            {this.state.selectedMethod.inputNames.map((item, i) => <Input key={i} name={item} fluid focus type={"text"} style={{"marginTop": "5px"}} placeholder={item} onChange={this.handleTextChange}/>)}
        </Modal.Content>
        <Modal.Actions>
                <Button  negative onClick={() => {
                    this.setState({showInputBox: false});
                }}>{CANCEL_BUTTON()}</Button>
                <Button color="blue" loading={this.state.sendActionLoading} onClick={this.handleSendAction}>{actionsMessages().EXECUTE_ACTION_BUTTON}</Button>
            </Modal.Actions>
      </Modal>
      )

    render(){
        return(
        <>
            <div>
                <div>{this.state.showInputBox && this.inputBox()}</div>
                <div style={{"marginTop": "10px"}}>
                    <Dropdown
                        upward={false}
                        placeholder={SESSION_HEADER()} 
                        fluid
                        selection 
                        options={this.getActionGroups()}  
                        onChange={this.handleOnChangeActionGroups}/>
                </div>
                <div style={{"marginTop": "10px"}}>
                    <Dropdown 
                        upward={false}
                        placeholder={actionsMessages().SESSION_SUB_ACTION_GROUP_OPTIONS_SESSION_CAPABILITIES}
                        fluid
                        selection 
                        options={this.getSubActionGroups()}  
                        onChange={this.handleOnChangeSubActionGroups}
                        value={this.state.selectedSubActionID}
                    />
                </div>
                <div style={{"marginTop": "20px"}}>
                    {this.state.methods && this.state.methods.map((item, i) => <Button style={{"marginTop": "5px"}} key={i} basic color='grey' onClick={()=>{ this.handleOnClick(item)}}>{actionsMessages(null, null, null, item.name).BUTTON_NAME_LABEL}</Button>)}
                </div>
            </div>
        </>
        );
    }

}

export default withTranslation()(ActionsPanel);