import React, { Component } from 'react';
import { Dropdown, Image } from 'semantic-ui-react';
import i18n from '../translate/i18n';
import turkeyFlag from '../assets/images/LanguageFlags/turkey.png';
import engFlag from '../assets/images/LanguageFlags/eng.png';
import '../styles.css';


class LanguageOptions extends Component {

    handleChangeLanguage = value => {
        localStorage.setItem('lang', value);
        i18n.changeLanguage(value);
        if (this.props.callback !== null) {
            this.props.callback(value);
        }
    }


    // returnTranslate = () => {
    //     return (
    //         <div key={item.to} className="flex gap-4 items-center py-2 px-4">
    //             {item.icon('text-primary-500 -mr-2')}
    //             <div className="flex items-center align-center divide-x ">
    //                 <div className="pr-2">
    //                     <div
    //                         className={classNames(
    //                             'cursor-pointer flex text-sm',
    //                             localStorage.getItem('lang') === 'tr'
    //                                 ? 'bg-primary-200 text-primary-700 py-0.5 px-1 font-semibold rounded-full'
    //                                 : ' text-gray-800 hover:text-primary-700 hover:font-bold'
    //                         )}
    //                         onClick={() => handleChangeLanguage('tr')}>
    //                         {' '}
    //                         TR{' '}
    //                     </div>
    //                 </div>
    //                 <div className="pl-2">
    //                     <div
    //                         className={classNames(
    //                             'cursor-pointer flex text-sm',
    //                             localStorage.getItem('lang') === 'en'
    //                                 ? 'bg-primary-200 text-primary-700 py-0.5 px-1 font-semibold rounded-full'
    //                                 : ' text-gray-800 hover:text-primary-700 hover:font-bold'
    //                         )}
    //                         onClick={() => handleChangeLanguage('en')}>
    //                         {' '}
    //                         EN{' '}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }



    render() {
        return (
            <><Dropdown.Item>
                {
                    <div id='language-flags-container-div'>
                        <div onClick={() => this.handleChangeLanguage('tr')}
                            className={this.props.showFlagOf === 'tr' ? 'language-flag-outer-div-selected' : 'language-flag-outer-div'}
                            name='tr' key='tr' value='tr'>
                            <Image className='language-flag' name='tr' src={turkeyFlag} avatar />Tr
                        </div>
                        <div id='lang-flag-seperator-div-as-hr'></div>
                        <div onClick={() => this.handleChangeLanguage('en')}
                            className={this.props.showFlagOf === 'en' ? 'language-flag-outer-div-selected' : 'language-flag-outer-div'}
                            name='en' key='en' value='en'>
                            <Image className='language-flag' name='en' src={engFlag} avatar />En
                        </div>
                    </div>
                }
            </Dropdown.Item></>
        )
    }
}

LanguageOptions.defaultProps = {
    callback: null
}

export default LanguageOptions;
