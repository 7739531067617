
import { isArray } from 'lodash';
import React from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';
import {
    showMoreDialogBoxMessages, CLOSE_BUTTON
} from '../utils/UIMessages';

const ShowMoreDialog = ({
    inputProp = null,
    setOpenFuncProp = () => { },
    valueExtensionProp = null,
    segmentClassNameProp = null,
    innerDivClassNameProp = null,
    iconNameProp = null,
    headerProp = null
}) => {

    /*This operaton cannot be done above the determination of useTranslation(). So, it is here.*/
    if (headerProp === null) {
        headerProp = showMoreDialogBoxMessages().SHOW_MORE;
    }

    return (<>
        <Segment className={segmentClassNameProp} compact>
            <Header as='h4' textAlign='center' icon={iconNameProp} content={headerProp} />
            <div className={innerDivClassNameProp}>
                {
                    (inputProp !== null && inputProp !== undefined) ?
                        (typeof(inputProp) === 'string' || typeof(inputProp) === 'number' ) ?
                            <p className='show-more-resuable-dialogbox-p'>{inputProp}</p>
                            : typeof(inputProp) === 'object' ?
                                isArray(inputProp) ?
                                    valueExtensionProp === null ?
                                        inputProp.map((x, idx) => <p key={idx} className='show-more-resuable-dialogbox-p'>{idx + 1}. {x}</p>)
                                        :
                                        inputProp.map((x, idx) => <p key={idx} className='show-more-resuable-dialogbox-p'>{idx + 1}. {x[valueExtensionProp]}</p>)
                                    : valueExtensionProp === null ?
                                        <p className='show-more-resuable-dialogbox-p'>{inputProp}</p>
                                        :
                                        <p className='show-more-resuable-dialogbox-p'>- {inputProp[valueExtensionProp]}</p>
                                : <p className='show-more-resuable-dialogbox-p'>{showMoreDialogBoxMessages().UNSUPPORTED_SHOW_MORE_INPUT}</p>
                        : <p className='show-more-resuable-dialogbox-p'>{showMoreDialogBoxMessages().INPUT_NULL_OR_UNDEFINED}</p>
                }

            </div>
            <div style={{ 'marginTop': '10px' }}>
                <Button floated='right' negative onClick={() => setOpenFuncProp(false)} >
                    {CLOSE_BUTTON()}</Button>
            </div>
        </Segment>
    </>);
}

export default ShowMoreDialog;
