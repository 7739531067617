import React, {Component} from 'react';
import {Button, Dimmer, Dropdown, Form, Grid, Header, Icon, Input, Loader, Modal, Table} from "semantic-ui-react";
import _ from "lodash"
import {toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import {showError} from "../../../utils/ToastHelpers";
import {
    getLdapList,
    getLdapUsers,
    createLdapUsers
} from '../../../api/apiCalls'
import {DEBOUNCE_WAIT} from '../../../utils/Constants';
import { addLdapUsersMessages, ADD_BUTTON, DELETE_BUTTON, FAILED_ERROR_MESSAGE, FULLNAME_HEADER, LOADING, SEARCH_BUTTON, SUCCESS_MESSAGE, USERNAME_HEADER, USER_GROUPS_HEADER, USER_HEADER, USER_ROLES_LABEL } from '../../../utils/UIMessages';
import { SEARCH_ICON, USER_PLUS_ICON } from '../../../utils/UiIcons';

class AddLdapUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKeyword: "",
            allResults: [],
            allResultsForFilter: [],
            selectedResults: [],
            loading: false,
            filterByName: "",
            ldapDropdown: [],
            selectedLdap: "all",
            selectedSearchType: "userName"

        }
        this.getResults = _.debounce(this.getResults, DEBOUNCE_WAIT.VERY_LONG)
    }

    ldapRequestController = null

    searchTypesDropdown = [
        {
            key: "userName",
            value: "userName",
            text: USERNAME_HEADER(),
        },
        {
            key: "mail",
            value: "mail",
            text: addLdapUsersMessages().SEARCH_TYPE_DROPDOWN_EMAIL_OPTION,
        },
        {
            key: "fullName",
            value: "fullName",
            text: FULLNAME_HEADER(),
        },
        {
            key: "department",
            value: "department",
            text: addLdapUsersMessages().SEARCH_TYPE_DROPDOWN_DEPARTMENT_OPTION,
        }
    ]

    getResults = (value) => {
        let ldapId = this.state.selectedLdap;

        if (this.state.selectedLdap === "all"){
            ldapId = undefined;
        }

        this.ldapRequestController = new AbortController();
        //axios.get(url)
        getLdapUsers(ldapId,this.state.selectedSearchType,value).then(res => {
            this.setState({allResults: res.data, allResultsForFilter: res.data, loading: false})
        }).catch(() => {
            this.setState({loading: false, allResults:[]})
        }).finally(() => this.ldapRequestController = null);
    }

    onChangesearchKeyword = (e, {value}) => {
        if (this.ldapRequestController) {
          this.ldapRequestController.abort();
        }
        if (value.length < 3){
                this.setState({allResults: [], allResultsForFilter: [], loading: false, searchKeyword: value})
        } else {
            this.setState({searchKeyword: value, loading: true}, () => {
                this.getResults(value)
            })
        }
    }

    onClickAddUser = user => {
        let roles = [];
        user.roles?.map(role => roles.push(role.roleId))
        user.roles = roles;

        let groups = [];
        user.groups?.map(group => groups.push(group.id))
        user.groups = groups;
        this.setState((prevState) => ({
            selectedResults: [...prevState.selectedResults, user]
        }))
    }


    componentDidUpdate(prevProps) {
        if (prevProps.ldapModalVisibility === false && this.props.ldapModalVisibility === true) {
            this.setState({
                searchKeyword: "",
                allResults: [],
                selectedResults: [],
                loading: false,
                filterByName: ""
            })
        }
    }

    componentDidMount() {
        let ldapDropdown = [{
            key: "all",
            value: "all",
            text: addLdapUsersMessages().LDAP_DROPDOWN_ALL_SERVERS_OPTION
        }]

        getLdapList().then(res => {
            res.data.map(ldapServer => {
                ldapDropdown.push({
                    key: ldapServer.id,
                    value: ldapServer.id,
                    text: ldapServer.name
                })
                this.setState({ldapDropdown})
            })
        })
    }

    onSubmitUsers = () => {
        //axios.post("/api/user/add/ldap-user", this.state.selectedResults)
        createLdapUsers(this.state.selectedResults).then(() => {
            toast.success(SUCCESS_MESSAGE())
            this.props.handleModalClose()
            this.props.getAllUsersRolesGroups()
        }).catch(err => {
            this.props.handleModalClose()
            this.props.getAllUsersRolesGroups()
            showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`);
        })
    }

    onHandleChangeRoles = (e, { value, name }) => {
        let selectedResults = this.state.selectedResults;
        selectedResults.find((user,index) => {
            if(user.userName === name) {
                user.roles = value;
                selectedResults.splice(index, 1, user);
            }
        });
        this.setState({ selectedResults })
    }

    onHandleChangeGroups = (e, { value, name }) => {
        let selectedResults = this.state.selectedResults;
        selectedResults.find((user,index) => {
            if(user.userName === name) {
                user.groups = value;
                selectedResults.splice(index, 1, user);
            }
        });
        this.setState({ selectedResults })
    }

    render() {
        return (
            <div>
                <Modal open={this.props.ldapModalVisibility}
                       closeIcon
                       centered={false} size="large"
                       onClose={this.props.handleModalClose}>
                    <Header style={{textAlign: "center"}}>{addLdapUsersMessages().SEARCH_LDAP_USER_MODAL_HEADER}</Header>
                    <Modal.Content
                        // style={{textAlign: "center"}}
                    >
                        <Grid columns={2} celled={"internally"} relaxed={"very"} centered stackable>
                            <Grid.Column>
                                <Grid>
                                    <Grid.Row >
                                        <Form  style={{width:'100%'}}>
                                            <Form.Field>
                                                <label>{addLdapUsersMessages().SELECT_LDAP_SERVER_LABEL}</label>
                                                <Dropdown fluid
                                                    selection
                                                    options={this.state.ldapDropdown}
                                                    onChange={(e, {value}) => this.setState({
                                                        selectedLdap: value,
                                                        searchKeyword: "",
                                                        allResults: []
                                                    })}
                                                    defaultValue={"all"}
                                                />
                                            </Form.Field>
                                            <Form.Field>

                                                <Input

                                                label={
                                                    <Dropdown
                                                    defaultValue={"userName"}
                                                    options={this.searchTypesDropdown}
                                                    onChange={(e, {value}) => this.setState({
                                                        selectedSearchType: value,
                                                        searchKeyword: "",
                                                        allResults: []
                                                    })}
                                                    value={this.state.selectedSearchType}
                                                />
                                                }
                                                icon={SEARCH_ICON}
                                                placeholder={SEARCH_BUTTON()}
                                                       value={this.state.searchKeyword}
                                                       loading={this.state.loading}
                                                       onChange={this.onChangesearchKeyword}/>
                                            </Form.Field>
                                            {/*<Form.Field>*/}
                                            {/*    <label>Filter by name</label>*/}
                                            {/*    <Input icon={"search"} placeholder={"Search Name"}*/}
                                            {/*           disabled={this.state.searchKeyword === "" || this.state.loading}*/}
                                            {/*           value={this.state.filterByName}*/}
                                            {/*           onChange={this.onChangeFilterByName}/>*/}
                                            {/*</Form.Field>*/}
                                        </Form>

                                    </Grid.Row>
                                    <Grid.Row style={{height: "45vh", overflowY: "auto"}}>
                                        <Dimmer active={this.state.loading} inverted>
                                            <Loader inverted>{LOADING()}</Loader>
                                        </Dimmer>
                                        {!_.isEmpty(this.state.allResults) && <Table basic='very' celled compact>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{USER_HEADER()}</Table.HeaderCell>
                                                    <Table.HeaderCell>{ADD_BUTTON()}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {this.state.allResults.map(user => {
                                                    return (
                                                        <Table.Row key={user.userName}>
                                                            <Table.Cell>
                                                                <Header as='h4'>
                                                                    <Header.Content>
                                                                        {user.fullName}
                                                                        <Header.Subheader>{user.userMail}</Header.Subheader>
                                                                    </Header.Content>
                                                                </Header>
                                                            </Table.Cell>
                                                            <Table.Cell width={"3"}>
                                                                <Button primary disabled={
                                                                    this.state.selectedResults.some(value => {
                                                                        return value.userName === user.userName
                                                                    })
                                                                    ||
                                                                    this.props.savedUsers.some(value => {
                                                                        return value.userName === user.userName
                                                                    })
                                                                } onClick={() => {
                                                                    this.onClickAddUser(user)
                                                                }}>
                                                                    {">"}
                                                                </Button>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                })}
                                            </Table.Body>
                                        </Table>}
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column>
                                <Form>
                                    <Form.Field>
                                        <label>{addLdapUsersMessages().SELECTED_USERS_LABEL}</label>
                                    </Form.Field>
                                </Form>
                                <div style={{height: "61vh", overflowY: "auto", marginTop: 7}}>
                                    {!_.isEmpty(this.state.selectedResults) && <Table basic='very' celled compact>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{DELETE_BUTTON()}</Table.HeaderCell>
                                                <Table.HeaderCell>{USER_HEADER()}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.selectedResults.map((user, index) => {
                                                return (
                                                    <Table.Row key={user.userName}>
                                                        <Table.Cell width={"3"}>
                                                            <Button color={"red"} onClick={() => this.setState({
                                                                selectedResults: this.state.selectedResults.filter((_, i) => i !== index)
                                                            })}>
                                                                {`<`}
                                                            </Button>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Table.Row>
                                                                <Header as='h4'>
                                                                    <Header.Content>
                                                                        {user.fullName}
                                                                        <Header.Subheader>{user.userMail}</Header.Subheader>
                                                                    </Header.Content>
                                                                </Header>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <Form.Field required>
                                                                        <label>{USER_ROLES_LABEL()}</label>
                                                                        <Dropdown
                                                                            disabled={false}
                                                                            selection multiple options={this.props.roleDropdown} clearable
                                                                            onChange={this.onHandleChangeRoles} value={user.roles} name={user.userName} />
                                                                    </Form.Field>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Form.Field>
                                                                        <label>{USER_GROUPS_HEADER()}</label>
                                                                        <Dropdown
                                                                            disabled={false}
                                                                            selection multiple options={this.props.groupDropdown} clearable
                                                                            onChange={this.onHandleChangeGroups} name={user.userName} />
                                                                    </Form.Field>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                        </Table.Body>
                                    </Table>}
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button icon labelPosition={"right"} color={"green"} onClick={this.onSubmitUsers}
                            disabled={_.isEmpty(this.state.selectedResults)
                                || this.state.selectedResults.some(user => _.isEmpty(user.roles))}>
                            <Icon name={USER_PLUS_ICON} /> {addLdapUsersMessages().ADD_SELECTED_USERS_BUTTON}</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(AddLdapUsers);