import React, {Component} from 'react'
import {Flip, toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import {Button, Confirm, Divider, Dropdown, Form, Header, Icon, Input, Modal, Segment} from "semantic-ui-react";
import {validAgentName, validHostname, validIpAddress, validPort} from "../../../utils/Constants";
import {showError} from "../../../utils/ToastHelpers";
import {
    getAgentConfigurationList,
    deleteAgentConfig,
    updateAgentConfig,
    createAgentConfig
}from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { encryptData } from '../../../utils/PublicKeyHelper';
import ChangePasswordInput from '../../../components/ChangePasswordInput';
import { ACTIONS_HEADER, AGENGT_CONFIGURATION_HEADER, agentConfigMessages, CANCEL_BUTTON, CONFIRM_BUTTON, FAILED_ERROR_MESSAGE, HOST_HEADER, NAME_HEADER, OTHER_LABEL, PASSWORD_HEADER, PORT_HEADER, SAVE_BUTTON, SUCCESS_MESSAGE, USERNAME_HEADER, USE_SSL_LABEL } from '../../../utils/UIMessages';
import { CHECK_ICON, EDIT_ICON, EYE_ICON, EYE_SLASH_ICON, PLUS_ICON, X_ICON } from '../../../utils/UiIcons';


const agentOptions = [
    { key: 1, text: agentConfigMessages().TRAFFIC_OPTION, value: 'TRAFFIC' },
    { key: 2, text: agentConfigMessages().RESIGN_SERVER_OPTION, value: 'RESIGN_SERVER' },
    { key: 3, text: OTHER_LABEL(), value: 'OTHER' },
];

class AgentConfig extends Component {
    
    state = {
        agentFields: {},
        modalVisibility: false,
        confirmVisibility: false,
        showPassword: false,
        agents: [],
        loadingAgents: false
    }

    //New React Table V8 Columns
    columns = () => [
        {
            header: () => <div>{NAME_HEADER()}</div>,
            accessorKey: 'name',
            id: 'name',
            cell: info => (<span style={{ fontWeight: 'bold' }}>{info.getValue()}</span>)
        },
        {
            header: () => <div>{HOST_HEADER()}</div>,
            accessorKey: 'host',
            id: 'host',
            cell: info => (<span className='number' style={{ fontWeight: 'bold' }}>{info.getValue()}</span>)
        },
        {
            header: () => <div>{PORT_HEADER()}</div>,
            accessorKey: 'port',
            id: 'port',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'userName',
            id: 'userName',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{agentConfigMessages().AGENT_TYPE_LABEL}</div>,
            accessorKey: 'type',
            id: 'type',
            cell: info => (<span className='number' > {info.getValue()}</span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            enableColumnFilter: false,
            enableSorting: false,
            cell: props => (<span className='number'>
                <Button size='tiny' icon onClick={() => {
                    this.setState({ modalVisibility: true, agentFields: props.row.original })
                }}><Icon name={EDIT_ICON} /></Button>
                <Button size='tiny' icon color={'red'} onClick={() => {
                    this.setState({ confirmVisibility: true, agentFields: props.row.original })
                }}><Icon name={X_ICON} /></Button></span>)
        }
    ]

    componentDidMount() {
        this.getAgentConfigList()
    }

    getAgentConfigList = () => {
        this.setState({loadingAgents: true});
        //axios.get("/api/agent/config/all")
        getAgentConfigurationList().then(res => {
            this.setState({agents: res.data})
        }).catch(err => {
            showError(agentConfigMessages().NOT_FETCH_AGENT_LIST + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({loadingAgents: false}));
    }

    handleModalOpen = () => {
        this.setState({modalVisibility: true})
    }

    handleModalClose = () => {
        this.setState({modalVisibility: false, agentFields: {}})
    }

    onHandleChange = (e, {name, value, checked}) => {
        this.setState(prevState => (
            {
                agentFields: {
                    ...prevState.agentFields,
                    [name]: value
                }
            }))
        if (checked !== undefined) {
            this.setState(prevState => (
                {
                    agentFields: {
                        ...prevState.agentFields,
                        [name]: checked
                    }
                }))
        }
    }

    onTogglePassword = () => {
        this.setState(
            prevState => ({
                showPassword: !prevState.showPassword
            }),
        );
    }

    onDelete = () => {
        //axios.delete(`/api/agent/config/${this.state.agentFields.id}`)
        deleteAgentConfig(this.state.agentFields.id).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                delay: 1000,
                transition: Flip
            })
            this.setState({confirmVisibility: false, agentFields: {}})
            this.getAgentConfigList()
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data?.message ?? err.toString()}`)
        })
    }

    checkForCheckboxes = async () => {
        const data = {
            ...this.state.agentFields,
            userName: await encryptData(this.state.agentFields?.userName),
            password: await encryptData(this.state.agentFields?.password)
        };
        if (!data.sslEnable) {
            data.sslEnable = false
        }
        return data
    }

    onSubmit = async () => {
        const data = await this.checkForCheckboxes()
        if (this.state.agentFields.id) {
            //axios.put(`/api/agent/config/${this.state.agentFields.id}`, data)
            updateAgentConfig(this.state.agentFields.id,data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip
                })
                this.handleModalClose();
                this.getAgentConfigList()
            }).catch(err => {
                showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data?.message ?? err.toString()}`)
            })
        } else {
            //axios.post(`/api/agent/config`, data)
            createAgentConfig(data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip
                })
                this.handleModalClose();
                this.getAgentConfigList()
            }).catch(err => {
                showError(`${FAILED_ERROR_MESSAGE()}: ${err.response?.data?.message}`)
            })
        }
    }

    render() {
        return (
            <div>
                <div className='main-right-header'>
                    <div>
                        <h2>{agentConfigMessages().AGENT_CONFIGURATION_HEADER}</h2>
                        <small>{agentConfigMessages().AGENT_CONFIGURATION_SUB_HEADER}</small>
                    </div>
                    <div className='main-right-buttons'>
                        <Button primary icon={PLUS_ICON} content={agentConfigMessages().ADD_NEW_AGENT_BUTTON}
                                onClick={() => this.handleModalOpen()} />
                    </div>

                </div>
                <ReactTableV8
                    columns={this.columns()}
                    data={this.state.agents}
                    loadingProp={this.state.loadingAgents}
                />
                <Modal open={this.state.modalVisibility} onClose={this.handleModalClose} closeIcon
                       closeOnDimmerClick={true}
                       size={"small"}>
                    <Header icon='spy' content={AGENGT_CONFIGURATION_HEADER()}/>
                    <Modal.Content>
                        <h3>{agentConfigMessages().AGENT_FIELDS_LABEL} </h3>
                        <Divider/>
                        <Form>
                            <Form.Field>
                                <label>{NAME_HEADER()}</label>
                                <Form.Input
                                    fluid
                                    name={"name"}
                                    value={this.state.agentFields && this.state.agentFields.name}
                                    onChange={this.onHandleChange}
                                    error={(this.state.agentFields.host?.match(validAgentName) === null)}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{HOST_HEADER()}</label>
                                <Form.Input
                                    fluid
                                    name={"host"}
                                    value={this.state.agentFields && this.state.agentFields.host}
                                    onChange={this.onHandleChange}
                                    error={(this.state.agentFields.host?.match(validHostname || validIpAddress) === null)}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{PORT_HEADER()}</label>
                                <Form.Input
                                    fluid
                                    name={"port"}
                                    value={this.state.agentFields && this.state.agentFields.port}
                                    onChange={this.onHandleChange}
                                    error={(this.state.agentFields.port?.toString().match(validPort) === null)}
                                />
                            </Form.Field>
                            {!(this.state.agentFields?.id) && <Form.Field required>
                                <label>{agentConfigMessages().AGENT_TYPE_LABEL}</label>
                                <Dropdown style={{wordBreak: "break-all"}} fluid search selection clearable
                                    placeholder={agentConfigMessages().AGENT_TYPE_LABEL}
                                    name="type"
                                    options={agentOptions}
                                    value={this.state.agentFields?.type}
                                    onChange={this.onHandleChange} />
                            </Form.Field>}
                            <Form.Field>
                                <label>{USERNAME_HEADER()}</label>
                                <Input
                                    fluid
                                    name={"userName"}
                                    value={this.state.agentFields && this.state.agentFields.userName}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            {!(this.state.agentFields?.id) ? <Form.Field>
                                <label>{PASSWORD_HEADER()}</label>
                                <Input
                                    name="password"
                                    value={this.state.agentFields?.password}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    onChange={this.onHandleChange}
                                    icon={
                                        <Icon
                                            name={this.state.showPassword ? EYE_SLASH_ICON : EYE_ICON}
                                            link
                                            onClick={this.onTogglePassword}
                                        />
                                    }
                                />
                            </Form.Field>
                                :
                                <ChangePasswordInput
                                    name='password'
                                    value={this.state.agentFields?.password}
                                    onChange={this.onHandleChange}
                                    reset={() => this.setState(prevState => ({
                                        agentFields: {
                                            ...prevState.agentFields,
                                            password: null
                                        }
                                    }))}
                                />
                            }
                            <Segment>
                                <Form.Field>
                                    <Form.Checkbox
                                        label={USE_SSL_LABEL()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.agentFields && this.state.agentFields.sslEnable}
                                        fluid
                                        name="sslEnable"
                                    />
                                </Form.Field>
                            </Segment>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button compact color="green" icon={CHECK_ICON} content={SAVE_BUTTON()}
                                disabled={!this.state.agentFields.host || !this.state.agentFields.port || !this.state.agentFields.type}
                                onClick={this.onSubmit}/>
                    </Modal.Actions>
                </Modal>
                <Confirm
                    open={this.state.confirmVisibility}
                    content={agentConfigMessages().DELETE_CONFIRM}
                    onCancel={() => {
                        this.setState({confirmVisibility: false, agentFields: {}})
                    }}
                    onConfirm={this.onDelete}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />

            </div>
        );
    }
}

export default withTranslation()(AgentConfig);
