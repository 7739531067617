import React from 'react';
import Interweave from 'interweave';
import { Message } from 'semantic-ui-react';
import { articleViewerMessages, DOCUMENTATION_ERROR_MESSAGE_HEADER } from '../../../utils/UIMessages';



const HeaderBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        {
          props.level === 2 ?
            <h2 className='ce-header'>
              <Interweave content={props.text} tagName='span' />
            </h2> :
            <h3 className='ce-header'>
              <Interweave content={props.text} tagName='span' />
            </h3>
        }
      </div>
    </div>
  );
}


const ParagraphBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <div className='ce-paragraph cdx-block'>
          <Interweave content={props.text} tagName='span' />
        </div>
      </div>
    </div>
  );
}


const ImageBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <div className='cdx-block image-tool image-tool--filled'>
          <div className='image-tool__image'>
            <a href={props.url} target="_blank" rel='noopener noreferrer'>
              <img className='image-tool__image-picture' src={props.url} alt={props.caption} />
            </a>
          </div>
          <div className='cdx-input image-tool__caption'>
            <Interweave content={props.caption} tagName='span' />
          </div>
        </div>
      </div>
    </div>
  );
}


const UnorderedListBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <ul className='cdx-block cdx-list cdx-list--unordered'>
          {
            props.items.map((item, i) => <li key={i} className='cdx-list__item'><Interweave content={item} tagName='span' /></li>)
          }
        </ul>
      </div>
    </div>
  );
}


const OrderedListBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <ol className='cdx-block cdx-list cdx-list--ordered'>
          {
            props.items.map((item, i) => <li key={i} className='cdx-list__item'><Interweave content={item} tagName='span' /></li>)
          }
        </ol>
      </div>
    </div>
  );
}


const CodeBlock = (props) => {
  return (
    <div className='ce-block'>
      <div className='ce-block__content'>
        <div className='cdx-block ce-code'>
          <pre className='code-block'><code>{props.code}</code></pre>
        </div>
      </div>
    </div>
  );
}



/**
 * props: articleId, getHeaders(), toggleArticleLoading()
 */
export default class ArticleViewer extends React.Component {

  state = {
    article: {},
    blocks: [],
    error: false
  };


  getArticle = async (id) => {
    this.props.toggleArticleLoading(true);

    try {
      let articleJson = await fetch(`/docsJson/article/${id}.json`);
      const article = await articleJson.json();
      this.setState({
        article: article,
        blocks: JSON.parse(article.content).blocks,
        error: false
      }, this.props.getHeaders);
    } catch (e) {
      this.setState({ error: true });
    } finally {
      this.props.toggleArticleLoading(false);
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps.articleId !== this.props.articleId) {
      this.getArticle(this.props.articleId);
    }
  }


  componentDidMount() {
    this.getArticle(this.props.articleId);
  }


  render() {
    return (
      <div>
        <div id='article-viewer' >
          <h1 id='article-title-header'>
            {this.state.article.title}
          </h1>
          {!this.state.error ?
            this.state.blocks.map((block, i) => {
              if (block.type === 'header') {
                if (block.data.level === 2) {
                  return <HeaderBlock key={i} level={2} text={block.data.text} />;
                }
                else if (block.data.level === 3) {
                  return <HeaderBlock key={i} level={3} text={block.data.text} />;
                }
                else {
                  return null;
                }
              }
              else if (block.type === 'paragraph') {
                return <ParagraphBlock key={i} text={block.data.text} />;
              }
              else if (block.type === 'image') {
                return <ImageBlock key={i} url={block.data.file.url} caption={block.data.caption} />;
              }
              else if (block.type === 'list') {
                if (block.data.style === 'unordered') {
                  return <UnorderedListBlock key={i} items={block.data.items} />;
                }
                else if (block.data.style === 'ordered') {
                  return <OrderedListBlock key={i} items={block.data.items} />;
                }
                else {
                  return null;
                }
              }
              else if (block.type === 'code') {
                return <CodeBlock key={i} code={block.data.code} />;
              }
              else {
                return null;
              }
            }) :
            <Message error>
              <Message.Header>{DOCUMENTATION_ERROR_MESSAGE_HEADER()}</Message.Header>
              <p>{articleViewerMessages().ERROR_MESSAGE}</p>
            </Message>
          }
        </div>
      </div>
    )
  }
}