import React, {Component} from 'react';
import {Tab} from 'semantic-ui-react';
import InstantUsage from './InstantUsage';
import {withTranslation} from 'react-i18next';
import ApiUsage from './ApiUsage';
import NumberOfUsers from './tabs/NumberOfUsers/NumberOfUsers';
import TopUsers from './tabs/TopUsers/TopUsers';
import Reservations from './tabs/Reservations/Reservations';
import Devices from './tabs/Devices/Devices';
import DeviceUsages from './tabs/DeviceUsage/DeviceUsages';
import UserReservations from './tabs/UserReservations/UserReservations';
import RawUsageData from './tabs/RawUsageData/RawUsageData';
import Alarms from './Alarms';
import {PrivilegeConstants} from '../../utils/PrivilegeConstants';
import {getPrivileges} from '../../utils/Methods'
import { ALARMS_HEADER, DEVICES_HEADER, reportingMessages, REPORTS_HEADER, RESERVATIONS_LABEL } from '../../utils/UIMessages';
import {
  ALARM_ICON, CALENDAR_ALTERNATE_OUTLINE_ICON,
  CALENDAR_PLUS_OUTLINE_ICON, CIRCLE_ICON, MAP_SIGNS_ICON,
  MOBILE_ALTERNATE_ICON, PLAY_ICON, TH_LIST_ICON,
  TROPHY_ICON, USERS_ICON
} from '../../utils/UiIcons';

class ReportingPage extends Component {
  state = {
    // toggleValue: localStorage.getItem("toggleValue") !== null ? localStorage.getItem("toggleValue") : "device",
    privileges: []
  };

  componentDidMount() {
    const privileges = getPrivileges();
    this.setState({privileges});
  }

  panesRender = () => {
    return [
      this.state.privileges.includes(PrivilegeConstants.DEVICE_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'nou',
            icon: USERS_ICON,
            content: reportingMessages().NUMBER_OF_USERS_MENU_ITEM
          },
          render: () => <NumberOfUsers/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.DEVICE_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'tu',
            icon: TROPHY_ICON,
            content: reportingMessages().TOP_USERS_MENU_ITEM
          },
          render: () => <TopUsers/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.DEVICE_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'res',
            icon: CALENDAR_ALTERNATE_OUTLINE_ICON,
            content: RESERVATIONS_LABEL()
          },
          render: () => <Reservations/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.DEVICE_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'userRes',
            icon: CALENDAR_PLUS_OUTLINE_ICON,
            content: reportingMessages().USER_RESERVATIONS_MENU_ITEM
          },
          render: () => <UserReservations/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.DEVICE_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'devices',
            icon: MOBILE_ALTERNATE_ICON,
            content: DEVICES_HEADER()
          },
          render: () => <Devices/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.DEVICE_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'deviceUsage',
            icon: PLAY_ICON,
            content: reportingMessages().DEVICE_USAGE_MENU_ITEM
          },
          render: () => <DeviceUsages/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.DEVICE_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'rawData',
            icon: TH_LIST_ICON,
            content: reportingMessages().RAW_USAGE_DATA_MENU_ITEM
          },
          render: () => <RawUsageData/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.INSTANT_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'instant',
            icon: CIRCLE_ICON,
            content: reportingMessages().INSTANT_USAGE_MENU_ITEM
          },
          render: () => <InstantUsage/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.API_USAGE_REPORT)
        ? {
          menuItem: {
            key: 'api',
            icon: MAP_SIGNS_ICON,
            content: reportingMessages().API_USAGE_MENU_ITEM
          },
          render: () => <ApiUsage/>
        }
        : {},
      this.state.privileges.includes(PrivilegeConstants.ALARMS)
        ? {
          menuItem: {
            key: 'alarms',
            icon: ALARM_ICON,
            content: ALARMS_HEADER()
          },
          render: () => <Alarms/>
        }
        : {}
    ];
  };

  render() {
    return (
      <>
        <div>
          <div>
            <h1>{REPORTS_HEADER()}</h1>

            {/* ARAS: FILTER BOX */}
            <Tab
              panes={this.panesRender()}
              menu={{
                fluid: true,
                tabular: true,
                vertical: true
              }}
              // activeIndex={4}
              // for development
            />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ReportingPage);
