import React, {Component} from 'react';
import {Button, Header, Icon, Modal, Table} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import _ from 'lodash'
import { X_ICON } from '../../../utils/UiIcons';
import { certificatesMessages, CLOSE_BUTTON } from '../../../utils/UIMessages';

class CertificateDetailsModal extends Component {


    render() {
        return (
            <div>
                <Modal open={this.props.detailModalVisibility} onClose={this.props.handleModalClose} closeIcon
                       closeOnDimmerClick={true}
                       size={"small"}>
                    <Header style={{textAlign: "center"}}>{certificatesMessages().CERTIFICATE_DETAILS_HEADER}</Header>
                    <Modal.Content>
                        <Table basic='very' celled>
                            <Table.Body>
                                {Object.keys(this.props.certificateDetails).map((key, i) => {
                                    return (<Table.Row key={i}>
                                        <Table.Cell>
                                            {key === "fromDate" || key === "toDate" ? this.props.t(_.startCase(key)) :this.props.t(_.capitalize(key))}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.props.certificateDetails[key]}
                                        </Table.Cell>
                                    </Table.Row>)
                                })}
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button.Group>
                            <Button icon labelPosition="right" color="red" onClick={this.props.handleModalClose}
                            >
                                <Icon
                                    name={X_ICON}/> {CLOSE_BUTTON()}</Button>
                        </Button.Group>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(CertificateDetailsModal);