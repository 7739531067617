import React, {Component} from 'react';
import {Confirm, Header, Icon, Popup} from "semantic-ui-react";
import axios from "axios";
import {Flip, toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {headerForReports} from '../../utils/Methods';
import {showError} from "../../utils/ToastHelpers";
import {
    stopManual,
    stopDevelopment,
    getBusyDevices
} from '../../api/apiCalls'
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8';
import {getPrivileges} from '../../utils/Methods'
import { BRAND_HEADER, CANCEL_BUTTON, CONFIRM_BUTTON, CONNECTION_TERMINATED_MESSAGE, DEVICE_ID_HEADER, FAILED_ERROR_MESSAGE, instantUsageMessages, MODEL_HEADER, NOT_FETCH_DEVICES, NO_LABEL, OS_HEADER, RESERVED_LABEL, SUCCESS_MESSAGE, TERMINATE_CONNECTION_MESSAGE, TEST_TYPE_LABEL, USER_HEADER, YES_LABEL } from '../../utils/UIMessages';
import { MOBILE_ICON, REFRESH_ICON } from '../../utils/UiIcons';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';

const username = localStorage.getItem('username');

class InstantUsage extends Component {

    stopDevelopmentTest = (deviceId) => {   
        toast.info(instantUsageMessages(null,deviceId).DEVELOPMENT_STOP_INFO);
        const params = {
            udId: deviceId
        }
        //axios.post('/api/device-connection/stop-development', null, {params})
        stopDevelopment(params).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                autoClose: 3000,
                transition: Flip,
            });
            this.getInstantUsages()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    };

    state = {
        deviceList: [],
        deviceListClone: [],
        allUsers: [],
        allDeviceIds: [],
        selectedUsersForFilter: "",
        selectedIdsForFilter: "",
        selectedUsernameForTerminate: "",
        selectedDeviceIdForTerminate: "",
        selectedTerminateType: "",
        confirmVisibility: false,
        privileges: [],
        filtered: [],
        loading: false
    }

    onConfirmVisible = (userName, deviceId, usageType) => {
        this.setState({
            selectedUsernameForTerminate: userName,
            selectedDeviceIdForTerminate: deviceId,
            selectedTerminateType: usageType
        }, () => this.setState({confirmVisibility: true}))
    }

    onTerminateConnection = () => {
        const params = {
            udId: this.state.selectedDeviceIdForTerminate,
            username: this.state.selectedUsernameForTerminate
        }
        //axios.post('/api/device-connection/stop-manual', null, {params})
        stopManual(params).then(() => {
            toast.success(CONNECTION_TERMINATED_MESSAGE(), {
                autoClose: 3000,
                transition: Flip,
            });
            this.getInstantUsages()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
        this.setState({
            selectedUsernameForTerminate: "",
            selectedDeviceIdForTerminate: "",
            confirmVisibility: false
        })

    }

    getInstantUsages = () => {
        this.setState({loading: true});
        let allDeviceIds, deviceList, allUsers
        //axios.get("/api/device-connection/busy-devices")
        getBusyDevices().then(res => {
            deviceList = res.data
            let tempUsers = _.uniqBy(deviceList.map(device => device.users).flat(), "userName")
            tempUsers = tempUsers.sort((a, b) => a.fullName.localeCompare(b.fullName))
            allUsers = tempUsers.map(user => {
                return {
                    key: user.userName,
                    value: user.userName,
                    text: user.fullName + " / " + user.userName
                }
            })
            allDeviceIds = res.data.map(device => {
                return {
                    key: device.deviceId,
                    value: device.deviceId,
                    text: device.deviceId,
                    content: (<Header size="tiny" icon={MOBILE_ICON} content={device.deviceId}
                                      subheader={`${device.brand} ${device.deviceModel}`}/>)
                };
            });
            this.setState({deviceList, deviceListClone: deviceList, allUsers, allDeviceIds})
        }).catch(err => {
            showError(NOT_FETCH_DEVICES() + err?.response?.data?.message ?? err.toString())
            this.setState({filtered: []})
        }).finally(() => this.setState({loading: false}));
    }

    stopAutomationTest = (deviceId) => {
        toast.info(instantUsageMessages(null,deviceId).AUTOMATION_STOP_INFO);
        axios.delete(`/api/grid/sessions/${deviceId}`).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                transition: Flip,
                autoClose: 3000
            });
            this.getInstantUsages()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + this.props.t(err?.response?.data?.message ?? err.toString()))
        })
    };

    getTerminateFunction = () => {
        const terminateType = this.state.selectedTerminateType;
        if (terminateType === "Manual") {
            this.onTerminateConnection();
        } else if (terminateType === "Automation") {
            this.stopAutomationTest(this.state.selectedDeviceIdForTerminate);
        } else if (terminateType === "Development") {
            this.stopDevelopmentTest(this.state.selectedDeviceIdForTerminate);
        }
    }

    //New React Table V8 Columns
    columns = () => [
        {
            header: () => <div>{USER_HEADER()}</div>,
            accessorFn: d => {
                let userName;
                let fullName;
                let usageType;
                d.users?.map(
                    user => user.usageType?.map(ut => {
                         userName = user.userName;
                         fullName = user.fullName;
                         usageType = instantUsageMessages(null,null, ut).USAGE_TYPE_LABEL;
                }))
                return `${userName} ${fullName} ${usageType}`;
            },
            id: 'users',
            filterType: 'text',
            cell: info => {
                return <>
                    {info?.row.original?.users?.map((user, i) => {
                        return user.usageType.map(ut =>
                            <>
                                <div key={i} style={{
                                    display: 'flex'
                                }}>
                                    <p style={{ marginInlineEnd: 'auto', textAlign: 'left' }}>{user.userName}<br />
                                        <div className='instant-usage-usage-type-label'>{instantUsageMessages(null, null, ut).USAGE_TYPE_LABEL}</div>
                                    </p>
                                    {((ut === 'Automation' && ((this.props.privileges.includes(PrivilegeConstants.ACCESS_AUTOMATION) && username === user.userName) ||
                                        this.props.privileges.includes(PrivilegeConstants.REMOVE_GRID_SESSION))) ||
                                        (ut === "Manual" || ut === "Development") && this.props.privileges.includes(PrivilegeConstants.SUPREME_DEVICE_MANAGEMENT)) &&
                                        <Popup wide position={'bottom left'}
                                            trigger={<Icon
                                                fitted
                                                circular
                                                link
                                                color='red'
                                                name='x'
                                                onClick={() => this.onConfirmVisible(user.userName, info.row.original.deviceId, ut)}
                                            />}
                                            content={ut === 'Automation' ?
                                                instantUsageMessages().AUTOMATION_POPUP_MESSAGE :
                                                ut === 'Development' ?
                                                    instantUsageMessages().DEVELOPMENT_POPUP_MESSAGE :
                                                    instantUsageMessages(user.userName).POPUP_MESSAGE
                                            }
                                        />} <br /></div>
                            </>
                        )
                    })}
                </>
            }
        },
        {
            header: () => <div>{DEVICE_ID_HEADER()}</div>,
            accessorKey: 'deviceId',
            id: 'deviceId',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{BRAND_HEADER()}</div>,
            accessorFn: d => _.capitalize(d.brand),
            id: 'brand',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{MODEL_HEADER()}</div>,
            accessorKey: 'deviceModel',
            id: 'deviceModel',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{OS_HEADER()}</div>,
            accessorFn: d => _.capitalize(d.os),
            id: 'os',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{TEST_TYPE_LABEL()}</div>,
            accessorFn: d => Object.keys(d.status).find(item => d.status[item] && item !== 'Reserved' && item !== 'ReservedBy'),
            id: 'status',
            filterType: 'dropdown',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{RESERVED_LABEL()}</div>,
            accessorFn: d => d.status.Reserved ? YES_LABEL() : NO_LABEL(),
            id: 'reserved',
            filterType: 'dropdown',
            cell: info => (<span>{info.getValue()}</span>)
        }
    ];

    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({privileges})
        this.getInstantUsages()
    }

    render() {
        return (
            <div className='main-container-report'>
                        <div className="main-right-header">
            <h2 style={{paddingLeft:8}}>{this.props.t(headerForReports())}</h2>
            <Popup wide position={"right center"}
                                       trigger={<Icon
                                        style={{paddingRight:8}}
                                           fitted
                                           link
                                           name={REFRESH_ICON}
                                           onClick={() => {
                                               this.getInstantUsages()
                                           }}
                                       />}
                                       content={instantUsageMessages().REFRESH_TABLE_POPUP}
                                />
        </div>
                <>
                    <ReactTableV8
                        data={this.state.deviceList}
                        columns={this.columns()}
                        columnwiseFilterableProp={true}
                        loadingProp={this.state.loading}
                    />

                    <Confirm
                        open={this.state.confirmVisibility}
                        onCancel={() => this.setState({
                            selectedUsernameForTerminate: "",
                            selectedDeviceIdForTerminate: "",
                            confirmVisibility: false
                        })}
                        onClose={() => this.setState({
                            selectedUsernameForTerminate: "",
                            selectedDeviceIdForTerminate: "",
                            confirmVisibility: false
                        })}
                        onConfirm={this.getTerminateFunction}
                        content={instantUsageMessages(this.state.selectedUsernameForTerminate).CONFIRM_TERMINATE}
                        header={TERMINATE_CONNECTION_MESSAGE()}
                        confirmButton={CONFIRM_BUTTON()}
                        cancelButton={CANCEL_BUTTON()}
                    />
                </>
            </div>
        );
    }

}

export default withTranslation()(InstantUsage);
