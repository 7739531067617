import React, {Component} from 'react';
import {Grid, Header, Segment, Statistic} from "semantic-ui-react";
import DateRangeDropdown from "../../../../components/DateRangeDropdown";
import {ResponsivePie} from "@nivo/pie";
import CountUp from "react-countup";
import _ from "lodash";
import axios from "axios";
import {withTranslation} from 'react-i18next';
import {showError} from "../../../../utils/ToastHelpers";
import { chartsDevMessages, FAILED_ERROR_MESSAGE, SCREEN_RESOLUTION_HEADER, SCREEN_SIZE_HEADER, TOTAL_LABEL } from '../../../../utils/UIMessages';


class ChartsDev extends Component {

    state = {
        dataPieOS: [],
        dataPieAndroid: [],
        dataPieIOS: [],
        dataPieScreenResolution: [],
        dataPieScreenSize: [],
        totalDevicesArr: [],
        totalDevicesNum: 0,
        visibility: false,
        startDate: new Date(),
        endDate: new Date(),
        loading: false
    }

    generatePies = data => {
        return Object.keys(data).map((key) => {
            return ({
                id: key,
                label: key === "android" || key === "ios" ? _.upperCase(key) : key,
                value: data[key]
            })
        })
    }

    convertDataForPieChart = data => {
        this.setState({visibility: false})
        let [dataPieOS, dataPieAndroid, dataPieIOS, totalDevicesArr, totalDevicesNum] = [this.generatePies(data?.os), this.generatePies(data?.android), this.generatePies(data?.ios), [], data?.count]
        let [dataPieScreenResolution, dataPieScreenSize] = [this.generatePies(data.screenResolution), this.generatePies(data.screenSize)]
        totalDevicesArr = Object.keys(data?.os).map(key => ({[key]: data?.os[key]}))
        this.setState({
            dataPieIOS,
            dataPieAndroid,
            dataPieOS,
            totalDevicesArr,
            totalDevicesNum,
            dataPieScreenResolution,
            dataPieScreenSize
        }, () => {
            this.setState({visibility: true})
        })
    }

    typeCheck = async (type, params = "") => {
      this.setState({ loading: true });
       try {
           if (type === "all") {
               const device_stats = (await axios.get("/api/reports/device-stats" + params)).data
               this.convertDataForPieChart(device_stats)
               let startDate = new Date(device_stats?.startDate)
               let endDate = new Date(device_stats?.endDate)
               this.setState({startDate, endDate})
           }
           if (type === "used") {
               const used_device_stats = (await axios.get("/api/reports/used-device-stats" + params)).data
               this.convertDataForPieChart(used_device_stats)
               let startDate = new Date(used_device_stats?.startDate)
               let endDate = new Date(used_device_stats?.endDate)
               this.setState({startDate, endDate})
           }
       } catch (err) {
           showError(FAILED_ERROR_MESSAGE() +err?.response?.data?.message ?? err.toString())
       } finally {
          this.setState({ loading: false });
       }
    }

    componentDidMount() {
        this.typeCheck(this.props.type)
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.typeCheck(this.props.type)
        }
    }

    onChangeDateComponent = link => {
        const params = "?" + link
        this.typeCheck(this.props.type, params)
    }

    render() {

        return (

            <div>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <DateRangeDropdown
                                isOrderByVisibility={false}
                                isSizeVisibility={false}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                type={this.props.type}
                                isUserRolesVisibility={this.props.type === 'used'}
                                callback={(link) => this.onChangeDateComponent(link)}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered columns={2}>
                        <Header textAlign={"center"} content={chartsDevMessages().ALL_DEVICES_HEADER}/>
                        <Grid.Column>
                            <Segment placeholder style={{height: 350}} loading={this.state.loading}>
                                <ResponsivePie
                                    data={this.state.dataPieOS}
                                    margin={{top: 40, right: 80, bottom: 80, left: 80}}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    sortByValue={true}
                                    radialLabel={function(e){return e.label+" ("+e.value+")"}}
                                    cornerRadius={3}
                                    colors={{scheme: 'set2'}}
                                    borderWidth={1}
                                    borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                                    radialLabelsSkipAngle={10}
                                    radialLabelsTextColor="#333333"
                                    radialLabelsLinkColor={{from: 'color'}}
                                    sliceLabelsSkipAngle={10}
                                    sliceLabelsTextColor="#333333"
                                    legends={[
                                        {
                                            anchor: 'right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 70,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 18,
                                            itemTextColor: '#999',
                                            itemDirection: 'right-to-left',
                                            itemOpacity: 1,
                                            symbolSize: 18,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment placeholder style={{height: 350, display: "flex", alignItems: "center"}} loading={this.state.loading}>

                                <Statistic.Group horizontal>
                                    {this.state.totalDevicesArr.map((el, index) => {
                                        return (
                                            <Statistic key={index}>
                                                <Statistic.Value>
                                                    <CountUp start={0} end={+Object.values(el)} delay={0.9}/>
                                                </Statistic.Value>
                                                <Statistic.Label>{Object.keys(el)}</Statistic.Label>
                                            </Statistic>
                                        )
                                    })}
                                    <hr style={{width: "100%"}}/>
                                    {this.state.visibility && <Statistic key={99}>
                                        <Statistic.Value>
                                            <CountUp start={0} end={this.state.totalDevicesNum} delay={0.5}/>
                                        </Statistic.Value>
                                        <Statistic.Label>{TOTAL_LABEL()}</Statistic.Label>
                                    </Statistic>}
                                </Statistic.Group>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header textAlign={"center"} content={chartsDevMessages().ANDROID_VERSIONS_HEADER}/>
                            <Segment placeholder style={{height: 350}} loading={this.state.loading}>
                                <ResponsivePie
                                    data={this.state.dataPieAndroid}
                                    margin={{top: 40, right: 80, bottom: 80, left: 80}}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    sortByValue={true}
                                    radialLabel={function(e){return e.label+" ("+e.value+")"}}
                                    colors={{scheme: 'set2'}}
                                    borderWidth={1}
                                    borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                                    radialLabelsSkipAngle={10}
                                    radialLabelsTextColor="#333333"
                                    radialLabelsLinkColor={{from: 'color'}}
                                    sliceLabelsSkipAngle={10}
                                    sliceLabelsTextColor="#333333"
                                    legends={[
                                        {
                                            anchor: 'right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 70,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 18,
                                            itemTextColor: '#999',
                                            itemDirection: 'right-to-left',
                                            itemOpacity: 1,
                                            symbolSize: 14,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Header textAlign={"center"} content={chartsDevMessages().IOS_VERSIONS_HEADER}/>
                            <Segment placeholder style={{height: 350}} loading={this.state.loading}>
                                <ResponsivePie
                                    data={this.state.dataPieIOS}
                                    margin={{top: 40, right: 80, bottom: 80, left: 80}}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    sortByValue={true}
                                    radialLabel={function(e){return e.label+" ("+e.value+")"}}
                                    colors={{scheme: 'set2'}}
                                    borderWidth={1}
                                    borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                                    radialLabelsSkipAngle={10}
                                    radialLabelsTextColor="#333333"
                                    radialLabelsLinkColor={{from: 'color'}}
                                    sliceLabelsSkipAngle={10}
                                    sliceLabelsTextColor="#333333"
                                    legends={[
                                        {
                                            anchor: 'right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 70,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 18,
                                            itemTextColor: '#999',
                                            itemDirection: 'right-to-left',
                                            itemOpacity: 1,
                                            symbolSize: 14,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header textAlign={"center"} content={SCREEN_SIZE_HEADER()}/>
                            <Segment placeholder style={{height: 350}} loading={this.state.loading}>
                                <ResponsivePie
                                    data={this.state.dataPieScreenSize}
                                    margin={{top: 40, right: 80, bottom: 80, left: 80}}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    sortByValue={true}
                                    radialLabel={function(e){return e.label+" ("+e.value+")"}}
                                    colors={{scheme: 'set2'}}
                                    borderWidth={1}
                                    borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                                    radialLabelsSkipAngle={10}
                                    radialLabelsTextColor="#333333"
                                    radialLabelsLinkColor={{from: 'color'}}
                                    sliceLabelsSkipAngle={10}
                                    sliceLabelsTextColor="#333333"
                                    legends={[
                                        {
                                            anchor: 'right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 70,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 18,
                                            itemTextColor: '#999',
                                            itemDirection: 'right-to-left',
                                            itemOpacity: 1,
                                            symbolSize: 14,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Header textAlign={"center"} content={SCREEN_RESOLUTION_HEADER()}/>
                            <Segment placeholder style={{height: 350}} loading={this.state.loading}>
                                <ResponsivePie
                                    data={this.state.dataPieScreenResolution}
                                    margin={{top: 40, right: 80, bottom: 80, left: 80}}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    sortByValue={true}
                                    radialLabel={function(e){return e.label+" ("+e.value+")"}}
                                    colors={{scheme: 'set2'}}
                                    borderWidth={1}
                                    borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                                    radialLabelsSkipAngle={10}
                                    radialLabelsTextColor="#333333"
                                    radialLabelsLinkColor={{from: 'color'}}
                                    sliceLabelsSkipAngle={10}
                                    sliceLabelsTextColor="#333333"
                                    legends={[
                                        {
                                            anchor: 'right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 70,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 18,
                                            itemTextColor: '#999',
                                            itemDirection: 'right-to-left',
                                            itemOpacity: 1,
                                            symbolSize: 14,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(ChartsDev);
