import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Confirm, Icon, List } from 'semantic-ui-react';
import { showError,showSuccess } from '../../../utils/ToastHelpers';
import {
    getAllWebHooks,
    deleteWebhook,
    getWebhookEventTypes,
    getWebhookAuthMethod
} from '../../../api/apiCalls';
import WebhookModal from './WebhookModal';
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { ACTIONS_HEADER, ACTIVE_LABEL, CANCEL_BUTTON, CONFIRM_BUTTON, FAILED_ERROR_MESSAGE, NAME_HEADER, webhookMessages } from '../../../utils/UIMessages';
import { CHECKMARK_ICON, EDIT_ICON, PLUS_ICON, X_ICON } from '../../../utils/UiIcons';

class Webhooks extends Component {

    state = {
        modalVisibility: false,
        confirmVisibility: false,
        webhookList: [],
        selectedWebhook: {},
        loadingWebhook: false,
        eventTypeDropdown: [],
        authMethodDropdown: [],
        page: 0,
        size: 10,
        totalPages: -1
    }

    //New React Table V8 Columns
    columnsRender = () => [
        {
            header: () => <div>{NAME_HEADER()}</div>,
            accessorKey: 'name',
            id: 'name',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{webhookMessages().EVENT_TYPE_HEADER}</div>,
            accessorKey: 'eventType',
            id: 'eventType',
            cell: props => (<List as='ol' verticalAlign='middle'>
                {props.getValue().map((eventType, i) => {
                    return (
                        <List.Item key={i} as='li' value='*'>
                            {this.state.eventTypeDropdown.find(item => item.key === eventType)?.text}
                        </List.Item>)
                })}</List>)
        },
        {
            header: () => <div>{ACTIVE_LABEL()}</div>,
            accessorKey: 'active',
            id: 'active',
            cell: props => (<span> {props.getValue() ? <>
                <Icon name={CHECKMARK_ICON} color='green' /></> : <>
                <Icon name={X_ICON} color='red' />
            </>} </span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            enableColumnFilter: false,
            enableSorting: false,
            cell: info => {
                return <span className='number'><Button size='tiny' onClick={() => {
                    this.setState({ selectedWebhook: info.row.original }, () => {
                        this.setState({ modalVisibility: true })
                    })
                }} icon={<Icon name={EDIT_ICON} />} /> <Button
                        size='tiny'
                        color='red'
                        onClick={() => {
                            this.setState({ confirmVisibility: true, selectedWebhook: info.row.original })
                        }}
                        icon={<Icon
                            name={X_ICON} />} /></span>
            }
        }
    ];

    handleAddModalClose = () => {
        this.setState({ modalVisibility: false, selectedWebhook: null })
        this.getWebhookList()
    }

    componentDidMount() {
        this.getWebhookList();
        this.webhookAuthMethodList();
        this.webhookEventTypeList();
    }

    webhookAuthMethodList = () => {
        getWebhookAuthMethod().then(res => {
            const authMethodDropdown = res.data.map(authMethod => {
                return {
                    key: authMethod.value,
                    value: authMethod.value,
                    text: this.props.t(authMethod.text)
                }
            })
            this.setState({ authMethodDropdown });
        })
    }

    webhookEventTypeList = () => {
        getWebhookEventTypes().then(res => {
            const eventTypeDropdown = res.data.map(eventType => {
                return {
                    key: eventType.key,
                    value: eventType.key,
                    text: this.props.t(eventType.value)
                }
            })
            this.setState({ eventTypeDropdown })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.t !== prevProps.t) {
            this.webhookAuthMethodList();
            this.webhookEventTypeList();
        }
    }

    getWebhookList = (page, size) => {
        this.setState({ loadingWebhook: true });
        getAllWebHooks({ page: page === undefined ? this.state.page : page, size: size || this.state.size }).then(res => {
            this.setState(prevState => ({
                ...prevState,
                webhookList: res?.data?.content, page: res?.data?.number,
                size: res?.data?.size, totalPages: res?.data?.totalPages
            }));
        }).catch(err => {
            showError(webhookMessages().NOT_FETCH_WEBHOOK_LIST + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingWebhook: false }));
    }

    onDeleteFinish = () => {
        this.setState({ selectedWebhook: null, confirmVisibility: false })
        this.getWebhookList();
    }

    onDelete = () => {
        deleteWebhook(this.state.selectedWebhook?.id).then(() => {
            showSuccess(webhookMessages(this.state.selectedWebhook?.id).WEBHOOK_DELETE_SUCCESSFUL)
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data?.message ?? err.toString()}`)
        }).finally(this.onDeleteFinish)
    }

    render() {
        return (
            <>
                <div>
                    <>
                        <div className='main-right-header'>
                            <div>
                                <h2>{webhookMessages().WEBHOOK_MANAGEMENT_HEADER}</h2>
                                <small>{webhookMessages().WEBHOOK_MANAGEMENT_SUB_HEADER}</small>
                            </div>
                            <div className='main-right-buttons'>
                                <Button primary icon={PLUS_ICON} content={webhookMessages().ADD_NEW_WEBHOOK_BUTTON}
                                    onClick={() => this.setState({ modalVisibility: true, selectedWebhook: {} })} />
                            </div>
                        </div>
                        <ReactTableV8
                            columns={this.columnsRender()}
                            data={this.state.webhookList}
                            loadingProp={this.state.loadingWebhook}

                            //Manual Pagination
                            manualPaginationProp={true}
                            pageIndexProp={this.state.page}
                            pageSizeProp={this.state.size}
                            onPaginationChangeProp={(page, size) => {
                                this.getWebhookList(page, size)
                            }}
                            totalPagesProp={this.state.totalPages}
                        />
                        <div>
                            {this.state.modalVisibility &&
                                <WebhookModal
                                    addModalVisibility={this.state.modalVisibility}
                                    handleModalClose={this.handleAddModalClose}
                                    webhook={this.state.selectedWebhook}
                                    eventTypeDropdown={this.state.eventTypeDropdown}
                                    authMethodDropdown={this.state.authMethodDropdown}
                                />
                            }

                            <Confirm
                                size='small'
                                open={this.state.confirmVisibility}
                                content={webhookMessages().DELETE_CONFIRM_MESSAGE}
                                onCancel={() => {
                                    this.setState({ confirmVisibility: false })
                                }}
                                header={webhookMessages().DELETE_CONFIRM_HEADER}
                                onConfirm={this.onDelete}
                                confirmButton={CONFIRM_BUTTON()}
                                cancelButton={CANCEL_BUTTON()}
                            />
                        </div>
                    </>
                </div>
            </>
        )
    }
}

export default withTranslation()(Webhooks);
