import React from 'react'
import {Icon} from 'semantic-ui-react'
import { CHAT_APP_CONSTANTS } from '../../utils/Constants'
import { SEND_ICON } from '../../utils/UiIcons'
import { chatMessages } from '../../utils/UIMessages'

const ChatInput = ({ setMessage, sendMessage, message }) => {

   return(
      <form className='chat-form'>
        <textarea
          maxLength={CHAT_APP_CONSTANTS.MAX_MSG_INPUT_LENGTH}
          className='chat-input'
          placeholder={chatMessages().PLACEHOLDER}
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
          onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
        />
        <div className='vertical-center chat-msg-length'  style={{ color: message?.length === CHAT_APP_CONSTANTS.MAX_MSG_INPUT_LENGTH ? 'red' : 'grey' }}>
        {CHAT_APP_CONSTANTS.MAX_MSG_INPUT_LENGTH - message?.length}
        </div>
        <button className='message-send-button' disabled={message?.length === 0} onClick={e => sendMessage(e)}>
          <Icon name={SEND_ICON} className='send-msg-icon'/>
        </button>
      </form>
   )
}
export default ChatInput;

