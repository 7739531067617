import React from 'react';
import {useTranslation} from 'react-i18next';
import {Tab} from "semantic-ui-react";
import ChartsRes from "./ChartsRES";
import {headerForReports} from "../../../../utils/Methods";
import { DAY_LABEL, MONTH_LABEL } from '../../../../utils/UIMessages';


const Reservations = () => {
    const { t } = useTranslation();

    const panes = [
        {
            menuItem: DAY_LABEL(),
            render: () => <><ChartsRes type={"daily"}/></>,
        },
        {
            menuItem: MONTH_LABEL(),
            render: () => <><ChartsRes type={"monthly"}/></>,
        },
    ]
    return (
        <div className='main-container-report'>
        <div className="main-right-header">
            <h2>{t(headerForReports())}</h2>
        </div>

        <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
    </div>
    );
};


export default Reservations;
