import React, {Component} from 'react';
import {Button, Dropdown, Form, Input, Label} from "semantic-ui-react";
import {stringify} from "querystring";
import {httpMethods,API_USAGE, HTTP_STATUS, DEBOUNCE_WAIT} from '../../utils/Constants';
import _ from "lodash"
import {showError} from "../../utils/ToastHelpers";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {headerForReports, millisConverter} from '../../utils/Methods';
import {withTranslation} from 'react-i18next';
import {
    getAllApi,
    getUserSummaryList
} from '../../api/apiCalls'
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8';
import { apiUsageMessages, END_DATE_LABEL, EVENT_TIME_HEADER, NOT_FETCH_USERS, RESET_TABLE_BUTTON, SEARCH_USER_PLACEHOLDER, START_DATE_LABEL, STATUS_HEADER, TIME_LABEL, USERNAME_HEADER, USERS_HEADER } from '../../utils/UIMessages';

class ApiUsage extends Component {

    inputRef = React.createRef()

    //React Table V8 Columns
    columns = () => [
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'userName',
            id: 'userName',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{EVENT_TIME_HEADER()}</div>,
            accessorKey: 'eventTime',
            id: 'eventTime',
            cell: info => (<span className='number'>{moment(info.getValue()).format('DD/MM/YYYY HH:mm:ss')}</span>)
        },
        {
            header: () => <div>{apiUsageMessages().METHOD_HEADER}</div>,
            accessorKey: 'method',
            id: 'method',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{apiUsageMessages().PATH_HEADER}</div>,
            accessorKey: 'path',
            id: 'path',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{STATUS_HEADER()}</div>,
            accessorKey: 'status',
            id: 'status',
            cell: info => (<span className='number'>{this.statusLabelChanger(info.getValue())}</span>)
        },
        {
            header: () => <div>{apiUsageMessages().TIME_TAKEN_HEADER}</div>,
            accessorKey: 'timeTaken',
            id: 'timeTaken',
            cell: info => (<span className='number'>{millisConverter(info.getValue())}</span>)
        },
        {
            header: () => <div>{apiUsageMessages().REMOTE_ADDRESS_HEADER}</div>,
            accessorKey: 'remoteAddress',
            id: 'remoteAddress',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            response: {},
            apiUsages: [],
            apiUsagesURL: '/api/trace/all',
            page: 0,
            loading: false,
            pageSize: 10,
            totalPages: 10,
            allParams: {
                page: 0,
                size: 10
            },
            allUsers: [],
            endDate: null,
            startDate: null,
            loadingUsers: false,

            //justForResetTable
            userName: '',
            path: '',
            method: '',
            remoteAddress: '',
            status: ''
        }
        this.debouncedFunc = _.debounce(this.debouncedFunc, DEBOUNCE_WAIT.LONG)
    }

    statusLabelChanger = status => {
        let color;
        if(status.toString().startsWith(HTTP_STATUS.OK_PREFIX)){
            color = 'green'
        } else if (status.toString().startsWith(HTTP_STATUS.CLIENT_ERROR_PREFIX)){
            color = 'yellow'
        } else if (status.toString().startsWith(HTTP_STATUS.SERVER_ERROR_PREFIX)){
            color = 'red'
        } else {
            color = ''
        }
        return <Label color={color}>{status}</Label>
    }

    getUsers = () => {
        this.setState({ loadingUsers: true });
        //axios.get('/api/user/users')
        getUserSummaryList().then(res => {
            let allUsers = res.data.sort(function compare(a, b) {
                const bandA = a.fullName.toUpperCase();
                const bandB = b.fullName.toUpperCase();

                let comparison = 0;
                if (bandA > bandB) {
                    comparison = 1;
                } else {
                    comparison = -1;
                }
                return comparison;
            })
            allUsers = allUsers.map(user => {
                return {
                    key: user.userName,
                    value: user.userName,
                    text: user.fullName === null ? user.userName : user.fullName
                }
            });
            this.setState({allUsers})
        }).catch(err => {
            showError(`${NOT_FETCH_USERS()} ${err?.response?.data?.message ?? err.toString()}`)
        }).finally(() => this.setState({ loadingUsers: false }));
    }

    onPaginationChange = (pageIndex, pageSize) => {
        this.setState({ page: pageIndex, pageSize })
        this.setState(prevState => ({
            allParams: {
                ...prevState.allParams,
                page: pageIndex,
                size: pageSize
            }
        }), () => this.loadData())
    }

    handleDropdown = (e, {value, name}) => {
        this.setState({[name]: value})
        if (name === 'method') {
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    [name]: 'in:' + value
                }
            }), () => this.loadData())
        } else {
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    [name]: 'eq:' + value
                }
            }), () => this.loadData())
        }
    }

    debouncedFunc = (value, name) => {
        this.setState(prevState => ({
            allParams: {
                ...prevState.allParams,
                [name]: 'like:' + value
            }
        }), () => this.loadData())
    }

    handleChangeDebounce = (e, {name, value}) => {
        this.setState({[name]: value})
        this.debouncedFunc(value, name)
    }


    onChangeStartDate = startDate => {
        let endDate = this.state.endDate;

        if(startDate > endDate){
            endDate = startDate;
        }

        const dateAfterInterval = new Date(startDate);
        dateAfterInterval.setDate(dateAfterInterval.getDate() + API_USAGE.MAX_ALLOWED_QUERY_INTERVAL);

        if(dateAfterInterval.getTime() < endDate.getTime()){
            endDate = dateAfterInterval;
        }

        this.setStateOnChangeDate(startDate, endDate);
    };

    onChangeEndDate = endDate => {
        let startDate = this.state.startDate;

        const dateBeforeInterval = new Date(endDate);
        dateBeforeInterval.setDate(dateBeforeInterval.getDate() - API_USAGE.MAX_ALLOWED_QUERY_INTERVAL);

        if(startDate == null || dateBeforeInterval.getTime() > startDate.getTime()){
            startDate = dateBeforeInterval;
        }

        this.setStateOnChangeDate(startDate, endDate);
    };

    setStateOnChangeDate = (startDate, endDate) => {
        this.setState({startDate, endDate}, () => {
            endDate = moment(endDate).format(API_USAGE.DATETIME_FORMAT);
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    'eventTime': `btn:${moment(this.state.startDate).format(API_USAGE.DATETIME_FORMAT)},${moment(this.state.endDate).format(API_USAGE.DATETIME_FORMAT)}`
                }
            }), () => this.loadData())
        })
    }

    loadData = () => {
        this.setState({loading: true})
        let params = this.state.allParams
        Object.keys(params).forEach(key => {
            if (typeof params[key] === 'string' && (params[key].slice(-1) === ':' || params[key].includes('Invalid date')) ) {
                delete params[key]
            }
        })
        params = stringify(params)
        //axios.get(this.state.apiUsagesURL + '?' + params)
        getAllApi(params).then(res => {
            this.setState({
                apiUsages: res.data.content,
                pageSize: res.data.size,
                totalPages: res.data.totalPages,
                page: res.data.pageable.pageNumber,
                loading: false
            })
        })
    }

    getApiUsages = () => {
        this.setState({loading: true})
        // axios.get(this.state.apiUsagesURL).then(res => {
          getAllApi().then(res => {
            this.setState({
                apiUsages: res.data.content,
                pageSize: res.data.size,
                totalPages: res.data.totalPages,
                page: res.data.pageable.pageNumber,
                allParams: {page: res.data.pageable.pageNumber, size: res.data.size}
            })
        }).catch(err => {
            showError(`${apiUsageMessages().NOT_FETCH_API_USAGE_HISTORY}: ${err?.response?.data?.message ?? err.toString()}`);
        }).finally(() => this.setState({ loading: false }));
    }

    onSortChange = newSorted => {
        if (newSorted.length > 0) {
            const columnName = newSorted[0].id
            const orderBy = newSorted[0].desc ? 'DESC' : 'ASC'
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    sort: `${columnName},${orderBy}`
                }
            }), () => this.loadData())
        }
    }

    initializeDefaultsAndLoadData = () => {
        this.onChangeEndDate(new Date())
    }

    componentDidMount() {
        this.getUsers()
        this.initializeDefaultsAndLoadData();
    }

    render() {
        return (
            <>
                <div className='main-container'>
                            <div className='main-left-container'>
                                <h1>{this.props.t(headerForReports())}</h1>
                                <div className='main-left-content'>
                                    <Form>
                                        <Form.Field>
                                            <label>{USERS_HEADER()}</label>
                                            <Dropdown style={{wordBreak: 'break-all'}} fluid search selection clearable
                                                      placeholder={SEARCH_USER_PLACEHOLDER()}
                                                      name={'userName'}
                                                      options={this.state.allUsers}
                                                      value={this.state.userName}
                                                      onChange={this.handleDropdown}
                                                      loading={this.state.loadingUsers} />
                                        </Form.Field>
                                        <Form.Field inline>
                                            <label>{START_DATE_LABEL()}</label>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={date => {
                                                    this.onChangeStartDate(date)
                                                }}
                                                disabledKeyboardNavigation
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                dateFormat='dd/MM/yyyy HH:mm'
                                                showTimeSelect
                                                timeFormat='HH:mm'
                                                timeIntervals={15}
                                                timeCaption={TIME_LABEL()}
                                                locale={this.props.i18n.language}
                                                maxDate={new Date()}
                                                //TODO: maxTime
                                            />
                                        </Form.Field>
                                        <Form.Field inline>
                                            <label>{END_DATE_LABEL()}</label>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={date => {
                                                    this.onChangeEndDate(date)
                                                }}
                                                selectsEnd
                                                disabledKeyboardNavigation
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                minDate={this.state.startDate}
                                                dateFormat='dd/MM/yyyy HH:mm'
                                                showTimeSelect
                                                timeFormat='HH:mm'
                                                timeIntervals={15}
                                                timeCaption={TIME_LABEL()}
                                                locale={this.props.i18n.language}
                                                //TODO: minTime
                                                //TODO: maxTime
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{apiUsageMessages().METHOD_HEADER}</label>
                                            <Dropdown style={{wordBreak: 'break-all'}} fluid selection clearable
                                                      placeholder={apiUsageMessages().METHOD_PLACEHOLDER}
                                                      options={httpMethods}
                                                      value={this.state.method}
                                                      name={'method'}
                                                      onChange={this.handleDropdown}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{STATUS_HEADER()}</label>
                                            <Dropdown style={{wordBreak: 'break-all'}} fluid selection clearable
                                                      placeholder={apiUsageMessages().STATUS_CODE_PLACEHOLDER}
                                                      value={this.state.status}
                                                      options={HTTP_STATUS.CODES}
                                                      name={'status'}
                                                      onChange={this.handleDropdown}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{apiUsageMessages().PATH_HEADER}</label>
                                            <Input placeholder={apiUsageMessages().PATH_PLACEHOLDER}
                                                   value={this.state.path}
                                                   name={'path'}
                                                   onChange={this.handleChangeDebounce}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{apiUsageMessages().REMOTE_ADDRESS_HEADER}</label>
                                            <Input placeholder={apiUsageMessages().REMOTE_ADDRESS_PLACEHOLDER}
                                                   value={this.state.remoteAddress}
                                                   name={'remoteAddress'}
                                                   onChange={this.handleChangeDebounce}/>
                                        </Form.Field>
                                    </Form>
                                    <Button style={{marginTop: 10}} onClick={() => {
                                                    this.setState({
                                                        endDate: null,
                                                        startDate: null,
                                                        path: '',
                                                        status: '',
                                                        userName: '',
                                                        remoteAddress: '',
                                                        method: ''
                                                    }, this.initializeDefaultsAndLoadData)
                                                }} fluid>{RESET_TABLE_BUTTON()}</Button>
                                </div>
                            </div>
                    <div className='main-right-container'>                        
                        <ReactTableV8
                            data={this.state.apiUsages}
                            columns={this.columns()}

                            //Manual (server-side) Pagination
                            manualPaginationProp={true}
                            pageSizeProp={this.state.pageSize}
                            totalPagesProp={this.state.totalPages}
                            pageIndexProp={this.state.page}
                            onPaginationChangeProp={this.onPaginationChange}

                            //Server-side Sorting
                            manualSortingProp={true}
                            onSortedChangeProp={newSorted => this.onSortChange(newSorted)}
                            loadingProp={this.state.loading}
                        />
                    </div>
                            {/*<Button*/}
                            {/*    icon*/}
                            {/*    floated='right'*/}
                            {/*    color='green'*/}
                            {/*    style={{marginTop: 5, marginRight: 10}}*/}
                            {/*    onClick={this.onClickCsvDownload}*/}
                            {/*> <Icon*/}
                            {/*    name='download'/> Download CSV</Button>*/}

                </div>

            </>
        );
    }
}

export default withTranslation()(ApiUsage);
