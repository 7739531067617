import React, {Component} from 'react';
import {Button, Confirm, Form, Header, Icon, Modal, Segment} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import CertificateDetailsModal from './CertificateDetailsModal';
import { showError, showSuccess } from '../../../utils/ToastHelpers';
import {
    createCertificate,
    updateCertificate,
    getCertificateList,
    deleteCertificate
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import ChangePasswordInput from '../../../components/ChangePasswordInput';
import { encryptData } from '../../../utils/PublicKeyHelper';
import { extensionListToAcceptAttribute, onChangeFileInput, dataRefresher } from '../../../utils/Methods';
import { ACCEPTABLE_CERT_EXTENSION } from '../../../utils/Constants';
import FileSelectionForm from '../../../components/FileSelectionForm';
import { ACTIONS_HEADER, ACTIVE_LABEL, CANCEL_BUTTON, certificatesMessages, CERTIFICATES_HEADER, CONFIRM_BUTTON, FAILED_ERROR_MESSAGE, NO_LABEL, SAVE_BUTTON, SUCCESS_MESSAGE, YES_LABEL } from '../../../utils/UIMessages';
import { CHECKMARK_ICON, EDIT_ICON, EYE_ICON, EYE_SLASH_ICON, INFO_ICON, PLUS_ICON, USER_PLUS_ICON, X_ICON } from '../../../utils/UiIcons';

//Accepted Certificate File Extentions---------------------------

class CertificatesPage extends Component {

    //New React Table V8 Columns
    columnsRender = () => [
        {
            header: () => <div>{certificatesMessages().CERTIFICATE_NAME_HEADER}</div>,
            accessorKey: 'name',
            id: 'name',
            filterType: 'text',
            cell: info => (<span>{info.getValue() === null ? <>N/A</> : <>{info.getValue()}</>}</span>)
        },
        {
            header: () => <div>{ACTIVE_LABEL()}</div>,
            accessorFn: d => d.active ? YES_LABEL() : NO_LABEL(),
            id: 'active',
            filterType: 'dropdown',
            cell: props => (<span> {props.getValue() === YES_LABEL() ?
                <Icon name={CHECKMARK_ICON} color={'green'} /> :
                <Icon name={X_ICON} color={'red'} />
            } </span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            id: 'nameCertificateTable',
            enableColumnFilter: false,
            enableSorting: false,
            cell: props => (<span>
                <Button size='tiny' basic icon onClick={() => {
                    this.setState({ certificateFields: props.row.original, detailModalVisibility: true }, () => {
                        this.setState(prevState => ({
                            ...prevState,
                            certificateFields: {
                                ...prevState.certificateFields,
                                keyStorePassword: ''
                            }
                        }), () => {
                            this.stringToJSON(this.state.certificateFields?.detail)
                        })
                    })
                }}><Icon name={INFO_ICON} /></Button>
                <Button size='tiny' basic icon onClick={() => {
                    this.setState({ certificateFields: props.row.original, modalVisibility: true }, () => {
                        this.setState(prevState => ({
                            ...prevState,
                            certificateFields: {
                                ...prevState.certificateFields,
                                keyStorePassword: ''
                            }
                        }))
                    })
                }}><Icon name={EDIT_ICON} /></Button>
                <Button size='tiny' icon color='red' onClick={() => {
                    this.setState({ certificateFields: props.row.original, confirmVisibility: true })
                }}> <Icon name={X_ICON} /></Button>
            </span>)
        }
    ];

    state = {
        certificateList: [],
        certificateFields: {},
        certificateDetails: {},
        confirmVisibility: false,
        certificateFile: undefined,
        modalVisibility: false,
        detailModalVisibility: false,
        loadingCertificateList: false,
        showPassword: false,
        certificateFormInputsErrorMsg: '',
        isCertificatePasswordError: false,
        isCertificateFileExtentionError: false,
        language: localStorage.getItem('lang') || 'en',
        acceptableExtensions: ''
    }

    onDelete = () => {
        //axios.delete('/api/certificate/' + this.state.certificateFields?.id)
        deleteCertificate(this.state.certificateFields?.id).then(() => {
            this.getCertificates()
            showSuccess(SUCCESS_MESSAGE());
            this.setState({ confirmVisibility: false, certificateFields: {} })
        }).catch(err => {
            showError(`${certificatesMessages().NOT_FETCH_CERTIFICATES} :` + this.props.t(err?.response?.data?.message ?? err.toString()))
            this.setState({ confirmVisibility: false, certificateFields: {} })
        })
    }

    getCertificates = () => {
        this.setState({ loadingCertificateList: true });
        //axios.get("/api/certificate")
        getCertificateList().then(res => {
            this.setState({ certificateList: res.data })
        }).catch(err => {
            showError(`${certificatesMessages().NOT_FETCH_CERTIFICATES} :` + this.props.t(err?.response?.data?.message ?? err.toString()))
        }).finally(() => this.setState({ loadingCertificateList: false }));
    }

    onHandleChange = (e, { name, value, checked }) => {
        this.setState(prevState => (
            {
                certificateFields: {
                    ...prevState.certificateFields,
                    [name]: value
                }
            }))
        if (checked !== undefined) {
            this.setState(prevState => (
                {
                    certificateFields: {
                        ...prevState.certificateFields,
                        [name]: checked
                    }
                }))
        }
    }

    onHandleChangeCertFile = (e) => {
        onChangeFileInput(e.target.files[0], ACCEPTABLE_CERT_EXTENSION,
            certificateFormInputsErrorMsg => this.setState({ certificateFormInputsErrorMsg }),
            certificateFile => this.setState({ certificateFile }))
    }

    handleModalClose = () => {
        this.setState({
            modalVisibility: false,
            certificateFields: {},
            detailModalVisibility: false,
            certificateDetails: {},
            certificateFile: null,
            certificateFormInputsErrorMsg: '',
            isCertificatePasswordError: false,
            isCertificateFileExtentionError: false
        })
    }

    stringToJSON = certificateDetails => {
        certificateDetails = JSON.parse(certificateDetails.substring(1, certificateDetails.length - 1))
        this.setState({ certificateDetails })
    }

    onSubmit = async () => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        let data = new FormData()
        data.append('file', this.state.certificateFile?.get('file'))
        data.append('name', this.state.certificateFields?.name)
        data.append('isActive', !!this.state.certificateFields?.active)
        if (this.state.certificateFields?.keyStorePassword) {
            data.append('keyStorePassword', await encryptData(this.state.certificateFields?.keyStorePassword))
        }
        if (this.state.certificateFields?.id) {
            //axios.put('/api/certificate/' + this.state.certificateFields?.id, data, config)
            updateCertificate(this.state.certificateFields?.id, data, config).then(() => {
                showSuccess(SUCCESS_MESSAGE());
                this.handleModalClose()
                this.getCertificates()
            }).catch(err => {
                showError(`${FAILED_ERROR_MESSAGE()} :` + this.props.t(err?.response?.data?.message ?? err.toString()))
            })
        } else {
            //axios.post('/api/certificate', data, config)
            createCertificate(data, config).then(() => {
                showSuccess(SUCCESS_MESSAGE());
                this.handleModalClose()
                this.getCertificates()
            }).catch(err => {
                if (err?.response?.data?.message === 'toDerInputStream rejects tag type 45') {
                    this.setState({ isCertificatePasswordError: true, certificateFormInputsErrorMsg: certificatesMessages().WRONG_CERTIFICATE_PASSWORD_MESSAGE }, () => {
                        setTimeout(() => {
                            this.setState({ isCertificatePasswordError: false, certificateFormInputsErrorMsg: '' })
                        }, 4000)
                    })
                }
                showError(`${FAILED_ERROR_MESSAGE()} :` + this.props.t(err?.response?.data?.message ?? err.toString()))
            })
        }
    }

    validationFields = () => {
        if (this.state.certificateFields?.id) {
            return this.state.isCertificateFileExtentionError || this.state.certificateFields?.name?.trim() < 1 ||
                this.state.certificateFields?.name === undefined
        } else {
            return this.state.isCertificateFileExtentionError || this.state.certificateFields?.name?.trim() < 1 ||
                this.state.certificateFields?.name === undefined || this.state.certificateFile === undefined ||
                this.state.certificateFile === null
        }

    }

    onTogglePassword = () =>
        this.setState(
            prevState => ({
                showPassword: !prevState.showPassword
            })
        );

    componentDidMount() {
        this.getCertificates();
        const acceptableExtensions= extensionListToAcceptAttribute(ACCEPTABLE_CERT_EXTENSION)
        this.setState({ language: localStorage.getItem('lang') || 'en' ,acceptableExtensions});
    }

    componentDidUpdate() {
        // When language is changed, table data will be renewed.
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.getCertificates())
        )
    }

    render() {
        return (
            <div>
                <div>
                    <div className='main-right-header'>
                        <div>
                            <h2> {CERTIFICATES_HEADER()}</h2>
                            <small> {certificatesMessages().CERTIFICATES_SUB_HEADER}</small>
                        </div>
                        <div className='main-right-buttons'>
                            <Button primary icon={PLUS_ICON} content={certificatesMessages().ADD_NEW_CERTIFICATE_BUTTON}
                                onClick={() => this.setState({ modalVisibility: true })} />
                        </div>

                    </div>
                    <ReactTableV8
                        columns={this.columnsRender()}
                        data={this.state.certificateList}
                        columnwiseFilterableProp={true}
                        loadingProp={this.state.loadingCertificateList}
                    />
                    <Modal open={this.state.modalVisibility} onClose={this.handleModalClose} closeIcon
                        closeOnDimmerClick={true}
                        size={'small'}>
                        <Header style={{ textAlign: 'center' }}>{certificatesMessages().CERTIFICATES_MODAL_HEADER}</Header>
                        <Segment secondary compact style={{
                            marginBottom: '-42px',
                            marginTop: '-1px',
                            marginLeft: 'auto',
                            marginRight: '22px'
                        }}>
                            <Form>
                                <Form.Field>
                                    <Form.Checkbox
                                        label={ACTIVE_LABEL()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.certificateFields && this.state.certificateFields.active}
                                        fluid={'true'}
                                        name={'active'}
                                    />
                                </Form.Field>
                            </Form>
                        </Segment>
                        <Modal.Content>
                            <Form>

                                <Form.Field required>
                                    <label>{certificatesMessages().CERTIFICATE_NAME_HEADER}</label>

                                    <Form.Input
                                        width={16}
                                        fluid
                                        name={'name'}
                                        value={this.state.certificateFields && this.state.certificateFields.name ? this.state.certificateFields.name : ""}
                                        onChange={this.onHandleChange}
                                    />

                                </Form.Field>

                                <FileSelectionForm
                                headerName = {certificatesMessages().CERTIFICATE_FILE_HEADER}
                                acceptableExtensions = {this.state.acceptableExtensions}
                                errMsg = {this.state.certificateFormInputsErrorMsg}
                                onChangeFileUpload = {this.onHandleChangeCertFile}/>
                                <Form.Field>
                                    <label>{certificatesMessages().CERTIFICATE_PASSWORD_LABEL}</label>

                                    {!(this.state.certificateFields?.id) ? <Form.Input
                                        error={this.state.isCertificatePasswordError && {
                                            content: this.state.certificateFormInputsErrorMsg,
                                            pointing: 'below'
                                        }}
                                        width={16}
                                        fluid
                                        name={'keyStorePassword'}
                                        value={this.state.certificateFields && this.state.certificateFields.keyStorePassword ?
                                            this.state.certificateFields.keyStorePassword.trim() : ''}
                                        onChange={this.onHandleChange}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        icon={
                                            <Icon
                                                name={this.state.showPassword ? EYE_SLASH_ICON : EYE_ICON}
                                                link
                                                onClick={this.onTogglePassword}
                                            />
                                        }
                                    />
                                        :
                                        <ChangePasswordInput
                                            name='keyStorePassword'
                                            error={this.state.isCertificatePasswordError && {
                                                content: this.state.certificateFormInputsErrorMsg,
                                                pointing: 'below'
                                            }}
                                            value={this.state.certificateFields?.keyStorePassword}
                                            onChange={this.onHandleChange}
                                            reset={() => this.setState(prevState => ({
                                                certificateFields: {
                                                    ...prevState.certificateFields,
                                                    keyStorePassword: null
                                                }
                                            }))}
                                        />
                                    }
                                </Form.Field>
                            </Form>

                        </Modal.Content>
                        <Modal.Actions>
                            <Button.Group>

                                <Button icon labelPosition='right' color='green' onClick={this.onSubmit}
                                    disabled={this.validationFields()}>
                                    <Icon
                                        name={USER_PLUS_ICON} /> {SAVE_BUTTON()}</Button>
                            </Button.Group>
                        </Modal.Actions>
                    </Modal>

                    <Confirm
                        open={this.state.confirmVisibility}
                        content={certificatesMessages().DELETE_CONFIRM}
                        onCancel={() => {
                            this.setState({ confirmVisibility: false, certificateFields: {}, certificateDetails: {} })
                        }}
                        onClose={() => {
                            this.setState({ confirmVisibility: false, certificateFields: {}, certificateDetails: {} })
                        }}
                        onConfirm={this.onDelete}
                        confirmButton={CONFIRM_BUTTON()}
                        cancelButton={CANCEL_BUTTON()}
                    />
                    <CertificateDetailsModal
                        detailModalVisibility={this.state.detailModalVisibility}
                        handleModalClose={this.handleModalClose}
                        certificateDetails={this.state.certificateDetails}
                    />

                </div>
            </div>
        );
    }
}

export default withTranslation()(CertificatesPage);


