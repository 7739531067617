import XPath from 'xpath';
import _ from 'lodash';
import moment from 'moment';
import fileExtension from 'file-extension';
import defaultDeviceImage from '../assets/images/DefaultDeviceImage.png';
import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import i18n from '../translate/i18n';
import {
  ONE_HOUR_IN_SECONDS,
  ONE_MINUTE_IN_SECONDS,
  PAD_LENGTH,
  TIMEOUT_TOAST_SHOW_RATE,
  WEBHOOK_AUTH_TOKEN,
  WEBHOOK_AUTH_USERNAME_PASSWORD,
  ANNOUNCEMENT_CONSTANTS,
  ONE_YEAR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_SECOND_IN_SECONDS,
  ONE_DAY_IN_MILLIS,
  ONE_HOUR_IN_MILLIS,
  ONE_MINUTE_IN_MILLIS,
  ONE_SECOND_IN_MILLIS,
  characterCheckList,
  UNIQUE_XPATH_ATTRIBUTES,
  UNIQUE_CLASS_CHAIN_ATTRIBUTES,
  UNIQUE_PREDICATE_ATTRIBUTES, FILE_HASH_CHUNK_SIZE
} from "./Constants";
import { showError, showSuccess } from './ToastHelpers';
import { Base64 } from 'js-base64';
import jwt_decode from 'jwt-decode';
import { deviceReboot } from "../api/apiCalls";
import {
  ACTIVE_LABEL,
  APP_RESTRICTIONS_HEADER,
  AUTOMATION_LABEL,
  AUTOMATION_HEADER,
  DEVELOPMENT_HEADER,
  BRAND_HEADER,
  SECONDARY_SERVERS_HEADER,
  CLIENT_IP_HEADER,
  DAYS_SMALL_LABEL,
  DAY_LABEL,
  DAY_SMALL_LABEL,
  DEVELOPMENT_LABEL,
  DEVICE_ID_HEADER,
  DEVICE_STATUS_HEADER,
  DEVICE_TAG_HEADER,
  EVERY_DAY_LABEL,
  EVERY_MONTH_LABEL,
  FREE_LABEL,
  GOOGLE_SERVICE_HEADER,
  HIDE_BUTTON,
  HOURS_LABEL,
  HOUR_LABEL,
  informationPanelMessages,
  LOADING,
  MAC_ADDRESS_HEADER,
  USERS_HEADER,
  FAVORITE_DEVICE_HEADER,
  MAINTENANCE_LABEL,
  MANUAL_LABEL,
  MANUAL_HEADER,
  methodsMessages,
  MINUTES_LABEL,
  MINUTE_LABEL,
  MODEL_HEADER,
  NEXT_LABEL,
  NONE_LABEL,
  NO_LABEL,
  N_A_LABEL,
  OFFLINE_LABEL,
  ONLINE_LABEL,
  OPERATING_SYSTEM_HEADER,
  OS_VERSION_HEADER,
  PAGE_LABEL,
  PASSIVE_LABEL,
  PREVIOUS_LABEL,
  RESERVED_BY_LABEL,
  RESERVED_HEADER,
  SCREEN_RESOLUTION_HEADER,
  BOOKABLE_HEADER,
  SCREEN_SIZE_HEADER,
  SECONDS_LABEL,
  SHOW_BUTTON,
  STATUS_HEADER,
  systemParametersMessages,
  UNKNOWN_LABEL,
  USER_HEADER,
  YES_LABEL,
  CATEGORIES_HEADER,
  NODE_LABEL,
  multiManageOperationMessages,
  NODE_INFO_LABEL,
  GROUP_LABEL
} from './UIMessages';
import {
  ADJUST_ICON,
  ANDROID_ICON,
  BOX_ICON,
  CALENDAR_ALTERNATE_OUTLINE_ICON,
  CALENDAR_OUTLINE_ICON,
  CIRCLE_THIN_ICON,
  CODE_ICON,
  EXPAND_ARROWS_ALTERNATE_ICON,
  FILTER_ICON,
  IOS_ICON,
  MOBILE_ICON,
  QUESTION_MARK_ICON,
  SERVER_ICON,
  SITEMAP_ICON,
  STAR_ICON,
  TABLET_ALTERNATE_ICON,
  TABLET_ICON,
  UNORDERED_LIST_ICON
} from './UiIcons';
import {load} from 'cheerio';
import {parseDocument} from 'htmlparser2';
import Compress from 'compress.js';
import { PrivilegeConstants } from './PrivilegeConstants';

import CryptoJS from 'crypto-js'; "../App";

const DEVICE_LIST_ICON_CLASSNAME = 'icon-deviceLists';

const compress = new Compress();

const t = (str) => i18n.t(str);

export const getDate = () => {
  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth() + 1;
  const d = today.getDate();
  const h = today.getHours();
  const mi = today.getMinutes();
  const s = today.getSeconds();
  return `${d}/${m}/${y}-${h}:${mi}:${s}`;
};

export const timeConvert = (seconds, detail) => {
  // Return a string containing the number of years, days, hours,
  // minutes, and seconds in the given numeric seconds argument.
  // The optional detail argument can limit the about of detail.
  // Note: 1 year is treated as 365.25 days to approximate "leap
  // years" TAGS: secToYMDHMS, secToDHMS
  //
  // Some Examples:
  //
  // fmt_duration(35000000)
  // returns "1 year, 39 days, 20 hours, 13 minutes, 20 seconds"
  //
  // fmt_duration(24825601)
  // returns "287 days, 8 hours, 1 second"
  //
  // fmt_duration(24825601, 3)
  // returns "287 days, 8 hours"
  //
  // fmt_duration(24825601, 1)
  // returns "less than one year"
  //
  let labels = [
      methodsMessages().YEARS_LABEL,
      methodsMessages().DAYS_LABEL,
      methodsMessages().HOURS_LABEL,
      methodsMessages().MINUTES_LABEL,
      SECONDS_LABEL()
    ],
    increments = [
      ONE_YEAR_IN_SECONDS,
      ONE_DAY_IN_SECONDS,
      ONE_HOUR_IN_SECONDS,
      ONE_MINUTE_IN_SECONDS,
      ONE_SECOND_IN_SECONDS
    ],
    result = '',
    i,
    increment,
    label,
    quantity;
  detail = detail === undefined ? increments.length : detail;
  detail = Math.min(detail, increments.length);

  for (i = 0; i < detail; i += 1) {
    increment = increments[i];
    label = labels[i];

    if (seconds >= increment) {
      quantity = Math.floor(seconds / increment);
      if (quantity === 1) {
        // if singular, strip the 's' off the end of the label
        label = label.slice(0, -1);
      }
      seconds -= quantity * increment;
      result = `${result} ${quantity} ${label},`;
    }
  }

  result = result.slice(1, -1);
  if (result === '') {
    result = NONE_LABEL();
  }
  return result;
};

export const xmlToJSON = (source) => {
  // Translates sourceXML to JSON
  let xmlDoc;
  // Replace strings with Unicode format &#012345 with #012345
  // The &# unicode format breaks the parser
  source = source.replace(/&#([0-9]{4,})/g, '#$1');
  const recursive = (xmlNode, parentPath, index) => {
    // Translate attributes array to an object
    const attrObject = {};
    for (const attribute of xmlNode.attributes || []) {
      attrObject[attribute.name] = attribute.value;
    }
    // Dot Separated path of indices
    const path = index !== undefined && `${!parentPath ? '' : parentPath + '.'}${index}`;
    return {
      children: [...xmlNode.children].map((childNode, childIndex) => recursive(childNode, path, childIndex)),
      tagName: xmlNode.tagName,
      attributes: attrObject,
      xpath: getOptimalXPath(xmlDoc, xmlNode),
      path
    };
  };
  xmlDoc = new DOMParser().parseFromString(source, 'application/xml');
  const sourceXML = xmlDoc.children[0];
  return recursive(sourceXML);
};

/**
 * Get an optimal XPath for a DOMNode
 * @param {*} domNode {DOMNode}
 */
 export const getOptimalXPath = (doc, domNode) => {
  // Attributes on nodes that we know are unique to the node
  const uniqueAttributes = ['name', 'content-desc', 'id', 'accessibility-id'];
  try {
    // BASE CASE #1: If this isn't an element, we're above the root, return empty string
    if (!domNode.tagName || domNode.nodeType !== 1) {
      return '';
    }
    // BASE CASE #2: If this node has a unique attribute, return an absolute XPath with that attribute
    for (const attrName of uniqueAttributes) {
      const attrValue = domNode.getAttribute(attrName);
      if (attrValue) {
        let xpath = `//${domNode.tagName || '*'}[@${attrName}="${attrValue}"]`;
        let othersWithAttr;

        // If the XPath does not parse, move to the next unique attribute
        try {
          othersWithAttr = XPath.select(xpath, doc);
          // If the attribute isn't actually unique, get it's index too
          if (othersWithAttr.length > 1) {
            const index = othersWithAttr.indexOf(domNode);
            xpath = `(${xpath})[${index + 1}]`;
          }
          return xpath;
        } catch (ign) {
          /**/
        }
      }
    }

    // Get the relative xpath of this node using tagName
    let relativeXpath = `/${domNode.tagName}`;

    // If this node has siblings of the same tagName, get the index of this node
    if (domNode.parentNode) {
      // Get the siblings
      const childNodes = Array.prototype.slice
        .call(domNode.parentNode.childNodes, 0)
        .filter((childNode) => childNode.nodeType === 1 && childNode.tagName === domNode.tagName);

      // If there's more than one sibling, append the index
      if (childNodes.length > 1) {
        const index = childNodes.indexOf(domNode);
        relativeXpath += `[${index + 1}]`;
      }
    }

    // Make a recursive call to this nodes parents and prepend it to this xpath
    return getOptimalXPath(doc, domNode.parentNode) + relativeXpath;
  } catch (ign) {
    // If there's an unexpected exception, abort and don't get an XPath
    return null;
  }
};

export const findKeyValueCount = (key, value, obj) => {
  let count = 0;
  const keys = Object.keys(obj);
  keys.forEach(function (k) {
    const v = obj[k];
    if (typeof v === 'object') {
      count += findKeyValueCount(key, value, v);
    } else if (k === key && v === value) {
      count += 1;
    } else {
      //invalid data
    }
  });
  return count;
};

export const isUnique = (key, value, obj) => findKeyValueCount(key, value, obj) === 1;

export const iconSelector = (string) => {
  switch (string) {
    case 'brand':
      return MOBILE_ICON;
    case 'deviceStatus':
      return CIRCLE_THIN_ICON;
    case 'clientIp':
      return SITEMAP_ICON;
    case 'os':
      return TABLET_ICON;
    case 'oSVersion':
      return TABLET_ALTERNATE_ICON;
    case 'Reserved':
      return CALENDAR_ALTERNATE_OUTLINE_ICON;
    case 'reservedByMe':
      return CALENDAR_OUTLINE_ICON;
    case 'Automation':
      return SERVER_ICON;
    case 'Manual':
      return ADJUST_ICON;
    case 'screenSize':
      return EXPAND_ARROWS_ALTERNATE_ICON;
    case 'screenResolution':
      return EXPAND_ARROWS_ALTERNATE_ICON;
    case 'favorite':
      return STAR_ICON;
    case 'categories':
      return UNORDERED_LIST_ICON;
    case 'application':
      return BOX_ICON;
    case 'appVersion':
      return CODE_ICON;
    default:
      return FILTER_ICON;
  }
};

export const stringSelector = (string) => {
  switch (string) {
    case 'deviceStatus':
      return DEVICE_STATUS_HEADER();
    case 'clientIp':
      return SECONDARY_SERVERS_HEADER();
    case 'os':
      return OPERATING_SYSTEM_HEADER();
    case 'oSVersion':
      return OS_VERSION_HEADER();
    case 'screenSize':
      return SCREEN_SIZE_HEADER();
    case 'screenResolution':
      return SCREEN_RESOLUTION_HEADER();
    case 'favorite':
      return methodsMessages().FAVORITE_DEVICE_LABEL;
    case 'Reserved':
      return RESERVED_HEADER();
    case 'Manual':
      return MANUAL_HEADER();
    case 'Automation':
      return AUTOMATION_HEADER();
    case 'Development':
      return DEVELOPMENT_HEADER();
    case 'brand':
      return BRAND_HEADER();
    case 'reservedByMe':
      return methodsMessages().RESERVED_BY_ME_LABEL;
    case 'nodeList':
      return NODE_LABEL();
    case 'groupList': 
      return GROUP_LABEL();
    default:
      return _.capitalize(string);
  }
};
export const stringSelector2 = (string) => {
  switch (string) {
    case 'deviceId':
      return DEVICE_ID_HEADER();
    case 'brand':
      return BRAND_HEADER();
    case 'deviceStatus':
      return STATUS_HEADER();
    case 'deviceModel':
      return MODEL_HEADER();
    case 'os':
      return OPERATING_SYSTEM_HEADER();
    case 'oSVersion':
      return OS_VERSION_HEADER();
    case 'clientIp':
      return CLIENT_IP_HEADER();
    case 'user':
      return USER_HEADER();
    case 'marketName':
      return methodsMessages().DEVICE_MARKET_NAME_LABEL;
    case 'specificName':
      return DEVICE_TAG_HEADER();
    case 'screenSize':
      return SCREEN_SIZE_HEADER();
    case 'screenResolution':
      return SCREEN_RESOLUTION_HEADER();
    case 'bookable':
      return BOOKABLE_HEADER();
    case 'macAddress':
      return MAC_ADDRESS_HEADER();
    case 'users':
      return USERS_HEADER();
    case 'Reserved':
      return RESERVED_HEADER();
    case 'Development':
      return DEVELOPMENT_HEADER();
    case 'Automation':
      return AUTOMATION_HEADER();
    case 'Manual':
      return MANUAL_HEADER();
    case 'categories':
      return CATEGORIES_HEADER();
    case 'favorite':
      return FAVORITE_DEVICE_HEADER();
    case 'googleService':
      return GOOGLE_SERVICE_HEADER();
    case 'offlineReservedInfo':
      return methodsMessages().OFFLINE_RESERVED_INFO_LABEL;
    case 'ReservedBy':
      return RESERVED_BY_LABEL();
    case 'node':
      return NODE_INFO_LABEL();
    default:
      return _.capitalize(string);
  }
};

export const valueSelector = (array) => {
  if (array.length === 1) {
    if (array[0].toString() === '0') {
      return ONLINE_LABEL();
    } else if (array[0].toString() === '1') {
      return OFFLINE_LABEL();
    } else if (array[0].toString() === '2') {
      return MAINTENANCE_LABEL();
    } else if (array[0].toString() === 'true') {
      return YES_LABEL();
    } else if (array[0].toString() === 'false') {
      return NO_LABEL();
    } else {
      return _.capitalize(array[0]);
    }
  } else if (array.length > 1) {
    const capitalized = array.map((el) => _.capitalize(el));
    return capitalized.join(', ');
  } else {
    //nothing to do
    return '';
  }
};

export const totalDeviceTextSelector = (value = ['x']) => {
  if (value[0] === 0) {
    return ONLINE_LABEL();
  } else if (value[0] === 1) {
    return OFFLINE_LABEL();
  } else {
    return '';
  }
};

export const stringSelector3 = (string) => {
  switch (string) {
    case 'deviceId':
      return DEVICE_ID_HEADER();
    case 'imei':
      return 'IMEI';
    case 'iccid':
      return 'ICCID';
    case 'battery':
      return methodsMessages().BATTERY_STATUS_LABEL;
    case 'internet':
      return methodsMessages().INTERNET_STATUS_LABEL;
    case 'remoteDebug':
      return methodsMessages().REMOTE_DEBUG_ADDRESS_LABEL;
    case 'wifi':
      return informationPanelMessages().WIFI_STATUS_HEADER;
    case 'gps':
      return informationPanelMessages().GPS_STATUS_HEADER;
    case 'mobileData':
      return methodsMessages().MOBILE_DATA_STATUS;
    default:
      return _.capitalize(string);
  }
};


export const cardContent = (deviceStatus, status, offlineReservedUsername = '') => {
  const busyViewer = (reserved, automation, manual, development, reservedBy) => {
    let busyText = '';
    if (reserved) {
      busyText += `${`${RESERVED_BY_LABEL()} ${_.capitalize(reservedBy)}`} / `;
      if (development && manual && automation) {
        busyText += `${DEVELOPMENT_LABEL()}\n${MANUAL_LABEL()} & ${AUTOMATION_LABEL()}`;
      } else if (development && manual) {
        busyText += `\n${DEVELOPMENT_LABEL()} & ${MANUAL_LABEL()}`;
      } else if (development && automation) {
        busyText += `\n${DEVELOPMENT_LABEL()} & ${AUTOMATION_LABEL()}`;
      } else if (manual && automation) {
        busyText += `\n${MANUAL_LABEL()} & ${AUTOMATION_LABEL()}`;
      } else if (development) {
        busyText += DEVELOPMENT_LABEL();
      } else if (manual) {
        busyText += `${MANUAL_LABEL()} ${methodsMessages().TEST_LABEL}`;
      } else if (automation) {
        busyText += `${AUTOMATION_LABEL()} ${methodsMessages().TEST_LABEL}`;
      } else {
        busyText = `${RESERVED_BY_LABEL()} ${_.capitalize(reservedBy)}`;
      }
    } else {
      busyText += `${methodsMessages().BUSY_LABEL} / `;
      if (development && manual && automation) {
        busyText += `${DEVELOPMENT_LABEL()}\n${MANUAL_LABEL()} & ${AUTOMATION_LABEL()}`;
      } else if (development && manual) {
        busyText += `${DEVELOPMENT_LABEL()} & ${MANUAL_LABEL()}`;
      } else if (development && automation) {
        busyText += `\n${DEVELOPMENT_LABEL()} & ${AUTOMATION_LABEL()}`;
      } else if (manual && automation) {
        busyText += `${MANUAL_LABEL()} & ${AUTOMATION_LABEL()}`;
      } else if (development) {
        busyText += DEVELOPMENT_LABEL();
      } else if (manual) {
        busyText += `${MANUAL_LABEL()} ${methodsMessages().TEST_LABEL}`;
      } else if (automation) {
        busyText += `${AUTOMATION_LABEL()} ${methodsMessages().TEST_LABEL}`;
      } else {
        //unhandled status
      }
    }
    return busyText;
  };

  if (deviceStatus === 1) {
    if (offlineReservedUsername) {
      return `${MAINTENANCE_LABEL()} (${offlineReservedUsername})`;
    } else {
      return OFFLINE_LABEL();
    }
  } else if (deviceStatus === 0) {
    if (status.Automation || status.Manual || status.Reserved || status.Development) {
      return busyViewer(status.Reserved, status.Automation, status.Manual, status.Development, status.ReservedBy);
    } else {
      return `${ONLINE_LABEL()} / ${FREE_LABEL()}`;
    }
  } else {
    return methodsMessages().UNKNOWN_STATUS;
  }
};

export const millisConverter = (duration) => {
  function parseDuration(duration2Parse = duration) {
    let remain = duration2Parse;

    const days = Math.floor(remain / ONE_DAY_IN_MILLIS);
    remain = remain % ONE_DAY_IN_MILLIS;

    const hours = Math.floor(remain / ONE_HOUR_IN_MILLIS);
    remain = remain % ONE_HOUR_IN_MILLIS;

    const minutes = Math.floor(remain / ONE_MINUTE_IN_MILLIS);
    remain = remain % ONE_MINUTE_IN_MILLIS;

    const seconds = Math.floor(remain / ONE_SECOND_IN_MILLIS);
    remain = remain % ONE_SECOND_IN_MILLIS;

    const milliseconds = remain;

    return {
      days,
      hours,
      minutes,
      seconds,
      milliseconds
    };
  }

  const formatTime = (o, useMilli) => {
    const parts = [];
    if (o.days) {
      let ret = o.days;
      if (o.days !== 1) {
        ret += ` ${methodsMessages().DAYS_LABEL}`;
      } else {
        ret += ` ${DAY_LABEL()}`;
      }
      parts.push(ret);
    }
    if (o.hours) {
      let ret = o.hours;
      if (o.hours !== 1) {
        ret += ` ${methodsMessages().HOURS_LABEL}`;
      } else {
        ret += ` ${methodsMessages().HOUR_LABEL}`;
      }
      parts.push(ret);
    }
    if (o.minutes) {
      let ret = o.minutes;
      if (o.minutes !== 1) {
        ret += ` ${methodsMessages().MINUTES_LABEL}`;
      } else {
        ret += ` ${methodsMessages().MINUTE_LABEL}`;
      }
      parts.push(ret);
    }
    if (o.seconds) {
      let ret = o.seconds;
      if (o.seconds !== 1) {
        ret += ` ${SECONDS_LABEL()}`;
      } else {
        ret += ` ${methodsMessages().SECOND_LABEL}`;
      }
      parts.push(ret);
    }
    if (useMilli && o.milliseconds) {
      let ret = o.milliseconds;
      if (o.milliseconds !== 1) {
        ret += ` ${methodsMessages().MILLISECONDS_LABEL}`;
      } else {
        ret += ` ${methodsMessages().MILLISECOND_LABEL}`;
      }
      parts.push(ret);
    }
    if (parts.length === 0) {
      return methodsMessages().INSTANTLY_LABEL;
    } else {
      return parts.join(' ');
    }
  };

  const time = parseDuration(duration);
  return formatTime(time, true);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatValuesForSysParameterKeys = (key) => {
  //Return statements are turned in translation for the purpose of creating Turkish words searchable.
  switch (key) {
    case 'isCaptchaActive':
      return methodsMessages().CAPTCHA_LABEL;
    case 'failedLoginLowerLimit':
      return methodsMessages().LOGIN_FAILED_LOWER_LIMIT;
    case 'failedLoginUpperLimit':
      return methodsMessages().LOGIN_FAILED_UPPER_LIMIT;
    case 'blockedUserTimeout':
      return methodsMessages().USER_BLOCKED_TIMEOUT;
    case 'isTwoFactorLoginActive':
      return methodsMessages().TWO_FACTOR_LOGIN;
    case 'isAppRestrictionsEnabled':
      return APP_RESTRICTIONS_HEADER();
    case 'ldapConnectionTimeOut':
      return methodsMessages().LDAP_CONNECTION_TIMEOUT;
    case 'deviceInteractionScreenTimeout':
      return methodsMessages().DEVICE_INTERACTION_TIMEOUT;
    case 'activeWindowScreenTimeout':
      return methodsMessages().DEVICE_ACTIVE_BROWSER_WINDOW_TIMEOUT;
    case 'captchaMode':
      return methodsMessages().CAPTCHA_MODE;
    case 'singularReservationLimitPerUser':
      return methodsMessages().SINGULAR_RESERVATION_LIMIT;
    case 'totalReservationLimitPerUser':
      return methodsMessages().TOTAL_RESERVATION_LIMIT;
    case 'apiKeyFailureLimit':
      return methodsMessages().API_KEY_FAILURE_LIMIT;
    case 'apiKeyBlockTime':
      return methodsMessages().API_KEY_BLOCK_TIME;
    case 'poolUserConcurrentAccessLimit':
      return methodsMessages().POOL_USER_CONCURRENT_ACCESS_LIMIT;
    case 'lastUsedDeviceCount':
      return methodsMessages().LAST_USED_DEVICE_COUNT;
    case 'isChatAppEnabled':
      return methodsMessages().AVAILABILITY_OF_CHAT_APPLICATION;
    case 'chatHistoryDayLimit':
      return methodsMessages().CHAT_HISTORY_DAY_LIMIT;
    case 'reservationDroppedTime':
      return methodsMessages().RESERVATION_DROPPED_TIME;
    case 'visibilityOfBusyDevice':
      return methodsMessages().BUSY_DEVICE_VISIBILITY;
    case 'allowLocalUserLogin':
      return methodsMessages().ALLOW_LOCAL_USER_LOGIN;
    case 'allowLocalAdminUser':
      return methodsMessages().ALLOW_LOCAL_ADMIN_USER;
    case 'allowLocalUserAutomationKeyUsage':
      return methodsMessages().ALLOW_LOCAL_USER_AUTOMATION_KEY_USAGE;
    case 'allowLocalUserApiKeyUsage':
      return methodsMessages().ALLOW_LOCAL_USER_API_KEY_USAGE;
    case 'allowLocalUserTestAutomation':
      return methodsMessages().ALLOW_LOCAL_USER_TEST_AUTOMATION;
    case 'allowToUseReservedDevice':
      return methodsMessages().ALLOW_TO_USER_USE_RESERVED_DEVICE;
    case 'exterminateUser':
      return methodsMessages().EXTERMINATE_USERS;
    case 'dormancyDuration':
      return methodsMessages().DORMANCY_DURATION;
    case 'androidDevicesDefaultVideoStreamQuality':
      return methodsMessages().ANDROID_DEVICE_QUALITY
    case 'iosDevicesDefaultVideoStreamQuality':
      return methodsMessages().IOS_DEVICE_QUALITY
    case 'dormancyCheckExclusions':
      return methodsMessages().DORMANCY_CHECK_EXCLUSION
    case 'allowAnyNodeToRegister':
      return methodsMessages().ALLOW_ANY_NODE_TO_REGISTER
    case 'licenseReminderDay': 
      return methodsMessages().LICENSE_EXPIRATION_REMINDER
    case 'licenseReminderRecipients': 
      return methodsMessages().LICENSE_EXPIRATION_REMINDER_RECIPIENTS
    default:
      return key;
  }
};

export const formatValuesForSysParameterValues = (param) => {
  if (param.parameterKey === 'captchaMode') {
    switch (param.parameterValue) {
      case '0':
        return systemParametersMessages().ARITHMETIC_CAPTCHA_OPTION;
      case '1':
        return systemParametersMessages().ALPHANUMERIC_CAPTCHA_OPTION;
      case '2':
        return systemParametersMessages().GIF_CAPTCHA_OPTION;
      default:
        return param.parameterValue;
    }
  } else if(param.parameterKey === 'exterminateUser'){
    switch(param.parameterValue){
      case '0':
        return systemParametersMessages().DO_NOTHING;
      case '1':
        return systemParametersMessages().DISABLE_USER;
      case '2':
        return systemParametersMessages().DELETE_USER;
      default:
        return param.parameterValue;
    }
  } else if (param.parameterValue) {
    switch (param.parameterValue) {
      case 'true':
        return ACTIVE_LABEL();
      case 'false':
        return PASSIVE_LABEL();
      case 'int':
        return methodsMessages().INTEGER_LABEL;
      default:
        return param.parameterValue;
    }
  }
  else {
    switch (param) {
      case 'true':
        return ACTIVE_LABEL();
      case 'false':
        return PASSIVE_LABEL();
      case 'int':
        return methodsMessages().INTEGER_LABEL;
      case 'boolean':
        return methodsMessages().BOOLEAN_LABEL;
      case 'string':
        return methodsMessages().TEXT_LABEL;
      default:
        return param;
    }
  }
};

export const formatValuesManualTestSession = (key) => {
  //Return statements are turned in translation for the purpose of creating Turkish words searchable.
  switch (key) {
    case 'DONE':
      return methodsMessages().MANUAL_TEST_SESSION_STATUS_DONE;
    case 'IN_PROGRESS':
      return methodsMessages().MANUAL_TEST_SESSION_STATUS_IN_PROGRESS;
    case 'TIMEOUT':
      return methodsMessages().MANUAL_TEST_SESSION_STATUS_TIMEOUT;
    case 'ABORTED':
      return methodsMessages().MANUAL_TEST_SESSION_STATUS_ABORTED;
    case 'DEVICE_ERROR':
      return methodsMessages().MANUAL_TEST_SESSION_STATUS_DEVICE_ERROR;
    default:
      return key;
  }
};

export const renameFile = (resizedFile, newName) => {
    /*
        When we resize an image, although we and Java file type detector cannot detect explicitly, the actual type of the image is shifted
    to "jpeg" (we suppose it occurs in binary side in third-part resizing library that we cannot see explicitly but Tika can detect it successfully)
    and this case brings about some problems in backend side which is due to the conflict btw extension-originalType
    To avert this problem, we set the file type and the file extension as "jpeg", just in case. This is the most effective
    and reasonable solution we developed.
    */
    return new File([resizedFile], `${newName.slice(0, (-fileExtension(newName).length) - 1)}.jpeg`, {
        type: 'image/jpeg',
        lastModified: resizedFile.lastModified
    });
}

export async function resizeImage(file, size = 1, quality = 1, maxWidth = 1920, maxHeight = 1920, resize = true) {

    const resizedImage = await compress.compress([file], {
        // the max size in MB, defaults to 2MB
        size,
        // the quality of the image, max is 1
        quality,
        // the max width of the output image, defaults to 1920px
        maxWidth,
        // the max height of the output image, defaults to 1920px
        maxHeight,
        resize
    })


    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt)
    return renameFile(resizedFile, file.name);
}

export const formatXValues = (type, tick) => {
  if (type === 'daily') {
    return moment(tick).format('MMM DD');
  } else if (type === 'monthly') {
    return moment(tick).format('YYYY MMM');
  } else {
    //invalid or unhandled type
  }

  return '';
};

export const formatDeviceNames = (tick) => {
  const index = tick.lastIndexOf(' ');
  tick = tick.slice(0, index) + tick.slice(index).replace(' ', '\n');
  return tick;
};

export const formatTickValues = (type) => {
  if (type === 'daily') {
    return EVERY_DAY_LABEL();
  } else if (type === 'monthly') {
    return EVERY_MONTH_LABEL();
  } else {
    return '';
  }
};

export const renameKey = (obj, oldKey, newKey) => {
  // check if old key = new key
  if (oldKey !== newKey) {
    // modify old key
    Object.defineProperty(
      obj,
      newKey,
      // fetch description from object
      Object.getOwnPropertyDescriptor(obj, oldKey)
    );

    // delete old key
    delete obj[oldKey];
  }
  return obj;
};
export const extractUsersFromAnotherUserSet = (firstSet, secondSet) => {
  const secondSetNames = secondSet.map(user => user.userName);
  return firstSet.filter(user => !secondSetNames.includes(user.userName));
};
export const calculateDifference = (arr1, arr2) => {
  return arr1.map((value, index) => {
      const correspondingValue = arr2[index];
      return correspondingValue ? correspondingValue - value : null;
  });
};
export const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / ONE_DAY_IN_SECONDS);
  const h = Math.floor((seconds % ONE_DAY_IN_SECONDS) / ONE_HOUR_IN_SECONDS);
  const m = Math.floor((seconds % ONE_HOUR_IN_SECONDS) / ONE_MINUTE_IN_SECONDS);
  const s = Math.floor(seconds % ONE_MINUTE_IN_SECONDS);

  const dDisplay = d > 0 ? d + (d === 1 ? ` ${DAY_SMALL_LABEL()}, ` : ` ${DAYS_SMALL_LABEL()} `) : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ` ${HOUR_LABEL()}, ` : ` ${HOURS_LABEL()} `) : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ` ${MINUTE_LABEL()}, ` : ` ${MINUTES_LABEL()} `) : '';
  const sDisplay =
    s > 0
      ? s + (s === 1 ? ` ${methodsMessages().SECOND_SMALL_LABEL}, ` : ` ${methodsMessages().SECONDS_SMALL_LABEL} `)
      : '';
  return dDisplay + hDisplay + mDisplay + sDisplay || N_A_LABEL();
};

export const secondsToDhmsShort = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / ONE_DAY_IN_SECONDS);
  const h = Math.floor((seconds % ONE_DAY_IN_SECONDS) / ONE_HOUR_IN_SECONDS);
  const m = Math.floor((seconds % ONE_HOUR_IN_SECONDS) / ONE_MINUTE_IN_SECONDS);
  const s = Math.floor(seconds % ONE_MINUTE_IN_SECONDS);

  const dDisplay = d > 0 ? d + ':' : '';
  const hDisplay = h > 0 ? h + ':' : '';
  const mDisplay = m > 0 ? m + ':' : '';
  const sDisplay = s > 0 ? s + ':' : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const secondsToDhmsNormal = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / ONE_DAY_IN_SECONDS);
  const h = Math.floor((seconds % ONE_DAY_IN_SECONDS) / ONE_HOUR_IN_SECONDS);
  const m = Math.floor((seconds % ONE_HOUR_IN_SECONDS) / ONE_MINUTE_IN_SECONDS);
  const s = Math.floor(seconds % ONE_MINUTE_IN_SECONDS);

  const dDisplay = d > 0 ? d + ' d ' : '';
  const hDisplay = h > 0 ? h + ' h ' : '';
  const mDisplay = m > 0 ? m + ' m ' : '';
  const sDisplay = s > 0 ? s + ' s ' : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const beautifyFileName = (file) => {
  if (file.startsWith('appium')) {
    return methodsMessages().APPIUM_LOG_FILE;
  } else if (file.startsWith('video')) {
    return methodsMessages().VIDEO_RECORD_FILE;
  } else if (file.startsWith('device')) {
    return methodsMessages().DEVICE_LOG_FILE;
  } else if (file.startsWith('traffic')) {
    return methodsMessages().NETWORK_TRAFFIC_FILE;
  } else if (file.startsWith('screenshot')) {
    return methodsMessages().SCREENSHOT_FILE;
  } else {
    return UNKNOWN_LABEL();
  }
};

export const beautifyFileDate = (file) => {
  const datePart = file.substring(file.indexOf('_') + 1, file.lastIndexOf('.'));
  return `(${moment(datePart, 'YYYY-MM-DD_HH-mm-ss.SSS').format('DD/MM/YYYY HH:mm:ss.SSS')})`;
};

export const timelineLabels = (desiredStartTime, interval, period) => {
  const periodsInADay = moment.duration(1, 'day').as(period);

  const timeLabels = [];
  const startTimeMoment1 = moment(desiredStartTime, 'hh:mm');
  for (let i = 0; i < periodsInADay; i += interval) {
    startTimeMoment1.add(i === 0 ? 0 : interval, period);
    const startTimeMoment2 = moment(startTimeMoment1).add(1, 'hour');
    timeLabels.push(`${startTimeMoment1.format('HH:mm')} - ${startTimeMoment2.format('HH:mm')}`);
  }
  return timeLabels;
};

export const tickFormatter = (tick) => {
  tick = Number(tick);
  const h = Math.floor((tick % ONE_DAY_IN_SECONDS) / ONE_HOUR_IN_SECONDS);
  const m = Math.floor((tick % ONE_HOUR_IN_SECONDS) / ONE_MINUTE_IN_SECONDS);
  const s = Math.floor(tick % ONE_MINUTE_IN_SECONDS);

  const hDisplay = h > 0 ? h + ' h ' : '';
  const mDisplay = m > 0 ? m + ' m ' : '';
  const sDisplay = s > 0 ? s + ' s ' : '';
  return hDisplay + mDisplay + sDisplay;
};

export const clearLocalStorage = () => {
  const lang = localStorage.getItem('lang');
  const timeoutModalState = localStorage.getItem('timeoutModalState');
  localStorage.clear();
  localStorage.setItem('lang', lang);
  localStorage.setItem('timeoutModalState', timeoutModalState);
};

export const logoutProcess = () => {
  setTimeout(() => {
    clearLocalStorage();
  }, 100);
  setTimeout(() => {
    window.location = '/login';
  }, 1000);
};

export const filterBarGenerator = (deviceList) => {
  const findForOs = (devices, neededValue) => {
    const osList = _.sortedUniq(
      devices.map((device) => {
        if (device.os.toLowerCase() === 'android') {
          return 'Android';
        } else if (device.os.toLowerCase() === 'ios') {
          return 'iOS';
        } else {
          return device.os;
        }
      })
    );

    const obj = {};
    for (const osListKey in osList) {
      obj[osList[osListKey]] = [];
    }
    deviceList.forEach((device) => {
      Object.keys(obj).map((key) => {
        if (key.toLowerCase() === device.os.toLowerCase() && !obj[key].includes(device[neededValue])) {
          obj[key].push(device[neededValue]);
        }
      });
    });
    return obj;
  };

    const filterBar = {
        brand: _.compact(_.sortedUniq(deviceList.map(device => _.capitalize(device.brand)))),
        nodeList: [],
        groupList: [],
        deviceStatus: ['Online', 'Offline', 'Maintenance'],
        reservedByMe: ['Reserved by me'],
        oSVersion: findForOs(deviceList, 'oSVersion'),
        status: {
            Reserved: [true, false],
            Automation: [true, false],
            Manual: [true, false],
            Development: [true, false]
        },
        os: _.compact(_.sortedUniq(deviceList.map(device => {
            if (device.os.toLowerCase() === 'android') {
                return 'Android'
            } else if (device.os.toLowerCase() === 'ios') {
                return 'iOS'
            } else {
                return device.os
            }
        }))),
        screenSize: findForOs(deviceList, 'screenSize'),
        screenResolution: findForOs(deviceList, 'screenResolution'),
        favorite: [true, false],
        categories: _.uniq(deviceList.reduce((categoryNames, device) => {
            categoryNames = categoryNames.concat(device.categories.map(c => c.name));
            return categoryNames;
        }, []))
        // todo aÃ§ bunu
    }

  if (filterBar.oSVersion.Android) {
    //null check
    filterBar.oSVersion.Android = _.compact(filterBar.oSVersion.Android);
  }
  if (filterBar.oSVersion.iOS) {
    //null check
    filterBar.oSVersion.iOS = _.compact(filterBar.oSVersion.iOS);
  }
  if (filterBar.screenSize.Android) {
    //null check
    filterBar.screenSize.Android = _.compact(filterBar.screenSize.Android);
  }
  if (filterBar.screenSize.iOS) {
    //null check
    filterBar.screenSize.iOS = _.compact(filterBar.screenSize.iOS);
  }
  if (filterBar.screenResolution.Android) {
    //null check
    filterBar.screenResolution.Android = _.compact(filterBar.screenResolution.Android);
  }
  if (filterBar.screenResolution.iOS) {
    //null check
    filterBar.screenResolution.iOS = _.compact(filterBar.screenResolution.iOS);
  }

  if (filterBar.oSVersion.Android && filterBar.oSVersion.iOS) {
    //find same values
    const sameValues = filterBar.oSVersion.Android.filter((val) => filterBar.oSVersion.iOS.includes(val));
    //delete same values
    filterBar.oSVersion.Android = filterBar.oSVersion.Android.filter((value) => !sameValues.includes(value));
  }
  if (filterBar.screenSize.Android && filterBar.screenSize.iOS) {
    //find same values
    const sameValues = filterBar.screenSize.Android.filter((val) => filterBar.screenSize.iOS.includes(val));
    //delete same values
    filterBar.screenSize.Android = filterBar.screenSize.Android.filter((value) => !sameValues.includes(value));
  }
  if (filterBar.screenResolution.Android && filterBar.screenResolution.iOS) {
    //find same values
    const sameValues = filterBar.screenResolution.Android.filter((val) => filterBar.screenResolution.iOS.includes(val));
    //delete same values
    filterBar.screenResolution.Android = filterBar.screenResolution.Android.filter(
      (value) => !sameValues.includes(value)
    );
  }


    deviceList?.forEach((device)=>{
        if(!filterBar.nodeList.some(slv => slv.id === device?.node?.id)){
            filterBar.nodeList.push(device?.node)
        }
    })
    
     deviceList?.forEach((device)=>{
      device.groups?.forEach(group => {
        if(!filterBar.groupList.some(slv => slv.id === group?.id)){
          filterBar.groupList.push(group)
      }})
   })

    const newFilterBar = {
        brand: {},
        deviceStatus: {},
        oSVersion: {},
        status: {},
        os: {},
        screenSize: {},
        screenResolution: {},
        categories: {},
        nodeList:[...filterBar.nodeList],
        groupList:[...filterBar.groupList]
    };

    Object.keys(filterBar).filter(filterType => 
      filterType !== 'oSVersion' && 
      filterType !== 'nodeList' && 
      filterType !== 'groupList' && 
      filterType !== 'status' && 
      filterType !== 'screenSize' && 
      filterType !== 'screenResolution')
      .forEach(key => {
        const valueArray = filterBar[key];
        const valueObject = {};
        valueArray.forEach(item => {
            valueObject[item] = false
        });
        newFilterBar[key] = valueObject
    });

  Object.keys(filterBar.oSVersion).forEach((key) => {
    const valueArray = filterBar.oSVersion[key];
    const valueObject = {};
    valueArray.forEach((item) => {
      valueObject[item] = false;
    });
    newFilterBar.oSVersion[key] = valueObject;
  });

  Object.keys(filterBar.screenSize).forEach((key) => {
    const valueArray = filterBar.screenSize[key];
    const valueObject = {};
    valueArray.forEach((item) => {
      valueObject[item] = false;
    });
    newFilterBar.screenSize[key] = valueObject;
  });

  Object.keys(filterBar.screenResolution).forEach((key) => {
    const valueArray = filterBar.screenResolution[key];
    const valueObject = {};
    valueArray.forEach((item) => {
      valueObject[item] = false;
    });
    newFilterBar.screenResolution[key] = valueObject;
  });
  
  Object.keys(filterBar.status).forEach((key) => {
    const valueArray = filterBar.status[key];
    const valueObject = {};
    valueArray.forEach((item) => {
      valueObject[item] = false;
    });
    newFilterBar.status[key] = valueObject;
  });

  const orderedBrand = {};
  Object.keys(newFilterBar.brand)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    .forEach(function (key) {
      orderedBrand[key] = newFilterBar.brand[key];
    });
  newFilterBar.brand = orderedBrand;

  const orderedCategories = {};
  Object.keys(newFilterBar.categories)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    .forEach(function (key) {
      orderedCategories[key] = newFilterBar.categories[key];
    });
  newFilterBar.categories = orderedCategories;

  return newFilterBar;
};

export const changeLabel = (key) => {
  if (key === 'true') {
    return YES_LABEL();
  } else if (key === 'false') {
    return NO_LABEL();
  } else {
    return t(key);
  }
};

export const orderDevices = (deviceList = []) => {
  deviceList.sort((a, b) => {
    const sortByFavorite = b.favorite - a.favorite; // boolean
    const sortByDeviceStatus = a.deviceStatus - b.deviceStatus; // number
    const sortByBrand = a.brand?.localeCompare(b.brand); // string
    const sortByDeviceModel = a.deviceModel?.localeCompare(b?.deviceModel); // string
    const sortByDeviceId = a.deviceId?.localeCompare(b?.deviceId); // string
    // order priority
    return sortByFavorite || sortByDeviceStatus || sortByBrand || sortByDeviceModel || sortByDeviceId;
  });
  return deviceList;
};

// added for files with the same package name but different file types
// must do it "{appPackage}.slice(0, -4)" for making server request or showing to end user
// this method often uses with "groupBy" method
export const editPackageName = (allApps) => {
  allApps.forEach((app) => {
    if (app.appOs === 'android') {
      app.appPackage = app.appPackage + '-apk';
    }
    if (app.appOs === 'ios') {
      app.appPackage = app.appPackage + '-ipa';
    }
  });
  return allApps;
};

export const parseJwt = (token) => jwt_decode(token.token);

export const androidOrIos = (fileName) => {
  const extension = fileExtension(fileName);
  if (['apk', 'aab', 'apks'].includes(extension.toLowerCase())) {
    return 'Android';
  } else if (extension.toLowerCase() === 'ipa') {
    return 'IOS';
  } else {
    return UNKNOWN_LABEL();
  }
};
const latitudeRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
const longitudeRegex = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const validateLocationInput = (latitude, longitude, name) => {
  let isValid = true;
  if (!name?.trim().length) {
    showError(methodsMessages().LOCATION_INPUT_NAME_EMPTY_ERROR_MESSAGE);
    isValid = false;
  }
  if (!latitudeRegex.test(latitude)) {
    showError(methodsMessages().LOCATION_INPUT_LATITUTE_ERROR_MESSAGE);
    isValid = false;
  }
  if (!longitudeRegex.test(longitude)) {
    showError(methodsMessages().LOCATION_INPUT_LONGITUDE_ERROR_MESSAGE);
    isValid = false;
  }
  return isValid;
}

const urlRegex = /^(https?:\/\/)?((\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}|(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6})(:((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4})))?(\/[\w/.-]+)?$/;
export const validateWebhookInput = ({ name, eventType, url, authMethod, username, tokenHeader }) => {
  let isNotValid = false;
  if (!name?.trim().length) {
    isNotValid = true;
  } else if (!eventType?.length) {
    isNotValid = true;
  } else if (!urlRegex.test(url)) {
    isNotValid = true;
  } else if (authMethod === WEBHOOK_AUTH_USERNAME_PASSWORD && !username?.trim().length) {
    isNotValid = true;
  } else if (authMethod === WEBHOOK_AUTH_TOKEN && !tokenHeader?.trim().length) {
    isNotValid = true;
  } else {
    return isNotValid;
  }
  return isNotValid;
};

export const imageSelector = (deviceModel, loadingImages, deviceImages, customCss = null) => {
  if (loadingImages) {
    return <Image className="device-card-image" style={customCss} src={defaultDeviceImage} alt="deviceImage" />;
  } else { 
    //sometime get different models: GET/images "MI8Lite" , GET/devices "Mi 8 Lite" etc..
    const index = deviceImages?.findIndex((arr) => arr.deviceModel === deviceModel?.replace(/\s+/g, ''));
    if (index > -1) {
      const deviceImage = deviceImages[index].image;
      return (
        <Image
          className="device-card-image"
          style={customCss}
          src={`data:image/png;base64,${deviceImage}`}
          alt="deviceImage"
          loading="lazy"
        />
      );
    } else {
      return (
        <Image
          className="device-card-image"
          style={customCss}
          src={defaultDeviceImage}
          alt="deviceImage"
          loading="lazy"
        />
      );
    }
  }
};

export const getObjectValues = (obj) =>
  obj && typeof obj === 'object' ? Object.values(obj).map(getObjectValues).flat() : [obj];

export const androidOrIosIcon = (fileName) => {
  const lastThree = fileName.substr(fileName.length - 3);
  if (lastThree.toLowerCase() === 'apk') {
    return <Icon name={ANDROID_ICON} />;
  }
  if (lastThree.toLowerCase() === 'ipa') {
    return <Icon name={IOS_ICON} />;
  } else {
    return UNKNOWN_LABEL();
  }
};

export const ArrayBufferToObject = (err) => {
  const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(err)));
  if (!text) {
    return undefined;
  }
  return JSON.parse(text);
};

export const blobToJson = (
  error,
  func = () => {
    /**/
  }
) => {
  //Especially for file operaitons, when request type is set as 'blob', response data
  // also returns as as blob. To get error msg from blob, this generic method is required.
  //Promise works here
  //Convert string to JSON and return, now.
  error.response?.data?.text().then((resultAsString) => func(JSON.parse(resultAsString)));
};

export const filterByValue = (array, string) => {
  console.log(array);
  console.log(string);
  return array.filter((o) => {
    return Object.keys(o).some((k) => {
      if (k === 'deviceStatus') {
        if (o[k] === 0) {
          return 'online'.includes(string?.toLowerCase());
        } else {
          return 'offline'.includes(string?.toLowerCase());
        }
      } else if (k === 'status') {
        return Object.keys(o[k]).some(
          (statKey) => o[k][statKey] && statKey?.toLowerCase()?.includes(string?.toLowerCase())
        );
      } else if (k === 'node' ) {
        let nodeProps =  [o[k].ip, o[k].name, o[k].location];
        return nodeProps.some((nodeProp) => nodeProp?.toLowerCase()?.includes(string?.toLowerCase()));
      } else {
        return typeof o[k] === 'string' && o[k]?.toLowerCase()?.includes(string?.toLowerCase());
      }
    });
  });
};

export const orderFilterElements = (ref) => {
  const orderedOsVersions = ref.current.querySelector('.filterOSVersions');
  [...orderedOsVersions.childNodes]
    .sort((a, b) =>
      a.innerText.localeCompare(b.innerText, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    )
    .forEach((node) => orderedOsVersions.appendChild(node));

  const orderedScreenSizes = ref.current.querySelector('.filterScreenSizes');
  [...orderedScreenSizes.childNodes]
    .sort((a, b) =>
      a.innerText.localeCompare(b.innerText, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    )
    .forEach((node) => orderedScreenSizes.appendChild(node));

  const orderedScreenResolutions = ref.current.querySelector('.filterScreenResolutions');
  [...orderedScreenResolutions.childNodes]
    .sort((a, b) =>
      a.innerText.localeCompare(b.innerText, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    )
    .forEach((node) => orderedScreenResolutions.appendChild(node));
};

export const conditionalShowFilterValues = (ref, requestParams, deviceList = []) => {
    const filterBrands = ref.current.querySelector('.filterBrands');
    const filterSlaves = ref.current.querySelector('.filterSlaves');
    const filterOsVersions = ref.current.querySelector('.filterOSVersions');
    const filterScreenSizes = ref.current.querySelector('.filterScreenSizes');
    const filterScreenResolutions = ref.current.querySelector('.filterScreenResolutions');
    const filterOperationSystems = ref.current.querySelector('.filterOperationSystems')

    const deviceBrands = deviceList.map(device => device.brand?.toLowerCase())
    const deviceSlaves = deviceList.map(device => device?.node?.name)
    const deviceScreenSizes = deviceList.map(device => device.screenSize)
    const deviceScreenResolutions = deviceList.map(device => device.screenResolution)
    const deviceOsVersions = deviceList.map(device => device.oSVersion)
    const deviceOperationSystems = deviceList.map(device => device.os.toLowerCase())

  if (!requestParams.brand.length) {
    const tempBrands = requestParams.brand.map((el) => el.toLowerCase());
    filterBrands.childNodes.forEach((child) => {
      if (!deviceBrands.includes(child.innerText?.toLowerCase())) {
        child.style.display = 'none';
      } else {
        child.style.display = 'block';
      }
      if (tempBrands.includes(child.innerText?.toLowerCase())) {
        child.style.display = 'block';
      }
    });
  }

  if (!requestParams.os.length) {
    const tempOs = requestParams.brand.map((el) => el.toLowerCase());
    filterOperationSystems.childNodes.forEach((child) => {
      if (!deviceOperationSystems.includes(child.innerText?.toLowerCase())) {
        child.style.display = 'none';
      } else {
        child.style.display = 'block';
      }
      if (tempOs.includes(child.innerText?.toLowerCase())) {
        child.style.display = 'block';
      }
    });
  }

  if (!requestParams.nodeList?.length) {
      filterSlaves.childNodes.forEach(child => {
          if (!deviceSlaves.some(item => item === child.innerText)) {
              child.style.display = 'none'
          } else {
              child.style.display = 'block'
          }
          for(const node in requestParams.nodeList){
              if (Object.values(node).includes(child.innerText)) {
                  child.style.display = 'block';
                  break;
              }
          }
      })
  }

  if (!requestParams.screenSize.length) {
    filterScreenSizes.childNodes.forEach((child) => {
      if (!deviceScreenSizes.includes(child.innerText)) {
        child.style.display = 'none';
      } else {
        child.style.display = 'block';
      }
      if (requestParams.screenSize.includes(child.innerText)) {
        child.style.display = 'block';
      }
    });
  }

  if (!requestParams.screenResolution.length) {
    filterScreenResolutions.childNodes.forEach((child) => {
      if (!deviceScreenResolutions.includes(child.innerText)) {
        child.style.display = 'none';
      } else {
        child.style.display = 'block';
      }
      if (requestParams.screenResolution.includes(child.innerText)) {
        child.style.display = 'block';
      }
    });
  }

  if (!requestParams.oSVersion.length) {
    filterOsVersions.childNodes.forEach((child) => {
      if (!deviceOsVersions.includes(child.innerText)) {
        child.style.display = 'none';
      } else {
        child.style.display = 'block';
      }
      if (requestParams.oSVersion.includes(child.innerText)) {
        child.style.display = 'block';
      }
    });
  }
};

export const getReactTableTranslationProps = () => ({
  nextText: NEXT_LABEL(),
  previousText: PREVIOUS_LABEL(),
  ofText: '/',
  rowsText: methodsMessages().ROWS_TEXT,
  pageText: PAGE_LABEL(),
  noDataText: methodsMessages().NO_DATA_TEXT,
  loadingText: LOADING()
});

export const headerForReports = () => {
  const { pathname } = window.location;
  const header = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length);
  return _.startCase(header);
};

export const chunkObject = (obj) => {
  //+3 reason is "status:{manual:.. , reserved:..., automation:....}" object keys and values, each shown on a separate line
  const size = Math.ceil((Object.keys(obj)?.length + 3) / 2);
  const chunks = [];
  for (const cols = Object.entries(obj); cols.length; ) {
    chunks.push(cols.splice(0, size).reduce((o, [k, v]) => ((o[k] = v), o), {}));
  }
  return chunks;
};

export const secondTohhmmss = (time) => {
  const h = Math.floor(time / ONE_HOUR_IN_SECONDS)
      .toString()
      .padStart(PAD_LENGTH, '0'),
    m = Math.floor((time % ONE_HOUR_IN_SECONDS) / ONE_MINUTE_IN_SECONDS)
      .toString()
      .padStart(PAD_LENGTH, '0'),
    s = Math.floor(time % ONE_MINUTE_IN_SECONDS)
      .toString()
      .padStart(PAD_LENGTH, '0');
  return `${h}:${m}:${s}`;
};

export const timeoutShowCondition = (time) => +(time * TIMEOUT_TOAST_SHOW_RATE).toFixed();

//BELOW CODE SEGMENT---------: Device OS Icon Selector
export const osIconSelectorProperly = (device, viewType) => {
  if (device.os?.toLowerCase() === 'ios') {
    return <Icon className={viewType === 'table' ? null : DEVICE_LIST_ICON_CLASSNAME} name={IOS_ICON} color="grey" />;
  } else if (device.os?.toLowerCase() === 'android') {
    if (device?.googleService === true) {
      return (
        <Icon className={viewType === 'table' ? null : DEVICE_LIST_ICON_CLASSNAME} name={ANDROID_ICON} color="olive" />
      );
    } else if (device?.googleService === false) {
      // if google service is not active, half-green android icon will be shown
      return (
        <i
          className={
            viewType === 'table' ? 'icon-customizedFor-googleService_Table' : 'icon-customizedFor-googleService'
          }></i>
      );
    } else {
      return <Icon className={viewType === 'table' ? null : DEVICE_LIST_ICON_CLASSNAME} name={QUESTION_MARK_ICON} />;
    }
  } else {
    return <Icon className={viewType === 'table' ? null : DEVICE_LIST_ICON_CLASSNAME} name={QUESTION_MARK_ICON} />;
  }
};
//ABOVE CODE SEGMENT---------: Device OS Icon Selector

export const getPrivileges = () => Base64.decode(localStorage.getItem('privileges')).split(',');

export const getUserDetails = () => localStorage.getItem('userDetails') ? JSON.parse(Base64.decode(localStorage.getItem('userDetails'))) : {};

export const showMoreText = (showOrHide, selectedId, text) => {
  return (
    <span style={{ wordBreak: 'keep-all', whiteSpace: 'normal' }}>
      {showOrHide && text.description.length > ANNOUNCEMENT_CONSTANTS.ANNOUNCEMENT_HISTORY_DESCRIPTION_LENGTH
        ? text.description.slice(0, ANNOUNCEMENT_CONSTANTS.ANNOUNCEMENT_HISTORY_DESCRIPTION_LENGTH) + '...'
        : selectedId === text.id
        ? text.description
        : text.description.length > ANNOUNCEMENT_CONSTANTS.ANNOUNCEMENT_HISTORY_DESCRIPTION_LENGTH
        ? text.description.slice(0, ANNOUNCEMENT_CONSTANTS.ANNOUNCEMENT_HISTORY_DESCRIPTION_LENGTH) + '...'
        : text.description}
    </span>
  );
};

export const showMoreLabel = (setShowOrHide, setDescriptionId, showOrHide, text, selectedId) => {
  return (
    <span
      style={{
        wordBreak: 'keep-all',
        whiteSpace: 'pre-wrap',
        cursor: 'pointer',
        color: 'purple',
        alignItems: 'flex-end',
        display: 'flex'
      }}
      onClick={() => {
        setShowOrHide(!showOrHide);
        setDescriptionId(text.id);
      }}>
      {selectedId !== text.id ? SHOW_BUTTON() : showOrHide ? SHOW_BUTTON() : HIDE_BUTTON()}
    </span>
  );
};

// When language is changed, table data will be renewed.
export const dataRefresher = (language, onChangeFunc) => {
  if (language !== localStorage.getItem('lang')) {
    onChangeFunc();
  }
};

export const onChangeFileInput = (inputFile, acceptableFileExtensions, setErrMsg, setCheckedFileOutput, setFileName = () => { }) => {
  setErrMsg('');
  if (inputFile) {
    if (!acceptableFileExtensions.includes(fileExtension(inputFile?.name))) {
      setErrMsg(methodsMessages().UNACCEPTABLE_FILE_TYPE_ERROR_MESSAGE);
    } else {
      setFileName(inputFile?.name)
      const formData = new FormData();
      formData.append('file', inputFile);
      setCheckedFileOutput(formData);
    }
  } else {
    setErrMsg(methodsMessages().CHOOSE_A_FILE_ERROR_MESSAGE);
  }
};

export const extensionListToAcceptAttribute = (acceptableFileExtension) =>
  acceptableFileExtension.map((extension) => `.${extension}`).join(', ');

export const validateUrl = (url) => /(http(s?)):\/\//i.test(url) && !characterCheckList.some((e) => url.endsWith(e));

//Extension to Icon converter
export const getIconForFile = (key, size) => {
  switch (key) {
    case 'txt':
      return <Icon name="file text" size={size} color="black" />;
    case 'doc':
      return <Icon name="file word" size={size} color="blue" />;
    case 'docx':
      return <Icon name="file word" size={size} color="blue" />;
    case 'pdf':
      return <Icon name="file pdf" size={size} color="red" />;
    case 'zip':
      return <Icon name="file archive" size={size} color="orange" />;
    case 'log':
      return <Icon name="file text" size={size} color="black" />;
    case 'xlsx':
      return <Icon name="file excel" size={size} color="green" />;
    case 'xml':
      return <Icon name="file code" size={size} color="teal" />;
    default:
      return <Icon name="file text" size={size} color="black" />;
  }
};

export const stringToArrayBuffer = (data) => {
  const buf = new ArrayBuffer(data.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = data.length; i < strLen; i++) {
    bufView[i] = data.charCodeAt(i);
  }
  return buf;
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const downloadXML = sourceXML => {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:application/xml;charset=utf-8,' + encodeURIComponent(sourceXML));
    element.setAttribute('download', 'source.xml');
      element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
}

export function htmlToJSON (source) {
    const childNodesOf = (xmlNode) => {
      if (!xmlNode || !xmlNode.hasChildNodes()) {
        return [];
      }
  
      const result = [];
      for (let childIdx = 0; childIdx < xmlNode.childNodes.length; ++childIdx) {
        const childNode = xmlNode.childNodes.item(childIdx);
        if (childNode.nodeType === 1) {
          result.push(childNode);
        }
      }
      return result;
    };
    const translateRecursively = (xmlNode, parentPath = '', index = null) => {
      const attributes = {};
      for (let attrIdx = 0; attrIdx < xmlNode.attributes.length; ++attrIdx) {
        const attr = xmlNode.attributes.item(attrIdx);
        attributes[attr.name] = attr.value;
      }
  
      // Dot Separated path of indices
      const path = _.isNil(index) ? '' : `${!parentPath ? '' : parentPath + '.'}${index}`;
      const classChainSelector = isIOS ? getOptimalClassChain(xmlDoc, xmlNode, UNIQUE_CLASS_CHAIN_ATTRIBUTES) : '';
      const predicateStringSelector = isIOS ? getOptimalPredicateString(xmlDoc, xmlNode, UNIQUE_PREDICATE_ATTRIBUTES) : '';
  
      return {
        children: childNodesOf(xmlNode)
          .map((childNode, childIndex) => translateRecursively(childNode, path, childIndex)),
        tagName: xmlNode.tagName,
        attributes,
        xpath: getOptimalXPath(xmlDoc, xmlNode, UNIQUE_XPATH_ATTRIBUTES),
        ...(isIOS ? {classChain: classChainSelector ? `**${classChainSelector}` : ''} : {}),
        ...(isIOS ? {predicateString: predicateStringSelector ? predicateStringSelector : ''} : {}),
        path,
      };
    };
    const isIOS = source.includes('XCUIElement');
    const xmlDoc = new DOMParser().parseFromString(source);
    // get the first child element node in the doc. some drivers write their xml differently so we
    // first try to find an element as a direct descendend of the doc, then look for one in
    // documentElement
    const firstChild = childNodesOf(xmlDoc)[0] || childNodesOf(xmlDoc.documentElement)[0];
  
    return firstChild ? translateRecursively(firstChild) : {};
}

export function getOptimalClassChain (doc, domNode, uniqueAttributes) {
  try {
    // BASE CASE #1: If this isn't an element, we're above the root, or this is `XCUIElementTypeApplication`,
    // which is not an official XCUITest element, return empty string
    if (!domNode.tagName || domNode.nodeType !== 1 || domNode.tagName === 'XCUIElementTypeApplication') {
      return '';
    }

    // BASE CASE #2: If this node has a unique class chain based on attributes then return it
    for (let attrName of uniqueAttributes) {
      const attrValue = domNode.getAttribute(attrName);
      if (attrValue) {
        let xpath = `//${domNode.tagName || '*'}[@${attrName}="${attrValue}"]`;
        let classChain = `/${domNode.tagName || '*'}[\`${attrName} == "${attrValue}"\`]`;
        let othersWithAttr;

        // If the XPath does not parse, move to the next unique attribute
        try {
          othersWithAttr = XPath.select(xpath, doc);
        } catch (ign) {
          continue;
        }

        // If the attribute isn't actually unique, get it's index too
        if (othersWithAttr.length > 1) {
          let index = othersWithAttr.indexOf(domNode);
          classChain = `${classChain}[${index + 1}]`;
        }
        return classChain;
      }
    }

    // Get the relative xpath of this node using tagName
    let classChain = `/${domNode.tagName}`;

    // If this node has siblings of the same tagName, get the index of this node
    if (domNode.parentNode) {
      // Get the siblings
      const childNodes = Array.prototype.slice.call(domNode.parentNode.childNodes, 0).filter((childNode) => (
        childNode.nodeType === 1 && childNode.tagName === domNode.tagName
      ));

      // If there's more than one sibling, append the index
      if (childNodes.length > 1) {
        let index = childNodes.indexOf(domNode);
        classChain += `[${index + 1}]`;
      }
    }

    // Make a recursive call to this nodes parents and prepend it to this xpath
    return getOptimalClassChain(doc, domNode.parentNode, uniqueAttributes) + classChain;
  } catch (error) {
    // If there's an unexpected exception, abort and don't get an XPath
    showError(`${methodsMessages().MOST_OPTIMAL_IOS_MESSAGE} '${JSON.stringify(error, null, 2)}'`);

    return null;
  }
}

export function getOptimalPredicateString (doc, domNode, uniqueAttributes) {
  try {
    // BASE CASE #1: If this isn't an element, we're above the root, or this is `XCUIElementTypeApplication`,
    // which is not an official XCUITest element, return empty string
    if (!domNode.tagName || domNode.nodeType !== 1 || domNode.tagName === 'XCUIElementTypeApplication') {
      return '';
    }

    // BASE CASE #2: Check all attributes and try to find the best way
    let xpathAttributes = [];
    let predicateString = [];

    for (let attrName of uniqueAttributes) {
      const attrValue = domNode.getAttribute(attrName);

      if (_.isNil(attrValue) || _.isString(attrValue) && attrValue.length === 0) {
        continue;
      }

      xpathAttributes.push(`@${attrName}="${attrValue}"`);
      const xpath = `//*[${xpathAttributes.join(' and ')}]`;
      predicateString.push(`${attrName} == "${attrValue}"`);
      let othersWithAttr;

      // If the XPath does not parse, move to the next unique attribute
      try {
        othersWithAttr = XPath.select(xpath, doc);
      } catch (ign) {
        continue;
      }

      // If the attribute isn't actually unique, get it's index too
      if (othersWithAttr.length === 1) {
        return predicateString.join(' AND ');
      }
    }
  } catch (error) {
    // If there's an unexpected exception, abort and don't get an XPath
    showError(`${methodsMessages().MOST_OPTIMAL_IOS_MESSAGE} '${JSON.stringify(error, null, 2)}'`);

    return null;
  }
}

export function parseSource (source) {
    // TODO this check is a bit brittle, figure out a better way to check whether we have a web
    // source vs something else. Just checking for <html in the source doesn't work because fake
    // driver app sources can include embedded <html elements even though the overall source is not
    // html. So for now just look for fake-drivery things like <app> or <mock...> and ensure we don't
    // parse that as html
    if (!source.includes('<html') || source.includes('<app ') || source.includes('<mock')) {
      return source;
    }
  
    const dom = parseDocument(source);
    const $ = load(dom);
  
    // Remove the head and the scripts
    const head = $('head');
    head.remove();
    const scripts = $('script');
    scripts.remove();
    const noscript = $('noscript');
    noscript.remove();
    const iframe = $('iframe');
    iframe.remove();

  
    
    // Clean the source
    $('*')
      // remove all `data-appium-desktop-` prefixes so only the width|height|x|y are there
      .each(function () {
        const $el = $(this);
  
        ['width', 'height', 'x', 'y'].forEach((rectAttr) => {
          if ($el.attr(`data-appium-inspector-${rectAttr}`)) {
            $el.attr(rectAttr, $el.attr(`data-appium-inspector-${rectAttr}`));
  
            $el.removeAttr(`data-appium-inspector-${rectAttr}`);
          }
        });
      });

    return $.xml();
  }

  
  export const setSessionValuesFromToken = (accessToken, refreshToken, user) => {
  const jwt = parseJwt({ token: accessToken });
  const sessionUser = user || JSON.parse(Base64.decode(localStorage.getItem('userDetails')));
  if (sessionUser) {
    localStorage.setItem('userDetails', Base64.encode(JSON.stringify(sessionUser)));
    localStorage.setItem('username', sessionUser.userName);
  }
  if (jwt?.privileges) {
    localStorage.setItem('privileges', Base64.encode(jwt?.privileges));
  }
  localStorage.setItem('activeGroup', jwt['activeGroup'] || -1);
  localStorage.setItem('accessToken', accessToken);
  refreshToken && localStorage.setItem('refreshToken', refreshToken);
};

export function getFrameworkId(automationFrameworks, value) {
  return Object.values(automationFrameworks).find(framework => framework.name === value).id;
}

export const deviceIdRenderer = deviceId => {
  if (deviceId) {
      const firstChars = deviceId.substring(0, 5)
      const lastChars = deviceId.substring(deviceId.length - 5, deviceId.length)
      return `${firstChars}...${lastChars}`
  } else {
      return deviceId
  }
}

export const handleReboot = async (udId, callback = () => { }) => {
  try {
    await deviceReboot(udId);
    showSuccess(multiManageOperationMessages().REBOOT_SUCCESSFUL_MESSAGE);
  } catch (err) {
    if (err?.response?.status === 500 || err?.response?.status === 404) {
      showError(`${multiManageOperationMessages().REBOOT_FAILED_MESSAGE} ${err?.response?.status}`);
    }
  } finally {
    callback();
  }
};

export const renderedNodeInfo = (node = {}) => {
  const { name, location, ip } = node;
  const nodeInfo = [name, ...(location ? [`(${location})`] : [])];
  if(node.ip && node.ip !== node.name && getPrivileges()?.includes(PrivilegeConstants.SECONDARY_SERVER_MANAGEMENT)) {
    nodeInfo.push("(" + ip + ")");
  }
  return nodeInfo.join(' ');
};

export const calculateHash = async (file) => {
  const fileSize = file.size;
  let offset = 0;
  const cryptoHash = CryptoJS.algo.SHA256.create();

  const readSlice = (offset) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const chunk = CryptoJS.lib.WordArray.create(reader.result);
        cryptoHash.update(chunk);
        resolve();
      };
      reader.onerror = reject;

      const slice = file.slice(offset, offset + FILE_HASH_CHUNK_SIZE);
      reader.readAsArrayBuffer(slice);
    });
  };

  while (offset < fileSize) {
    await readSlice(offset);
    offset += FILE_HASH_CHUNK_SIZE;
  }

  return cryptoHash.finalize().toString(CryptoJS.enc.Hex);
};

export const maskString = (string, start, end, regex) => {
  return string.substring(0, start) + string.substring(start, end).replace(regex, "*") + string.substring(end);
}

export const isNotBlankStr = str => {
  return str && str.trim().length > 0;
}