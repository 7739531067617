import React, {Component} from 'react';
import {Button, Confirm, Icon} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import NewGroupModal from "./NewGroupModal";
import {Flip, toast} from "react-toastify";
import ManageGroupsModal from "./ManageGroupsModal";
import {showError} from "../../../utils/ToastHelpers";
import {
    deleteGroup,
    getGroupList
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { dataRefresher, getPrivileges } from '../../../utils/Methods';
import { ACTIONS_HEADER, ACTIVE_LABEL, CANCEL_BUTTON, CONCURRENT_ACCESS_LIMIT_LABEL, CONFIRM_BUTTON, FAILED_ERROR_MESSAGE, GROUP_NAME_HEADER,
    GROUP_DESCRIPTION_HEADER, NO_LABEL, N_A_LABEL, SUCCESS_MESSAGE, userGroupsMessages, USER_GROUPS_HEADER, YES_LABEL } from '../../../utils/UIMessages';
import { ADD_USER_ICON, CHECKMARK_ICON, EDIT_ICON, USER_TIMES_ICON, X_ICON } from '../../../utils/UiIcons';


class UserGroupsPage extends Component {

    //React Table V8 Columns
    columnsRender = () => [
        {
            header: () => <div>{GROUP_NAME_HEADER()}</div>,
            accessorKey: 'name',
            id: 'name',
            cell: info => (<span>{info.getValue() === null ? <>N/A</> : <>{info.getValue()}</>}</span>)
        },
        {
            header: () => <div>{GROUP_DESCRIPTION_HEADER()}</div>,
            accessorKey: 'description',
            id: 'description',
            cell: info => (<span>{info.getValue() === null || info.getValue()?.trim().length === 0 ? <>N/A</> : <>{info.getValue()}</>}</span>)
        },
        {
            header: () => <div>{ACTIVE_LABEL()}</div>,
            accessorFn: d => d.active ? YES_LABEL() : NO_LABEL(),
            id: 'active',
            filterType: 'dropdown',
            cell: props => (<span> {props.getValue() === YES_LABEL() ? <>
                <Icon name={CHECKMARK_ICON} color={'green'} /></> : <>
                <Icon name={X_ICON} color={'red'} />
            </>} </span>)
        },
        {
            header: () => <div>{CONCURRENT_ACCESS_LIMIT_LABEL()}</div>,
            accessorKey: 'concurrentAccessLimit',
            id: 'concurrentAccessLimit',
            enableColumnFilter: false,
            enableSorting: false,
            cell: info => (<span>{info.getValue() === null ? <>{N_A_LABEL()}</> : <>{info.getValue()}</>}</span>)
        },
        {
            header: ()=><div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            enableColumnFilter: false,
            enableSorting: false,
            cell: props => (<span>
                <Button size='tiny' icon onClick={() => {
                    this.setState({ selectedGroup: props.row.original, requestType: 'put' }, () => {
                        this.setState({ addModalVisibility: true })
                    })
                }}><Icon name={EDIT_ICON} />
                </Button><Button size='tiny' color={'blue'} icon onClick={() => {
                    this.setState({ manageModalVisibility: true, selectedGroup: props.row.original })
                }}><Icon name='setting' /></Button>
                <Button size='tiny' icon color='red' onClick={() => {
                    this.setState({ confirmVisibility: true, selectedGroup: props.row.original })
                }}> <Icon name={USER_TIMES_ICON} /></Button>
            </span>)
        }
    ]

    state = {
        allUserGroups: [],
        addModalVisibility: false,
        requestType: "",
        selectedGroup: {bookable:false},
        confirmVisibility: false,
        manageModalVisibility: false,
        loadingAllUserGroups: false,
        language: localStorage.getItem('lang') || 'en',
        privileges: [],
    }

    getAllGroups = () => {
        this.setState({loadingAllUserGroups: true});
        //axios.get('/api/groups')
        getGroupList().then(res => {
            this.setState({allUserGroups: res.data})
        }).catch(err => {
            showError(userGroupsMessages().NOT_FETCH_GROUPS + err?.response?.data?.message ?? err.toString());
        }).finally(() => this.setState({loadingAllUserGroups: false}));
    }

    handleAddModalClose = () => {
        this.setState({addModalVisibility: false, requestType: "", selectedGroup: null})
        this.getAllGroups()
    }

    handleManageModalClose = () => {
        this.setState({manageModalVisibility: false})
    }

    onDelete = () => {
        //axios.delete('/api/group/' + this.state.selectedGroup?.id)
        deleteGroup(this.state.selectedGroup?.id).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                transition: Flip,
                autoClose: 3000
            })
            this.setState({selectedGroup: null, confirmVisibility: false})
            this.handleAddModalClose()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
            this.handleAddModalClose()
            this.setState({selectedGroup: null, confirmVisibility: false})
        })
    }

    componentDidMount() {
        this.getAllGroups();
        const privileges = getPrivileges();
        this.setState({ language: localStorage.getItem('lang') || 'en', privileges });
    }

    componentDidUpdate() {
        // When language is changed, table data will be renewed.
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.getAllGroups())
        )
    }


    render() {
        return (
            <>
                <div className='main-right-header'>
                    <div>
                        <h2> {USER_GROUPS_HEADER()}</h2>
                        <small> {userGroupsMessages().USER_GROUPS_SUB_HEADER}</small>
                    </div>
                    <div className='main-right-buttons'>
                        <Button primary icon onClick={() => {
                            this.setState({addModalVisibility: true, requestType: 'post', selectedGroup: null})
                        }}> <Icon name={ADD_USER_ICON}/> {userGroupsMessages().ADD_NEW_GROUP_BUTTON}</Button>
                    </div>
                </div>
                <ReactTableV8
                    data={this.state.allUserGroups}
                    columns={this.columnsRender()}
                    columnwiseFilterableProp={true}
                    loadingProp={this.state.loadingAllUserGroups}
                />
                <div>
                    <NewGroupModal
                        addModalVisibility={this.state.addModalVisibility}
                        handleModalClose={this.handleAddModalClose}
                        requestType={this.state.requestType}
                        selectedGroup={this.state.selectedGroup}
                        privileges={this.state.privileges}
                    />
                    <ManageGroupsModal
                        manageModalVisibility={this.state.manageModalVisibility}
                        selectedGroup={this.state.selectedGroup}
                        handleModalClose={this.handleManageModalClose}
                    />

                    <Confirm
                        open={this.state.confirmVisibility}
                        content={userGroupsMessages().DELETE_CONFIM}
                        onClose={() => this.setState({confirmVisibility: false, selectedGroup: {}})}
                        onCancel={() => this.setState({confirmVisibility: false, selectedGroup: {}})}
                        onConfirm={() => {
                            this.onDelete()
                        }}
                        confirmButton={CONFIRM_BUTTON()}
                        cancelButton={CANCEL_BUTTON()}
                    />
                </div>
            </>
        );
    }
}

export default withTranslation()(UserGroupsPage);
