
import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Flip, toast } from "react-toastify";
import { Button, Confirm, Form, Header, Icon, Input, Modal } from "semantic-ui-react";
import { getPrivileges, validateLocationInput } from '../../../utils/Methods';
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { showError } from "../../../utils/ToastHelpers";
import {
  createSystemLocation,
  createUserLocation,
  updateSystemLocation,
  updateUserLocation,
  deleteSystemLocation,
  deleteUserLocation,
  getUserLocationList,
  getUserAndSystemLocationList
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { ACTIONS_HEADER, CANCEL_BUTTON, CLICK_TO_MAKE_MARKER_DRAGGABLE_LABEL, DELETE_BUTTON, LATITUDE_LABEL, locationsMessages, LOCATION_NAME_LABEL, LONGITUDE_LABEL, MARKER_IS_DRAGGABLE_LABEL, SUBMIT_BUTTON, SUCCESS_MESSAGE } from "../../../utils/UIMessages";
import { EDIT_ICON, PLUS_ICON, X_ICON } from "../../../utils/UiIcons";

const center = [0, 0];
const zoom = 13;


const DisplayMap = ({ lat, lng, changeCoordinates }) => {

  const markerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [draggable] = useState(true);


  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          changeCoordinates(marker.getLatLng());
        }
      }
    }),
    [],
  )


  const displayMap = useMemo(
    () => (
      <MapContainer
        center={center}
        zoom={zoom}
        scrollWheelZoom={false}
        whenCreated={setMap}
        style={{ height: 750 }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> | <a href="https://netas.com.tr/" target="_blank">NETAS</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          draggable={draggable}
          eventHandlers={eventHandlers}
          ref={markerRef}
          position={[lat, lng]}
        >
          <Popup minWidth={90}>
            <span>
              {draggable
                ? MARKER_IS_DRAGGABLE_LABEL()
                : CLICK_TO_MAKE_MARKER_DRAGGABLE_LABEL()}
            </span>
          </Popup>
        </Marker>


      </MapContainer>
    ),
    [lat, lng],
  )


  useEffect(() => {
    if (map !== null) {
      map.setView([lat, lng], map.getZoom());
    }
  }, [map, lat, lng]);


  return (
    <>
      {displayMap}
    </>
  );
}


class Locations extends Component {

  state = {
    id: "",
    name: "",
    latitude: "",
    longitude: "",
    isGlobal: false,
    modalVisibility: false,
    confirmVisibility: false,
    systemLocationList: [],
    userLocationList: [],
    // pageSizeSystem: 10,
    // pageSystem: 0,
    // pageSizeUser: 10,
    pageUser: 0,
    privileges: [],
    loadingLocationList: false
  };

  //New React Table V8 Columns
  columnsRender = () => [
    {
      header: () => <div>{LOCATION_NAME_LABEL()}</div>,
      accessorKey: 'name',
      id: 'name',
      cell: info => (<span className='number' style={{ fontWeight: 'bold' }}>{info.getValue()}</span>)
    },
    {
      header: () => <div>{LATITUDE_LABEL()}</div>,
      accessorKey: 'latitude',
      id: 'latitude',
      cell: info => (<span className='number'>{info.getValue()}</span>)
    },
    {
      header: () => <div>{LONGITUDE_LABEL()}</div>,
      accessorKey: 'longitude',
      id: 'longitude',
      cell: info => (<span className='number'>{info.getValue()}</span>)
    },
    {
      header: () => <div>{ACTIONS_HEADER()}</div>,
      accessorKey: 'id',
      id: 'id',
      enableColumnFilter: false,
      enableSorting: false,
      cell: props => {
        return <span className='number'><Button size='tiny' onClick={() => this.openFormModal(props.row.original, props.row.original.global)}
          icon={<Icon name={EDIT_ICON} />} /> <Button
            size='tiny'
            color='red'
            onClick={() => {
              this.openConfirm(props.getValue(), props.row.original.global)
            }}
            icon={<Icon name={X_ICON} />} /></span>
      }
    }
  ];

  onHandleChange = (e, { value, name }) => this.setState(prevState => ({ ...prevState, [name]: value }))


  changeCoordinates = (position) => {
    this.setState({
      latitude: position.lat,
      longitude: position.lng
    });
  }


  openConfirm = (id, isGlobal) => {
    this.setState({ id, isGlobal }, () => {
      this.setState({ confirmVisibility: true })
    })
  }


  getBrowserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      () => { });
  }


  openFormModal = (fields, isGlobal) => {
    if (fields) {
      this.setState({
        id: fields.id,
        name: fields.name,
        latitude: fields.latitude,
        longitude: fields.longitude,
        isGlobal
      })
    } else {
      this.setState({ id: "", name: "", latitude: "", longitude: "", isGlobal }, this.getBrowserLocation);
    }
    this.setState({ modalVisibility: true })
  }


  onSubmit = () => {
    if (validateLocationInput(this.state.latitude, this.state.longitude, this.state.name)) {
      const data = {
        name: this.state.name,
        latitude: this.state.latitude,
        longitude: this.state.longitude
      };

      let resPromise;
      if (this.state.id) {
        if (this.state.isGlobal) {
          //resPromise = axios.put(`/api/location/system/${this.state.id}`, data);
          resPromise = updateSystemLocation(this.state.id, data);
        } else {
          //resPromise = axios.put(`/api/location/user/${this.state.id}`, data);
          resPromise = updateUserLocation(this.state.id, data);
        }
      } else {
        if (this.state.isGlobal) {
          //resPromise = axios.post("/api/location/system", data);
          resPromise = createSystemLocation(data);
        } else {
          //resPromise = axios.post("/api/location/user", data);
          resPromise = createUserLocation(data);
        }
      }

      resPromise
        .then(() => {
          toast.success(SUCCESS_MESSAGE(), {
            transition: Flip,
            autoClose: 2000
          });
          this.setState({ modalVisibility: false }, this.getLocationList);
        })
        .catch(err => {
          
          showError(locationsMessages(this.state.id ? 'update' : 'create').NOT_CREATE_OR_UPDATE_LOCATION_ERROR_MESSAGE + err.toString(),);
        });
    }
  }


  onDelete = () => {
    let resPromise;
    if (this.state.isGlobal) {
      //resPromise = axios.delete(`/api/location/system/${this.state.id}`);
      resPromise = deleteSystemLocation(this.state.id);
    } else {
      //resPromise = axios.delete(`/api/location/user/${this.state.id}`);
      resPromise = deleteUserLocation(this.state.id);
    }

    resPromise
      .then(() => {
        toast.success(SUCCESS_MESSAGE(), {
          transition: Flip,
          autoClose: 2000
        });
        this.setState({ confirmVisibility: false }, this.getLocationList);
      })
      .catch(err => {
        showError(locationsMessages().NOT_DELETE_LOCATION_ERROR_MESSAGE + err.toString(),);
      });
  }


  getAllLocationList = () => {
    this.setState({ loadingLocationList: true });
    //axios.get("/api/location/all")
    getUserAndSystemLocationList().then(res => {
      this.setState({
        systemLocationList: res.data.system,
        userLocationList: res.data.user
      });
    })
      .catch(err => {
        showError(locationsMessages().NOT_FETCH_LOCATION_LIST + err.toString());
      }).finally(() => this.setState({ loadingLocationList: false }));
  }


  getUserLocationList = () => {
    //axios.get("/api/location/user")
    getUserLocationList().then(res => {
      this.setState({ userLocationList: res.data });
    })
      .catch(err => {
        showError(locationsMessages().NOT_FETCH_LOCATION_LIST + err.toString());
      });
  }


  getLocationList = () => {
    if (this.state.privileges.includes("43")) {
      this.getAllLocationList();
    } else {
      this.getUserLocationList();
    }
  }


  componentDidMount() {
    const privileges = getPrivileges();
    this.setState({ privileges }, this.getLocationList);
  }

  validationCheck = () => {
    return !(this.state.name && this.state.latitude && this.state.longitude)
  }


  render() {
    return (
      <>
        <div>
          {this.state.privileges.includes('43') ?
            <>
              <div className='main-right-header'>
                <div>
                  <h2>{locationsMessages().SYSTEM_LOCATIONS_HEADER}</h2>
                  <small>{locationsMessages().SYSTEM_LOCATIONS_SUB_HEADER}</small>
                </div>
                <div className='main-right-buttons'>
                  <Button primary icon={PLUS_ICON} content={locationsMessages().ADD_NEW_LOCATION_BUTTON}
                    onClick={() => this.openFormModal(null, true)} />
                </div>

              </div>
              <ReactTableV8
                columns={this.columnsRender()}
                data={this.state.systemLocationList}
                loadingProp={this.state.loadingLocationList}
              />
            </> : null
          }

          <div className="main-right-header" style={{ marginTop: 50 }}>
            <div>
              <h2>{locationsMessages().MY_LOCATIONS_HEADER}</h2>
              <small> {locationsMessages().CREATE_USER_LOCATION_SUB_HEADER}</small>
            </div>
            <div className="main-right-buttons">
              <Button primary icon={PLUS_ICON} content={locationsMessages().ADD_NEW_LOCATION_BUTTON}
                onClick={() => this.openFormModal(null, false)} />
            </div>

          </div>
          <ReactTableV8
            columns={this.columnsRender()}
            data={this.state.userLocationList}
            loadingProp={this.state.loadingLocationList}
          />
          <Modal closeIcon closeOnDimmerClick={true} open={this.state.modalVisibility}
            onClose={() => this.setState({ modalVisibility: false })}
            size='large'>
            <Header content={locationsMessages().LOCATION_PROPERTIES_HEADER} />
            <Modal.Content>
              <Form style={{ marginBottom: '50px' }} >
                <Form.Field required>
                  <label>{LOCATION_NAME_LABEL()}</label>
                  <Input
                    fluid
                    name='name'
                    type={'text'}
                    onChange={this.onHandleChange}
                    value={this.state.name}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>{LATITUDE_LABEL()}</label>
                  <Input
                    fluid
                    name='latitude'
                    type={'number'}
                    onChange={this.onHandleChange}
                    value={this.state.latitude}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>{LONGITUDE_LABEL()}</label>
                  <Input
                    fluid
                    name='longitude'
                    type={'number'}
                    onChange={this.onHandleChange}
                    value={this.state.longitude}
                  />
                </Form.Field>
              </Form>
              <DisplayMap lat={this.state.latitude} lng={this.state.longitude} changeCoordinates={this.changeCoordinates} />
            </Modal.Content>
            <Modal.Actions>
              <Button
                color='green' onClick={this.onSubmit} disabled={this.validationCheck()}>{SUBMIT_BUTTON()}</Button>
            </Modal.Actions>
          </Modal>
          <Confirm
            content={locationsMessages().DELETE_CONFIRM}
            open={this.state.confirmVisibility}
            onCancel={() => {
              this.setState({ confirmVisibility: false })
            }}
            onConfirm={this.onDelete}
            confirmButton={DELETE_BUTTON()}
            cancelButton={CANCEL_BUTTON()}
          />
        </div>
      </>
    );
  }
}

export default withTranslation()(Locations);
