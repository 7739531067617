import React, {Component} from 'react';
import {Header, Segment, TransitionablePortal} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';

class Notification extends Component {
    render() {
        return (
            <>
                <TransitionablePortal
                    open={this.props.openPortal}
                    transition={{animation: 'fade left', duration: '200'}}
                >
                    <Segment

                        style={{
                            top: 0,
                            position: 'fixed',
                            right: 0,
                            zIndex: 1000,
                        }}
                    >
                        <Header>{this.props.headerPortal}</Header>
                        <p>{this.props.contentPortal}</p>
                        {this.props.actionsPortal}
                    </Segment>
                </TransitionablePortal>
            </>
        );
    }
}

export default withTranslation()(Notification);