import React, {Component} from 'react';
import {Grid, Header, Segment} from "semantic-ui-react";
import DateRangeDropdown from "../../../../components/DateRangeDropdown";
import {ResponsiveRadar} from "@nivo/radar";
import axios from "axios";
import {dataRefresher, secondsToDhms} from '../../../../utils/Methods';
import _ from "lodash";
import {withTranslation} from 'react-i18next';
import {showError} from "../../../../utils/ToastHelpers";
import { AUTOMATION_LABEL, chartsDeviceUsageMessages, DEVELOPMENT_LABEL, FAILED_ERROR_MESSAGE, MANUAL_LABEL, TOTAL_LABEL, USAGE_LABEL } from '../../../../utils/UIMessages';

class ChartsDuStats extends Component {

    state = {
        dataForRadarAndroid: [],
        dataForRadarIOS: [],
        startDate: new Date(),
        endDate: new Date(),
        visibility: false, //need this for change date
        loading: false,
        language: localStorage.getItem('lang') || 'en'
    }

    convertDataForRadar = async (params = "") => {
        this.setState({ loading: true });
        try {
            const data = (await axios.get("/api/reports/device-usage-stats" + params)).data
            let [dataForRadarAndroid, dataForRadarIOS, {android, ios}] = [[], [], data, data]
            for (let i = 0; i < android?.versions?.length; i++) {
                dataForRadarAndroid.push({
                    version: android.versions[i],
                    [MANUAL_LABEL()]: android.usages[i][1],
                    [AUTOMATION_LABEL()]: android.usages[i][0],
                    [DEVELOPMENT_LABEL()]: android.usages[i][2],
                    [TOTAL_LABEL()]: android.usages[i][0] + android.usages[i][1] + android.usages[i][2]
                })
            }
            for (let i = 0; i < ios?.versions?.length; i++) {
                dataForRadarIOS.push({
                    version: ios.versions[i],
                    [MANUAL_LABEL()]: ios.usages[i][1],
                    [AUTOMATION_LABEL()]: ios.usages[i][0],
                    [DEVELOPMENT_LABEL()]: ios.usages[i][2],
                    [TOTAL_LABEL()]: ios.usages[i][0] + ios.usages[i][1] + ios.usages[i][2]
                })
            }
            this.setState({dataForRadarAndroid, dataForRadarIOS}, () => {
                this.setState({visibility: true})
            })
            let startDate = new Date(data.startDate)
            let endDate = new Date(data.endDate)
            this.setState({startDate, endDate})
        } catch (err) {
            showError(FAILED_ERROR_MESSAGE() +err?.response?.data?.message ?? err.toString())
        } finally {
          this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.convertDataForRadar()
    }
    componentDidUpdate(){
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.convertDataForRadar())
        )
    }

    onChangeDateComponent = link => {
        this.setState({visibility: false})
        const params = "?" + link
        this.convertDataForRadar(params)
    }

    radarChartsRenderer = (data, key) => {
        let allKeys = [AUTOMATION_LABEL(), MANUAL_LABEL(), DEVELOPMENT_LABEL(), TOTAL_LABEL()]
        allKeys = allKeys.filter((el) => el !== key) // delete selected key and keep other keys for filter original data
        data = _.map(data, function (row) {
            return _.omit(row, [allKeys[0], allKeys[1], allKeys[2]]); // delete other keys in data object and keep selected data
        })
        return <>
            <Header size={"small"} textAlign={"center"}>
                {chartsDeviceUsageMessages(key).DEVICE_USAGE_STATUS_HEADER} {USAGE_LABEL()}
            </Header>
            <Segment placeholder style={{height: 750}} loading={this.state.loading}>
                <ResponsiveRadar
                    data={_.orderBy(data, ['version'], ['desc'])}
                    keys={[key]}
                    indexBy="version"
                    maxValue="auto"
                    margin={{top: 70, right: 80, bottom: 40, left: 80}}
                    curve="catmullRomClosed"
                    borderWidth={2}
                    borderColor={{from: 'color'}}
                    gridLevels={5}
                    gridShape="circular"
                    gridLabelOffset={36}
                    enableDots={false}
                    dotSize={10}
                    dotColor={{theme: 'background'}}
                    dotBorderWidth={2}
                    dotBorderColor={{from: 'color'}}
                    enableDotLabel={true}
                    dotLabel="key"
                    dotLabelYOffset={-12}
                    colors={{scheme: 'dark2'}}
                    fillOpacity={0.10}
                    blendMode="multiply"
                    animate={false}
                    motionConfig="wobbly"
                    isInteractive={true}
                    tooltipFormat={value => `${secondsToDhms(value)}`}
                />
            </Segment>

        </>
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <DateRangeDropdown
                                isOrderByVisibility={false}
                                isSizeVisibility={false}
                                type={"daily"}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                callback={(link) => {
                                    this.onChangeDateComponent(link)
                                }}
                                isUserRolesVisibility={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header textAlign={"center"}>
                                {chartsDeviceUsageMessages().ANDROID_USAGE_STATS_LABEL}
                            </Header>
                            <>
                                {this.radarChartsRenderer(this.state.dataForRadarAndroid, TOTAL_LABEL())}
                                {this.radarChartsRenderer(this.state.dataForRadarAndroid, MANUAL_LABEL())}
                                {this.radarChartsRenderer(this.state.dataForRadarAndroid, AUTOMATION_LABEL())}
                                {this.radarChartsRenderer(this.state.dataForRadarAndroid, DEVELOPMENT_LABEL())}
                            </>
                        </Grid.Column>
                        <Grid.Column>
                            <Header textAlign={"center"}>
                                {chartsDeviceUsageMessages().IOS_USAGE_STATS_LABEL}
                            </Header>
                            <>
                                {this.radarChartsRenderer(this.state.dataForRadarIOS, TOTAL_LABEL())}
                                {this.radarChartsRenderer(this.state.dataForRadarIOS, MANUAL_LABEL())}
                                {this.radarChartsRenderer(this.state.dataForRadarIOS, AUTOMATION_LABEL())}
                                {this.radarChartsRenderer(this.state.dataForRadarIOS, DEVELOPMENT_LABEL())}
                            </>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(ChartsDuStats);
