import React, {Component} from 'react';
import axios from "axios";
import {secondsToDhms, tickFormatter, timelineLabels} from "../../../../utils/Methods";
import {ResponsiveLine} from "@nivo/line";
import {Grid, Header, Segment} from "semantic-ui-react";
import DateRangeDropdown from "../../../../components/DateRangeDropdown";
import {withTranslation} from 'react-i18next';
import {showError} from "../../../../utils/ToastHelpers";
import { AUTOMATION_LABEL, chartsDeviceUsageMessages, DEVELOPMENT_LABEL, FAILED_ERROR_MESSAGE, MANUAL_LABEL, SIMPLE_RATIO_LABEL, TOTAL_LABEL, USAGE_LABEL } from '../../../../utils/UIMessages';

class ChartsHourlyUsage extends Component {

    state = {
        dataForLineChartAut: [],
        dataForLineChartMan: [],
        dataForLineChartDev: [],
        dataForLineChartTotal: [],
        startDate: new Date(),
        endDate: new Date(),
        simpleRatio: false,
        hours: timelineLabels('00:00', 1, 'hour'),
        loading: false
    }

    generateLine = (x, y) => {
        let lines = []
        for (let i = 0; i < 24; i++) { // 24 hours
            lines.push({
                "x": x[i],
                "y": y[i]
            })
        }
        return lines
    }

    generateState = (arr, type) => {
        return [{
            id: type,
            data: this.generateLine(this.state.hours, arr)
        }]
    }

    generateData = data => {
        let [
            dataForLineChartAut,
            dataForLineChartMan,
            dataForLineChartDev,
            dataForLineChartTotal
        ] = [
            this.generateState(data?.automationUsage, AUTOMATION_LABEL()),
            this.generateState(data?.manualUsage, MANUAL_LABEL()),
            this.generateState(data?.developmentUsage,DEVELOPMENT_LABEL()),
            this.generateState(data?.totalUsage, TOTAL_LABEL())
        ]
        this.setState({
            dataForLineChartAut: dataForLineChartAut.concat(dataForLineChartTotal),
            dataForLineChartMan: dataForLineChartMan.concat(dataForLineChartTotal),
            dataForLineChartDev: dataForLineChartDev.concat(dataForLineChartTotal),
            dataForLineChartTotal,
            startDate:new Date(data?.startDate),
            endDate:new Date(data?.endDate)
        })
    }

    getUsages = async (params = "") => {
        this.setState({ loading: true });
        try {
            let data = (await axios.get(`/api/reports/device-usages-hourly${params}`)).data
            this.generateData(data)
        } catch (err) {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        } finally {
          this.setState({ loading: false });
        }
    }

    lineChartsRenderer = data => {
        return <>
            <Segment placeholder style={{height: 500}} loading={this.state.loading}>
                <ResponsiveLine
                    data={data}
                    margin={{top: 50, right: 30, bottom: 100, left: 110}}
                    yFormat=">-d"
                    curve="catmullRom"
                    enableSlices="x"
                    yScale={{
                        type: "linear",
                        min: 0,
                        max: "auto",
                        stacked: false,
                        reverse: false
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        format: tick => {
                            return this.state.simpleRatio ? `% ${tick/100}` : tickFormatter(tick) //the ratio is at the base of 10.000
                        },
                        orient: "left",
                        tickPadding: 5,
                        tickRotation: 0,
                        tickValues: 5,
                        legend: USAGE_LABEL(),
                        legendOffset: -100,
                        legendPosition: "middle"
                    }}
                    axisBottom={{
                        tickRotation: -55,
                        legend: chartsDeviceUsageMessages().TIME_INTERVALS_LABEL,
                        legendOffset: 85,
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        legendPosition: 'middle'
                    }}
                    sliceTooltip={({slice}) => {
                        return (
                            <div
                                style={{
                                    background: 'white',
                                    padding: '9px 12px',
                                    border: '1px solid #ccc',
                                }}
                            >
                                {slice.points.map(point => (
                                    <div
                                        key={point.id}
                                        style={{
                                            color: point.serieColor,
                                            padding: '3px 0',
                                        }}
                                    >
                                        <strong>{point.serieId}</strong>: {this.state.simpleRatio ? `% ${point.data.yFormatted/100}`: secondsToDhms(point.data.yFormatted)}
                                    </div>
                                ))}
                            </div>
                        )
                    }}
                    crosshairType={"bottom-left"}
                    colors={{scheme: 'set1'}}
                    pointSize={10}
                    pointColor={{from: 'color', modifiers: []}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: "serieColor"}}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: "bottom-right",
                            direction: "row",
                            justify: false,
                            translateX: 45,
                            translateY: 90,
                            itemsSpacing: 10,
                            itemDirection: "left-to-right",
                            itemWidth: 100,
                            itemHeight: 0,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: "circle",
                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </Segment>

        </>
    }

    componentDidMount() {
        this.getUsages()
    }

    onChangeDateComponent = link => {
        const params = "?" + link
        this.getUsages(params)
        if (params.includes("type=1")){
            this.setState({simpleRatio:true})
        } else {
            this.setState({simpleRatio:false})
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <DateRangeDropdown isOrderByVisibility={false}
                            isSizeVisibility={false}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            type={this.props.type}
                            hourlyDeviceStats={true}
                            simpleRatio={this.state.simpleRatio}
                            callback={(link) => {
                                this.onChangeDateComponent(link)
                            }}
                            isUserRolesVisibility={true}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={1}>
                    <Grid.Column>
                        <Header textAlign={"center"}>{chartsDeviceUsageMessages().HOURLY_USAGE_HEADER} {this.state.simpleRatio ? SIMPLE_RATIO_LABEL() : chartsDeviceUsageMessages().STATS_HEADER}</Header>
                        <Grid.Column>
                            <Header size={"small"} style={{marginTop: 30}} textAlign={"center"}>{AUTOMATION_LABEL()}</Header>
                            {this.lineChartsRenderer(this.state.dataForLineChartAut)}
                        </Grid.Column>
                        <Grid.Column>
                            <Header size={"small"} style={{marginTop: 35}} textAlign={"center"}>{MANUAL_LABEL()}</Header>
                            {this.lineChartsRenderer(this.state.dataForLineChartMan)}
                        </Grid.Column>
                        <Grid.Column>
                            <Header size={"small"} style={{marginTop: 35}} textAlign={"center"}>{DEVELOPMENT_LABEL()}</Header>
                            {this.lineChartsRenderer(this.state.dataForLineChartDev)}
                        </Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withTranslation()(ChartsHourlyUsage);
