import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ArticleHeaderList from './ArticleHeaderList';
import '../product.css';


const CollectionList = (props) => {
  return (
    <div id='collection-list'>
      {
        props.collections.map(col => (
          <Fragment key={col.id}>
            <div className='collection-list-item'>
              <h3>{col.name}</h3>
            </div>
            {
              col.articles.map(art => (
                <div key={art.id}>
                  <Link to={`/documentation/${art.id}`} >
                    <div onClick={() => props.selectArticle(art.id)}
                      className={'article-list-item ' + (props.articleId === art.id && "article-list-item-active")}>
                      {art.title}
                    </div>
                  </Link>
                  {
                    props.articleId === art.id &&
                    <ArticleHeaderList headerList={props.headerList} />
                  }
                </div>
              ))
            }
          </Fragment>
        ))
      }
    </div>
  );
};

export default CollectionList;