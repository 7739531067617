import React, {Component} from 'react';
import {Button, Dropdown, Form, Header, Icon, Input, Modal, Popup, Segment} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import {validEmail, validFullname, validUsername} from "../../../utils/Constants";
import ChangePasswordInput from '../../../components/ChangePasswordInput';
import { ACTIVE_LABEL, addNewUserMessages, FULLNAME_HEADER, PASSWORD_HEADER, SAVE_BUTTON, USERNAME_HEADER, USER_GROUPS_HEADER, USER_MAIL_HEADER, USER_ROLES_LABEL } from '../../../utils/UIMessages';
import { EYE_ICON, EYE_SLASH_ICON, INFO_ICON, USER_PLUS_ICON } from '../../../utils/UiIcons';

class AddNewUser extends Component {

    render() {
        return (
            <div>
                <Modal open={this.props.modalVisibility} onClose={this.props.handleModalClose} closeIcon
                       closeOnDimmerClick={true}
                       size={"small"}>
                    <Header style={{textAlign: "center"}}>{addNewUserMessages().MODAL_HEADER}</Header>
                    {this.props.requestType === "put" &&
                    <Segment secondary compact style={{
                        marginBottom: "-42px",
                        marginTop: "-1px",
                        marginLeft: "auto",
                        marginRight: "22px"
                    }}>
                        <Form>
                            <Form.Field>
                                <Form.Checkbox
                                    label={ACTIVE_LABEL()}
                                    onChange={this.props.onHandleChange}
                                    checked={this.props.userFields && this.props.userFields.userEnabled}
                                    fluid={"true"}
                                    name={"userEnabled"}
                                />
                            </Form.Field>
                        </Form>
                    </Segment>}
                    <Modal.Content>
                        <Form>

                            <Form.Field required>
                                <label>{FULLNAME_HEADER()}</label>
                                <Form.Group inline>
                                <Form.Input
                                    className='add-user-input-field'
                                    width={16}
                                    disabled={this.props.userFields.ldapuser}
                                    fluid
                                    name={"fullName"}
                                    value={this.props.userFields && this.props.userFields.fullName}
                                    onChange={this.props.onHandleChange}
                                    error={(this.props.userFields.fullName?.match(validFullname) === null)}
                                />
                                    <Popup
                                        trigger={<Icon name={INFO_ICON} size='large' color="grey"/>}
                                        content={addNewUserMessages().FULLNAME_INPUT_INFO_MESSAGE}
                                        position='right center'
                                    />
                                </Form.Group>
                            </Form.Field>
                            <Form.Field required>
                                <label>{USERNAME_HEADER()}</label>
                                <Form.Group inline>
                                    <Form.Input
                                        className='add-user-input-field'
                                        width={16}
                                        fluid
                                        disabled={(this.props.userFields.ldapuser) || (this.props.userFields.id !== undefined && this.props.userFields.userName === "admin")}
                                        name={"userName"}
                                        value={this.props.userFields && this.props.userFields.userName}
                                        onChange={this.props.onHandleChange}
                                        error={(this.props.userFields.userName?.match(validUsername) === null)}
                                    />
                                    <Popup
                                        trigger={<Icon name={INFO_ICON} size='large' color="grey"/>}
                                        content={addNewUserMessages().USERNAME_INPUT_INFO_MESSAGE}
                                        position='right center'
                                    />
                                </Form.Group>
                            </Form.Field>
                            {!this.props?.userFields?.ldapuser ?
                                <Form.Field required={this.props.requestType === "post"}>
                                    <label>{PASSWORD_HEADER()} {this.props.requestType === "put" &&
                                        <Popup position={"right center"} trigger={<Icon name={INFO_ICON} />} content={<span
                                            style={{ fontWeight: "normal" }}>{addNewUserMessages().PASSWORD_INPUT_INFO_MESSAGE}</span>} />}</label>
                                    {!this.props?.userFields?.id ? <Input
                                        disabled={this.props.userFields.ldapuser}
                                        name={"password"}
                                        value={this.props.userFields && this.props.userFields.password ? this.props.userFields.password : ""}
                                        type={this.props.showPassword ? 'text' : 'password'}
                                        onChange={this.props.onHandleChange}
                                        icon={
                                            <Icon
                                                name={this.props.showPassword ? EYE_SLASH_ICON : EYE_ICON}
                                                link
                                                onClick={this.props.onTogglePassword}
                                            />
                                        }
                                    />
                                        :
                                        <ChangePasswordInput
                                            name='password'
                                            value={this.props.userFields?.password}
                                            onChange={this.props.onHandleChange}
                                            reset={this.props.resetPasswordInput} />
                                    }
                                </Form.Field>
                                :
                                null}
                            <Form.Field required>
                                <label>{USER_MAIL_HEADER()}</label>
                                <Form.Input
                                    fluid
                                    disabled={this.props.userFields.ldapuser}
                                    name={"userMail"}
                                    value={this.props.userFields && this.props.userFields.userMail}
                                    onChange={this.props.onHandleChange}
                                    error={((this.props.userFields.userMail?.match(validEmail) === null && (this.props.userFields.userMail !== "")))}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{USER_ROLES_LABEL()}</label>
                                <Dropdown
                                    disabled={this.props.userFields.id !== undefined && this.props.userFields.userName === "admin"}
                                    selection multiple options={this.props.roleDropdown} clearable
                                    onChange={this.props.onHandleChangeRoles} value={this.props.selectedRoles}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{USER_GROUPS_HEADER()}</label>
                                <Dropdown
                                    disabled={this.props.userFields.id !== undefined && this.props.userFields.userName === "admin"}
                                    selection multiple options={this.props.groupDropdown} clearable
                                    onChange={this.props.onHandleChangeGroups} value={this.props.selectedGroups}/>
                            </Form.Field>
                            <Popup
                                content={addNewUserMessages().NOTIFICATION_USER_POPUP_MESSAGE}
                                trigger={
                                    <Segment compact>
                                        <Form.Field>
                                            <Form.Checkbox
                                                label={addNewUserMessages().NOTIFICATION_USER_LABEL}
                                                onChange={this.props.onHandleChange}
                                                checked={this.props.userFields && this.props.userFields.enableMail}
                                                fluid
                                                name="enableMail"
                                            />
                                        </Form.Field>
                                    </Segment>}
                                on={"hover"}
                                position={"right center"}
                            />
                        </Form>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button.Group>
                            <Button icon labelPosition='right' color='green' onClick={this.props.onSubmit}
                                loading={this.props.pendingRequestUserFormProp}
                                disabled={
                                    this.props.pendingRequestUserFormProp ||
                                    !this.props?.userFields?.userName
                                    || (this.props.requestType === 'post' && !this.props.userFields.password)
                                    || (this.props.userFields?.fullName?.trim().length < 2)
                                    || !(this.props.userFields?.fullName?.trim())
                                    || (this.props.userFields?.fullName?.trim().length > 30)
                                    || this.props.userFields.userName === undefined
                                    || (this.props.userFields.id === undefined && this.props.userFields.userName === "admin")
                                    || this.props.userFields.userName?.match(validUsername) === null
                                    || this.props.userFields.userMail?.match(validEmail) === null
                                    || this.props.userFields.fullName?.match(validFullname) === null
                                    || !this.props.selectedRoles.length
                                }>
                                <Icon
                                    name={USER_PLUS_ICON}/> {SAVE_BUTTON()}</Button>
                        </Button.Group>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(AddNewUser);
