import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Button, Message, Form, Modal } from 'semantic-ui-react'
import PinInput from 'react-pin-input';
import { twoFactorAuthMessages } from '../utils/UIMessages';

class TwoFactorAuthenticationModal extends Component {
    render() {
        return (
            <div>
                <Modal closeIcon closeOnDimmerClick={true} open={this.props.twoFactorModalVisibility}
                    onClose={this.props.onClose}
                    size='tiny'>
                    <Modal.Header className='otp-modal-header'>{twoFactorAuthMessages().MODAL_HEADER}</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field>
                                <p className='otp-modal-description'>{twoFactorAuthMessages().MODAL_DESCRIPTION}</p>
                            </Form.Field>
                            <Form.Field>
                                <PinInput
                                    id='TwoFactorAuthenticationPin'
                                    length={6}
                                    focus
                                    //secret
                                    style={{textAlign: 'center'}}
                                    ref={p => (this.pin = p)}
                                    type='numeric'
                                    onChange={this.props.onChangeTFPin}
                                />
                                <div style={{textAlign: 'right'}}>
                                    <a style={{ fontSize: 10}} id='MFAReset' href='' onClick={this.props.handleSubmit}>{twoFactorAuthMessages().RESET_TWO_FACTOR_AUTH_REGISTRATION_SECRET}</a>
                                </div>
                            </Form.Field>
                            <Form.Field >
                                <Button color='orange' fluid size='huge' onClick={this.props.handleSubmit} disabled={!this.props.twoFactorVerificationCode || this.props.twoFactorVerificationCode.length !== 6} id='MFASubmit'>
                                    {twoFactorAuthMessages().VERIFY_BUTTON}
                                </Button>
                            </Form.Field>
                            <Message color='yellow' hidden={this.props.hideTwoFactorMessage}>
                                {this.props.t(this.props.twoFactorInfoMessage)}
                            </Message>
                        </Form>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

export default withTranslation()(TwoFactorAuthenticationModal)