import React, {Component} from 'react';
import {Button, Form, Header, Icon, Label, Menu, Table, Popup, ModalContent, Modal,ModalActions, Confirm} from "semantic-ui-react";
import {withTranslation} from 'react-i18next'
import {Base64} from "js-base64";
import copy from "copy-to-clipboard";
import {Flip, toast} from "react-toastify";
import {getDate, maskString} from "../../utils/Methods";
import {showError, showInfo, showSuccess} from '../../utils/ToastHelpers';
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8';
import {
    POPUP_POSITIONS
} from '../../utils/Constants';
import {
    getAutomationAccessKey,
    createAutomationAccessKey,
    deleteAutomationAccessKey,
    refreshAutomationAccessKey,
    changePassword,
    getApiKey,
    createApiKey,
    deleteApiKey,
} from '../../api/apiCalls'

import { encryptData } from '../../utils/PublicKeyHelper';
import { ACTIONS_HEADER, COPIED_LABEL, COPY_BUTTON, FAILED_ERROR_MESSAGE, GENERATE_BUTTON, KEY_LABEL, profileMessages, SUCCESS_MESSAGE, CLOSE_BUTTON, DOWNLOAD_BUTTON, DELETE_BUTTON, CONFIRM_BUTTON, CANCEL_BUTTON, REFRESH_BUTTON } from '../../utils/UIMessages';
import { EYE_ICON, EYE_SLASH_ICON, REFRESH_ICON, USER_ICON, COPY_ICON, DOWNLOAD_ICON, TRASH_ICON } from '../../utils/UiIcons';


class ProfilePage extends Component {
    state = {
        userDetails: {},
        automationKey: "",
        showAutomationKey: {},
        automationKeysList: [],
        apiKeysList: [],
        apiKey: "",
        showApiKey: {},
        showOldPassword: false,
        showNewPassword: false,
        oldPassword: "",
        newPassword: "",
        activeItem:'RoleAndPrivileges',
        keyName: '',
        keyAddingModalOpen: false,
        selectedAutomationKey: null,
        confirmVisibility: false,
        onConfirmHook: () => {},
        confirmMessage: null,
    };

    getAutomationAccessKeys = () =>{
        getAutomationAccessKey().then(res => {
            this.setState({ automationKeysList: res.data })
        }).catch(err => {
            if (err?.response?.status === 404 || err?.response?.status === 400) { //first situation, no access key
                showError(profileMessages().NOT_HAVE_AUTOMATION_KEY_MESSAGE + err?.response?.data?.message ?? err.toString())
            } else {
                showError(profileMessages().NOT_FETCH_AUTOMATION_KEY + err?.response?.data?.message ?? err.toString())
            }
        })
    }  

    getAutomationApiKeys = () =>{
        
        getApiKey().then(res => {
            this.setState({ apiKeysList: res.data })
        }).catch(err => {
            if (err?.response?.status === 404 || err?.response?.status === 400) {
                this.setState({ apiKey: profileMessages().NOT_HAVE_API_KEY_MESSAGE})
            } else {
                showError(profileMessages().NOT_FETCH_API_KEY + err?.response?.data?.message ?? err.toString())
            }
        })
    }

    componentDidMount() {
        this.getAutomationAccessKeys();
        this.getAutomationApiKeys();  
      
        let userDetails = JSON.parse(Base64.decode(localStorage.getItem("userDetails")))
        this.setState({ userDetails })
    }

    closeAutomationKeyGeneratinModal = () =>{
        this.setState({ keyName: '', keyAddingModalOpen: false, automationKey: '' });
    }
    closeApiKeyGeneratinModal = () =>{
        this.setState({ keyName: '', keyAddingModalOpen: false, apiKey: '' });
    }

    refreshAutomationKey = () => {
        refreshAutomationAccessKey(this.state.selectedAutomationKey).then(() => {
            showSuccess(profileMessages().SUCCESS_MSG_AUTOMATION_KEY_REFRESHED)
        }).catch(err => {
            showError(profileMessages().NOT_REFRESH_AUTOMATION_KEY + err?.response?.data?.message ?? err.toString())
        }).finally(() => {
            this.setState({confirmVisibility: false, selectedAutomationKey: null, confirmMessage: null, onConfirmHook: () => {}})
            this.getAutomationAccessKeys();
        })
    }

    generateKey = (key) => {
        if (key === "automationKey") {
            createAutomationAccessKey(this.state.keyName).then(res => {
                this.setState({automationKey: res.data.accessKey})
                this.getAutomationAccessKeys();
                showSuccess(profileMessages().SUCCESS_MSG_AUTOMATION_KEY_GENERATED)
            }).catch(err => {
                showError(profileMessages().NOT_GENERATE_AUTOMATION_KEY + err?.response?.data?.message ?? err.toString())
            })
        } else if (key === "apiKey") {
            createApiKey(this.state.keyName).then(res => {
                this.setState({ apiKey: res.data.apiKey })
                this.getAutomationApiKeys();
                showSuccess(profileMessages().SUCCESS_MSG_API_KEY_GENERATED)
            }).catch(err => {
                showError(profileMessages().NOT_GENERATE_API_KEY + err?.response?.data?.message ?? err.toString())
            })
        }
    }

    copyKey = (key) => {
        copy(this.state[key]);
        showInfo(COPIED_LABEL(), 1000);
    }

    downloadTxtFile = (key) => {
        console.log(key)
        const element = document.createElement("a");
        const file = new Blob([this.state[key]], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${key}_${this.state.keyName}_${getDate()}.txt`;
        document.body.appendChild(element);
        element.click();
        element.remove();
    }

    onToggleShowKey = (id) =>
    this.setState((prevState) => ({
        showApiKey: {
            ...prevState.showApiKey,
            [id]: !prevState.showApiKey[id]
        }
    }));


    toggleAutomationKeyVisibility = (id) => {
        this.setState((prevState) => ({
            showAutomationKey: {
                ...prevState.showAutomationKey,
                [id]: !prevState.showAutomationKey[id]
            }
        }));
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    changePassword = async () => {
        const oldPassword = await encryptData(this.state.oldPassword);
        const newPassword = await encryptData(this.state.newPassword);
        //axios.post(`/api/user/change-password/${this.state.userDetails.userName}?oldPassword=${Base64.encode(this.state.oldPassword)}&newPassword=${Base64.encode(this.state.newPassword)}`)
        changePassword(this.state.userDetails.userName, { oldPassword, newPassword }).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                autoClose: 3000,
                transition: Flip
            })
            this.setState({
                oldPassword: "",
                newPassword: ""
            })
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    deleteAutomationKey = () => {
        deleteAutomationAccessKey(this.state.selectedAutomationKey).then(()=>{
            showSuccess(profileMessages().AUTOMATION_KEY_DELETED_SUCCESSFULLY, 1000);
            this.getAutomationAccessKeys();
        })
        .catch(err=>{
            showError(profileMessages().AUTOMATION_KEY_DELETION_ERROR + err?.response?.data?.message ?? err.toString());
        }).finally(() => {
            this.setState({confirmVisibility: false, selectedAutomationKey: null, confirmMessage: null, onConfirmHook: () => {}})
        })
    }
     deleteApiKey = id => {
        deleteApiKey(id).then(()=>{
        showSuccess(profileMessages().API_KEY_DELETED_SUCCESSFULLY, 1000);
             this.getAutomationApiKeys();
         })
         .catch(err=>{
        showError(profileMessages().API_KEY_DELETION_ERROR + err?.response?.data?.message ?? err.toString());
        })
     }

    columns = () => [
        {
            header: () => <div>{profileMessages().AUTOMATION_KEY_NAME}</div>,
            accessorKey: 'keyName',
            id: 'keyName',
            // filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{profileMessages().AUTOMATION_KEY_HEADER}</div>,
            accessorKey: 'accessKey',
            id: 'accessKey',
            enableSorting: false,
            enableColumnFilter: false,
            cell: info => (<span>
                {
                    <Form>
                            <Form.Input
                                readOnly
                                className="automation-key-view-table-input-area"
                                value={maskString(info.getValue(), 9, 36, /[a-z0-9]/g)}
                                type={this.state.showAutomationKey[info.row?.original.id] ? 'text' : 'password'}
                                icon={
                                    <Icon
                                        name={this.state.showAutomationKey[info.row?.original.id] ? EYE_SLASH_ICON : EYE_ICON}
                                        link
                                        onClick={() => this.toggleAutomationKeyVisibility(info.row?.original.id)}
                                    />
                                }
                                autoComplete="automation-key"
                            />
                    </Form>
                }
            </span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            id: 'id',
            accessorKey: 'id',
            enableSorting: false,
            enableColumnFilter: false,
            cell: info => {
                let accessKey = info.row?.original.accessKey;
                return (<span>
                    <Popup position={POPUP_POSITIONS.TOP_RIGHT} content={COPY_BUTTON()}
                        trigger={
                            <Button size='tiny' icon={COPY_ICON} onClick={() => {
                                copy(accessKey);
                                showInfo(COPIED_LABEL(), 1000);
                            }} />
                        }
                    />
                    <Popup position={POPUP_POSITIONS.TOP_RIGHT} content={REFRESH_BUTTON()}
                        trigger={
                            <Button size='tiny' icon={REFRESH_ICON} onClick={() => {
                                this.setState({ confirmVisibility: true, 
                                    confirmMessage: profileMessages().REFRESH_CONFIRM,
                                    onConfirmHook: () => {
                                        this.refreshAutomationKey()
                                    },
                                    selectedAutomationKey: info.row?.original.id })
                            }} />
                        }
                    />
                    <Popup position={POPUP_POSITIONS.TOP_RIGHT} content={DOWNLOAD_BUTTON()}
                        trigger={
                            <Button size='tiny' icon={DOWNLOAD_ICON} onClick={() => {
                                this.setState({automationKey: accessKey, keyName: info.row?.original.keyName}, () => {
                                    this.downloadTxtFile("automationKey");
                                });
                                
                            }} />
                        }
                    />
                    <Popup position={POPUP_POSITIONS.TOP_RIGHT} content={DELETE_BUTTON()}
                        trigger={
                            <Button size='tiny' icon={TRASH_ICON} onClick={() => {
                                this.setState({ confirmVisibility: true, 
                                    confirmMessage: profileMessages().DELETE_CONFIRM,
                                    onConfirmHook: () => {
                                        this.deleteAutomationKey()
                                    },
                                    selectedAutomationKey: info.row?.original.id })
                            }} />
                        }
                    />
                    <Confirm
                        open={this.state.confirmVisibility}
                        content={this.state.confirmMessage}
                        onClose={() => this.setState({confirmVisibility: false, selectedAutomationKey: null})}
                        onCancel={() => this.setState({confirmVisibility: false, selectedAutomationKey: null})}
                        onConfirm={this.state.onConfirmHook}
                        confirmButton={CONFIRM_BUTTON()}
                        cancelButton={CANCEL_BUTTON()}
                    />
                </span>)
            }
        }
    ];
    columnsForApiKey = () => [
        {
            header: () => <div>{profileMessages().API_KEY_NAME}</div>,
            accessorKey: 'keyName',
            id: 'keyName',
            // filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{profileMessages().API_KEY_HEADER}</div>,
            accessorKey: 'apiKey',
            id: 'apiKey',
            enableSorting: false,
            enableColumnFilter: false,
            cell: info => (<span>
                {
                  <Form>
                  <Form.Input
                      readOnly
                      className="automation-key-view-table-input-area"
                      value={info.getValue()}
                      type={this.state.showApiKey[info.row?.original.id] ? 'text' : 'password'}
                      icon={
                          <Icon
                              name={this.state.showApiKey[info.row?.original.id] ? EYE_SLASH_ICON : EYE_ICON}
                              link
                              onClick={() => this.onToggleShowKey(info.row?.original.id)} // Pass the id as parameter
                          />
                      }
                      autoComplete="api-key"
                  />
              </Form>
              
                }
            </span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            id: 'id',
            accessorKey: 'id',
            enableSorting: false,
            enableColumnFilter: false,
            cell: info => {
                return (<span>
                    <Popup position={POPUP_POSITIONS.TOP_RIGHT} content={DELETE_BUTTON()}
                        trigger={
                            <Button size='tiny' icon={TRASH_ICON} onClick={() => {
                                this.deleteApiKey(info.row?.original.id);
                            }} />
                        }
                    />
                </span>)
            }
        }
    ];
    renderRolesAndPrivileges = () => {
        return <>
            {this.state.userDetails?.roles?.map((role, i) => {
                return <div key={i}><Header size={"small"}>{role.name}</Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{profileMessages().PRIVILEGE_NAME_LABEL}</Table.HeaderCell>
                                <Table.HeaderCell>{profileMessages().PRIVILEGE_DESCRIPTION_LABEL}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {role.privileges.map((privilege, i) => {
                                return <Table.Row key={i}>
                                    <Table.Cell>
                                        <Header as='h4'>
                                            <Header.Content>
                                                {this.props.t(privilege.name)}
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{this.props.t(privilege.description)}</Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>

                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Label color='blue' ribbon={"right"}>
                                        {profileMessages().TOTAL_NUMBER_OF_PRIVILEGES_LABEL}: {role?.privileges?.length}
                                    </Label>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
            })}
        </>
    }

    renderChangePassword = () => {
        return <>
            {
                !this.state.userDetails.ldapuser && <>
                    <Form name='changePassword'>
                        <Form.Field inline>
                            <Form.Input
                                name='oldPassword'
                                onFocus={(e) => {
                                    e.target.select()
                                }}
                                onClick={(e) => {
                                    e.target.select()
                                }}
                                onChange={this.handleChange}
                                className="centeredPlaceholder"
                                placeholder={profileMessages().OLD_PASSWORD_PLACEHOLDER}
                                type={this.state.showOldPassword ? 'text' : 'password'}
                                value={this.state.oldPassword}
                                icon={
                                    <Icon
                                        name={this.state.showOldPassword ? EYE_SLASH_ICON : EYE_ICON}
                                        link
                                        onClick={() => this.onToggleShowKey("showOldPassword")}
                                    />
                                }
                            />
                        </Form.Field>
                        <Form.Field inline>
                            <Form.Input
                                name='newPassword'
                                onFocus={(e) => {
                                    e.target.select()
                                }}
                                onClick={(e) => {
                                    e.target.select()
                                }}
                                onChange={this.handleChange}
                                className="centeredPlaceholder"
                                placeholder={profileMessages().NEW_PASSWORD_PLACEHOLDER}
                                type={this.state.showNewPassword ? 'text' : 'password'}
                                value={this.state.newPassword}
                                icon={
                                    <Icon
                                        name={this.state.showNewPassword ? EYE_SLASH_ICON : EYE_ICON}
                                        link
                                        onClick={() => this.onToggleShowKey("showNewPassword")}
                                    />
                                }
                            />
                        </Form.Field>
                        <Button color={"blue"}
                                disabled={!this.state.oldPassword || !this.state.newPassword}
                                onClick={this.changePassword}>
                            {profileMessages().CHANGE_PASSWORD_BUTTON}
                        </Button>
                    </Form>
                </>}
        </>
    }

    renderAutomationKey = () => {
        return <div>
            <div className='key-generation-text-and-button'>
                <h5>{profileMessages().TOTAL_NUM_OF_OWNED_KEYS}:{this.state.automationKeysList?.length || 0}</h5>
                <Modal
                    className='automation-key-generator-modal'
                    closeIcon
                    open={this.state.keyAddingModalOpen}
                    trigger={<Button className='generate-new-key-button'>{profileMessages().AUTOMATION_KEY_GENERATE_MODAL_HEADER}</Button>}
                    onClose={() => this.closeAutomationKeyGeneratinModal()}
                    onOpen={() => this.setState({ keyAddingModalOpen: true })}
                >
                    <Header textAlign='center' content={profileMessages().AUTOMATION_KEY_GENERATE_MODAL_HEADER} />
                    <ModalContent>
                        <p>{profileMessages().WARNING_ABOUT_MAX_ATTAINABLE_KEY_NUMBER}</p>
                        <p>{profileMessages().YOUR_CURRENT_NUM_OF_AUTOMATION_KEYS}:  <b>{this.state.automationKeysList?.length}</b></p>

                        <Form>
                            <p className='automation-key-generator-input-label'>{`${profileMessages().AUTOMATION_KEY_NAME}`}</p>
                            <Form.Field className='automation-key-custom-name-input-area' required>
                                <Form.Input
                                    className='automation-key-generator-key-name-input'
                                    placeholder={profileMessages().AUTOMATION_KEY_GIVE_NAME_PLACEHOLDER}
                                    value={this.state.keyName}
                                    onChange={e => this.setState({ keyName: e.target.value })}
                                />
                                <Button size='small' color={'blue'} className='automation-key-generate-button-modal'
                                    onClick={() => this.generateKey('automationKey')} disabled={this.state.keyName.length < 3 || this.state.keyName.length > 30 || this.state.automationKeysList?.length === 20 }>
                                    <Icon name={REFRESH_ICON} /> {GENERATE_BUTTON()}
                                </Button>
                            </Form.Field>

                            <hr className='hr-key-generator-modal' />

                            <Form.Field disabled={!this.state.automationKey}>
                                <p className='automation-key-generator-output-label'>{`${profileMessages().AUTOMATION_KEY_GENERATED}`}</p>
                                <Form.Input
                                    readOnly
                                    placeholder={KEY_LABEL()}
                                    value={this.state.automationKey}
                                />
                                <Button color={"blue"} className='automation-key-modal-output-operations-btn'
                                    disabled={this.state.automationKey?.length === 0 ||
                                        this.state.automationKey === null || this.state.automationKey === undefined
                                    }
                                    onClick={() => this.copyKey("automationKey")}>
                                    {COPY_BUTTON()}
                                </Button>
                                <Button color={"blue"} className='automation-key-modal-output-operations-btn'
                                    disabled={this.state.automationKey?.length === 0 ||
                                        this.state.automationKey === null || this.state.automationKey === undefined
                                    }
                                    onClick={() => this.downloadTxtFile("automationKey")}>
                                    {profileMessages().DOWNLOAD_TXT_BUTTON}
                                </Button>
                            </Form.Field>
                        </Form>

                    </ModalContent>
                    <ModalActions className='automation-key-generator-modal-actions'>
                        <Button color='red' onClick={() => this.closeAutomationKeyGeneratinModal()}>
                            <Icon name='remove' /> {CLOSE_BUTTON()}
                        </Button>

                    </ModalActions>
                </Modal>
            </div>

            <ReactTableV8
                data={this.state.automationKeysList}
                columns={this.columns()}
            />
        </div>
    }
    
    renderApiKey=()=>{
       return <div>
            <div className='key-generation-text-and-button'>
                <h5>{profileMessages().TOTAL_NUM_OF_OWNEDAPI_KEYS}:{this.state.apiKeysList?.length || 0}
                {console.log("this.state.apiKeysList?.length ", this.state.apiKeysList?.length )}
                </h5>
                <Modal
                    className='automation-key-generator-modal'
                    closeIcon
                    open={this.state.keyAddingModalOpen}
                    trigger={<Button className='generate-new-key-button'>{profileMessages().API_KEY_GENERATE_MODAL_HEADER}</Button>}
                    onClose={() => this.closeApiKeyGeneratinModal()}
                    onOpen={() => this.setState({ keyAddingModalOpen: true })}
                >
                    <Header textAlign='center' content={profileMessages().API_KEY_GENERATE_MODAL_HEADER} />
                    <ModalContent>
                        <p>{profileMessages().WARNING_ABOUT_MAX_ATTAINABLE_APIKEY_NUMBER}</p>
                        <p>{profileMessages().YOUR_CURRENT_NUM_OF_AUTOMATION_KEYS}:  <b>{this.state.apiKeysList?.length}</b></p>

                        <Form>
                            <p className='automation-key-generator-input-label'>{`${profileMessages().API_KEY_NAME}`}</p>
                            <Form.Field className='automation-key-custom-name-input-area'>
                                <Form.Input
                                    className='automation-key-generator-key-name-input'
                                    placeholder={profileMessages().AUTOMATION_KEY_GIVE_NAME_PLACEHOLDER}
                                    value={this.state.keyName}
                                    onChange={e => this.setState({ keyName: e.target.value })} />
                                <Button size='small' color={'blue'} className='automation-key-generate-button-modal'
                                    onClick={() => this.generateKey('apiKey')}>
                                    <Icon name={REFRESH_ICON} /> {GENERATE_BUTTON()}
                                </Button>
                            </Form.Field>

                            <hr className='hr-key-generator-modal' />

                            <Form.Field disabled={!this.state.apiKey}>
                                <p className='automation-key-generator-output-label'>{`${profileMessages().AUTOMATION_KEY_GENERATED}`}</p>
                                <Form.Input
                                    readOnly
                                    placeholder={KEY_LABEL()}
                                    value={this.state.apiKey}
                                />
                                <Button color={"blue"} className='automation-key-modal-output-operations-btn'
                                    disabled={this.state.apiKey?.length === 0 ||
                                        this.state.apiKey === null || this.state.apiKey === undefined
                                    }
                                    onClick={() => this.copyKey("apiKey")}>
                                    {COPY_BUTTON()}
                                </Button>
                                <Button color={"blue"} className='automation-key-modal-output-operations-btn'
                                    disabled={this.state.apiKey?.length === 0 ||
                                        this.state.apiKey === null || this.state.apiKey === undefined
                                    }
                                    onClick={() => this.downloadTxtFile("apiKey")}>
                                    {profileMessages().DOWNLOAD_TXT_BUTTON}
                                </Button>
                            </Form.Field>
                        </Form>

                    </ModalContent>
                    <ModalActions className='automation-key-generator-modal-actions'>
                        <Button color='red' onClick={() => this.closeApiKeyGeneratinModal()}>
                            <Icon name='remove' /> {CLOSE_BUTTON()}
                        </Button>

                    </ModalActions>
                </Modal>
            </div>

            <ReactTableV8
                data={this.state.apiKeysList}
                columns={this.columnsForApiKey()}
            />
        </div>
    }


    render() {
    let content, pageHeader;
    const {activeItem} = this.state
    if (activeItem === 'RoleAndPrivileges'){
        content = this.renderRolesAndPrivileges()
        pageHeader = profileMessages().ROLES_AND_PRIVILEGES_HEADER
    }
    if (activeItem === 'ChangePassword'){
        content = this.renderChangePassword()
        pageHeader = profileMessages().CHANGE_PASSWORD_HEADER
    }
    if (activeItem === 'AutomationKey'){
        content = this.renderAutomationKey()
        pageHeader = profileMessages().AUTOMATION_KEY_HEADER
    }
    if (activeItem === 'APIKey'){
        content = this.renderApiKey()
        pageHeader = profileMessages().API_KEY_LABEL
    }
        return (
            <>
                <div className="main-container">
                    <div className="main-left-container profile-page-left-menu">
                        <h1>{profileMessages().PROFILE_PAGE_HEADER}</h1>
                        <div className="main-left-content">
                            <div className="profile-me-box">
                                <Icon name={USER_ICON} circular />
                                <>{this.state.userDetails.fullName}</>
                                <div>{this.state.userDetails.userMail}</div>
                            </div>

                            <Menu vertical fluid secondary >
                                <Menu.Item
                                    name='RoleAndPrivileges'
                                    active={this.state.activeItem === 'RoleAndPrivileges'}
                                    onClick={this.handleItemClick}
                                >
                                    {profileMessages().ROLES_AND_PRIVILEGES_HEADER}

                                </Menu.Item>

                                {!this.state.userDetails.ldapuser && <Menu.Item
                                    name='ChangePassword'
                                    active={this.state.activeItem === 'ChangePassword'}
                                    onClick={this.handleItemClick}
                                >
                                    {profileMessages().CHANGE_PASSWORD_HEADER}
                                    
                                </Menu.Item>}

                                <Menu.Item
                                    name='AutomationKey'
                                    active={this.state.activeItem === 'AutomationKey'}
                                    onClick={this.handleItemClick}
                                >
                                    {profileMessages().AUTOMATION_KEY_HEADER}
                                    
                                </Menu.Item>
                                <Menu.Item
                                    name='APIKey'
                                    active={this.state.activeItem === 'APIKey'}
                                    onClick={this.handleItemClick}
                                >
                                    {profileMessages().API_KEY_LABEL}
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>
                    <div className="main-right-container">
                        <div className="main-right-header">
                            <h2>{pageHeader}</h2>
                        </div>
                        {content}
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ProfilePage);