import React, {Component} from 'react';
import {Button, Form, Header, Icon, Modal} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import {Flip, toast} from "react-toastify";
import {showError} from '../../../utils/ToastHelpers'
import { DAILYLY_DATETIME_FORMAT, YEARLY_DATE_FORMAT, validLimit} from "../../../utils/Constants";
import {
    createGroup,
    updateGroup
} from '../../../api/apiCalls'
import GroupUsersReservationModal from './GroupUsersReservationModal';
import { ACTIVE_LABEL, CONCURRENT_ACCESS_LIMIT_LABEL, FAILED_ERROR_MESSAGE, GROUP_DESCRIPTION_HEADER, GROUP_NAME_HEADER, newGroupMessages, RESERVATION_LABEL, SAVE_BUTTON, SUCCESS_MESSAGE, USER_GROUP_PROPERTIES_HEADER } from '../../../utils/UIMessages';
import { USER_PLUS_ICON } from '../../../utils/UiIcons';
import { PrivilegeConstants } from '../../../utils/PrivilegeConstants';
import DatePicker  from 'react-datepicker';
import moment from 'moment';

const defaultValues ={bookable:false,concurrentAccessLimit:-1,name:null}

class NewGroupModal extends Component {

    state = {
        selectedGroup: this.props.selectedGroup || {...defaultValues},
        groupHasNoAccessLimit: this.props.selectedGroup?.concurrentAccessLimit ? this.props.selectedGroup?.concurrentAccessLimit === -1 : true,
        previousAccessLimit: this.props.selectedGroup?.concurrentAccessLimit ?? -1,
        groupUsersReservationModalVisibility:false,
        permanentGroup: true,
        notActiveAfter: this.props.selectedGroup?.notActiveAfter ? new Date(this.props.selectedGroup?.notActiveAfter) : null
    }


    componentDidUpdate(prevProps) {
        if (prevProps.addModalVisibility !== this.props.addModalVisibility) {
            this.setState({
                selectedGroup: this.props.selectedGroup || {...defaultValues},
                groupHasNoAccessLimit: this.props.selectedGroup?.concurrentAccessLimit ? this.props.selectedGroup?.concurrentAccessLimit === -1 : true,
                previousAccessLimit: this.props.selectedGroup?.concurrentAccessLimit ?? -1,
                notActiveAfter: this.props.selectedGroup?.notActiveAfter ? new Date(this.props.selectedGroup?.notActiveAfter) : null
            })
        }
    }

    handleOnLimit = (e, {name, value}) => {
        if(value.match(validLimit) !== null){
            this.setState(prevState => (
                {
                    selectedGroup: {
                        ...prevState.selectedGroup,
                        [name]: value
                    }
                }))
        }
    }


    handleOnChangeNoLimit = () => {
        this.setState(prevState => ({
            groupHasNoAccessLimit:  !prevState.groupHasNoAccessLimit
        }), () => {
            this.setState(prevState => ({
                previousAccessLimit: this.state.groupHasNoAccessLimit ? prevState.selectedGroup?.concurrentAccessLimit : prevState.previousAccessLimit,
                selectedGroup: {
                    ...prevState.selectedGroup,
                    concurrentAccessLimit: this.state.groupHasNoAccessLimit ? -1 : prevState.previousAccessLimit
                }
            }))
        })

    }

    handleChange = (e, {name, value, checked}) => {
        this.setState(prevState => (
            {
                selectedGroup: {
                    ...prevState.selectedGroup,
                    [name]: value
                }
            }))
        if (checked !== undefined) {
            this.setState(prevState => (
                {
                    selectedGroup: {
                        ...prevState.selectedGroup,
                        [name]: checked
                    }
                }))
        }
    }

    handleChangeDate = date => {
        this.setState(prevState => (
            {
                selectedGroup: {
                    ...prevState.selectedGroup,
                    ['notActiveAfter']: moment(date).format(DAILYLY_DATETIME_FORMAT)
                },
                notActiveAfter: date
            }))
    }

    handleBookableChange = (e,data) =>this.setState(prevState =>({
        selectedGroup:{
            ...prevState.selectedGroup,
            bookable:data.checked
        }
    }))
    

    onSubmit = () => {
        let data =  this.state.selectedGroup
        if (!data.active) {
            data.active = false
        }
        if (this.props.requestType === 'post') {
            createGroup(data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    transition: Flip,
                    autoClose: 3000
                })
                this.props.handleModalClose();
                this.setState({selectedGroup: null})
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + newGroupMessages(err?.response?.data?.message ?? err.toString()).CREATE_UPDATE_GROUP_ERROR_MESSAGE)
            })
        } else {
            updateGroup(this.state.selectedGroup?.id, data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    transition: Flip,
                    autoClose: 3000
                })
                this.props.handleModalClose();
                this.setState({selectedGroup: null});
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + newGroupMessages(err?.response?.data?.message ?? err.toString()).CREATE_UPDATE_GROUP_ERROR_MESSAGE)
            })
        }
    }

    validationCheck = () => {
        return !this.state.selectedGroup?.concurrentAccessLimit ||
        this.state.selectedGroup?.concurrentAccessLimit === '-' ||
        !this.state.selectedGroup?.name?.trim()
    }

    render() {
        return (
            <div>
                <Modal open={this.props.addModalVisibility} onClose={this.props.handleModalClose} closeIcon
                       closeOnDimmerClick={true}
                       size={'small'}>
                    <Header style={{textAlign: 'center'}}>{USER_GROUP_PROPERTIES_HEADER()}</Header>

                    <Modal.Content>
                        <Form>
                            <Form.Field required>
                                <label>{GROUP_NAME_HEADER()}</label>
                                <Form.Input
                                    fluid
                                    name={'name'}
                                    value={this.state.selectedGroup?.name || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{GROUP_DESCRIPTION_HEADER()}</label>
                                <Form.Input
                                    fluid
                                    name={'description'}
                                    value={this.state.selectedGroup?.description || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            {(this.state.selectedGroup?.id && this.props.privileges.includes(PrivilegeConstants.RESERVATION.GROUP)) && 
                                <Form.Field >
                                    <div id='group-reservation-container'>
                                        <Form.Checkbox 
                                            toggle
                                            checked={this.state.selectedGroup?.bookable}
                                            onChange={this.handleBookableChange}
                                            label={RESERVATION_LABEL()}
                                        />
                                        {this.state.selectedGroup?.bookable &&
                                            <div>
                                                <Button
                                                    content={newGroupMessages().EDIT_RESERVATION_USER_LIST_BUTTON}
                                                    primary
                                                    size='tiny'
                                                    onClick={() => this.setState({ groupUsersReservationModalVisibility: true })}
                                                />
                                            </div>
                                        }
                                    </div>
                                </Form.Field>
                            }
                            <Form.Field >
                                <label>{CONCURRENT_ACCESS_LIMIT_LABEL()}</label>
                                {
                                    !this.state.groupHasNoAccessLimit &&
                                    <Form.Input
                                        fluid
                                        name={'concurrentAccessLimit'}
                                        value={this.state.selectedGroup?.concurrentAccessLimit}
                                        onChange={this.handleOnLimit}
                                    />
                                }
                                <Form.Checkbox
                                    toggle
                                    label={newGroupMessages().NO_LIMIT_LABEL}
                                    checked={this.state.groupHasNoAccessLimit}
                                    onChange={this.handleOnChangeNoLimit}
                                />
                            </Form.Field>
                             <Form.Field>
                                <Form.Checkbox
                                    toggle
                                    label={ACTIVE_LABEL()}
                                    onChange={() => this.setState({
                                        selectedGroup: {
                                            ...this.state.selectedGroup,
                                            active: !this.state.selectedGroup?.active
                                        }
                                    })}
                                    checked={this.state.selectedGroup?.active}
                                    fluid={'true'}
                                    name={'active'}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Checkbox
                                    toggle
                                    label={this.state.permanentGroup === true ? newGroupMessages().PERMANENT_GROUP : newGroupMessages().TEMPORARY_GROUP}
                                    onChange={() => this.setState({
                                        permanentGroup: !this.state.permanentGroup
                                    })}
                                    checked={this.state.permanentGroup}
                                    fluid='true'
                                />
                            </Form.Field>
                            {!this.state.permanentGroup &&
                                <Form.Field>
                                    <label>{newGroupMessages().Not_ACTIVE_AFTER}</label>
                                    <DatePicker
                                        id='notActiveAfter'
                                        onKeyDown={e => e.preventDefault()}
                                        selected={this.state.notActiveAfter}
                                        onChange={this.handleChangeDate}
                                        selectsStart
                                        minDate={new Date()}
                                        startDate={this.state.notActiveAfter}
                                        dateFormat={YEARLY_DATE_FORMAT}
                                        locale={localStorage.getItem('lang') === 'null' ? 'en' : localStorage.getItem('lang')}
                                    />
                                </Form.Field>
                                }
                        </Form>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button.Group>

                            <Button icon labelPosition='right' color='green'
                                    onClick={this.onSubmit}
                                    disabled={this.validationCheck()}>
                                <Icon
                                    name={USER_PLUS_ICON}/> {SAVE_BUTTON()}</Button>
                        </Button.Group>
                    </Modal.Actions>
                </Modal>
                <GroupUsersReservationModal
                    groupUsersReservationModalVisibility = {this.state.groupUsersReservationModalVisibility}
                    onClose={()=>this.setState({groupUsersReservationModalVisibility:false})}
                    selectedGroup={this.props.selectedGroup || {...defaultValues}}
                />
            </div>
        );
    }
}

export default withTranslation()(NewGroupModal);
