import React, {Component} from 'react';
import {Flip, toast} from "react-toastify";
import {Button, Confirm, Divider, Form, Header, Icon, Input, Modal, Segment} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import {validHostname, validIpAddress, validPort} from "../../../utils/Constants";
import {showError} from "../../../utils/ToastHelpers";
import {
    getEmailConfigList,
    updateEmailConfig,
    createEmailConfig,
    deleteEmailConfig,
    createTestEmailConfig
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { encryptData } from '../../../utils/PublicKeyHelper';
import ChangePasswordInput from '../../../components/ChangePasswordInput';
import { ACTIONS_HEADER, ACTIVE_LABEL, CANCEL_BUTTON, CONFIRM_BUTTON, emailConfigMessages, FAILED_ERROR_MESSAGE, HOST_HEADER, MAIL_SERVERS_HEADER, PASSWORD_HEADER, PORT_HEADER, SAVE_BUTTON, SUCCESS_MESSAGE, TEST_BUTTON, USERNAME_HEADER, USE_SSL_LABEL } from '../../../utils/UIMessages';
import { BUG_ICON, CHECKMARK_ICON, CHECK_ICON, EDIT_ICON, EYE_ICON, EYE_SLASH_ICON, PLUS_ICON, USER_ICON, X_ICON } from '../../../utils/UiIcons';

class EmailConfig extends Component {

    state = {
        emailServers: [],
        serverFields: {},
        modalVisibility: false,
        showPassword: false,
        confirmVisibility: false,
        testModalVisibility: false,
        message: "",
        userMail: "",
        testLoading: false,
        loadingEmailServers: false
    }

    //New React Table V8 Columns
    columns = () => [
        {
            header: () => <div>{HOST_HEADER()}</div>,
            accessorKey: 'host',
            id: 'host',
            cell: info => (<span className='number' style={{ fontWeight: 'bold' }}>{info.getValue()}</span>)
        },
        {
            header: <div>{PORT_HEADER()}</div>,
            accessorKey: 'port',
            id: 'port',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'username',
            id: 'username',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{this.props.t('From')}</div>,
            accessorKey: 'fromAddr',
            id: 'fromAddr',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{ACTIVE_LABEL()}</div>,
            accessorKey: 'active',
            id: 'active',
            cell: info => (<span>
                {info.getValue() ? <Icon name={CHECKMARK_ICON} color={'green'} />
                    : <Icon name={X_ICON} color={'red'} />}</span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            enableSorting: false,
            cell: props => (<span className='number'>
                <Button size='tiny' icon onClick={() => {
                    this.setState({ modalVisibility: true, serverFields: props.row.original })
                }}><Icon name={EDIT_ICON} /></Button>
                <Button size='tiny' icon color={'red'} onClick={() => {
                    this.setState({ confirmVisibility: true, serverFields: props.row.original })
                }}><Icon name={X_ICON} /></Button>
            </span>)
        }
    ]

    getEmailConfigList = () => {
        this.setState({ loadingEmailServers: true });
        //axios.get("/api/email/config")
        getEmailConfigList().then(res => {
            this.setState({emailServers: res.data})
        }).catch(err => {
            showError(emailConfigMessages().NOT_FETCH_MAIL_SERVER_LIST + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingEmailServers: false }));
    }

    componentDidMount() {
        this.getEmailConfigList()
    }

    onHandleChange = (e, {name, value, checked}) => {
        this.setState(prevState => (
            {
                serverFields: {
                    ...prevState.serverFields,
                    [name]: value
                }
            }))
        if (checked !== undefined) {
            this.setState(prevState => (
                {
                    serverFields: {
                        ...prevState.serverFields,
                        [name]: checked
                    }
                }))
        }
    }

    onTogglePassword = () =>
        this.setState(
            prevState => ({
                showPassword: !prevState.showPassword
            }),
        );

    handleModalOpen = () => {
        this.setState({modalVisibility: true})
    }

    handleModalClose = () => {
        this.setState({modalVisibility: false, serverFields: {}})
    }

    onHandleChangeTestModal = (e, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    checkForCheckboxes = async () => {
        const data = {
            ...this.state.serverFields,
            username: await encryptData(this.state.serverFields?.username),
            password: await encryptData(this.state.serverFields?.password)
        }
        if (!data.active) {
            data.active = false
        }
        if (!data.sslEnable) {
            data.sslEnable = false
        }
        if (!data.starttlsEnabled) {
            data.starttlsEnabled = false
        }
        return data
    }

    onSubmit = async () => {
        const data = await this.checkForCheckboxes()
        if (this.state.serverFields.id) {
            //axios.put(`/api/email/config?emailId=${this.state.serverFields.id}`, data)
            updateEmailConfig(this.state.serverFields.id,data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip
                })
                this.handleModalClose();
                this.getEmailConfigList()
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
            })
        } else {
            //axios.post(`/api/email/config`, data)
            createEmailConfig(data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip
                })
                this.handleModalClose();
                this.getEmailConfigList()
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
            })
        }
    }

    onSubmitTest = async () => {
        this.setState({testLoading: true})
        let data = await this.checkForCheckboxes();
        data = Object.assign(data,
            {
                "userMail": this.state.userMail
            }
        )
        //axios.post("/api/email/test", data)
        createTestEmailConfig(data).then(() => {
            this.setState({testLoading: false})
            toast.success(SUCCESS_MESSAGE())
        }).catch(err => {
            this.setState({testLoading: false})
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    onDelete = () => {
        //axios.delete(`/api/email/config?emailId=${this.state.serverFields.id}`)
        deleteEmailConfig(this.state.serverFields.id).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                delay: 1000,
                transition: Flip
            })
            this.setState({confirmVisibility: false, serverFields: {}})
            this.getEmailConfigList()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    validationCheck = () => {
        return this.state.serverFields.host === undefined || this.state.serverFields.host === "" ||
            this.state.serverFields.port === undefined || this.state.serverFields.port === "" ||
            this.state.serverFields.username === undefined || this.state.serverFields.username === "" ||
            this.state.serverFields.port?.match(validPort) === null
    }

    render() {
        return (
            <div>
                <div className='main-right-header'>
                    <div>
                        <h2> {MAIL_SERVERS_HEADER()}</h2>
                        <small>{emailConfigMessages().MAIL_SERVER_SUB_HEADER}</small>
                    </div>
                    <div className='main-right-buttons'>
                        <Button primary icon={PLUS_ICON} content={emailConfigMessages().ADD_NEW_SERVER_BUTTON}
                                onClick={() => this.handleModalOpen()}/>
                    </div>

                </div>
                <ReactTableV8
                    columns={this.columns()}
                    data={this.state.emailServers}
                    loadingProp={this.state.loadingEmailServers}
                />
                <Modal open={this.state.modalVisibility} onClose={this.handleModalClose} closeIcon
                       closeOnDimmerClick={true}
                       size={"small"}>
                    <Header icon={USER_ICON} content={emailConfigMessages().EMAIL_SERVER_CONFIGURATION_HEADER}/>
                    <Modal.Content>
                        <h3>{emailConfigMessages().SERVER_FIELDS_LABEL} </h3>
                        <Divider/>
                        <Form>
                            <Form.Field required>
                                <label>{HOST_HEADER()}</label>
                                <Form.Input
                                    fluid
                                    name={"host"}
                                    value={this.state.serverFields && this.state.serverFields.host}
                                    onChange={this.onHandleChange}
                                    error={(this.state.serverFields.host?.match(validHostname || validIpAddress) === null)}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{PORT_HEADER()}</label>
                                <Form.Input
                                    fluid
                                    name={"port"}
                                    value={this.state.serverFields && this.state.serverFields.port}
                                    onChange={this.onHandleChange}
                                    error={(this.state.serverFields.port?.match(validPort) === null)}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{USERNAME_HEADER()}</label>
                                <Input
                                    fluid
                                    name={"username"}
                                    value={this.state.serverFields && this.state.serverFields.username}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{this.props.t('From')}</label>
                                <Input
                                    fluid
                                    name={'fromAddr'}
                                    value={this.state.serverFields && this.state.serverFields?.fromAddr}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{PASSWORD_HEADER()}</label>
                                {!(this.state.serverFields?.id) ? <Input
                                    name={"password"}
                                    value={this.state.serverFields?.password}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    onChange={this.onHandleChange}
                                    icon={
                                        <Icon
                                            name={this.state.showPassword ? EYE_SLASH_ICON : EYE_ICON}
                                            link
                                            onClick={this.onTogglePassword}
                                        />
                                    }
                                />
                                    :
                                    <ChangePasswordInput
                                        name='password'
                                        value={this.state.serverFields?.password}
                                        onChange={this.onHandleChange}
                                        reset={() => this.setState(prevState => ({
                                            serverFields: {
                                                ...prevState.serverFields,
                                                password: null
                                            }
                                        }))}
                                    />
                                }
                            </Form.Field>
                            <Segment>
                                <Form.Field>
                                    <Form.Checkbox
                                        label={ACTIVE_LABEL()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.serverFields && this.state.serverFields.active}
                                        fluid
                                        name="active"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                        label={USE_SSL_LABEL()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.serverFields && this.state.serverFields.sslEnable}
                                        fluid
                                        name="sslEnable"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                        label={emailConfigMessages().USE_STARTTLS_LABEL}
                                        onChange={this.onHandleChange}
                                        checked={this.state.serverFields && this.state.serverFields.starttlsEnabled}
                                        fluid
                                        name="starttlsEnabled"
                                    />
                                </Form.Field>
                            </Segment>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>

                        <Button compact color={"blue"} icon={BUG_ICON} content={emailConfigMessages().TEST_MAIL_SETTINGS_HEADER}
                                onClick={() => {
                                    this.setState({testModalVisibility: true})
                                }}
                                disabled={this.validationCheck()}
                        />
                        <Button compact color="green" icon={CHECK_ICON} content={SAVE_BUTTON()}
                                onClick={this.onSubmit}
                                disabled={this.validationCheck()}/>

                    </Modal.Actions>
                </Modal>
                <Modal
                    open={this.state.testModalVisibility}
                    onClose={() => {
                        this.setState({testModalVisibility: false, message: "", userMail: ""})
                    }}
                    size='small'
                    centered
                    closeIcon
                >
                    <Modal.Header>{emailConfigMessages().TEST_MAIL_SETTINGS_HEADER}</Modal.Header>
                    <Modal.Content>
                        <p>{emailConfigMessages().TEST_MAIL_SETTINGS_MODAL_CONTENT}</p>
                        <Form>
                            <Form.Field>
                                <label>{emailConfigMessages().MAIL_LABEL}</label>
                                <Input
                                    fluid
                                    name={"userMail"}
                                    value={this.state.userMail}
                                    onChange={this.onHandleChangeTestModal}
                                />
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="green" icon={CHECK_ICON} content={TEST_BUTTON()} onClick={this.onSubmitTest}
                                loading={this.state.testLoading} disabled={this.state.testLoading}/>
                    </Modal.Actions>
                </Modal>
                <Confirm
                    content={emailConfigMessages().DELETE_CONFIM}
                    open={this.state.confirmVisibility}
                    onCancel={() => {
                        this.setState({confirmVisibility: false, serverFields: {}})
                    }}
                    onConfirm={this.onDelete}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />

            </div>
        );
    }
}

export default withTranslation()(EmailConfig);
