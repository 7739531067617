import {getPublicKey} from '../api/apiCalls';
import {Base64} from 'js-base64';
import { arrayBufferToBase64, stringToArrayBuffer } from './Methods';

const IV_LENGTH = 16;
const AAD_LENGTH = 16;

export const getKey = async () => {
    let publicKey = localStorage.getItem('publicKey');

    if (!publicKey) {
        try {
            const response = await getPublicKey();
            publicKey = response?.data?.value;
            localStorage.setItem('publicKey', publicKey);
        } catch (error) {
            //ignore
        }
    }

    return publicKey;
};

export const encryptData = async (data) => {
    if (data && window.crypto?.subtle?.generateKey) {
        const publicKey = await getPublicKey();

       if (publicKey) {
            const aesCryptoKey = await window.crypto.subtle.generateKey(
                {
                    name: 'AES-GCM',
                    length: 256
                },
                true,
                ['encrypt']
            );

            const aesKey = await window.crypto.subtle.exportKey('raw', aesCryptoKey);
            const iv = window.crypto.getRandomValues(new Uint8Array(IV_LENGTH));
            const additionalData = window.crypto.getRandomValues(new Uint8Array(AAD_LENGTH));

            const binaryDerString = window.atob(publicKey.data.value);

            const binaryDer = stringToArrayBuffer(binaryDerString);

            const pubKey = await window.crypto.subtle.importKey(
                'spki',
                binaryDer,
                {
                    name: 'RSA-OAEP',
                    hash: 'SHA-256'
                },
                true,
                ['encrypt']
            );

            const encryptedIV = await window.crypto.subtle.encrypt({
                    name: 'RSA-OAEP'
                },
                pubKey,
                iv);

            const encryptedKey = await window.crypto.subtle.encrypt({
                    name: 'RSA-OAEP'
                },
                pubKey,
                aesKey);

            const encryptedData = await window.crypto.subtle.encrypt(
                {
                    name: 'AES-GCM',
                    iv,
                    additionalData
                },
                aesCryptoKey,
                new TextEncoder().encode(data)
            );




            return Base64.encode(JSON.stringify({
                data: arrayBufferToBase64(encryptedData),
                iv: arrayBufferToBase64(encryptedIV),
                key: arrayBufferToBase64(encryptedKey),
                aad: arrayBufferToBase64(additionalData)
            }), false);
        }
    }
    return data;
};
