import React, { useEffect, useState} from 'react';
import {Button, Dropdown, Grid, Header, Popup, Segment, Tab} from "semantic-ui-react";
import {Flip, toast} from "react-toastify";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {googlecode} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {getFrameworkId} from "../../utils/Methods";
import copy from "copy-to-clipboard";
import {showError} from "../../utils/ToastHelpers";
import {
    getAppList, getAutomationAccessKeySummary, getAutomationCapabilities, getAutomationTypes
} from '../../api/apiCalls'
import { AUTOMATION_SETTINGS_HEADER, COPIED_LABEL,COPY_CODE_TO_CLIPBOARD_MESSAGE,manageAutomationSettingsMessages,NOT_FETCH_APPS } from '../../utils/UIMessages';
import { COPY_OUTLINE_ICON } from '../../utils/UiIcons';

const ManageAutomationSettings = props => {
    const [appList, setAppList] = useState([]);
    const [appDropdown, setAppDropdown] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedApp, setSelectedApp] = useState({});
    const [loading, setLoading] = useState(true);
    const [automationFrameworks, setAutomationFrameworks] = useState([]);
    const [currentAutomationFrameworkId, setCurrentAutomationFrameworkId] = useState();
    const [maskedAutomationData, setMaskedAutomationData] = useState();
    const [currentAutomationData, setCurrentAutomationData] = useState('');
    const [automationKeyDropDown, setAutomationKeyDropDown] = useState([]);
    const [selectedAutomationKey, setSelectedAutomationKey] = useState(null);

    useEffect(() => {
        setCurrentAutomationFrameworkId(automationFrameworks[0]?.id)
    }, [automationFrameworks]);

    useEffect(() => {
        setAppDropdown(appList.map(app => {
            return {
                key: app.id,
                value: app.id,
                text: (<span>{app.appName} <span style={{color: '#a2a2a2'}}>v{app.appVersion}</span> <span
                    style={{color: '#b5b5b5'}}>Build: {app.appVersionCode}</span></span>),
                image: {avatar: true, src: `data:image/png;base64,${app.appIcon}`}
            }
        }))
    }, [appList]);

    useEffect(() => {
        if(props.deviceId && (selectedApp?.id || currentAutomationFrameworkId || selectedAutomationKey)){
            setLoading(true)
            getAutomationCapabilities(props.deviceId, selectedApp.id, currentAutomationFrameworkId, selectedAutomationKey, window.location.origin)
                .then(res => {
                    setCurrentAutomationData(res.data);
                })
                .catch(err => showError(err))
                .finally(() => setLoading(false));
        }
    }, [selectedApp, currentAutomationFrameworkId, props.deviceId, selectedAutomationKey]);

    useEffect(() => {
        if (props.deviceOs){
            getAppList({appOs: props.deviceOs}).then(response => {
                setAppList(response.data);
            }).catch((err) => {
                showError(`${NOT_FETCH_APPS()} ${err.response?.data?.message ?? err.toString()}`)
            })
        }
    }, [props.deviceOs])

    useEffect(() => {
        setLoading(true);
        getAutomationTypes().then(res => {
            setAutomationFrameworks(res.data)
        }).catch(() => showError(manageAutomationSettingsMessages().AUTOMATION_TYPES_EXCEPTION));
    }, []);

    useEffect(() => {
        setLoading(true);
        getAutomationAccessKeySummary().then(res => {
            setAutomationKeyDropDown(res.data.map(automationKey => {
                return {
                    key: automationKey.id,
                    value: automationKey.id,
                    text: automationKey.keyName
                }
            }))
        }).catch(() => showError(manageAutomationSettingsMessages().AUTOMATION_KEYS_EXCEPTION));
    }, []);

    useEffect(() => {
        setMaskedAutomationData(currentAutomationData.replace(/(.*):([a-z0-9]{8})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{12})(.*)/g, "$1:$2-****-****-****-************$7"))
    }, [currentAutomationData]);

    const copyButtonStyle = {
        position: "absolute",
        right: 16
    }

    const getPanes = () => {
        return Object.values(automationFrameworks).map(framework => {
            return {
                menuItem: framework.name,
                render: () => <Segment className='device-manage-automation-code-segment' loading={loading}>
                    {copyButton()}
                    <div>
                        <SyntaxHighlighter language={framework.lang} style={googlecode}>{maskedAutomationData}</SyntaxHighlighter>
                    </div>
                </Segment>
            }
        })
    }

    const copyButton = () => {
        return <Popup content={COPY_CODE_TO_CLIPBOARD_MESSAGE()} position='top right'
                      trigger={<Button basic compact onClick={handleCodeCopy} icon={COPY_OUTLINE_ICON}
                                       style={copyButtonStyle}/>}/>
    }

    const handleCodeCopy = () => {
        copy(currentAutomationData);
        toast.info(COPIED_LABEL(), {
            autoClose: 1200,
            transition: Flip
        })
    };

    const onChangeAppDropdown = (e, {value}) => {
        const appId = value
        const selectedApp = appList.find(app => app.id === appId)
        setSelectedApp(selectedApp);
    }

    const onChangeAutomationKeyDropDown = (e, {value}) => {
        setSelectedAutomationKey(value);
    }

    const handleTabChange = (e, {activeIndex}) => {
        setActiveIndex(activeIndex);
        setCurrentAutomationFrameworkId(getFrameworkId(automationFrameworks, e.target.innerHTML));
    }

    return (
        <Grid columns={1}>
            <Grid.Column width={16}>
                <Grid.Row className='device-manage-automation-row-padding-top'>
                    <Grid centered>
                        <Grid.Row centered columns={2}
                                  className='device-manage-automation-row-padding-top'  // cok bitisik oluyor
                        >
                            <Grid.Column width={4}>
                                <Header as='h4' dividing style={{marginTop:10, marginLeft:2}}>
                                    {AUTOMATION_SETTINGS_HEADER()}
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Dropdown selection fluid options={appDropdown} className='device-manage-automation-select-app-dropdown'
                                          onChange={onChangeAppDropdown}
                                          placeholder={manageAutomationSettingsMessages().DROPDOWN_PLACEHOLDER}/>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Dropdown selection fluid options={automationKeyDropDown} className='device-manage-automation-select-key-dropdown'
                                          onChange={onChangeAutomationKeyDropDown}
                                          placeholder={manageAutomationSettingsMessages().KEY_DROPDOWN_PLACEHOLDER}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Row>
                <Grid.Row className='device-manage-automation-row-padding-top'>
                    <Tab menu={{secondary: true, fluid: true, vertical: true}} panes={getPanes()}
                         style={{fontFamily: "monospace"}}
                         activeIndex={activeIndex}
                         onTabChange={handleTabChange}
                    />
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
}

export default ManageAutomationSettings;