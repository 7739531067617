/*
    VisiumFarm Custom Generic Backend Pagination

                            User Documentation / User Manual

This component has been written manually (custom) and is used for BACKEND pagiantion operations.
It can be used whenever and wherever it is needed. It takes 5 props which are ALL ARE COMPULSORY.
1. activePageProp : (number) Represents the page index on UI where you are looking.
2. pageIndexProp : (number) Represents the pageIndex parameter coming from backend server.
3. totalPagesProp : (number) Represents the totalPages number parameter coming from backend server.
4. onPageChangeProp : (function) Works as a function and determine what to do when page number is changed via buttons.
                        It re-loads your data from your server through sending proper pagination parameters.
5. classNameProp : (String) It is for Specific CSS Customization. You must send className of your pagination's styling.
                    Unless you send any parameter here, pagination will be placed according to the
                    DOM order of html file. E.g: classNameProp='library-pagination'
*/

import React from 'react';
import {
    Icon,
    Pagination
} from 'semantic-ui-react';
import { ANGLE_DOUBLE_LEFT_ICON, ANGLE_DOUBLE_RIGHT_ICON, ANGLE_LEFT_ICON, ANGLE_RIGHT_ICON, ELLIPSIS_HORIZONTAL_ICON } from '../utils/UiIcons';


const FarmGenericPagination = props => {
    return (
        <div className={props.classNameProp}>
            <Pagination
                defaultActivePage={1}
                activePage={props.activePageProp}
                ellipsisItem={{ content: <Icon name={ELLIPSIS_HORIZONTAL_ICON} />, icon: true }}
                firstItem={{
                    content: <Icon disabled={props.pageIndexProp + 1 === 1}
                        name={ANGLE_DOUBLE_LEFT_ICON} className='generic-farm-pagination-buttons' />, icon: true
                }}
                prevItem={{
                    content: <Icon disabled={props.pageIndexProp + 1 === 1}
                        name={ANGLE_LEFT_ICON} className='generic-farm-pagination-buttons' />, icon: true
                }}
                lastItem={{
                    content: <Icon disabled={props.pageIndexProp + 1 === props.totalPagesProp}
                        name={ANGLE_DOUBLE_RIGHT_ICON} className='generic-farm-pagination-buttons' />, icon: true
                }}
                nextItem={{
                    content: <Icon disabled={props.pageIndexProp + 1 === props.totalPagesProp}
                        name={ANGLE_RIGHT_ICON} className='generic-farm-pagination-buttons' />, icon: true
                }}
                totalPages={props.totalPagesProp}
                onPageChange={(e, { activePage }) => {
                    props.onPageChangeProp(activePage - 1);
                }}
            />
        </div>)
}

export default FarmGenericPagination;
