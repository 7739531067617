import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Dropdown, Form, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { createWebhook, updateWebhook, checkWebhook } from '../../../api/apiCalls';
import { validateWebhookInput } from '../../../utils/Methods';
import { showError, showSuccess } from '../../../utils/ToastHelpers';
import { WEBHOOK_AUTH_USERNAME_PASSWORD, WEBHOOK_AUTH_TOKEN, WEBHOOK_AUTH_NONE } from '../../../utils/Constants';
import { CONNECTION_SUCCESSFULL_MESSAGE, NAME_HEADER, SUCCESS_MESSAGE, webhookMessages,URL_LABEL, USERNAME_HEADER, PASSWORD_HEADER, ACTIVE_LABEL, PASSIVE_LABEL, SAVE_BUTTON } from '../../../utils/UIMessages';
import { CHECK_ICON, EYE_ICON, EYE_SLASH_ICON } from '../../../utils/UiIcons';

const WebhookModal = ({ addModalVisibility, handleModalClose, authMethodDropdown, eventTypeDropdown = [], webhook = {} }) => {
    const { t } = useTranslation();
    const [selectedWebhook, setSelectedWebhook] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showTokenValue, setShowTokenValue] = useState(false);

    const onHandleChange = (e, { value, name }) => setSelectedWebhook(prevState => (
        { ...prevState, [name]: value }
    ));
    const onHandleChangeCheckbox = (e, { name, checked }) => setSelectedWebhook(prevState => (
        { ...prevState, [name]: checked }
    ))
    const onTogglePassword = () => {
        setShowPassword(prevState => !prevState);
    }
    const onToggleTokenValue = () => {
        setShowTokenValue(prevState => !prevState);
    }
    const onSuccess = () => {
        showSuccess(SUCCESS_MESSAGE())
        handleModalClose()
        setSelectedWebhook(null)
    }
    const onSubmit = () => {
        const data = selectedWebhook
        if (selectedWebhook?.id) {
            updateWebhook(selectedWebhook?.id, data).then(() => {
                onSuccess()
            }).catch(err => {
                showError(`${t(err?.response?.data?.message)}`)
            })
        } else {
            createWebhook(data).then(() => {
                onSuccess()
            }).catch(err => {
                showError(`${t(err?.response?.data?.message)}`)
            })
        }
    }
    const onCheckWebhookConnection = () => {
        const data = selectedWebhook
        checkWebhook(data).then(() => {
            showSuccess(CONNECTION_SUCCESSFULL_MESSAGE())
        }).catch(err => {
            showError(t(`${err?.response?.data?.message}`))
        })
    }
    useEffect(() => {
        setSelectedWebhook(webhook);
    }, [webhook])
    const isNotValid = useMemo(() => validateWebhookInput(selectedWebhook), [selectedWebhook])

    return (
        <div>
            <Modal open={addModalVisibility} onClose={handleModalClose} closeIcon
                closeOnDimmerClick={true}
                size={'small'}>
                <Modal.Content>
                    <Header as='h3'>
                        {webhookMessages().WEBHOOK_FIELDS_MODAL_HEADER}
                    </Header>
                    <Divider />
                    <Form>
                        <Form.Field required>
                            <label>{NAME_HEADER()}</label>
                            <Input
                                fluid
                                type='text'
                                name='name'
                                value={selectedWebhook?.name}
                                onChange={onHandleChange}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>{webhookMessages().EVENT_TYPE_HEADER}</label>
                            <Dropdown
                                selection multiple options={eventTypeDropdown} clearable
                                name='eventType'
                                onChange={onHandleChange} value={selectedWebhook?.eventType} />
                        </Form.Field>
                        <Form.Field required>
                            <label>{URL_LABEL()}</label>
                            <Input
                                fluid
                                type='text'
                                name='url'
                                value={selectedWebhook?.url}
                                onChange={onHandleChange}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>{webhookMessages().AUTHENTICATION_METHOD_LABEL}</label>
                            <Dropdown style={{ wordBreak: 'break-all' }} fluid search selection clearable
                                placeholder={webhookMessages().AUTHENTICATION_METHOD_LABEL}
                                name="authMethod"
                                options={authMethodDropdown}
                                value={selectedWebhook?.authMethod || WEBHOOK_AUTH_NONE}
                                onChange={onHandleChange} />
                        </Form.Field>
                        {selectedWebhook?.authMethod === WEBHOOK_AUTH_USERNAME_PASSWORD &&
                            <>
                                <Form.Field required>
                                    <label>{USERNAME_HEADER()}</label>
                                    <Input
                                        fluid
                                        type='text'
                                        name='username'
                                        value={selectedWebhook?.username}
                                        onChange={onHandleChange}
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label>{PASSWORD_HEADER()}</label>
                                    <Input
                                        name='password'
                                        value={selectedWebhook?.password}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={onHandleChange}
                                        icon={
                                            <Icon
                                                name={showPassword ? EYE_SLASH_ICON : EYE_ICON}
                                                link
                                                onClick={onTogglePassword}
                                            />
                                        }
                                    />
                                </Form.Field>
                            </>
                        }
                        {selectedWebhook?.authMethod === WEBHOOK_AUTH_TOKEN &&
                            <>
                                <Form.Field required>
                                    <label>{webhookMessages().TOKEN_HEADER_LABEL}</label>
                                    <Input
                                        fluid
                                        type='text'
                                        name='tokenHeader'
                                        value={selectedWebhook?.tokenHeader}
                                        onChange={onHandleChange}
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label>{webhookMessages().TOKEN_VALUE_LABEL}</label>
                                    <Input
                                        fluid
                                        type={showTokenValue ? 'text' : 'password'}
                                        name='tokenValue'
                                        value={selectedWebhook?.tokenValue}
                                        onChange={onHandleChange}
                                        icon={
                                            <Icon
                                                name={showTokenValue ? EYE_SLASH_ICON : EYE_ICON}
                                                link
                                                onClick={onToggleTokenValue}
                                            />
                                        }
                                    />
                                </Form.Field>
                            </>
                        }
                        <Form.Field>
                            <Form.Checkbox
                                toggle
                                label={selectedWebhook?.active === true ? ACTIVE_LABEL() : PASSIVE_LABEL()}
                                onChange={onHandleChangeCheckbox}
                                checked={selectedWebhook?.active}
                                name='active'
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button compact icon='sync' color='yellow'
                        content={webhookMessages().WEBHOOK_CONNECTION_TEST_BUTTON}
                        disabled={isNotValid}
                        onClick={onCheckWebhookConnection} />
                    <Button compact color='green' icon={CHECK_ICON} content={SAVE_BUTTON()}
                        onClick={onSubmit} disabled={isNotValid} />
                </Modal.Actions>
            </Modal>
        </div >
    );
}
export default WebhookModal;



