import React, {Component} from 'react';
import Tree from 'rc-tree/es';
import { showError } from '../utils/ToastHelpers';
import { appSourceTreeMessages } from '../utils/UIMessages';


class AppSourceTree extends Component {

    state = {
        onScreenSelectionMode: false,
        selectedElementPath: '',
        selectedElementsChildPath: '',
        treeData: []
    };

    componentDidUpdate(prevProps){
        if(prevProps.selectedElementPath !== this.props.selectedElementPath){
            this.setState({onScreenSelectionMode: true})
            this.props.handleElementSelect(this.props.selectedElementPath)
        }
    }

    // Recursives through the source and generate list for tree
    generateTreeDataList (elemObj) {
        if (!elemObj) {return null;}
        if (elemObj.children.length === 0) {return null;} // leaf

        var temp_list = [];
        elemObj.children.map((el) => {
            temp_list.push({
                ...el,
                key: '0-' + el.path.replace(/[. ,:-]+/g, "-"),
                title: this.getFormattedAppSourceElementTitle(el),
                children: this.generateTreeDataList(el),
            });
        });
        return temp_list;
    }

    getFormattedAppSourceElementTitle(element){
        let elementAttributes = element.attributes
        const title_style = {color: 'crimson '};
        const resource_id_title_style = {color: 'indigo', fontSize: '12px'}
        const resource_id_style = {color: 'gray', fontSize: '12px'}
        if(elementAttributes["resource-id"] !== undefined){
            let resource_id = `"${elementAttributes["resource-id"]}"`
        return <span>  <span style={title_style}>{element.tagName}</span>  <span style={resource_id_title_style}><i> resource-id= </i></span> <span style={resource_id_style}>{resource_id}</span> </span>
        }
        else if(elementAttributes['name'] !== undefined){
            let name = `"${elementAttributes["name"]}"`
            return <span>  <span style={title_style}>{element.tagName}</span>  <span style={resource_id_title_style}><i> Name= </i></span> <span style={resource_id_style}>{name}</span> </span>
        }
        else if(elementAttributes['id'] !== undefined){
            let id = `"${elementAttributes['id']}"`
            return <span>  <span style={title_style}>{element.tagName}</span>  <span style={resource_id_title_style}><i> id= </i></span> <span style={resource_id_style}>{id}</span> </span>
        }
        else if(elementAttributes['elementId'] !== undefined){
            let elementId = `"${elementAttributes['elementId']}"`
            return <span>  <span style={title_style}>{element.tagName}</span>  <span style={resource_id_title_style}><i> elementId= </i></span> <span style={resource_id_style}>{elementId}</span> </span>
        }
        else{
            return  <span style={title_style}>{element.tagName}</span>
        }
    }

    getTreeData(){
        try {
            let treeData =  this.generateTreeDataList(this.props.sourceJSON);
            return treeData
        }
        catch(err){
            showError(`${appSourceTreeMessages().CONVERTING_XML_TO_JSON_FAILED_MESSAGE} ${err}`)
            return [];
        }
    }

    onSelect = (selectedKeys, info) => {
        this.props.onSelect(info.node.props.eventKey)
        this.setState({onScreenSelectionMode: false, selectedElementPath: info.node.props.eventKey}, ()=>{
            if(info.node.props.expanded){
                this.setState({selectedElementsChildPath: this.state.selectedElementPath})
            }
            else{
                this.setState({selectedElementsChildPath: this.state.selectedElementPath + "-0"})
            }
        })
    };

    getExpandedPaths(path){
        // Expand all of this element's ancestors so that it's visible in the source tree
        let expandedPaths = []
        let pathArr = path.split('-').slice(0, path.length - 1);
        while (pathArr.length > 1) {
            pathArr.splice(pathArr.length - 1);
            let expandedPath = pathArr.join('-');
            if (expandedPaths.indexOf(expandedPath) < 0) {
                expandedPaths.push(expandedPath);
            }
        }
        return expandedPaths
    }

    setExpandedKeys=()=>{
        if(this.state.onScreenSelectionMode){
            return this.getExpandedPaths(this.props.selectedElementPath)
        }
        else{
            return this.getExpandedPaths(this.state.selectedElementsChildPath)
        }
    }

    setSelectedKeys=()=>{
        if(this.state.onScreenSelectionMode){
            return [this.props.selectedElementPath]
        }
        else{
            return [this.state.selectedElementPath]
        }
    }

    render(){
        return(
            <Tree 
            treeData={this.getTreeData()}
            showIcon={false}
            onSelect={this.onSelect}
            expandedKeys={this.setExpandedKeys()}
            selectedKeys={this.setSelectedKeys()}
            defaultExpandParent={true}
            />
        )
    }
}

export default AppSourceTree;