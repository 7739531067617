import React, { Component } from 'react';
import { Button, Confirm, Dropdown, Icon, List, Popup } from "semantic-ui-react";
import { withTranslation } from 'react-i18next'
import { toast } from "react-toastify";
import AddNewUser from "./AddNewUser";
import AddLdapUsers from "./AddLdapUsers";
import PasswordRules from "./PasswordRules";
import BlockedUsers from './BlockedUsers';
import moment from "moment";
import { showError } from "../../../utils/ToastHelpers";
import {
    getGroupList,
    getAllRoles,
    getUserList,
    updateCredential,
    createUser,
    deleteLdapUser,
    deleteUser,
    checkUser,
    deleteTwoFactorSecretKey,
    downloadUserReport
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { encryptData } from '../../../utils/PublicKeyHelper';
import { getPrivileges, dataRefresher } from '../../../utils/Methods';
import { PrivilegeConstants } from '../../../utils/PrivilegeConstants';
import { ACTIONS_HEADER, ACTIVE_LABEL, CANCEL_BUTTON, CONFIRM_BUTTON, DELETE_BUTTON, DOWNLOAD_TABLE_AS_CSV, EDIT_BUTTON, FAILED_ERROR_MESSAGE, FULLNAME_HEADER, manageGroupUsersMessages, manageUsersMessages, NOT_DOWNLOAD_REPORTS, NOT_FETCH_ROLES_ERROR_MESSAGE, NOT_FETCH_USERS, NO_LABEL, N_A_LABEL, SUCCESS_MESSAGE, UNKNOWN_ERROR, USERNAME_HEADER, USER_GROUPS_HEADER, USER_MAIL_HEADER, USER_ROLES_LABEL, YES_LABEL } from '../../../utils/UIMessages';
import { ADD_USER_ICON, CHECKMARK_ICON, DOWNLOAD_ICON, EDIT_ICON, ELLIPSIS_HORIZONTAL_ICON, KEY_ICON, LOCK_ICON, USER_TIMES_ICON, X_ICON } from '../../../utils/UiIcons';
class ManageUsers extends Component {

    //React Table V8 Columns
    columnsRender = () => [
        {
            header: () => <div>{FULLNAME_HEADER()}</div>,
            accessorKey: 'fullName',
            id: 'fullName',
            cell: info => (<span>{info.getValue() === null ? <>{N_A_LABEL()}</> : <>{info.getValue()}</>}</span>)
        },
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'userName',
            id: 'userName',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{USER_MAIL_HEADER()}</div>,
            accessorKey: 'userMail',
            id: 'userMail',
            cell: info => (<span className='number'>{info.getValue() === null ? <>{N_A_LABEL()}</> : <>{info.getValue()}</>}</span>)
        },
        {
            header: () => <div>{USER_ROLES_LABEL()}</div>,
            accessorFn: d => d.roles.map(({ name }) => name),
            id: 'roles',
            filterFn: (row, columnId, value) => {
                if (row.getValue(columnId).some(r => r === value)) {
                    return true;
                }
                return false;
            },
            filterType: 'dropdown',
            cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue().map((role, i) => {
                return (<List.Item key={i} as='li' value='*'>
                    {role}
                </List.Item>)
            })}</List>)
        },
        {
            header: () => <div>{manageUsersMessages().LDAP_USER_HEADER}</div>,
            accessorFn: d => d.ldapuser ? YES_LABEL() : NO_LABEL(),
            id: 'ldapuser',
            filterType: 'dropdown',
            cell: info => (<span> {info.getValue() === YES_LABEL() ? <>
                <Icon name={CHECKMARK_ICON} color={'green'} /></> : <>
                <Icon name={X_ICON} color={'red'} />
            </>} </span>)
        },
        {
            header: () => <div>{ACTIVE_LABEL()}</div>,
            accessorFn: d => d.userEnabled ? YES_LABEL() : NO_LABEL(),
            id: 'userEnabled',
            filterType: 'dropdown',
            cell: info => (<span>{info.getValue() === YES_LABEL() ? <>
                <Icon name={CHECKMARK_ICON} color={'green'} /></> : <>
                <Icon name={X_ICON} color={'red'} />
            </>} </span>)
        },
        {
            header: () => <div>{manageUsersMessages().TWO_FACTOR_AUTH_HEADER}</div>,
            accessorFn: d => d.using2FA ? YES_LABEL() : NO_LABEL(),
            id: 'using2FA',
            filterType: 'dropdown',
            cell: info => (<span> {info.getValue() === YES_LABEL() ?
                <Popup hoverable position='top center' disabled={info.row.original.userName === 'admin'}
                    trigger={<Icon name={CHECKMARK_ICON} link disabled={info.row.original.userName === 'admin'}
                        onClick={() => {
                            this.setState({
                                twoFactorSecretKeyConfirmVisibility: true,
                                userFields: { 'id': info.row.original.id }
                            })
                        }}
                        color={'green'} />}>
                    <Icon name={X_ICON}
                        color={'red'} /> {manageUsersMessages().RESET_TWO_FACTOR_AUTH_SECRET_KEY} </Popup>
                : <> <Icon name={X_ICON} color={'red'} />
                </>} </span>)
        },
        {
            header: () => <div>{USER_GROUPS_HEADER()}</div>,
            accessorFn: d => d.groups.map(({ name }) => name),
            id: 'groups',
            filterType: 'dropdown',
            filterFn: (row, columnId, value) => {
                if (row.getValue(columnId).some(r => r === value)) {
                    return true;
                }
                return false;
            },
            enableSorting: false,
            cell: info => (<List as='ol' verticalAlign='middle'>
                {info.row.original.groups.length === 0 ? <>{manageUsersMessages().NO_GROUP_LABEL}</> : info.row.original.groups.map((group, i) => {
                    return (<List.Item key={i} as='li' value='*'>
                        {group.name}
                    </List.Item>)
                })}</List>)
        },
        {
            header: () => <div>{manageUsersMessages().USER_ACTIVITIES_HEADER}</div>,
            accessorKey: 'lastLogon',
            id: 'lastLogon',
            enableColumnFilter: false,
            enableSorting: false,
            cell: info => (<><p style={{ color: 'gray', fontSize: '12' }}>
                {manageUsersMessages().LAST_LOGON_LABEL} : {info.getValue() === null ? <>{manageUsersMessages().NEVER_LOGGED_IN_LABEL}</>
                    : <>{moment(info.getValue()).format('DD/MM/YYYY HH:mm')}</>}<br />
                <span style={{
                    color: 'gray',
                    fontSize: 12
                }}>{manageUsersMessages().DATE_OF_REGISTRATION_LABEL} : {info.row.original.dateOfRegistration === null ? <>{N_A_LABEL()}</>
                    : <>{moment(info.row.original.dateOfRegistration).format('DD/MM/YYYY HH:mm')}</>}</span>
            </p></>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            id: 'actions',
            enableColumnFilter: false,
            enableSorting: false,
            cell: info => (<span>
                <Dropdown
                    icon={ELLIPSIS_HORIZONTAL_ICON}
                    button
                    className='icon'
                >
                    <Dropdown.Menu style={{ zIndex: 90 }}>
                        <Dropdown.Item
                            onClick={() => {
                                this.handleModalOpen(info.row.original)
                            }}
                        >
                            <Icon name={EDIT_ICON} />{EDIT_BUTTON()}
                        </Dropdown.Item>

                        <Dropdown.Item
                            disabled={info.row.original.userName === 'admin'}
                            onClick={() => {
                                this.setState({
                                    confirmVisibility: true,
                                    userFields: { 'id': info.row.original.id, ldapuser: info.row.original.ldapuser }
                                })
                            }}
                        >
                            <Icon name={USER_TIMES_ICON} color='red' />{DELETE_BUTTON()}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </span>)
        }
    ];

    state = {
        pageSize: 10,
        page: 0,
        users: [],
        modalVisibility: false,
        userFields: {},
        showPassword: false,
        requestType: "",
        allRoles: [],
        allGroups: [],
        groupDropdown: [],
        roleDropdown: [],
        selectedRoles: [],
        selectedGroups: [],
        isLdap: false,
        ldapModalVisibility: false,
        blockedUsersModalVisibility: false,
        passModalVisibility: false,
        twoFactorSecretKeyConfirmVisibility: false,
        loadingUsers: false,
        language: localStorage.getItem('lang') || 'en',
        privileges: [],
        pendingRequestUserForm : false
    };


    handleModalOpen = (userDetails) => {
        if (userDetails) {
            let selectedRoles = userDetails.roles.map(role => role.roleId)
            let selectedGroups = userDetails.groups.map(group => group.id);
            this.setState({ userFields: userDetails, requestType: "put", selectedRoles, selectedGroups }, () => {
                this.setState({ modalVisibility: true })
            })
        } else {
            this.setState({ userFields: { "enableMail": false }, requestType: "post" }, () => {
                this.setState({ modalVisibility: true })
            })
        }
    };

    handleModalClose = () => this.setState({
        modalVisibility: false,
        userFields: {},
        showPassword: false,
        requestType: "",
        isLdap: false,
        selectedRoles: [],
        selectedGroups: [],
        ldapModalVisibility: false
    }, () => {
        toast.dismiss()
    });

    onTogglePassword = () =>
        this.setState(
            prevState => ({
                showPassword: !prevState.showPassword
            }),
        );

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
                :
                true
        );
    };

    getAllUsersRolesGroups = () => {
        this.setState({ loadingUsers: true });
        //axios.get("/api/user/users")
        getUserList().then(res1 => {
            this.setState({ users: res1.data })
        }).catch(err => {
            showError(NOT_FETCH_USERS() + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingUsers: false }));
        //axios.get("/api/manage/roles")
        getAllRoles().then(res2 => {
            this.setState({ allRoles: res2.data })
            let roleDropdown = res2.data.map(role => {
                return {
                    key: role.roleId,
                    value: role.roleId,
                    text: role.name,
                }
            })
            this.setState({ roleDropdown })
        }).catch(err => {
            showError(NOT_FETCH_ROLES_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
        //axios.get("/api/groups")
        getGroupList().then(res3 => {
            let groupDropdown = res3.data.map(group => {
                return {
                    key: group.id,
                    value: group.id,
                    text: group.name,
                }
            });
            this.setState({ allGroups: res3.data, groupDropdown });
        }).catch(err => {
            showError(manageUsersMessages().NOT_FETCH_USER_GROUPS_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString())
        });
    };

    onHandleChange = (e, { value, name, checked }) => {
        this.setState(prevState => (
            {
                userFields: {
                    ...prevState.userFields,
                    [name]: value
                }
            }))
        if (checked !== undefined) {
            this.setState(prevState => (
                {
                    userFields: {
                        ...prevState.userFields,
                        [name]: checked
                    }
                }))
        }
    }
    onHandleChangeRoles = (e, { value }) => {
        this.setState({ selectedRoles: value })
    }

    onHandleChangeGroups = (e, { value }) => {
        this.setState({ selectedGroups: value })
    }

    editDataForRequest = async () => {
        let {userFields, selectedRoles, selectedGroups} = this.state

        const data = {
            ...userFields,
            enableMail: this.state.userFields.enableMail ? this.state.userFields.enableMail : false,
            roles: selectedRoles,
            groups: selectedGroups,
            userEnabled: this.state.userFields.userEnabled ?? true,
            password: await encryptData(userFields?.password),
            userName: await encryptData(userFields?.userName)
        }

        if (this.state.isLdap && this.state.requestType === "post") {
            data.ldapuser = true;
        }
        return data;
    }

    onDelete = () => {
        if (this.state.userFields.ldapuser) {
            //axios.delete("api/user/remove/ldap-user?id=" + this.state.userFields.id)
            deleteLdapUser(this.state.userFields.id).then(() => {
                toast.success(manageUsersMessages().USER_DELETE_SUCCESSFULL)
                this.setState({ confirmVisibility: false, userFields: {} }, () => {
                    this.getAllUsersRolesGroups()
                })
            }).catch(err => {
                if (err?.response) {
                    showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data}`);
                } else {
                    showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data?.message ?? err.toString()}`);
                }
            })
        } else {
            //axios.delete("api/user/delete-user?id=" + this.state.userFields.id)
            deleteUser(this.state.userFields.id).then(() => {
                toast.success(manageUsersMessages().USER_DELETE_SUCCESSFULL)
                this.setState({ confirmVisibility: false, userFields: {} }, () => {
                    this.getAllUsersRolesGroups()
                })
            }).catch(err => {
                if (err?.response) {
                    showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data}`);
                } else {
                    showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data?.message ?? err.toString()}`);
                }
            })
        }
    }

    sendUserData = data => {
        createUser(data).then(() => {
            toast.success(manageUsersMessages().USER_CREATED_SUCCESSFULL)
            this.setState({modalVisibility: false,selectedGroups: [],selectedRoles: []}, () => {
                this.getAllUsersRolesGroups()
            })
        }).catch(err => {
            if (err?.response?.data?.errors) {
                err.response.data.errors.map(errObject => showError(`${errObject.defaultMessage}`))
            } else {
                showError(`${UNKNOWN_ERROR()}: ${err?.response?.data?.message ?? err.toString()}`)
            }
        })
        .finally(()=>this.setState({pendingRequestUserForm:false}));
    }


    onSubmit = async () => {
        if (this.state.requestType === 'post') {
            this.setState({pendingRequestUserForm:true});
            let checkUsername = false
            let checkUserMail = false
            checkUser('userName',this.state.userFields.userName).then(async res1 => {
                res1.data ? toast.info(manageUsersMessages().USERNAME_ALREADY_TAKEN_INFO_MESSAGE) : checkUsername = true
                if (this.state.userFields.userMail) {
                    checkUser('userMail',this.state.userFields.userMail).then(async res2 => {
                        res2.data ? toast.info(manageUsersMessages().MAIL_ALREADY_TAKEN_INFO_MESSAGE) : checkUserMail = true
                        if (checkUserMail && checkUsername) {
                            const data = await this.editDataForRequest()
                            this.sendUserData(data)
                        }else{
                           this.setState({pendingRequestUserForm:false});
                        }
                    })
                } else {
                    if (checkUsername) {
                        const data = await this.editDataForRequest()
                        this.sendUserData(data)
                    }else{
                        this.setState({pendingRequestUserForm:false});
                    }
                }
            })
        }

        if (this.state.requestType === 'put') {
            this.setState({pendingRequestUserForm:true});
            const data = await this.editDataForRequest()
            updateCredential(this.state.userFields.id, data).then(() => {
                toast.success(manageUsersMessages().USER_EDITED_SUCCESSFULL)
                this.setState({ modalVisibility: false }, () => {
                    this.getAllUsersRolesGroups()
                })
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
            })
            .finally(()=>this.setState({pendingRequestUserForm:false}));
        }

    }

    onClickCsvDownload = () => {
        //axios.get('/api/user/csv-format/users-report', {responseType: "blob"})
        downloadUserReport().then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Report.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(err => {
            showError(NOT_DOWNLOAD_REPORTS() + err?.response?.data?.message ?? err.toString())
        })
    };

    handleClosePassModel = () => {
        this.setState({ passModalVisibility: false })
    }
    handleCloseBlockedUserModal = () => {
        this.setState({blockedUsersModalVisibility: false})
    }

    resetTwoFactorSecretKey = () => {
        //axios.delete("/api/user/two-factor-secret-key/" + this.state.userFields.id)
        deleteTwoFactorSecretKey(this.state.userFields.id).then(res => {
            if (res.data) {
                toast.success(SUCCESS_MESSAGE());
                this.getAllUsersRolesGroups();
            } else {
                showError(manageUsersMessages().NOT_DELETE_TWO_FACTOR_AUTH_SECRET_KEY_ERROR_MESSAGE)
            }
        }).catch(err => {
            showError(manageUsersMessages().NOT_DELETE_TWO_FACTOR_AUTH_SECRET_KEY_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString())
        });
        this.setState({ twoFactorSecretKeyConfirmVisibility: false });
    }

    componentDidMount() {
        this.getAllUsersRolesGroups();
        const privileges = getPrivileges();
        this.setState({ language: localStorage.getItem('lang') || 'en', privileges });
    }

    componentDidUpdate() {
        // When language is changed, table data will be renewed.
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.getAllUsersRolesGroups())
        )
    }

    resetPasswordInput = () => {
        this.setState(prevState => ({
            userFields: {
                ...prevState.userFields,
                password: null
            }
        }))
    }


    render() {
        return (
            <div>

                <div className='main-right-header'>
                    <div>
                        <h2>{manageUsersMessages().USER_MANAGEMENT_HEADER}</h2>
                        <small>{manageUsersMessages().USER_MANAGEMENT_SUB_HEADER}</small>
                    </div>
                    <div className='main-right-buttons user-settings-button-group'>
                        {this.state.privileges.includes(PrivilegeConstants.SYSTEM_PARAMETER) &&
                            <Button primary icon onClick={() => {
                                this.setState({ passModalVisibility: true })
                            }}> <Icon name={KEY_ICON} /> {manageUsersMessages().PASSWORD_RULES_BUTTON}</Button>
                        }
                        {this.state.privileges.includes(PrivilegeConstants.MANAGE_USER) &&
                            <Button primary icon onClick={() => {
                                this.setState({ blockedUsersModalVisibility: true })
                            }}> <Icon name={LOCK_ICON} /> {manageUsersMessages().BLOCKED_USERS_BUTTON_LABEL}</Button>
                        }
                        <Button primary icon onClick={() => {
                            this.setState({ ldapModalVisibility: true })
                        }}> <Icon name={ADD_USER_ICON} /> {manageUsersMessages().ADD_LDAP_SERVER_BUTTON}</Button>
                        <Button primary icon onClick={() => {
                            this.handleModalOpen()
                        }}> <Icon name={ADD_USER_ICON} /> {manageUsersMessages().ADD_NEW_USER_BUTTON}</Button>
                    </div>
                    <Dropdown text={ACTIONS_HEADER()} direction='left' id='user-settings-actions' >
                        <Dropdown.Menu>
                            {this.state.privileges.includes(PrivilegeConstants.SYSTEM_PARAMETER) &&
                                <Dropdown.Item text={manageUsersMessages().PASSWORD_RULES_BUTTON} icon={KEY_ICON} onClick={() => this.setState({ passModalVisibility: true })} />
                            }
                            {this.state.privileges.includes(PrivilegeConstants.MANAGE_USER) &&
                                <Dropdown.Item text={manageUsersMessages().BLOCKED_USERS_BUTTON_LABEL} icon={LOCK_ICON} onClick={() => this.setState({ blockedUsersModalVisibility: true })} />
                            }
                            <Dropdown.Item text={manageUsersMessages().ADD_LDAP_SERVER_BUTTON} icon={ADD_USER_ICON} onClick={() => this.setState({ ldapModalVisibility: true })} />
                            <Dropdown.Item text={manageUsersMessages().ADD_NEW_USER_BUTTON} icon={ADD_USER_ICON} onClick={() => this.handleModalOpen()} />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div id='count-total-number-of-user'>
                    {manageGroupUsersMessages().TOTAL_NUMBER_OF_USERS_LABEL}:{' '}
                    {this.state.users?.length}
                </div>
                <ReactTableV8
                    data={this.state.users}
                    columns={this.columnsRender()}
                    columnwiseFilterableProp={true}
                    loadingProp={this.state.loadingUsers}
                />
                <Button
                    icon
                    floated="right"
                    color="green"
                    style={{ marginTop: 5, marginRight: 10 }}
                    onClick={this.onClickCsvDownload}
                > <Icon
                        name={DOWNLOAD_ICON} />{DOWNLOAD_TABLE_AS_CSV()}</Button>

                <AddNewUser
                    modalVisibility={this.state.modalVisibility}
                    handleModalClose={this.handleModalClose}
                    userFields={this.state.userFields}
                    onHandleChange={this.onHandleChange}
                    showPassword={this.state.showPassword}
                    onTogglePassword={this.onTogglePassword}
                    onHandleChangeRoles={this.onHandleChangeRoles}
                    onHandleChangeGroups={this.onHandleChangeGroups}
                    onSubmit={this.onSubmit}
                    pendingRequestUserFormProp={this.state.pendingRequestUserForm}
                    roleDropdown={this.state.roleDropdown}
                    groupDropdown={this.state.groupDropdown}
                    selectedRoles={this.state.selectedRoles}
                    selectedGroups={this.state.selectedGroups}
                    requestType={this.state.requestType}
                    isLdap={this.state.isLdap}
                    resetPasswordInput={this.resetPasswordInput}
                />
                <AddLdapUsers
                    ldapModalVisibility={this.state.ldapModalVisibility}
                    handleModalClose={this.handleModalClose}
                    getAllUsersRolesGroups={this.getAllUsersRolesGroups}
                    savedUsers={this.state.users}
                    allRoles={this.state.allRoles}
                    allGroups={this.state.allGroups}
                    roleDropdown={this.state.roleDropdown}
                    groupDropdown={this.state.groupDropdown}
                />
                <PasswordRules
                    passModalVisibility={this.state.passModalVisibility}
                    handleClosePassModel={this.handleClosePassModel}
                />

                <BlockedUsers
                blockedUsersModalVisibility={this.state.blockedUsersModalVisibility}
                handleCloseBlockedUserModal={this.handleCloseBlockedUserModal}
                />

                <Confirm
                    open={this.state.confirmVisibility}
                    content={manageUsersMessages().DELETE_USER_CONFIRM_MESSAGE}
                    onCancel={() => this.setState({ confirmVisibility: false, userFields: {} })}
                    onConfirm={() => {
                        this.onDelete()
                    }}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />
                <Confirm
                    open={this.state.twoFactorSecretKeyConfirmVisibility}
                    content={manageUsersMessages().RESET_TWO_FACTOR_CONFIRM_MESSAGE}
                    onCancel={() => this.setState({ twoFactorSecretKeyConfirmVisibility: false, userFields: {} })}
                    onConfirm={() => {
                        this.resetTwoFactorSecretKey()
                    }}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />
            </div>
        );
    }
}

export default withTranslation()(ManageUsers);
