import React, { useEffect, useState } from 'react'
import { Modal, Button, Icon, List, Confirm } from 'semantic-ui-react'
import { getAllBlockedUsers, removeBlockedUser } from '../../../api/apiCalls'
import { showError, showSuccess } from '../../../utils/ToastHelpers'
import {
    ACTIONS_HEADER, blockedUsersMessages,
    CANCEL_BUTTON,
    CONFIRM_BUTTON,
    IP_HEADER,
    SUCCESS_MESSAGE,
    USERNAME_HEADER
} from '../../../utils/UIMessages'
import { UNLOCK_ICON } from '../../../utils/UiIcons'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8'

const BlockedUsers = ({ blockedUsersModalVisibility, handleCloseBlockedUserModal }) => {
    const [blockedUserList, setBlockedUserList] = useState([]);
    const [selectedBlockedUser, setSelectedBlockedUser] = useState(null);
    const [confirmVisibility, setConfirmVisibility] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (blockedUsersModalVisibility) {
            fetchBlockedUserList();
        }
    }, [blockedUsersModalVisibility])

    const fetchBlockedUserList = async () => {
        setLoading(true);
        try {
            const response = await getAllBlockedUsers();
            setBlockedUserList(response.data);
        } catch (error) {
            showError(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }


    const removeBlockedUserFromList = async () => {
        try {
            await removeBlockedUser(selectedBlockedUser?.ipAddress);
            showSuccess(SUCCESS_MESSAGE())
        } catch (error) {
            showError(error?.response?.data?.message)
        } finally {
            setConfirmVisibility(false);
            fetchBlockedUserList();
        }
    }

    const columns = () => [
        {
            header: () => <div>{IP_HEADER()}</div>,
            accessorKey: 'ipAddress',
            id: 'ipAddress',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'username',
            id: 'username',
            cell: info => (
                <List as='ol' verticalAlign='middle'>
                    {info.getValue()?.map((username, i) => {
                        if (username != null) {
                            return (
                                <List.Item key={i} as='li' value='*'>
                                    {username}
                                </List.Item>
                            )
                        }
                    })}
                </List>
            )
        },
        {
            header: () => <div>{blockedUsersMessages().NUMBER_OF__LOGIN_ATTEMPTS_LABEL}</div>,
            accessorKey: 'attempts',
            id: 'attempts',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            enableColumnFilter: false,
            enableSorting: false,
            cell: info => {
                return <span className='number'>
                    <Button
                        size='tiny'
                        onClick={() => {
                            setSelectedBlockedUser(info.row.original)
                            setConfirmVisibility(true)
                        }}
                        icon={<Icon name={UNLOCK_ICON} />} />
                </span>
            }
        }
    ]

    return (
        <>
            <Modal
                open={blockedUsersModalVisibility}
                onClose={handleCloseBlockedUserModal}
                closeIcon
                closeOnDimmerClick={true}
                size={'small'}
            >
                <Modal.Header>{blockedUsersMessages().MODAL_HEADER}</Modal.Header>
                <ReactTableV8
                    data={blockedUserList}
                    columns={columns()}
                    loadingProp={loading}
                />
            </Modal>
            <Confirm
                content={blockedUsersMessages().REMOVE_CONFIRM}
                open={confirmVisibility}
                onCancel={() => {
                    setConfirmVisibility(false);
                    setSelectedBlockedUser(null);
                }}
                onConfirm={() => removeBlockedUserFromList()}
                confirmButton={CONFIRM_BUTTON()}
                cancelButton={CANCEL_BUTTON()}
            />
        </>
    )
}
export default BlockedUsers;