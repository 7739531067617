import React from 'react';
import axios from 'axios';
import _ from 'lodash'
import {withTranslation} from 'react-i18next';
import {Button, Checkbox, Icon, Modal, Popup} from 'semantic-ui-react';
import { cardContent, orderDevices, dataRefresher } from '../../utils/Methods';
import {showError, showInfo} from '../../utils/ToastHelpers';
import {POPUP_POSITIONS} from '../../utils/Constants';
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8';
import { connect } from 'react-redux';
import { setDeviceList } from '../../actions';
import { addDeviceMessages, ADD_BUTTON, AVAILABILITY_HEADER, BRAND_HEADER, CANCEL_BUTTON, ID_HEADER, MODEL_HEADER } from '../../utils/UIMessages';
import { PLUS_ICON } from '../../utils/UiIcons';

class AddDeviceModal extends React.Component {

  state = {
    isOpen: false,
    selectedDeviceIdList: [],
    page: 0,
    pageSize: 10,
    language: localStorage.getItem('lang') || 'en'
  };

  //New React Table V8 Columns
  columns = () => [
    {
      header: () => <div>{ID_HEADER()}</div>,
      accessorKey: 'deviceId',
      id: 'deviceId',
      cell: info => (<span className='number'>{info.getValue()}</span>)
    },
    {
      header: () => <div>{BRAND_HEADER()}</div>,
      accessorKey: 'brand',
      id: 'brand',
      cell: info => (<span className='number'>{_.capitalize(info.getValue())}</span>)
    },
    {
      header: () => <div>{MODEL_HEADER()}</div>,
      accessorKey: 'deviceModel',
      id: 'deviceModel',
      cell: info => (<span className='number'>{info.getValue()}</span>)
    },
    {
      header: () => <div>{AVAILABILITY_HEADER()}</div>,
      accessorFn: d => {
        return (d.deviceStatus === 0 ? 'Online' : 'Offline') +
          (d.status?.Manual ? 'Manual' : '') +
          (d.status?.Automation ? 'Automation' : '') +
          (d.status?.Development ? 'Development' : '') +
          (d.status?.Reserved ? 'Reserved' : '');
      },
      id: 'status',
      cell: props => (<span className='number'>
        {cardContent(props.row.original?.deviceStatus, props.row.original?.status, props.row.orginal?.offlineReservedInfo?.username)}
      </span>)
    },
    {
      header: <div></div>,
      enableColumnFilter: false,
      enableSorting: false,
      id: 'selection',
      cell: props => (<Checkbox checked={this.state.selectedDeviceIdList.includes(props.row.original.deviceId)}
        onChange={(e, { checked }) => this.toggleSelectedDevice(props.row.original.deviceId, checked)}
        disabled={props.row.original.deviceStatus === 1 || this.props.connectedDeviceIdList.includes(props.row.original.deviceId)} />)
    }
  ]

  getDeviceList = async () => {
    let promiseObject
    if (this.props.embedMode){
      const token = this.props.match.params;
      const headers = {'Embedded-Screen-Key': `${token.token}`}
      promiseObject = axios.get('/api/devices', {headers})
    } else {
      promiseObject = axios.get('/api/devices')
    }
    try {
      const res = await promiseObject;
      this.props.setDeviceList(orderDevices(res.data));
    } catch (error) {
      showError(addDeviceMessages().DEVICE_LIST_ERROR_MESSAGE + error?.response?.message);
    }
  }

  componentDidMount() {
    this.setState({ language: localStorage.getItem('lang') || 'en' });
  }

  componentDidUpdate() {
    // When the selected language is changed, table data will be renewed.
    dataRefresher(
      this.state.language,
      () => this.setState({ language: localStorage.getItem('lang') },
        () => this.getDeviceList())
    )
  }

  toggleSelectedDevice = (deviceId, checked) => {
    if (checked) {
      this.setState(prev => {
        if (prev.selectedDeviceIdList.length < this.props.availableSpots) {
          return {
            ...prev,
            selectedDeviceIdList: prev.selectedDeviceIdList.concat(deviceId)
          };
        } else {
          return prev;
        }
      });
    } else {
      this.setState(prev => ({
        ...prev,
        selectedDeviceIdList: prev.selectedDeviceIdList.filter(id => id !== deviceId)
      }));
    }
  }


  openModal = () => {
    if (this.props.availableSpots > 0) {
      this.setState({ isOpen: true });
      this.getDeviceList();
    } else {
      showInfo(addDeviceMessages().NOT_ADD_ANYMORE_DEVICE_MESSAGE);
    }
  }


  handleOnClose = () => {
    this.setState({
      isOpen: false,
      selectedDeviceIdList: [],
      page: 0
    });
  }


  addNewDevices = () => {
    this.props.addToConnectedDeviceIdList(this.state.selectedDeviceIdList);
    this.handleOnClose();
  }


  render() {
    return (
      <Modal trigger={
        <Popup
          position={POPUP_POSITIONS.TOP_RIGHT}
          content={addDeviceMessages().ADD_DEVICE_HEADER}
          trigger={
            <Button icon circular onClick={this.openModal}>
              <Icon name={PLUS_ICON} />
            </Button >
          }
        />
      }
        open={this.state.isOpen} onClose={this.handleOnClose} >
        <Modal.Header>{addDeviceMessages().ADD_DEVICE_HEADER}</Modal.Header>
        <Modal.Content>
          <ReactTableV8
            columns={this.columns()}
            data={this.props.deviceList}
            columnwiseFilterableProp={true}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleOnClose}>{CANCEL_BUTTON()}</Button>
          <Button primary onClick={this.addNewDevices} disabled={this.state.selectedDeviceIdList.length === 0}>{ADD_BUTTON()}</Button>
        </Modal.Actions>
      </Modal >
    );
  }
}

const mapStateToProps = state => {
  return {
      deviceList: state.deviceList.deviceList
  };
};

export default withTranslation()(
  connect(mapStateToProps, { setDeviceList })(AddDeviceModal)
);
