import React, {Component} from 'react';
import { Button, Confirm, Dropdown, Header, Input, Modal, Radio, Popup } from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';
import {validDeviceNames, DEVICE_IMAGE, POPUP_POSITIONS} from '../utils/Constants';
import {imageSelector, resizeImage} from '../utils/Methods';
import {deleteOldCaches} from '../utils/CacheStorageHandler';
import {withTranslation} from 'react-i18next';
import {showError, showSuccess} from '../utils/ToastHelpers';
import {
    createDeviceCategory,
    getDeviceCategories,
    deleteCategory,
    updateDeviceInfo,
    deleteDevice,
    deleteDeviceImage
} from '../api/apiCalls';
import {PrivilegeConstants} from '../utils/PrivilegeConstants';
import { CANCEL_BUTTON, CONFIRM_BUTTON, DEVICE_CARD_CATEGORIES, DEVICE_TAG_HEADER, editDeviceFieldMessages, FAILED_ERROR_MESSAGE, SAVE_BUTTON, SELECT_OR_ADD_NEW_CATEGORY, SUCCESS_MESSAGE } from '../utils/UIMessages';
import { CHECKMARK_ICON, TRASH_ALTERNATE_OUTLINE_ICON } from '../utils/UiIcons';


class EditDeviceFields extends Component {

    state = {
        marketName: '',
        specificName: '',
        file: '',
        imagePreviewUrl: '',
        imageUploadButtonStatus: true,
        confirmVisibility: false,
        category: [],
        categoryList: [],
        categoryLoading: false,
        isOpenCategoryDeleteModal: false,
        updateFlag: false,
        bookable: false,
        selectedReservableGroups: [],
        groupList: [],
        reservableGroupList: [],
        messageVisibility: false
    };

    customCssForImage = {margin: '0 3vh 0 1vh', width: 'calc(15vh)', height: 'fit-content'}

    handleChangeMarketName = event => {
        this.setState({marketName: event.target.value});
    };
    handleChangeSpecificName = event => {
        this.setState({specificName: event.target.value});
    };

    componentDidUpdate(prevProps) {
        if (prevProps.device?.marketName !== this.props.device?.marketName) {
            if (this.props.device?.marketName === null || this.props.device?.marketName === undefined) {
                this.setState({marketName: ''})
            } else {
                this.setState({marketName: this.props.device?.marketName})
            }
        }
        if (prevProps.device?.specificName !== this.props.device?.specificName) {
            if (this.props.device?.specificName === null || this.props.device?.specificName === undefined) {
                this.setState({specificName: ''})
            } else {
                this.setState({specificName: this.props.device?.specificName})
            }
        }
        
        if (prevProps.device?.categories !== this.props.device?.categories) {
            if (this.props.device?.categories === null || this.props.device?.categories === undefined) {
                this.setState({category: []})
            } else {
                this.setState({ category: this.props.device?.categories?.map(({id}) => id) })
            }
        }

        if ((this.props.showModal !== prevProps.showModal) && this.props.showModal) {
            this.setState({
                bookable: this.props.device.bookable,
                selectedReservableGroups: this.props.device.reservableGroups
            })

            this.getCategories();
            this.props.getReservableGroupList().then(() => {
                const reservableGroupList = this.props.reservableGroupList?.map(g => {
                    return {
                        key: g.id,
                        value: g.id,
                        text: g.name
                    }
                })
                this.setState({reservableGroupList})
            });
        }
    }



    updateDeviceInformation = async () => {
        const formData = new FormData();
        formData.append('marketName', this.state.marketName);
        formData.append('reservableGroups', this.state.selectedReservableGroups);
        formData.append('specificName', this.state.specificName);
        formData.append('deviceCategories',this.state.category);
        formData.append('bookable', this.state.bookable);
        formData.append('deviceModel', this.props.device?.deviceModel);
        if (!this.state.file) {
            this.sendDeviceInfo(formData)
        } else {
            formData.append('deviceImage', this.state.file);
            this.sendDeviceInfo(formData)
            this.props.reloadImages();
        }
        this.setState({ updateFlag: true })
    }

    sendDeviceInfo = async(formData) => {
        await updateDeviceInfo(this.props.device?.id, formData).then(() => {
            showSuccess(SUCCESS_MESSAGE());
            deleteOldCaches('images-1').then(() => {
                this.setState({ file: '', imageUploadButtonStatus: true, selectedReservableGroups: [] });
                this.props.onClose();
            })
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()} ${err.response?.data?.message ?? err.toString()}`);
        });
    }

    handleImageChange = async e => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (file !== undefined) {
            const resizedFile = await resizeImage(file, DEVICE_IMAGE.SIZE_MULTIPLIER, DEVICE_IMAGE.QUALITY, DEVICE_IMAGE.MAXIMUM_WIDTH, DEVICE_IMAGE.MAXIMUM_HEIGHT);
            if (resizedFile.type.includes('image')) {
                reader.onloadend = () => {
                    this.setState({
                        file: resizedFile,
                        imagePreviewUrl: reader.result,
                        imageUploadButtonStatus: false
                    });
                };
                reader.readAsDataURL(resizedFile)
            }
        } else {
            this.setState({
                file: '',
                imagePreviewUrl: ''
            })
        }
        
    };

    handleDeleteDevice = deviceId => {
        deleteDevice(deviceId).then(() => {
            showSuccess(SUCCESS_MESSAGE());
            this.setState({confirmVisibility: false},()=>this.props.onClose())
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()} ${err.response?.data?.message ?? err.toString()}`);
        })
    };

    onDeleteDeviceImage = deviceModel => {
        deleteDeviceImage(deviceModel).then(() => {
            showSuccess(SUCCESS_MESSAGE());
            deleteOldCaches('images-1').then(() => {
                this.props.onClose();
            })
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()} ${this.props.t(err.response?.data?.message ?? err.toString())}`);
        })
    };

    addCategory = data => {
        this.setState({categoryLoading: true});
        if (/\s/g.test(data.value)) {
            showError(editDeviceFieldMessages().CATEGORY_NAME_WHITESPACE_ERROR_MESSAGE);
            this.setState({categoryLoading: false});
        } else if (['and', 'or', 'not'].includes(data.value?.toLowerCase())) {
            showError(editDeviceFieldMessages().CATEGORY_NAME_NOT_ALLOWED_ERROR_MESSAGE);
            this.setState({categoryLoading: false});
        } else {
            createDeviceCategory(data.value).then(() => {
                this.getCategories();
            }).catch((err) => {
                showError(`${editDeviceFieldMessages().NO_ADD_NEW_CATEGORY_ERROR_MESSAGE}: ${this.props.t(err?.response?.data?.message ?? err.toString())}`);
            }).finally(() => {
                this.setState({
                    categoryLoading: false
                });
            });
        }
    }

    getCategories = () => {
        this.setState({categoryLoading: true});
        //axios.get("/api/v1/device-categories")
        getDeviceCategories().then(res => {
            this.setState({categoryList: res.data.map(cat => ({key: cat.id, text: cat.name, value: cat.id}))});
        })
            .catch(() => {
                showError(editDeviceFieldMessages().NOT_GET_CATEGORIES_ERROR_MESSAGE);
            })
            .finally(() => this.setState({categoryLoading: false}));
    }


    deleteCategory = () => {
        //axios.delete(`/api/v1/device-categories/${this.state.category}`)
        deleteCategory(this.state.category.toString()).then(() => {
            this.getCategories();
            this.setState({
                category: [],
                isOpenCategoryDeleteModal: false
            });
        })
            .catch(() => {
                showError(editDeviceFieldMessages().NOT_DELETE_CATEGORY_ERROR_MESSAGE);
            })
    }

    renderDeviceImageSection(){
        return (
            <>
                <label>{editDeviceFieldMessages().DEVICE_IMAGE_LABEL}</label>
                <div className='edit-device-input-container'>
                    <Input
                        size='small'
                        type='file'
                        accept='image/*'
                        name='deviceImage'
                        onChange={this.handleImageChange}
                        className='edit-device-input'
                    />
                    <Popup
                        position={POPUP_POSITIONS.TOP_RIGHT}
                        content={editDeviceFieldMessages().REMOVE_DEVICE_IMAGE_MESSAGE}
                        trigger={
                            <Button
                                icon='ban'
                                disabled={!this.props.privileges.includes(PrivilegeConstants.EDIT_DEVICE_INFORMATION)}
                                onClick={() => this.onDeleteDeviceImage(this.props.device.deviceModel)}
                                id='edit-device-image-remove-button'
                            />
                        }
                    />
                </div>
            </>
        )
    }

    renderMarketNameSection(){
        return (
            <>
                <label>{editDeviceFieldMessages().MARKET_NAME_LABEL}</label>
                <div className='edit-device-input-container'>
                    <Input
                        size='small'
                        error={(this.state.marketName?.match(validDeviceNames) === null)}
                        value={this.state.marketName}
                        onChange={this.handleChangeMarketName}
                        className='edit-device-input'
                    />
                </div>
                {this.state.marketName?.match(validDeviceNames) === null &&
                    <label style={{ color: 'red' }}>{editDeviceFieldMessages().MARKET_NAME_NOT_VALID_MESSAGE}</label>
                }
            </>
        )
    }

    renderDeviceTagSection(){
        return (
            <>
                <label>{DEVICE_TAG_HEADER()}</label>
                <div className='edit-device-input-container'>
                    <Input
                        size='small'
                        value={this.state.specificName}
                        error={this.state.specificName?.match(validDeviceNames) === null}
                        onChange={this.handleChangeSpecificName}
                        className='edit-device-input'
                    />
                </div>
                {this.state.specificName?.match(validDeviceNames) === null &&
                    <label style={{ color: 'red' }}>{editDeviceFieldMessages().DEVICE_TAG_NOT_VALID_MESSAGE}</label>
                }
            </>
        )
    }

    renderDeviceCategorySection(){
        return (
            <>
                <label>{DEVICE_CARD_CATEGORIES()}</label>
                <div className='edit-device-display-flex-column'>
                    <div>
                        <Dropdown
                            search
                            multiple
                            selection
                            clearable
                            allowAdditions
                            additionLabel={editDeviceFieldMessages().DEVICE_CATEGORY_ADDITIONAL_LABEL + ': '}
                            placeholder={SELECT_OR_ADD_NEW_CATEGORY()}
                            value={this.state.category}
                            options={this.state.categoryList}
                            onChange={(_e, { value }) => {
                                const existingOptions = this.state.categoryList.map((option) => option.value);
                                const validValues = value.filter((val) => existingOptions.includes(val));
                                this.setState({ category: validValues });
                              }}
                            onAddItem={(_e, data) => this.addCategory(data)}
                            loading={this.state.categoryLoading}
                            disabled={this.state.categoryLoading}
                            className='edit-device-input category-dropdown'
                        />

                        <Button
                            content={editDeviceFieldMessages().PERMANENT_DELETE_BUTTTON}
                            icon={TRASH_ALTERNATE_OUTLINE_ICON}
                            disabled={this.state.category.length < 1}
                            onClick={() => this.setState({ isOpenCategoryDeleteModal: true })}
                            id='edit-device-category-delete-button'
                        />

                        <Confirm
                            open={this.state.isOpenCategoryDeleteModal}
                            onCancel={() => this.setState({ isOpenCategoryDeleteModal: false })}
                            header={editDeviceFieldMessages().DELETE_CATEGORY_CONFIRM_HEADER}
                            onConfirm={this.deleteCategory}
                            cancelButton={CANCEL_BUTTON()}
                            confirmButton={CONFIRM_BUTTON()}
                            content={editDeviceFieldMessages().DELETE_CATEGORY_CONFIRM}
                            size='tiny'
                        />
                    </div>
                </div>
            </>
        )
    }

    renderReservable() {
        return (
            <div className='edit-device-input-container'>
                <Radio
                    toggle
                    checked={this.state.bookable}
                    onChange={(_e, data) => this.setState({ bookable: data.checked, messageVisibility: true })}
                    label={editDeviceFieldMessages().RESERVABLE_LABEL}
                    className='edit-device-reservable'
                />
            </div>
        )
    }

    renderReservableGroupSection() {
        return (
            <>
                <label>{editDeviceFieldMessages().GROUP_RESERVATION_DROPDOWN_PLACEHOLDER}</label>
                <Dropdown
                    placeholder={editDeviceFieldMessages().GROUP_RESERVATION_DROPDOWN_PLACEHOLDER}
                    fluid
                    multiple
                    selection
                    value={this.state.selectedReservableGroups}
                    options={this.state.reservableGroupList}
                    onChange={(_e, { value }) => this.setState({ selectedReservableGroups: [...value] })}
                />
            </>
        )
    }

    renderModalDescription() {
        return (
            <>
                <Modal.Description id='edit-device-modal-size'>
                    <div className='edit-device-display-flex-column'>
                        {this.props.privileges.includes(PrivilegeConstants.EDIT_DEVICE_INFORMATION) &&
                        <>
                            {this.renderDeviceImageSection()}
                            {this.renderMarketNameSection()}
                            {this.renderDeviceTagSection()}
                            {this.renderReservable()}
                        </>
                        }
                        {this.props.privileges.includes(PrivilegeConstants.EDIT_DEVICE_INFORMATION) && this.state.bookable &&
                            this.state.reservableGroupList?.length > 0 &&
                            this.renderReservableGroupSection()
                        }
                        {this.renderDeviceCategorySection()}
                    </div>
                </Modal.Description>
            </>
        )
    }

    prepareModalHeader(){
        const headerItems = [this.props.device.deviceId, this.props.device.deviceModel, this.props.device.os];
        if(this.props.device.marketName){
            headerItems.push(this.props.device.marketName);
        }
        return headerItems.join(' / ');
    }

    render() {
        const {imagePreviewUrl, file, imageUploadButtonStatus} = this.state;
        const imagePreviewExists = imagePreviewUrl && file;
        return (
            <div>
                <>
                    <Modal
                        closeIcon
                        size='small'
                        open={this.props.showModal}
                        onClose={() => {
                            this.props.onClose();
                            this.setState({ file: '', imageUploadButtonStatus: true })
                        }}
                        closeOnDimmerClick={true}
                    >
                        <Header
                            icon='mobile'
                            textAlign='center'
                            as='h2'
                            content= {editDeviceFieldMessages().MODAL_HEADER}
                            subheader={this.prepareModalHeader()}
                        />
                       
                        <Modal.Content image style={{ lineHeight: 2.5 }}>
                            <div id='edit-device-modal-content-size'>
                                {this.props.privileges.includes(PrivilegeConstants.EDIT_DEVICE_INFORMATION) &&
                                    <>
                                        {imagePreviewExists ?
                                            <img style={this.customCssForImage}
                                                src={imagePreviewUrl} alt={'ImagePreview'}
                                                className='device-card-image' /> :
                                            imageSelector(this.props.device.deviceModel, this.props.loadingImages,
                                                this.props.deviceImages, this.customCssForImage)}
                                    </>
                                }
                                {this.renderModalDescription(imageUploadButtonStatus)}
                            </div>
                        </Modal.Content>

                        <Modal.Actions>
                            <React.Fragment>
                                {this.props.privileges.includes(PrivilegeConstants.EDIT_DEVICE_INFORMATION) && <>
                                    {this.props.device.deviceStatus === 1 && <Button
                                            disabled={!this.props.privileges.includes(PrivilegeConstants.REMOVE_DEVICE)}
                                            color={'red'} onClick={() => {
                                            this.setState({confirmVisibility: true})
                                        }}> {editDeviceFieldMessages().DELETE_DEVICE_LABEL} </Button>
                                    }
                                    {this.props.device.deviceStatus !== 1 && <Button color={'red'} disabled> {editDeviceFieldMessages().DELETE_THIS_DEVICE_BUTTON} </Button>}
                                </>
                                }

                                <Button color='green' icon={CHECKMARK_ICON}
                                    onClick={async () => {
                                        await this.updateDeviceInformation();
                                        this.setState({file: '', imageUploadButtonStatus: true, selectedReservableGroups: []});
                                        this.props.onClose();
                                    }}
                                    content={SAVE_BUTTON()} 
                                    disabled={this.state.specificName?.match(validDeviceNames) === null || this.state.marketName?.match(validDeviceNames) === null}
                                />
                            </React.Fragment>
                        </Modal.Actions>
                    </Modal>
                </>
                <Confirm
                    centered={true}
                    open={this.state.confirmVisibility}
                    header={editDeviceFieldMessages().DELETE_DEVICE_LABEL}
                    content={editDeviceFieldMessages().DELETE_CONFIRM_MESSAGE}
                    onClose={() => {
                        this.setState({confirmVisibility: false})
                    }}
                    onCancel={() => {
                        this.setState({confirmVisibility: false})
                    }}
                    onConfirm={() => {
                        this.handleDeleteDevice(this.props.device.deviceId)
                    }}
                    cancelButton={CANCEL_BUTTON()}
                    confirmButton={CONFIRM_BUTTON()}
                />
            </div>
        );
    }
}


export default withTranslation()(withRouter(EditDeviceFields));
