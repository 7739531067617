import { Modal, Header, Grid, Form, Dropdown, Checkbox, Input, Progress, Icon, Button, Popup } from 'semantic-ui-react'
import { ACCEPTABLE_APP_EXTENSION, DEVICEOS, POPUP_POSITIONS } from "../utils/Constants";
import { extensionListToAcceptAttribute } from '../utils/Methods'
import { PrivilegeConstants } from '../utils/PrivilegeConstants';

import FileSelectionForm from './FileSelectionForm'
import { APPLICATION_CATEGORY, appUploadMessages, CANCEL_BUTTON, SELECT_OR_ADD_NEW_CATEGORY, UPLOAD_NEW_APP_LABEL } from '../utils/UIMessages'
import { UPLOAD_ICON, X_ICON, QUESTION_CIRCLE_OUTLINE_ICON } from '../utils/UiIcons'


const AppUploadModal = props => {

    const acceptableExtensions = extensionListToAcceptAttribute(ACCEPTABLE_APP_EXTENSION);

    return (
        <>
        <Modal
            open={props.appUploadModalVisibility}
            onClose={props.handleCloseUploadAppModal}
            size='small'
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
            centered={false}
        >
            <Header
                icon='upload'
                content={UPLOAD_NEW_APP_LABEL()}
            />
            <Modal.Content>
                <Grid>
                    <Grid.Row columns={2} textAlign={'center'}>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label
                                        style={{ textAlign: 'left' }}
                                    >
                                        {APPLICATION_CATEGORY()}
                                    </label>
                                    <Dropdown
                                        search
                                        selection
                                        clearable
                                        allowAdditions
                                        additionLabel={`${appUploadMessages().NEW_CATEGORY_LABEL}: `}
                                        placeholder={SELECT_OR_ADD_NEW_CATEGORY()}
                                        value={props.value}
                                        options={props.allAppCategories}
                                        onChange={(e, { value }) => props.selectAppCategory(value)}
                                        onAddItem={props.handleAddition}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label style={{ textAlign: 'left' }}>
                                        {appUploadMessages().APPLICATION_VERSION_NAME_LABEL}
                                    </label>
                                    <Input
                                        value={props.appVersionName}
                                        onChange={(e, { value }) =>props.setAppVersionName(value)}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                    <FileSelectionForm
                        headerName={appUploadMessages().APPLICATION_FILE_LABEL}
                        acceptableExtensions={acceptableExtensions}
                        errMsg={props.fileExtensionErrorMsg}
                        onChangeFileUpload={props.onChangeAppUpload} />
                    {
                        (props.privilegesProp.includes(PrivilegeConstants.ANNOUNCEMENT_MANAGEMENT) || props.resignServerAvailable) &&
                        <div id='app-upload-modal-announce-and-resign-app'>
                            {
                                // Announce After App Upload-------------------------------------------------
                                props.privilegesProp.includes(PrivilegeConstants.ANNOUNCEMENT_MANAGEMENT) &&
                                <div className='manuel-segment-in-app-upload-modal'>
                                    <Checkbox label={appUploadMessages().ANNOUNCE_NEW_APP_LABEL}
                                        checked={props.isAnnounceAfterAppUploadProp}
                                        onChange={props.onChangeAnnounceNewAppProp}
                                    />
                                    <span className='manuel-segment-in-app-upload-modal-pop-up'>
                                        <Popup
                                          content={appUploadMessages().QUESTION_MARK_ANNOUNCE_NEW_APP}
                                          position={POPUP_POSITIONS.RIGHT_CENTER}
                                          trigger={<Icon name={QUESTION_CIRCLE_OUTLINE_ICON} />}
                                        />
                                    </span>

                                </div>
                            }
                            {
                                props.resignServerAvailable &&
                                <div className='manuel-segment-in-app-upload-modal'>
                                    <Checkbox label={appUploadMessages().RESIGN_APPLICATION_LABEL}
                                        checked={props.resign}
                                        onChange={props.onChangeResign} />
                                </div>
                            }
                            {
                              props.selectedFileOS === DEVICEOS.ANDROID &&
                              <div className='manuel-segment-in-app-upload-modal'>
                                  <Checkbox label={appUploadMessages().INJECT_APP_LABEL}
                                            onChange={props.onChangeInjection} />
                                  <span className='manuel-segment-in-app-upload-modal-pop-up'>
                                  <Popup
                                    className='manuel-segment-in-app-upload-modal-pop-up'
                                    content={appUploadMessages().QUESTION_MARK_INJECT_APP}
                                    position={POPUP_POSITIONS.RIGHT_CENTER}
                                    trigger={<Icon name={QUESTION_CIRCLE_OUTLINE_ICON} />}
                                  />
                                  </span>
                              </div>
                            }
                        </div>
                    }
                <Progress
                    style={{ marginTop: 25 }}
                    percent={props.uploadAppPercent}
                    disabled={!props.appFile}
                    indicating
                    progress
                />
                <div style={{ textAlign: 'right' }}>
                    <Button
                        loading={props.loadingUploadButton}
                        color={'green'}
                        icon={<Icon name={UPLOAD_ICON} />}
                        content={appUploadMessages().UPLOAD_APP_BUTTON}
                        disabled={!props.appFile || props.loadingUploadButton || props.fileExtensionErrorMsg.length > 0}
                        onClick={props.handleUploadApp}
                    />
                    <Button
                        color={'red'}
                        icon={<Icon name={X_ICON} />}
                        content={CANCEL_BUTTON()}
                        disabled={!props.appFile || !props.loadingUploadButton}
                        onClick={props.handleCancelUpload}
                    />
                </div>
            </Modal.Content>
        </Modal>
        </>
    )
}

export default AppUploadModal
