import React, {Component} from 'react';
import {Grid, Header, Segment, Statistic} from "semantic-ui-react";
import {ResponsiveLine} from "@nivo/line";
import {ResponsivePie} from "@nivo/pie";
import DateRangeDropdown from "../../../../components/DateRangeDropdown";
import moment from "moment";
import CountUp from "react-countup";
import _, { uniqueId } from 'lodash';
import axios from "axios";
import {dataRefresher} from "../../../../utils/Methods";
import {withTranslation} from 'react-i18next';
import {showError} from "../../../../utils/ToastHelpers";
import { EVERY_DAY_LABEL, EVERY_MONTH_LABEL, FAILED_ERROR_MESSAGE, TIME_LABEL, chartsLicenseUsageMessages} from '../../../../utils/UIMessages';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

class ChartsLu extends Component {

    state = {
        componentDidUpdateCheck:false,
        dataForLineChart: [],
        dataForPieChart: [],
        visibility: false,
        licenseTotal: 0,
        startDate: new Date(),
        endDate: new Date(),
        pageSize1: 10,
        pageSize2: 10,
        //sorted1: [],
        //sorted2: [],
        subscription: {},
        subscriptionId: null,
        loading: false,
        language: localStorage.getItem('lang') || 'en'
    }

    generateLines = (x, y, arr) => {
        let lineArray = []
        for (let arrKey in arr) {
            lineArray.push(
                {
                    "x": x[arrKey],
                    "y": y[arrKey]
                }
            )
        }
        return lineArray
    }

    convertDataForLineChart = (...allData) => {
        this.setState({visibility: false})
        let dataForLineChart = allData.map((el, index) => {
            let c = index * 4
            return {
                id: this.props.t(_.startCase(el.y)),
                color: `hsl(${c}, 70%, 50%)`,
                data: this.generateLines(el?.response[el.x], el?.response[el.y], el?.response[el.x])
            }
        })
        this.setState({dataForLineChart}, () => {
            this.setState({visibility: true})
        })
    }

    convertDataForPieChart = (...allData) => {
        this.setState({visibility: false})
        let dataForPieChart;
        let licenseTotal = allData[0]?.licenseTotal
        let licenseUsed = allData[0]?.licenseUsed
        let licenseUnused = licenseTotal - licenseUsed
        let licenseUsedValue = {
            id: chartsLicenseUsageMessages().LICENSE_USED_LABEL,
            label: chartsLicenseUsageMessages().LICENSE_USED_LABEL,
            value: licenseUsed,
            color: "hsl(15, 70%, 50%)"
        }
        let licenseUnusedValue = {
            id: chartsLicenseUsageMessages().LICENSE_UNUSED_LABEL,
            label: chartsLicenseUsageMessages().LICENSE_UNUSED_LABEL,
            value: licenseUnused,
            color: "hsl(242, 70%, 50%)"
        }
        dataForPieChart = [licenseUsedValue, licenseUnusedValue]
        this.setState({dataForPieChart, licenseTotal, visibility: true})
    }

    getLicenseUsageInfo = () => {
        if (this.props.wsClient) {
            const id = uniqueId();
            this.setState({ subscriptionId: id });
            const subscription=this.props.wsClient.subscribe('/topic/license-usage', message => {
              const msgObj = JSON.parse(message?.body);
              this.convertDataForPieChart(msgObj)
          }, { 'AccessToken': localStorage.getItem('accessToken'), id})
          this.setState({subscription})
        }
    }

    dailyOrMonthlyCheck = async (type, params = "") => { //server request
        this.setState({ loading: true });
        try {
            if (type === "daily") {
                let licenseUsageDaily = await axios.get('/api/reports/license-usage-daily' + params);
                licenseUsageDaily = licenseUsageDaily.data;
                this.convertDataForLineChart(
                    {"response": licenseUsageDaily, x: "dates", y: "licenseUsed"},
                    {"response": licenseUsageDaily, x: "dates", y: "totalLicense"})

                this.setState({loading: false})
                let startDate = new Date(licenseUsageDaily.dates[0])
                let endDate = new Date(licenseUsageDaily.dates[licenseUsageDaily.dates.length - 1])
                this.setState({startDate, endDate})
             
            }
            if (type === "monthly") {
                let licenseUsageMonthly = await axios.get('/api/reports/license-usage-monthly' + params);
                licenseUsageMonthly = licenseUsageMonthly.data;
                this.convertDataForLineChart(
                    {"response": licenseUsageMonthly, x: "dates", y: "licenseUsed"},
                    {"response": licenseUsageMonthly, x: "dates", y: "totalLicense"})
                this.setState({loading: false})
                let startDate = new Date(licenseUsageMonthly.dates[0])
                let endDate = new Date(licenseUsageMonthly.dates[licenseUsageMonthly.dates.length - 1])
                this.setState({startDate, endDate})

            }
        } catch (err) {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString());
            this.setState({ loading: false });
        }
        
    }
      componentDidMount() {
        this.dailyOrMonthlyCheck(this.props.type);
    }

    componentWillUnmount(){
        if(this.props.wsClient){
            this.props.wsClient.unsubscribe(this.state.subscriptionId);
          }
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.setState({
                //sorted1:[],
                //sorted2:[],
                visibility: false,
                componentDidUpdateCheck : !this.state.componentDidUpdateCheck
            })
            this.dailyOrMonthlyCheck(this.props.type)
        }
        if(!this.state.subscriptionId){
-            this.getLicenseUsageInfo();
        }
        
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.dailyOrMonthlyCheck(this.props.type))
        )
    }

    onChangeDateComponent = link => {
        const params = "?" + link
        this.setState({visibility: false})
        this.dailyOrMonthlyCheck(this.props.type, params)
    }
    render() {
        return (
            <div>
                <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <DateRangeDropdown
                            isOrderByVisibility={false}
                            isSizeVisibility={false}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            isUserRolesVisibility={false}
                            type={this.props.type}
                            setSpecificEndDate={new Date().setDate(new Date().getDate() - 1)}
                            callback={(link) => {
                                this.onChangeDateComponent(link)
                            }} />
                    </Grid.Column>
                </Grid.Row>
                    <Grid.Row centered columns={1}>
                        <Grid.Column>
                            <Header textAlign={"center"}>
                                {chartsLicenseUsageMessages(_.startCase(this.props.type === 'daily' ? 'Day' : 'Month')).LICENSE_USAGE_BY_HEADER}
                            </Header>
                            <Segment placeholder style={{height: 500}} loading={this.state.loading}>
                            {this.state.visibility && <ResponsiveLine
                                data={this.state.dataForLineChart}
                                margin={{top: 50, right: 30, bottom: 70, left: 60}}
                                yFormat=">-d"
                                enableSlices="x"
                                yScale={{
                                    type: "linear",
                                    min: 0,
                                    max: "auto",
                                    stacked: false,
                                    reverse: false
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisLeft={{
                                    orient: "left",
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    tickValues: 5,
                                    legend: chartsLicenseUsageMessages().LICENSE_USAGE_AXIS_LEFT,
                                    legendOffset: -40,
                                    legendPosition: "middle"
                                }}
                                axisBottom={{
                                    format: this.props.type === 'daily' ? (tick) => moment(tick).format('MMM DD') : (tick) => moment(tick).format('YYYY MMM'),
                                    tickValues: this.props.type === 'daily' ? EVERY_DAY_LABEL() : EVERY_MONTH_LABEL(),
                                    tickRotation: -55,
                                    legend: TIME_LABEL(),
                                    legendOffset: 55,
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    legendPosition: 'middle'
                                }}
                                crosshairType={"bottom-left"}
                                colors={{scheme: 'set1'}}
                                pointSize={10}
                                pointColor={{from: 'color', modifiers: []}}
                                pointBorderWidth={2}
                                pointBorderColor={{from: "serieColor"}}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                useMesh={true}
                                legends={[
                                    {
                                        anchor: "bottom-right",
                                        direction: "row",
                                        justify: false,
                                        translateX: 30,
                                        translateY: 60,
                                        itemsSpacing: 30,
                                        itemDirection: "left-to-right",
                                        itemWidth: 100,
                                        itemHeight: 0,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: "circle",
                                        symbolBorderColor: "rgba(0, 0, 0, .5)",
                                        effects: [
                                            {
                                                on: "hover",
                                                style: {
                                                    itemBackground: "rgba(0, 0, 0, .03)",
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Segment placeholder style={{height: 300, minHeight: "22rem"}} loading={this.state.loading}>
                            <ResponsivePie
                                data={this.state.dataForPieChart}
                                margin={{top: 40, right: 80, bottom: 80, left: 80}}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={{scheme: 'set2'}}
                                borderWidth={1}
                                borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextColor="#333333"
                                radialLabelsLinkColor={{from: 'color'}}
                                sliceLabelsSkipAngle={10}
                                sliceLabelsTextColor="#333333"
                                legends={[
                                    {
                                        anchor: 'bottom',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 25,
                                        translateY: 50,
                                        itemsSpacing: 20,
                                        itemWidth: 100,
                                        itemHeight: 18,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment placeholder style={{display: "flex", alignItems: "center", minHeight: "22rem"}} loading={this.state.loading}>
                            <div>
                                <Statistic.Group horizontal>
                                    {this.state.dataForPieChart?.map((el, index) => {
                                        return (
                                            <Statistic key={index}>
                                                <Statistic.Value>
                                                    <CountUp start={0} end={el.value} delay={0.7}/>
                                                </Statistic.Value>
                                                <Statistic.Label>{this.props.t(`${el.label}`)}</Statistic.Label>
                                            </Statistic>
                                        )
                                    })}
                                    <hr style={{width: "100%"}}/>
                                    {this.state.licenseTotal !== 0 && <Statistic key={99}>
                                        <Statistic.Value>
                                            <CountUp start={0} end={this.state.licenseTotal} delay={0.5}/>
                                        </Statistic.Value>
                                        <Statistic.Label>{chartsLicenseUsageMessages().LICENSE_TOTAL_LABEL}</Statistic.Label>
                                    </Statistic>}
                                </Statistic.Group>
                            </div>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      wsClient: state.stompClient.client
    };
  };

export default withTranslation()(connect(mapStateToProps)(withRouter(ChartsLu)));
