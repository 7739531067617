import React from 'react';
import {useTranslation} from 'react-i18next';
import {Tab} from "semantic-ui-react";
import ChartsDev from "./ChartsDEV";
import {headerForReports} from "../../../../utils/Methods";
import { chartsDevMessages } from '../../../../utils/UIMessages';


const Devices = () => {
    const { t } = useTranslation();

    const panes = [
      {
          menuItem: chartsDevMessages().ALL_DEVICES_HEADER,
          render: () => <><ChartsDev type={"all"}/></>,
      },
      {
          menuItem: chartsDevMessages().USED_DEVICES_HEADER,
          render: () => <><ChartsDev type={"used"}/></>,
      },
    ]
    return (
        <div className='main-container-report'>
        <div className="main-right-header">
            <h2>{t(headerForReports())}</h2>
        </div>

        <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
    </div>
    );
};

export default Devices;