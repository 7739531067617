
import React, { useEffect, useState } from 'react'
import { Grid, Header, Modal, Tab, Button, Popup} from 'semantic-ui-react';
import ManageAutomationSettings from '../pages/manage/ManageAutomationSettings';
import { useTranslation } from 'react-i18next';
import { showError } from '../utils/ToastHelpers';
import { imageSelector, renderedNodeInfo} from '../utils/Methods';
import {getDeviceInfo, getDeviceDetails} from '../api/apiCalls';
import 'react-toastify/dist/ReactToastify.css';
import DeviceClipboard from '../pages/device/DeviceClipboard';
import { POPUP_POSITIONS } from '../utils/Constants';
import { AUTOMATION_SETTINGS_HEADER, DEVICE_DETAILS_LABEL, DEVICE_ID_HEADER, DEVICE_INFORMATION_CLIPBOARD_POPUP_MESSAGE, DEVICE_MODEL_HEADER, informationPanelMessages, MAC_ADDRESS_HEADER, OS_HEADER, SCREEN_RESOLUTION_HEADER, SCREEN_SIZE_HEADER, SHOW_MORE_BUTTON, SHOW_LESS_BUTTON, NODE_INFO_LABEL } from '../utils/UIMessages';
import { CLIPBOARD_ICON } from '../utils/UiIcons';

const InformationPanel = props => {
    const [deviceDetails, setDeviceDetails] = useState({});
    const [deviceModel, setDeviceModel] = useState('');
    const [groups, setGroups] = useState([]);
    const [modalState, setModalState] = useState(false);
    const [deviceSpecs, setDeviceSpecs] = useState({});
    const { t } = useTranslation();
    const slicedGroup = 3;
    const [showAllGroups, setShowAllGroups] = useState(false);

    const customCssForImage = { position: 'absolute', height: 'calc(30vh)', width: 'calc(15vh)' }

    useEffect(() => {
        if (props.open === true && props.deviceDetails) {
            try {
                setDeviceDetails((prevState) => ({
                    ...prevState,
                    'Device ID': props.deviceDetails?.deviceId,
                    'GPS Status': props.deviceDetails?.gps === true ? informationPanelMessages().OPEN_LABEL : informationPanelMessages().CLOSED_LABEL,
                    'Wifi Status': props.deviceDetails?.wifi === true ? informationPanelMessages().OPEN_LABEL : informationPanelMessages().CLOSED_LABEL
                }))

                    fetchDeviceData();
                    getDeviceDetails(props.deviceDetails?.deviceId).then(res => {
                        setDeviceSpecs(res.data);
                    }).catch(() => {
                        showError(informationPanelMessages().DEVICE_DETAILS_NOT_FETCH);
                    })
                } catch (error) {
                    showError(`${informationPanelMessages().DEVICE_SPECS_NOT_FETCH} ${error?.response?.data?.message ?? error.toString()}.`);    
                }
        }
    }, [props.open, props.deviceDetails])

    const fetchDeviceData = async() =>{
       const res = await getDeviceInfo(props.deviceDetails?.deviceId);
       if(res.data){
           setDeviceDetails(prevState=>({
               ...prevState,
               'Device Model': `${res.data?.brand} ${res.data?.deviceModel}` || '',
               'OS': `${res.data?.os} ${res.data?.oSVersion}` || '',
               'Screen Size': res.data?.screenSize,
               'Screen Resolution': res.data?.screenResolution || '',
               'Node Info': renderedNodeInfo(res.data.node),
               'Mac Address': res.data?.macAddress || ''
           }))
           setDeviceModel(res.data?.deviceModel)
           setGroups(res.data?.groups);
       }
    }

    const toggleShowAllGroups = () => {
        setShowAllGroups(prevState => !prevState);
    };

    const renderedGroups = () => {
        const groupsToDisplay = showAllGroups ? groups : groups.slice(0, slicedGroup);
        return groupsToDisplay.map(group => (
            <Grid.Column key={group.name} id='column-style-group'>
                <p id='column-style-text'>{group.name}</p>
            </Grid.Column>
        ));
    };



    return (
        <Modal
            open={props.open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            closeIcon
            style={{ minHeight: 'calc(50vh - 100px)' }}
            onClose={() => props.onClose()}
        >
            <Tab
                panes={[
                    {
                        menuItem: DEVICE_DETAILS_LABEL(),
                        render: () => <div style={{ marginTop: '20px', marginBottom: '20px', minHeight: 'calc(50vh - 100px)' }}>
                            <Grid columns={3}>
                                <Grid.Row stretched>
                                    <Grid.Column>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {imageSelector(deviceModel, props.loadingImages, props.deviceImages, customCssForImage)}
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column className='information-panel-column'>
                                        <Header as='h4'>{DEVICE_ID_HEADER()}</Header>
                                        <p>{deviceDetails['Device ID']}</p>
                                        <Header as='h4'>{DEVICE_MODEL_HEADER()}</Header>
                                        <p>{deviceDetails['Device Model']}</p>
                                        <Header as='h4'>{OS_HEADER()}</Header>
                                        <p>{deviceDetails['OS']}</p>
                                        <Header as='h4'>{NODE_INFO_LABEL()}</Header>
                                        <p>{deviceDetails['Node Info']}</p>
                                        <Header as='h4'>{MAC_ADDRESS_HEADER()}</Header>
                                        <p>{deviceDetails['Mac Address']}</p>
                                    </Grid.Column>
                                    <Grid.Column className='information-panel-column'>
                                        <Header as='h4'>{SCREEN_SIZE_HEADER()}</Header>
                                        <p>{deviceDetails['Screen Size']}</p>
                                        <Header as='h4'>{SCREEN_RESOLUTION_HEADER()}</Header>
                                        <p>{deviceDetails['Screen Resolution']}</p>
                                        <Header as='h4'>{informationPanelMessages().WIFI_STATUS_HEADER}</Header>
                                        <p>{t(deviceDetails['Wifi Status'])}</p>
                                        <Header as='h4'>{informationPanelMessages().GPS_STATUS_HEADER}</Header>
                                        <p>{t(deviceDetails['GPS Status'])}</p>
                                        {groups.length > 0 && <>
                                            <Header as='h4'>{informationPanelMessages().GROUPS_HEADER}</Header>
                                            
                                            <div className='groups-tab'>
                                                <div id='list-of-groups'>
                                                    {renderedGroups()}
                                                </div>
                                                {groups.length > slicedGroup && (
                                                <Button onClick={() => toggleShowAllGroups()} id='groups-button'>
                                                    {showAllGroups ? SHOW_LESS_BUTTON() : SHOW_MORE_BUTTON()}
                                                </Button>
                                                )}
                                            </div>
                                        </>}

                                    

                                        <div className='clipboard-div'>
                                            <Popup
                                                position={POPUP_POSITIONS.BOTTOM_CENTER}
                                                content={DEVICE_INFORMATION_CLIPBOARD_POPUP_MESSAGE()}
                                                trigger={
                                                    <Button compact icon={CLIPBOARD_ICON}
                                                        onClick={() => setModalState((prevState) => !prevState)}                                
                                                        />}
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    },
                    {
                        menuItem: AUTOMATION_SETTINGS_HEADER(),
                        render: () =>
                            <div className='information-panel-tab'>
                                <ManageAutomationSettings deviceId={deviceSpecs.deviceId} deviceOs={deviceSpecs.os}/>
                            </div>
                    }
                ]}
            >
            </Tab>
            <DeviceClipboard
                open={modalState}
                closeModal={() => setModalState(false)}
                openModal={() => setModalState(true)}
                device={deviceSpecs}
            />
        </Modal>
    )
}
export default InformationPanel
