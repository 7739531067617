import React,{useEffect, useState} from 'react'
import {Form, Modal,Input,Header,Button} from 'semantic-ui-react'
import { showError,showSuccess } from '../../src/utils/ToastHelpers';
import {updateFileName} from '../api/apiCalls'
import { EDIT_ICON } from '../utils/UiIcons';
import { EDIT_BUTTON, FAILED_ERROR_MESSAGE, fileEditMessages, SUCCESS_MESSAGE } from '../utils/UIMessages';

const FileEditModal = (props) => {

    const [name,setName] = useState(props.name);
    const [id,setId] = useState();

    useEffect(()=>{
        if(props.name){
            setName(props.name)
            setId(props.id)
        }
    },[props.name])

    const handleEditName = async() =>{
        try{
            await updateFileName(id,name);
            props.getFileList();
            showSuccess(SUCCESS_MESSAGE(),1000);  
        }catch(err){
            showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ??  err.toString()}`)
        }finally{
            props.onClose();
        }
    }

    return(
        <>
            <Modal
                open={props.editModalVisibility}
                onClose={props.onClose}
                size='small'
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                centered={false}
            >
                <Header                 
                    icon={EDIT_ICON}
                    content={fileEditMessages().HEADER_NAME}
                />
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <Input
                                type='text'
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                            >
                            </Input>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='green'
                        disabled = {!name.trim()}
                        onClick={() => handleEditName()}>
                        {EDIT_BUTTON()}
                    </Button>
                </Modal.Actions>
            </Modal>
    
        </>
    );
} 

export default FileEditModal
