import React, { useEffect,useState } from 'react'
import ChatPortal from './ChatPortal'
import {Icon} from 'semantic-ui-react'
import { Base64 } from 'js-base64';


const Chat = ({chatPortalVisibility,setchatPortalVisibility,device, deviceList,type}) => {

    const [user,setUser] = useState({});

    useEffect(()=>{
        const userDetails = localStorage.getItem('userDetails');
        const usr = JSON.parse(Base64.decode(userDetails));
        setUser(usr);
    },[])

    return (
        <div>
        {chatPortalVisibility ?
            (
                <ChatPortal
                    open={chatPortalVisibility}
                    onClose={()=>setchatPortalVisibility(false)}
                    device={device}
                    type={type}
                    user={user}
                    deviceList={deviceList}
                ></ChatPortal>
            ):(
                <Icon name='wechat' className='chat-icon' size='huge' onClick={()=>setchatPortalVisibility(true)}></Icon>
            )
        }
    </div>
    )
}

export default Chat