import React from 'react'
import {
    Card
} from 'semantic-ui-react'




const LibraryAppList = (props) => {
   const { appList } = props;
    return (
        <>
            <Card.Group centered>
                {Object.keys(appList).map((app, i) => {
                    return (
                        <Card
                            className='app-card-container'
                            key={i}
                            //onClick={() => this.setState({ detailModalVisibility: true,selectedApp:appList[app]})}
                            onClick={() => props.onOpenAppDetail(appList[app])}
                        >
                            <Card.Content>
                                <div className='device-card'>
                                    <div>
                                        <img
                                            style={{ objectFit: 'contain', height: 72, background: 'white' }}
                                            loading='lazy'
                                            src={'data:image/png;base64,' + appList[app][0].appIcon}
                                            alt='deviceImage'
                                        />
                                    </div>
                                    <div
                                        className='device-card-content'
                                        style={{ minHeight: 85 }}
                                    >
                                        <div className='device-card-name'>
                                            {appList[app][0].appName}
                                        </div>

                                        <span style={{ color: 'black' }}>
                                            {appList[app][0].appOs}
                                        </span>

                                        <span style={{ color: 'black' }}>
                                            {appList[app][0].appPackage.slice(0, -4)}
                                        </span>
                                    </div>
                                </div>
                            </Card.Content>
                        </Card>
                    );
                })}
            </Card.Group>
        </>
    )
}

export default LibraryAppList