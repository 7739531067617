import React, { useState } from 'react';
import { Modal, Button, Grid, Input, Popup } from 'semantic-ui-react';
import copy from 'copy-to-clipboard';
import { withTranslation } from 'react-i18next';
import { showInfo, showError } from '../utils/ToastHelpers';
import { SHOW_INFO_TIMEOUT } from '../utils/Constants';
import SharedUrlExpirationModal from './SharedUrlExpirationModal';
import { COPIED_LABEL, COPY_TO_CLIPBOARD_MESSAGE, sharedUrlMessages, UNSUPPORTED_TOKEN_TYPE_ERROR } from '../utils/UIMessages';
import { COPY_ICON, REFRESH_ICON, TIME_ICON } from '../utils/UiIcons';

const SharedUrlModal = ({ sharedUrlModalVisibility, onCloseSharingModal,
    sharedUrlView, sharedUrlManage,
    sharedUrlInspector, refreshToken, localeProp, deviceId }) => {

    const [linkExpirationModalVisibility, setLinkExpirationModalVisibility] = useState(false);
    const [linkType, setLinkType] = useState(null);

    const handleCodeCopy = e => {
        if (e.currentTarget.id.includes('view')) {
            copy(sharedUrlView)
        }
        if (e.currentTarget.id.includes('manage')) {
            copy(sharedUrlManage)
        }
        if (e.currentTarget.id.includes('inspector')) {
            copy(sharedUrlInspector)
        }
        showInfo(COPIED_LABEL(), SHOW_INFO_TIMEOUT);
        onCloseSharingModal();
    }

    const handleModalClose = () => {
        setLinkExpirationModalVisibility(false);
    }

    const linkExpiration = e => {
        if (e.currentTarget.id.includes('view')) {
            setLinkType('view');
            setLinkExpirationModalVisibility(true);
        } else if (e.currentTarget.id.includes('manage')) {
            setLinkType('manage');
            setLinkExpirationModalVisibility(true);
        } else if (e.currentTarget.id.includes('inspector')) {
            setLinkType('inspector');
            setLinkExpirationModalVisibility(true);
        } else {
            showError(UNSUPPORTED_TOKEN_TYPE_ERROR());
            setLinkExpirationModalVisibility(false);
            setLinkType(null);
        }

    }

    return (
        <Modal
            size='large'
            centered={true}
            open={sharedUrlModalVisibility}
            closeIcon={true}
            onClose={onCloseSharingModal}
            closeOnDimmerClick={true}
        >
            <Modal.Header content={sharedUrlMessages().MODAL_HEADER} />
            <Modal.Content>
                <Grid>
                    <Grid.Row className='shared-url-row'>
                        <Grid.Column width={13}>
                            <label>{sharedUrlMessages().VIEW_ONLY_SCREEN_LABEL}</label>
                            <Input fluid readOnly focus value={sharedUrlView} />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Popup
                                size='tiny'
                                trigger={<Button className='shared-url-copy-button' onClick={handleCodeCopy}
                                    id='view' icon={COPY_ICON} />}
                                content={COPY_TO_CLIPBOARD_MESSAGE()}
                                position='bottom center'
                            />
                            <Popup
                                size='tiny'
                                trigger={<Button onClick={() => refreshToken('view')}
                                    id='view' icon={REFRESH_ICON} />}
                                content={sharedUrlMessages().REFRESH_TOKEN_POPUP}
                                position='bottom center'
                            />
                            <Popup
                                size='tiny'
                                trigger={<Button className='shared-url-expired-button' onClick={linkExpiration}
                                    id='view' icon={TIME_ICON} />}
                                content={sharedUrlMessages().CHANGE_LINK_EXPIRATION_POPUP}
                                position='bottom center'
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid>
                    <Grid.Row className='shared-url-row' >
                        <Grid.Column width={13}>
                            <label>{sharedUrlMessages().MANAGE_SCREEN_LABEL}</label>
                            <Input fluid readOnly focus value={sharedUrlManage} />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Popup
                                size='tiny'
                                trigger={<Button className='shared-url-copy-button' onClick={handleCodeCopy}
                                    id='manage' icon={COPY_ICON} />}
                                content={COPY_TO_CLIPBOARD_MESSAGE()}
                                position='bottom center'
                            />
                            <Popup
                                size='tiny'
                                trigger={<Button onClick={() => refreshToken('manage')}
                                    id='manage' icon={REFRESH_ICON} />}
                                content={sharedUrlMessages().REFRESH_TOKEN_POPUP}
                                position='bottom center'
                            />
                            <Popup
                                size='tiny'
                                trigger={<Button className='shared-url-expired-button' onClick={linkExpiration}
                                    id='manage' icon={TIME_ICON} />}
                                content={sharedUrlMessages().CHANGE_LINK_EXPIRATION_POPUP}
                                position='bottom center'
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid>
                    <Grid.Row className='shared-url-row'>
                        <Grid.Column width={13}>
                            <label>{sharedUrlMessages().INSPECTOR_SCREEN_LABEL}</label>
                            <Input fluid readOnly focus value={sharedUrlInspector} />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Popup
                                size='tiny'
                                trigger={<Button className='shared-url-copy-button' onClick={handleCodeCopy}
                                    id='inspector' icon={COPY_ICON} />}
                                content={COPY_TO_CLIPBOARD_MESSAGE()}
                                position='bottom center'
                            />
                            <Popup
                                size='tiny'
                                trigger={<Button onClick={() => refreshToken('inspector')}
                                    id='inspector' icon={REFRESH_ICON} />}
                                content={sharedUrlMessages().REFRESH_TOKEN_POPUP}
                                position='bottom center'
                            />

                            <Popup
                                size='tiny'
                                trigger={<Button className='shared-url-expired-button' onClick={linkExpiration}
                                    id='inspector' icon={TIME_ICON} />}
                                content={sharedUrlMessages().CHANGE_LINK_EXPIRATION_POPUP}
                                position='bottom center'
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div>
                    {linkExpirationModalVisibility &&
                        <SharedUrlExpirationModal
                            openModalVisibility={linkExpirationModalVisibility}
                            handleModalClose={handleModalClose}
                            linkType={linkType}
                            localeProp={localeProp}
                            deviceId={deviceId}
                        />
                    }
                </div>
            </Modal.Content>

        </Modal>
    )
}

export default withTranslation()(SharedUrlModal);
