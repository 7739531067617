import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Header, Modal, Segment, Icon } from "semantic-ui-react";
import { RECONNECT_BUTTON } from '../utils/UIMessages';
import { CHECKMARK_ICON, REDO_ICON } from '../utils/UiIcons';

class ErrorModal extends Component {
    render() {
        return (
            <div>
                <Modal open={this.props.visibility} size='small' closeOnDimmerClick={this.props.closeOnDimmerClick} style={{ zIndex: 9999 }}
                    closeOnEscape={this.props.closeOnEscape}
                    onClose={this.props.onClose} dimmer='blurring'>
                    <Header icon={this.props.headerIcon} content={this.props.t(this.props.header)} color='red' />
                    <Modal.Content style={{ textAlign: 'center' }}>
                        <Segment>
                            <Header size='medium'>{this.props.t(this.props.content)}</Header>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={this.props.onClick} icon> <Icon name={CHECKMARK_ICON} /> {this.props.t(this.props.buttonContent)} </Button>
                        {!this.props.content.includes('offline reserved') &&
                        <Button color='blue' onClick={this.props.onClickReconnect} icon>
                            <Icon name={REDO_ICON} /> {RECONNECT_BUTTON()}
                        </Button>
                        }
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(withRouter(ErrorModal));
