import moment from 'moment';
import { Icon,List } from 'semantic-ui-react';
import React from 'react';
import { secondsToDhms } from '../../../../utils/Methods';
import {DEVICE_STATUS_MULTIPLIER} from '../../../../utils/Constants';
import {
    DATE_LABEL, DEVICES_HEADER,
    AUTOMATION_LABEL, MANUAL_LABEL, DEVELOPMENT_LABEL,
    TOTAL_LABEL,
    USER_ROLES_LABEL,
    BRAND_HEADER,
    DEVICE_MODEL_HEADER,
    DEVICE_HEADER
} from '../../../../utils/UIMessages';
import { CALENDAR_ALTERNATE_OUTLINE_ICON, MOBILE_ALTERNATE_ICON } from '../../../../utils/UiIcons';
import _ from 'lodash';

export const columnsSelector1 = (type, resType) => {
    if (type === 'devices') {
        return [
            {
                header: () => <div>{BRAND_HEADER()}</div>,
                accessorKey: 'brand',
                id: 'brand',
                cell: info => (<span className='number'>{_.capitalize(info.getValue())}</span>)
            },
            {
                header: () => <div>{DEVICE_MODEL_HEADER()}</div>,
                accessorKey: 'model',
                id: 'device model',
                cell: info => (<span className='number'>{info.getValue()}</span>)
            },
            {
                header: () => <div>{DEVICE_HEADER()}</div>,
                accessorKey: 'device',
                id: 'device',
                cell: info => (<span className='number'> <Icon name={MOBILE_ALTERNATE_ICON} />{info.getValue()}</span>)
            },
            {
                header: () => <div>{USER_ROLES_LABEL()}</div>,
                accessorKey: 'userRoles',
                id: 'userRoles',
                cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue()?.map((role, i) => {
                    return (<List.Item key={i} as='li' value='*'>
                        {role}
                    </List.Item>)
                })}</List>)
            },
            {
                header: () => <div>{AUTOMATION_LABEL()}</div>,
                accessorKey: AUTOMATION_LABEL(),
                id: 'Automation',
                cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
            },
            {
                header: () => <div>{MANUAL_LABEL()}</div>,
                accessorKey: MANUAL_LABEL(),
                id: 'Manual',
                cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
            },
            {
                header: () => <div>{DEVELOPMENT_LABEL()}</div>,
                accessorKey: DEVELOPMENT_LABEL(),
                id: 'Development',
                cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
            },
            {
                header: () => <div>{TOTAL_LABEL()}</div>,
                accessorFn: d => d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()] ,
                id: 'Total',
                cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
            }
        ];
    }
    if (type === 'daily' || type === 'monthly') {
        return [
            {
                header: () => <div>{DATE_LABEL()}</div>,
                accessorFn: d => type === 'monthly' ? moment(d.date).format('MM/YYYY') : moment(d.date).format('DD/MM/YYYY'),
                id: 'date',
                cell: info => (<span className='number'>
                    <Icon name={CALENDAR_ALTERNATE_OUTLINE_ICON} /> {info.getValue()}
                </span>)
            },
            {
                header: () => <div>{AUTOMATION_LABEL()}</div>,
                accessorKey: AUTOMATION_LABEL(),
                id: 'Automation',
                cell: info => (<span className='number'>{resType === 'second' ? secondsToDhms(info.getValue()) : info.getValue()}</span>)
            },
            {
                header: () => <div>{MANUAL_LABEL()}</div>,
                accessorKey: MANUAL_LABEL(),
                id: 'Manual',
                cell: info => (<span className='number'>{resType === 'second' ? secondsToDhms(info.getValue()) : info.getValue()}</span>)
            },
            {
                header: () => <div>{DEVELOPMENT_LABEL()}</div>,
                accessorKey: DEVELOPMENT_LABEL(),
                id: 'Development',
                cell: info => (<span className='number'>{resType === 'second' ? secondsToDhms(info.getValue()) : info.getValue()}</span>)
            },
            {
                header: () => <div>{TOTAL_LABEL()}</div>,
                accessorFn: d => d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()],
                id: 'Total',
                cell: info => (<span className='number'>{resType === 'second' ? secondsToDhms(info.getValue()) : info.getValue()}</span>)
            }
        ];
    }
    return [];
}

export const columnsSelector2 = (type) => {

    if (type === 'devices') {
        return [
            {
                header: () => <div>{DEVICES_HEADER()}</div>,
                accessorKey: 'device',
                id: 'device',
                cell: info => (<span className='number'> <Icon name={'mobile alternate'} /> {info.getValue()}</span>)
            },
            {
                header: () => <div>{USER_ROLES_LABEL()}</div>,
                accessorKey: 'userRoles',
                id: 'userRoles',
                cell: info => (<List as='ol' verticalAlign='middle'>{info?.getValue()?.map((role, i) => {
                    return (<List.Item key={i} as='li' value='*'>
                        {role}
                    </List.Item>)
                })}</List>)
            },
            {
                header: () => <div>{MANUAL_LABEL()}</div>,
                accessorFn: d => ((d[MANUAL_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
                id: 'manual',
                cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
            },
            {
                header: () => <div>{AUTOMATION_LABEL()}</div>,
                accessorFn: d => ((d[AUTOMATION_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()]+ d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
                id: 'automation',
                cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
            },
            {
                header: () => <div>{DEVELOPMENT_LABEL()}</div>,
                accessorFn: d => ((d[DEVELOPMENT_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
                id: 'development',
                cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
            }
        ]
    }
    if (type === 'daily' || type === 'monthly') {
        return [
            {
                header: () => <div>{DATE_LABEL()}</div>,
                accessorFn: d => type === 'monthly' ? moment(d.date).format('MM/YYYY') : moment(d.date).format('DD/MM/YYYY'),
                id: 'date',
                cell: info => (<span className='number'> <Icon name={'calendar alternate outline'} /> {info.getValue()}</span>)
            },
            {
                header: () => <div>{MANUAL_LABEL()}</div>,
                accessorFn: d => ((d[MANUAL_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
                id: 'manual',
                cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
            },
            {
                header: () => <div>{AUTOMATION_LABEL()}</div>,
                accessorFn: d => ((d[AUTOMATION_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
                id: 'automation',
                cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
            },
            {
                header: () => <div>{DEVELOPMENT_LABEL()}</div>,
                accessorFn: d => ((d[DEVELOPMENT_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
                id: 'development',
                cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
            }
        ]
    }
    return [];
}
