export const START_LOADING_FULLSCREEN = 'START_LOADING_FULLSCREEN';
export const END_LOADING_FULLSCREEN = 'END_LOADING_FULLSCREEN';
export const START_LOADING_DEVICE_LIST = 'START_LOADING_DEVICE_LIST';
export const END_LOADING_DEVICE_LIST = 'END_LOADING_DEVICE_LIST';
export const SET_DEVICE_LIST = 'SET_DEVICE_LIST';
export const SET_DEVICE_LIST_FILTER = 'SET_DEVICE_LIST_FILTER';
export const SET_STOMP_WEBSOCKET_CLIENT = 'SET_STOMP_WEBSOCKET_CLIENT';
export const START_LOADING_SYSTEM_PARAMETERS = 'START_LOADING_SYSTEM_PARAMETERS';
export const SET_CHOSENAPPMODE = 'SET_CHOSENAPPMODE';

