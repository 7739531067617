import React from 'react';
import {useTranslation} from 'react-i18next';
import {Tab} from "semantic-ui-react";
import ChartsDu from "./ChartsDU";
import ChartsDuStats from "./ChartsDUStats";
import ChartsHourlyUsage from "./ChartsHourlyUsage";
import {headerForReports} from "../../../../utils/Methods";
import { chartsDeviceUsageMessages } from '../../../../utils/UIMessages';

const DeviceUsages = () => {
    const { t } = useTranslation();

    const panes = [
        {
            menuItem: chartsDeviceUsageMessages().TOP_DEVICES_MENU_ITEM,
            render: () => <><ChartsDu type={"devices"}/></>,
        },
        {
            menuItem: chartsDeviceUsageMessages().USAGE_COUNT_DAY_MENU_ITEM,
            render: () => <><ChartsDu type={"daily"} resType={"count"}/></>,
        },
        {
            menuItem: chartsDeviceUsageMessages().USAGE_COUNT_MONTH_MENU_ITEM,
            render: () => <><ChartsDu type={"monthly"} resType={"count"}/></>,
        },
        {
            menuItem: chartsDeviceUsageMessages().USAGE_DURATIONS_DAY_MENU_ITEM,
            render: () => <><ChartsDu type={"daily"} resType={"second"}/></>,
        },
        {
            menuItem: chartsDeviceUsageMessages().USAGE_DURATIONS_MONTH_MENU_ITEM,
            render: () => <><ChartsDu type={"monthly"} resType={"second"}/></>,
        },
        {
            menuItem: chartsDeviceUsageMessages().HOURLY_USAGE_HEADER,
            render: () => <><ChartsHourlyUsage type={"daily"} /></>,
        },
        {
            menuItem: chartsDeviceUsageMessages().USAGE_MAP_MENU_ITEM,
            render: () => <><ChartsDuStats/></>,
        },
    ]


    return (
        <div className='main-container-report'>
        <div className="main-right-header">
            <h2>{t(headerForReports())}</h2>
        </div>

        <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
    </div>
    );
};

export default DeviceUsages;
