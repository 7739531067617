import React, {Component} from 'react';
import {Button, Grid, Header, Input, Table} from 'semantic-ui-react';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {showError, showSuccess} from '../../../utils/ToastHelpers';
import {
    addUsersToGroup,
    getAllUsersInGroup,
    deleteUserInGroup,
    getUserList,
    removeAllUsersInGroup
} from '../../../api/apiCalls'
import { FAILED_ERROR_MESSAGE, REMOVE_ALL_BUTTON ,manageGroupUsersMessages, USER_HEADER, DELETE_BUTTON, SELECT_ALL_BUTTON, ADD_BUTTON, SUCCESS_MESSAGE, SEARCH_USER_PLACEHOLDER} from '../../../utils/UIMessages';
import { CHEVRON_LEFT_ICON, CHEVRON_RIGHT_ICON } from '../../../utils/UiIcons';


class ManageGroupUsers extends Component {
    state = {
        userList: [],
        cloneUserList: [],
        selectedUsers: [],
        cloneSelectedUsers: [],
        searchKeywordAll: '',
        searchKeywordSelected: '',
        selectedUserIds:[]
    }

    componentDidMount() {
        this.getResources()
    }

    getResources = () => {
        getUserList().then(res1 => {
            this.setState({userList: res1.data, cloneUserList: res1.data})
            getAllUsersInGroup(this.props.selectedGroup?.id).then(res2 => {
                this.setState({selectedUsers: res2.data, cloneSelectedUsers: res2.data})
                this.editAllValues()
            }).catch(err => {
                showError(manageGroupUsersMessages().NOT_FETCH_GROUP_USERS_ERROR_MESSAGE + +err?.response?.data?.message ?? err.toString())
            })
        }).catch(err => {
            showError(manageGroupUsersMessages().NOT_FETCH_USERS_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString())
        })
    }

    // grup içindekilerin, bütün değerlerde görünmemesi için
    editAllValues = () => {
            let {userList, cloneUserList} = this.state
            const selectedUsernames = this.state.selectedUsers.map(user => user?.userName)
            const selectedUserIds = this.state.selectedUsers.map(user => user.id)
            userList = userList.filter(user => !selectedUsernames.includes(user?.userName)).filter(user=>!(user?.userName === 'admin' && user?.fullName === 'Administrator'))
            cloneUserList = cloneUserList.filter(user => !selectedUsernames.includes(user?.userName))
                .filter(user=>!(user?.userName === 'admin' && user?.fullName === 'Administrator'))
            this.setState({userList, cloneUserList,selectedUserIds})
    }

    //TODO: needs to be centralized. Other files have same function
    handleAfterUserOperation = () => {
        showSuccess(SUCCESS_MESSAGE());
        this.getResources()
        this.setState({searchKeywordSelected: '', searchKeywordAll: ''})
    }

    onClickAddUser = username => {
        const data = [username]
        const userFormData = new FormData();
        userFormData.append('usernames', data)
        addUsersToGroup(this.props.selectedGroup?.id,userFormData).then(() => {
            this.handleAfterUserOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    onClickAddAllUsers = () => {
        const usernames = this.state.userList.map(user => user.userName)
        const userFormData = new FormData()
        userFormData.append('usernames', usernames)
        addUsersToGroup(this.props.selectedGroup?.id,userFormData).then(() => {
            this.handleAfterUserOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    onClickDeleteUser = id => {
        deleteUserInGroup(this.props.selectedGroup?.id,id).then(() => {
            this.handleAfterUserOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    onClickDeleteAllUsers = async() =>{
        try{
            const {selectedUserIds} = this.state;
            await removeAllUsersInGroup(this.props.selectedGroup?.id,selectedUserIds?.toString());
            showSuccess(SUCCESS_MESSAGE());
            this.getResources()
            this.setState({searchKeywordSelected: '', searchKeywordAll: ''})
        }catch(error){
            showError(FAILED_ERROR_MESSAGE() + error?.response?.data?.message ?? error.toString);
        }
    }

    filterUserList = (userList, value) => {
        return userList.filter(user => {
            return user.userName?.toLowerCase().includes(value) ||
                user.fullName?.toLowerCase().includes(value) ||
                user.userMail?.toLowerCase().includes(value)
        });
    }

    onChangeSearch = (_e, {value, name}) => {
        value = value.toLowerCase();
        if (name === 'all') {
            const {cloneUserList} = this.state
            const userList = this.filterUserList(cloneUserList, value);
            this.setState({userList, searchKeywordAll: value})
        }else if (name === 'selected') {
            const {cloneSelectedUsers} = this.state
            const selectedUsers = this.filterUserList(cloneSelectedUsers, value);
            this.setState({selectedUsers, searchKeywordSelected: value})
        }else{
            //invalid name
        }
    }

    render() {
        return (
            <div>
                <Grid columns={2} celled={'internally'} centered>
                    <Grid.Column>
                        {/* <h3>{this.props.t('All Users')}</h3> */}
                        <div className='manage-group-container'>
                            <Input fluid onChange={this.onChangeSearch} placeholder={SEARCH_USER_PLACEHOLDER()}
                                   value={this.state.searchKeywordAll}
                                   style={{paddingRight: 7}} name={'all'}/>
                            {!_.isEmpty(this.state.userList) && <><Table basic='very' celled compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{USER_HEADER()}</Table.HeaderCell>
                                        <Table.HeaderCell>{ADD_BUTTON()}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {_.orderBy(this.state.userList, ['fullName'], ['asc']).map((user, index) => {
                                            return (
                                                <Table.Row key={index}
                                                           style={this.state.cloneSelectedUsers.some(value => value.id === user.id) ? {display: 'none'} : {display: ''}}>
                                                    <Table.Cell>
                                                        <Header as='h4'>
                                                            <Header.Content>
                                                                {(user.fullName)}
                                                                <Header.Subheader>{user.userMail}</Header.Subheader>
                                                            </Header.Content>
                                                        </Header>
                                                    </Table.Cell>
                                                    <Table.Cell width={'3'}>
                                                        <Button icon={CHEVRON_RIGHT_ICON} primary onClick={() => {
                                                            this.onClickAddUser(user.userName)
                                                        }}>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                    })}
                                </Table.Body>
                            </Table>
                                {this.state.userList?.length > 1 &&
                                <Button style={{marginTop: 10}} primary fluid onClick={this.onClickAddAllUsers}>{SELECT_ALL_BUTTON()}</Button>}
                            </>}
                        </div>
                        <div className='count-total-number'>
                            {manageGroupUsersMessages().TOTAL_NUMBER_OF_USERS_LABEL}:{' '}
                            {this.state.userList?.length}
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        {/* <h3>{this.props.t('Group Users')}</h3> */}
                        <div className='manage-group-container'>
                            <Input fluid onChange={this.onChangeSearch} placeholder={SEARCH_USER_PLACEHOLDER()}
                                   value={this.state.searchKeywordSelected}
                                   style={{paddingRight: 7}} name={'selected'}/>
                            {!_.isEmpty(this.state.selectedUsers) &&<> <Table basic='very' celled compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{DELETE_BUTTON()}</Table.HeaderCell>
                                        <Table.HeaderCell>{USER_HEADER()}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {_.orderBy(this.state.selectedUsers, ['fullName'], ['asc']).map((user, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell width={'3'}>
                                                    <Button color={'red'} icon={CHEVRON_LEFT_ICON}
                                                            onClick={() => this.onClickDeleteUser(user.id)}>
                                                    </Button>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Header as='h4'>
                                                        <Header.Content>
                                                            {_.capitalize(user.fullName)}
                                                            <Header.Subheader>{user.userMail}</Header.Subheader>
                                                        </Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                            {this.state.selectedUserIds?.length > 1 &&
                                <Button style={{marginTop: 10}} primary fluid onClick={this.onClickDeleteAllUsers}>{REMOVE_ALL_BUTTON()}</Button>}
                            </>}
                        </div>
                        <div className='count-total-number'>
                            {manageGroupUsersMessages().TOTAL_NUMBER_OF_USERS_LABEL}:{' '}
                            {this.state.selectedUsers?.length}
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(ManageGroupUsers);
