import React, { useState, useEffect } from 'react'
import { SEARCH_PLACEHOLDER } from '../../utils/UIMessages'
import { DEFAULT_DEBOUNCE_TIMER } from '../../utils/Constants';

const DebouncedInput = ({
  valueProp: initialValue,
  onChangeProp,
  typeProp = 'text',
  fluidProp = false,
  iconProp = false,
  placeHolderProp = SEARCH_PLACEHOLDER(),
  onKeyUpProp = () => { },
  onKeyDownProp = () => { },
  debounceProp = DEFAULT_DEBOUNCE_TIMER,
  ...props
}) => {

  const [value, setValue] = useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChangeProp(value);
    }, debounceProp);

    return () => clearTimeout(timeout);
  }, [value])

  return (
    <>
      {
        iconProp === true
          ?
          <div className='ui icon input' style={fluidProp ? { width: '100%' } : {}}>
            <input onKeyUp={e => onKeyUpProp(e)} onKeyDown={e => onKeyDownProp(e)} type={typeProp} placeholder={placeHolderProp} value={value}
              onChange={e => setValue(e.target.value)} {...props} />
            <i style={{ marginTop: '1px' }} aria-hidden='true' className='search circular icon'></i>
          </div>
          :
          <div className='ui icon input' style={fluidProp ? { width: '100%' } : {}}>
            <input onKeyUp={e => onKeyUpProp(e)} onKeyDown={e => onKeyDownProp(e)} type={typeProp} placeholder={placeHolderProp} value={value}
              onChange={e => setValue(e.target.value)} {...props} />
          </div>
      }
    </>
  )
}

export default DebouncedInput
