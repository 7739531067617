import React, { useState } from 'react';
import {Segment, Header, Button, Accordion, Icon, Divider, Table} from "semantic-ui-react";
import { PLAY_ICON, DROPDOWN_ICON, ARROW_LEFT_ICON } from '../utils/UiIcons';
import {getTestRunSteps} from '../api/apiCalls';
import ManageTestRunSession from '../pages/session/ManageTestRunSession';
import {integrationMessages} from '../utils/UIMessages';

const ManageTestRunPanel = props => {

    const manageTestRuns = props.manageTestRuns;
    const [modalOpen, setModalOpen] = useState(false);

    const [activeIndex, setActiveIndex] = useState(-1);
    const [subAccordionActiveIndex, setSubAccordionActiveIndex] = useState(-1);
    const [testRunSteps, setTestRunSteps] = useState([]);
    const [selectedTestRunId, setSelectedTestRunId] = useState(null);

    const handleManageStartSession = () => {
      props.prepareFreeDevices();
      setModalOpen(true)
    }

    const handleOpenModal = () => {
        props.onBackClick();
    };

    const handleModalClose = () => {
        setModalOpen(false);
    }
    
    const handleClick = async (e, { index }, testRun) => {
      if (activeIndex === index) {
        // if clicked accordion is already active, deactivate it
        setActiveIndex(-1);
      } else {
        // otherwise, activate the clicked accordion and deactivate the previously active one
        setActiveIndex(index);
        const steps = await getTestRunSteps(testRun);
        setTestRunSteps(steps.data);
      }
    };

    const handleAccordionClick = (testRunId) => {
      // Store the ID of the clicked test run in a state variable
      setSelectedTestRunId(testRunId);
    }
    
    return (
    <div>
        <div>
              <h3 className='manage-test-runs-header'>{integrationMessages().MANAGE_TEST_RUNS}</h3> 
              <Button color='gray' icon={ARROW_LEFT_ICON} onClick={handleOpenModal} id='manage-back-button'/>
              <Divider /> 

              {
                 manageTestRuns.map((testRun, index) => {
                  return(
                    <div key={index}>
                      <Segment id='manage-test-run-segment'>
                        <Accordion>
                        <Accordion.Title
                          active={activeIndex === manageTestRuns.indexOf(testRun)}
                          index={manageTestRuns.indexOf(testRun)}
                          onClick={(e, { index }) => {
                            handleClick(e, { index }, testRun)
                            handleAccordionClick(testRun.id)
                          }}
                          className='manage-test-run-accordion-title'
                          as='h3'
                        >
                          <Icon name={DROPDOWN_ICON} />
                          <Header className='manage-test-run-text-style'>{testRun.code}</Header>
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === manageTestRuns.indexOf(testRun)}>
                          <Header className='manage-test-run-text-style'> {integrationMessages().TESTER}:</Header> {testRun.tester.name}
                          <Header className='manage-test-run-text-style'> {integrationMessages().SUMMARY}:</Header> {testRun.summary}
                          <Accordion>
                            <Accordion.Title
                              active={subAccordionActiveIndex === 1}
                              index={1}
                              onClick={(e, { index }) => {
                                if (subAccordionActiveIndex === index) {
                                  setSubAccordionActiveIndex(-1);
                                } else {
                                  setSubAccordionActiveIndex(index);
                                }
                              }}
                              className='manage-test-run-accordion-title-v2'
                              as='h3'
                            >
                              <Icon name={DROPDOWN_ICON} />
                              <Header className='manage-test-run-text-style'>{integrationMessages().TEST_STEPS}</Header>
                            </Accordion.Title>

                            <Accordion.Content active={subAccordionActiveIndex === 1}>
                              <div>
                                {
                                  (testRunSteps.length === 0) ? null : <Table compact='very' id='test-run-panel-table'>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell>{integrationMessages().STEP}</Table.HeaderCell>
                                      <Table.HeaderCell>{integrationMessages().RESULT}</Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {testRunSteps.map((row, index) => (
                                      <Table.Row key={index}>
                                        <Table.Cell>{row.description}</Table.Cell>
                                        <Table.Cell>{row.expectedResult}</Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                                }
                              </div>
                            </Accordion.Content>
                          </Accordion>
                        </Accordion.Content>
                        </Accordion>

                        <Button color='green' icon={PLAY_ICON} size='tiny' onClick={() => {
                          handleAccordionClick(testRun.id);
                          handleManageStartSession()
                          }} id='test-run-play-icon'/>
                      </Segment>
                    </div>
                  )
                })
              }


            {/* Rendering a session starter modal */}
            {
            <ManageTestRunSession
                projectId={props.projectId}
                testRunId={selectedTestRunId}
                manageTestRuns={manageTestRuns}
                onHandleChange={props.onHandleChange}
                handleModalClose={handleModalClose}
                handleDropdown={props.handleDropdown}
                onClickStartSession={props.onClickStartSession}
                allDevices={props.allDevices}
                allAppsDropdown={props.allAppsDropdown}
                testSessionField={props.testSessionField}
                modalVisibility={modalOpen}
                loading={props.loading}
                sessionType={props.sessionType}
            />
      }
      </div>

    </div>
  )
}

export default ManageTestRunPanel