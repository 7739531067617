import React, {Component} from 'react';
import {Grid, Icon, Header, Segment, List} from 'semantic-ui-react';
import DateRangeDropdown from '../../../../components/DateRangeDropdown';
import axios from 'axios';
import {dataRefresher, secondsToDhms} from '../../../../utils/Methods';
import {generateColumns, generateUserResData} from './TableColumnsUSERRES';
import {ResponsiveBar} from '@nivo/bar';
import {withTranslation} from 'react-i18next';
import {showError} from '../../../../utils/ToastHelpers';
import ReactTableV8 from '../../../../components/ReactTableV8/ReactTableV8';
import { chartsUserReservationsMessages, FAILED_ERROR_MESSAGE, HOURS_LABEL, HOUR_LABEL, MINUTES_LABEL, MINUTE_LABEL, TOTAL_LABEL, USAGE_LABEL, USED_HEADER, USER_HEADER, USER_ROLES_LABEL } from '../../../../utils/UIMessages';

const ONE_HOUR_IN_SECONDS = 3600;
const ONE_MINUTE_IN_SECONDS = 60;

const BORDER_COLOR_DEGREE = 1.6;

class ChartsUserRes extends Component {

    //New React Table V8 Columns
    userTotalTableColumnsTranslated = () => [
        {
            header: () => <div>{USER_HEADER()}</div>,
            accessorKey: 'user',
            id: 'user',
            cell: info => (<span className='number'> <Icon name={'user'} /> {info.getValue()}</span>)
        },
        {
            header: () => <div>{USER_ROLES_LABEL()}</div>,
            accessorKey: 'userRoles',
            id: 'userRoles',
            cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue()?.map((role, i) => {
                return (<List.Item key={i} as='li' value='*'>
                    {role}
                </List.Item>)
            })}</List>)
        },
        {
            header: () => <div>{USED_HEADER()}</div>,
            accessorKey: 'used',
            id: 'used',
            cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
        },
        {
            header: () => <div>{TOTAL_LABEL()}</div>,
            accessorKey: 'total',
            id: 'total',
            cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
        }
    ]

    state = {
        componentDidUpdateCheck:false,
        dataForUserAndDateTable: [],
        dataForUserTotalTable: [],
        dataForBarChart: [],
        startDate: new Date(),
        endDate: new Date(),
        sizeValue: 10,
        visibility: false,
        users: [],
        loading: false,
        language: localStorage.getItem('lang') || 'en'
    }

    convertDataForUserDateTable = data => {
        const dataForUserAndDateTable = []
        this.setState({users: data.users}, () => {
            for (let i = 0; i < data.dates.length; i++) {
                dataForUserAndDateTable.push({
                    dates: data.dates[i],
                    ...(generateUserResData(data, i))
                })
            }
            this.setState({dataForUserAndDateTable})
        })
    }

    convertDataForUserTotalTable = data => {
        const dataForUserTotalTable = []
        for (const x in data?.users) {
            if(Object.prototype.hasOwnProperty.call(data?.users, x)){
                dataForUserTotalTable.push({
                    user: data?.users[x]?.fullName,
                    userRoles: data?.users[x]?.userRoles,
                    total: data?.intervalTotal[x],
                    used: data?.intervalUsed[x]
                })
            }
        }
        this.setState({dataForUserTotalTable})
    }

    convertDataBarChart = data => {
        const dataForBarChart = []
        for (const x in data?.users) {
            if (Object.prototype.hasOwnProperty.call(data?.users, x)) {
                dataForBarChart.push({
                    User: data?.users[x]?.fullName,
                    [TOTAL_LABEL()]: data?.intervalTotal[x],
                    [USED_HEADER()]: data?.intervalUsed[x]
                })
            }
        }
        this.setState({ dataForBarChart })
    }

    convertData = data => {
        this.convertDataForUserDateTable(data)
        this.convertDataForUserTotalTable(data)
        this.convertDataBarChart(data)
    }


    dailyOrMonthlyCheck = async (type, params = '') => {
        this.setState({ loading: true });
        try {
            if (type === 'daily') {
                const userResDaily = (await axios.get('/api/reports/user-reservation-daily' + params)).data
                this.convertData(userResDaily)
                this.setState({users: userResDaily.users}, () => {
                    this.setState({sizeValue: this.state.users.length})
                })
                const startDate = new Date(userResDaily?.dates[0])
                const endDate = new Date(userResDaily.dates[userResDaily?.dates?.length - 1])
                this.setState({startDate, endDate})

            }
            if (type === 'monthly') {
                const userResMonthy = (await axios.get('/api/reports/user-reservation-monthly' + params)).data
                this.convertData(userResMonthy)
                this.setState({users: userResMonthy.users}, () => {
                    this.setState({sizeValue: this.state.users.length})
                })
                const startDate = new Date(userResMonthy?.dates[0])
                const endDate = new Date(userResMonthy.dates[userResMonthy?.dates?.length - 1])
                this.setState({startDate, endDate})
            }
        } catch (err) {
            showError(FAILED_ERROR_MESSAGE() +err?.response?.data?.message ?? err.toString())
        } finally {
          this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.dailyOrMonthlyCheck(this.props.type)
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.setState({
                visibility: false,
                componentDidUpdateCheck : !this.state.componentDidUpdateCheck
            })
            this.dailyOrMonthlyCheck(this.props.type)
        }
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.dailyOrMonthlyCheck(this.props.type))
        )
    }

    onChangeDateComponent = link => {
        const params = '?' + link
        this.setState({visibility: false})
        this.dailyOrMonthlyCheck(this.props.type, params)
    }

    render() {
        const userDateTableColumns = generateColumns(this.state.users, this.props.type);

        return (
            <div>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <DateRangeDropdown
                                isOrderByVisibility={false}
                                isSizeVisibility={true}
                                type={this.props.type}
                                sizeValue={this.state.sizeValue}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                callback={link => this.onChangeDateComponent(link)}
                                isUserRolesVisibility={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered columns={1} style={{marginTop: 20}}>
                        <Header textAlign={'center'}>
                            {chartsUserReservationsMessages(this.props.type === 'daily' ? 'Day' : 'Month').RESERVATION_USAGE_BY_HEADER}
                        </Header>
                        <Grid.Row centered columns={1}>
                            <Grid.Column>
                                <Segment placeholder style={{height: 450}} loading={this.state.loading}>
                                    <ResponsiveBar
                                        data={this.state.dataForBarChart}
                                        keys={[TOTAL_LABEL(), USED_HEADER()]}
                                        indexBy='User'
                                        enableGridY={true}
                                        margin={{top: 50, right: 30, bottom: 90, left: 130}}
                                        groupMode='grouped'
                                        label={() => null}
                                        valueScale={{type: 'linear'}}
                                        indexScale={{type: 'band', round: true}}
                                        colors={{scheme: 'pastel2'}}
                                        borderColor={{from: 'color', modifiers: [['darker', BORDER_COLOR_DEGREE]]}}
                                        axisTop={null}
                                        axisRight={null}
                                        tooltip={el => {
                                            return (
                                                <>
                                                    {el.id} - {el.indexValue}: {secondsToDhms(el.value)}
                                                </>
                                            )
                                        }}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: -40,
                                            legend: USER_HEADER(),
                                            legendPosition: 'middle',
                                            legendOffset: 80
                                        }}
                                        axisLeft={
                                            {
                                                format: tick => {
                                                    tick = Number(tick);
                                                    const h = Math.floor(tick / ONE_HOUR_IN_SECONDS);
                                                    const m = Math.floor(tick % ONE_HOUR_IN_SECONDS / ONE_MINUTE_IN_SECONDS);
                                                    var hDisplay = h > 0 ? h + (h === 1 ? ` ${HOUR_LABEL()} `  : ` ${HOURS_LABEL()} `) : '';
                                                    var mDisplay = m > 0 ? m + (m === 1 ? ` ${MINUTE_LABEL()}, ` : ` ${MINUTES_LABEL()} `) : '';
                                                    return `${hDisplay} ${mDisplay}`;
                                                },
                                                tickSize: 5,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                legend: USAGE_LABEL(),
                                                legendPosition: 'middle',
                                                legendOffset: -125
                                            }
                                            // null
                                        }
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        // labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                                        legends={[
                                            {
                                                dataFrom: 'keys',
                                                anchor: 'bottom-right',
                                                direction: 'row',
                                                justify: false,
                                                translateX: 40,
                                                translateY: 80,
                                                itemsSpacing: 8,
                                                itemDirection: 'left-to-right',
                                                itemWidth: 100,
                                                itemHeight: 0,
                                                itemOpacity: 0.75,
                                                symbolSize: 12,
                                                symbolShape: 'circle',
                                                // symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            // itemBackground: 'rgba(0, 0, 0, .03)',
                                                            itemOpacity: 1
                                                        }
                                                    }
                                                ]

                                            }
                                        ]}
                                        animate={false}
                                        motionStiffness={90}
                                        motionDamping={15}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered columns={1} style={{marginTop: 20}}>
                            <Grid.Column>
                                <Segment placeholder style={{minHeight: '22rem'}}>
                                    <ReactTableV8
                                        columns={userDateTableColumns}
                                        data={this.state.dataForUserAndDateTable}
                                        onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                        loadingProp={this.state.loading}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered columns={1} style={{marginTop: 20}}>
                            <Grid.Column>
                                <Segment placeholder style={{minHeight: '22rem', maxWidth: '65rem'}}>
                                    <ReactTableV8
                                        columns={this.userTotalTableColumnsTranslated()}
                                        data={this.state.dataForUserTotalTable}
                                        onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                        loadingProp={this.state.loading}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(ChartsUserRes);
