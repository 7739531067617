import React, {Component} from 'react';
import {Button, Header, Modal, Segment, Icon} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { RETURN_TO_DEVICE_LIST_LABEL, SECONDS_LABEL, warningMessages } from '../utils/UIMessages';
import { EXCLAMATION_ICON, UNDO_ICON } from '../utils/UiIcons';
class TimedOutModal extends Component {
    render() {
        return (
            <div>
                <Modal open={this.props.warningModalVisibility} size='small' closeIcon style={{zIndex:9999}}
                       onClose={this.props.onCloseWarningModal} dimmer={'blurring'}>
                    <Header icon={EXCLAMATION_ICON} content={warningMessages().MODAL_HEADER} color='yellow'/>
                    <Modal.Content style={{textAlign: 'center'}}>
                        <Segment>
                            <Header size='medium'>{warningMessages(this.props.fullTime).INACTIVE_ERROR_MESSAGE}<br/>
                                {warningMessages().TERMINATE_MODAL_CONNECTION} {this.props.closingTime} {SECONDS_LABEL()}.</Header>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={this.props.onCloseWarningModal} icon> <Icon name={UNDO_ICON}/> {RETURN_TO_DEVICE_LIST_LABEL()}</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(withRouter(TimedOutModal));
