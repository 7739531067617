import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Icon, Segment, Table} from 'semantic-ui-react';
import axios from 'axios';
import {PrivilegeConstants} from '../../utils/PrivilegeConstants'
import {chunkObject, getPrivileges, renderedNodeInfo, stringSelector2} from '../../utils/Methods';
import moment from 'moment';
import _ from 'lodash'
import {showError, showInfo} from '../../utils/ToastHelpers';
import ManageAutomationSettings from './ManageAutomationSettings';
import {
    deleteFavoriteDevice,
    createFavoriteDevice
} from '../../api/apiCalls'
import DeviceClipboard from '../device/DeviceClipboard';
import { ADD_DEVICE_TO_FAVORITE_MESSAGE, APPLICATION_HEADER, DEVICE_ID_HEADER, DEVICE_MODEL_HEADER, manageDeviceInfoMessages, NO_DATA_LABEL, NO_LABEL, ONLINE_LABEL, OPERATING_SYSTEM_HEADER, OS_VERSION_HEADER, REMOVE_DEVICE_FROM_FAVORITE_MESSAGE, SCREEN_RESOLUTION_HEADER, SCREEN_SIZE_HEADER, SESSION_ID_HEADER, SESSION_NAME_HEADER, VERSION_CODE_HEADER, VERSION_HEADER, YES_LABEL } from '../../utils/UIMessages';
import { CLIPBOARD_ICON, STAR_ICON, STAR_OUTLINE_ICON } from '../../utils/UiIcons';

const ManageDeviceInfo = props => {
    const [deviceSpecs, setDeviceSpecs] = useState({});
    const [privileges, setPrivileges] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deviceId, setDeviceId] = useState('');
    const [modalState, setModalState] = useState(false);

    useEffect(() => {
        handleGetDeviceDetails();
        handleGetPrivileges();
    }, [])

    const handleGetDeviceDetails = () => {
        if (props.afterInspector) {
            setLoading(true);
            // wait for 5 seconds
            setTimeout(() => {
                getDeviceDetails()
            }, 5000)
            // set after Inspector to false
            props.setAfterInspector(false)
        } else {
            getDeviceDetails()
        }
    }

    const handleGetPrivileges = () => {
        if (localStorage.getItem('privileges')) {
            const privileges = getPrivileges();
            setPrivileges(privileges)
        }
    }

    const getDeviceDetails = () => {
        axios.get(`/api/devices/${props.id}`).then(response => {
            setDeviceSpecs(response.data);
            setLoading(false);
            setDeviceId(response.data?.id);
        }).catch(err => showError(err.message))
    }

    const onFavoriteDevice = async isFav => {
        try {
            if (isFav) {
                //await axios.delete(`/api/favorite-devices/${deviceId}`)
                await deleteFavoriteDevice(deviceId);
                showInfo(REMOVE_DEVICE_FROM_FAVORITE_MESSAGE())
            } else {
                //await axios.post(`/api/favorite-devices/${deviceId}`)
                await createFavoriteDevice(deviceId);
                showInfo(ADD_DEVICE_TO_FAVORITE_MESSAGE())
            }
            getDeviceDetails()
        } catch (e) {
            showError(e.message)
        }
    }

    const renderFavArea = isFav => {
        return <>
            {renderObjectValues(isFav)}
            <Button compact icon style={{marginLeft: 13}}
                    onClick={() => onFavoriteDevice(isFav)}
            >{isFav ? <><Icon name={STAR_ICON}/></> : <><Icon name={STAR_OUTLINE_ICON}/></>}
            </Button>
        </>
    }

    const renderCategories = categories => {
        return <div className="device-card-category-container">
            {
                categories.map((c) => (
                    <span className="device-card-category-item" key={c.id}>
            {c.name}
          </span>
                ))
            }
        </div>
    }

    const showSpec = device => {
        if (!_.isEmpty(device) && device !== undefined) {
            return Object.keys(device).map((spec, index) => {
                if(spec === 'node') {
                    return <Table.Row key='node'>
                    <Table.Cell>
                        <strong>
                            {stringSelector2(spec)}
                        </strong>
                    </Table.Cell>
                    <Table.Cell>{renderedNodeInfo(device[spec])}</Table.Cell>
                </Table.Row>
                } else if (spec === 'status') {
                    return Object.keys(device.status).map(stat => {
                        return <Table.Row key={stat}>
                            <Table.Cell>
                                <strong>
                                    {stringSelector2(stat)}
                                </strong>
                            </Table.Cell><Table.Cell>{renderObjectValues(device.status[stat])}</Table.Cell>
                        </Table.Row>
                    });
                }
                if (spec === 'users') {
                    return <Table.Row key={index}>
                        <Table.Cell>
                            <strong>
                                {stringSelector2(spec)}
                            </strong>
                        </Table.Cell>
                        <Table.Cell>{device[spec]?.map(user => user.fullName)?.flat()?.join(', ')}</Table.Cell>
                    </Table.Row>
                } else if(spec !== 'groups' && spec !== 'deviceReservation' && spec !=='reservableGroups' && spec !=='offlineReservedInfo'){
                    return <Table.Row key={index}>
                        <Table.Cell>
                            <strong>
                                {stringSelector2(spec)}
                            </strong>
                        </Table.Cell>
                        <Table.Cell style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                            {spec === 'favorite' ?
                                renderFavArea(device[spec], device.id) :
                                spec === 'categories' ?
                                    renderCategories(device[spec]) :
                                    renderObjectValues(device[spec])}
                        </Table.Cell>
                    </Table.Row>
                }

            })
        }
    };

    const showTestSessionFields = () => {
        return (<>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {SESSION_ID_HEADER()}
                    </strong>
                </Table.Cell><Table.Cell>{props.testSessionField.id}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {SESSION_NAME_HEADER()}
                    </strong>
                </Table.Cell><Table.Cell>{props.testSessionField.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {manageDeviceInfoMessages().SESSION_TYPE_HEADER}
                    </strong>
                </Table.Cell><Table.Cell>{props.testSessionField.type}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {manageDeviceInfoMessages().START_TIME_HEADER}
                    </strong>
                </Table.Cell><Table.Cell>{moment(props.testSessionField.startDate).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {APPLICATION_HEADER()}
                    </strong>
                </Table.Cell><Table.Cell>{props.testSessionField.appName}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {VERSION_HEADER()}
                    </strong>
                </Table.Cell><Table.Cell>{props.testSessionField.appVersion}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {VERSION_CODE_HEADER()}
                    </strong>
                </Table.Cell><Table.Cell>{props.testSessionField.appVersionCode}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {DEVICE_ID_HEADER()}
                    </strong>
                </Table.Cell>
                <Table.Cell>{renderObjectValues(deviceSpecs.deviceId)}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {DEVICE_MODEL_HEADER()}
                    </strong>
                </Table.Cell>
                <Table.Cell>{renderObjectValues(deviceSpecs.deviceModel)}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {OPERATING_SYSTEM_HEADER()}
                    </strong>
                </Table.Cell>
                <Table.Cell>{renderObjectValues(deviceSpecs.os)}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {OS_VERSION_HEADER()}
                    </strong>
                </Table.Cell>
                <Table.Cell>{renderObjectValues(deviceSpecs.osversion)}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {SCREEN_SIZE_HEADER()}
                    </strong>
                </Table.Cell>
                <Table.Cell>{renderObjectValues(deviceSpecs.screenSize)}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    <strong>
                        {SCREEN_RESOLUTION_HEADER()}
                    </strong>
                </Table.Cell>
                <Table.Cell>{renderObjectValues(deviceSpecs.screenResolution)}</Table.Cell>
            </Table.Row>
        </>)
    }

    const renderObjectValues = value => {
        if (value === null) {
            return <>{NO_DATA_LABEL()}</>
        } else if (value === 0) {
            return <>{ONLINE_LABEL()}</>
        } else if (value === false) {
            return <>{NO_LABEL()}</>
        } else if (value === true) {
            return <>{YES_LABEL()}</>
        } else {
            return <>{value}</>
        }
    }

    const deviceSpecsArr = useMemo(() => chunkObject(deviceSpecs), [deviceSpecs]);

    return (
        <Segment className="device-info-tab-content" basic loading={loading}
                 style={{minHeight: 'calc(25vh)'}}>
            <Grid>
                {props.testSessionField !== undefined ?
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={16}>
                            <Table basic='very' compact>
                                <Table.Body>
                                    {showTestSessionFields()}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row> :
                    <>
                        <Grid.Row columns={2} centered style={{marginBottom: 10}}>
                            <Grid.Column width={8}>
                                <Table basic='very' compact>
                                    <Table.Body>
                                        {deviceSpecsArr[0] ? showSpec(deviceSpecsArr[0]) : <>{NO_DATA_LABEL()}</>}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Table basic='very' compact>
                                    <Table.Body>
                                        <div className='clipboard-div-phone'>
                                            <Button compact icon={CLIPBOARD_ICON}
                                                    style={{'marginBottom': '0.3rem'}}
                                                    onClick={() => setModalState(!modalState)}
                                            />
                                        </div>
                                        {deviceSpecsArr[1] ? showSpec(deviceSpecsArr[1]) : <>{NO_DATA_LABEL()}</>}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                        {
                            ([
                                PrivilegeConstants.ACCESS_AUTOMATION,
                                PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS
                            ].some(privilege => privileges.includes(privilege)))
                            &&
                            <Grid.Row className='device-manage-automation-row-padding-top'>
                                <ManageAutomationSettings deviceId={deviceSpecs.deviceId} deviceOs={deviceSpecs.os}/>
                            </Grid.Row>}
                    </>
                }
            </Grid>
            <DeviceClipboard
                open={modalState}
                closeModal={() => setModalState(false)}
                openModal={() => setModalState(true)}
                device={deviceSpecs}
            />
        </Segment>
    );
}

export default ManageDeviceInfo;
