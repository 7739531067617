import {
    START_LOADING_FULLSCREEN,
    END_LOADING_FULLSCREEN,
    START_LOADING_DEVICE_LIST,
    END_LOADING_DEVICE_LIST,
    SET_DEVICE_LIST,
    SET_DEVICE_LIST_FILTER,
    SET_STOMP_WEBSOCKET_CLIENT,
    START_LOADING_SYSTEM_PARAMETERS,
    SET_CHOSENAPPMODE
} from './types';

// action creators

//fullpage loading
export const startLoadingFullCreator = loadingContent => {
    return {
        type: START_LOADING_FULLSCREEN,
        loadingContent
    }
}

export const endLoadingFullCreator = () => {
    return {
        type: END_LOADING_FULLSCREEN
    }
}

//deviceList loading
export const startLoadingDeviceCreator = loadingContent => {
    return {
        type: START_LOADING_DEVICE_LIST,
        loadingContent
    }
}

export const endLoadingDeviceCreator = () => {
    return {
        type: END_LOADING_DEVICE_LIST
    }
}

export const setDeviceListCreator = data => {
    return {
        type: SET_DEVICE_LIST,
        data
    }
}


export const setDeviceListFilterCreator = data => {
    return {
        type: SET_DEVICE_LIST_FILTER,
        data
    }
}

export const stompClientActionCreator = data =>{
    return{
        type: SET_STOMP_WEBSOCKET_CLIENT,
        payload:data
    }
}

export const systemParametersActionCreator = data => {
    return{
        type: START_LOADING_SYSTEM_PARAMETERS,
        payload: data
    }
}

const updateDeviceStatus = (deviceList,statusUpdate) => {
    const objectIndex = deviceList.findIndex(obj => obj.deviceId === statusUpdate.deviceId)

    if(objectIndex !== -1) {
        deviceList[objectIndex].status.Manual = statusUpdate.manual;
        deviceList[objectIndex].status.Automation = statusUpdate.automation;
        deviceList[objectIndex].status.Development = statusUpdate.development;
        deviceList[objectIndex].status.Reserved = statusUpdate.reserved;
        deviceList[objectIndex].status.ReservedBy = statusUpdate.reservedBy;
        deviceList[objectIndex].deviceStatus = statusUpdate.deviceStatus;
        deviceList[objectIndex].offlineReservedInfo = statusUpdate.offlineReservationInfo;
        deviceList[objectIndex].users = statusUpdate.users;
        deviceList[objectIndex].visible = statusUpdate.visible;
    }

    return deviceList;
}



// notification actions

//fullpage loading
export const startFullLoad = loadingContent => dispatch => {
    dispatch(startLoadingFullCreator(loadingContent))
};

export const endFullLoad = () => dispatch => {
    dispatch(endLoadingFullCreator())
};


//deviceList loading
export const startDeviceLoad = loadingContent => dispatch => {
    dispatch(startLoadingDeviceCreator(loadingContent))
};

export const endDeviceLoad = () => dispatch => {
    dispatch(endLoadingDeviceCreator())
};
export const setDeviceList = data => dispatch => {
    dispatch(setDeviceListCreator(data))
}

export const setDeviceListFilter = data => dispatch => {
    dispatch(setDeviceListFilterCreator(data))
}

export const updateDeviceList = data => (dispatch,getState) => {
    const updatedDevices = [...getState().deviceList.deviceList];
    if(updatedDevices.length > 0){
        dispatch(setDeviceListCreator(updateDeviceStatus(updatedDevices,data)))
    }
}
export const updateDeviceListFilter = data => (dispatch,getState) => {
    const updatedDevicesFilter = [...getState().deviceList.deviceListFilter];
    if(updatedDevicesFilter > 0){
        dispatch(setDeviceListFilterCreator(updateDeviceStatus(updatedDevicesFilter,data)))
    }
}

export const setStompClient = client => dispatch => {
    dispatch(stompClientActionCreator(client))
}

export const setSystemParameters = systemParameters => dispatch =>{
    dispatch(systemParametersActionCreator(systemParameters))
}

export const setChosenAppMode = data => {
    return{
        type: SET_CHOSENAPPMODE,
        data
    }
}
