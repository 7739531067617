import React, {Component} from 'react';
import {Button, Header, Modal, Segment, Icon} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { OK_BUTTON, RECONNECT_BUTTON, timedOutMessages } from '../utils/UIMessages';
import { REDO_ICON, CHECKMARK_ICON, EXCLAMATION_ICON } from '../utils/UiIcons';
class TimedOutModal extends Component {
    render() {
        return (
            <div>
                <Modal open={this.props.idleModalVisibility} size='small' closeOnDimmerClick={false} style={{zIndex:9999}}
                       closeOnEscape={false}
                       onClose={this.props.onCloseIdleModal} dimmer={'blurring'}>
                    <Header icon={EXCLAMATION_ICON} content={timedOutMessages().MODAL_HEADER} color={'red'}/>
                    <Modal.Content style={{textAlign: 'center'}}>
                        <Segment>
                            <Header size='medium'>{timedOutMessages(this.props.fullTime).CONNECTION_CLOSED_MESSAGE}</Header>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={() => {
                            this.props.history.push(this.props.redirectPage)
                        }} icon> <Icon name={CHECKMARK_ICON}/> {OK_BUTTON()} </Button>
                        <Button color='blue' onClick={this.props.onClickReconnect} icon>
                            <Icon name={REDO_ICON}/> {RECONNECT_BUTTON()}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(withRouter(TimedOutModal));
