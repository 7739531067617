class Framework {

    constructor (host, port, path, https, caps) {
        this.host = host || 'localhost';
        this.port = port || 4723;
        this.path = path || '/wd/hub';
        this.caps = caps || {};
        this.https = !!https;
        this.scheme = https ? 'https' : 'http';
        this.actions = [];
        this.includeBoilerplate = false;
        this.localVarCount = 0;
        this.localVarCache = {};
        this.lastAssignedVar = null;
        this.funcMap = {
          'codeFor_findElement': this.codeFor_findElement,
          'codeFor_tap': this.codeFor_tap
        };
      }

      get serverUrl () {
        return `${this.scheme}://${this.host}:${this.port}${this.path}`;
      }
    
      get name () {
        throw new Error('Must implement name getter');
      }
    
      get language () {
        throw new Error('Must implement language getter');
      }
    
      addAction (action, params) {
        this.actions.push({action, params});
      }
    
      wrapWithBoilerplate () {
        throw new Error('Must implement wrapWithBoilerplate');
      }

      indent (str, spaces) {
        let lines = str.split('\n');
        let spaceStr = '';
        for (let i = 0; i < spaces; i++) {
          spaceStr += ' ';
        }
        return lines
          .filter((l) => !!l.trim())
          .map((l) => `${spaceStr}${l}`)
          .join('\n');
      }

      
      getCodeString () {  
        let str = '';
        for (let {action, params} of this.actions) {
            let code = ''
            let varNameIgnore, varIndexIgnore, url
            switch(action){
              case 'findElement': {
                let {strategy, locator} = params
                code = this.codeFor_findElement(strategy, locator)
                break;
              }
              case 'click':
                code = this.codeFor_click(this.lastAssignedVar)
                break;
              case 'tap': {
                let {x, y} = params
                code = this.codeFor_tap(null, null, x, y)
                break;
              }
              case 'swipe': {
                let {x1, y1, x2, y2} = params
                code = this.codeFor_swipe(null, null, x1, y1, x2, y2)
                break;
              }
              case 'clear':
                code = this.codeFor_clear(this.lastAssignedVar)
                break;
              case 'sendKeys':
                code = this.codeFor_sendKeys(this.lastAssignedVar, null, params?.text)
                break;
              case 'back':
                code = this.codeFor_back()
                break;
              case 'sessionCapabilities':
                code = this.codeFor_sessionCapabilities()
                break;
              case 'setPageLoadTimeout':
                code = this.codeFor_setPageLoadTimeout(null, null, params?.ms)
                break;
              case 'setAsyncScriptTimeout':
                code = this.codeFor_setAsyncScriptTimeout(null, null, params?.ms)
                break;
              case 'setImplicitWaitTimeout':
                code = this.codeFor_setImplicitWaitTimeout(null, null, params?.ms)
                break;
              case 'getOrientation':
                code = this.codeFor_getOrientation()
                break;
              case 'setOrientation':
                code = this.codeFor_setOrientation(null, null, params?.orientation)
                break;
              case 'getGeoLocation':
                code = this.codeFor_getGeoLocation()
                break;
              case 'setGeoLocation':
                code = this.codeFor_setGeoLocation(null, null, params?.latitude, params?.longitude, params?.altitude)
                break;
              case 'logTypes':
                code = this.codeFor_logTypes()
                break;
              case 'log':
                code = this.codeFor_log(null, null, params?.logType)
                break;
              case 'settings':
                code = this.codeFor_settings()
                break;
              case 'updateSettings':
                code = this.codeFor_updateSettings(null, null, params?.json)
                break;
              case 'openNotifications':
                code = this.codeFor_openNotifications()
                break;
              case 'getSystemTime':
                code = this.codeFor_getDeviceTime()
                break;
              case 'getSystemBars':
                code = this.codeFor_getSystemBars()
                break;
              case 'getClipboard':
                code = this.codeFor_getClipboard()
                break;
              case 'setClipboard':
                code = this.codeFor_setClipboard(null, null, params?.content)
                break;
              case 'toggleAirplaneMode':
                code = this.codeFor_toggleAirplaneMode()
                break;
              case 'toggleData':
                code = this.codeFor_toggleData()
                break;
              case 'toggleWifi':
                code = this.codeFor_toggleWiFi()
                break;
              case 'toggleLocationServices':
                code = this.codeFor_toggleLocationServices()
                break;
              case 'shake':
                code = this.codeFor_shake()
                break;
              case 'lock':
                code = this.codeFor_lock(null, null, params?.seconds)
                break;
              case 'unlock':
                code = this.codeFor_unlock()
                break;
              case 'isLocked':
                code = this.codeFor_isLocked()
                break;
              case 'rotate':
                code = this.codeFor_rotateDevice(null, null, params?.x, params?.y, params?.radius, params?.rotation, params?.touchCount, params?.duration)
                break;
              case 'pressKeyCode':
                code = this.codeFor_pressKeycode(null, null, params?.keycode, params?.metastate, params?.flags)
                break;
              case 'longPressKeyCode':
                code = this.codeFor_longPressKeycode(null, null, params?.keycode, params?.metastate, params?.flags)
                break;
              case 'hideKeyboard':
                code = this.codeFor_hideDeviceKeyboard()
                break;
              case 'isKeyboardShown':
                code = this.codeFor_isKeyboardShown()
                break;
              case 'startActivity':
                code = this.codeFor_startActivity(params?.appPackage, params?.appActivity)
                break;
              case 'currentActivity':
                code = this.codeFor_getCurrentActivity()
                break;
              case 'currentPackage':
                code = this.codeFor_getCurrentPackage()
                break;
              case 'isAppInstalled':
                code = this.codeFor_isAppInstalledOnDevice(null, null, params?.bundleId)
                break;
              case 'launchApp':
                code = this.codeFor_launchApp()
                break;
              case 'backgroundApp':
                code = this.codeFor_backgroundApp(null, null, params?.seconds)
                break;
              case 'closeApp':
                code = this.codeFor_closeApp()
                break;
              case 'resetApp':
                code = this.codeFor_resetApp()
                break;
              case 'removeApp':
                if(params?.appId !== ''){
                  code = this.codeFor_removeAppFromDevice(null, null, params?.appId)
                }
                else{
                  code = this.codeFor_removeAppFromDevice(null, null, params?.bundleId)
                }
                break;
              case 'activateApp': 
                if(params?.type === 'appId'){
                  code = this.codeFor_activateApp(params?.appId)
                }
                else if(params?.type === 'bundleId'){
                  code = this.codeFor_activateApp(params?.bundleId)
                }
                else{
                  // do not record
                }
                break;
              case 'terminateApp': 
                if(params?.type === 'appId'){
                  code = this.codeFor_terminateApp(params?.appId)
                }
                else if(params?.type === 'bundleId'){
                  code = this.codeFor_terminateApp(params?.bundleId)
                }
                else{
                  // do not record
                }
                break;
              case 'getAppStrings':
                code = this.codeFor_getAppStrings(null, null, params?.language, params?.stringFile)
                break;
              case 'endTestCoverage':
                code = this.codeFor_endTestCoverage(params?.intent, params?.path)
                break;
              case 'getPerformanceData':
                code = this.codeFor_getPerformanceData(null, null, params?.packageName, params?.dataType, params?.dataReadTimeout)
                break;
              case 'getPerformanceDataTypes':
                code = this.codeFor_getSupportedPerformanceDataTypes()
                break;
              case 'execute':
                code = this.codeFor_execute(params?.script, params?.args)
                break;
              case 'getContext':
                code = this.codeFor_currentContext()
                break;
              case 'getAllContext':
                code = this.codeFor_contexts()
                break;
              case 'setContext':
                code = this.codeFor_context(params?.name)
                break;
              case 'goToUrl':
                varNameIgnore, varIndexIgnore, url = params
                code = this.codeFor_goToUrl(url);
                break;
              case 'getUrl':
                code = this.codeFor_getUrl()
                break;
              case 'webBack':
                code = this.codeFor_webBack()
                break;
              case 'forward':
                code = this.codeFor_forward()
                break;
              case 'refresh':
                code = this.codeFor_refresh()
                break;
              default:
                break
            }

            if (code !== '') {
              str += `${code}\n`;
            }
        }
        if (this.includeBoilerplate) {
          return this.wrapWithBoilerplate(str);
        }
        return str;
      }

      getNewLocalVar () {
        this.localVarCount++;
        return `el${this.localVarCount}`;
      }

      getVarName (varName, varIndex) {
        if (varIndex || varIndex === 0) {
          return `${varName}[${varIndex}]`;
        }   
        return varName;
      }

      codeFor_findAndAssign () {
        throw new Error('Need to implement codeFor_findAndAssign');
      }

      getVarForFind (strategy, locator)  {
        const key = `${strategy}-${locator}`;
        let wasNew = false;
        if (!this.localVarCache[key]) {
          this.localVarCache[key] = this.getNewLocalVar();
          wasNew = true;
        }
        this.lastAssignedVar = this.localVarCache[key];
        return [this.localVarCache[key], wasNew];
      }

      codeFor_findElement (strategy, locator) {
        //let {strategy, locator} = params
        let [localVar, wasNew] = this.getVarForFind(strategy, locator);
        if (!wasNew) {
          // if we've already found this element, don't print out
          // finding it again
          return '';
        }
        return this.codeFor_findAndAssign(strategy, locator, localVar);
      }

      codeFor_tap () {
        throw new Error('Need to implement codeFor_tap');
      }

      codeFor_swipe () {
        throw new Error('Need to implement codeFor_tap');
      }

}


export default Framework