import React, { Component } from 'react';
import {Header, Segment, Dropdown, Menu, Button, Icon, Popup, Modal} from "semantic-ui-react";
import {withTranslation} from 'react-i18next';
import frameworks from "./frameworks";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import copy from "copy-to-clipboard";


class RecordedActions extends Component {

    
    state = {
        actionFramework: 'java',
        syntax: 'java',
        showBoilerplate: false,
        recorderModal: false
    };


    code () { 
        let {sessionDetails, recordedActions, showBoilerplate} = this.props;
        let {host, port, path, https, desiredCapabilities} = sessionDetails;
        let framework = new frameworks[this.state.actionFramework](host, port, path,
          https, desiredCapabilities);
        framework.actions = recordedActions;
        framework.includeBoilerplate = showBoilerplate;
        let rawCode = framework.getCodeString();
        return rawCode
    }


    // clears code inside recorder panel
    clearRecording = () => {
        this.props.clearActions()
    }

    // close recorder panel
    closeRecorder = () => {
        this.props.closeRecoder()

    }

    openRecorderLog = () => {
        this.setState({recorderModal : true});
    }

    closeRecorderLog = () => {
        this.setState({recorderModal : false});
    }

    handleBoilerPlate = () => {
        this.setState({showBoilerplate: !this.state.showBoilerplate}, ()=>{
            this.props.handleBoilerPlate()
        })
    }

    handleCodeCopy = () => {
        copy(this.code());  
    }

    handleOnChange = (e, data) => {
        // check if selected index is existed in languages list
        if(this.getLanguages().length >= data.value){
            // array index is one less of dropdown index 
            this.setState({actionFramework: this.getLanguages()[data.value - 1][0], 
                           syntax: this.getLanguages()[data.value - 1][1]})
        }
    }

    getActionFrameworks(){
        const actionFrameworks = [
            { key: 1, text: 'Java - JUnit', value: 1 },
            { key: 2, text: 'Python', value: 2 },
            { key: 3, text: 'Ruby', value: 3 },
            { key: 4, text: 'Robot Framework', value: 4 },
            { key: 5, text: 'JS - WD (Promise)', value: 5 },
            { key: 6, text: 'JS - Webdriver.io', value: 6 },
            { key: 7, text: 'JS - Oxygen HQ', value: 7 }
        ]
        return actionFrameworks
    }

    getLanguages(){
        // the following array stores "language framework key" - "syntax highlighter key" pairs. 
        return [['java', 'java'], 
                ['python', 'python'], 
                ['ruby', 'ruby'], 
                ['robot', 'robot'], 
                ['jsWd', 'javascript'], 
                ['jsWdio', 'javascript'], 
                ['jsOxygen', 'javascript']]
    }


    render(){
        return(
                <Segment> 
                    <div>
                    <Header as='h4' icon='terminal' content={this.props.t(`Recorder`)} />
                    <div style={{top: 10, position: "absolute", right: 10, paddingBottom: "inherit", paddingRight: "inherit"}}>
                        <Menu compact position={"top right"} size='mini'>
                            <Dropdown placeholder='Java - JUnit' simple item options={this.getActionFrameworks()}  onChange={this.handleOnChange}/>
                        </Menu>
                        <Button.Group basic position={"top right"} style={{"marginLeft": "10px"}} size='small'>

                            <Popup flowing on={["hover", "click"]} hoverable content={this.props.t(`Show/Hide Boiler Plate`)}  position='bottom right' pinned 
                                trigger={<Button onClick={this.handleBoilerPlate} icon='sign-in alternate'/>}/>

                            <Popup flowing on={["hover", "click"]} hoverable content={this.props.t(`Copy code to clipboard`)}   position='bottom right' pinned 
                                trigger={<Button onClick={this.handleCodeCopy}  icon='copy outline'/>}/>
                               
                            <Popup flowing on={["hover", "click"]} hoverable content={this.props.t(`Clear Actions`)}  position='bottom right' pinned 
                                trigger={<Button onClick={this.clearRecording} icon='trash alternate outline'/>}/>
                            
                            <Popup flowing on={["hover", "click"]} hoverable content={this.props.t(`Recorder Logs`)} position='bottom right' pinned
                                trigger={<Button onClick={this.openRecorderLog} icon='expand arrows alternate' />} />

                            <Popup flowing on={["hover", "click"]} hoverable content={this.props.t(`Close Recorder`)}  position='bottom right' pinned 
                                trigger={<Button onClick={this.closeRecorder} icon='close'/>}/>

                        </Button.Group>
                    </div>
                    </div>
                <div>
                        {(this.props.recordedActions.length === 0) && <i> {this.props.t(`Perform some actions to see code show up here`)} </i>}
                   
                            <div>
                                <SyntaxHighlighter language={this.state.actionFramework} style={googlecode} wrapLines={true}> 
                                    {this.code()} 
                                </SyntaxHighlighter>
                            </div>
                       
                    </div>
                <Modal
                    open={this.state.recorderModal}
                    onClose={this.closeRecorderLog}
                    onOpen={this.openRecorderLog}>
                    <Modal.Header>{this.props.t(`Recorder Logs`)}</Modal.Header>
                    <Modal.Content image scrolling>
                        <Modal.Description>
                            <p>
                                <SyntaxHighlighter language={this.state.actionFramework} style={googlecode} wrapLines={true}>
                                    {this.code()}
                                </SyntaxHighlighter>
                            </p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeRecorderLog} primary icon labelPosition='right'>
                            {this.props.t(`Close`)}
                            <Icon name='window close icon' />
                        </Button>
                    </Modal.Actions>
                </Modal>
                </Segment>
        )
    }

}


export default withTranslation()(RecordedActions);