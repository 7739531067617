import React from 'react';
import {Card, Checkbox, Dropdown, Icon, Popup, Confirm} from 'semantic-ui-react';
import {Link, withRouter} from 'react-router-dom';
import ReservationModal from '../../components/ReservationModal';
import {endFullLoad, startFullLoad} from '../../actions';
import axios from 'axios';
import {connect} from 'react-redux';
import EditDeviceFields from '../../components/EditDeviceFields';
import {withTranslation} from 'react-i18next';
import {cardContent, deviceIdRenderer, imageSelector, osIconSelectorProperly, handleReboot} from '../../utils/Methods';
import moment from 'moment';
import {showError, showInfo} from '../../utils/ToastHelpers';
import copy from 'copy-to-clipboard';
import InstantUsageInfo from '../../components/InstantUsageInfo';
import DeviceCardCategories from '../../components/DeviceCardCategories';

import {PrivilegeConstants} from '../../utils/PrivilegeConstants';
import InformationPanel from '../../components/InformationPanel';
import { deviceListCardMessages, DEVICE_DETAILS_LABEL, EDIT_BUTTON, FAVORITE_LABEL, NOT_FETCH_DEVICE_DETAILS, NO_DATA_LABEL, RESERVATION_LABEL, TEST_TYPE_LABEL, UNFAVORITE_LABEL, multiManageOperationMessages, YES_LABEL, CANCEL_BUTTON } from '../../utils/UIMessages';
import { CALENDAR_ALTERNATE_OUTLINE_ICON, CANCEL_ICON, EDIT_ICON, ELLIPSIS_HORIZONTAL_ICON, QUESTION_CIRCLE_OUTLINE_ICON, STAR_ICON, STAR_OUTLINE_ICON, SYNC_ICON } from '../../utils/UiIcons';


const statusIcon = (deviceStatus, status, offlineReservedUsername='') => {
    let iconColor = '';
    if (deviceStatus === 0) {
        if (status.Reserved === true) {
            iconColor = 'yellow'
        } else if (status.Manual === true || status.Automation === true || status.Development === true) {
            iconColor = 'blue'
        } else {
            iconColor = 'green';
        }
    } else if (deviceStatus === 1) {  
        if (offlineReservedUsername) {
        iconColor = 'orange';
      } else {
        iconColor = 'red';
    }
}
    return (<i aria-hidden='true' style={{ fontSize: 8 }} className={`dot circle ${iconColor} bold icon basic`}></i>)
};


class DeviceListCard extends React.Component {
    state = {
        showReservationModal: false,
        selectedDevice: {},
        showDetailsModal: false,
        deviceDetails: {},
        loading: false,
        showModal: false,
        showModalReservation: false,
        isUsersPopupShow: false,
        selectedTerminateType: '',
        openConfirm: false,
        deviceToBeRebooted: null
    };


    fetchDeviceDetails = (deviceId, deviceStatus) => {
        this.props.startFullLoad(` ${DEVICE_DETAILS_LABEL()}`);
        if (deviceStatus === 0) {
            axios.get(`/api/devices/${deviceId}/details`).then(res => {
                this.setState({ deviceDetails: res.data, showDetailsModal: true }, () => {
                    this.props.endFullLoad()
                })
            }).catch(err => {
                showError(`${NOT_FETCH_DEVICE_DETAILS()} ${err?.response?.status}`);
                this.props.endFullLoad()
            })
        } else if (deviceStatus === 1) {
            this.setState({ deviceDetails: { deviceId, gps: false, wifi: false }, showDetailsModal: true }, () => {
                this.props.endFullLoad()
            })
        }
    };

    exceptInDeviceListPage = () => this.props.lastUsedDevices || this.props.favoriteDevices

    onClickDeviceIdSpan = deviceId => {
        copy(deviceId)
        showInfo(deviceListCardMessages().DEVICE_ID_COPIED_MESSAGE, 1500)
    }

    onConfirmClose = () => this.setState({ openConfirm: false })

    render() {
        return (
            <>
                <Card.Group centered={this.exceptInDeviceListPage()} className={this.exceptInDeviceListPage() ? 'card-group-sidebar' : null}>
                    {this.props.deviceList.length > 0 && this.props.deviceList.map((device, i) => {

                        let buttonStatus = device.deviceStatus === 1 || !this.props.privileges.includes('24');
                        
                        if(device?.visible !== undefined){
                            buttonStatus = buttonStatus || !device?.visible
                        }

                        return <React.Fragment key={i}>
                            <Card className={this.exceptInDeviceListPage() ? 'device-card-container-sidebar' : 'device-card-container'}>
                                <Card.Content>
                                    <div className='device-card'>
                                      
                                        <Link to={'/device-manage?device_1=' + device.deviceId} className={buttonStatus ? 'no-access' : ''}>
                                            {imageSelector(device.deviceModel, this.props.loadingImages, this.props.deviceImages)}
                                        </Link>

                                        <div className={this.exceptInDeviceListPage() ? 'device-card-content' : 'device-card-content-sidebar'}>
                                           
                                            <div className='device-card-name'>
                                                {device.marketName ? 
                                                <Link className={buttonStatus ? 'no-access' : ''} to={'/device-manage?device_1=' + device.deviceId}>
                                                    {device.brand ? device.brand.charAt(0).toUpperCase() + device.brand.toLowerCase().slice(1) + " " : NO_DATA_LABEL()}
                                                    {device.deviceModel ? `${device.deviceModel} ` : <>{deviceListCardMessages().UNKNOWN_MODEL_LABEL}</>}
                                                    {<div style={{textOverflow: 'ellipsis',overflow: 'hidden', whiteSpace: 'nowrap'}}>{device.marketName}</div>}
                                                </Link> : 
                                                <>
                                                    <Link className={buttonStatus ? 'no-access' : ''}
                                                        to={'/device-manage?device_1=' + device.deviceId}>

                                                        {device?.brand ? device?.brand.charAt(0).toUpperCase() + device?.brand.toLowerCase().slice(1) + ' ' : NO_DATA_LABEL()}
                                                        {device?.deviceModel ? `${device?.deviceModel} ` : <>{deviceListCardMessages().UNKNOWN_MODEL_LABEL}</>}
                                                    </Link>
                                                    <>{''}</>
                                                </>
                                                }
                                            </div>

                                            <div>
                                                <div>
                                                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden',whiteSpace: 'nowrap'}}>
                                                        {device.specificName ? `${device.specificName}\n` :<>{''}</>}
                                                    </div>
                                                </div>
                                                <span className='device-card-id-span' title={device.deviceId}
                                                    onClick={() => this.onClickDeviceIdSpan(device.deviceId)}>{deviceIdRenderer(device.deviceId)}
                                                </span>
                                                <span className='device-card-status-span'>
                                                    {statusIcon(device.deviceStatus, device.status, device.offlineReservedInfo?.username)}
                                                    {cardContent(device.deviceStatus, device.status, device.offlineReservedInfo?.username)}
                                                </span>

                                                {/*for side bar*/}
                                                {this.props?.lastUsedDevices &&
                                                    <div className='device-card-last-used-container'>
                                                        <div>{deviceListCardMessages().USE_DATE_LABEL}: {moment(device.dateOfUse).format('DD/MM/YYYY HH:mm:ss')}</div>
                                                        <div>{TEST_TYPE_LABEL()}: {device.testType}</div>
                                                    </div>}

                                                {!this.exceptInDeviceListPage() && <DeviceCardCategories categories={device.categories} />}
                                            </div>
                                            <div className='device-icon' style={{
                                                bottom: 0, position: 'absolute', right: 0, paddingBottom: 'inherit',
                                                paddingRight: 'inherit'
                                            }}>
                                                {
                                                    (device.status.Manual || device.status.Automation || device.status.Development) &&
                                                    <InstantUsageInfo device={device}
                                                        privileges={this.props.privileges} />
                                                }
                                                { <Popup style={{ left: 10 }}
                                                    trigger={<Icon
                                                        name={device.favorite ? STAR_ICON : STAR_OUTLINE_ICON}
                                                        onClick={() => this.props.onFavoriteDevice(device)}
                                                        style={(device.favorite ? { color: '#f1a81a', verticalAlign:'middle' } : { color: 'gray', verticalAlign:'middle' })}
                                                    />}
                                                    content={device.favorite ? UNFAVORITE_LABEL() : FAVORITE_LABEL()}
                                                    position={'bottom right'}
                                                />}
                                                
                                                {!this.exceptInDeviceListPage() && <Dropdown icon={<Icon name={ELLIPSIS_HORIZONTAL_ICON}/>}
                                                        className='device-card-dropdown' direction='left'>
                                                        <Dropdown.Menu>
                                                            {/*RBA check*/}

                                                            {(this.props.privileges.includes(PrivilegeConstants.RESERVATION.OFFLINE) ||
                                                            (this.props.privileges.includes(PrivilegeConstants.RESERVATION.NORMAL) && device.bookable)) &&
                                                                <Dropdown.Item icon={CALENDAR_ALTERNATE_OUTLINE_ICON}
                                                                    text={RESERVATION_LABEL()}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            selectedDevice: device,
                                                                            showReservationModal: true
                                                                        })
                                                                    }}
                                                                />}
                                                                {
                                                          this.props.privileges.includes('44') && device.offlineReservedInfo &&
                                                          <Dropdown.Item icon={CANCEL_ICON}
                                                                       text={deviceListCardMessages().CANCEL_OFFLINE_RESERVATION}
                                                                       onClick={() => {
                                                                         this.props.setCancelledReservationId(device.offlineReservedInfo?.reservationId);
                                                                         this.props.handleOpenCancelReservationModal();
                                                                       }}
                                                          />
                                                        }
                                                            {/*RBA check*/}
                                                            {this.props.privileges.includes(PrivilegeConstants.DEVICE_INFORMATION) &&
                                                                <Dropdown.Item icon={QUESTION_CIRCLE_OUTLINE_ICON}
                                                                    text={deviceListCardMessages().INFO_LABEL}
                                                                    onClick={() => {
                                                                        this.fetchDeviceDetails(device.deviceId, device.deviceStatus, device.deviceModel)
                                                                    }}
                                                                />}

                                                            {
                                                                <Dropdown.Item icon={EDIT_ICON}
                                                                    text={EDIT_BUTTON()}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            showModal: true,
                                                                            selectedDevice: device
                                                                        })
                                                                    }}
                                                                />}

                                                            {device.deviceStatus === 0 && this.props.privileges.includes(PrivilegeConstants.REBOOT_SHUTDOWN) &&
                                                                <Dropdown.Item icon={SYNC_ICON}
                                                                    text={multiManageOperationMessages().REBOOT_PHONE}
                                                                    onClick={() => {
                                                                        this.setState({openConfirm: true, deviceToBeRebooted: device.deviceId})
                                                                    }
                                                                }
                                                                />
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>}

                                                {!this.exceptInDeviceListPage() &&
                                                    <Checkbox fitted className='device-card-checkbox'
                                                        checked={this.props.selectedDeviceIdList.includes(device.deviceId)}
                                                        onChange={(e, { checked }) => this.props.toggleSelectedDevice(device.deviceId, checked)}
                                                        disabled={device.deviceStatus === 1} style={{verticalAlign:'middle'}} />
                                                }                       
                                                <Icon>{osIconSelectorProperly(device)}</Icon>                                                                         
                                            </div>

                                            <Confirm
                                                open={this.state.openConfirm}
                                                header={multiManageOperationMessages(null, null, 'Reboot').REBOOT_SHUTDOWN_CONFIRM_HEADER}
                                                onCancel={this.onConfirmClose}
                                                content={multiManageOperationMessages(null, null, 'Reboot').REBOOT_SHUTDOWN_CONFIRM_MESSAGE}
                                                cancelButton={CANCEL_BUTTON()}
                                                confirmButton={YES_LABEL()}
                                                onConfirm={() => {
                                                    handleReboot(this.state.deviceToBeRebooted, this.onConfirmClose);
                                                }}
                                            />
                                            {/*RBA check*/}
                                            {/*{this.props.privileges.includes("14") && !infoButtonStatus && <Popup style={{left: 10}}*/}
                                            {/*    trigger={<Icon name='calendar alternate outline' onClick={() => {*/}
                                            {/*        this.setState({*/}
                                            {/*            selectedDevice: device,*/}
                                            {/*            showReservationModal: true*/}
                                            {/*        })*/}
                                            {/*    }}/>}*/}
                                            {/*    content={this.props.t("Reservation")}*/}
                                            {/*    position={"bottom right"}*/}
                                            {/*/>}*/}
                                            {/*/!*RBA check*!/*/}
                                            {/*{this.props.privileges.includes("7")&&<Popup style={{left: 10}}*/}
                                            {/*    trigger={<Icon name='question circle outline'*/}
                                            {/*                   onClick={() => {*/}
                                            {/*                       this.fetchDeviceDetails(device.deviceId,device.deviceStatus, device.deviceModel)*/}
                                            {/*                   }}/>}*/}
                                            {/*    content={this.props.t("Info")}*/}
                                            {/*    position={"bottom right"}*/}
                                            {/*/>}*/}
                                            {/*<Popup style={{left: 10}}*/}
                                            {/*    trigger={<Icon name="edit outline" onClick={() => {*/}
                                            {/*        this.setState({showModal: true, selectedDevice: device})*/}
                                            {/*    }}/>}*/}
                                            {/*    content={this.props.t("Edit")}*/}
                                            {/*    position={"bottom right"}*/}

                                            {/*/>*/}
                                            {/*{*/}
                                            {/*    (device.status.Manual || device.status.Automation || device.status.Development) &&*/}
                                            {/*    <InstantUsageInfo device={device} privileges={this.props.privileges} />*/}
                                            {/*}*/}
                                            {/*<span className={"verticalLine"}/>*/}
                                            {/*<Icon className={"osIcon"} name={this.osIconSelector(device.os)} color='grey'/>*/}



                                        </div>

                                    </div>
                                </Card.Content>
                            </Card>
                            
                        </React.Fragment>
                    })}
                </Card.Group>

                <ReservationModal showModal={this.state.showReservationModal}
                    showModalEdit={this.state.showModal}
                    onCloseModal={() => this.setState({ showReservationModal: false, selectedDevice: {} })}
                    device={this.state.selectedDevice}
                    privileges={this.props.privileges}
                    getReservableGroupList={this.props.getReservableGroupList}
                    reservableGroupList={this.props.reservableGroupList}
                    userDetails={this.props.userDetails}
                    />
                {/* <InformationPanel.old
                    open={this.state.showDetailsModal}
                    setShowDetailsModel={() => {
                        this.setState({ showDetailsModal: false })
                    }}
                    deviceDetails={this.state.deviceDetails}
                    device={this.state.selectedDevice}
                    deviceImages={this.props.deviceImages}
                    loadingImages={this.props.loadingImages}
                /> */}
                <InformationPanel
                    open={this.state.showDetailsModal}
                    onClose ={()=>this.setState({ showDetailsModal: false })}
                    deviceImages={this.props.deviceImages}
                    deviceDetails={this.state.deviceDetails}
                    loadingImages={this.props.loadingImages}
                />
                <EditDeviceFields showModal={this.state.showModal} device={this.state.selectedDevice}
                    reloadImages={this.props.reloadImages}
                    privileges={this.props.privileges}
                    deviceImages={this.props.deviceImages}
                    loadingImages={this.props.loadingImages}
                    onClose={() => this.setState({ showModal: false, selectedDevice: {} }, () => {
                        this.props.reloadDevices();
                    })}
                    getReservableGroupList={this.props.getReservableGroupList}
                    reservableGroupList={this.props.reservableGroupList}
                    />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.notification.loading,
        loadingContent: state.notification.loadingContent,
        systemParameterList : state.systemParameters.systemParameters
    }
};


export default withTranslation()(connect(mapStateToProps, { startFullLoad, endFullLoad })(withRouter(DeviceListCard)));
