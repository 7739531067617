import React, {Component} from 'react';
import axios from "axios";
import {showError} from "../utils/ToastHelpers";
import DeviceListCard from "../pages/device/DeviceListCard";
import {withTranslation} from "react-i18next";
import {orderDevices} from "../utils/Methods";
import { sidebarFavDevicesMessages } from '../utils/UIMessages';

class SidebarFavDevices extends Component {
    state = {
        favoriteDeviceList: [],
    };

    getFavoriteDevices = () => {
        axios.get("/api/devices", {params: {favorite: true}})
            .then((res) => {
                let favoriteDeviceList = orderDevices(res.data)
                this.setState({favoriteDeviceList});
            })
            .catch((err) => showError(sidebarFavDevicesMessages().FAVORITE_DEVICES_FETCH_ERROR + err?.response?.data?.message ?? err.toString()));
    };

    componentDidMount() {
        this.getFavoriteDevices();
    }

    componentDidUpdate(prevProps) {
        if ((JSON.stringify(prevProps.deviceList) !== JSON.stringify(this.props.deviceList) && this.props.visible) || //added for onFavoriteDevice method
            (!prevProps.visible && this.props.visible)// trigger every time the sidebar shows up
        ) {
            this.getFavoriteDevices();
        }
    }

    render() {
        return (
            <>
                {this.state.favoriteDeviceList?.length > 0 && (
                    <DeviceListCard
                        {...this.props}
                        deviceList={this.state.favoriteDeviceList}
                        favoriteDevices={true}
                    />
                )}
            </>
        );
    }
}

export default withTranslation()(SidebarFavDevices);