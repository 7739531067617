import React, {Component} from 'react';
import { Button, Confirm, Dropdown, Form, Modal, Icon, Popup, Checkbox } from 'semantic-ui-react';
import {PrivilegeConstants} from '../../utils/PrivilegeConstants'
import {
    POPUP_POSITIONS
} from '../../utils/Constants';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next'
import {getPrivileges, formatBytes} from '../../utils/Methods';
import moment from 'moment';
import _ from 'lodash'
import {showError, showSuccess} from '../../utils/ToastHelpers';
import {
    downloadApp,
    deleteSelectedApps
} from '../../api/apiCalls'
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8'
import { DOWNLOAD_ICON, ELLIPSIS_HORIZONTAL_ICON, TRASH_ALTERNATE_OUTLINE_ICON } from '../../utils/UiIcons';
import { ACTIONS_HEADER, appDetailsMessages, APPLICATION_CATEGORY, APP_VERSION_HEADER, CANCEL_BUTTON, CONFIRM_BUTTON, DELETE_BUTTON, DOWNLOAD_BUTTON, FAILED_ERROR_MESSAGE, FILE_NAME_HEADER, FILE_SIZE_HEADER, ID_HEADER, NO_NAME_LABEL, UNKNOWN_LABEL, UPLOADER_HEADER, UPLOAD_DATE_HEADER } from '../../utils/UIMessages';

class AppDetailsModal extends Component {

    //New React Table V8 Columns with Library Access Control Method
    columnsRender = () => {
        const columns = [
            {
                header: () => <div>{ID_HEADER()}</div>,
                accessorFn: d => d.id.toString(),
                id: 'id',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{FILE_NAME_HEADER()}</div>,
                accessorKey: 'fileName',
                id: 'fileName',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{APP_VERSION_HEADER()}</div>,
                accessorKey: 'appVersion',
                id: 'appVersion',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{appDetailsMessages().APP_VERSION_CODE_HEADER}</div>,
                accessorKey: 'appVersionCode',
                id: 'appVersionCode',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{appDetailsMessages().APP_VERSION_NAME_HEADER}</div>,
                accessorKey: 'appVersionName',
                id: 'appVersionName',
                cell: info => (<span>{info.getValue() ?? NO_NAME_LABEL()}</span>)
            },
            {
                header: () => <div>{FILE_SIZE_HEADER()}</div>,
                accessorFn: d => formatBytes(d.fileSize),
                id: 'fileSize',
                cell: info => (<span>{info.getValue()}</span>)
            },
            {
                header: () => <div>{UPLOAD_DATE_HEADER()}</div>,
                accessorKey: 'uploadDate',
                id: 'uploadDate',
                cell: info => (<span>{moment(info.getValue()).format('DD/MM/YYYY HH:mm')}</span>)
            },
            {
                header: () => <div>{UPLOADER_HEADER()}</div>,
                accessorKey: 'uploader',
                id: 'uploader',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            }
        ];

        ([
            PrivilegeConstants.APPLICATION_MANAGEMENT.DOWNLOAD_FROM_LIB,
            PrivilegeConstants.APPLICATION_MANAGEMENT.DELETE_FROM_LIB
        ].some(privilege => this.state.privileges.includes(privilege))) && (
            columns.push(
                {
                    header: () => <div>{ACTIONS_HEADER()}</div>,
                    accessorKey: 'id',
                    id: 'actions',
                    enableColumnFilter: false,
                    enableSorting: false,
                    cell: info => (
                        <Dropdown
                            text={ACTIONS_HEADER()}
                            icon={ELLIPSIS_HORIZONTAL_ICON}
                            labeled
                            button
                            className='icon'
                        >
                            <Dropdown.Menu style={{ zIndex: 90 }}>
                                {this.state.privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.DOWNLOAD_FROM_LIB) &&
                                    <Dropdown.Item onClick={() => this.handleDownloadApp(info.row.original)}><Icon
                                        name={DOWNLOAD_ICON} />{DOWNLOAD_BUTTON()}</Dropdown.Item>
                                }
                                {this.state.privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.DELETE_FROM_LIB) &&
                                    <Dropdown.Item onClick={() => this.setState({
                                        confirmVisibility: true,
                                        selectedId: info.row.original.id
                                    })}>
                                        <Icon name={TRASH_ALTERNATE_OUTLINE_ICON} /> {DELETE_BUTTON()}
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }
            )
            ,columns.unshift({
                accessorKey: 'btn',
                id: 'btn',
                size: 85,
                header: () => <div></div>,
                selection:
                    <span id='select-all-app-details'>
                        {
                            <span style={{ marginLeft: 7 }}>
                            <Checkbox checked={this.state.filteredSelectedApp.length === this.state.selectedAppIdList.length || this.state.unSelectAll}
                                onChange={this.onSelectAll} />
                            </span>
                        }

                        
                        <Popup
                            position={POPUP_POSITIONS.BOTTOM_RIGHT}
                            content={appDetailsMessages().DELETE_SELECTED_APPS_LABEL}
                            trigger={
                                <Button icon={TRASH_ALTERNATE_OUTLINE_ICON} color={'red'}
                                    disabled={this.state.selectedAppIdList.length < 2}
                                    onClick={() => this.setState({
                                        confirmDeleteAllVisibility: true
                                    })}
                                />}
                        />
                    
                    </span>
                ,
                filterType: 'selection',
                enableSorting: false,
                cell: info => (
                    <Checkbox checked={this.state.selectedAppIdList.includes(info.row.original.id)}
                    onChange={(e, { checked }) => this.toggleSelectedApp(info.row.original.id, checked)}
                />)
            })
        )
        
        return columns;
    }

    state = {
        appDetail: [],
        privileges: [],
        cloneSelectedApp: [],
        filteredSelectedApp: [],
        confirmVisibility: false,
        confirmDeleteAllVisibility: false,
        selectedId: '',
        loading: false,
        selectedCategory: '',
        allCategories: [],
        selectedAppIdList: [],
        pageIndex: 0,
        pageSize:0,
        unSelectAll:false,
        tableRef: {}
    };

    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({ privileges })
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.detailModalVisibility !== this.props.detailModalVisibility) && (this.props.detailModalVisibility)) {
            let allCategories = this.props.selectedApp.map(app => {
                return {
                    key: app.appCategory,
                    value: app.appCategory,
                    text: app.appCategory
                }
            })
            allCategories = _.uniqBy(allCategories, 'key')
            this.setState({allCategories}, () => {
                this.setState({selectedCategory: allCategories[0].value}, () => {
                    const filteredSelectedApp = this.props.selectedApp.filter(app => app.appCategory === this.state.selectedCategory)
                    this.setState({filteredSelectedApp})
                })
            })
        }
    }

    handleDeleteSelectedApps = idList => {
        deleteSelectedApps(idList.toString()).then(res => {
            if (res.data.success) {
                showSuccess(this.props.t(res.data.message));
            } else {
                const splittedMessage = res.data.message.split(':');
                showError(this.props.t(splittedMessage[0]) + ':' + splittedMessage[1]);
            }
            this.props.handleCloseDetailModal();
            this.props.callback();
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()} ${this.props.t(err.response?.data?.message ?? err.toString())}`)
        }).finally(() =>{
            this.setState({ confirmVisibility: false, confirmDeleteAllVisibility: false, selectedAppIdList: [], unSelectAll: false });
        });
    };

    handleDownloadApp = (app) => {
        this.setState({ loading: true }, () => {
            downloadApp(app.id).then(res => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${app.fileName}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.setState({ loading: false })
            }).catch(err => {
                this.setState({ loading: false })
                showError(FAILED_ERROR_MESSAGE() + err.response?.data?.message ?? err.toString())
            })
        })
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined && row[id] !== null ?
                String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    onChangeCategory = (e, {value}) => {
        this.setState({selectedCategory: value}, () => {
            const filteredSelectedApp = this.props.selectedApp.filter(app => app.appCategory === value)
            this.setState({filteredSelectedApp})
        })
    }

    toggleSelectedApp = (appId, checked) => {
        if (checked) {
            this.setState(prev => {
                return {
                    ...prev,
                    selectedAppIdList:
                        prev.selectedAppIdList.concat(appId)
                };
            });
        } else {
            this.setState(prev => ({
                ...prev,
                selectedAppIdList: prev.selectedAppIdList.filter(
                    id => id !== appId
                ),
                unSelectAll:false
            }));
        }
    };

    onSelectAll = () => {
        const startIndex = this.state.tableRef.pageIndex * this.state.tableRef.pageSize;
            const endIndex = startIndex + this.state.tableRef.pageSize;
        const selectedApps = [...this.state.filteredSelectedApp]
            .slice(startIndex, endIndex);
        selectedApps.forEach(selectedApp => this.toggleSelectedApp(selectedApp.id,!this.state.unSelectAll))
        this.setState({unSelectAll:!this.state.unSelectAll})
    }

    render() {
        return (
            <>
                <Modal
                    open={this.props.detailModalVisibility}
                    onClose={()=>{
                        this.setState({selectedAppIdList:[],unSelectAll:false})
                        this.props.handleCloseDetailModal()
                    }}
                    size='fullscreen'
                    closeIcon
                    closeOnDimmerClick={true}
                    centered={false}
                >
                    <Modal.Header icon='list' textAlign={'left'} style={{ fontSize: '1.54rem' }}
                        size={'tiny'}>
                        <div>{appDetailsMessages().APP_DETAILS_HEADER} <br /> {this.props.selectedApp[0]?.appName} - {this.props.selectedApp[0]?.appPackage.slice(0, -4)}</div>
                        <div style={{ justifyContent: 'flex-end', display: 'flex', marginTop: -62, marginBottom: -15 }}>
                            <Form>
                                <Form.Field>
                                    <label>{APPLICATION_CATEGORY()}</label>
                                    <Form.Dropdown style={{ marginBottom: 10 }} options={this.state.allCategories}
                                        selection value={this.state.selectedCategory}
                                        placeholder={appDetailsMessages().APP_CATEGORY_PLACEHOLDER}
                                        onChange={this.onChangeCategory} />
                                </Form.Field>
                            </Form>
                        </div>
                    </Modal.Header>
                    <Modal.Content>
                        <div>
                            {this.state.selectedCategory &&
                                <ReactTableV8
                                    data={this.state.filteredSelectedApp}
                                    columns={this.columnsRender()}
                                    loadingProp={this.state.loading}
                                    columnwiseFilterableProp={true}
                                    getTableRefProp={tableRef => this.setState({tableRef})}
                                />
                            }
                        </div>
                    </Modal.Content>
                </Modal>

                <Confirm
                    open={this.state.confirmVisibility}
                    content={appDetailsMessages().DELETE_CONFIRM}
                    header={appDetailsMessages().DELETE_APP_HEADER}
                    onClose={() => {
                        this.setState({confirmVisibility: false, selectedId: ''})
                    }}
                    onCancel={() => {
                        this.setState({confirmVisibility: false, selectedId: ''})
                    }}
                    onConfirm={() => {
                        this.handleDeleteSelectedApps(this.state.selectedId)
                    }}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />
                <Confirm
                    open={this.state.confirmDeleteAllVisibility}
                    content={appDetailsMessages().DELETE_SELECTED_APP_CONFIRM}
                    header={appDetailsMessages().DELETE_SELECTED_APPS_LABEL}
                    onClose={() => {
                        this.setState({confirmDeleteAllVisibility: false})
                    }}
                    onCancel={() => {
                        this.setState({confirmDeleteAllVisibility: false})
                    }}
                    onConfirm={() => {
                        this.handleDeleteSelectedApps(this.state.selectedAppIdList)
                    }}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />
            </>
        );
    }
}

export default withTranslation()(withRouter(AppDetailsModal));
