import React, { Component } from 'react';
import { Button, Grid, Header, Input, Table } from 'semantic-ui-react';
import _ from 'lodash';
import { showError, showSuccess } from '../../../utils/ToastHelpers';
import {
    addAllFilesToGroup,
    addFileToGroup,
    deleteFileInGroup,
    getAllFilesInGroup,
    getFileList,
    removeAllFilesInGroup
} from '../../../api/apiCalls';
import { getIconForFile, formatBytes } from '../../../utils/Methods';
import { withTranslation } from 'react-i18next';
import { ADD_BUTTON, DELETE_BUTTON, FAILED_ERROR_MESSAGE, FILE_HEADER, FILE_SIZE_HEADER, ID_HEADER, libraryFileUploadMessages, manageGroupFilesMessages, REMOVE_ALL_BUTTON, SELECT_ALL_BUTTON, SUCCESS_MESSAGE } from '../../../utils/UIMessages';
import fileExtension from 'file-extension';

class ManageGroupFiles extends Component {

    state = {
        fileList: [],
        cloneFileList: [],
        selectedFiles: [],
        cloneSelectedFiles: [],
        searchKeywordAll: '',
        searchKeywordSelected: '',
        selectedFileIds: []
    }

    componentDidMount() {
        this.getResources()
    }

    getResources = () => {
        getFileList().then(res1 => {
            this.setState({ fileList: res1.data.content, cloneFileList: res1.data.content })
            getAllFilesInGroup(this.props.selectedGroup?.id).then(res2 => {
                this.setState({ selectedFiles: res2.data, cloneSelectedFiles: res2.data }, () => {
                    this.editAllValues()
                })
            }).catch(err => {
                showError(manageGroupFilesMessages().NOT_FETCH_GROUP_FILES + err?.response?.data?.message ?? err.toString())
            })
        }).catch(err => {
            showError(manageGroupFilesMessages().NOT_FETCH_FILES + err?.response?.data?.message ?? err.toString())
        })
    }

    editAllValues = () => {
        if (!_.isEmpty(this.state.selectedFiles)) {
            let { fileList, cloneFileList } = this.state;
            const selectedFileIds = this.state.selectedFiles.map(file => file.id);
            fileList = fileList.filter(file => !selectedFileIds.includes(file.id));
            cloneFileList = cloneFileList.filter(file => !selectedFileIds.includes(file.id));
            this.setState({ fileList, cloneFileList, selectedFileIds });
        }
    }

    handleAfterDeviceOperation = () => {
        showSuccess(SUCCESS_MESSAGE());
        this.getResources()
        this.setState({ searchKeywordSelected: '', searchKeywordAll: '' })
    }



    onClickAddFile = fileId => {
        const data = [fileId]
        const fileFormData = new FormData();
        fileFormData.append('fileIds', data)
        addFileToGroup(this.props.selectedGroup?.id, fileFormData).then(() => {
            this.handleAfterDeviceOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }
    onClickAddAllFiles = () => {
        const fileIds = this.state.fileList.map(file => file.id)
        const fileFormData = new FormData();
        fileFormData.append('fileIds', fileIds);
        addAllFilesToGroup(this.props.selectedGroup?.id, fileFormData).then(() => {
            this.handleAfterDeviceOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }

    onClickDeleteFile = id => {
        deleteFileInGroup(this.props.selectedGroup?.id, id).then(() => {
            this.handleAfterDeviceOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }

    onClickRemoveAllFiles = async () => {
        try {
            const { selectedFileIds } = this.state;
            await removeAllFilesInGroup(this.props.selectedGroup?.id, selectedFileIds?.toString());
            showSuccess(SUCCESS_MESSAGE());
            this.getResources();
            this.setState({searchKeywordSelected: '', searchKeywordAll: ''});
        } catch (error) {
            showError(FAILED_ERROR_MESSAGE() + error?.response?.data?.message ?? error.toString());
        }
    }


    filterFiles = (listFile, value) => {
        return listFile.filter(file => {
            return file.fileName?.toLowerCase().includes(value) ||
                file.name?.toLowerCase().includes(value)
        });
    }

    onChangeSearch = (_e, { name, value }) => {
        value = value.toLowerCase();
        if (name === 'all') {
            const { cloneFileList } = this.state
            const fileList = this.filterFiles(cloneFileList, value);
            this.setState({ fileList, searchKeywordAll: value })
        } else if (name === 'selected') {
            const { cloneSelectedFiles } = this.state
            const selectedFiles = this.filterDeviceList(cloneSelectedFiles, value);
            this.setState({ selectedFiles, searchKeywordSelected: value })
        } else {
            //invalid name
        }
    }

    render() {
        return (
            <div>
                <Grid columns={2} celled={'internally'} centered>
                    <Grid.Column>
                        <div className='manage-group-container'>
                            <Input fluid onChange={this.onChangeSearch} placeholder={manageGroupFilesMessages().SEARCH_FILE_PLACEHOLDER}
                                value={this.state.searchKeywordAll}
                                style={{ paddingRight: 7 }} name={'all'} />
                            {!_.isEmpty(this.state.fileList) && <><Table basic='very' celled compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{FILE_HEADER()}</Table.HeaderCell>
                                        <Table.HeaderCell>{ADD_BUTTON()}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {_.orderBy(this.state.fileList, ['fileName'], ['asc']).map((file, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <Header as='h4'>
                                                        <Header.Content>
                                                            <div className='file-cell-with-image'>
                                                                {getIconForFile(fileExtension(file.fileName),'huge')}
                                                                <div>
                                                                    {_.capitalize(file.name)}
                                                                    <Header.Subheader>{ID_HEADER()}: {file.id}</Header.Subheader>
                                                                    <Header.Subheader>{FILE_SIZE_HEADER()}: {formatBytes(file.fileSize)}</Header.Subheader>
                                                                </div>
                                                            </div>
                                                        </Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                                <Table.Cell width={'3'}>
                                                    <Button icon='chevron right' primary onClick={() => {
                                                        this.onClickAddFile(file.id)
                                                    }}>
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                                {this.state.fileList?.length > 1 &&
                                    <Button style={{ marginTop: 10 }} primary fluid onClick={this.onClickAddAllFiles}>{SELECT_ALL_BUTTON()}</Button>}
                            </>
                            }
                        </div>
                        <div className='count-total-number'>
                            {libraryFileUploadMessages().TOTAL_NUMBER_OF_FILES_LABEL}:{' '}
                            {this.state.fileList?.length}
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='manage-group-container'>
                            <Input fluid onChange={this.onChangeSearch} placeholder={manageGroupFilesMessages().SEARCH_FILE_PLACEHOLDER}
                                value={this.state.searchKeywordSelected}
                                style={{ paddingRight: 7 }} name={'selected'} />
                            {!_.isEmpty(this.state.selectedFiles) && <><Table basic='very' celled compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{DELETE_BUTTON()}</Table.HeaderCell>
                                        <Table.HeaderCell>{FILE_HEADER()}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.selectedFiles.map((file, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell width={'3'}>
                                                    <Button icon='chevron left' color={'red'}
                                                        onClick={() => this.onClickDeleteFile(file.id)}>

                                                    </Button>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Header as='h4'>
                                                        <Header.Content>
                                                            <div className='file-cell-with-image'>
                                                                {getIconForFile(fileExtension(file.fileName),'huge')}
                                                                <div>
                                                                    {_.capitalize(file.name)}
                                                                    <Header.Subheader>{ID_HEADER()}: {file.id}</Header.Subheader>
                                                                    <Header.Subheader>{FILE_SIZE_HEADER()}: {formatBytes(file.fileSize)}</Header.Subheader>
                                                                </div>
                                                            </div>
                                                        </Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                                {this.state.selectedFileIds?.length > 1 &&
                                    <Button style={{ marginTop: 10 }} primary fluid onClick={this.onClickRemoveAllFiles}>{REMOVE_ALL_BUTTON()}</Button>}
                            </>
                            }
                        </div>
                        <div className='count-total-number'>
                            {libraryFileUploadMessages().TOTAL_NUMBER_OF_FILES_LABEL}:{' '}
                            {this.state.selectedFiles?.length}
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}
export default withTranslation()(ManageGroupFiles);