import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { REDIRECT_DELAY } from '../utils/Constants';
import { notFoundMessages } from '../utils/UIMessages';
import { getPrivileges } from '../utils/Methods';
import { PrivilegeConstants } from '../utils/PrivilegeConstants';

const NotFoundPage = () => {
  const [privileges, setPrivileges] = useState([])
  const [redirectNow, setRedirectNow] = useState(false);

  useEffect(() => {
    setPrivileges(getPrivileges());
}, []);

  setTimeout(() => setRedirectNow(true), REDIRECT_DELAY);

  return redirectNow ? (
    privileges.includes(PrivilegeConstants.DEVICE_LIST) ? <Redirect to='/' /> : <Redirect to='/me' />
  ) : (
    <div className='not-found-page-div'>
          <h1 className='not-found-page-h1'>{notFoundMessages().NOT_FOUND_404}</h1>
          <h2>{notFoundMessages().MESSAGE}</h2>
    </div>
  )
}

export default NotFoundPage;

