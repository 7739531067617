import React from 'react'
import { Button, Input } from 'semantic-ui-react'
import { PrivilegeConstants } from '../utils/PrivilegeConstants'
import { PLUS_ICON, SEARCH_ICON } from '../utils/UiIcons'
import { libraryFileUploadMessages, SEARCH_PLACEHOLDER, UPLOAD_NEW_FILE_LABEL } from '../utils/UIMessages'

const LibraryFileUpload = props => {

    return (
        <div>
            <Input
                onChange={props.handleFileSearch}
                value={props.searchFileKeyword}
                icon={SEARCH_ICON}
                fluid
                placeholder={SEARCH_PLACEHOLDER()}
            />
            {props.privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.UPLOAD) && (
                <Button
                    className='library-file-upload-button'
                    icon={PLUS_ICON}
                    fluid
                    onClick={props.handleOpenFileUploadModal}
                    content={UPLOAD_NEW_FILE_LABEL()}
                />
            )}
            <div className='count-total-number'>{libraryFileUploadMessages().TOTAL_NUMBER_OF_FILES_LABEL}: {props.totalNumOfFiles}</div>
        </div>
    )
}

export default LibraryFileUpload
