import React, { useEffect, useState } from 'react';
import { Dropdown, Icon, Modal, Confirm } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { downloadFile,deleteFile } from '../api/apiCalls';
import { getPrivileges ,blobToJson , formatBytes} from '../utils/Methods'
import { showError, showSuccess } from '../utils/ToastHelpers';
import { PrivilegeConstants } from '../utils/PrivilegeConstants'
import ReactTableV8 from '../components/ReactTableV8/ReactTableV8'
import { HTTP_STATUS_CODES } from '../utils/Constants';
import { ACTIONS_HEADER, CANCEL_BUTTON, CONFIRM_BUTTON, DELETE_BUTTON, DOWNLOAD_BUTTON, EDIT_BUTTON, FAILED_ERROR_MESSAGE, fileDetailsMessages, FILE_NAME_HEADER, FILE_SIZE_HEADER, ID_HEADER, NAME_HEADER, NO_NAME_LABEL, UNKNOWN_LABEL, UPLOADER_HEADER, UPLOAD_DATE_HEADER } from '../utils/UIMessages';
import { DOWNLOAD_ICON, EDIT_ICON, ELLIPSIS_HORIZONTAL_ICON, TRASH_ALTERNATE_OUTLINE_ICON } from '../utils/UiIcons';

const FileDetailsModal = (props) => {

    const [privileges, setPrivileges] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [confirmVisibility, setConfirmVisibility] = useState(false);
    const [openNotFoundDeleteConfirm, setOpenNotFoundDeleteConfirm] = useState(false);

    //React Table V8 Columns
    const columnsRender = () => {
        const columns = [
            {
                header: () => <div>{ID_HEADER()}</div>,
                accessorFn: d => String(d.id),
                id: 'id',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{NAME_HEADER()}</div>,
                accessorKey: 'name',
                id: 'name',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{FILE_NAME_HEADER()}</div>,
                accessorKey: 'fileName',
                id: 'fileName',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{FILE_SIZE_HEADER()}</div>,
                accessorFn: d => formatBytes(d.fileSize),
                id: 'fileSize',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{UPLOAD_DATE_HEADER()}</div>,
                accessorKey: 'uploadTime',
                id: 'uploadTime',
                cell: info => (<span>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
            },
            {
                header: () => <div>{UPLOADER_HEADER()}</div>,
                accessorKey: 'uploader',
                id: 'uploader',
                cell: info => (<span>{info.getValue() ?? NO_NAME_LABEL()}</span>)
            }
        ];

        ([
            PrivilegeConstants.FILE_MANAGEMENT.DOWNLOAD,
            PrivilegeConstants.FILE_MANAGEMENT.EDIT,
            PrivilegeConstants.FILE_MANAGEMENT.DELETE
        ].some(privilege => privileges.includes(privilege))) &&
            columns.push({
                header: () => <div>{ACTIONS_HEADER()}</div>,
                accessorKey: 'id',
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                cell: () => (<span>
                    {privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.LIST) &&
                        <Dropdown
                            icon={ELLIPSIS_HORIZONTAL_ICON}
                            button
                            labeled
                            text={ACTIONS_HEADER()}
                            className='icon'
                        >
                            <Dropdown.Menu style={{ zIndex: 90 }}>
                                {(privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.LIST) && privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.DOWNLOAD)) &&
                                    <Dropdown.Item onClick={() => handleDownloadFile(selectedFile)}>
                                        <Icon name={DOWNLOAD_ICON} />{DOWNLOAD_BUTTON()}
                                    </Dropdown.Item>
                                }
                                {(privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.LIST) && privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.EDIT)) &&
                                    <Dropdown.Item onClick={() => handleNameEdit()}>
                                        <Icon name={EDIT_ICON} />{EDIT_BUTTON()}
                                    </Dropdown.Item>
                                }
                                {(privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.LIST) && privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.DELETE)) &&
                                    <Dropdown.Item onClick={() => setConfirmVisibility(true)}>
                                        <Icon name={TRASH_ALTERNATE_OUTLINE_ICON} />{DELETE_BUTTON()}
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>}
                </span>)
            })
        return columns;
    }


    useEffect(() => {
        setPrivileges(getPrivileges())
        setSelectedFile(props.selectedFile)
    }, [props.selectedFile])

    const handleDeleteFile = async () => {
        try {
            await deleteFile(selectedFile[0].id);
            props.deleteFile(true);
            showSuccess(fileDetailsMessages().DELETE_SUCCESSFULL)
        } catch (err) {
            showError(t(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`))
        } finally {
            setConfirmVisibility(false);
            props.searchFileKeyword('');
            props.onClose();
        }
    }


    const handleDownloadFile = () => {
        setLoading(true);
        downloadFile(parseInt(selectedFile[0].id)).then(res => {
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${selectedFile[0].fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.response.status === HTTP_STATUS_CODES[19].value && privileges.includes(PrivilegeConstants.FILE_DELETE)) {
                setOpenNotFoundDeleteConfirm(true);
            }
            //Because requestType (via axios) is blob, catched error's data is blob as well.
            //In order to show error message on the screen, blob object shoud be converted. Below
            //imported function works for it.
            blobToJson(error, data => {
                showError(`${FAILED_ERROR_MESSAGE()} : ${t(data?.message)}`);
            })
        })
    }


    const handleNameEdit = () => {
        props.onClose();
        props.openFileEditModal(props.selectedFile[0].name, props.selectedFile[0].id)
    }

    return (
        <>
            <Modal
                open={props.detailModalVisibility}
                onClose={props.onClose}
                size='large'
                closeIcon
                closeOnDimmerClick={true}
                centered={false}
            >
                <Modal.Header style={{ fontSize: '1.54rem', textAlign: 'left' }} size={'tiny'}>
                    <div>{fileDetailsMessages().FILE_DETAILS} <br /> </div>
                </Modal.Header>
                <Modal.Content>
                    <div>
                        <ReactTableV8
                            columns={columnsRender()}
                            data={selectedFile}
                            loadingProp={loading}
                            columnwiseFilterableProp={true}
                        />
                    </div>
                </Modal.Content>
            </Modal>
            <Confirm
                open={confirmVisibility}
                content={fileDetailsMessages().CONFIRM_DELETE}
                header={fileDetailsMessages().CONFIRM_HEADER}
                onClose={() =>setConfirmVisibility(false)}
                onCancel={() =>setConfirmVisibility(false)}
                onConfirm={() => handleDeleteFile()}
                confirmButton={CONFIRM_BUTTON()}
                cancelButton={CANCEL_BUTTON()}
            />

            {/* CONFIRM SCREEN: CONFIRM SCREEN for warning in case there does not exist the targeted file in the storage to download */}
            {/* The file might have been deleted from backend stroage manually or by mistake. This CONFIRM SCREEN is for such situations. */}
            <Confirm
                open={openNotFoundDeleteConfirm}
                content={fileDetailsMessages().FILE_NOT_FOUND_CONFIRM_DELETE}
                header={`${fileDetailsMessages().FILE_NOT_FOUND_CONFIRM_HEADER}!`}
                onClose={() => {
                    setOpenNotFoundDeleteConfirm(false);
                }}
                onCancel={() => {
                    setOpenNotFoundDeleteConfirm(false);
                }}
                onConfirm={() => {
                    //parameter is true, meaning that @RequestParam forceDeleteFileFromDb will be true
                    //and mising file's info will be deleted from database as well.
                    handleDeleteFile();
                    setOpenNotFoundDeleteConfirm(false);
                }}
                confirmButton={CONFIRM_BUTTON()}
                cancelButton={CANCEL_BUTTON()}
            />
        </>
    )
}

export default FileDetailsModal
