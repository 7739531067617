import React from 'react';
import { Header, Icon, Segment, Divider, Button } from 'semantic-ui-react';
import {integrationMessages} from '../utils/UIMessages';
import { FOLDER_OPEN_OUTLINE_ICON } from '../utils/UiIcons';
import { getTestRunByProject } from '../api/apiCalls';
import { showError } from '../utils/ToastHelpers';

function ManageProjectsPanel(props) {
    const manageProjects = props.manageProjects;

    const handleProjectClick = async (project) => {
        props.setProjectId(project.id);
        getTestRunByProject(project).then(res => {
            props.setManageTestRuns(res.data.content);
        }).catch(() => showError(integrationMessages().TEST_RUNS_NOT_FETCHED));
    };

    const redirectVisiumManageLogin = () => {
        //Token does not exist so go to Visium Manage for it. . .    
        window.location.href = `${props.visiumManageUrl}/callback/${encodeURIComponent(window.location.origin)}`;
    }

    return (
        <div>
            {localStorage.getItem('manageAccessToken') ?
                <div>
                <h3 className='manage-projects-header'>{integrationMessages().MANAGE_PROJECTS}</h3>
                <Divider /> 
                {
                    (manageProjects && manageProjects.length > 0) ?
                    manageProjects.map((project, index) => {
                        return(
                            <Segment key={index} id='manage-projects-segment' onClick={() => handleProjectClick(project)}>
                                <Header as='h4' style={{marginBotton: '2rem'}}>
                                    <Icon name={FOLDER_OPEN_OUTLINE_ICON} />
                                    <Header.Content className='manage-test-run-text-style'>{project.name}</Header.Content>
                                </Header>
                            </Segment>

                        );
                        
                    }) : <EmptyProjectComponent />
                }
                </div> : <Button id='visiumManageLoginButton' onClick={redirectVisiumManageLogin}>{integrationMessages().LOG_IN_VISIUM_MANAGE}</Button>
            }
        </div>
    );
}

const EmptyProjectComponent = () => {
    return(
        <div>
            {integrationMessages().NO_PROJECTS_AVAIBLE}
        </div>
    )
}

export default ManageProjectsPanel