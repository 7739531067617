import React, {Component} from 'react';
import {Grid, Header, Label, List, Popup} from 'semantic-ui-react';
import { DEVICE_CARD_CATEGORIES } from '../utils/UIMessages';
class DeviceCardCategories extends Component {

    categoryRenderer = categories => {
        if (categories?.length <= 1) {
            return <>
                {categories?.map((c) => (<Label size='tiny' basic key={c.id}>{c.name}</Label>))}
            </>
        } else {
            return <>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Label size='tiny' basic>{categories[0]?.name}</Label>
                    <Popup on='hover'
                        position='bottom center'
                        basic
                        trigger={<Label basic circular style={{ cursor: 'pointer' }}>+{categories?.length - 1}</Label>}>
                        <Header size='tiny' textAlign='left' style={{marginBottom: 15}}>{DEVICE_CARD_CATEGORIES()}</Header>
                        <Grid basic textAlign='left' relaxed>
                            <List celled style={{marginBottom: 11}}>
                            {categories?.map((c) => (
                                <>
                                    <List.Item>{c.name}</List.Item>
                                </>
                            ))}
                            </List>
                        </Grid>
                    </Popup>
                </div>
            </>
        }
    }


    render() {
        return (
            <div>
                {this.categoryRenderer(this.props.categories)}
            </div>
        );
    }
}

export default DeviceCardCategories;