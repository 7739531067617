import { Icon } from 'semantic-ui-react';
import { secondsToDhms } from '../../../../utils/Methods';
import React from 'react';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table'
import { DATE_LABEL, TOTAL_LABEL, USED_HEADER } from '../../../../utils/UIMessages';
import { CALENDAR_ALTERNATE_OUTLINE_ICON } from '../../../../utils/UiIcons';

//New React Table V8 Columns
export const generateColumns = (users, type) => {

    //For creating Columng Groups
    const columnHelper = createColumnHelper();

    const columns = [
        {
            header: () => <div>{DATE_LABEL()}</div>,
            accessorFn: d => type === 'monthly' ? moment(d.dates).format('MM/YYYY') : moment(d.dates).format('DD/MM/YYYY'),
            id: 'date',
            cell: info => (<span className='number'> <Icon name={CALENDAR_ALTERNATE_OUTLINE_ICON} /> {info.getValue()}</span>)
        }
    ]

    for (let i = 0; i < users.length; i++) {
        columns.push(
            columnHelper.group({
                header: () => <div className='react-table-centered-header'>{users[i]?.fullName}</div>,
                footer: info => info.column.id,
                id: i.toString(),
                columns: [
                    columnHelper.accessor(d => d?.[users[i].userName]?.usedReservation
                        , {
                            header: () => <div>{USED_HEADER()}</div>,
                            id: i.toString(),
                            cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
                        }),
                    columnHelper.accessor(d => d?.[users[i].userName]?.totalReservation
                        , {
                            header: () => <div>{TOTAL_LABEL()}</div>,
                            id: (i + 100).toString(),
                            cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
                        })
                ]
            })
        )
    }
    return columns;
}

export const generateUserResData = (data, i) => {
    let obj = {}
    for (let j = 0; j < data.users.length; j++) {
        obj = Object.assign(obj, {
            [data.users[j].userName]: {
                userName: data.users[j].userName,
                fullName: data.users[j].fullName,
                usedReservation: data.usedReservation[j][i],
                totalReservation: data.totalReservation[j][i]
            }
        })
    }
    return obj
}
