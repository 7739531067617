import {START_LOADING_FULLSCREEN, END_LOADING_FULLSCREEN} from "../actions/types";

const initialState = {
    loading: false,
    loadingContent: ""
};

export default (state, action) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case START_LOADING_FULLSCREEN:
            return {
                loading: true,
                loadingContent: action.loadingContent
            };
        case END_LOADING_FULLSCREEN:
            return {
                loading: false
            };
        default:
            return state
    }

}
