import React, {Component} from 'react';

class SwipeElements extends Component {

    state = {
        widthRatio: 1,
        heightRatio: 1,
        firstUpdate: true,
        onSwipe: false
    }

    componentDidUpdate(){
        if(this.swipeStarted() && this.state.firstUpdate){
            // save proportions to calculate exact location of starting point if window size changes
            this.setState({
                widthRatio: this.props.swipeStart[0] / this.props.imageBounds[0],
                heightRatio: this.props.swipeStart[1] / this.props.imageBounds[1],
                firstUpdate: false,
                onSwipe: true
            })
        }
        // reset state values when current swipe is done
        if(this.swipeEnded() && this.state.onSwipe){
            this.setState({
                widthRatio: 1,
                heightRatio: 1,
                firstUpdate: true,
                onSwipe: false
            })
        }
    }

    swipeStarted = () => {
        return this.props.swipeStart.length > 0 && this.props.swipeEnd.length === 0
    }

    swipeCompleted = () => {
        return this.props.swipeStart.length > 0 && this.props.swipeEnd.length > 0
    }

    swipeEnded = () => {
        return this.props.swipeStart.length === 0 && this.props.swipeEnd.length === 0
    }

    render(){
        return <svg className={'swipeSvg'}>
            {this.swipeStarted() && <circle
                cx={this.props.imageBounds[0] * this.state.widthRatio}  
                cy={this.props.imageBounds[1]* this.state.heightRatio}
              />}
              {this.swipeCompleted() && <line
                x1={this.props.imageBounds[0] * this.state.widthRatio}
                y1={this.props.imageBounds[1]* this.state.heightRatio} 
                x2={this.props.swipeEnd[0]} 
                y2={this.props.swipeEnd[1]} 
              />}
            </svg>;
    }
}

export default SwipeElements;