import React from 'react'
import { Icon } from 'semantic-ui-react';
import { DEFAULT_PAGINATION_PROPS } from '../../utils/Constants';
import {
    ANGLE_DOUBLE_RIGHT_ICON, ANGLE_DOUBLE_LEFT_ICON,
    ANGLE_LEFT_ICON,
    ANGLE_RIGHT_ICON
} from '../../utils/UiIcons';
import { SHOW_BUTTON, PAGE_LABEL } from '../../utils/UIMessages';
export const PaginationV8 = ({ table}) => {
    return (<>
        <div className='table-pagination-outframe'>
            {/* Left Side of Pagination */}
            <div className='table-pagination-left-part'>
                <select
                    id='pagination-bounce-dropdown-selection'
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value));
                    }}
                >
                    {DEFAULT_PAGINATION_PROPS.DEFAULT_PAGE_SIZE_OPTIONS.map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {SHOW_BUTTON()} {pageSize}
                        </option>
                    ))}
                </select>
            </div>

            <span className='table-pagination-page'>
                {`${PAGE_LABEL()} : `}
                <input
                    type='number'
                    max={table.getPageCount()}
                    min={1}
                    value={table.getState().pagination.pageIndex + 1}
                    onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        table.setPageIndex(page);
                    }}
                    id='table-pagination-go-to-page-input'
                />
            </span>

            {/* Right Side of Pagination */}
            <table className='pagination-change-buttons-table' border='1'>
                <tbody>
                    <tr>
                        <td className='pagination-change-buttons-table'>
                            <button
                                className='table-pagination-button'
                                onClick={() => {
                                    table.setPageIndex(0);
                                }}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <Icon name={ANGLE_DOUBLE_LEFT_ICON} />
                            </button>
                        </td>
                        <td className='pagination-change-buttons-table'>
                            <button
                                className='table-pagination-button'
                                onClick={() => {
                                    table.setPageIndex(table.getState().pagination.pageIndex - 1);
                                }}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <Icon name={ANGLE_LEFT_ICON} />
                            </button>
                        </td>
                        <td className='pagination-change-buttons-table'>
                            <span className='table-pagination-page'>
                                {table.getState().pagination.pageIndex + 1}
                                {` / ${table.getPageCount()}`}
                            </span>
                        </td>
                        <td className='pagination-change-buttons-table'>
                            <button
                                className='table-pagination-button'
                                onClick={() => {
                                    table.setPageIndex(table.getState().pagination.pageIndex + 1);
                                }}
                                disabled={!table.getCanNextPage()}
                            >
                                <Icon name={ANGLE_RIGHT_ICON} />
                            </button>
                        </td>
                        <td className='pagination-change-buttons-table'>
                            <button
                                className='table-pagination-button'
                                onClick={() => {
                                    table.setPageIndex(table.getPageCount() - 1);
                                }}
                                disabled={!table.getCanNextPage()}
                            >
                                <Icon name={ANGLE_DOUBLE_RIGHT_ICON} />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* ABOVE CODE SEGMENT: Pagination part -------------------------------------------------------*/}
        </div>
    </>);
}

export const TableWrapper = ({ children }) => {
    return (
        <div className='react-table-wrapper'>
            {children}
        </div>
    )
}
