import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { validSessionName } from "../../utils/Constants";
import { Button, Dropdown, Form, Header, Icon, Modal, Segment, Popup, Checkbox } from "semantic-ui-react";
import { APPLICATION_HEADER, DEVICE_HEADER, DEVICE_LOGS_HEADER, manualTestSessionModalMessages, SEARCH_APPLICATION_PLACEHOLDER, SEARCH_ID_PLACEHOLDER, SESSION_NAME_HEADER } from '../../utils/UIMessages';
import { PLAY_ICON } from '../../utils/UiIcons';

class ManualTestSessionModal extends Component {
    render() {
        return (


            <Modal open={this.props.modalVisibility} onClose={this.props.handleModalClose} closeIcon
                closeOnDimmerClick={true}
                size={"tiny"}>

                <Header id="sessionModalTitle" style={{ textAlign: "center" }}>{manualTestSessionModalMessages().MODAL_HEADER}</Header>
                <Modal.Content>
                    <Segment basic loading={this.props.loading}>
                        <Form>
                            <Form.Field required>
                                <label>{SESSION_NAME_HEADER()}</label>
                                <Form.Group inline >
                                    <Form.Input
                                        id="sessionName"
                                        width={16}
                                        fluid
                                        name={"name"}
                                        placeholder={SESSION_NAME_HEADER()}
                                        value={this.props.testSessionField.name}
                                        onChange={this.props.onHandleChange}
                                        error={(this.props.testSessionField.name?.match(validSessionName) === null)}
                                    />
                                </Form.Group>
                            </Form.Field>
                            <Form.Field required>
                                <label>{DEVICE_HEADER()}</label>
                                <Dropdown style={{ wordBreak: "break-all" }} fluid selection search clearable
                                    id="device"
                                    name={'udId'}
                                    placeholder={SEARCH_ID_PLACEHOLDER()}
                                    options={this.props.allDevices}
                                    noResultsMessage={manualTestSessionModalMessages().NO_AVAILABLE_DEVICE_MESSAGE}
                                    value={this.props.testSessionField.udId} onChange={this.props.handleDropdown} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{APPLICATION_HEADER()}</label>
                                <Dropdown style={{ wordBreak: "break-all" }} fluid selection search clearable
                                    id="application"
                                    name={'appId'}
                                    placeholder={SEARCH_APPLICATION_PLACEHOLDER()}
                                    options={this.props.allAppsDropdown}
                                    noResultsMessage={manualTestSessionModalMessages().NO_AVAILABLE_APP_MESSAGE}
                                    value={this.props.testSessionField.appId} 
                                    onChange={this.props.handleDropdown}
                                    disabled={!this.props.testSessionField.udId} />
                            </Form.Field>
                            <Form.Field>
                                <label>{manualTestSessionModalMessages().RECORDS_LABEL}</label>
                                <Form.Group>
                                    <Form.Field>
                                        <Popup
                                            content={manualTestSessionModalMessages().RECORD_VIDEO_MESSAGE}
                                            trigger={
                                                <Segment compact>
                                                    <Checkbox
                                                        label={manualTestSessionModalMessages().VIDEO_RECORD_LABEL}
                                                        onChange={this.props.onHandleChange}
                                                        checked={this.props.testSessionField.videoRecord}
                                                        fluid
                                                        name="videoRecord"
                                                    />
                                                </Segment>}
                                            on={"hover"}
                                            position={"right center"}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Popup
                                            content={manualTestSessionModalMessages().RECORD_DEVICE_LOG_MESSAGE}
                                            trigger={
                                                <Segment compact>
                                                    <Checkbox
                                                        label={DEVICE_LOGS_HEADER()}
                                                        onChange={this.props.onHandleChange}
                                                        checked={this.props.testSessionField.deviceLog}
                                                        fluid
                                                        name="deviceLog"
                                                    />
                                                </Segment>}
                                            on={"hover"}
                                            position={"right center"}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Popup
                                            content={manualTestSessionModalMessages().RECORD_NETWORK_TRAFFIC_MESSAGE}
                                            trigger={
                                                <Segment compact>
                                                    <Checkbox
                                                        label={manualTestSessionModalMessages().NETWORK_TRAFFIC_LABEL}
                                                        onChange={this.props.onHandleChange}
                                                        checked={this.props.testSessionField.trafficRecord}
                                                        fluid
                                                        name="trafficRecord"
                                                    />
                                                </Segment>}
                                            on={"hover"}
                                            position={"right center"}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form.Field>
                            <Form.Field>
                                <label>{manualTestSessionModalMessages().OPTIONS_LABEL}</label>
                                <Form.Group>
                                    <Form.Field>
                                        <Popup
                                            content={manualTestSessionModalMessages().UNINSTALL_APP_MESSAGE}
                                            trigger={
                                                <Segment compact>
                                                    <Checkbox
                                                        label={manualTestSessionModalMessages().UNINSTALL_APP_LABEL}
                                                        onChange={this.props.onHandleChange}
                                                        checked={this.props.testSessionField.deleteApp}
                                                        disabled={!this.props.testSessionField.appId}
                                                        fluid
                                                        name="uninstallApp"
                                                    />
                                                </Segment>}
                                            on={"hover"}
                                            position={"right center"}
                                        />
                                    </Form.Field>
                                </ Form.Group>
                            </Form.Field>
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button
                            id="startSession"
                            icon labelPosition="right" color="green"
                            onClick={this.props.onClickStartSession}
                            disabled={!this.props.testSessionField.name
                                || this.props.testSessionField.name?.match(validSessionName) === null
                                || !this.props.testSessionField.appId
                                || !this.props.testSessionField.udId
                                || this.props.loading} >
                            <Icon
                                name={PLAY_ICON} /> {manualTestSessionModalMessages().START_SESSION_BUTTON}
                        </Button>
                    </Button.Group>
                </Modal.Actions>

            </Modal >

        )
    }
}

export default withTranslation()(ManualTestSessionModal);
