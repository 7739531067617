import React, {Component} from 'react';
import {Grid, Table} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import packageJSON from "../../../../package.json";
import {
    getApplicationVersion
} from '../../../api/apiCalls'
import { versionMessages, VERSIONS_LABEL, VERSION_HEADER } from '../../../utils/UIMessages';


class Versions extends Component {

    state={
        backVersion:"",
        frontVersion:packageJSON.version
    }


    componentDidMount() {
        //axios.get("/api/version")
        getApplicationVersion().then(res => {
            this.setState({backVersion: res.data.version})
        })
    }

    render() {
        return (
            <div>
                <div className="main-right-header">
                    <div>
                        <h2> {VERSIONS_LABEL()}</h2>
                        <small>  {versionMessages().VERSION_SUB_HEADER}</small>
                    </div>

                </div>

                    <Grid.Row className='shadow' style={{backgroundColor: '#ffffff', marginTop: 14}} columns={1}>
                        <Grid.Column>
                            <div>
                                <>
                                    <Table celled >
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>{versionMessages().FRONTEND_LABEL} {VERSION_HEADER()}</Table.Cell>
                                                <Table.Cell>v{this.state.frontVersion}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{versionMessages().BACKEND_LABEL} {VERSION_HEADER()}</Table.Cell>
                                                <Table.Cell>{this.state.backVersion}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </>

                            </div>
                        </Grid.Column>

                    </Grid.Row>

            </div>
        );
    }
}

export default withTranslation()(Versions);