import React, { Component } from 'react';
import { Grid, Header, Segment } from "semantic-ui-react";
import { withTranslation } from 'react-i18next'
import DateRangeDropdown from "../../../../components/DateRangeDropdown";
import { ResponsiveBar } from "@nivo/bar";
import {
    formatTickValues,
    formatXValues,
    dataRefresher,
    secondsToDhms,
    secondsToDhmsNormal,
    deviceIdRenderer
} from "../../../../utils/Methods";
import { columnsSelector1, columnsSelector2 } from "./TableColumnsDU";
import axios from "axios";
import { showError } from "../../../../utils/ToastHelpers";
import ReactTableV8 from '../../../../components/ReactTableV8/ReactTableV8'
import { AUTOMATION_LABEL, chartsDeviceUsageMessages, DATE_LABEL, DEVELOPMENT_LABEL, DEVICES_HEADER, FAILED_ERROR_MESSAGE, MANUAL_LABEL, TOTAL_LABEL, USAGE_LABEL } from '../../../../utils/UIMessages';

class ChartsDu extends Component {

    state = {
        componentDidUpdateCheck: false,
        dataForBarChart: [],
        startDate: new Date(),
        endDate: new Date(),
        sizeValue: 10,
        visibility: false,
        loading: false,
        language: localStorage.getItem('lang') || 'en'
    }

    convertDataForBarChart = data => {
        let dataForBarChart = [];
        const xLine = this.props.type === "devices" ? "device" : "date"
        const lengthXLine = this.props.type === "devices" ? data?.devices?.length : data?.dates?.length
        for (let i = 0; i < lengthXLine; i++) {
            if (this.props.type === 'devices') {
                dataForBarChart.push({
                    [xLine]: deviceIdRenderer(data.devices[i]?.udId),
                    userRoles: data?.devices[i]?.userRoles,
                    ['brand']: data.devices[i]?.brand,
                    ['model']: data.devices[i]?.model,
                    [AUTOMATION_LABEL()]: data?.automationUsages[i],
                    [MANUAL_LABEL()]: data?.manualUsages[i],
                    [DEVELOPMENT_LABEL()]: data?.developmentUsages[i],
                    [TOTAL_LABEL()]: data?.totalUsages[i],
                });
            } else {
                dataForBarChart.push({
                    [xLine]: data.dates[i],
                    [AUTOMATION_LABEL()]: data?.automationUsages[i],
                    [MANUAL_LABEL()]: data?.manualUsages[i],
                    [DEVELOPMENT_LABEL()]: data?.developmentUsages[i],
                    [TOTAL_LABEL()]: data?.totalUsages[i],
                });
            }
        }
        this.setState({ dataForBarChart })
        let startDate = this.props.type === "devices" ? new Date(data?.startDate) : new Date(data?.dates[0])
        let endDate = this.props.type === "devices" ? new Date(data?.endDate) : new Date(data?.dates[data?.dates?.length - 1])
        this.setState({ startDate, endDate })
        if (this.props.type === "devices") {
            let sizeValue = data?.devices?.length
            this.setState({ sizeValue })
        }
        this.setState({ visibility: true })
    }

    typeCheck = async (type, resType, params = "") => {
        this.setState({ loading: true });
        try {
            let data = []
            if (type === "devices") {
                data = (await axios.get("/api/reports/device-usages" + params))?.data
            }
            if (type === "daily") {
                if (resType === "count") {
                    data = (await axios.get("/api/reports/device-usage-type-daily" + params))?.data
                }
                if (resType === "second") {
                    data = (await axios.get("/api/reports/device-usage-type-duration-daily" + params))?.data
                }
            }
            if (type === "monthly") {
                if (resType === "count") {
                    data = (await axios.get("/api/reports/device-usage-type-monthly" + params))?.data
                }
                if (resType === "second") {
                    data = (await axios.get("/api/reports/device-usage-type-duration-monthly" + params))?.data
                }
            }
            this.convertDataForBarChart(data)
        } catch (err) {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        } finally {
            this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.typeCheck(this.props.type, this.props.resType)
    }

    barHeightFixer = () => {
        const maxValue = Math.max(...this.state.dataForBarChart.map(o => { return o[TOTAL_LABEL()] }))
        return maxValue / 1.15
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.setState({
                componentDidUpdateCheck : !this.state.componentDidUpdateCheck,
                // sorted1: [],
                // sorted2: [],
                visibility: false
             }, () => {
                this.typeCheck(this.props.type, this.props.resType)
            })
        }
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.typeCheck(this.props.type, this.props.resType))
        )
    }

    onChangeDateComponent = link => {
        const params = "?" + link
        this.setState({ visibility: false }, () => {
            this.typeCheck(this.props.type, this.props.resType, params)
        })
    }

    getMainHeader = () => {
        if (this.props.type === "daily" || this.props.type === "monthly") {
            if (this.props.resType === "second") {
                if (this.props.type === "daily") {
                    return chartsDeviceUsageMessages().USAGE_DURATIONS_BY_DATE;
                } else {
                    return chartsDeviceUsageMessages().USAGE_DURATIONS_BY_MONTH;
                }
            } else {
                if (this.props.type === "daily") {
                    return chartsDeviceUsageMessages().USED_DEVICES_COUNT_BY_DATE;
                } else {
                    return chartsDeviceUsageMessages().USED_DEVICES_COUNT_BY_MONTH;
                }
            }
        } else {
            return chartsDeviceUsageMessages().TOP_DEVICES_BY_USAGE;
        }
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <DateRangeDropdown
                                isOrderByVisibility={this.props.type === "devices"}
                                isSizeVisibility={this.props.type === "devices"}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                type={this.props.type}
                                sizeValue={this.state.sizeValue}
                                callback={(link) => {
                                    this.onChangeDateComponent(link)
                                }}
                                isUserRolesVisibility={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered columns={1}>
                        <Grid.Column>
                            <Header textAlign={"center"}>
                                {this.getMainHeader()}
                            </Header>
                            <Segment placeholder style={{ height: this.props.type === "devices" ? 620 : 450 }} loading={this.state.loading}>
                                {<ResponsiveBar
                                    padding={0.4}
                                    data={this.state.dataForBarChart}
                                    keys={[MANUAL_LABEL(), AUTOMATION_LABEL(), DEVELOPMENT_LABEL(), TOTAL_LABEL()]}
                                    indexBy={this.props.type === "devices" ? "device" : "date"}
                                    margin={{
                                        top: 50,
                                        right: 30,
                                        bottom: (this.props.type === "devices") ? 160 : 90,
                                        left: (this.props.type === "devices" || this.props.resType === "second") ? 160 : 70
                                    }}
                                    groupMode="grouped"
                                    maxValue={this.barHeightFixer()}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={{ scheme: 'pastel1' }}
                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        format: this.props.type === "devices" ? tick => tick : (tick) => formatXValues(this.props.type, tick),
                                        tickValues: this.props.type === "devices" ? undefined : formatTickValues(this.props.type),
                                        tickRotation: -55,
                                        legend: this.props.type === "devices" ? DEVICES_HEADER() : DATE_LABEL(),
                                        legendPosition: 'middle',
                                        legendOffset: (this.props.type === "devices") ? 150 : 70,
                                    }}
                                    axisLeft={{
                                        format: (tick) => {
                                            return (this.props.type === "devices" || this.props.resType === "second") ? secondsToDhmsNormal(tick) : tick
                                        },
                                        tickSize: 5,
                                        tickPadding: 10,
                                        tickRotation: 0,
                                        legend: (this.props.type === "devices" || this.props.resType === "second") ? USAGE_LABEL() : chartsDeviceUsageMessages().DEVICE_COUNT_LABEL,
                                        legendPosition: 'middle',
                                        legendOffset: (this.props.type === "devices" || this.props.resType === "second") ? -135 : -50
                                    }}
                                    label={() => null}
                                    tooltip={(el) => {
                                        return (
                                            <span key={el.id}>
                                                {el.id} - {this.props.type === "devices" ? <>{el.data?.brand} {el.data?.model} {deviceIdRenderer(el.data?.device)}</> : <>{el.indexValue}</>} : {this.props.type === "devices" ? <>{secondsToDhms(el.value)}</> : this.props.resType === "second" ? <>{chartsDeviceUsageMessages().DEVICE_USAGE_DURATION_LABEL} : {secondsToDhms(el.value)}</> : <>{chartsDeviceUsageMessages().DEVICE_COUNT_LABEL}
                                                    : {el.value}</>}
                                            </span>
                                        )
                                    }}

                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: "bottom-right",
                                            direction: "row",
                                            justify: false,
                                            translateX: 45,
                                            translateY: (this.props.type === "devices") ? 150 : 80,
                                            itemsSpacing: 8,
                                            itemDirection: "left-to-right",
                                            itemWidth: 100,
                                            itemHeight: 0,
                                            itemOpacity: 0.75,
                                            symbolSize: 12,
                                            symbolShape: "circle",
                                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                                            effects: [
                                                {
                                                    on: "hover",
                                                    style: {
                                                        itemBackground: "rgba(0, 0, 0, .03)",
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]

                                        }
                                    ]}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                />}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Segment placeholder style={{ minHeight: '22rem' }}>
                                <ReactTableV8
                                    data={this.state.dataForBarChart}
                                    columns={columnsSelector1(this.props.type, this.props.resType)}
                                    onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                    loadingProp={this.state.loading}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment placeholder style={{ minHeight: '22rem' }}>
                                <ReactTableV8
                                    data={this.state.dataForBarChart}
                                    columns={columnsSelector2(this.props.type)}
                                    onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                    loadingProp={this.state.loading}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(ChartsDu);
