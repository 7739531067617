import React, {Component} from 'react';
import {Button, Checkbox, Confirm, Form, Header, Icon, Input, List, Modal} from "semantic-ui-react";
import {Flip, toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import {showError} from "../../../utils/ToastHelpers";
import {
    getAllRoles,
    getAllPrivileges,
    createRole,
    updateRole,
    deleteRole,
    getPrivilegeGroups
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { ACTIONS_HEADER, CANCEL_BUTTON, CONFIRM_BUTTON, FAILED_ERROR_MESSAGE, NOT_FETCH_ROLES_ERROR_MESSAGE, roleMessages, ROLES_HEADER, SUBMIT_BUTTON, SUCCESS_MESSAGE } from '../../../utils/UIMessages';
import { EDIT_ICON, X_ICON } from '../../../utils/UiIcons';

class RolePage extends Component {

    state = {
        pageSize: 10,
        page: 0,
        allRoles: [],
        allPrivileges: [],
        modalVisibility: false,
        roleName: "",
        roleDescription: "",
        checkedPrivileges: [],
        roleId: "",
        confirmVisibility: false,
        requestType: "",
        groupedPrivileges: [],
        loadingRoles: false

    }

    //React Table V8 Columns
    columnsRender = () => [
        {
            header: () => <div>{roleMessages().ROLE_NAME_LABEL}</div>,
            accessorKey: 'name',
            id: 'name',
            cell: info => (<span className='number' style={{ fontWeight: 'bold' }}>{info.getValue()}</span>)
        },
        {
            header: () => <div>{roleMessages().ROLE_DESCRIPTION_LABEL}</div>,
            accessorKey: 'description',
            id: 'description',
            cell: info => {
              return (
                <span>
                  {(info.getValue()?.trim().length === 0 || info.getValue() === null || info.getValue() === undefined) ? roleMessages().NO_ENTRY_MSG : this.props.t(info.getValue())}
                </span>
              );
            }
        },
        {
            header: () => <div>{roleMessages().PRIVILEGES_HEADER}</div>,
            accessorKey: 'privileges',
            id: 'privileges',
            cell: info => (<span className='number'>{info.getValue().length}</span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'roleId',
            id: 'roleId',
            enableSorting: false,
            enableColumnFilter: false,
            cell: info => {
                return (<span className='number'>
                    <Button
                        size='tiny'
                        onClick={() => this.openFormModal(info.row.original)}
                        icon={<Icon name={EDIT_ICON} />} /> <Button
                        disabled={info.row.original.name === 'ROLE_ADMIN' || info.row.original.name === 'ROLE_USER'} size='tiny'
                        color='red'
                        onClick={() => {
                            this.openConfirm(info.getValue())
                        }}
                        icon={<Icon name={X_ICON}/>} />
                </span>)
            }
        }
    ];

    onHandleChange = (e, {value, name}) => this.setState(prevState => ({...prevState, [name]: value}))

    onHandleCheckbox = (e, data) => {
        const checkedPrivileges = [...this.state.checkedPrivileges];
        if (data.checked) {
            checkedPrivileges.push({"id": data.value})
        } else {
            const index = checkedPrivileges.findIndex((ch) => ch.id === data.value);
            checkedPrivileges.splice(index, 1);
        }
        this.setState({checkedPrivileges});
    }


    openFormModal = (fields) => {
        if (fields) {
            this.setState({
                roleName: fields.name,
                roleDescription: fields.description,
                checkedPrivileges: [...fields.privileges],
                requestType: "put",
                roleId: fields.roleId
            })
        } else {
            this.setState({roleName: "",roleDescription: "", checkedPrivileges: [], requestType: "post", roleId: ""})
        }
        this.setState({modalVisibility: true})
    }

    openConfirm = (roleId) => {
        this.setState({roleId}, () => {
            this.setState({confirmVisibility: true})
        })
    }

    onDeleteRole = () => {
        //axios.delete('/api/manage/delete-role?roleId=' + this.state.roleId)
        deleteRole(this.state.roleId).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                delay: 1000,
                transition: Flip
            })
            this.setState({confirmVisibility: false}, () => {
                this.getResources()
            })
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + this.props.t(err?.response?.data?.message ?? err.toString()))
        })

    }

    onSubmitRole = () => {
        let data = {
            "name": this.state.roleName,
            "description": this.state.roleDescription,
            "privileges": this.state.checkedPrivileges
        }
        if (this.state.requestType === "post") {
            createRole(data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    transition: Flip,
                    delay: 1000
                })
                this.setState({modalVisibility: false}, () => {
                    this.getResources()
                })
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + this.props.t(err?.response?.data?.message ?? err.toString()))
            })
        }
        if (this.state.requestType === "put") {
            //axios.put("/api/manage/update?roleId=" + this.state.roleId, data)
            updateRole(this.state.roleId,data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    transition: Flip,
                    delay: 1000
                })
                this.setState({modalVisibility: false}, () => {
                    this.getResources()
                })
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + this.props.t(err?.response?.data?.message ?? err.toString()))
            })
        }
    }

    getResources = () => {
        this.setState({loadingRoles: true});
        //axios.get("/api/manage/roles")
        getAllRoles().then(res => {
            this.setState({allRoles: res.data})
        }).catch(err => {
            showError(NOT_FETCH_ROLES_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({loadingRoles: false}));
        //axios.get("/api/manage/privilege-groups")
        getPrivilegeGroups().then(res => {
            this.setState({groupedPrivileges: res.data})
        }).catch(err => {
            showError(roleMessages().NOT_FETCH_PRIVILEGE_GROUP_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString())
        })
        //axios.get("/api/manage/privileges")
        getAllPrivileges().then(res => {
            this.setState({allPrivileges: res.data})
        }).catch(err => {
            showError(roleMessages().NOT_FETCH_PRIVILEGES_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString())
        })
    }

    findLabelForCheckbox = (id) => {
        const privilege = this.state.allPrivileges.find(privilege => privilege.id === id.id)
        return privilege?.name
    }

    findDescriptionForCheckbox = (id) => {
        const privilege = this.state.allPrivileges.find(privilege => privilege.id === id.id)
        return privilege?.description
    }

    componentDidMount() {
        this.getResources()
    }


    render() {
        return (
            <>
                <div className='main-right-header'>
                    <div>
                        <h2> {ROLES_HEADER()}</h2>
                        <small>  {roleMessages().ROLES_SUB_HEADER}</small>
                    </div>
                    <div className='main-right-buttons'>
                        <Button primary icon='plus' content={roleMessages().ADD_NEW_ROLE_BUTTON}
                                onClick={() => this.openFormModal()}/>
                    </div>

                </div>            
                <ReactTableV8
                    data={this.state.allRoles}
                    columns={this.columnsRender()}
                    loadingProp={this.state.loadingRoles}
                />
                <Modal closeIcon closeOnDimmerClick={true} open={this.state.modalVisibility}
                       onClose={() => this.setState({modalVisibility: false})}
                       size='small'>
                    <Header content={roleMessages().ROLE_PROPERTIES_MODAL_HEADER}/>
                    <Modal.Content>
                        <Form>
                            <Form.Field required>
                                <label>{roleMessages().ROLE_NAME_LABEL}</label>
                                <Input
                                    fluid
                                    name="roleName"
                                    type={"text"}
                                    disabled={(this.state?.roleName.toLowerCase() === "role_user" && (!!this.state.roleId)) || ((this.state?.roleName.toLowerCase() === "role_admin" && (!!this.state.roleId)))}
                                    onChange={this.onHandleChange}
                                    value={this.state.roleName}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{roleMessages().ROLE_DESCRIPTION_LABEL}</label>
                                <Input
                                    fluid
                                    name='roleDescription'
                                    type={'text'}
                                    disabled={(this.state?.roleName.toLowerCase() === 'role_user' && (!!this.state.roleId)) || ((this.state?.roleName.toLowerCase() === 'role_admin' && (!!this.state.roleId)))}
                                    onChange={this.onHandleChange}
                                    value={this.state.roleDescription}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Header as='h4' block>
                                    {roleMessages().PRIVILEGES_HEADER}
                                </Header>
                                <List divided relaxed="very" style={{marginLeft: 10, marginTop: 10}}>
                                    {this.state.groupedPrivileges.map((privilege, i) => {
                                        return <React.Fragment key={i}>
                                            <Header as="h5">{this.props.t(privilege.name)}</Header>
                                            {privilege.privilegeList.map((id, i) => {
                                                return <React.Fragment key={i}>
                                                    <List.Item>
                                                        <List.Header>
                                                            <Checkbox
                                                                disabled={(this.state?.roleName.toLowerCase() === "role_user" && (!!this.state.roleId)) || ((this.state?.roleName.toLowerCase() === "role_admin" && (!!this.state.roleId)))}
                                                                checked={this.state.checkedPrivileges.some((ch) => ch.id === id.id)}
                                                                onChange={(e, data) => this.onHandleCheckbox(e, data)}
                                                                label={this.props.t(this.findLabelForCheckbox(id))}
                                                                value={id.id}
                                                            />
                                                        </List.Header>
                                                        <List.List>
                                                            <List.Item style={{marginLeft: 20, marginTop: -10}}>
                                                                <small>{this.props.t(this.findDescriptionForCheckbox(id))}</small>
                                                            </List.Item>
                                                        </List.List>
                                                    </List.Item>
                                                </React.Fragment>
                                            })}
                                        </React.Fragment>
                                    })}
                                </List>
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            disabled={((this.state?.roleName.toLowerCase() === 'role_user' || 
                            this.state?.roleName.toLowerCase() === 'role_admin')) || 
                            (this.state.checkedPrivileges.length === 0 || !this.state.roleName?.trim())}
                            color="green" onClick={() => {
                            this.onSubmitRole()
                        }}>{SUBMIT_BUTTON()}</Button>
                    </Modal.Actions>
                </Modal>
                <Confirm
                    content={roleMessages().DELETE_CONFIRM}
                    open={this.state.confirmVisibility}
                    onCancel={() => {
                        this.setState({confirmVisibility: false})
                    }}
                    onConfirm={this.onDeleteRole}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />

            </>
        );
    }
}

export default withTranslation()(RolePage);