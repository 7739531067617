import React, {Component} from 'react';
import HighlighterRect from './HighlighterRect';

class HighlighterRects extends Component {

    getElements (source) {
        const elementsByOverlap = this.buildElementsWithProps(source, null, [], {});
        let elements = [];
    
        // Adjust overlapping elements
        for (const key of Object.keys(elementsByOverlap)) {
          if (elementsByOverlap[key].length > 1) {
            const {centerX, centerY} = elementsByOverlap[key][0].properties;
    
            // Create new element obj which will be a +/- centroid
    
            const element = {
              type: 'expand',
              element: null,
              parent: null,
              properties: {
                left: null,
                top: null,
                width: null,
                height: null,
                centerX,
                centerY,
                angleX: null,
                angleY: null,
                path: key,
                keyCode: key,
                container: null,
                accessible: null
              }
            };
            elements = [...elements, element,
              ...this.updateOverlapsAngles(elementsByOverlap[key], key)];
          } else {
            elements.push(elementsByOverlap[key][0]);
          }
        }
    
        return elements;
      }

    updateOverlapsAngles (elements, key) {
        const steps = elements.length;
        for (let step = 0; step < steps; step++) {
          const [el, elProps] = [elements[step], elements[step].properties];
          el.type = 'overlap';
          elProps.keyCode = key;
          elProps.angleX = Math.cos(2 * Math.PI * (step / steps));
          elProps.angleY = Math.sin(2 * Math.PI * (step / steps));
        }
        return elements;
    }

    parseCoordinates (element) {
        let {bounds, x, y, width, height} = element.attributes || {};
  
        if (bounds) {
          let boundsArray = bounds.split(/\[|\]|,/).filter((str) => str !== '');
          const x1 = parseInt(boundsArray[0], 10);
          const x2 = parseInt(boundsArray[2], 10);
          const y1 = parseInt(boundsArray[1], 10);
          const y2 = parseInt(boundsArray[3], 10);
          return { x1, y1, x2, y2 };
        } else if (x) {
          x = parseInt(x, 10);
          y = parseInt(y, 10);
          width = parseInt(width, 10);
          height = parseInt(height, 10);
          return {x1: x, y1: y, x2: x + width, y2: y + height};
        } else {
          return {};
        }
      }

    buildElementsWithProps (source, prevElement, elements, overlaps) {
        if (!source) {
          return {};
        }
        const scaleRatio = this.props.ratio;
        const {x1, y1, x2, y2} = this.parseCoordinates(source);
        const xOffset = this.highlighterXOffset || 0;
        const centerPoint = (v1, v2) =>
          Math.round(v1 + ((v2 - v1) / 2)) / scaleRatio;
        const obj = {
          type: 'centroid',
          element: source,
          parent: prevElement,
          properties: {
            left: x1 / scaleRatio + xOffset,
            top: y1 / scaleRatio,
            width: (x2 - x1) / scaleRatio,
            height: (y2 - y1) / scaleRatio,
            centerX: centerPoint(x1, x2) + xOffset,
            centerY: centerPoint(y1, y2),
            angleX: null,
            angleY: null,
            path: source?.path,
            container: false,
            keyCode: null,
            accessible: source?.attributes?.accessible
          }
        };
        const coordinates = `${obj.properties.centerX}.${obj.properties.centerY}`;
        obj.properties.container = this.isElementContainer(obj, elements);
    
        elements.push(obj);
    
        if (source.path) {
          if (overlaps[coordinates]) {
            overlaps[coordinates].push(obj);
          } else {
            overlaps[coordinates] = [obj];
          }
        }
    
        if (source.children) {
          for (const childEl of source.children) {
            this.buildElementsWithProps(childEl, source, elements, overlaps);
          }
        }
    
        return overlaps;
      }

      isElementContainer (element1, elements) {
        for (const element2 of elements) {
          if (element2.element !== element1.element
            && this.isElementOverElement(element1.properties, element2.properties)
            && !this.isAncestor(element1.parent, element2.element, elements)) {
            return true;
          }
        }
        return false;
      }
    
      isElementOverElement (element1, element2) {
        return element1.left <= element2.left
            && element1.width >= element2.width
            && element1.top >= element2.top
            && element1.height >= element2.height;
      }
    
      // Traverse through parent elements until we reach maybeAncestor
      isAncestor (curElement, maybeAncestor, elements) {
        if (elements.length > 0) {
          while (curElement !== null) {
            if (curElement === maybeAncestor) { return true; }
    
            for (const elem of elements) {
              if (elem.element === curElement) { curElement = elem.parent; }
            }
          }
        }
        return false;
      }

    render(){
        // Recurse through the 'source' JSON and render a highlighter rect for each element
        const highlighterRects = [];

        let renderElements = (source) => {
            for (const element of source) {
              highlighterRects.push(<HighlighterRect
                parseCoordinates={this.parseCoordinates}
                hoveredElement={this.props.hoveredElement}
                dimensions={element.properties}
                selectedElement={this.props.selectedElement}
                selectHoveredElement={this.props.selectHoveredElement}
                selectSelectedElement={this.props.selectSelectedElement}
                element={element.element}
                key={element.properties.path}
                ratio={this.props.ratio}/>);
            }
          };

        if(this.props.source !== [] || this.props.source !== undefined){
            let element = this.getElements(this.props.source);
            renderElements(element);
        }
        return <div style={{position: 'absolute'}}>{ highlighterRects }</div>;
    }
}

export default HighlighterRects;
