import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {Confirm, Grid, Header, Icon, Popup} from 'semantic-ui-react';
import {showError, showInfo, showSuccess} from '../utils/ToastHelpers';
import {
  stopManual,
  stopDevelopment
} from '../api/apiCalls'
import {WINDOW_RELOAD_TIMEOUT} from '../utils/Constants';
import { CANCEL_BUTTON, CONFIRM_BUTTON, CONNECTION_TERMINATED_MESSAGE, instantUsageMessages, SUCCESS_MESSAGE, TERMINATE_CONNECTION_MESSAGE } from '../utils/UIMessages';
import { X_ICON } from '../utils/UiIcons';
import { PrivilegeConstants } from '../utils/PrivilegeConstants';

const username = localStorage.getItem('username');

class InstantUsagePopup extends React.Component {
  state = {
    selectedUsernameForTerminate: "",
    selectedDeviceIdForTerminate: "",
    isUsersPopupShow: false,
    selectedTerminateType: "",
    confirmVisibility: false
  }

  onConfirmVisible = (userName, deviceId, usageType) => {
    this.setState({
      selectedUsernameForTerminate: userName,
      selectedDeviceIdForTerminate: deviceId,
      isUsersPopupShow: true,
      selectedTerminateType: usageType
    }, () => this.setState({ confirmVisibility: true }));
  }

  onConfirmClose = () => {
    this.setState({
      selectedUsernameForTerminate: "",
      selectedDeviceIdForTerminate: "",
      selectedTerminateType: "",
      confirmVisibility: false,
      isUsersPopupShow: false
    });
  }

  onTerminateConnection = () => {
    const params = {
      udId: this.state.selectedDeviceIdForTerminate,
      username: this.state.selectedUsernameForTerminate
    }
    //axios.post('/api/device-connection/stop-manual', null, { params })
    stopManual(params).then(() => {
      showSuccess(CONNECTION_TERMINATED_MESSAGE());
      setTimeout(() => window.location.reload(), WINDOW_RELOAD_TIMEOUT.DEFAULT);
    }).catch(err => {
      this.setState({ isUsersPopupShow: false });
      showError(err.response?.data?.message ?? err.toString());
    }).finally(this.onConfirmClose);
  }

  stopAutomationTest = (deviceId) => {
    this.setState({ isUsersPopupShow: true });
    showInfo(instantUsageMessages(null,deviceId).AUTOMATION_STOP_INFO);
    axios.delete(`/api/grid/sessions/${deviceId}`).then(() => {
      showSuccess(SUCCESS_MESSAGE());
      setTimeout(() => window.location.reload(), WINDOW_RELOAD_TIMEOUT.DEFAULT);
    }).catch(err => {
      this.setState({ isUsersPopupShow: false });
      showError(this.props.t(err.response?.data?.message ?? err.toString()));
    }).finally(this.onConfirmClose);
  };

  onStopDevelopmentTest = (deviceId) => {
    this.setState({ isUsersPopupShow: true });
    showInfo(instantUsageMessages(null,deviceId).DEVELOPMENT_STOP_INFO);
    const params = { udId: deviceId };
    //axios.post('/api/device-connection/stop-development', null, { params })
    stopDevelopment(params).then(() => {
      showSuccess(SUCCESS_MESSAGE());
      setTimeout(() => window.location.reload(), WINDOW_RELOAD_TIMEOUT.DEFAULT);
    }).catch(err => {
      this.setState({ isUsersPopupShow: false });
      showError(err.response?.data?.message ?? err.toString());
    }).finally(this.onConfirmClose);
  }

  getTerminateFunction = () => {
    const terminateType = this.state.selectedTerminateType;
    if (terminateType === "Manual") {
      this.onTerminateConnection();
    } else if (terminateType === "Automation") {
      this.stopAutomationTest(this.state.selectedDeviceIdForTerminate);
    } else if (terminateType === "Development") {
      this.onStopDevelopmentTest(this.state.selectedDeviceIdForTerminate);
    }
  }

  render() {
    return (
      <>
        <Popup trigger={<Icon name={"user circle"} color="blue" style={this.props?.isDeviceListView ? { marginBottom:'17%', cursor: 'pointer', verticalAlign:'middle' }:{ cursor: 'pointer', verticalAlign:'middle' }} />}
          eventsEnabled={true}
          style={{ paddingBottom: 0, paddingRight: 7, paddingLeft: "inherit" }}
          position={"bottom center"}
          disabled={this.state.isUsersPopupShow}
          hoverable
          hideOnScroll
        >
          <Grid centered divided={"vertically"} padded
            style={{ display: "initial" }}>
            {!_.isEmpty(this.props.device.users) && <Header floated={"left"}
              size={"small"}>{instantUsageMessages().HEADER}</Header>}
            {!_.isEmpty(this.props.device.users) && this.props.device.users?.map((user, i) => {
              return user?.usageType.map(ut => <Grid.Row key={i}>
                <p style={{
                  marginInlineEnd: "auto",
                  marginBottom: "auto",
                  marginLeft: 13,
                  textAlign: "left"
                }}>{user.fullName}<br />
                  <div style={{
                    display: "flex",
                    color: "gray",
                    fontSize: 12
                  }}>{this.props.t(ut)}</div>
                </p>
                {((ut === 'Automation' && ((this.props.privileges.includes(PrivilegeConstants.ACCESS_AUTOMATION) && username === user.userName) ||
                  this.props.privileges.includes(PrivilegeConstants.REMOVE_GRID_SESSION))) ||
                  (ut === "Manual" || ut === "Development") && this.props.privileges.includes(PrivilegeConstants.SUPREME_DEVICE_MANAGEMENT)) &&
                  <Popup wide position={"bottom left"}
                    trigger={<Icon
                      fitted
                      circular
                      link
                      color="red"
                      name={X_ICON}
                      onClick={() => this.onConfirmVisible(user.userName, this.props.device.deviceId, ut)}
                    />}
                    content={ut === "Automation" ?
                      instantUsageMessages().AUTOMATION_POPUP_MESSAGE :
                      ut === "Development" ?
                        instantUsageMessages().DEVELOPMENT_POPUP_MESSAGE :
                        instantUsageMessages(user.userName).POPUP_MESSAGE
                    }
                  />}
              </Grid.Row>
              )
            })}
          </Grid>
        </Popup>

        <Confirm
          open={this.state.confirmVisibility}
          onCancel={this.onConfirmClose}
          onClose={this.onConfirmClose}
          onConfirm={this.getTerminateFunction}
          content={instantUsageMessages(this.state.selectedUsernameForTerminate).CONFIRM_TERMINATE}
          header={TERMINATE_CONNECTION_MESSAGE()}
          confirmButton={CONFIRM_BUTTON()}
          cancelButton={CANCEL_BUTTON()}
        />
      </>
    );
  }
}

export default withTranslation()(InstantUsagePopup);