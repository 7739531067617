import React, { Component } from "react";
import {
    androidOrIosIcon,
    changeLabel,
    conditionalShowFilterValues,
    editPackageName,
    filterBarGenerator,
    getObjectValues,
    orderFilterElements,
    getPrivileges
} from "../utils/Methods";
import { Accordion, Checkbox, Menu, Radio, Popup } from "semantic-ui-react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { connect } from "react-redux";
import { endDeviceLoad, startDeviceLoad } from "../actions";
import { showError } from "../utils/ToastHelpers";
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { DEVICE_LIST_FILTER_KEY_LIST, FILTER_FIELDS, USAGE_STATUS_FILTER_FIELDS, WINDOW_RELOAD_TIMEOUT, POPUP_POSITIONS } from '../utils/Constants';
import {
    getAppList
} from '../api/apiCalls';
import {
    APPLICATIONS_HEADER, BRAND_HEADER, SECONDARY_SERVERS_HEADER, DEVICE_STATUS_HEADER, filterBarMessages,
    NOT_FETCH_DEVICE_LIST, OPERATING_SYSTEM_HEADER, OS_VERSION_HEADER, SCREEN_RESOLUTION_HEADER,
    SCREEN_SIZE_HEADER, VERSIONS_LABEL, USER_GROUPS_HEADER
} from "../utils/UIMessages";
import { PrivilegeConstants } from '../utils/PrivilegeConstants';

const PACKAGE_NAME_TRIM_END = -4;

const ONLINE_CODE = 0;
const OFFLINE_CODE = 1;
const MAINTENANCE_CODE = 2;

//External filtering function(Outside Component)
const FilterItem = (props) => {
    const { items, header, onClick, type = 'checkbox' } = props;
    const privileges = getPrivileges();
    if (type === 'slaveFilter') {
        return (
            <>
                {items?.map((item) => {
                    let slaveFilterComponent;
                    slaveFilterComponent = (
                        privileges.includes(PrivilegeConstants.SECONDARY_SERVER_MANAGEMENT) ?
                            <Popup
                                id={item.id}
                                content={item.ip}
                                trigger={
                                    <Checkbox
                                        id={item.id}
                                        label={item.name}
                                        value={item.id}
                                        checked={item.selected}
                                        onClick={() => onClick(item)}
                                        className='filterbar-checkbox'
                                    />}
                                position={POPUP_POSITIONS.TOP_LEFT}
                            /> :
                            <Checkbox
                                id={item.id}
                                label={item.name}
                                value={item.id}
                                checked={item.selected}
                                onClick={() => onClick(item)}
                                className='filterbar-checkbox'
                            />
                    )
                    return (
                        <div
                            key='slaveFilter'
                            style={{ paddingBottom: 10 }}
                            className={'filterItem'}
                        >
                            {slaveFilterComponent}
                        </div>
                    );
                })}
            </>
        )
    }

    return (
        <>
        {
            Object.keys(items)
                ?.sort((a, b) =>
                    a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                )
                ?.map(key => {
                    const value = items[key];
                    let selectionComponent;
                    if( header === 'Reserved'){
                        selectionComponent = (
                            <Checkbox
                                label={_.capitalize(changeLabel(key))}
                                value={`${value}`}
                                checked={value}
                                onClick={() => onClick(key)}
                            />
                        )
                    } else {
                        selectionComponent = (
                            <Checkbox
                                label={_.capitalize(key)}
                                value={`${value}`}
                                checked={value}
                                onClick={() => onClick(key)}
                                className='filterbar-checkbox'
                            />
                        );
                    }
                    
                    if (type !== 'checkbox') {
                        selectionComponent = (
                            <Radio
                                label={_.capitalize(changeLabel(key))}
                                value={key}
                                checked={value}
                                onClick={() => onClick(key)}
                                className='filterbar-radio'
                            />
                        );
                    } 

                    return (
                        <div
                            key={key}
                            style={{paddingBottom: 10}}
                            className={'filterItem'}
                        >
                            {selectionComponent}
                        </div>
                    );
                })}
        </>
    );
};

class FilterBar extends Component {
    state = {
        filterBar: null,
        activeIndex: -1,
        appList: [],
        selectedApp: '',
        versionList: [],
        selectedVersion: 'all',
        groupList: []
    };

    filterAccordionRef = React.createRef();

    //Device Filtering Methods
    //---------------------------------------------------------------------------------------------//
    selectedSingleOs = () => {
        const selectedOss = Object.keys(this.state.filterBar.os).filter(
            (key) => this.state.filterBar.os[key] === true
        );
        return selectedOss.length === 1 ? selectedOss[0] : undefined;
    };

    handleClickAccordion = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;
        this.setState({activeIndex: newIndex});
    };

    onChooseFilterItem = filterBarParam => {
        this.setState({ filterBar: filterBarParam }, () => {
            this.loadData();
        });
    }

    onClickCheckboxFilter = (filterItem, field) => {
        const filterBar = JSON.parse(JSON.stringify(this.state.filterBar));
        const currentField = filterBar[filterItem];
        currentField[field] = !currentField[field];
        this.onChooseFilterItem(filterBar);
    };

    onClickAndroidOrIosType = (os, version, key) => {
        const filterBar = JSON.parse(JSON.stringify(this.state.filterBar));
        const currentOs = filterBar[key][os];
        currentOs[version] = !currentOs[version];
        this.onChooseFilterItem(filterBar);
    };

    onClickNode = selectedNode => {
        const filterBar = JSON.parse(JSON.stringify(this.state.filterBar));
        const index = filterBar['nodeList']?.findIndex(node =>node.id === selectedNode.id);
        const node = filterBar['nodeList'][index];
        node.selected = !node.selected;
        filterBar['nodeList'].splice(index, 1, node)
        this.onChooseFilterItem(filterBar);
    }

    onClickRadioStatus = (filterItem, field) => {
        const filterBar = JSON.parse(JSON.stringify(this.state.filterBar));
        const currentFilteredItem = filterBar['status'][filterItem];
        const reservedByMeField = filterBar['reservedByMe'];
        Object.keys(currentFilteredItem).forEach((key) => {
            if (key === field) {
                //If items in the currentFilteredItem match the field parameter we assign it to false.
                currentFilteredItem[key] = !currentFilteredItem[key];
                if (filterItem === 'Reserved' && currentFilteredItem[key] === false) {
                    reservedByMeField['Reserved by me'] = false;
                }
            } else {
                //If items in the currentFilteredItem does not match the field parameter we assign it to false.
                currentFilteredItem[key] = false;
                if (filterItem === 'Reserved') {
                    reservedByMeField['Reserved by me'] = false;
                }
            }
        });
        this.onChooseFilterItem(filterBar); //Updates the filterBar by calling the onChooseFilterItem.
    };

    onClickRadioFilter = (filterItem, field) => {
        const filterBar = JSON.parse(JSON.stringify(this.state.filterBar));
        let currentFilteredItem = filterBar[filterItem];
        Object.keys(currentFilteredItem).forEach((key) => {
            if (key === field) {
                currentFilteredItem[key] = !currentFilteredItem[key];
            } else {
                currentFilteredItem[key] = false;
            }
        });
        this.onChooseFilterItem(filterBar);
    };

    checkAppFilterInfo = (path, urlSearchString, packageName, version) => {
      if (this.state.selectedApp || packageName) {
        const currentValApp = this.state.selectedApp || packageName;
        path += `packageName=${currentValApp.slice(0, PACKAGE_NAME_TRIM_END)}&`;
        urlSearchString += `packageName=${currentValApp}&`;
        if ((this.state.selectedVersion && this.state.selectedVersion !== 'all') || version) {
          const currentValVersion = this.state.selectedVersion || version;
          path += `version=${currentValVersion}&`;
          urlSearchString += `version=${currentValVersion}&`;
        }
      }

      return { path, urlSearchString };
    }

    getDevicesForFilterBar = (requestParams, packageName, version) => {
        let path = '/api/devices';
        let urlSearchString = '';
        if (requestParams) {
            path += '?';
            Object.keys(requestParams)
                .filter((key) => requestParams[key].length > 0)
                .forEach((key) => {
                    const selections = requestParams[key];
                    for (let selection of selections) {
                        path += key + '=' + selection + '&';
                    }
                });
            urlSearchString = path.slice(path.indexOf('?'));
            ({ path, urlSearchString } = this.checkAppFilterInfo(path, urlSearchString, packageName, version));
        }
        if (urlSearchString) {
          const currentUrlSearchObj = qs.parse(this.props.location.search);
          if (currentUrlSearchObj.searchKeyword) {
            urlSearchString += `searchKeyword=${currentUrlSearchObj.searchKeyword}`;
          }
          this.props.history.replace({ search: urlSearchString });
          sessionStorage.setItem('deviceSearchUrl',urlSearchString)
        }
        return axios.get(path);
    };

    loadData = (packageName='', version='') => {
        this.props.startDeviceLoad('');
        try {
            const selectedOs = this.selectedSingleOs();
            let oSVersion = [];
            let screenSize = [];
            let screenResolution = [];

            if (selectedOs) {
                const currentOsVersions =
                    this.state.filterBar.oSVersion[selectedOs];
                const currentScreenSizes =
                    this.state.filterBar.screenSize[selectedOs];
                const currentScreenResolutions =
                    this.state.filterBar.screenResolution[selectedOs];
                oSVersion = Object.keys(currentOsVersions).filter(
                    (key) => currentOsVersions[key] === true
                );
                screenSize = Object.keys(currentScreenSizes).filter(
                    (key) => currentScreenSizes[key] === true
                );
                screenResolution = Object.keys(currentScreenResolutions).filter(
                    (key) => currentScreenResolutions[key] === true
                );
            } else {
                for (let os of Object.keys(this.state.filterBar.oSVersion)) {
                    const currentOsVersions =
                        this.state.filterBar.oSVersion[os];
                    const currentScreenSizes =
                        this.state.filterBar.screenSize[os];
                    const currentScreenResolutions =
                        this.state.filterBar.screenResolution[os];
                    oSVersion = [
                        ...oSVersion,
                        ...Object.keys(currentOsVersions).filter(
                            (key) => currentOsVersions[key] === true
                        ),
                    ];
                    screenSize = [
                        ...screenSize,
                        ...Object.keys(currentScreenSizes).filter(
                            (key) => currentScreenSizes[key] === true
                        ),
                    ];
                    screenResolution = [
                        ...screenResolution,
                        ...Object.keys(currentScreenResolutions).filter(
                            (key) => currentScreenResolutions[key] === true
                        ),
                    ];
                }
            }
            const requestParams = {
                brand: Object.keys(this.state.filterBar.brand).filter(
                    (key) => this.state.filterBar.brand[key] === true
                ),
                deviceStatus: Object.keys(this.state.filterBar.deviceStatus)
                    .filter(
                        (key) => this.state.filterBar.deviceStatus[key] === true
                    )
                    .map((status) => {
                        if (status === 'Online') {
                          return ONLINE_CODE;
                        } else if (status === 'Offline') {
                          return OFFLINE_CODE;
                        } else if (status === 'Maintenance') {
                          return MAINTENANCE_CODE;
                        } else {
                          return OFFLINE_CODE;
                        }
                    }),
                nodeList: this.state.filterBar?.nodeList?.filter(slave=>slave?.selected).map(({id})=> id),
                groupList:  this.state.filterBar?.groupList?.filter(
                    group=>group?.selected).map(({id})=> id
                ),
                screenSize,
                screenResolution,
                os: Object.keys(this.state.filterBar.os).filter(
                    (key) => this.state.filterBar.os[key] === true
                ),
                oSVersion,
                Reserved: Object.keys(
                    this.state.filterBar.status.Reserved
                ).filter(
                    (key) => this.state.filterBar.status.Reserved[key] === true
                ),
                reservedByMe: Object.keys(this.state.filterBar.reservedByMe).filter(
                    key => this.state.filterBar.reservedByMe[key] === true
                ).map(reservedByMe => {
                    return reservedByMe === 'Reserved by me';
                }),
                Manual: Object.keys(this.state.filterBar.status.Manual).filter(
                    (key) => this.state.filterBar.status.Manual[key] === true
                ),
                Automation: Object.keys(
                    this.state.filterBar.status.Automation
                ).filter(
                    (key) =>
                        this.state.filterBar.status.Automation[key] === true
                ),
                Development: Object.keys(
                    this.state.filterBar.status.Development
                ).filter(
                    (key) =>
                        this.state.filterBar.status.Development[key] === true
                ),
                favorite: Object.keys(this.state.filterBar.favorite).filter(
                    (key) => this.state.filterBar.favorite[key] === true
                ),
                categories: Object.keys(this.state.filterBar.categories).filter(
                    (key) => this.state.filterBar.categories[key] === true
                ),
            };

            this.getDevicesForFilterBar(requestParams, packageName, version)
                .then((response) => {
                    this.props.changeDeviceList(response.data, response.data);
                    this.props.changeFilterValues(requestParams);
                    const urlSearchObj = qs.parse(this.props.location.search);
                    if (urlSearchObj.searchKeyword) {
                        this.props.onRememberFilterDevices(urlSearchObj.searchKeyword);
                    }
                    conditionalShowFilterValues(
                        this.filterAccordionRef,
                        requestParams,
                        response.data
                    );
                    this.props.endDeviceLoad();
                })
                .catch((err) => {
                    this.props.endDeviceLoad();
                    showError(
                        `${NOT_FETCH_DEVICE_LIST()}${err?.response?.status}`
                    );
                });

        } catch (e) {
            this.props.endDeviceLoad();
            showError(
                `${filterBarMessages().FILTER_ERROR}${e.toString()}`
            );
        }

    };

    //app filter methods
    onClickAppRadioFilter = (e, { value }) => {
        this.setState(prev => {
          if (prev.selectedApp === value) {
            this.props.changeFilterAppValues('');
            this.props.changeFilterAppVersion('all');
            return { ...prev, selectedApp: '', versionList: [], selectedVersion: 'all' };
          } else {
            this.props.changeFilterAppValues(value.slice(0, PACKAGE_NAME_TRIM_END));
            this.props.changeFilterAppVersion('all');
            const versionList = _.uniq(this.state.appList[value].map(v => v.appVersion));
            return { ...prev, selectedApp: value, versionList, selectedVersion: 'all' };
          }
        }, this.loadData);
    };
       
     onClickGroupList = selectedGroupList => {
         const filterBar = JSON.parse(JSON.stringify(this.state.filterBar));
         const index = filterBar['groupList']?.findIndex(groups =>groups.id === selectedGroupList.id);
         const group = filterBar['groupList'][index];
         group.selected = group.selected == undefined ? true : !group.selected;
         filterBar['groupList'].splice(index, 1, group)
         this.onChooseFilterItem(filterBar);
     }
    

    onClickAppVersionRadioFilter = (e, { value }) => {
        this.setState(prev => {
          if (prev.selectedVersion === value) {
            this.props.changeFilterAppVersion('all');
            return { ...prev, selectedVersion: 'all' };
          } else {
            this.props.changeFilterAppVersion(value);
            return { ...prev, selectedVersion: value };
          }
        }, this.loadData);
    };

    getApps = (packageName='') => {
        getAppList().then((response) => {
                if (response.data) {
                    try {
                        let groupBy = function (xs, key) {
                            return xs.reduce(function (rv, x) {
                                (rv[x[key]] = rv[x[key]] || []).push(x);
                                return rv;
                            }, {});
                        };
                        const allApps = editPackageName(response.data);
                        const groupedByPackage = groupBy(allApps, 'appPackage');
                        let versionList = [];
                        if (packageName) {
                          versionList = _.uniq(groupedByPackage[packageName].map(v => v.appVersion));
                        }
                        this.setState({ appList: groupedByPackage, versionList });
                    } catch (e) {
                        showError(
                            `${filterBarMessages().APP_LIST_NOT_PARSED_ERROR}${e.toString()}`
                        );
                    }
                }
            })
            .catch((err) => {
                showError(
                    `${filterBarMessages().APP_LIST_FETCH_ERROR}${err?.response?.status}`
                );
            });
    };

    //Fills the filterBar state object
    getFilterBar = () => {
        let filterBar = filterBarGenerator(this.props.deviceList);
        this.setState({ filterBar }, () =>
            orderFilterElements(this.filterAccordionRef)
        );
    };

    filterUrlSearchKeys = urlSearchObj => {
      Object.keys(urlSearchObj).forEach(k => {
        if (!DEVICE_LIST_FILTER_KEY_LIST.includes(k)) {
          delete urlSearchObj[k];
        }
      });
      delete urlSearchObj.searchKeyword;
    };

    setFieldValues = (filterBarObj, field, ...values) => {
      if (FILTER_FIELDS.includes(field)) {
        values.forEach(value => filterBarObj[field][value] = true);
      } else if (USAGE_STATUS_FILTER_FIELDS.includes(field)) {
        values.forEach(value => filterBarObj.status[field][value] = true);
      } else if (field === 'deviceStatus') {
        values.forEach(value => {
          if (value === ONLINE_CODE.toString()) {
            filterBarObj.deviceStatus['Online'] = true;
          } else if (value === OFFLINE_CODE.toString()) {
            filterBarObj.deviceStatus['Offline'] = true;
          } else if (value === MAINTENANCE_CODE.toString()) {
            filterBarObj.deviceStatus['Maintenance'] = true;
          } else {
            /* invalid value, do nothing */
          }
        });
      } else if (['oSVersion', 'screenResolution', 'screenSize'].includes(field)) {
        values.forEach(value => {
          for (const [osType, osTypeValues] of Object.entries(filterBarObj[field])) {
            for (const key in osTypeValues) {
              if (key === value) {
                filterBarObj[field][osType][key] = true;
                break;
              }
            }
          }
        });
      } else {
        /* filterBar property'si değil */
      }
    };

    getFilterbarFromUrlSearch = urlSearchObj => {
      const filterBar = filterBarGenerator(this.props.deviceList);
      for (const [field, value] of Object.entries(urlSearchObj)) {
        if (value instanceof Array) {
          this.setFieldValues(filterBar, field, ...value);
        } else {
          this.setFieldValues(filterBar, field, value);
        }
      }
      return filterBar;
    };


    getFilterBarAndLoadData = () =>{
        this.getFilterBar();
    }

    getUrlSearchParam = () => qs.parse(this.props.location.search);

    loadFilterBarAndData = () => {
        const urlSearchObj = this.getUrlSearchParam();
        const { packageName, version } = urlSearchObj;
        this.filterUrlSearchKeys(urlSearchObj);
        if (_.isEmpty(urlSearchObj)) {
            this.getFilterBar();
        } else {
            try {
                const rememberFilterBar = this.getFilterbarFromUrlSearch(urlSearchObj);
                if (Object.prototype.hasOwnProperty.call(urlSearchObj, 'groupList')) {
                    const gList = Array.isArray(urlSearchObj.groupList) ? urlSearchObj.groupList : [urlSearchObj.groupList];
                  
                    rememberFilterBar.groupList = rememberFilterBar?.groupList.map(group => {
                        if (gList.some(item => item == group.id)) {
                            group.selected = true;
                        }
                        return group;
                    });
                }
                if (Object.prototype.hasOwnProperty.call(urlSearchObj, 'nodeList')) {
                    const nList = Array.isArray(urlSearchObj.nodeList) ? urlSearchObj.nodeList : [urlSearchObj.nodeList];
                    rememberFilterBar?.nodeList.map(node => {
                        if (nList.includes(node.id)) {
                            node.selected = true;
                        }
                    });
                    if (Object.prototype.hasOwnProperty.call(urlSearchObj, 'os')){
                        let dList = [];
                        const osList = Array.isArray(urlSearchObj.os) ? urlSearchObj.os.map(os => os.toLowerCase()) : [urlSearchObj.os.toLowerCase()];
                        this.props.deviceList?.forEach((device)=>{
                            if(osList.includes(device?.os.toLowerCase())){
                                dList.push(device)
                            }
                        })
                        
                        rememberFilterBar.nodeList = rememberFilterBar.nodeList?.filter(node => dList.some(device => device?.node?.id === node.id));
                    }
                }
                
                if (getObjectValues(rememberFilterBar).includes(true) || packageName) {
                    this.setState({ filterBar: rememberFilterBar }, () => {
                        this.loadData(packageName, version);
                        orderFilterElements(this.filterAccordionRef);
                    });
                } else {
                    this.getFilterBar();
                }
                if (localStorage.getItem('activeIndex') != null) {
                    this.setState({ activeIndex: +localStorage.getItem('activeIndex') })
                } else {
                    this.setState({ activeIndex: -1 });
                }
            } catch (e) {
                this.props.history.replace({ search: '' });
                setTimeout(() => window.location.reload(), WINDOW_RELOAD_TIMEOUT.DEFAULT);
            }
        }
    }

    //lifecycle methods

    componentDidMount() {
        window.addEventListener("beforeunload", this.componentCleanup);
        this.loadFilterBarAndData();
        const urlSearchObj = this.getUrlSearchParam();
        const { packageName, version } = urlSearchObj;
        this.getApps(packageName);
        this.setState({
          selectedApp: packageName || '',
          selectedVersion: version || 'all'
        }, () => {
          this.props.changeFilterAppValues(this.state.selectedApp.slice(0, PACKAGE_NAME_TRIM_END));
          this.props.changeFilterAppVersion(this.state.selectedVersion);
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deviceList !== this.props.deviceList) {
            this.componentCleanup();
            this.loadFilterBarAndData();
        }
    }

    componentCleanup = () => {
        const { activeIndex } = this.state;
        localStorage.setItem('activeIndex', activeIndex);
    };

    componentWillUnmount() {
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    render() {
        return (
            <>
                <div ref={this.filterAccordionRef}>
                    {this.state.filterBar !== null && (
                        <Accordion
                            vertical={'true'}
                            fluid
                            styled
                            className='filter-accordion'
                        >
                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 0}
                                    index={0}
                                    content={
                                        <>
                                            {BRAND_HEADER()}
                                        </>
                                    }
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 0}
                                    content={
                                        <div className={'filterBrands'}>
                                            {
                                                <FilterItem
                                                    header={BRAND_HEADER()}
                                                    items={
                                                        this.state.filterBar
                                                            ?.brand
                                                    }
                                                    onClick={(field) =>
                                                        this.onClickCheckboxFilter(
                                                            'brand',
                                                            field
                                                        )
                                                    }
                                                />
                                            }
                                        </div>
                                    }
                                />
                            </Menu.Item>

                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 1}
                                    content={DEVICE_STATUS_HEADER()}
                                    index={1}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 1}
                                    content={
                                        <FilterItem
                                            header={DEVICE_STATUS_HEADER()}
                                            items={
                                                this.state.filterBar
                                                    ?.deviceStatus
                                            }
                                            onClick={(field) =>
                                                this.onClickRadioFilter(
                                                    'deviceStatus',
                                                    field
                                                )
                                            }
                                            type='radio'
                                        />
                                    }
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 2}
                                    content={filterBarMessages().USAGE_STATUS_TITLE}
                                    index={2}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 2}
                                    content={
                                        <>
                                            {Object.keys(
                                                this.state.filterBar?.status
                                            ).map((status) => {
                                                return (
                                                    <React.Fragment
                                                        key={status}
                                                    >
                                                        <div className='usage-status-container'>
                                                            <h4>
                                                                {this.props.t(
                                                                    _.capitalize(
                                                                        status
                                                                    )
                                                                )}
                                                            </h4>
                                                            <div>
                                                                <FilterItem
                                                                    key={status}
                                                                    header={
                                                                        status
                                                                    }
                                                                    items={
                                                                        this
                                                                            .state
                                                                            .filterBar
                                                                            ?.status[
                                                                            status
                                                                        ]
                                                                    }
                                                                    onClick={(
                                                                        field
                                                                    ) => {
                                                                        this.onClickRadioStatus(
                                                                            status,
                                                                            field
                                                                        );
                                                                    }}
                                                                    type={
                                                                        'radio'
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {status === 'Reserved' &&
                                                            this.state.filterBar?.status['Reserved'].true &&
                                                            <FilterItem
                                                                header={'Reserved'}
                                                                items={this.state.filterBar?.reservedByMe}
                                                                onClick={(field) =>
                                                                    this.onClickCheckboxFilter('reservedByMe', field)}
                                                            /> 
                                                        }
                                                    </React.Fragment>
                                                );
                                            })}
                                        </>
                                    }
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 3}
                                    content={SECONDARY_SERVERS_HEADER()}
                                    index={3}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 3}
                                    content={
                                        <div className={'filterSlaves'}>
                                            {<FilterItem
                                                header={SECONDARY_SERVERS_HEADER()}
                                                items={this.state.filterBar?.nodeList}
                                                onClick={field =>this.onClickNode(field)}
                                                type='slaveFilter'
                                            />}
                                        </div>
                                    }
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 4}
                                    content={OPERATING_SYSTEM_HEADER()}
                                    index={4}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 4}
                                    content={
                                        <div className='filterOperationSystems'>
                                            {
                                                <FilterItem
                                                    header={OPERATING_SYSTEM_HEADER()}
                                                    items={
                                                        this.state.filterBar?.os
                                                    }
                                                    onClick={(field) =>
                                                        this.onClickCheckboxFilter(
                                                            'os',
                                                            field
                                                        )
                                                    }
                                                />
                                            }
                                        </div>
                                    }
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 5}
                                    content={OS_VERSION_HEADER()}
                                    index={5}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 5}
                                    content={
                                        <div className='filterOSVersions'>
                                            {Object.keys(
                                                this.state.filterBar?.oSVersion
                                            )
                                                .filter((os) => {
                                                    const selectedSingleOs =
                                                        this.selectedSingleOs();
                                                    return (
                                                        !selectedSingleOs ||
                                                        selectedSingleOs === os
                                                    );
                                                })
                                                .map((devType) => {
                                                    return (
                                                        <FilterItem
                                                            key={devType}
                                                            header={devType}
                                                            items={
                                                                this.state
                                                                    .filterBar
                                                                    ?.oSVersion[
                                                                    devType
                                                                ]
                                                            }
                                                            onClick={(
                                                                field
                                                            ) => {
                                                                this.onClickAndroidOrIosType(
                                                                    devType,
                                                                    field,
                                                                    'oSVersion'
                                                                );
                                                            }}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    }
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 8}
                                    content={SCREEN_SIZE_HEADER()}
                                    index={8}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 8}
                                    content={
                                        <div className='filterScreenSizes'>
                                            {Object.keys(
                                                this.state.filterBar?.screenSize
                                            )
                                                .filter((size) => {
                                                    const selectedSingleOs =
                                                        this.selectedSingleOs();
                                                    return (
                                                        !selectedSingleOs ||
                                                        selectedSingleOs ===
                                                            size
                                                    );
                                                })
                                                .map((devType) => {
                                                    return (
                                                        <FilterItem
                                                            key={devType}
                                                            header={devType}
                                                            items={
                                                                this.state
                                                                    .filterBar
                                                                    ?.screenSize[
                                                                    devType
                                                                ]
                                                            }
                                                            onClick={(
                                                                field
                                                            ) => {
                                                                this.onClickAndroidOrIosType(
                                                                    devType,
                                                                    field,
                                                                    'screenSize'
                                                                );
                                                            }}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    }
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 9}
                                    content={SCREEN_RESOLUTION_HEADER()}
                                    index={9}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 9}
                                    content={
                                        <div className='filterScreenResolutions'>
                                            {Object.keys(
                                                this.state.filterBar
                                                    ?.screenResolution
                                            )
                                                .filter((size) => {
                                                    const selectedSingleOs =
                                                        this.selectedSingleOs();
                                                    return (
                                                        !selectedSingleOs ||
                                                        selectedSingleOs ===
                                                            size
                                                    );
                                                })
                                                .map((devType) => {
                                                    return (
                                                        <FilterItem
                                                            key={devType}
                                                            header={devType}
                                                            items={
                                                                this.state
                                                                    .filterBar
                                                                    ?.screenResolution[
                                                                    devType
                                                                ]
                                                            }
                                                            onClick={(
                                                                field
                                                            ) => {
                                                                this.onClickAndroidOrIosType(
                                                                    devType,
                                                                    field,
                                                                    'screenResolution'
                                                                );
                                                            }}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    }
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 10}
                                    content={<>{filterBarMessages().FAVORITES_TITLE}</>}
                                    index={10}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 10}
                                    content={
                                        <div className='filterFavorites'>
                                            <FilterItem
                                                header={filterBarMessages().FAVORITES_TITLE}
                                                items={
                                                    this.state.filterBar
                                                        ?.favorite
                                                }
                                                onClick={(field) =>
                                                    this.onClickRadioFilter(
                                                        'favorite',
                                                        field
                                                    )
                                                }
                                                type='radio'
                                            />
                                        </div>
                                    }
                                />
                            </Menu.Item>
                            {!_.isEmpty(this.state.filterBar?.categories) && (
                                <Menu.Item>
                                <Accordion.Title
                                    active={this.state.activeIndex === 11}
                                    content={filterBarMessages().DEVICE_CATEGORIES_TITLE}
                                    index={11}
                                    onClick={this.handleClickAccordion}
                                />
                                <Accordion.Content
                                    style={{ paddingLeft: 16 }}
                                    active={this.state.activeIndex === 11}
                                    content={
                                        <div className='filterCategories'>
                                            {
                                                <FilterItem
                                                    header={'Categories'}
                                                    items={
                                                        this.state.filterBar
                                                            ?.categories
                                                    }
                                                    onClick={(field) =>
                                                        this.onClickCheckboxFilter(
                                                            'categories',
                                                            field
                                                        )
                                                    }
                                                />
                                            }
                                        </div>
                                    }
                                />
                            </Menu.Item>
                            )}
                            
                            {!_.isEmpty(this.state.appList) ? (
                                <>
                                    <Menu.Item>
                                        <Accordion.Title
                                            active={
                                                this.state.activeIndex === 6
                                            }
                                            content={APPLICATIONS_HEADER()}
                                            index={6}
                                            onClick={this.handleClickAccordion}
                                        />
                                        <Accordion.Content
                                            style={{ paddingLeft: 16 }}
                                            active={
                                                this.state.activeIndex === 6
                                            }
                                            content={
                                                <>
                                                    {Object.keys(
                                                        this.state.appList
                                                    ).map((packName, i) => {
                                                        return (
                                                            <div key={i}>
                                                                <Checkbox
                                                                    radio
                                                                    label={_.capitalize(this.state.appList[packName][0].appName)}
                                                                    name='checkboxRadioGroup'
                                                                    value={packName}
                                                                    checked={this.state.selectedApp === packName}
                                                                    onClick={this.onClickAppRadioFilter}
                                                                />{' '}
                                                                {androidOrIosIcon(this.state.appList[packName][0].fileName)}
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            }
                                        />
                                    </Menu.Item>
                                    {!_.isEmpty(this.state.versionList) ? (
                                        <>
                                            <Menu.Item>
                                                <Accordion.Title
                                                    active={this.state.activeIndex === 7}
                                                    content={VERSIONS_LABEL()}
                                                    index={7}
                                                    onClick={this.handleClickAccordion}
                                                />
                                                <Accordion.Content
                                                    style={{ paddingLeft: 16 }}
                                                    active={this.state.activeIndex === 7}
                                                    content={
                                                        <>
                                                            <Checkbox
                                                                radio
                                                                label={filterBarMessages().ALL_VERSIONS_LABEL}
                                                                name='checkboxRadioGroup'
                                                                value={'all'}
                                                                checked={this.state.selectedVersion === 'all'}
                                                                onClick={this.onClickAppVersionRadioFilter}
                                                            />

                                                            {this.state.versionList.map((version, i) => {
                                                                    return (
                                                                        <div
                                                                            key={i}>
                                                                            <Checkbox
                                                                                radio
                                                                                label={version}
                                                                                name='checkboxRadioGroup'
                                                                                value={version}
                                                                                checked={this.state.selectedVersion === version}
                                                                                onClick={this.onClickAppVersionRadioFilter}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    }
                                                />
                                            </Menu.Item>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    
                                </>
                            ) : (
                                <></>
                            )}
                            {!_.isEmpty(this.state.filterBar.groupList) && (
                                <>
                                    <Menu.Item>
                                        <Accordion.Title
                                            active={
                                                this.state.activeIndex === 12
                                            }
                                            content={USER_GROUPS_HEADER()}
                                            index={12}
                                            onClick={this.handleClickAccordion}
                                        />
                                        <Accordion.Content
                                            style={{ paddingLeft: 16 }}
                                            active={
                                                this.state.activeIndex === 12
                                            }
                                            content={
                                                <>
                                                    {
                                                        this.state.filterBar?.groupList
                                                    .map((group, i) => {
                                                        return (
                                                            <div key={i}>
                                                                <Checkbox
                                                                    label={_.capitalize(group.name)}
                                                                    name='checkBoxGroup'
                                                                    checked={group.selected}
                                                                    onClick={() =>
                                                                        this.onClickGroupList(
                                                                            group
                                                                        )
                                                                    }
                                                                />{' '}
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            }
                                        />
                                    </Menu.Item>
                                    </>)}
                        </Accordion>
                    )}
                </div>
                <div className='count-total-number'>
                    {filterBarMessages().AVAILABLE_DEVICES_LABEL}:{' '}
                    {this.props.deviceCount}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.deviceList.loading,
        loadingContent: state.deviceList.loadingContent,
    };
};

export default withTranslation()(
    connect(mapStateToProps, { startDeviceLoad, endDeviceLoad })(withRouter(FilterBar))
);
