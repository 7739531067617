import copy from 'copy-to-clipboard';
import en from 'date-fns/locale/en-GB';
import tr from 'date-fns/locale/tr';
import _ from 'lodash';
import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button, Form, Grid, Header, Input, Modal, Popup } from 'semantic-ui-react';
import { generateEmbeddedScreenShareToken } from '../api/apiCalls';
import { DATETIME_FORMAT } from '../utils/Constants';
import { showError, showInfo, showSuccess } from '../utils/ToastHelpers';
import moment from 'moment';
import { COPIED_LABEL, COPY_TO_CLIPBOARD_MESSAGE, GENERATE_BUTTON, sharedUrlExpirationMessages, SUCCESS_MESSAGE, TIME_LABEL, UNSUPPORTED_TOKEN_TYPE_ERROR } from '../utils/UIMessages';
import { COPY_ICON } from '../utils/UiIcons';

registerLocale('tr', tr);
registerLocale('en', en);

const SharedUrlExpirationModal = ({ openModalVisibility, handleModalClose, localeProp, linkType, deviceId }) => {
    const { t } = useTranslation();
    const [expiryDate, setExpiryDate] = useState('');
    const [oneTimeSharedUrl, setOneTimeSharedUrl] = useState('');
    const [specifiedTimeSharedUrl, setSpecifiedTimeSharedUrl] = useState('');
    const [tokenUsageTime, setTokenUsageTime] = useState('oneTime');
    const [embedScreenTokenVisibility, setEmbedScreenTokenVisibility] = useState(false);

    const handleChange = (e, { value }) => {
        setTokenUsageTime(value)
    }

    const handleCodeCopy = () => {
        if (tokenUsageTime === 'oneTime') {
            copy(oneTimeSharedUrl);
        } else {
            copy(specifiedTimeSharedUrl);
        }
        showInfo(COPIED_LABEL(), 1000);
        handleModalClose();
    }

    const onSend = () => {
        let data;
        if (tokenUsageTime === 'oneTime') {
            data = {
                'tokenType': tokenUsageTime,
                'udId': deviceId,
                'embedMode': linkType
            };
        } else if (tokenUsageTime === 'specifiedTime' && expiryDate) {
            data = {
                'tokenType': tokenUsageTime,
                'udId': deviceId,
                'expiryDate': moment(expiryDate).format(DATETIME_FORMAT),
                'embedMode': linkType
            };
        } else {
            data = {
                'tokenType': tokenUsageTime,
                'udId': deviceId,
                'embedMode': linkType
            };
        }
        generateEmbeddedScreenShareToken(data).then(res => {
            let token;
            if (linkType === 'view') {
                token = res.data.accessToken;
            } else if (linkType === 'manage') {
                token = res.data.manageToken;
            } else if (linkType === 'inspector') {
                token = res.data.inspectorToken;
            } else {
                showError(UNSUPPORTED_TOKEN_TYPE_ERROR());
            }
            const url = `${window.location.origin}/embed-${linkType}/${token}`;
            if (tokenUsageTime === 'oneTime') {
                setOneTimeSharedUrl(url);
            } else {
                setSpecifiedTimeSharedUrl(url);
            }
            setEmbedScreenTokenVisibility(true);
            showSuccess(SUCCESS_MESSAGE())
        }).catch(err => {
            showError(t(err?.response?.data?.message) ?? err.toString())
            setEmbedScreenTokenVisibility(false);
        });

    }

    return (
        <div>
            <Modal centered={true} open={openModalVisibility} onClose={handleModalClose} closeIcon
                closeOnDimmerClick={true}
                size='tiny'>
                <Modal.Content>
                    <Header as='h3'>
                        {sharedUrlExpirationMessages().MODAL_HEADER}
                    </Header>
                    <Form>
                        <Form.Field style={{ display: 'flex', width: '100%', marginTop: '30px' }}>
                            <Form.Field style={{ marginRight: '10px', width: '50%' }}>
                                <label>{sharedUrlExpirationMessages().LINK_TYPE_LABEL}</label>
                                <Form.Field>
                                    <Form.Group>
                                        <div className='shared-url-link-type-radio'>
                                            <Form.Radio
                                                label={sharedUrlExpirationMessages().ONE_TIME_LABEL}
                                                value='oneTime'
                                                checked={tokenUsageTime === 'oneTime'}
                                                onChange={handleChange}
                                            />
                                            <Form.Radio
                                                label={sharedUrlExpirationMessages().SPECIFIED_TIME_LABEL}
                                                value='specifiedTime'
                                                checked={tokenUsageTime === 'specifiedTime'}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Form.Group>
                                </Form.Field>
                            </Form.Field>
                            <Form.Field style={{ width: '50%' }}>
                                {tokenUsageTime === 'specifiedTime' &&
                                    <>
                                        <label>{sharedUrlExpirationMessages().EXPIRY_DATE_LABEL}</label>
                                        <DatePicker
                                            id='expiryDate'
                                            selected={expiryDate}
                                            //prevent manual input
                                            onKeyDown={e => {
                                                e.preventDefault();
                                            }}
                                            endDate={expiryDate}
                                            minDate={new Date()}
                                            onChange={date => setExpiryDate(date)}
                                            dateFormat='yyyy/MM/dd HH:mm:ss'
                                            showTimeSelect
                                            timeFormat='HH:mm:ss'
                                            timeIntervals={1}
                                            timeCaption={TIME_LABEL()}
                                            locale={localeProp}
                                        />
                                    </>
                                }
                            </Form.Field>
                        </Form.Field>
                        {embedScreenTokenVisibility && tokenUsageTime === 'oneTime' && !_.isEmpty(oneTimeSharedUrl) &&
                            <Grid>
                                <Grid.Row className='shared-url-row'>
                                    <Grid.Column width={13}>
                                        <label>{sharedUrlExpirationMessages().ONE_TIME_SCREEN_SHARE_LABEL}</label>
                                        <Input fluid readOnly focus value={oneTimeSharedUrl} />
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        <Popup
                                            size='tiny'
                                            trigger={<Button className='shared-url-copy-button' onClick={handleCodeCopy}
                                                icon={COPY_ICON} />}
                                            content={COPY_TO_CLIPBOARD_MESSAGE()}
                                            position='bottom center'
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }
                        {embedScreenTokenVisibility && tokenUsageTime === 'specifiedTime' && !_.isEmpty(specifiedTimeSharedUrl) &&
                            <Grid>
                                <Grid.Row className='shared-url-row'>
                                    <Grid.Column width={13}>
                                        <label>{sharedUrlExpirationMessages().SPECIFIED_TIME_SCREEN_SHARE_LABEL}</label>
                                        <Input fluid readOnly focus value={specifiedTimeSharedUrl} />
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        <Popup
                                            size='tiny'
                                            trigger={<Button className='shared-url-copy-button' onClick={handleCodeCopy}
                                                icon={COPY_ICON} />}
                                            content={COPY_TO_CLIPBOARD_MESSAGE()}
                                            position='bottom center'
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions style={{ textAlign: 'center' }}>
                    <Button primary onClick={onSend}>{GENERATE_BUTTON()}</Button>
                </Modal.Actions>
            </Modal>
        </div >
    )
}
export default withTranslation()(SharedUrlExpirationModal);
