import React, { useMemo } from 'react'
import DebouncedInput from './DebouncedInput'
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import { filterMessages, SEARCH_PLACEHOLDER } from '../../utils/UIMessages';

const Filter = ({ column, filterType = 'text', selection = null }) => {

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(() => filterType !== 'dropdown'
    ? []
    : _.uniq(Array.from(column.getFacetedUniqueValues().keys()).flat()).sort().map(val => ({ key: val, text: val, value: val })),
    [column.getFacetedUniqueValues(), filterType, column, localStorage.getItem('lang')]);

  return (
    <>
      {
        filterType === 'number' ?
          <div>
            <div className='space-x-2'>
              <DebouncedInput
                typeProp='number'
                min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                //value={(columnFilterValue as [number, number])?.[0] ?? ''}
                valueProp={(columnFilterValue)?.[0] ?? ''}
                onChangeProp={value =>
                  //column.setFilterValue((old: [number, number]) => [value, old?.[1]])
                  column.setFilterValue((old) => [value, old?.[1]])
                }
                placeHolderProp={`Min ${column.getFacetedMinMaxValues()?.[0]
                  ? `(${column.getFacetedMinMaxValues()?.[0]})`
                  : ''
                  }`}
                className='debounce-input-number'
              />
              <DebouncedInput
                typeProp='number'
                min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                //value={(columnFilterValue as [number, number])?.[1] ?? ''}
                valueProp={(columnFilterValue)?.[1] ?? ''}
                placeHolderProp={value =>
                  //column.setFilterValue((old: [number, number]) => [old?.[0], value])
                  column.setFilterValue((old) => [old?.[0], value])
                }
                placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
                  ? `(${column.getFacetedMinMaxValues()?.[1]})`
                  : ''
                  }`}
                className='debounce-input-number'
              />
            </div>
            <div style={{ height: '0.25rem' /* 4px */ }} />
          </div>

          : filterType === 'dropdown' ?
            <Dropdown
              id='filter-columns-dropdown'
              selection
              fluid
              options={sortedUniqueValues}
              clearable
              placeholder={filterMessages().DROPDOWN_PLACEHOLDER}
              onChange={(e, { value }) => column.setFilterValue(value)} />

            : filterType === 'text' ?
              <>
                <datalist id={column.id + 'list'}>
                  {sortedUniqueValues.slice(0, 5000).map(value => (
                    <option value={value} key={value} />
                  ))}
                </datalist>
                <DebouncedInput
                  typeProp='text'
                  valueProp={String((columnFilterValue ?? ''))}
                  onChangeProp={value => column.setFilterValue(value)}
                  placeHolderProp={SEARCH_PLACEHOLDER()}
                  className='filter-table-column-debounce-input-text'
                  list={column.id + 'list'}
                />
                <div style={{ height: '0.25rem' /* 4px */ }} />
              </>
              : filterType === 'selection' ?
                selection : null
      }
    </>
  )
}


export default Filter
