import React, {Component} from 'react';
import axios from "axios";
import {Flip, toast} from "react-toastify";
import {Button, Modal, Grid, Confirm, Popup} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {showError, showSuccess} from '../utils/ToastHelpers';
import ReactTableV8 from '../components/ReactTableV8/ReactTableV8';
import {
    appsInDeviceMessages, APPS_IN_THIS_DEVICE_HEADER, CANCEL_BUTTON,
    CONFIRM_BUTTON, SUCCESS_MESSAGE,
    UNKNOWN_ERROR, VERSION_HEADER
} from '../utils/UIMessages';
import {STOP_ICON, TRASH_ICON} from '../utils/UiIcons';
import {POPUP_POSITIONS} from '../utils/Constants';
import {stopApplication} from '../api/apiCalls';

class AppsInDeviceModal extends Component {

    state = {
        loading: false,
        allApps: [],
        pageSize: 10,
        page: 0,
        confirmVisibility: false,
        confirmMessage: '',
        onConfirmAction: null,
        selectedAppPackage: ''
    }

    //New React Table v8 Columns
    columns = [
        {
            header: () => <div>{appsInDeviceMessages().PACKAGE_NAME_HEADER}</div>,
            accessorKey: 'packageName',
            id: 'packageName',
            filterType: 'text',
            cell: info => (<span className='number'>{info.getValue() || 'N/A'}</span>)
        },
        {
            header: () => <div>{VERSION_HEADER()}</div>,
            accessorKey: 'version',
            id: 'version',
            filterType: 'text',
            cell: info => (<span className='number'>{info.getValue() || 'N/A'}</span>)
        },
        {
            header: <div></div>,
            id: 'actions',
            enableSorting: false,
            enableColumnFilter: false,
            cell: props => (<>
                <Popup
                    position={POPUP_POSITIONS.TOP_RIGHT}
                    content={appsInDeviceMessages().DELETE_APP_POPUP}
                    trigger={
                        <Button icon={TRASH_ICON} color={'red'} onClick={() => this.setState({
                            confirmVisibility: true,
                            selectedAppPackage: props.row.original.packageName,
                            onConfirmAction: this.onDeleteAppInDevice,
                            confirmMessage: appsInDeviceMessages().DELETE_CONFIRM
                        })}
                            disabled={this.props.embedMode}
                        />}
                />
                {
                    props.row.original.alive &&
                    <Popup
                        position={POPUP_POSITIONS.TOP_RIGHT}
                        content={appsInDeviceMessages().TERMINATE_APP_POPUP}
                        trigger={
                            <Button icon={STOP_ICON} color={'red'} onClick={() => this.setState({
                                confirmVisibility: true,
                                selectedAppPackage: props.row.original.packageName,
                                onConfirmAction: this.onStopApplication,
                                confirmMessage: appsInDeviceMessages().TERMINATE_CONFIRM
                            })}
                                    disabled={this.props.embedMode}
                            />}
                    />
                }
            </>)
        }
    ]

    getAppsInDevice = () => {
        this.setState({loading: true, selectedAppPackage: ""}, () => {
            let headers;
            if (this.props.embedMode) {
                const token = this.props.match.params;
                headers = {'Embedded-Screen-Key': `${token.token}`}
            }
            axios.get('/api/screen-share/apps-in-device',
                    {
                        ...(this.props.embedMode) && {headers},
                        params: {udId: this.props.deviceId},
                    }).then(res => {
                this.setState({allApps: res.data, loading: false})
            }).catch(err => {
                showError(`${appsInDeviceMessages().FETCH_ERROR} ${err?.response?.data?.message ?? err.toString()}`);
            })
        })
    }

    onDeleteAppInDevice = () => {
        this.setState({loading: true, confirmVisibility: false}, () => {
            axios.get('/api/screen-share/uninstall-app',
                {params: {udId: this.props.deviceId, packageName: this.state.selectedAppPackage}}).then(res => {
                if (res.data) {
                    toast.success(SUCCESS_MESSAGE(), {
                        delay: 1000,
                        transition: Flip
                    })

                } else {
                    showError(`${UNKNOWN_ERROR()}: ${res.data.toString()}`);
                }
            }).catch(err => {
                showError(err?.response?.message ?? err.toString())
            })
            setTimeout(() => this.getAppsInDevice(), 1000)
        })
    }

    onStopApplication = () => {
        this.setState({loading: true, confirmVisibility: false}, () => {
            stopApplication(this.props.deviceId, this.state.selectedAppPackage).then(res => {
                if (res.data) {
                    showSuccess(appsInDeviceMessages().APPLICATION_STOPPED);
                } else {
                    showError(appsInDeviceMessages().APPLICATION_COULD_NOT_BE_STOPPED);
                }
            }).catch(err => {
                showError(err?.response?.message ?? err.toString())
            })
            setTimeout(() => this.getAppsInDevice(), 1000)
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.appsInDeviceModalVisibility !== prevProps.appsInDeviceModalVisibility && this.props.appsInDeviceModalVisibility) {
            this.getAppsInDevice()
        }
    }

    render() {
        return (
            <>
                <Modal
                    open={this.props.appsInDeviceModalVisibility}
                    onClose={() => {
                        this.props.onClose()
                        this.setState({allApps: [], selectedAppPackage: ""})
                    }}
                    closeIcon
                >
                    <Modal.Header>{APPS_IN_THIS_DEVICE_HEADER()} </Modal.Header>
                    <Modal.Content>
                        <Grid stackable centered columns={1}>
                            <Grid.Column>
                                <ReactTableV8
                                    columns={this.columns}
                                    data={this.state.allApps}
                                    loadingProp={this.state.loading}
                                    columnwiseFilterableProp={true}
                                />
                            </Grid.Column>
                        </Grid>
                    </Modal.Content>
                    <Confirm
                        size={'small'}
                        open={this.state.confirmVisibility}
                        onCancel={() => this.setState({confirmVisibility: false, selectedAppPackage: '', confirmMessage: ''})}
                        content={this.state.confirmMessage}
                        onConfirm={() => this.state.onConfirmAction()}
                        confirmButton={CONFIRM_BUTTON()}
                        cancelButton={CANCEL_BUTTON()}
                    />
                </Modal>
            </>
        );
    }
}

export default withTranslation()(withRouter(AppsInDeviceModal));
