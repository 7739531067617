import React, {Component} from 'react';
import {Button, Confirm, Divider, Form, Icon, Input, Modal, Segment,} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next'
import {Flip, toast} from 'react-toastify';
import {showError} from '../../../utils/ToastHelpers';
import {
    getWhiteList,
    updateAppRestriction,
    createAppRestriction,
    deleteAppRestriction
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { dataRefresher } from '../../../utils/Methods';
import { ACTIONS_HEADER, appRestrictionsMessages, APP_RESTRICTIONS_HEADER, CANCEL_BUTTON, CONFIRM_BUTTON, FAILED_ERROR_MESSAGE, NO_LABEL, N_A_LABEL, SAVE_BUTTON, SUCCESS_MESSAGE, YES_LABEL } from '../../../utils/UIMessages';
import { CHECKMARK_ICON, CHECK_ICON, EDIT_ICON, PLUS_ICON, USER_TIMES_ICON, X_ICON } from '../../../utils/UiIcons';

class AppRestrictionsPage extends Component {

    //New React Table V6 Columns
    columns = () => [
        {
            header: () => <div>{appRestrictionsMessages().WHITELIST_HEADER}</div>,
            accessorKey: 'packageName',
            id: 'packageName',
            filterType: 'text',
            cell: info => (<span>{info.getValue() === null ? N_A_LABEL() : <>{info.getValue()}</>}</span>)
        },
        {
            header: () => <div>{appRestrictionsMessages().ENABLED_HEADER}</div>,
            accessorFn: d => d.enabled ? YES_LABEL() : NO_LABEL(),
            id: 'enabled',
            filterType: 'dropdown',
            cell: props => (<span> {props.getValue() === YES_LABEL() ?
                <Icon name={CHECKMARK_ICON} color={'green'} /> :
                <Icon name={X_ICON} color={'red'} />
            } </span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            id: 'id',
            enableSorting: false,
            enableColumnFilter: false,
            cell: props => (<span>
                <Button size='tiny' basic icon onClick={() => {
                    this.setState({ modalVisibility: true, whiteListFields: props.row.original })
                }}><Icon name={EDIT_ICON}/></Button>
                <Button size='tiny' icon color='red' disabled={this.state.appWhiteList.length <= 1} onClick={() => {
                    this.setState({
                        confirmVisibility: true, whiteListFields: props.row.original
                    })
                }}> <Icon name={USER_TIMES_ICON} /></Button>
            </span>)
        }
    ];

    state = {
        appWhiteList: [],
        modalVisibility: false,
        whiteListFields: {},
        confirmVisibility: false,
        detailModalVisibility: false,
        loadingAppWhiteList: false,
        language: localStorage.getItem('lang') || 'en'
    }

    getWhiteList = () => {
        this.setState({ loadingAppWhiteList: true });
        //axios.get("/api/apps/restrictions")
        getWhiteList().then(res => {
            this.setState({appWhiteList: res.data})
        }).catch(err => {
            showError(appRestrictionsMessages().NOT_FECTH_APP_RESTRICTIONS + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingAppWhiteList: false }));
    }

    componentDidMount() {
        this.getWhiteList();
        this.setState({ language: localStorage.getItem('lang') || 'en' });
    }

    componentDidUpdate() {
        // When language is changed, table data will be renewed.
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.getWhiteList())
        )
    }
    onHandleChange = (e, {name, value, checked}) => {
        this.setState(prevState => (
            {
                whiteListFields: {
                    ...prevState.whiteListFields,
                    [name]: value
                }
            }))
        if (checked !== undefined) {
            this.setState(prevState => (
                {
                    whiteListFields: {
                        ...prevState.whiteListFields,
                        [name]: checked
                    }
                }))
        }
    }

    handleModalOpen = () => {
        this.setState({modalVisibility: true})
    }

    handleModalClose = () => {
        this.setState({modalVisibility: false, whiteListFields: {}})
    }

    checkForCheckboxes = () => {
        let data = this.state.whiteListFields
        if (!data.enabled) {
            data.enabled = false
        }
        return data
    }

    onSubmit = () => {
        let data = this.checkForCheckboxes()
        if (this.state.whiteListFields.id) {
            //axios.put(`/api/apps/restriction/${this.state.whiteListFields.id}`, data)
            updateAppRestriction(this.state.whiteListFields.id,data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip
                })
                this.handleModalClose();
                this.getWhiteList()
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
            })
        } else {
            //axios.post(`/api/apps/restriction`, data)
            createAppRestriction(data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip
                })
                this.handleModalClose();
                this.getWhiteList()
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
            })
        }
    }

    onDelete = () => {
        //axios.delete(`/api/apps/restriction/${this.state.whiteListFields.id}`)
        deleteAppRestriction(this.state.whiteListFields.id).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                delay: 1000,
                transition: Flip
            })
            this.setState({confirmVisibility: false, whiteListFields: {}})
            this.getWhiteList()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }

    validationCheck = () => {
        return !this.state.whiteListFields?.packageName?.trim();
    }


    render() {
        return (
            <div>
                <div className='main-right-header'>
                    <div>
                        <h2>{APP_RESTRICTIONS_HEADER()}</h2>
                        <small>{appRestrictionsMessages().APP_RESTRICTIONS_SUB_HEADER}</small>
                    </div>
                    <div className='main-right-buttons'>
                        <Button primary icon={PLUS_ICON} content={appRestrictionsMessages().ADD_NEW_APP_WHITELIST_BUTTON}
                                onClick={() => this.handleModalOpen()}/>
                    </div>

                </div>
                <ReactTableV8
                    columns={this.columns()}
                    data={this.state.appWhiteList}
                    columnwiseFilterableProp={true}
                    loadingProp={this.state.loadingAppWhiteList}
                />
                <Modal open={this.state.modalVisibility} onClose={this.handleModalClose} closeIcon
                       closeOnDimmerClick={true}
                       size={"small"}>
                    <Modal.Content>
                        <h3 style={{textAlign: "center"}}>{appRestrictionsMessages().APP_RESTRICTION_MODAL_HEADER} </h3>
                        <Divider/>
                        <Form>
                            <Form.Field required>
                                <label>{appRestrictionsMessages().WHITELIST_HEADER}</label>
                                <Input
                                    fluid
                                    name={"packageName"}
                                    value={this.state.whiteListFields && this.state.whiteListFields.packageName}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Segment>
                                <Form.Field>
                                    <Form.Checkbox
                                        label={appRestrictionsMessages().ENABLED_HEADER}
                                        onChange={this.onHandleChange}
                                        checked={this.state.whiteListFields && this.state.whiteListFields.enabled}
                                        fluid
                                        name="enabled"
                                    />
                                </Form.Field>
                            </Segment>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button compact color="green" icon={CHECK_ICON} content={SAVE_BUTTON()}
                                onClick={this.onSubmit}
                                disabled={this.validationCheck()}
                        />
                    </Modal.Actions>
                </Modal>

                <Confirm
                    content={appRestrictionsMessages().DELETE_CONFIRM}
                    open={this.state.confirmVisibility}
                    onCancel={() => {
                        this.setState({confirmVisibility: false, whiteListFields: {}})
                    }}
                    onConfirm={this.onDelete}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />

            </div>
        );
    }
}

export default withTranslation()(AppRestrictionsPage);

