import React, {Component} from 'react';
import {
    Accordion,
    Button,
    Confirm,
    Divider,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    Modal,
    Popup,
    Segment
} from "semantic-ui-react";
import {Flip, toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {showError} from "../../../utils/ToastHelpers";
import {
    getGroupList,
    createLdap,
    deleteLdap,
    updateLdap,
    testLdapAuth,
    checkLdap,
    getLdapAttr,
    getAllRoles,
    getLdapList
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { encryptData } from '../../../utils/PublicKeyHelper';
import ChangePasswordInput from '../../../components/ChangePasswordInput';
import { ACTIONS_HEADER, ACTIVE_LABEL, AUTO_REGISTER_HEADER, AUTO_REGISTER_USER_GROUPS_LABEL, AUTO_REGISTER_USER_ROLES_LABEL, CANCEL_BUTTON, CONFIRM_BUTTON, CONNECTION_SUCCESSFULL_MESSAGE, FAILED_ERROR_MESSAGE, ldapConfigMessages, LDAP_SERVERS_HEADER, NAME_HEADER, PASSWORD_HEADER, SAVE_BUTTON, SUCCESS_MESSAGE, TEST_BUTTON, USERNAME_HEADER, USE_SSL_LABEL, VALUE_LABEL } from '../../../utils/UIMessages';
import { ADDRESS_BOOK_ICON, CHECKMARK_ICON, CHECK_ICON, DROPDOWN_ICON, EDIT_ICON, EYE_ICON, EYE_SLASH_ICON, PLUS_ICON, SYNC_ICON, X_ICON } from '../../../utils/UiIcons';

class LdapConfigPage extends Component {

    state = {
        ldapServers: [],
        serverFields: {
            attributeMap: {}
        },
        groupDropdown: [],
        roleDropdown: [],
        selectedRoles: [],
        selectedGroups: [],
        modalVisibility: false,
        testModalVisibility: false,
        showPassword: false,
        username: "",
        password: "",
        // pageSize: 10,
        // page: 0,
        confirmVisibility: false,
        showPassword2: false,
        loading: false,
        activeIndex: -1,
        extraKey: "",
        extraValue: "",
        attributeMap: {},
        loadingLdapServers: false
    }

    //React Table V8
    columns = () => [
        {
            header: () => <div>{ldapConfigMessages().SERVER_NAME_HEADER}</div>,
            accessorKey: 'name',
            id: 'name',
            cell: info => (<span className='number' style={{ fontWeight: 'bold' }}>{info.getValue()}</span>)
        },
        {
            header: () => <div>{ldapConfigMessages().SERVER_HEADER}</div>,
            accessorKey: 'urls',
            id: 'urls',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{ldapConfigMessages().GROUP_SEARCH_FILTER_HEADER}</div>,
            accessorKey: 'groupSearchFilter',
            id: 'groupSearchFilter',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{ldapConfigMessages().ROOT_DN_HEADER}</div>,
            accessorKey: 'base',
            id: 'base',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{ldapConfigMessages().USER_SEARCH_FILTER_HEADER}</div>,
            accessorKey: 'userSearchFilter',
            id: 'userSearchFilter',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{ACTIVE_LABEL()}</div>,
            accessorKey: 'active',
            id: 'active',
            cell: info => (<span style={{ textAlign: 'center' }}>
                {info.getValue()
                    ? <Icon name={CHECKMARK_ICON} color={'green'} />
                    : <Icon name={X_ICON} color={'red'} />}
            </span>)
        },
        {
            header: () => <div>{AUTO_REGISTER_HEADER()}</div>,
            accessorKey: 'autoRegister',
            id: 'autoRegister',
            cell: info => (<span style={{ textAlign: 'center' }}>
                {info.getValue()
                    ? <Icon name={CHECKMARK_ICON} color={'green'} />
                    : <Icon name={X_ICON} color={'red'} />}
            </span>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'active',
            id: 'activee',
            enableColumnFilter: false,
            enableSorting: false,
            cell: props => (<span className='number'>
                <Button size='tiny' icon={EDIT_ICON} onClick={() => {
                    this.setState({
                        modalVisibility: true,
                        serverFields: props.row.original,
                        selectedGroups: props.row.original.autoRegisterUserGroups,
                        selectedRoles: props.row.original.autoRegisterUserRoles
                    })
                }}></Button>
                <Button size='tiny' icon={X_ICON} color={'red'} onClick={() => {
                    this.setState({ confirmVisibility: true, serverFields: props.row.original })
                }}></Button>
            </span>)
        }
    ]

    getLdapList = () => {
        this.setState({loadingLdapServers: true});
        getLdapList().then(res => {
            this.setState({ldapServers: res.data})
        }).catch(err => {
            showError(ldapConfigMessages().NOT_FETCH_LDAP_LIST + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({loadingLdapServers: false}));
        //axios.get("/api/ldap/attributes")
        getLdapAttr().then(res2 => {
            this.setState({attributeMap: res2.data});
        })

        //axios.get("/api/manage/roles")
        getAllRoles().then(res3 => {
            let roleDropdown = res3.data.map(role => {
                return {
                    key: role.roleId,
                    value: role.roleId,
                    text: role.name,
                }
            })
            this.setState({roleDropdown})
        })

        //axios.get("/api/groups")
        getGroupList().then(res4 => {
            let groupDropdown = res4.data.map(group => {
                return {
                    key: group.id,
                    value: group.id,
                    text: group.name,
                }
            });
            this.setState({groupDropdown});
        })
    }

    componentDidMount() {
        this.getLdapList()
    }

    onHandleChange = (e, {name, value, checked}) => {
        this.setState(prevState => (
            {
                serverFields: {
                    ...prevState.serverFields,
                    [name]: value
                }
            }))
        if (checked !== undefined) {
            this.setState(prevState => (
                {
                    serverFields: {
                        ...prevState.serverFields,
                        [name]: checked
                    }
                }))
        }
    }

    onHandleChangeTestModal = (e, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    onTogglePassword = () =>
        this.setState(
            prevState => ({
                showPassword: !prevState.showPassword
            }),
        );

    onTogglePassword2 = () =>
        this.setState(
            prevState => ({
                showPassword2: !prevState.showPassword2
            }),
        );


    handleModalOpen = () => {
        this.setState({
            modalVisibility: true, serverFields: {
                attributeMap: this.state.attributeMap,
            },
        })
    }

    handleModalClose = () => {
        this.setState({
            modalVisibility: false,
            extraKey: "",
            extraValue: "",
            activeIndex: -1,
            selectedRoles: [],
            selectedGroups: [],
        })
    }

    onSubmit = async () => {
        const data = {
            ...this.state.serverFields,
            managerDn: await encryptData(this.state.serverFields?.managerDn),
            managerPassword: await encryptData(this.state.serverFields?.managerPassword)
        }
        data.autoRegisterUserRoles = this.state.selectedRoles;
        data.autoRegisterUserGroups = this.state.selectedGroups;

        Object.keys(data).forEach((key) => (data[key] === "") && delete data[key])
        if (this.state.serverFields.id) {
            //axios.put(`/api/ldap/${this.state.serverFields.id}`, data)
            updateLdap(this.state.serverFields.id,data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip
                })
                this.handleModalClose();
                this.getLdapList()
            }).catch(err => {
                showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data?.message ?? err.toString()}`)
            })
        } else {
            //axios.post(`/api/ldap`, data)
            createLdap(data).then(() => {
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip
                })
                this.handleModalClose();
                this.getLdapList()
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
            })
        }
    }

    checkForCheckboxes = async () => {
        const data = {
            ...this.state.serverFields,
            managerDn: await encryptData(this.state.serverFields?.managerDn),
            managerPassword: await encryptData(this.state.serverFields?.managerPassword)
        }
        if (!data.active) {
            data.active = false
        }
        if (!data.allowUnauthorizedCertificates) {
            data.allowUnauthorizedCertificates = false
        }
        return data
    }

    onSubmitTest = async () => {
        let data = await this.checkForCheckboxes()
        let id;
        if (data.id) {
            id = data.id
            delete data.id
        }
        Object.keys(data).forEach((key) => (data[key] === "") && delete data[key])
        data = Object.assign(data, {
            "username": unescape(encodeURIComponent(this.state.username)),
            "password": unescape(encodeURIComponent(this.state.password))
        })
        //axios.post("/api/ldap/test", data)
        testLdapAuth(data).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                delay: 1000,
                transition: Flip
            })
            if (id) {
                data.id = id
            }
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()}: ${err?.response?.data?.message ?? err.toString()}`)
            if (id) {
                data.id = id
            }
        })
    }

    onSubmitCheckConnection = async () => {
        const data = await this.checkForCheckboxes()
        let id;
        if (data.id) {
            id = data.id
            delete data.id
        }
        Object.keys(data).forEach((key) => (data[key] === "") && delete data[key])
        //axios.post("/api/ldap/check", data)
        checkLdap(data).then(() => {
            toast.success(CONNECTION_SUCCESSFULL_MESSAGE(), {
                delay: 1000,
                transition: Flip
            })
            this.setState({loading: false})
            if (id) {
                data.id = id
            }
        }).catch(err => {
            showError(`${err?.response?.data?.message ?? err.toString()}`)
            this.setState({loading: false})
            if (id) {
                data.id = id
            }
        })
    }

    onDelete = () => {
        //axios.delete(`/api/ldap/${this.state.serverFields.id}`)
        deleteLdap(this.state.serverFields.id).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                delay: 1000,
                transition: Flip
            })
            this.setState({
                confirmVisibility: false
            })
            this.getLdapList()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }

    validationCheck = () => {
        return !(this.state.serverFields?.name?.trim() && this.state.serverFields?.urls?.trim() && 
        this.state.serverFields?.base?.trim())
    }

    handleClickExtraFields = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({activeIndex: newIndex})
    }

    onChangeExtraFields = (e, {name, value}) => {
        this.setState(prevState => (
            {
                serverFields: {
                    ...prevState.serverFields,
                    attributeMap: {
                        ...prevState.serverFields.attributeMap,
                        [name]: value
                    }
                }
            }))
    }

    onHandleChangeDropdown = (e, {name, value}) => {
        this.setState({[name]: value})
    }

    // addExtraProperties = () => {
    //     let attributeMap;
    //     if (_.isEmpty(this.state.serverFields?.attributeMap)) {
    //         attributeMap = {}
    //     } else {
    //         attributeMap = this.state.serverFields?.attributeMap
    //     }
    //     Object.assign(attributeMap, {[this.state.extraKey]: this.state.extraValue})
    //     this.setState(prevState => (
    //         {
    //             serverFields: {
    //                 ...prevState.serverFields,
    //                 attributeMap: attributeMap
    //             }
    //         }), () => {
    //         this.setState({extraKey: "", extraValue: ""})
    //     })
    // }
    //
    // deleteExtraProperties = (key) => {
    //     let attributeMap = this.state.serverFields?.attributeMap
    //     delete attributeMap[key]
    //     this.setState(prevState => (
    //         {
    //             serverFields: {
    //                 ...prevState.serverFields,
    //                 attributeMap: attributeMap
    //             }
    //         })
    //     )
    // }

    render() {
        return (
            <div>
                <div className='main-right-header'>
                    <div>
                        <h2> {LDAP_SERVERS_HEADER()}</h2>
                        <small>{ldapConfigMessages().LDAP_SERVERS_SUB_HEADER}</small>
                    </div>
                    <div className='main-right-buttons'>
                        <Button primary icon={PLUS_ICON} content={ldapConfigMessages().ADD_NEW_SERVER_BUTTON}
                                onClick={() => this.handleModalOpen()}/>
                    </div>

                </div>
                <ReactTableV8
                    data={this.state.ldapServers}
                    columns={this.columns()}
                    loadingProp={this.state.loadingLdapServers}
                />
                <Modal open={this.state.modalVisibility} onClose={this.handleModalClose} closeIcon
                       closeOnDimmerClick={true}
                       size={"small"}>

                    {/* <Header icon='user' as='h4' content={<>Ldap Configuration</>} /> */}
                    <Modal.Content>
                        <Header as='h3'>
                            {ldapConfigMessages().LDAP_FIELDS_LABEL}
                        </Header>
                        <Divider/>
                        <Form>
                            <Form.Field required>
                                <label>{ldapConfigMessages().SERVER_NAME_HEADER}</label>
                                <Input
                                    fluid
                                    name={"name"}
                                    value={this.state.serverFields && this.state.serverFields.name}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{ldapConfigMessages().SERVER_HEADER} URL</label>
                                <Input
                                    fluid
                                    name={"urls"}
                                    value={this.state.serverFields && this.state.serverFields.urls}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>

                            <Form.Field required>
                                <label>{ldapConfigMessages().ROOT_DN_HEADER}</label>
                                <Input
                                    fluid
                                    name={"base"}
                                    value={this.state.serverFields && this.state.serverFields.base}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{ldapConfigMessages().USER_SEARCH_FILTER_HEADER}</label>
                                <Input
                                    fluid
                                    name={"userSearchFilter"}
                                    value={this.state.serverFields && this.state.serverFields.userSearchFilter}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{ldapConfigMessages().GROUP_SEARCH_FILTER_HEADER}</label>
                                <Input
                                    fluid
                                    name={"groupSearchFilter"}
                                    value={this.state.serverFields && this.state.serverFields.groupSearchFilter}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{ldapConfigMessages().MANAGER_DN_LABEL}</label>
                                <Input
                                    fluid
                                    name={"managerDn"}
                                    value={this.state.serverFields?.managerDn}
                                    onChange={this.onHandleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{ldapConfigMessages().MANAGER_PASSWORD_LABEL}</label>
                                {!(this.state.serverFields?.id) ? <Input
                                    name={"managerPassword"}
                                    value={this.state.serverFields?.managerPassword}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    onChange={this.onHandleChange}
                                    icon={
                                        <Icon
                                            name={this.state.showPassword ? EYE_SLASH_ICON : EYE_ICON}
                                            link
                                            onClick={this.onTogglePassword}
                                        />
                                    }
                                /> :
                                    <ChangePasswordInput
                                        name='managerPassword'
                                        value={this.state.serverFields?.managerPassword}
                                        onChange={this.onHandleChange}
                                        reset={() => this.setState(prevState => ({
                                            serverFields: {
                                                ...prevState.serverFields,
                                                managerPassword: null
                                            }
                                        }))}
                                    />
                                }
                            </Form.Field>
                            <Segment>
                                <Form.Field>
                                    <Form.Checkbox
                                        required
                                        label={ACTIVE_LABEL()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.serverFields && this.state.serverFields.active}
                                        fluid
                                        name="active"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                        required
                                        label={USE_SSL_LABEL()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.serverFields && this.state.serverFields.allowUnauthorizedCertificates}
                                        fluid
                                        name="allowUnauthorizedCertificates"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                        required
                                        label={AUTO_REGISTER_HEADER()}
                                        onChange={this.onHandleChange}
                                        checked={this.state.serverFields && this.state.serverFields.autoRegister}
                                        fluid
                                        name="autoRegister"
                                    />
                                </Form.Field>
                            </Segment>
                            <Form.Field>
                                <label>{AUTO_REGISTER_USER_ROLES_LABEL()}</label>
                                <Dropdown
                                    selection multiple options={this.state.roleDropdown} clearable
                                    name={"selectedRoles"}
                                    onChange={this.onHandleChangeDropdown} value={this.state.selectedRoles}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{AUTO_REGISTER_USER_GROUPS_LABEL()}</label>
                                <Dropdown
                                    selection multiple options={this.state.groupDropdown} clearable
                                    name={"selectedGroups"}
                                    onChange={this.onHandleChangeDropdown} value={this.state.selectedGroups}/>
                            </Form.Field>
                            <Accordion fluid>
                                <Accordion.Title
                                    active={this.state?.activeIndex === 0}
                                    index={0}
                                    onClick={this.handleClickExtraFields}
                                >
                                    <Icon name={DROPDOWN_ICON}/>
                                    {ldapConfigMessages().EXTRA_LDAP_FIELDS_LABEL}
                                </Accordion.Title>
                                <Accordion.Content active={this.state?.activeIndex === 0}>

                                    {!_.isEmpty(this.state.serverFields?.attributeMap) && Object.keys(this.state.serverFields?.attributeMap).map(attribute => {
                                        return <>
                                            <Form.Group widths='equal'>
                                                <Form.Input
                                                    fluid
                                                    label={NAME_HEADER()}
                                                    value={attribute}
                                                />
                                                <Form.Input
                                                    fluid
                                                    label={VALUE_LABEL()}
                                                    name={attribute}
                                                    value={this.state?.serverFields?.attributeMap[attribute]}
                                                    onChange={this.onChangeExtraFields}
                                                />
                                                {/*<Button*/}
                                                {/*    color="red"*/}
                                                {/*    inverted*/}
                                                {/*    icon="x"*/}
                                                {/*    style={{height: "inherit", marginTop: "auto"}}*/}
                                                {/*    onClick={()=>{this.deleteExtraProperties(attribute)}}/>*/}
                                                {/* There is no remove support in backend side */}
                                            </Form.Group>
                                        </>
                                    })}
                                    {/*<Segment color={"green"}>*/}
                                    {/*    <Header size={"small"}>*/}
                                    {/*        <Icon name='plus'/>*/}
                                    {/*        <Header.Content>Add New Field</Header.Content>*/}
                                    {/*    </Header>*/}
                                    {/*    <Form.Group widths='equal'>*/}
                                    {/*        <Form.Input*/}
                                    {/*            fluid*/}
                                    {/*            label='Key'*/}
                                    {/*            name="extraKey"*/}
                                    {/*            onChange={(e, {name, value}) => this.setState({[name]: value})}*/}
                                    {/*            value={this.state.extraKey}*/}
                                    {/*        />*/}
                                    {/*        <Form.Input*/}
                                    {/*            fluid*/}
                                    {/*            label='Value'*/}
                                    {/*            name="extraValue"*/}
                                    {/*            onChange={(e, {name, value}) => this.setState({[name]: value})}*/}
                                    {/*            value={this.state.extraValue}*/}
                                    {/*        />*/}
                                    {/*        <Button*/}
                                    {/*            color="green"*/}
                                    {/*            inverted*/}
                                    {/*            icon="plus"*/}
                                    {/*            style={{height: "inherit", marginTop: "auto"}}*/}
                                    {/*            disabled={this.state.extraValue === "" || this.state.extraKey === ""}*/}
                                    {/*            onClick={this.addExtraProperties}/>*/}
                                    {/*    </Form.Group>*/}
                                    {/*</Segment>*/}
                                </Accordion.Content>
                            </Accordion>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Popup
                            content={ldapConfigMessages().CHECK_AUTHENTICATION_PARAMETER_OR_NETWORK_PARAMETER}
                            trigger={
                                <Button compact icon={SYNC_ICON} color={"yellow"}
                                        content={ldapConfigMessages().LDAP_CONNECTION_TEST_BUTTON}
                                        loading={this.state.loading}
                                        disabled={this.state.serverFields.urls === undefined || this.state.serverFields.urls === ""}
                                        onClick={() => {
                                            this.onSubmitCheckConnection()
                                        }}/>
                            }
                            position='top center'
                            size='mini'
                        />

                        <Button compact icon={ADDRESS_BOOK_ICON} color={"blue"}
                                content={ldapConfigMessages().USER_AUTHENTICATION_TEST_BUTTON}
                                disabled={this.validationCheck()}
                                onClick={() => {
                                    this.setState({testModalVisibility: true})
                                }}/>
                        <Button compact color="green" icon={CHECK_ICON} content={SAVE_BUTTON()}
                                onClick={this.onSubmit}
                                disabled={this.validationCheck()}/>
                    </Modal.Actions>
                </Modal>

                <Modal
                    closeIcon
                    open={this.state.testModalVisibility}
                    onClose={() => {
                        this.setState({testModalVisibility: false, username: "", password: ""})
                    }}
                    size='small'
                    centered
                >
                    <Modal.Header>{ldapConfigMessages().TEST_LDAP_SETTINGS_HEADER}</Modal.Header>
                    <Modal.Content>
                        <p>{ldapConfigMessages().TEST_LDAP_SETTINGS_SUB_HEADER}</p>
                        <Form>
                            <Form.Field>
                                <label>{USERNAME_HEADER()}</label>
                                <Input
                                    fluid
                                    name={"username"}
                                    value={this.state.username}
                                    onChange={this.onHandleChangeTestModal}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{PASSWORD_HEADER()}</label>
                                <Input
                                    type={this.state.showPassword2 ? 'text' : 'password'}
                                    fluid
                                    name={"password"}
                                    value={this.state.password}
                                    onChange={this.onHandleChangeTestModal}
                                    icon={
                                        <Icon
                                            name={this.state.showPassword2 ? EYE_SLASH_ICON : EYE_ICON}
                                            link
                                            onClick={this.onTogglePassword2}
                                        />
                                    }
                                />
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="green" icon={CHECK_ICON} content={TEST_BUTTON()} onClick={this.onSubmitTest}/>
                    </Modal.Actions>
                </Modal>
                <Confirm
                    open={this.state.confirmVisibility}
                    content={ldapConfigMessages().DELETE_CONFIRM}
                    onCancel={() => {
                        this.setState({confirmVisibility: false, serverFields: {attributeMap: this.state.attributeMap}})
                    }}
                    onConfirm={this.onDelete}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />
            </div>
        );
    }
}

export default withTranslation()(LdapConfigPage);
