import { SET_STOMP_WEBSOCKET_CLIENT } from '../actions/types';

const initialState = {
    client: null
}


export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STOMP_WEBSOCKET_CLIENT:
            return {
                ...state,
                client: action.payload
            }
        default:
            return state;
    }
}
