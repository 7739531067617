import React, {useEffect,useState} from 'react'
import { getNodeList } from '../../../api/apiCalls';
import { showError } from '../../../utils/ToastHelpers';
import {Icon,Button} from 'semantic-ui-react'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import SlaveModal from './SlaveModal';
import {otherOpsMessages, NAME_HEADER, IP_HEADER, PORT_HEADER, LOCATION_HEADER, ACTIONS_HEADER, secondaryServerConfigMessages} from '../../../utils/UIMessages'
import {EDIT_ICON} from '../../../utils/UiIcons'

const SlaveConfigPage = () => {

    const [slaveList,setSlaveList] = useState([]);
    const [modalVisibility,setModalVisibility] = useState(false);
    const [selectedSlave,setSelectedSlave] = useState(null);
    const [loadingSlaveServers,setLoadingSlaveServers]= useState(false)

    useEffect(()=>{
        fetchNodeList();
    },[])

    const fetchNodeList = async() =>{
        setLoadingSlaveServers(true);
        try {
            const response = await getNodeList();
            setSlaveList(response.data);
        } catch (error) {
            showError(error?.response?.data?.message || otherOpsMessages().NOT_FETCH_NODES)
        } finally{
            setLoadingSlaveServers(false)
        }
    }

    useEffect(()=>{
        if(selectedSlave !== null){
            setModalVisibility(true);
        }
    },[selectedSlave])


    const handleOnCloseModal = flag =>{
        if(flag){
            fetchNodeList()
        }
        setSelectedSlave(null);
        setModalVisibility(false);
    }

    //React Table V8
    const columns = () => [
            {
                header: () => <div>{NAME_HEADER()}</div>,
                accessorKey: 'name',
                id: 'name',
                cell: info => (<span className='number' style={{ fontWeight: 'bold' }}>{info.getValue()}</span>)
            },
            {
                header: () => <div>{IP_HEADER()}</div>,
                accessorKey: 'ip',
                id: 'ip',
                cell: info => (<span className='number'>{info.getValue()}</span>)
            },
            {
                header: () => <div>{PORT_HEADER()}</div>,
                accessorKey: 'port',
                id: 'port',
                cell: info => (<span className='number'>{info.getValue()}</span>)
            },
            {
                header: () => <div>{LOCATION_HEADER()}</div>,
                accessorKey: 'location',
                id: 'location',
                cell: info => (<span className='number'>{info.getValue()}</span>)
            },
            {
                header: () => <div>{ACTIONS_HEADER()}</div>,
                accessorKey: 'id',
                id: 'id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: info => {
                    return <span className='number'>
                             <Button 
                                size='tiny' 
                                onClick={() => setSelectedSlave(info.row.original)} 
                                icon={<Icon name={EDIT_ICON} />} /> 
                           </span>
                }
            }
        ]
  
    return (
        <div>
            <div className='main-right-header'>
                <div>
                    <h2>{secondaryServerConfigMessages().SECONDARY_SERVER_CONFIGURATION_HEADER}</h2>
                    <small>{secondaryServerConfigMessages().SECONDARY_SERVER_CONFIGURATION_SUB_HEADER}</small>
                </div>
            </div>
            <ReactTableV8
                data={slaveList}
                columns={columns()}
                loadingProp={loadingSlaveServers}
            />
            <SlaveModal
                open={modalVisibility}
                onClose={handleOnCloseModal}
                selectedSlave={selectedSlave}
            />
        </div>
    )
}

export default SlaveConfigPage;