import React, { useEffect, useState } from 'react';
import { Modal, Form, Dropdown, Button} from 'semantic-ui-react';
import { showError, showSuccess } from '../utils/ToastHelpers';
import { useTranslation } from 'react-i18next';
import {deleteReservation, getReservationCancelReasons} from '../api/apiCalls';
import { cancelReservationMessages, CONFIRM_BUTTON } from '../utils/UIMessages';
import { TRASH_ICON } from '../utils/UiIcons';

const CancelReservationModal = props => {
  const { t } = useTranslation();

  const [cancelReasonsDropdown, setCancelReasonsDropdown] = useState([]);
  const [selectedReason, setSelectedReason] = useState('WRONG');
  const [loadingCancelResDropdown, setLoadingCancelResDropdown] = useState(false);
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem('lang') || 'en');

  const getCancelReasons = async () => {
    setLoadingCancelResDropdown(true);
    try {
      const res = await getReservationCancelReasons();
      const dropdownReasons = Object.keys(res.data).map(reason => {
        return {
            key: reason,
            value: reason,
            text: t(res.data[reason])
        };
      });
      setCancelReasonsDropdown(dropdownReasons);
    } catch (err) {
      showError(`${cancelReservationMessages().CANCELATION_REASON_ERROR}: ${err?.response?.status}`);
    } finally {
      setLoadingCancelResDropdown(false);
    }
  }

  const handleChangeCancelReason = (_e, {value}) => {
    setSelectedReason(value);
  }

  const handleDeleteReservation = async () => {
    const { id } = props;
    try {
      await deleteReservation(id, selectedReason);
      showSuccess(cancelReservationMessages().CANCEL_RESERVATION_SUCCESSFULL);
      if (props.afterDeleteCallback) {
        props.afterDeleteCallback();
      }
    } catch (err) {
      if (err?.response?.status === 405) {
        showError(`${cancelReservationMessages().CANCEL_RESERVATION_ERROR}: ${err?.response?.data}`);
      } else if (err?.response?.status===400) {
        showError(`${t(err?.response?.data)}`);
      } else {
        showError(`${cancelReservationMessages().CANCEL_RESERVATION_ERROR}: ${err?.response?.data?.message ?? err.toString()}`);
      }
    }
  };

  useEffect(() => {
    if (props.open) {
      if (cancelReasonsDropdown.length === 0) {
        //if Dev List is opened and directly change language, this condition prevents double get request
        if (localStorage.getItem('lang') !== selectedLang) {
          setSelectedLang(localStorage.getItem('lang') || 'en');
        }
        return getCancelReasons();
      }

      //Condition for reaction against change of the language.
      if (localStorage.getItem('lang') !== selectedLang) {
        setSelectedLang(localStorage.getItem('lang') || 'en');
        return getCancelReasons();
      }
    }

    return undefined;
  }, [props.open, localStorage.getItem('lang')]);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      size='small'
      closeOnEscape={true}
      closeIcon
      centered={false}
    >
      <Modal.Header>{cancelReservationMessages().MODAL_HEADER}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>{cancelReservationMessages().CANCEL_REASON_LABEL}</label>
            <Dropdown selection fluid options={cancelReasonsDropdown}
              placeholder={cancelReservationMessages().CANCEL_REASON_LABEL} value={selectedReason}
              loading={loadingCancelResDropdown}
              disabled={loadingCancelResDropdown}
              onChange={handleChangeCancelReason} />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loadingCancelResDropdown}
          disabled={loadingCancelResDropdown}
          icon={TRASH_ICON}
          content={CONFIRM_BUTTON()}
          color='red'
          onClick={handleDeleteReservation} />
      </Modal.Actions>
    </Modal>
  );
}

export default CancelReservationModal;
