import React, {Component} from 'react';
import {Table, Button, Popup} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import { CLEAR_BUTTON, COPIED_LABEL, selectedElementPanelMessages, SELECTOR_LABEL, SEND_KEYS_BUTTON, VALUE_LABEL } from '../utils/UIMessages';
import { COPY_OUTLINE_ICON } from '../utils/UiIcons';
import _ from 'lodash';
class SelectedElementPanel extends Component {

    selectedElementFindByPanel = elementSelected => {
        const keyStyle = {'wordBreak': 'break-all', 'white-space': 'pre-wrap'}
        if (elementSelected !== {} && elementSelected !== undefined) {
            return Object.keys(elementSelected).map((spec, i) => {
                const elementAttributes = elementSelected.attributes
                const accessibilityId = _.isEmpty(elementAttributes['content-desc']) ? '-' : elementAttributes['content-desc'];
                if (spec === 'xpath') {
                    return <>
                    <Table.Row key='xpath'>
                        <Table.Cell style={keyStyle}><span>xpath</span></Table.Cell>
                        <Table.Cell style={keyStyle}>{elementSelected[spec]}</Table.Cell>
                    </Table.Row>
                    <Table.Row key='content-desc'>
                        <Table.Cell style={keyStyle}><span>accessibility-id</span></Table.Cell>
                        <Table.Cell style={keyStyle}>{accessibilityId}</Table.Cell>
                    </Table.Row>
                    </>
                } else if (spec === 'attributes' && elementAttributes['resource-id'] !== undefined) {
                    return <Table.Row key="resource-id">
                        <Table.Cell style={keyStyle}><span>resource-id</span></Table.Cell>
                        <Table.Cell style={keyStyle}>{elementAttributes['resource-id']}</Table.Cell>
                    </Table.Row>
                } else {
                    return <Table.Row key={'spec-' + i} />
                }
            })
        }
        return <Table.Row/>
    };

    
    selectedElementAttributePanel = elementSelected => {
        const keyStyle = {'wordBreak': 'keep-all'}
        if (elementSelected !== {} && elementSelected !== undefined) {
            const elementAttributes = elementSelected.attributes
            if(elementAttributes !=={} && elementAttributes !== undefined){
                return Object.keys(elementAttributes).map(elementAttributeKey => {
                    return <Table.Row key={elementAttributeKey} className='manage-inspector-attribute-table-cells'>
                                <Table.Cell style={keyStyle}><span>{elementAttributeKey}</span></Table.Cell>
                                <Table.Cell style={keyStyle}><span>{elementAttributes[elementAttributeKey]}</span></Table.Cell>
                            </Table.Row>
                })
            }
        }
        return <Table.Row/>
    };


    handleTap = () => {
        this.props.handleSelectedElementTap();
        this.props.renewInspectorScreenSource('appiumAction');
    };

    handleSendKeys = () => {
        this.props.handleSelectedElementSendKeys();
    };

    handleClear = () => {
        this.props.handleSelectedElementClear();
        this.props.renewInspectorScreenSource('appiumAction');
    };

    handleCopy = () => {
        this.props.handleSelectedElementCopy()
    };

    render(){
        const buttonGroupStyle = {'marginTop': '10px'};
        return(
            <div id="SelectedElementPanel">
                <div style={{'textAlign':'center'}}>
                    <Button.Group basic style={buttonGroupStyle}>
                        <Button onClick={this.handleTap} loading={this.props.elementTapLoading} disabled={this.props.elementTapLoading}>{selectedElementPanelMessages().TAP_BUTTON}</Button>
                        <Button onClick={this.handleSendKeys}>{SEND_KEYS_BUTTON()}</Button>
                        <Button onClick={this.handleClear} loading={this.props.elementClearLoading} disabled={this.props.elementClearLoading}>{CLEAR_BUTTON()}</Button>
                        <Popup content={COPIED_LABEL()} on='click' pinned
                            trigger={<Button onClick={this.handleCopy} icon={COPY_OUTLINE_ICON}/>}/>
                    </Button.Group>
                </div>
                    <Table >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{selectedElementPanelMessages().FIND_BY_LABEL}</Table.HeaderCell>
                                <Table.HeaderCell>{SELECTOR_LABEL()}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.selectedElementFindByPanel(this.props.selectedElement)}
                        </Table.Body>
                    </Table>
                  <div className='manage-inspector-attribute-table-div'>
                      <Table basic='very'>
                          <Table.Header >
                              <Table.Row>
                                  <Table.HeaderCell className='manage-inspector-attribute-table-head-cell'>{selectedElementPanelMessages().ATTRIBUTE_LABEL}</Table.HeaderCell>
                                  <Table.HeaderCell className='manage-inspector-attribute-table-head-cell'>{VALUE_LABEL()}</Table.HeaderCell>
                              </Table.Row>
                          </Table.Header>
                          <Table.Body>
                              {this.selectedElementAttributePanel(this.props.selectedElement)}
                          </Table.Body>
                      </Table>
                  </div>
            </div>
        )
    }
}


export default withTranslation()(SelectedElementPanel);
