import { START_LOADING_DEVICE_LIST, END_LOADING_DEVICE_LIST, SET_DEVICE_LIST, SET_DEVICE_LIST_FILTER } from "../actions/types";

const initialState = {
    loading: true,
    loadingContent: "",
    deviceList: [],
    deviceListFilter: []
};

export default (state, action) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case START_LOADING_DEVICE_LIST:
            return {
                ...state, loading: true,
                loadingContent: action.loadingContent
            };
        case END_LOADING_DEVICE_LIST:
            return {
                ...state, loading: false
            };
        case SET_DEVICE_LIST:
            return {
                ...state,
                deviceList: action.data
            }
        case SET_DEVICE_LIST_FILTER:
            return {
                ...state,
                deviceListFilter: action.data
            }
        default:
            return state
    }

}
