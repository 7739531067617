import React, { useState, useEffect } from "react";
import {Button, Form, Input, Icon, Popup, Modal, Header} from 'semantic-ui-react';
import {
    updateVisiumManageIntegration
} from '../../../api/apiCalls';
import { ACTIVE_LABEL, integrationsMessages, KEY_LABEL, PASSIVE_LABEL, SUCCESS_MESSAGE, URL_LABEL, SUBMIT_BUTTON } from '../../../utils/UIMessages';
import { CHECKMARK_ICON, EDIT_ICON, PLUS_ICON, X_ICON } from '../../../utils/UiIcons';
import { POPUP_POSITIONS } from '../../../utils/Constants';
import {showError, showInfo, showSuccess} from '../../../utils/ToastHelpers';
import { validateUrl } from '../../../utils/Methods';
import { PrivilegeConstants } from '../../../utils/PrivilegeConstants';
import { initialParam } from "./IntegrationEdit";

const IntegrationEditModal = ({open, integrationData, setOpen, privileges, setIntegrationData}) => {

    const [visiumManageInfo, setVisiumManageInfo] = useState({});

    //Loading state
    const [loading, setLoading] = useState(false);

    useEffect(()=> {
        setVisiumManageInfo(integrationData);
    }, [integrationData])

    //Add parameter method
    const addParameter = () => {
        setVisiumManageInfo(prev => ({...prev, integrationParam: [...prev.integrationParam, {...initialParam}]}));
    };

    //Activity Checkbox method
    const handleActivityCheckbox = () => {
        setVisiumManageInfo(prev => ({...prev, active: !prev.active}));
    };

    //Url method
    const handleEditUrl = e => {
        setVisiumManageInfo(prev => ({...prev, url: e.target.value}));
    };

    //Update method
    const updateVisiumManageIntegrationWrapper = data => {
        updateVisiumManageIntegration(data).then(res => {
            setLoading(false);
            setIntegrationData(res.data);
            showSuccess(SUCCESS_MESSAGE())
            setOpen(!open);
        }).catch(err => {
            showError(`${integrationsMessages().UPDATE_ERROR_MESSAGE} ${err?.response?.data?.message ?? err.toString()}`);
        });
    }

    //Submit method
    const handleSubmit = () => {
        setLoading(true);
        if (privileges.includes(PrivilegeConstants.INTEGRATION)) {
            if (validateUrl(visiumManageInfo.url)) {
                if(validateIntegrationParamKey(visiumManageInfo.integrationParam) && validateIntegrationParamValue(visiumManageInfo.integrationParam) && !isContainsDuplicates(visiumManageInfo.integrationParam)){
                    updateVisiumManageIntegrationWrapper(visiumManageInfo);
                }else{
                    showError(integrationsMessages().EMPTY_OR_DUPLICATE_KEY);
                    setLoading(false);
                }
            } else {
                showError(integrationsMessages().WRONG_URL_FORMAT_MESSAGE);
                setLoading(false);
            }
        }
    };

    //Validation of key method
    const validateIntegrationParamKey = (params) => {
        if(params?.length){
            return !params.map(param => param.paramKey).some(key => !key);
        }
        return true;
    }

    //Validation of param method
    const validateIntegrationParamValue = (params) => {
        if(params?.length){
            return !params.map(param => param.paramValue).some(value => !value);
        }
        return true;
    }

    //Duplicate check method
    function isContainsDuplicates(array) {
        let keys = [];
        array.forEach(obj => {
            keys.push(obj.paramKey);
        });
        keys = keys.map(e => e.trim());
        if (keys.length !== new Set(keys).size && !keys.includes('')) {
          return true;
        }
        return false;
    }
    
    //Api change method
    const handleApiChange = (e, index) => {
        const {name, value} = e.target;
        const param = visiumManageInfo.integrationParam;
        const newParams = [...param];
        newParams.splice(index, 1, {...param[index], [name]: value});
        setVisiumManageInfo(prev => ({...prev, integrationParam: newParams}));
    };

    //Remove paramter method
    const removeApiParameter = index => {
        const list = [...visiumManageInfo.integrationParam];
        list.splice(index, 1);
        setVisiumManageInfo(prev => ({...prev, integrationParam: list}));
    };

    return(
        <div>
            <Modal
                closeIcon
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onOpen={() => setOpen(true)}
                size={'small'}
            >
                <Header icon={EDIT_ICON} content={integrationsMessages().EDIT_INTEGRATION_VALUES} />
                <Modal.Content>
                    <Form.Field>
                        <Form>
                            <Form.Field>
                                <label>{URL_LABEL()}:</label>
                                <Input fluid name='url' onChange={handleEditUrl} value={visiumManageInfo?.url} />
                            </Form.Field>
                            <Form.Field>
                                <Form.Checkbox
                                    toggle
                                    label={visiumManageInfo?.active ? ACTIVE_LABEL() : PASSIVE_LABEL()}
                                    onChange={handleActivityCheckbox}
                                    checked={visiumManageInfo?.active}
                                    name='active'
                                    id='activation-label'
                                />
                            </Form.Field>
                            <Form.Field id='integration-default-parameter'>
                                <label>{integrationsMessages().DEFAULT_PARAMETERS_LABEL}</label>
                                <Popup
                                    position={POPUP_POSITIONS.RIGHT_CENTER}
                                    content={integrationsMessages().ADD_NEW_PARAMETER_POPUP}
                                    trigger={
                                        <Button
                                            className='integration-edit-plusbutton'
                                            primary
                                            icon={PLUS_ICON}
                                            onClick={() => {
                                                addParameter();
                                            }}
                                        />
                                    }
                                />
                            </Form.Field>
                            {(visiumManageInfo?.integrationParam !== undefined && visiumManageInfo?.integrationParam.length > 0) &&
                                <Form.Field className="api-parameters">
                                        {visiumManageInfo?.integrationParam?.map((_singleParameter, index) => (
                                            <div className='integration-param-row' key={index}>
                                                <span className='integration-param-span'>{KEY_LABEL()}:</span>
                                                <Input
                                                    key={index}
                                                    name='paramKey'
                                                    value={_singleParameter.paramKey}
                                                    className='integration-param-span'
                                                    onChange={e => handleApiChange(e, index)}
                                                />
                                                <span className='integration-param-span'>{integrationsMessages().VALUE_LABEL}:</span>
                                                <Input
                                                    key={index}
                                                    name='paramValue'
                                                    value={_singleParameter.paramValue}
                                                    onChange={e => handleApiChange(e, index)}
                                                    id='integration-edit-keyvalue'
                                                />
                                                <Button
                                                    id='integration-param-span-remove-button'
                                                    size='tiny'
                                                    icon={X_ICON}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        removeApiParameter(index);
                                                        showInfo(integrationsMessages().PARAMETER_DELETE_MESSAGE);
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </Form.Field>}
                        </Form>
                
                    </Form.Field>
                </Modal.Content>

                <Modal.Actions>
                    <Button
                      loading={loading}
                      color='green'
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                    <Icon name={CHECKMARK_ICON} />{SUBMIT_BUTTON()}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default IntegrationEditModal;