import {combineReducers} from 'redux';
import notificationReducer from './notificationReducer';
import deviceListReducer from './deviceListReducer';
import stompClientReducer from './stompClientReducer';
import systemParametersReducer from './systemParametersReducer';
import inspectorReducer from './inspectorReducer';

export default combineReducers({
    notification: notificationReducer,
    deviceList: deviceListReducer,
    stompClient:stompClientReducer,
    systemParameters: systemParametersReducer,
    inspector: inspectorReducer
})
