import React, {Component} from 'react';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"

class ApiDocumentationPage extends Component {
    render() {
        return (
            <div className='main-container-api-doc'>
                <SwaggerUI url={'/api/doc/'}
                    requestInterceptor={(request)=>{
                        const accessToken = localStorage.getItem('accessToken');
                        request.headers.Authorization = `Bearer ${accessToken}`
                        return request
                    }}
                />
            </div>
        );
    }
}

export default ApiDocumentationPage;
