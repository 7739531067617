import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
    Button,
    Dropdown,
    Header,
    Icon,
    Input,
    Menu,
    Popup,
    Segment,
    Tab,
    Form
} from 'semantic-ui-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
    cardContent,
    editPackageName,
    getPrivileges,
    dataRefresher
} from '../../utils/Methods';
import { showError, showSuccess } from '../../utils/ToastHelpers';
import DebouncedInput from '../../components/ReactTableV8/DebouncedInput'

import {
    getAppList,
    getApp,
    getFileList,
    deployBulkFile    
} from '../../api/apiCalls'
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8'




import { connect } from 'react-redux';
import { setDeviceList, setDeviceListFilter } from '../../actions';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import { ACTIONS_HEADER, APPLICATION_HEADER, AVAILABILITY_HEADER, BRAND_HEADER, CATEGORY_LABEL, CLIENT_IP_HEADER, depAppListMessages, DEPLOYMENT_HEADER, DEPLOY_BUTTON, DEVICE_LIST_HEADER, FAILED_ERROR_MESSAGE, FILE_HEADER, FREE_LABEL, ID_HEADER, LIBRARY_HEADER, MAINTENANCE_LABEL, MODEL_HEADER, NOT_FETCH_APPS, NOT_FETCH_DEVICES, N_A_LABEL, OFFLINE_LABEL, ONLINE_FREE_LABEL, OS_HEADER, OS_VERSION_HEADER, RESERVED_LABEL, SCREEN_RESOLUTION_HEADER, SCREEN_SIZE_HEADER, SELECT_ALL_BUTTON, SUCCESS_MESSAGE, UNKNOWN_LABEL } from '../../utils/UIMessages';
import { ANGLE_LEFT_ICON, ASTERISK_ICON, CHECKMARK_ICON, CHEVRON_LEFT_ICON, CHEVRON_RIGHT_ICON, HAND_POINT_UP_OUTLINE_ICON, QUESTION_CIRCLE_OUTLINE_ICON, X_ICON } from '../../utils/UiIcons';

class DepAppList extends Component {

    state = {
        appList: [],
        versionList: [],
        showModal: false,
        selectedApp: '',
        selectedVersion: '',
        dropdownApp: [],
        dropdownVersion: [],
        chosenDevices: [],
        operatingSystem: '',
        loading: false,
        deviceCompatibility: false,
        fileFormData: undefined,
        activeIndex: 0,
        pageIndex: 0,
        pageSize: 0,
        deviceListForSelectAll: [],
        privileges: [],
        loadingDeviceList: false,
        fileUploadRadioSelection: 'local',
        loadingFileList: 'true',
        fileList: [],
        dropdownFileList: [],
        selectedFile: '',
        tableRef: {},
        language: localStorage.getItem('lang') || 'en',
        fileSearchInput: '',
        openDropdown: false
    };

    panesRender = () => [
        (this.state.privileges?.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS) && this.state.privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.INSTALL))
            ? {
                menuItem: APPLICATION_HEADER(),
                render: () => (
                    <>
                        <h4 style={{ fontWeight: 300 }}>{depAppListMessages().SELECT_APP_LABEL}</h4>
                        <Dropdown
                            placeholder={`${depAppListMessages().SELECT_APP_LABEL}`}
                            options={this.state.dropdownApp}
                            selection
                            fluid
                            value={this.state.selectedApp}
                            onChange={this.handleAppChange}
                            className='selectAppDropdown'
                        />

                        <Dropdown
                            style={{ marginTop: 12 }}
                            placeholder={depAppListMessages().SELECT_VERSION_LABEL}
                            disabled={this.state.selectedApp === ''}
                            selection
                            fluid
                            options={this.state.dropdownVersion}
                            value={this.state.selectedVersion}
                            onChange={this.handleVersionChange}
                        />
                    </>
                )
            }
            : {},
            (this.state.privileges?.includes(PrivilegeConstants.FILE_MANAGEMENT.LIST) && this.state.privileges?.includes(PrivilegeConstants.FILE_MANAGEMENT.DEPLOY))
            ? {
                menuItem: FILE_HEADER(),
                render: () => (
                    <>
                        <h4 style={{ fontWeight: 300 }}>{depAppListMessages().SELECT_FILE_LABEL}:</h4>
                        <Form>
                            <Form.Group>
                                <Form.Radio
                                    label={depAppListMessages().LOCAL_LABEL}
                                    value='local'
                                    checked={this.state.fileUploadRadioSelection === 'local'}
                                    onChange={this.handleChange}
                                />
                                <Form.Radio
                                    label={LIBRARY_HEADER()}
                                    value='library'
                                    checked={this.state.fileUploadRadioSelection === 'library'}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </Form>
                        {this.state.fileUploadRadioSelection === 'local' ?
                            <>
                                <h4 style={{ fontWeight: 300 }}>{depAppListMessages().SELECT_FILE_LABEL}</h4>
                                <Input
                                    type={'file'}
                                    onChange={this.onChangeUpload}
                                    fluid
                                />
                            </>
                            :
                            <>
                                <h4 style={{ fontWeight: 300 }}>
                                    {depAppListMessages().SELECT_FILE_LABEL}
                                    <span style={{ marginLeft: '5px' }}>
                                        <Popup
                                            style={{ padding: '10px' }}
                                            trigger={<Icon name={QUESTION_CIRCLE_OUTLINE_ICON} />}
                                            content={depAppListMessages().POPUP_MESSAGE}
                                        />
                                    </span>
                                </h4>
                                <Dropdown
                                    open={this.state.openDropdown}
                                    onOpen={() => this.setState({ openDropdown: true })}
                                    onBlur={() => {
                                        if (this.state.fileSearchInput.trim().length > 0) {
                                            this.setState({ fileSearchInput: '', openDropdown: false }, () => this.getFiles());
                                        } else {
                                            this.setState({ openDropdown: false });
                                        }
                                    }}
                                    onChange={this.handleFileChange}
                                    value={this.state.selectedFile}
                                    options={this.state.dropdownFileList}
                                    placeholder={depAppListMessages().DROPDOWN_PLACEHOLDER}
                                    loading={this.state.loadingFileList}
                                    selection
                                    noResultsMessage={depAppListMessages().DROPDOWN_NO_RESULT}
                                    fluid
                                    className='selectAppDropdown'
                                    clearable
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item >
                                            <DebouncedInput
                                                iconProp={true}
                                                valueProp={this.state.fileSearchInput}
                                                onChangeProp={value => {
                                                    //To prevent double request to the server when dropdown is blured
                                                    //this check is required.
                                                    if (this.state.openDropdown) {
                                                        this.setState({ fileSearchInput: value });
                                                        this.getFiles(value)
                                                    }
                                                }}
                                                className='debounce'
                                                debounceProp={1000}
                                                placeHolderProp={depAppListMessages().DEBOUNCE_PLACEHOLDER}
                                                onKeyUp={e => {
                                                    if (e.keyCode === 32) {
                                                        e.target.value = e.target.value + ' '
                                                        e.stopPropagation()
                                                    }
                                                }}
                                            />
                                        </Dropdown.Item>
                                        {this.state.dropdownFileList.map(option => (
                                            <Dropdown.Item onClick={() => {
                                                const value = option.value;
                                                this.setState({ openDropdown: false }, () => this.handleFileChange(option, { value }));
                                            }
                                            } key={option.value} {...option} />
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                        }
                    </>
                )
            }
            : {}
    ];

    columns = () => [
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            selection:
                <Button
                    onClick={() => {
                        this.onSelectAll();
                    }}
                    id='dep-app-list-select-all-button'
                    icon
                    labelPosition='left'>
                    {SELECT_ALL_BUTTON()}
                    <Icon name={ANGLE_LEFT_ICON} />
                </Button>
            ,
            filterType: 'selection',
            enableSorting: false,
            cell: info => (
                <Button
                    icon={CHEVRON_LEFT_ICON}
                    style={{ backgroundColor: '#1c1c4d' }}
                    disabled={
                        info.row.original.deviceStatus === 1 ||
                        this.state.chosenDevices.some(device => device.deviceId === info.row.original.deviceId)
                    }
                    primary
                    onClick={() => {
                        const selected = this.state.chosenDevices.some(device => info.getValue()?.deviceId === device.deviceId);

                        if (!selected) {
                            info.row.original.iconName = ASTERISK_ICON;
                            info.row.original.iconColor = 'black';
                            this.setState(prevState => ({
                                chosenDevices: [
                                    ...prevState.chosenDevices,
                                    info.row.original
                                ]
                            }));
                        }
                    }}
                ></Button>
            )
        },
        {
            header: () => <div>{ID_HEADER()}</div>,
            accessorKey: 'deviceId',
            id: 'deviceId',
            enableSorting: false,
            cell: info => (
                <Link to={'/device-manage?device_1=' + info.getValue()}>
                    {info.getValue()}
                </Link>
            )
        },
        {
            header: () => <div>{AVAILABILITY_HEADER()}</div>,
            accessorFn: row => {
                const deviceStatus = row.deviceStatus === 0 ? ONLINE_FREE_LABEL() :OFFLINE_LABEL();
                const reservedStatus = row.status.Reserved ? RESERVED_LABEL() : '';
                const manualStatus = row.status.Manual ? depAppListMessages().MANUAL_BUSY_LABEL : '';
                const automationStatus = row.status.Automation ? depAppListMessages().AUTOMATION_BUSY_LABEL : ''
                const maintenanceStatus = (row.deviceStatus === 1 && row.offlineReservedInfo?.username) ? MAINTENANCE_LABEL() : OFFLINE_LABEL();
                const freeStatus = (!manualStatus && !automationStatus && !reservedStatus) ? FREE_LABEL() : '';
                return `${deviceStatus} ${reservedStatus} ${manualStatus} ${automationStatus} ${maintenanceStatus} ${freeStatus}`;
            },
            id: 'status',
            cell: info => (
                <span className='number'>
                    {cardContent(
                        info.row.original.deviceStatus,
                        info.row.original.status,
                        info.row.original.offlineReservedInfo?.username
                    )}{' '}
                </span>
            )
        },
        {
            header: () => <div>{BRAND_HEADER()}</div>,
            accessorFn: row => row.brand && _.capitalize(row.brand),
            id: 'brand',
            cell: info => (<span className='number'>{info.getValue()}</span>)
        },
        {
            header: () => <div>{SCREEN_SIZE_HEADER()}</div>,
            accessorKey: 'screenSize',
            id: 'screenSize',
            cell: info => (<span className='number'>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
        },
        {
            header: () => <div>{SCREEN_RESOLUTION_HEADER()}</div>,
            accessorKey: 'screenResolution',
            id: 'screenResolution',
            cell: info => (<span className='number'>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
        },
        {
            header: () => <div>{MODEL_HEADER()}</div>,
            accessorKey: 'deviceModel',
            id: 'deviceModel',
            cell: info => <span className='number'>{info.getValue()}</span>
        },
        {
            header: () => <div>{OS_HEADER()}</div>,
            accessorFn: row => row.os && _.capitalize(row.os),
            id: 'osName',
            cell: info => <span className='number'>{info.getValue()}</span>
        },
        {
            header: () => <div>{OS_VERSION_HEADER()}</div>,
            accessorKey: 'oSVersion',
            id: 'oSVersion',
            cell: info => (<span className='number'>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
        },
        {
            header: () => <div>{CLIENT_IP_HEADER()}</div>,
            accessorKey: 'clientIp',
            id: 'clientIp',
            cell: info => <span className='number'>{info.getValue()}</span>
        },
        {
            header: () => <div>{depAppListMessages().TAG_HEADER}</div>,
            accessorFn: row => {
                if (row.specificName !== undefined && row.specificName !== '') {
                    return row.specificName;
                } else {
                    return N_A_LABEL();
                }
            },
            id: 'specificName',
            cell: info => <span className='number'>{info.getValue()}</span>
        }
    ];


    onSelectAll = () => {
        const startIndex = this.state.tableRef.pageIndex * this.state.tableRef.pageSize;
        const endIndex = startIndex + this.state.tableRef.pageSize;

        // find devices in this page & remove offline devices
        const onlineDevices = [...this.props.deviceList]
            .slice(startIndex, endIndex)
            .filter(device => device.deviceStatus !== 1);

        //chose online unselected device then set icon as asterisk
        const deviceListForSelectAll = onlineDevices.filter(
            ({ deviceId: id1 }) => !this.state.chosenDevices.some(({ deviceId: id2 }) => id2 === id1)
        ).map(device => ({
            ...device,
            iconName: ASTERISK_ICON,
            iconColor: 'black'
        }));

        // remove same devices
        const chosenDevices = [
            ...this.state.chosenDevices,
            ...deviceListForSelectAll
        ];

        this.setState({ chosenDevices });
    };

    handleAppChange = (e, { value }) => {
        this.props.setDeviceList(this.props.deviceListForFilter)
        this.setState(
            {
                selectedApp: value,
                selectedVersion: '',
                androidOrIos: ''
            },
            () => {
                const cloneAppList = this.state.appList;
                let chosen = {};
                Object.keys(cloneAppList).forEach(category => {
                    if (value === category) {
                        chosen = cloneAppList[category];
                    }
                });
                const dropdownVersion = chosen.map(app => {
                    return {
                        key: `${app.appVersion}-${app.appVersionCode}`,
                        value: app.id,
                        text: (
                            <>
                                <span>
                                    v{app.appVersion}{' '}
                                    <span style={{ color: '#a2a2a2' }}>
                                        {depAppListMessages().BUILD_LABEL} {app.appVersionCode}{' '}
                                        {app.appVersionName &&
                                            <span style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
                                                ({app.appVersionName})
                                            </span>
                                        }
                                    </span>
                                    <br />
                                    <span style={{ fontSize: 'x-small', color: '#a2a2a2', fontFamily: 'monospace' }}>#{app.id} - </span>
                                    <span style={{ color: '#666669', fontSize: 'x-small' }}>
                                        <b>{CATEGORY_LABEL()}:</b> {this.props.t(app.appCategory)}
                                    </span>
                                </span>
                            </>
                        )
                    };
                });
                this.setState({ dropdownVersion });
            }
        );
    };

    handleVersionChange = (e, { value }) => {
        const cloneDeviceList = this.props.deviceListForFilter;
        const cloneChosenDevices = this.state.chosenDevices;
        this.setState({ selectedVersion: value }, () => {
            getApp(this.state.selectedVersion).then(res => {
                let operatingSystem = res.data[0].appOs;
                if (operatingSystem === 'ios') {
                    operatingSystem = 'iOS';
                } else {
                    operatingSystem = 'Android'
                }
                this.setState({ operatingSystem }, () => {
                    const deviceList = cloneDeviceList.filter(
                        device => {
                            return (
                                device.os.toLowerCase() ===
                                operatingSystem.toLowerCase()
                            );
                        }
                    );
                    const chosenDevices = cloneChosenDevices.filter(
                        chosen => {
                            return (
                                chosen.os.toLowerCase() ===
                                operatingSystem.toLowerCase()
                            );
                        }
                    );
                    this.props.setDeviceList(deviceList);
                    this.setState({ chosenDevices });
                });
            });
        });
    };

    onErrorShowDeviceIcons = err => {
        const cloneChosenDevices = this.state.chosenDevices.map(device => {
            device.iconName = 'x';
            device.iconColor = 'red';
            device.message = `${err?.response?.data?.message ?? err.toString()
                }`;

            return device;
        })

        this.setState({ loading: false, chosenDevices: cloneChosenDevices });
    };

    onUpload = async () => {
        this.setState({ loading: true }, () => {
            const { chosenDevices } = this.state;
            chosenDevices.forEach(device => {
                device.iconName = ASTERISK_ICON;
                device.iconColor = 'black';
            });
            this.setState({ chosenDevices });
        });
        const idList = [];
        let operatingSystem;
        this.state.chosenDevices.forEach(device => idList.push(device.deviceId));
        if (this.state.activeIndex === 0) {
            //axios.get(`/api/apps?id=${this.state.selectedVersion}`)
            getApp(this.state.selectedVersion).then(res1 => {
                operatingSystem = res1.data[0].appOs;
                if (operatingSystem === 'ios') {
                    operatingSystem = 'iOS';
                } else {
                    operatingSystem = 'Android';
                }

                const data = {
                    deviceList: idList,
                    appList: [
                        {
                            appId: this.state.selectedVersion,
                            type: operatingSystem
                        }
                    ]
                };
                axios
                    .post('/api/apk/install', data)
                    .then(res2 => {
                        const cloneChosenDevices = this.state.chosenDevices.map(chosenDevice => {
                            res2.data.filter(deviceResponse => deviceResponse.udId === chosenDevice.deviceId).forEach(deviceResponse => {
                                if (deviceResponse.apps && ['APP INSTALLED SUCCESSFULLY', 'ok', 'OK'].includes(deviceResponse.apps[0].status)) {
                                    chosenDevice.iconName = CHECKMARK_ICON;
                                    chosenDevice.iconColor = 'green';
                                } else {
                                    chosenDevice.iconName = X_ICON;
                                    chosenDevice.iconColor = 'red';
                                    chosenDevice.message =
                                        deviceResponse.apps &&
                                        deviceResponse.apps[0].status.toString();
                                }
                            }
                            )
                            return chosenDevice;
                        });
                        this.setState({
                            loading: false,
                            chosenDevices: cloneChosenDevices
                        });
                    })
                    .catch(err => {
                        this.onErrorShowDeviceIcons(err);
                        showError(
                            FAILED_ERROR_MESSAGE() +
                            err.response?.data?.message ??
                            err.toString()
                        );
                    });
            })
                .catch(err => {
                    showError(
                        FAILED_ERROR_MESSAGE() +
                        err.response?.data?.message ?? err.toString()
                    );
                });
        } else {
            if (this.state.fileUploadRadioSelection === 'local') {
                const fileFormData = this.state.fileFormData;
                const idListString = JSON.stringify(idList);
                fileFormData.set('udIds', idListString);
                axios
                    .post('/api/file/send-bulk', fileFormData)
                    .then(res => {
                        this.setState({
                            loading: false,
                            chosenDevices: this.changeUploadIconStatus(res)
                        });
                    })
                    .catch(err => {
                        this.onErrorShowDeviceIcons(err);
                        showError(FAILED_ERROR_MESSAGE() + err.response?.data?.message ?? err.toString());
                    });
            } else {
                try {
                    const data = {
                        udIdList: this.state.chosenDevices.map(device => device.deviceId),
                        fileId: this.state.selectedFile
                    }
                    const res = await deployBulkFile(data);
                    this.setState({
                        selectedFile: '',
                        loading: false,
                        chosenDevices: this.changeUploadIconStatus(res)
                    })
                    showSuccess(SUCCESS_MESSAGE());
                } catch (err) {
                    showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`)
                }
            }
        }
    };

    changeUploadIconStatus = response => {
        return this.state.chosenDevices.map(chosenDevice => {
            response.data.filter(uploadResponse => uploadResponse.udId === chosenDevice.deviceId).forEach(uploadResponse => {
                if (uploadResponse.status === 'ok') {
                    chosenDevice.iconName = CHECKMARK_ICON;
                    chosenDevice.iconColor = 'green';
                } else {
                    chosenDevice.iconName = X_ICON;
                    chosenDevice.iconColor = 'red';
                }
            })
            return chosenDevice;
        });
    }

    onChangeUpload = event => {
        const appFile = event.target.files[0];
        const fileFormData = new FormData();
        fileFormData.append('file', appFile);
        this.setState({ fileFormData });
    };


    getFiles = async value => {
        this.setState({ loadingFileList: true });
        try {
            const res = await getFileList(value && {name:value});
            this.setState({ fileList: res.data.content }, () => {
                const dropdownFileList = this.state.fileList.map(file => {
                    return {
                        key: file.id,
                        value: file.id,
                        text: file.name
                    }
                })
                this.setState({ dropdownFileList });
            })
        } catch (err) {
            showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`)
        } finally {
            this.setState({ loadingFileList: false })
        }
    }

    handleChange = (e, { value }) => {
        if (value === 'local') {
            this.setState({ fileUploadRadioSelection: value, chosenDevices: [], selectedFile: '' })
        } else {
            this.setState({ fileUploadRadioSelection: value, chosenDevices: [], fileFormData: undefined })
        }
    }

    handleFileChange = (e, {value}) => this.setState({selectedFile: value})

    getApps() {
        getAppList().then((res) => {
            if (res.data) {
                let groupBy = function (xs, key) {
                    return xs.reduce(function (rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };
                let allApps = editPackageName(res.data);
                const groupedByPackage = groupBy(allApps, 'appPackage');
                let dropdownApp = Object.keys(groupedByPackage).map(
                    (app) => {
                        return {
                            key: app,
                            value: app,
                            text: app.slice(0, -4),
                            image: {
                                avatar: true,
                                src: `data:image/png;base64,${groupedByPackage[app][0].appIcon}`,
                            },
                        };
                    }
                );
                this.setState({
                    dropdownApp,
                    appList: groupedByPackage,
                });
            }
        })
            .catch((err) => {
                showError(`${NOT_FETCH_APPS()} ${err.response?.data?.message ?? err.toString()}`
                );
            });
    }

    getDeviceListAndFiles = () =>{
        axios
            .get('/api/devices')
            .then(res => {
                if (res.data) {
                    this.props.setDeviceList(res.data);
                    this.props.setDeviceListFilter(res.data);
                }
            })
            .catch(err => {
                showError(`${NOT_FETCH_DEVICES()} ${err.response?.data?.message ?? err.toString()}`);
            })
            .finally(() => this.setState({ loadingDeviceList: false }));
    }

    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({privileges});
        if (privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.INSTALL) && privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS)) {
            //axios.get("/api/apps")
               this.getApps();
        } else {
            this.setState({activeIndex: 1});
        }
        this.setState({
            loadingDeviceList: true,
            language: localStorage.getItem('lang') || 'en'
        });
        this.getDeviceListAndFiles();
    }

    componentDidUpdate() {
        // When language is changed, table data will be renewed.
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.getDeviceListAndFiles())
        )
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined && row[id] !== null
            ? String(row[id].toString().toLowerCase()).includes(
                filter.value.toLowerCase()
            )
            : true;
    };

    onChangeTab = (e, { activeIndex }) => {
        if (activeIndex === 0) {
            this.setState({ selectedFile: '', fileFormData: undefined, chosenDevices: [] })
            this.getApps();
        } else {
            this.getDeviceListAndFiles();
            this.setState({ selectedApp: '', selectedVersion: '', chosenDevices: [] })
            this.getFiles();
        }
        this.setState({ activeIndex }, () => {
            if (this.state.fileUploadRadioSelection !== 'local') {
                this.setState({ fileUploadRadioSelection: 'local' })
            }
        })
    }

    render() {
        return (
            <>
                <div className='main-container'>
                    <div className='main-left-container'>
                        <h1>{DEPLOYMENT_HEADER()}</h1>
                        <div className='main-left-content'>
                            <Tab
                                panes={this.panesRender()}
                                menu={{ secondary: true, pointing: true }}
                                onTabChange={(e, { activeIndex }) => this.onChangeTab(e, { activeIndex })}
                                activeIndex={this.state.activeIndex}
                            />
                            <div style={{ marginTop: 20 }}>
                                <h4 style={{ fontWeight: 300 }}>{depAppListMessages().SELECTED_DEVICES_LABEL} </h4>

                                {this.state.chosenDevices.length === 0 && (
                                    <>
                                        <Segment placeholder>
                                            <Header icon style={{ fontWeight: 300 }}>
                                                <Icon style={{ fontWeight: 300 }} name={HAND_POINT_UP_OUTLINE_ICON} />
                                                {depAppListMessages().SELECTED_DEVICE_FROM_TABLE_MESSAGE}
                                            </Header>
                                        </Segment>
                                    </>
                                )}
                                <Menu size='small' vertical fluid>
                                    {this.state.chosenDevices.map(
                                        (device, index) => {
                                            return (
                                                <Menu.Item
                                                    className={'textOverflow'}
                                                    key={index}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                                            <div>
                                                                <div style={{ fontSize: 16 }}>
                                                                    {device.deviceModel}{' '}
                                                                </div>
                                                                <div style={{ fontSize: 12 }}>
                                                                    {device.deviceId}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <Button
                                                                icon={CHEVRON_RIGHT_ICON}
                                                                color='red'
                                                                onClick={() =>
                                                                    this.setState({ chosenDevices: this.state.chosenDevices.filter((chosenDevice, i) => i !== index) })
                                                                }
                                                            ></Button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: 1,
                                                            justifyContent: 'flex-end'
                                                        }}
                                                    >
                                                        {device.message ? (
                                                            <Popup
                                                                trigger={
                                                                    <Icon
                                                                        name={`${device.iconName}`}
                                                                        loading={this.state.loading}
                                                                        color={`${device.iconColor}`}
                                                                    />
                                                                }
                                                                content={device.message}
                                                                position={'right center'}
                                                                mouseLeaveDelay={1000}
                                                            />
                                                        ) : (
                                                            <Icon
                                                                name={`${device.iconName}`}
                                                                loading={this.state.loading}
                                                                color={`${device.iconColor}`}
                                                            />
                                                        )}
                                                    </div>
                                                </Menu.Item>
                                            );
                                        }
                                    )}
                                    <Menu.Item>
                                        <Button
                                            fluid
                                            primary
                                            onClick={this.onUpload}
                                            disabled={this.state.loading ||
                                                this.state.chosenDevices.length === 0 ||
                                                (this.state.activeIndex === 0 ? (this.state.selectedApp === '' ||
                                                    this.state.selectedVersion === '') :
                                                    (this.state.selectedFile === '' && this.state.fileFormData === undefined))
                                            }
                                        >
                                            {DEPLOY_BUTTON()}
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button
                                            inverted
                                            fluid
                                            color={'red'}
                                            onClick={() =>
                                                this.setState({
                                                    chosenDevices: []
                                                })
                                            }
                                            disabled={
                                                this.state.chosenDevices
                                                    .length === 0 ||
                                                this.state.loading
                                            }
                                        >
                                            {depAppListMessages().CLEAR_ALL_DEVICES_BUTTON}
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div id='deployment-table-container' className='main-right-container'>
                        <div className='main-right-header'>
                            <h2>{DEVICE_LIST_HEADER()}</h2>
                        </div>
                        <ReactTableV8
                            data={this.props.deviceList}
                            columns={this.columns()}
                            table={this.table}
                            columnwiseFilterableProp={true}
                            loadingProp={this.state.loadingDeviceList}
                            getTableRefProp={tableRef => this.setState({tableRef})}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        deviceList: state.deviceList.deviceList,
        deviceListForFilter: state.deviceList.deviceListFilter
    };
};


export default withTranslation()(
    connect(mapStateToProps, { setDeviceList, setDeviceListFilter })(DepAppList)
);
