import React, { useEffect, useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import { EYE_ICON, EYE_SLASH_ICON } from '../utils/UiIcons';
import { CANCEL_BUTTON, CHANGE_BUTTON, changePasswordInputMessages } from '../utils/UIMessages';

const ChangePasswordInput = ({ name, value, onChange = () => { }, reset = () => { }, error }) => {

  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    reset();
  }, [disabled]);

  return (
    <Input fluid type={showPassword ? 'text' : 'password'} 
    placeholder={disabled ? changePasswordInputMessages().PLACEHOLDER_DISABLED : 
      changePasswordInputMessages().PLACEHOLDER_NOT_DISABLED}
      disabled={disabled} name={name}
      value={value || ''}
      onChange={onChange}
      error={error}
      action>
      <input />
      {!disabled && <Button basic icon={showPassword ? EYE_SLASH_ICON : EYE_ICON} link onClick={() => setShowPassword(prev => !prev)} />}
      <Button
        type='submit'
        onClick={e => {
          e.preventDefault();
          setDisabled(prev => !prev)
        }}>
        {disabled ? CHANGE_BUTTON() : CANCEL_BUTTON()}
      </Button>
    </Input>
  );
};

export default ChangePasswordInput;
