import {Button, Divider, Header, Icon, Modal, Segment, Table} from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import { beautifyFileName, beautifyFileDate } from "../../utils/Methods"
import { DELETE_ICON, DOWNLOAD_ICON, TAG_ICON } from "../../utils/UiIcons";
import { DELETE_BUTTON, DEVICE_ID_HEADER, DEVICE_MODEL_HEADER, DOWNLOAD_BUTTON, END_DATE_LABEL, FULLNAME_HEADER, NOTHING_HERE_MESSAGE, REPORT_ID_HEADER, SESSION_ID_HEADER, START_DATE_LABEL, testSessionDetailMessages, TYPE_LABEL, USERNAME_HEADER } from "../../utils/UIMessages";
import { PrivilegeConstants } from "../../utils/PrivilegeConstants";

const username = localStorage.getItem('username');
class TestSessionDetailsModal extends Component {

    downloadFilePrivilegeCheck = () => {
        if (this.props.testSessionFields.type == 'MANUAL') {
            return !(this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.DOWNLOAD_ANY) ||
                this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
                username === this.props.testSessionFields.username);
        } else {
            return !(this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.DOWNLOAD_ANY) ||
                this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT)||
                username === this.props.testSessionFields.username);
        }
    }

    deleteFilePrivilegeCheck = () => {
        if (this.props.testSessionFields.type == 'MANUAL') {
            return !(this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.DELETE_FILE) ||
                this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.DELETE_ANY_FILE) ||
                this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT));
        } else {
            return !(this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.DELETE_FILE) ||
                this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.DELETE_ANY_FILE) ||
                this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT));
        }
    }

    render() {
        let {testSessionFields} = this.props;
        return (
            <Modal
                open={this.props.modalVisibility} onClose={this.props.handleModalClose} closeOnDimmerClick={this.props.handleModalClose} closeIcon
                size={"small"}>
                <Modal.Content>
                    <Divider horizontal>
                        <Header as='h4'>
                            <Icon name={TAG_ICON}/>
                            {testSessionDetailMessages().TEST_SESSION_INFO_LABEL}
                        </Header>
                    </Divider>
                    <Table celled singleLine>
                        <Table.Body>
                            {testSessionFields.sessionId &&
                                <Table.Row>
                                    <Table.Cell><b>{REPORT_ID_HEADER()}</b></Table.Cell>
                                    <Table.Cell>{testSessionFields.id}</Table.Cell>
                                </Table.Row>}
                            <Table.Row>
                                <Table.Cell><b>{testSessionDetailMessages().TEST_SESSION_DETAIL_LABEL}</b></Table.Cell>
                                <Table.Cell className='test-session-info-modal-td'>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                {SESSION_ID_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.sessionId || testSessionFields.id}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {TYPE_LABEL()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.type}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {START_DATE_LABEL()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {moment(testSessionFields.startDate).format("DD/MM/YYYY HH:mm.ss")}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {END_DATE_LABEL()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {moment(testSessionFields.endDate).format("DD/MM/YYYY HH:mm.ss")}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{testSessionDetailMessages().USER_DETAIL_LABEL}</b></Table.Cell>
                                <Table.Cell className='test-session-info-modal-td'>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                {USERNAME_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.username}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {FULLNAME_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.fullName}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{testSessionDetailMessages().DEVICE_DETAIL_LABEL}</b></Table.Cell>
                                <Table.Cell className='test-session-info-modal-td'>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                {DEVICE_ID_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.udId}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {DEVICE_MODEL_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.deviceModel}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {testSessionDetailMessages().DEVICE_BRAND_LABEL}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.deviceBrand}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {testSessionDetailMessages().DEVICE_OS_LABEL}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.deviceOs}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {testSessionDetailMessages().DEVICE_OS_VERSION_LABEL}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.deviceOsVersion}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{testSessionDetailMessages().TEST_SESSION_FILES_LABEL}</b></Table.Cell>
                                <Table.Cell className='test-session-info-modal-td'>
                                    <Table>
                                        <Table.Body>
                                            {!_.isEmpty(testSessionFields.fileNames) ?
                                                Object.keys(testSessionFields.fileNames).map((key, i) => {
                                                    const file = testSessionFields.fileNames[key];
                                                    return <>
                                                        <Table.Row key={i}>
                                                            <Table.Cell>
                                                                {beautifyFileName(file)} {beautifyFileDate(file)}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ 'white-space': 'nowrap' }}>
                                                            <div className='ui two buttons'>
                                                                    <Button basic color='green'
                                                                        onClick={() => this.props.downloadFile(file)}
                                                                        disabled={this.downloadFilePrivilegeCheck()}>
                                                                        <Icon name={DOWNLOAD_ICON} />
                                                                        {DOWNLOAD_BUTTON()}
                                                                    </Button>
                                                                    <Button basic color='red'
                                                                        onClick={() => this.props.deleteFile(file)}
                                                                        disabled={this.deleteFilePrivilegeCheck()}>
                                                                        <Icon name={DELETE_ICON} />
                                                                        {DELETE_BUTTON()}
                                                                    </Button>
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </>
                                                })
                                                : <><Table.Row>
                                                    <Segment placeholder attached={"top"}>
                                                        <Header icon>
                                                            <Icon name='folder open outline'/>
                                                            {NOTHING_HERE_MESSAGE()}
                                                        </Header>
                                                    </Segment>
                                                </Table.Row>
                                                </>
                                            }
                                            {!_.isEmpty(testSessionFields.fileNames) ? <Table.Row>
                                                <Table.Cell>{testSessionDetailMessages().ZIP_FILE_LABEL}</Table.Cell>
                                                <Table.Cell><Button fluid color='green'
                                                                    onClick={() => this.props.downloadFile()}
                                                                    disabled={this.downloadFilePrivilegeCheck()}> <Icon
                                                    name={DOWNLOAD_ICON}/>{testSessionDetailMessages().ALL_FILES_DOWNLOAD_BUTTON}</Button>
                                                </Table.Cell>
                                            </Table.Row> : <></>}
                                        </Table.Body>
                                    </Table>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Modal>
        );
    }
}

export default withTranslation()(TestSessionDetailsModal);