import React, { useMemo, useState } from 'react';
import {
  Icon,
  Button,
  Modal,
  Header,
  Form,
  TextArea,
  Tab
} from 'semantic-ui-react';
import CopyToClipboard from 'copy-to-clipboard';
import AsciiTable from 'ascii-table';
import ReactJson from 'react-json-view'
import { showInfo } from '../../utils/ToastHelpers';
import { COPY_ICON, COPY_OUTLINE_ICON, DOWNLOAD_ICON } from '../../utils/UiIcons';
import {
  ASCII_HEADER, BRAND_HEADER,
  COPY_BUTTON, deviceClipboardMessages, DEVICE_ID_HEADER,
  DEVICE_INFORMATION_CLIPBOARD_POPUP_MESSAGE, DOWNLOAD_BUTTON,
  GOOGLE_SERVICE_HEADER, JSON_HEADER, MAC_ADDRESS_HEADER,
  OS_HEADER, OS_VERSION_HEADER, SCREEN_RESOLUTION_HEADER,
  SCREEN_SIZE_HEADER,
  SECONDARY_SERVER_HEADER
} from '../../utils/UIMessages';
import { renderedNodeInfo } from '../../utils/Methods';

const DeviceClipboard = props => {

    const [activeIndex, setActiveIndex] = useState(0);
    const deviceInfo = props.device;
    const nodeInfo = useMemo(() => renderedNodeInfo(deviceInfo?.node), [deviceInfo]);

    const table = new AsciiTable();
        table
        .addRow(DEVICE_ID_HEADER(), deviceInfo.deviceId)
        .addRow(SECONDARY_SERVER_HEADER(), nodeInfo)
        .addRow(BRAND_HEADER(), deviceInfo.brand)
        .addRow(OS_HEADER(), deviceInfo.os)
        .addRow(SCREEN_SIZE_HEADER(), deviceInfo.screenSize)
        .addRow(SCREEN_RESOLUTION_HEADER(), deviceInfo.screenResolution)
        .addRow(GOOGLE_SERVICE_HEADER(), deviceInfo.googleService)
        .addRow(MAC_ADDRESS_HEADER(), deviceInfo.macAddress)
        .addRow(OS_VERSION_HEADER(), deviceInfo.oSVersion)

    const customJSON = {
        [DEVICE_ID_HEADER()]: deviceInfo.deviceId,
        [SECONDARY_SERVER_HEADER()]: nodeInfo,
        [BRAND_HEADER()]: deviceInfo.brand,
        [OS_HEADER()]: deviceInfo.os,
        [SCREEN_SIZE_HEADER()]: deviceInfo.screenSize,
        [SCREEN_RESOLUTION_HEADER()]: deviceInfo.screenResolution,
        [GOOGLE_SERVICE_HEADER()]: deviceInfo.googleService,
        [MAC_ADDRESS_HEADER()]: deviceInfo.macAddress,
        [OS_VERSION_HEADER()]: deviceInfo.oSVersion
    };

    const clipboardTabs = [{
        menuItem: JSON_HEADER(),
        render: () => <Tab.Pane >
            <ReactJson src={customJSON} iconStyle='triangle' name='device'/>
        </Tab.Pane>
    },
    {
        menuItem: ASCII_HEADER(),
        render: () => <Tab.Pane >
            <div className='main-clipboard'>
                <Form>
                    <TextArea className='ascii-clipboard' style={{minHeight: 270, fontFamily: 'monospace', margin: 'auto', textAlign: 'center'}} value={table.render()}/>
                </Form>
            </div>
        </Tab.Pane>
    }]

    const copyDeviceInformation = () => {
        if(activeIndex === 0){
            CopyToClipboard(JSON.stringify(customJSON));
            showInfo(deviceClipboardMessages().COPIED_AS_JSON_FORMAT);
        } else if(activeIndex === 1){
            CopyToClipboard(`${DEVICE_ID_HEADER()}: ${deviceInfo.deviceId}
            \n${SECONDARY_SERVER_HEADER()}: ${nodeInfo}
            \n${BRAND_HEADER()}: ${deviceInfo.brand}
            \n${OS_HEADER()}: ${deviceInfo.os}
            \n${OS_VERSION_HEADER()}: ${deviceInfo.oSVersion}
            \n${SCREEN_SIZE_HEADER()}: ${deviceInfo.screenSize}
            \n${SCREEN_RESOLUTION_HEADER()}: ${deviceInfo.screenResolution}
            \n${GOOGLE_SERVICE_HEADER()}: ${deviceInfo.googleService}
            \n${MAC_ADDRESS_HEADER()}: ${deviceInfo.macAddress}`);
            showInfo(deviceClipboardMessages().COPIED_AS_TXT_FORMAT);
        } else {
          //invalid index
        }
    }

    const downloadDeviceInformation = () => {
        const element = document.createElement('a');
        if(activeIndex === 0){
            const file = new Blob([JSON.stringify(customJSON)], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `${deviceInfo.deviceId}` + '.txt';
            document.body.appendChild(element);
            element.click();
            element.remove();
        } else if(activeIndex === 1){
            const file = new Blob([table], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `${deviceInfo.deviceId}` + '.txt';
            document.body.appendChild(element);
            element.click();
            element.remove();
        } else {
          //invalid index
        }
    }

  return (
    <div>
      <Modal
        closeIcon
        open={props.open}
        onClose={()=>{
            setActiveIndex(0);
            props.closeModal();
        }}
        onOpen={()=>{
            props.openModal();
        }}
      >
        <Header
          icon={COPY_OUTLINE_ICON}
          content={DEVICE_INFORMATION_CLIPBOARD_POPUP_MESSAGE()}
        />
        <Modal.Content>
          <Form.Field>
            <Tab
              menu={{ pointing: true }}
              panes={clipboardTabs}
              activeIndex={activeIndex}
              onTabChange={(_e, data) => {
                setActiveIndex(data.activeIndex);
              }}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button icon={COPY_OUTLINE_ICON} color='gray' onClick={copyDeviceInformation}>
            <Icon name={COPY_ICON} /> {COPY_BUTTON()}
          </Button>
          <Button
            icon={DOWNLOAD_ICON}
            color='green'
            onClick={downloadDeviceInformation}
          >
            <Icon name={DOWNLOAD_ICON} /> {DOWNLOAD_BUTTON()}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default DeviceClipboard;
