import React, {Component} from 'react';
import axios from "axios";
import DeviceListCard from "../pages/device/DeviceListCard";
import {showError} from "../utils/ToastHelpers";
import {withTranslation} from "react-i18next";
import { sidebarLastDevicesMessages } from '../utils/UIMessages';

class SidebarLastDevices extends Component {

    state = {
        lastDeviceList: []
    }

    editDeviceListForLastDevices = () => {
        const deviceList = this.props.deviceList
        axios.get('/api/devices/last').then(res => {
            let response = res.data
            let lastDeviceList = deviceList.reduce((filtered, device) => {
                const lastUsed = response.find(u => device?.deviceId === u?.deviceId);
                if (lastUsed) {
                    device.dateOfUse = lastUsed.dateOfUse;
                    device.testType = lastUsed.testType;
                    filtered.push(device);
                }
                return filtered;
            }, []).sort(((a, b) => {
                return new Date(b?.dateOfUse) - new Date(a?.dateOfUse)
            }));
            this.setState({lastDeviceList})
        }).catch(err=>showError(sidebarLastDevicesMessages().LAST_DEVICES_FETCH_ERROR + err?.response?.data?.message ?? err.toString()))
    }

    componentDidMount() {
        if (this.props.visible) { // added for tab change
            this.editDeviceListForLastDevices()
        }
    }


    componentDidUpdate(prevProps) {
        if ((JSON.stringify(prevProps.deviceList) !== JSON.stringify(this.props.deviceList) && this.props.visible) //added for onFavoriteDevice method
            ||
            (!prevProps.visible && this.props.visible)) {// trigger every time the sidebar shows up
            this.editDeviceListForLastDevices()
        }
    }


    render() {
        return (
            <>
                {this.state.lastDeviceList?.length > 0 &&
                <DeviceListCard
                    {...this.props}
                    deviceList={this.state.lastDeviceList}
                    lastUsedDevices={true}
                />
                }
            </>
        );
    }
}

export default withTranslation()(SidebarLastDevices);