import React from 'react';
import {Dimmer, Grid, Icon, Message} from 'semantic-ui-react';
import CollectionList from './components/CollectionList';
import ArticleViewer from './components/ArticleViewer';
import { scrollToElement } from './helpers';
import {withTranslation} from 'react-i18next';
import './product.css';
import axios from 'axios';
import { documentationMessages, DOCUMENTATION_ERROR_MESSAGE_HEADER } from '../../utils/UIMessages';
import { SPINNER_ICON } from '../../utils/UiIcons';



class DocumentationPage extends React.Component {
  state = {
    selectedLangCode: localStorage.getItem("lang") || 'en',
    collections: [],
    articleId: this.props.match.params.articleId === undefined ? null : this.props.match.params.articleId,
    headerList: [],
    articleLoading: false,
    error: false
  };


  toggleArticleLoading = (val) => {
    this.setState({ articleLoading: val });
  }


  selectArticle = (id) => {
    this.setState({ articleId: id });
  }


  getHeaders = () => {
    const headerList = document.querySelectorAll('#article-viewer h2');
    this.setState({
      headerList: Array.from(headerList)
    }, () => {
      const mainHeader = document.getElementById('article-title-header');
      scrollToElement(mainHeader, 'auto');
    });
  }

  getCollections = (availableDocumentLanguages) => {
    let lang = this.state.selectedLangCode;

    if(!availableDocumentLanguages.includes(lang)){
      lang = "en";
    }

    return axios.get(`/docsJson/collection/${lang}.json`).then(res => {
      this.setState({
        collections: res.data.collections,
      });
    });
  }

  getAvailableLanguages = () => {
    return axios.get(`/docsJson/languages.json`).then(res => {
      return res.data.languages.map(l => (l.code));
    });
  }

  handleChangeLanguage = (value) => {
    this.setState({ selectedLangCode: value }, () => {
      this.getCollections(value)
        .then(() => {
          this.setState({ articleId: this.state.collections[0]?.articles[0]?.id || null });
        })
        .catch(() => {
          this.setState({
            error: true
          });
        })
    });
  }

  componentDidMount() {
    this.getAvailableLanguages().then(res => {
      this.getCollections(res).then(() => {
        if (!this.props.match.params.articleId) {
          this.selectArticle(this.state.collections[0]?.articles[0]?.id || null);
        }
      }).catch(() => {
        this.setState({
          error: true
        });
      });
    }).catch(() => {
      this.setState({
        error: true
      });
    });
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedLangCode !== localStorage.getItem('lang') && localStorage.getItem('lang') !== null) {
      this.handleChangeLanguage(localStorage.getItem('lang'));
    }
  }

  render() {
    return (
      <Grid>
        <Grid.Row className='page-content' columns={2} style={{ marginTop: '80px', marginBottom: '50px' }}>
          <Grid.Column width={4}>
            {!this.state.error ?
              <CollectionList collections={this.state.collections} articleId={this.state.articleId} selectArticle={this.selectArticle}
                headerList={this.state.headerList} /> : null
            }
          </Grid.Column>

          <Grid.Column width={12}>
            <Dimmer active={this.state.articleLoading} inverted >
              <Icon name={SPINNER_ICON} loading size='huge' color='blue' />
            </Dimmer>
            {!this.state.error ? (this.state.articleId ?
              <ArticleViewer articleId={this.state.articleId} getHeaders={this.getHeaders}
                toggleArticleLoading={this.toggleArticleLoading} /> :
              <Message>
                <Message.Header>{documentationMessages().NO_ARTICLE_HEADER}</Message.Header>
                <p>{documentationMessages().NO_ARTICLE_MESSAGE}</p>
              </Message>) :
              <Message error>
                <Message.Header>{DOCUMENTATION_ERROR_MESSAGE_HEADER()}</Message.Header>
                <p>{documentationMessages().ERROR_MESSAGE}</p>
              </Message>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withTranslation()(DocumentationPage);
