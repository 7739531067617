import React, {Component} from 'react';
import {Checkbox, Form, Segment, Dropdown} from 'semantic-ui-react';
import DatePicker, {registerLocale} from 'react-datepicker';
import queryString from 'querystring'
import moment from 'moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-GB';
import {DAILYLY_DATETIME_FORMAT, DAILY_DATE_FORMAT, DEBOUNCE_WAIT, MONTLY_DATETIME_FORMAT, MONTLY_DATE_FORMAT} from '../utils/Constants';
import { AUTOMATION_LABEL, dateRangeDropdownMessages, DEVELOPMENT_LABEL, END_DATE_LABEL, MANUAL_LABEL, NOT_FETCH_ROLES_ERROR_MESSAGE, RESERVED_LABEL, SEARCH_ROLE_PLACEHOLDER, SIMPLE_RATIO_LABEL, START_DATE_LABEL, TOTAL_LABEL, USER_ROLES_LABEL } from '../utils/UIMessages';
import { getAllRoles } from '../api/apiCalls';
import { showError } from '../utils/ToastHelpers';

registerLocale('tr', tr);
registerLocale('en', en);

class DateRangeDropdown extends Component {


    options = [
        {
            key: 'Total',
            value: 'total',
            text: TOTAL_LABEL()
        },
        {
            key: 'Automation',
            value: 'automation',
            text: AUTOMATION_LABEL()
        },
        {
            key: 'Manual',
            value: 'manual',
            text: MANUAL_LABEL()
        },
        {
            key: 'Development',
            value: 'development',
            text: DEVELOPMENT_LABEL()
        },
        {
            key: 'Reserved',
            value: 'reserved',
            text: RESERVED_LABEL()
        },
    ]

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(this.props.startDate),
            endDate: new Date(this.props.endDate),
            sizeValue: this.props.sizeValue ?? 10,
            orderValue: this.props.orderValue ?? 'total',
            simpleRatio: this.props.simpleRatio ?? false,
            userRoles: '',
            loadingRoles: false,
            roleDropdown: []
        }
        this.onChangeSize = _.debounce(this.onChangeSize, DEBOUNCE_WAIT.LONG)
    }


    urlGenerateForDate = (startDate, endDate) => {
        let url = this.props.type === 'monthly'
            ?
            {start: startDate, end: endDate}
            :
            {start_date: startDate, end_date: endDate}
        if (this.props.sizeValue) {
            url.size = this.state.sizeValue
        }

        if (this.props.isOrderByVisibility) {
            url.order_by = this.state.orderValue
        }
        if (this.props.isUserRolesVisibility) {
            url.userRoles = this.state.userRoles
        }
        if (this.props.hourlyDeviceStats) {
            url.type = +this.state.simpleRatio //boolean to 0 or 1
        }
        return url
    }


    onChangeStartDate = (startDate) => {
        this.setState({startDate}, () => {
            startDate = this.props.type === 'monthly' ? moment(startDate).format(MONTLY_DATETIME_FORMAT) : moment(startDate).format(DAILYLY_DATETIME_FORMAT)
            let endDate = this.props.type === 'monthly' ? moment(this.state.endDate).format(MONTLY_DATETIME_FORMAT) : moment(this.state.endDate).format(DAILYLY_DATETIME_FORMAT)
            let url = this.urlGenerateForDate(startDate, endDate)
            this.props.callback(queryString.stringify(url))
        })
    }

    onChangeEndDate = (endDate) => {
        this.setState({endDate}, () => {
            endDate = this.props.type === 'monthly' ? moment(endDate).format(MONTLY_DATETIME_FORMAT) : moment(endDate).format(DAILYLY_DATETIME_FORMAT)
            let startDate = this.props.type === 'monthly' ? moment(this.state.startDate).format(MONTLY_DATETIME_FORMAT) : moment(this.state.startDate).format(DAILYLY_DATETIME_FORMAT)
            let url = this.urlGenerateForDate(startDate, endDate)
            this.props.callback(queryString.stringify(url))
        })

    }

    onChangeUserRoles = (e, { value }) => {
        this.setState({ userRoles: value }, () => {
            this.formatDateForRequest()
        })
    }

    onChangeSize = (e, {value}) => {
        this.setState({sizeValue: value}, () => {
            this.formatDateForRequest()
        })

    }

    onChangeOrder = (e, {value}) => {
        this.setState({orderValue: value}, () => {
            this.formatDateForRequest()
        })
    }

    onChangeRatio = (e, {checked}) => {
        this.setState({simpleRatio: checked}, () => {
            this.formatDateForRequest()
        })
    }

    formatDateForRequest = () => {
        let {endDate, startDate} = this.state
        endDate = this.props.type === 'monthly' ? moment(endDate).format(MONTLY_DATETIME_FORMAT) : moment(endDate).format(DAILYLY_DATETIME_FORMAT)
        startDate = this.props.type === 'monthly' ? moment(startDate).format(MONTLY_DATETIME_FORMAT) : moment(startDate).format(DAILYLY_DATETIME_FORMAT)
        let url = this.urlGenerateForDate(startDate, endDate)
        this.props.callback(queryString.stringify(url))
    }

    componentDidMount(){
        if(this.props.isUserRolesVisibility){
            this.getAllRoles();
        }
    }

    getAllRoles = () => {
        this.setState({ loadingRoles: true });
        getAllRoles().then(res => {
            let roleDropdown = res.data.map(role => {
                return {
                    key: role.roleId,
                    value: role.name,
                    text: role.name,
                }
            })
            this.setState({ roleDropdown })
        }).catch(err => {
            showError(NOT_FETCH_ROLES_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingRoles: false }));
    }

    componentDidUpdate(prevProps) {
        if (+prevProps.startDate !== +this.props.startDate) {
            this.setState({startDate: new Date(this.props.startDate)})
        }
        if (+prevProps.endDate !== +this.props.endDate) {
            this.setState({endDate: new Date(this.props.endDate)})
        }
        if (prevProps?.sizeValue !== this.props.sizeValue) {
            this.setState({sizeValue: this.props.sizeValue})
        }
        if (prevProps?.type !== this.props.type) {
            this.setState({orderValue: 'total'})
            this.setState({userRoles: ''})
        }
        if (prevProps?.simpleRatio !== this.props.simpleRatio) {
            this.setState({simpleRatio: this.props.simpleRatio})
        }
    }

    threeMonthsFilter = (date, type) => {
        const filtered = new Date(date)
        if (type === 'start') {
            return new Date(filtered.setMonth(filtered.getMonth() - 3))
        }
        if (type === 'end') {
            return new Date(filtered.setMonth(filtered.getMonth() + 3))
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }


    render() {
        return (
            <Form style={{display: 'flex', justifyContent: 'center'}}>
                <Form.Group>
                    <Form.Field width={8}>
                        <label>{START_DATE_LABEL()}</label>
                        <DatePicker
                            minDate={this.props.hourlyDeviceStats ? this.threeMonthsFilter(this.state.endDate, 'start') : null}
                            maxDate={this.state.endDate}
                            selected={this.state.startDate}
                            selectsStart
                            dateFormat={this.props.type === 'monthly' ? MONTLY_DATE_FORMAT : DAILY_DATE_FORMAT}
                            startDate={this.state.startDate}
                            showMonthYearPicker={this.props.type === 'monthly'}
                            showFullMonthYearPicker={this.props.type === 'monthly'}
                            showFourColumnMonthYearPicker={this.props.type === 'monthly'}
                            endDate={this.state.endDate}
                            onChange={(date) => {
                                this.onChangeStartDate(date)
                            }}
                            onChangeRaw={this.handleDateChangeRaw}
                            locale={this.props.i18n.language}
                            />
                    </Form.Field>
                    <Form.Field width={8}>
                        <label>{END_DATE_LABEL()}</label>
                        <DatePicker
                            maxDate={this.props.hourlyDeviceStats ? this.threeMonthsFilter(this.state.startDate, 'end') : this.props.setSpecificEndDate ?? new Date()}
                            selected={this.state.endDate}
                            selectsEnd
                            dateFormat={this.props.type === 'monthly' ? MONTLY_DATE_FORMAT : DAILY_DATE_FORMAT}
                            showMonthYearPicker={this.props.type === 'monthly'}
                            showFullMonthYearPicker={this.props.type === 'monthly'}
                            showFourColumnMonthYearPicker={this.props.type === 'monthly'}
                            minDate={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={(date) => {
                                this.onChangeEndDate(date)
                            }}
                            onChangeRaw={this.handleDateChangeRaw}
                            locale={this.props.i18n.language}
                            />
                    </Form.Field>
                    {this.props.isUserRolesVisibility &&
                        <Form.Field>
                            <label>{USER_ROLES_LABEL()}</label>
                            <Dropdown
                                className='report-dropdown'
                                fluid
                                selection
                                search clearable
                                placeholder={SEARCH_ROLE_PLACEHOLDER()}
                                options={this.state.roleDropdown}
                                value={this.state.userRoles}
                                onChange={this.onChangeUserRoles}
                                loading={this.state.loadingRoles} />
                        </Form.Field>}
                    {this.props.isSizeVisibility &&
                    <Form.Input
                        fluid
                        label={dateRangeDropdownMessages().SIZE_LABEL}
                        width={8}
                        placeholder={dateRangeDropdownMessages().SIZE_LABEL}
                        type='number'
                        value={this.state.sizeValue}
                        onChange={this.onChangeSize}
                    />}
                    {this.props.isOrderByVisibility && <Form.Select
                        fluid
                        onChange={this.onChangeOrder}
                        options={this.options}
                        value={this.state.orderValue}
                        width={8}
                        label={dateRangeDropdownMessages().ORDER_BY_LABEL}
                        placeholder={dateRangeDropdownMessages().ORDER_BY_LABEL}
                    />}
                    {this.props.excludeUsers && <Form.Select
                        multiple
                        options={this.props.excludeOptions}
                        label={dateRangeDropdownMessages().EXCLUDE_USER_LABEL}
                        placeholder={dateRangeDropdownMessages().SELECT_USERS_PLACEHOLDER}
                        value={this.props.excludedUserList}
                        width={8}
                        onChange={this.props.excludeOnChange}
                    />}
                    {this.props.hourlyDeviceStats &&
                    <Segment compact style={{margin: '1.6rem 0.4em', padding: '0.66em 0.5em'}}>
                        <Checkbox
                            style={{wordBreak: 'keep-all', whiteSpace: 'pre'}}
                            fitted
                            label={SIMPLE_RATIO_LABEL()}
                            width={8}
                            checked={this.state.simpleRatio}
                            onChange={this.onChangeRatio}
                        />
                    </Segment>
                    }
                </Form.Group>
            </Form>
        );
    }
}

export default withTranslation()(DateRangeDropdown);
