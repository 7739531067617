import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Header, Icon, Modal } from 'semantic-ui-react';
import { changeActiveGroup, getUserGroups } from '../api/apiCalls';
import { setSessionValuesFromToken } from '../utils/Methods';
import { showError } from '../utils/ToastHelpers';
import { CANCEL_BUTTON, CHANGE_ACTIVE_GROUP, OK_BUTTON, PUBLIC, USER_GROUPS_HEADER, changeActiveGroupMessages } from '../utils/UIMessages';
import { INFO_ICON } from '../utils/UiIcons';

const ChangeActiveGroupModal = ({ open, onClose }) => {

  const [selectedGroup, setSelectedGroup] = useState(parseInt(localStorage.getItem('activeGroup')));
  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    getUserGroups().then(res => {
      const options = res.data?.map(({ id, name }) => ({ key: id, text: name, value: id }));
      setGroupOptions([{ key: -1, text: PUBLIC(), value: -1 }, ...options]);
    }).catch(err => showError(err?.response?.data?.message));
  }, []);

  const handleChange = (e, { value }) => {
    setSelectedGroup(value);
  };

  const handleOkButton = async () => {
    try {
      const response = await changeActiveGroup(selectedGroup);
      setSessionValuesFromToken(response.data);
      onClose();
      window.location.reload();
    } catch (err) {
      showError(err?.response?.data?.message);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size='tiny'
    >
      <Header icon='users' content={CHANGE_ACTIVE_GROUP()} />
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>{USER_GROUPS_HEADER()}</label>
            <Dropdown
              selection options={groupOptions}
              onChange={handleChange}
              value={selectedGroup} />
          </Form.Field>
          {localStorage.getItem('showActiveGroupModal') && localStorage.getItem('showActiveGroupModal') === 'true' &&
            <Form.Field>
              <label> <Icon name={INFO_ICON} />{changeActiveGroupMessages().INFO_LABEL}</label>
            </Form.Field>
          }
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='red'
          inverted
          onClick={onClose}
        >
          <Icon name='close' /> {CANCEL_BUTTON()}
        </Button>
        <Button
          color='green'
          inverted
          onClick={handleOkButton}
        >
          <Icon name='checkmark' /> {OK_BUTTON()}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ChangeActiveGroupModal;