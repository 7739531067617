import React from 'react';
import {useTranslation} from 'react-i18next';
import {Tab} from "semantic-ui-react";
import ChartsTu from "./ChartsTU";
import {headerForReports} from "../../../../utils/Methods";
import { AUTOMATION_LABEL, chartsTopUsersMessages, DEVELOPMENT_LABEL, MANUAL_LABEL } from '../../../../utils/UIMessages';


const TopUsers = () => {
    const { t } = useTranslation();

    const panes = [
        {
            menuItem: chartsTopUsersMessages().GENERAL_MENU_ITEM,
            render: () => <><ChartsTu type={"total"}/></>,
        },
        {
            menuItem: chartsTopUsersMessages().USAGE_TYPE_MENU_ITEM,
            render: () => <><ChartsTu type={"usageType"}/></>,
        },
        {
            menuItem: MANUAL_LABEL(),
            render: () => <><ChartsTu type={"manual"}/></>,
        },
        {
            menuItem: AUTOMATION_LABEL(),
            render: () => <><ChartsTu type={"automation"}/></>,
        },
        {
            menuItem: DEVELOPMENT_LABEL(),
            render: () => <><ChartsTu type={"development"}/></>,
        }
    ]
    return (
        <div className='main-container-report'>
                        <div className="main-right-header">
                <h2>{t(headerForReports())}</h2>
            </div>

            <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
        </div>
    );
};

export default TopUsers;