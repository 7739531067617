import React, { useState } from 'react';
import {
  Icon,
  Button,
  Modal,
  Form,
  Dropdown,
  Header
} from 'semantic-ui-react';
import { stopTestSession, sendTestResult } from '../../api/apiCalls';
import { SEND_ICON, DROPDOWN_ICON } from '../../utils/UiIcons';
import { SUBMIT_BUTTON, multiManageMessages, integrationMessages, selectedStateMessage} from '../../utils/UIMessages';
import { showError, showSuccess } from '../../utils/ToastHelpers';
import { dateTimeTestRunByToday } from '../../utils/Constants';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const TestRunCompletionModal = props => {

    const [selectedState, setSelectedState] = useState('');
    const history = useHistory();

    const handleSubmitResult = () => {
        stopTestSession(props.sessionId).then(() => {
            const executionEndTime = dateTimeTestRunByToday();
            axios.defaults.headers.common['X-Project-Id'] = props.projectId;
            sendTestResult(props.executionStartTime, executionEndTime, selectedState, props.testRunId).then(() => showSuccess(integrationMessages().QUICK_EXEC_HAS_BEEN_EXEC)).catch(() => showError(integrationMessages().COULD_NOT_START_EXEC));
            history.push('/manual-test-sessions');
          }).catch(err => {
            showError((`${multiManageMessages().NOT_STOPPED_TEST_SESSION}: ${err.message}`))
          })
    }

    const stateOptions = [
        { key: 'BLOCKED', text: integrationMessages().BLOCKED, value: 'BLOCKED' },
        { key: 'FAILED', text: integrationMessages().FAILED, value: 'FAILED' },
        { key: 'NA', text: integrationMessages().NA, value: 'NA' },
        { key: 'NO_RUN', text: integrationMessages().NO_RUN, value: 'NO_RUN' },
        { key: 'NOT_COMPLETE', text: integrationMessages().NOT_COMPLETE, value: 'NOT_COMPLETE' },
        { key: 'PASSED', text: integrationMessages().PASSED, value: 'PASSED' }
      ];

    const handleStateChange = (e, { value }) => {
        setSelectedState(value);
    };

    return (
        <div className='test-run-modal'>
          <Modal
            closeIcon
            open={props.open}
            onClose={()=>{
                props.onClose();
            }}
            onOpen={()=>{
                props.onOpen();
            }}
            size='tiny'
          >
            <Modal.Content>
              <Form.Field className='result-section'>
                <div className='test-run-modal-field'>
                    <div>
                <Header>
                {integrationMessages().TEST_RUN_RESULT}:
                </Header>
                    <Dropdown
                        icon={DROPDOWN_ICON}
                        button
                        labeled
                        fluid
                        text={selectedStateMessage(selectedState)}
                        className='icon'
                        onChange={handleStateChange}
                        options={stateOptions}
                    />
                    </div>
                
                    <div className='device-section'>
                    <p>{integrationMessages().DEVICE_ID}: {props.detailedInfoId}</p>
                    <p>{integrationMessages().SESSION_ID}: {props.sessionId}</p>
                    </div>
                </div>
              </Form.Field>
            </Modal.Content>
            <Modal.Actions>
              <Button color='green' onClick={handleSubmitResult}>
                <Icon name={SEND_ICON} />{SUBMIT_BUTTON}
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      );
}

export default TestRunCompletionModal;
