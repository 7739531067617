import React, {Component} from 'react';
import {Button, Grid, Header, Input, Table} from 'semantic-ui-react';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {Flip, toast} from 'react-toastify';
import moment from 'moment';
import {showError, showSuccess} from '../../../utils/ToastHelpers';
import {
    addAppToGroup,
    getAllAppsInGroup,
    deleteAppInGroup,
    getAppList,
    removeAllAppsInGroup,
    addAllAppsToGroup
} from '../../../api/apiCalls'
import {DEFAULT_DATETIME_SHORT} from '../../../utils/Constants';
import { ADD_BUTTON, APPLICATION_HEADER, DELETE_BUTTON, FAILED_ERROR_MESSAGE, manageGroupApplicationsMessages, NOT_FETCH_APPLICATIONS_ERROR_MESSAGE, REMOVE_ALL_BUTTON, SEARCH_APPLICATION_PLACEHOLDER, SELECT_ALL_BUTTON, SUCCESS_MESSAGE, TOTAL_NUMBER_OF_APPLICATIONS_LABEL, UPLOAD_DATE_HEADER, VERSION_CODE_HEADER } from '../../../utils/UIMessages';
import { CHEVRON_LEFT_ICON, CHEVRON_RIGHT_ICON } from '../../../utils/UiIcons';

class ManageGroupApplications extends Component {
    state = {
        appList: [],
        cloneAppList: [],
        selectedApps: [],
        cloneSelectedApps: [],
        searchKeywordAll: '',
        searchKeywordSelected: '',
        selectedAppIds:[]
    }

    componentDidMount() {
        this.getResources()

    }

    getResources = () => {
        getAppList().then(res1 => {
            this.setState({appList: res1.data, cloneAppList: res1.data})
            getAllAppsInGroup(this.props.selectedGroup?.id).then(res2 => {
                this.setState({selectedApps: res2.data, cloneSelectedApps: res2.data}, () => {
                    this.editAllValues()
                })
            }).catch(err => {
                showError(manageGroupApplicationsMessages().NOT_FETCH_GROUP_APPLICATIONS_ERROR_MESSAGE+ err?.response?.data?.message ?? err.toString())
            })
        }).catch(err => {
            showError(NOT_FETCH_APPLICATIONS_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }

    // grup içindekilerin, bütün değerlerde görünmemesi için
    editAllValues = () => {
        if (!_.isEmpty(this.state.selectedApps)) {
            let {appList, cloneAppList} = this.state
            const selectedAppIds = this.state.selectedApps.map(app => app.id)
            appList = appList.filter(app => !selectedAppIds.includes(app.id))
            cloneAppList = cloneAppList.filter(app => !selectedAppIds.includes(app.id))
            this.setState({appList, cloneAppList,selectedAppIds})
        }
    }

    onClickAddApp = appId => {
        const data = [appId]
        const appFormData = new FormData();
        appFormData.append('appIds', data)
        addAppToGroup(this.props.selectedGroup?.id ,appFormData).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                autoClose: 3000,
                transition: Flip
            })
            this.getResources()
            this.setState({searchKeywordSelected: '', searchKeywordAll: ''})
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    handleAfterAppOperation = () => {
        showSuccess(SUCCESS_MESSAGE());
        this.getResources()
        this.setState({searchKeywordSelected: '', searchKeywordAll: ''})
    }

    onClickAddAllApps = () => {
        const appIds = this.state.appList.map(app => app.id)
        const appFormData = new FormData();
        appFormData.append('appIds', appIds);
        addAllAppsToGroup(this.props.selectedGroup?.id,appFormData).then(() => this.handleAfterAppOperation()).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    onClickRemoveAllApps = async() =>{
        try{
            const {selectedAppIds} = this.state;
            await removeAllAppsInGroup(this.props.selectedGroup?.id,selectedAppIds?.toString());
            this.handleAfterAppOperation()
        }catch(error){
            showError(FAILED_ERROR_MESSAGE()+error?.response?.data?.message ?? error.toString());
        }
    }

    onClickDeleteApp = id => {
        deleteAppInGroup(this.props.selectedGroup?.id,id).then(() => this.handleAfterAppOperation()).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    filterApps = (appList, value) => {
        return appList.filter(app => {
            return app.appName?.toLowerCase().includes(value) ||
                app.appVersion?.toLowerCase().includes(value) ||
                app.appVersionCode?.toLowerCase().includes(value) ||
                moment(app.uploadDate).format(DEFAULT_DATETIME_SHORT).includes(value)
        });
    }

    onChangeSearch = (_e, {name, value}) => {
        value = value.toLowerCase();
        if (name === 'all') {
            const {cloneAppList} = this.state
            const appList = this.filterApps(cloneAppList, value);
            this.setState({appList, searchKeywordAll: value})
        }else if (name === 'selected') {
            const {cloneSelectedApps} = this.state
            const selectedApps = this.filterApps(cloneSelectedApps, value);
            this.setState({selectedApps, searchKeywordSelected: value})
        }else{
            //invalid name
        }
    }

    render() {
        return (
            <div>
                <Grid columns={2} celled={'internally'} centered>
                    <Grid.Column>
                        <div className='manage-group-container'>
                            <Input fluid onChange={this.onChangeSearch} placeholder={SEARCH_APPLICATION_PLACEHOLDER()}
                                   value={this.state.searchKeywordAll}
                                   style={{paddingRight: 7}} name={'all'}/>
                            {!_.isEmpty(this.state.appList) && <><Table basic='very' celled compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{APPLICATION_HEADER()}</Table.HeaderCell>
                                        <Table.HeaderCell>{ADD_BUTTON()}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {_.orderBy(this.state.appList, ['appName'], ['asc']).map((app, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <Header as='h4'>
                                                        <Header.Content>
                                                            {_.capitalize(app.appName)} {app.appVersion}
                                                            <Header.Subheader>{VERSION_CODE_HEADER()}: {app.appVersionCode} </Header.Subheader>
                                                            <Header.Subheader>{UPLOAD_DATE_HEADER()}: {moment(app.uploadDate).format('DD/MM/YYYY HH:mm')}</Header.Subheader>
                                                        </Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                                <Table.Cell width={'3'}>
                                                    <Button icon={CHEVRON_RIGHT_ICON} primary onClick={() => {
                                                        this.onClickAddApp(app.id)
                                                    }}>

                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                                {this.state.appList?.length > 1 &&
                                <Button style={{marginTop: 10}} primary fluid onClick={this.onClickAddAllApps}>{SELECT_ALL_BUTTON()}</Button>}
                            </>
                            }
                        </div>
                        <div className='count-total-number'>
                            {TOTAL_NUMBER_OF_APPLICATIONS_LABEL()}:{' '}
                            {this.state.appList?.length}
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='manage-group-container'>
                            <Input fluid onChange={this.onChangeSearch} placeholder={SEARCH_APPLICATION_PLACEHOLDER()}
                                   value={this.state.searchKeywordSelected}
                                   style={{paddingRight: 7}} name={'selected'}/>
                            {!_.isEmpty(this.state.selectedApps) && <><Table basic='very' celled compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{DELETE_BUTTON()}</Table.HeaderCell>
                                        <Table.HeaderCell>{APPLICATION_HEADER()}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.selectedApps.map((app, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell width={'3'}>
                                                    <Button icon={CHEVRON_LEFT_ICON} color={'red'}
                                                            onClick={() => this.onClickDeleteApp(app.id)}>

                                                    </Button>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Header as='h4'>
                                                        <Header.Content>
                                                            {_.capitalize(app.appName)} {app.appVersion}
                                                            <Header.Subheader>{VERSION_CODE_HEADER()}: {app.appVersionCode} </Header.Subheader>
                                                            <Header.Subheader>{UPLOAD_DATE_HEADER()}: {moment(app.uploadDate).format('DD/MM/YYYY HH:mm')}</Header.Subheader>
                                                        </Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                            {this.state.selectedAppIds?.length > 1 &&
                                <Button style={{marginTop: 10}} primary fluid onClick={this.onClickRemoveAllApps}>{REMOVE_ALL_BUTTON()}</Button>}
                            </>
                            }
                        </div>
                        <div className='count-total-number'>
                            {TOTAL_NUMBER_OF_APPLICATIONS_LABEL()}:{' '}
                            {this.state.selectedApps?.length}
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(ManageGroupApplications);
