import React from 'react'
import { Button, Header, Modal, Segment, Icon } from "semantic-ui-react";
import { CHECKMARK_ICON } from '../utils/UiIcons';

const BusyDeviceInfoModal = ({visibility, header, closeOnEscape, onClose, headerIcon, content, onClick, buttonContent, closeOnDimmerClick}) => {


    return (
        <div>
            <Modal 
                open={visibility} 
                size='small' 
                closeOnDimmerClick={closeOnDimmerClick} 
                className='z-index-999'
                closeOnEscape={closeOnEscape}
                onClose={onClose} 
                dimmer='blurring'
            >
                <Header icon={headerIcon} content={header} color='red' />
                <Modal.Content style={{ textAlign: 'center' }}>
                    <Segment>
                        <Header size='medium'>{content}</Header>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={onClick} icon> <Icon name={CHECKMARK_ICON} /> {buttonContent} </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default BusyDeviceInfoModal