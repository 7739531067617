import { useState } from 'react';
import {Tab} from 'semantic-ui-react';
import IntegrationEdit from './IntegrationEdit';
import { integrationsMessages, INTEGRATIONS_HEADER } from '../../../utils/UIMessages';

const Integrations = () => {

  const panes = [
    {
      menuItem: integrationsMessages().VISIUM_MANAGE_MENU_ITEM,
      render: () => (
        <Tab.Pane attached={false}>
          {edit ? (
            <IntegrationEdit name={integrationsMessages().VISIUM_MANAGE_MENU_ITEM} />
          ) : (
            <></>
          )}
        </Tab.Pane>
      )
    }
  ];

  //Edit Hook
  const [edit] = useState(true);

  return (
    <>
      <div>
        <div className='main-right-header'>
          <div>
            <h2>{INTEGRATIONS_HEADER()}</h2>
            <small>{integrationsMessages().INTEGRATIONS_SUB_HEADER}</small>
          </div>
        </div>

        <div className='main-content'>
          <Tab menu={{ pointing: true }} panes={panes} />
        </div>

      </div>
    </>
  );
}

export default Integrations;
