import JavaFramework from './Java';
import PythonFramework from './Python';
import RubyFramework from './Ruby';
import RobotFramework from './Robot';
import JsOxygenFramework from './JS_oxygen';
import JsWdIoFramework from './JS_wdio';
import JsWdFramework from './JS_wd';

const frameworks = {
  java: JavaFramework,
  python: PythonFramework,
  ruby: RubyFramework,
  robot: RobotFramework,
  jsWd: JsWdFramework,
  jsWdio: JsWdIoFramework,
  jsOxygen: JsOxygenFramework
};

export default frameworks;
