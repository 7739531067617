import React, { useState, useEffect } from 'react'
import { Icon, Loader } from 'semantic-ui-react';
import Filter from './Filter';
import DebouncedInput from './DebouncedInput';
import { TableWrapper, PaginationV8 } from './TableHelper';
import { DEFAULT_PAGINATION_PROPS } from '../../utils/Constants';


import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table';
import { LOADING, NO_DATA_LABEL, reactTableMessages } from '../../utils/UIMessages';
import { CARET_DOWN_ICON, CARET_UP_ICON } from '../../utils/UiIcons';

//To Adorn asc/desc sorting buttons when clicked.
const sortingAdornment = {
  display: 'flex',
  borderBottom: '1px solid #1c1c4d'
}

const ReactTableV8 = ({
  data = [],
  columns = [],

  //Table Reference (returns table states like pageIndex pageSize etc.)
  getTableRefProp = () => null,

  //Search Operations Props-------------
  globallySearchableProp = false,
  columnwiseFilterableProp = false,

  //Loading warning prop----------------
  loadingProp = false,

  //Pagination Props---------------
  manualPaginationProp = false,
  pageIndexProp = DEFAULT_PAGINATION_PROPS.PAGE_INDEX,
  pageSizeProp = DEFAULT_PAGINATION_PROPS.PAGE_SIZE,
  totalPagesProp,
  onPaginationChangeProp = () => null,

  //Sorting Props-------------------
  manualSortingProp = false,
  onSortedChangeProp = () => null,

  //This specific hand-written state is required to prevent sorting-related crashes in Reports Screens.
  onComponentDidUpdateProp = false
}) => {

  const [sorting, setSorting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const [pagination, setPagination] =
    useState({
      pageIndex: pageIndexProp,
      pageSize: pageSizeProp
    })

  const table = useReactTable({
    //means "data : data" coming from table component props
    data,
    //means "columns : columns" coming from table component props
    columns,
    columnResizeMode: 'onChange',

    //When Debug needed, this columns will be required. Therefore, they must stay as commented-out.
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,

    //Table State Options------------------------------
    state: {
      sorting,
      pagination,
      columnFilters,
      globalFilter
    },
    getCoreRowModel: getCoreRowModel(),

    //Filter Options---------------------------------------
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),

    //Sorting Operations Table Options---------------------
    onSortingChange: setSorting,
    manualSorting: manualSortingProp,
    getSortedRowModel: getSortedRowModel(),

    //Pagination Operations- Table Options------------------
    onPaginationChange: setPagination,
    manualPagination: manualPaginationProp,
    pageCount: (totalPagesProp !== null || totalPagesProp !== undefined) && totalPagesProp,
    getPaginationRowModel: getPaginationRowModel()
  })

  //Loading Pperations useEffect---------------------
  useEffect(() => {
    setLoading(loadingProp);
  }, [loadingProp]);

  //Pagination Operations useEffect-----------------------
  useEffect(() => {
    if (manualPaginationProp) {
      onPaginationChangeProp(pagination.pageIndex, pagination.pageSize);
    }
  }, [pagination, manualPaginationProp]);

  //Sorting and filterin operations useEffect----------------------
  useEffect(() => {
    onSortedChangeProp(sorting);
  }, [sorting]);

  useEffect(() => {
    setSorting([]);
  }, [onComponentDidUpdateProp]);

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName' && table.getState().sorting[0]?.id !== 'fullName') {
      table.setSorting([{ id: 'fullName', desc: false }])
    }
  }, [table.getState().columnFilters[0]?.id]);

  //Filtering Function--------------------------------------------
  const getFilterJsx = column => {
    const { filterType, selection } = columns.find(({ id }) => column.id === id);
    return <Filter
      column={column}
      selection={selection}
      filterType={filterType}
      table={table}
    />
  };

  //Return Table Reference---------------------------------------------
  useEffect(() => {
    getTableRefProp({ pageIndex: table.getState().pagination.pageIndex, pageSize: table.getState().pagination.pageSize })
  }, [table.getState().pagination.pageSize, table.getState().pagination.pageIndex])

  //HTML Part-----------------------------------------------------------
  return (
    <TableWrapper>
      {globallySearchableProp === true ?
        <div>
          <DebouncedInput
            iconProp={true}
            valueProp={globalFilter ?? ''}
            onChangeProp={value => setGlobalFilter(String(value))}
            className='debounce'
            placeHolderProp={reactTableMessages().DEBOUNCE_INPUT_SEARCH_ALL_PLACEHOLDER}
          />
        </div> : null}
      <div className='react-table-outer'>
        <table
          {...{ style: {} }}
          className='react-table'
        >
          <thead className='react-table-header' key='header'>
            {table.getHeaderGroups().map(headerGroup => (
              <>
                <tr key={headerGroup.id} className='react-table-header-row'>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        {...{
                          key: header.id,
                          colSpan: header.colSpan,
                          style: {
                            width: header.getSize()
                          }
                        }}
                        className='react-table-header-cell'
                      >
                        {
                          header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick: header.column.getToggleSortingHandler()
                              }}
                            >
                              <div style={{
                                asc: sortingAdornment,
                                desc: sortingAdornment
                              }[String(header.column.getIsSorted())] ?? null}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <span><Icon name={CARET_UP_ICON} /></span>,
                                  desc: <span><Icon name={CARET_DOWN_ICON} /></span>
                                }[String(header.column.getIsSorted())] ?? null}
                              </div>
                            </div>
                        )
                      }


                        <div
                          {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''
                              }`
                          }}
                        />
                      </th>
                    )
                  })}
                </tr>
                {
                  columnwiseFilterableProp === true ?
                    <tr id={headerGroup.id} className='react-table-search-row'>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} id={headerGroup.id} className='react-table-search-cell'>
                          {header.column.getCanFilter() ? (
                            <div>
                              {getFilterJsx(header.column)}
                            </div>) : null}
                        </th>
                      ))}
                    </tr> : null
                }
              </>
            ))}
          </thead>
          <tbody className='react-table-body'>
            {
              loading
                ? <tr id='loadingOrNoData'>
                  <td colSpan={'100%'}>
                    <h4 id='loading-or-no-data-react-table'>
                      <Loader
                        style={{ color: 'black' }}
                        active
                        inline={'centered'}
                        indeterminate={false}
                      >{LOADING()}
                      </Loader>
                    </h4>
                  </td>
                </tr>
                : <>
                  {table.getRowModel().rows.length > 0
                    ? table.getRowModel().rows.map(row => (
                      <tr key={row.id} className='odd:bg-white even:bg-slate-50 py-1 '>
                        {row.getVisibleCells().map(cell => (
                          <td
                              id={cell.id}
                              key={cell.id}
                              className='react-table-body-cell'
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                        )}
                      </tr>
                    ))
                    : <tr id='loadingOrNoData'>
                      <td colSpan={'100%'} id='loadingOrNoData2'>
                        <h4 id='loading-or-no-data-react-table'>{NO_DATA_LABEL()}.</h4>
                      </td>
                    </tr>
                  }
                </>
            }
          </tbody>
        </table>
      </div>

      <PaginationV8
        table={table}
      />
    </TableWrapper>
  )
}
export default ReactTableV8
