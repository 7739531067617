import {WEBSOCKET_STATES, TIMER, INTERVAL_BETWEEN_MULTITOUCHES} from './Constants'

function handleReceivedData(data, eventEmitter, deviceId) {
    var fileReader = new FileReader();
    fileReader.onload = (event) => {
        var receivedByte = new Uint8Array(event.target.result);
        var b64encoded = btoa(receivedByte.reduce(function (data1, byte1) {
          return data1 + String.fromCharCode(byte1);
        }, ''));
        eventEmitter.emit('jpeg' + deviceId, b64encoded);
    }
    fileReader.readAsArrayBuffer(data);
}

const sendMultiTouchMessage = async function (webSocket, message, count) {
    for (let i = 0; i < count; i++) {
        webSocket.send(message);
        if (i < count - 1) {
            await TIMER(INTERVAL_BETWEEN_MULTITOUCHES).then(() => {/**/});
        }
    }
}
function sendScrollMessage(webSocket,message,scroll){
    const newScroll = [];
    scroll.map(element=>{
        newScroll.push(((element.x)/(element.width))+'#'+((element.y)/(element.height)));
    });
    message = message.concat(newScroll)
    webSocket.send(message);
}

export default class DeviceClient {
    ws = {};
    timestampInterval = null;

    constructor(url, eventEmitter, deviceId) {
        this.ws = new WebSocket(url);
        let timeStamp="";
        let tempTimeStamp ="";
        let websocket = this.ws;

        this.timestampInterval = setInterval(function(){
            if(timeStamp===tempTimeStamp){
                websocket.send('HOME');
            }else{
                tempTimeStamp = timeStamp;
            }
        },5000);

        this.ws.onmessage = function (evt) {
            const data = evt.data;
            timeStamp = evt.timeStamp;
            
            if (data instanceof Blob) {
                handleReceivedData(data, eventEmitter, deviceId);          
            } else {
                eventEmitter.emit('connection', JSON.parse(data));
            }

        };

        this.ws.onclose = () => {
            clearInterval(this.timestampInterval)
        };

        eventEmitter.on("HOME" + deviceId, () => {
            this.ws.send('HOME');
        })
        eventEmitter.on("BACK" + deviceId, () => {
            this.ws.send('BACK');
        })
        eventEmitter.on("MENU" + deviceId, () => {
            this.ws.send('MENU');
        })
        eventEmitter.on("CLICK" + deviceId, (x, y, width, height) => {
            var message = "DOWN" + (x * 1.0 / width) + "#" + (y * 1.0 / height);
            this.ws.send(message);
        })
        eventEmitter.on("MULTI_TOUCH" + deviceId, (multitouch, numberOfFinger, numberOfClick) => {
            const newMultiTouch = [];

            multitouch.map(element=>{
              newMultiTouch.push(((element.x)/(element.width))+'#'+((element.y)/(element.height)));
            });

            let message = 'MULTI_TOUCH';
            message = message.concat(newMultiTouch.join('-'), numberOfFinger)
            sendMultiTouchMessage(this.ws, message, numberOfClick);
        })
        // eventEmitter.on("START",(x,y,width,height)=>{
        //     var xcord = x<width ? x * 1.0 / width : 1;
        //     var ycord = y<height ? y * 1.0 / height : 1;
        //     // ws.send("DOWN" + xcord + "#" + ycord);
        // })
        eventEmitter.on("DRAG" + deviceId, (x, y, width, height) => {
            var xcord = x < width ? x * 1.0 / width : 1;
            var ycord = y < height ? y * 1.0 / height : 1;
            this.ws.send("MOVE" + xcord + "#" + ycord);
        })
        eventEmitter.on("RELEASE" + deviceId, (x, y, width, height) => {

            var xcord = x < width ? x * 1.0 / width : 1;
            var ycord = y < height ? y * 1.0 / height : 1;
            this.ws.send("UP" + xcord + "#" + ycord);
        })
        eventEmitter.on("KEY" + deviceId, (key) => {
          this.ws.send("KEY" + key);
        })
        eventEmitter.on("TEXT" + deviceId, (key) => {
          this.ws.send("TEXT" + key);
        })
        eventEmitter.on("RIGHTKEY" + deviceId, () => {
          this.ws.send("KEY$RIGHT$")
        })
        eventEmitter.on("LEFTKEY" + deviceId, () => {
          this.ws.send("KEY$LEFT$")
        })
        eventEmitter.on("ENTER" + deviceId, () => {
          this.ws.send("KEY$ENTER$")
        })
        eventEmitter.on("QUALITY" + deviceId, (quality) => {
          this.ws.send("QUALITY" + quality);
        })
        eventEmitter.on("ROTATE" + deviceId, () => {
          this.ws.send("ROTATE");
        })
        eventEmitter.on("SWITCHLOCK" + deviceId, () => {
          this.ws.send("SWITCHLOCK");
        })
        eventEmitter.on('SCROLLUP' + deviceId, scroll => {
            sendScrollMessage(this.ws, 'SCROLLUP', scroll)
        })
        eventEmitter.on('SCROLLDOWN' + deviceId, scroll => {
            sendScrollMessage(this.ws, 'SCROLLDOWN', scroll)
        })
        eventEmitter.on("SET_APPIUM_SESSION_ID" + deviceId, (appiumSessionId) => {
            this.ws.send("SET_APPIUM_SESSION_ID:" + appiumSessionId);
        })
        eventEmitter.on("CLEAR_APPIUM_SESSION_ID" + deviceId, () => {
            this.ws.send("CLEAR_APPIUM_SESSION_ID");
        })
        eventEmitter.on('CLANG' + deviceId, locale => {
            this.ws.send(`CLANG${locale}`);
        })
    }

    getSocketState() {
        return this.ws.readyState
    }

    closeSocket() {
        if (this.ws.readyState === WEBSOCKET_STATES.CLOSED) {
            console.log("WS ALREADY CLOSED")
            //("Socket is already closed")
        } else {
            console.log("LET'S CLOSE SOCKET")
            //console.log("Socket is closed")
            this.ws.close();
        }
    }

}
 



