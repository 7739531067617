import React, {Component} from 'react';
import {Button, Grid, Header, Input, Table} from 'semantic-ui-react';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import {showError, showSuccess} from '../../../utils/ToastHelpers';
import {
    addDevicesToGroup,
    getAllDevicesInGroup,
    deleteDeviceInGroup,
    removeAllDevicesInGroup
} from '../../../api/apiCalls'
import { ADD_BUTTON, DELETE_BUTTON, DEVICE_HEADER, FAILED_ERROR_MESSAGE, manageGroupDevicesMessages, REMOVE_ALL_BUTTON, SELECT_ALL_BUTTON, SUCCESS_MESSAGE, TOTAL_NUMBER_OF_DEVICES_LABEL } from '../../../utils/UIMessages';
import { CHEVRON_LEFT_ICON, CHEVRON_RIGHT_ICON } from '../../../utils/UiIcons';

class ManageGroupDevices extends Component {
    state = {
        deviceList: [],
        cloneDeviceList: [],
        selectedDevices: [],
        cloneSelectedDevices: [],
        searchKeywordAll: '',
        searchKeywordSelected: '',
        selectedDeviceIds: []
    }

    componentDidMount() {
        this.getResources()

    }

    getResources = () => {
        axios.get('/api/devices').then(res1 => {
            this.setState({deviceList: res1.data, cloneDeviceList: res1.data})
            //axios.get('/api/group/' + this.props.selectedGroup?.id + '/devices')
            getAllDevicesInGroup(this.props.selectedGroup?.id).then(res2 => {
                this.setState({selectedDevices: res2.data, cloneSelectedDevices: res2.data}, () => {
                    this.editAllValues()
                })
            }).catch(err => {
               showError(manageGroupDevicesMessages().NOT_FETCH_GROUP_DEVICES_ERROR_MESSAGE+err?.response?.data?.message ?? err.toString())
            })
        }).catch(err => {
            showError(manageGroupDevicesMessages().NOT_FETCH_DEVICES_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString() )
        })

    }

    // grup içindekilerin, bütün değerlerde görünmemesi için
    editAllValues = () => {
        if (!_.isEmpty(this.state.selectedDevices)) {
            let {deviceList, cloneDeviceList} = this.state
            const selectedDeviceIds = this.state.selectedDevices.map(device => device.id)
            deviceList = deviceList.filter(device => !selectedDeviceIds.includes(device.id))
            cloneDeviceList = cloneDeviceList.filter(device => !selectedDeviceIds.includes(device.id))
            this.setState({deviceList, cloneDeviceList,selectedDeviceIds})
        }
    }

    //TODO: needs to be centralized. Other files have same function
    handleAfterDeviceOperation = () => {
        showSuccess(SUCCESS_MESSAGE());
        this.getResources()
        this.setState({searchKeywordSelected: '', searchKeywordAll: ''})
    }

    onClickAddDevice = deviceId => {
        const data = [deviceId]
        const deviceFormData = new FormData();
        deviceFormData.append('udIds', data)
        //axios.post('/api/group/' + this.props.selectedGroup?.id + '/devices', deviceFormData)
        addDevicesToGroup(this.props.selectedGroup?.id,deviceFormData).then(() => {
            this.handleAfterDeviceOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }


    onClickAddAllDevices = () => {
        const deviceIds = this.state.deviceList.map(device => device.deviceId)
        const deviceFormData = new FormData()
        deviceFormData.append('udIds', deviceIds)
        //axios.post('/api/group/' + this.props.selectedGroup?.id + '/devices', deviceFormData)
        addDevicesToGroup(this.props.selectedGroup?.id,deviceFormData).then(() => {
            this.handleAfterDeviceOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    onClickDeleteDevice = id => {
        //axios.delete('/api/group/' + this.props.selectedGroup?.id + '/device/' + id).
        deleteDeviceInGroup(this.props.selectedGroup?.id,id).then(() => {
            this.handleAfterDeviceOperation()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        })
    }

    onClickRemoveAllDevices = async() =>{
        try{
            const {selectedDeviceIds} = this.state;
            await removeAllDevicesInGroup(this.props.selectedGroup?.id,selectedDeviceIds?.toString());
            showSuccess(SUCCESS_MESSAGE());
            this.getResources();
            this.setState({searchKeywordSelected: '', searchKeywordAll: ''});
        }catch(error){
            showError(FAILED_ERROR_MESSAGE()+error?.response?.data?.message ?? error.toString());
        }
    }

    filterDeviceList = (deviceList, value) => {
        return deviceList.filter(device => {
            return device.deviceId?.toLowerCase().includes(value) ||
                device.deviceModel?.toLowerCase().includes(value) ||
                device.os?.toLowerCase().includes(value) ||
                device.brand?.toLowerCase().includes(value)
        });
    }

    onChangeSearch = (_e, {name, value}) => {
        value = value.toLowerCase();
        if (name === 'all') {
            const {cloneDeviceList} = this.state
            const deviceList = this.filterDeviceList(cloneDeviceList, value);
            this.setState({deviceList, searchKeywordAll: value})
        }else if (name === 'selected') {
            const {cloneSelectedDevices} = this.state
            const selectedDevices = this.filterDeviceList(cloneSelectedDevices, value);
            this.setState({selectedDevices, searchKeywordSelected: value})
        }else{
            //invalid name
        }
    }

    render() {
        return (
            <div>
                <Grid columns={2} celled={'internally'} centered>
                    <Grid.Column>

                        {/* <Form>
                            <Form.Field>
                                <h3>{this.props.t('All Devices')}</h3>
                            </Form.Field>
                        </Form> */}
                        <div className='manage-group-container'>
                            <Input fluid value={this.state.searchKeywordAll} onChange={this.onChangeSearch} placeholder={manageGroupDevicesMessages().SEARC_DEVICE_PLACEHOLDER}
                                   style={{paddingRight: 7}} name={'all'}/>
                            {!_.isEmpty(this.state.deviceList) && <><Table basic='very' celled compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{DEVICE_HEADER()}</Table.HeaderCell>
                                        <Table.HeaderCell>{ADD_BUTTON()}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {_.orderBy(this.state.deviceList, ['brand', 'deviceModel'], ['asc', 'asc']).map((device, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <Header as='h4'>
                                                        <Header.Content>
                                                            {_.capitalize(device.brand)} {device.deviceModel}
                                                            <Header.Subheader>{device.deviceId}</Header.Subheader>
                                                        </Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                                <Table.Cell width={'3'}>
                                                    <Button icon={CHEVRON_RIGHT_ICON} primary onClick={() => {
                                                        this.onClickAddDevice(device.deviceId)
                                                    }}>
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                                {this.state.deviceList?.length > 1 &&
                                <Button style={{marginTop: 10}} primary fluid onClick={this.onClickAddAllDevices}>{SELECT_ALL_BUTTON()}</Button>}
                            </>}
                        </div>
                        <div className='count-total-number'>
                            {TOTAL_NUMBER_OF_DEVICES_LABEL()}:{' '}
                            {this.state.deviceList?.length}
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='manage-group-container'>
                            <Input fluid value={this.state.searchKeywordSelected} onChange={this.onChangeSearch} placeholder={manageGroupDevicesMessages().SEARC_DEVICE_PLACEHOLDER}
                                   style={{paddingRight: 7}} name={'selected'}/>
                            {!_.isEmpty(this.state.selectedDevices) &&<><Table basic='very' celled compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{DELETE_BUTTON()}</Table.HeaderCell>
                                        <Table.HeaderCell>{DEVICE_HEADER()}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.selectedDevices.map((device, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell width={'3'}>
                                                    <Button icon={CHEVRON_LEFT_ICON} color={'red'}
                                                            onClick={() => this.onClickDeleteDevice(device.id)}>
                                                    </Button>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Header as='h4'>
                                                        <Header.Content>
                                                            {_.capitalize(device.brand)} {device.deviceModel}
                                                            <Header.Subheader>{device.deviceId}</Header.Subheader>
                                                        </Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                            {this.state.selectedDeviceIds?.length > 1 &&
                                <Button style={{marginTop: 10}} primary fluid onClick={this.onClickRemoveAllDevices}>{REMOVE_ALL_BUTTON()}</Button>}
                            </>}
                        </div>
                        <div className='count-total-number'>
                            {TOTAL_NUMBER_OF_DEVICES_LABEL()}:{' '}
                            {this.state.selectedDevices?.length}
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(ManageGroupDevices);
