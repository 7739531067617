import React, {useState} from 'react';
import {Segment, Sidebar} from "semantic-ui-react";
import ManageProjectsPanel from './ManageProjectsPanel';
import ManageTestRunPanel from './ManageTestRunPanel';


const ManageSidebar = (props) => {
  const manageProjects = props.manageProjects;
  const [manageTestRuns, setManageTestRuns] = useState([]);
  const [projectId, setProjectId] = useState([]);

  // Handle clicks on the Back button by resetting the selectedProject state
  const handleBackClick = () => {
    setManageTestRuns([]);
  }

  return (
    <Sidebar
        as={Segment}
        animation='scale down'
        direction='right'
        onHide={props.onHideSidebar}
        vertical
        visible={props.visible}
        width='wide'
        className='device-sidebar-component'
    >

      <div>
      {manageTestRuns.length > 0 ? (
        <ManageTestRunPanel
          projectId={projectId}
          manageTestRuns={manageTestRuns}
          onBackClick={handleBackClick}

          //Props for test session starter
          onHandleChange={props.onHandleChange}
          handleDropdown={props.handleDropdown}
          onClickStartSession={props.onClickStartSession}
          allDevices={props.allDevices}
          allAppsDropdown={props.allAppsDropdown}
          testSessionField={props.testSessionField}
          modalVisibility={true}
          loading={props.loading}
          sessionType={props.sessionType}
          prepareFreeDevices={props.prepareFreeDevices}
        />
      ) : (
        <ManageProjectsPanel manageProjects={manageProjects} setManageTestRuns={setManageTestRuns} setProjectId={setProjectId} visiumManageUrl={props.visiumManageUrl}/>
      )}
      </div>

    </Sidebar>
  )
}

export default ManageSidebar;
