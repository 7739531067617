import {useState, useEffect} from 'react';
import {Button, Icon, Segment} from 'semantic-ui-react';
import {
    getVisiumManageIntegration
} from '../../../api/apiCalls';
import {showError} from '../../../utils/ToastHelpers';
import { useTranslation } from 'react-i18next';
import { ACTIVE_LABEL, integrationsMessages, N_A_LABEL, PASSIVE_LABEL, URL_LABEL, KEY_LABEL, VALUE_LABEL } from '../../../utils/UIMessages';
import { CHECKMARK_ICON, EDIT_ICON, X_ICON } from '../../../utils/UiIcons';
import {getPrivileges } from '../../../utils/Methods';
import { PrivilegeConstants } from '../../../utils/PrivilegeConstants';
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import IntegrationEditModal from './IntegrationEditModal';

export const initialIntegration = {
    id : null,
    url : null,
    active : null,
    integrationParam : [ ],
    integrationType : null
};

export const initialParam = {
    paramKey: null,
    paramValue: null
}

const IntegrationEdit = () => {
    const {t} = useTranslation();

    //Visium Manage Hook(Holds the Visium Manage Integration infos coming from db)
    const [visiumManageInfo, setVisiumManageInfo] = useState(JSON.parse(JSON.stringify(initialIntegration)));
    const [privileges, setPrivileges] = useState([]);
    const [open, setOpen] = useState(false);

    const columnsRender = () => [
        {
            header: () => <div>{KEY_LABEL()}</div>,
            accessorKey: 'paramKey',
            id: 'paramKey',
            cell: info => (<span>{info.getValue() ?? N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{VALUE_LABEL()}</div>,
            accessorKey: 'paramValue',
            id: 'paramValue',
            cell: info => (<span>{info.getValue() ?? N_A_LABEL()}</span>)
        }
    ];

    useEffect(() => {
        const localPrivileges = getPrivileges();
        setPrivileges(prevPrivileges => [...prevPrivileges, ...localPrivileges])
    }, [])

    useEffect(() => {
        if (privileges) {
            getVisiumManageList()
        }
    }, [privileges]);

    const getVisiumManageList = () => {
        if (privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
            privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
            privileges.includes(PrivilegeConstants.INTEGRATION)) {
            getVisiumManageIntegration().then(res => {
                //Getting Visium Manage Integration Information
                const data = res.data;
                setVisiumManageInfo(data[0]);
            }).catch(err => {
                showError(`${integrationsMessages().NOT_FETCH_INTEGRATION_DETAILS} ${t(err?.response?.data?.message ?? err.toString())}`);
            });
        }
    }


    return (
        <>
            <div>
                <div className='integration-main-right-header'>
                    <div></div>
                    <div id='integration-edit-main-right-edit-button'>
                        {!open ? (
                            <Button
                                primary
                                icon={EDIT_ICON}
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            />
                        ) : (
                            null
                        )}
                    </div>
                </div>

                {!open ? (
                    <div>
                        {/*  Rendering Non-editable information  */}
                        <div className='visium-manage-information-section'>
                                <Segment id='integration-edit-segment'>{`${URL_LABEL()} : ${visiumManageInfo?.url ?? ''}`}</Segment>
                                <Segment
                                    id='integration-edit-segment'
                                    style={{
                                        color: `${visiumManageInfo?.active === true ? 'green' : 'red'}`
                                    }}>
                                    {`${visiumManageInfo?.active === true ? ACTIVE_LABEL() : PASSIVE_LABEL()} : `}
                                    {visiumManageInfo?.active === true ? <Icon name={CHECKMARK_ICON}/> : <Icon name={X_ICON}/>}
                                </Segment>
                                
                                {integrationsMessages().DEFAULT_PARAMETERS_LABEL}
                                <div className='integration-modal-edit'>
                                    <ReactTableV8
                                        columns={columnsRender()}
                                        data={visiumManageInfo.integrationParam}
                                    />
                                </div>
                        </div>

                    </div>
                ) : (
                    <IntegrationEditModal
                        open={open}
                        setOpen={setOpen}
                        integrationData={visiumManageInfo}
                        privileges={privileges}
                        setIntegrationData={setVisiumManageInfo}
                        style={{height: '50vw'}}
                    />
                )}
            </div>
        </>
    );
}

export default IntegrationEdit;
