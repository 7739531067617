import { SET_CHOSENAPPMODE } from '../actions/types'

const initialState ={
    appMode: 'native'
}

const systemParametersReducer = (state = initialState,action) =>{
    switch(action.type){
        case SET_CHOSENAPPMODE:
            return{
                ...state,
                appMode: action.data
            }
        default :
            return state;    
    }
}

export default systemParametersReducer;