import React, {Component} from 'react';
import axios from 'axios';
import {Flip, toast} from 'react-toastify';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Confirm, Dropdown, Form, Header, Input, Icon, Modal} from 'semantic-ui-react';
import DatePicker, {registerLocale} from 'react-datepicker';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {stringify} from 'querystring';
import _, { uniqueId } from 'lodash';
import TestSessionDetailsModal from './TestSessionDetailsModal';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-GB';
import {getPrivileges, validateUrl} from '../../utils/Methods';
import {showError} from '../../utils/ToastHelpers';
import {
    getTestSessionList,
    deleteTestSession,
    deleteReportFile,
    downloadReportFile,
    getUserSummaryList,
    getVisiumManageIntegration,
    getSingleUseToken,
    getTestSessionInfo,
    getIntegrationDefects
} from '../../api/apiCalls'
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8'
import {DATETIME_FORMAT, DEBOUNCE_WAIT} from '../../utils/Constants';
import {Base64} from 'js-base64';
import {proxy} from '../../../package.json';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import { ACTIONS_HEADER, automationTestSessionMessages,
     DEVICE_HEADER, END_DATE_LABEL, FAILED_ERROR_MESSAGE,
      NOT_FETCH_USERS, N_A_LABEL, OS_VERSION_HEADER, 
      REPORT_ID_HEADER, SESSION_ID_HEADER, START_DATE_LABEL,
       SUCCESS_MESSAGE, USER_HEADER,NOT_FETCH_DEVICES, NOT_FETCH_DEFECTS, DETAILS_LABEL, DELETE_BUTTON, DEFECT_BUTTON, DEFECT_URL_HEADER, DEFECT_CODE, PROJECT_SUMMARY, GO_TO_DEFECT_BUTTON, TEST_SESSIONS_HEADER, SESSION_NAME_HEADER, SEARCH_ID_PLACEHOLDER, USERS_HEADER, TIME_LABEL, RESET_TABLE_BUTTON, AUTOMATION_TEST_SESSION_HEADER, CONFIRM_BUTTON, CANCEL_BUTTON, OPEN_NEW_DEFECT_BUTTON, PREVIOUSLY_OPENED_DEFECTS_HEADER, DEFECT_INSPECTION_HEADER, SEARCH_SESSION_NAME_PLACEHOLDER, SEARCH_USER_PLACEHOLDER } from '../../utils/UIMessages';
import { BUG_ICON, ELLIPSIS_HORIZONTAL_ICON, INFO_ICON, MOBILE_ICON, SEARCH_ICON, X_ICON } from '../../utils/UiIcons';

registerLocale('tr', tr);
registerLocale('en', en);

class AutomationTestSession extends Component {

    //New React Table V8 Columns
    columns = [
        {
            header: () => <div>{REPORT_ID_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            cell: info => (
                <span>
                    {info.getValue() !== null ? <>{info.getValue()}</> : <>{N_A_LABEL()}</>}
                </span>
            )
        },
        {
            header: () => <div>{automationTestSessionMessages().APPIUM_SESSION_HEADER}</div>,
            accessorKey: 'name',
            id: 'name',
            cell: info => (
                <>
                    {info.getValue() !== null ? (
                        <>
                            {' '}
                            <p>
                                {info.getValue()} <br />{' '}
                                <span
                                    style={{ color: 'gray', fontSize: 12 }}
                                >{`${SESSION_ID_HEADER()}: ${info.row.original.sessionId}`}</span>
                            </p>{' '}
                        </>
                    ) : (
                        'N/A'
                    )}
                </>
            )
        },
        {
            header: () => <div>{USER_HEADER()}</div>,
            accessorKey: 'username',
            id: 'username',
            cell: info => (
                <>
                    {info.getValue() !== null ? (
                        <>
                            {' '}
                            <p>
                                {' '}
                                {info.row.original.fullName}
                                <br />{' '}
                                <span style={{ color: 'gray', fontSize: 12 }}>
                                    {info.getValue()}
                                </span>
                            </p>{' '}
                        </>
                    ) : (
                        'N/A'
                    )}
                </>
            )
        },
        {
            header: () => <div>{DEVICE_HEADER()}</div>,
            accessorKey: 'udId',
            id: 'udId',
            cell: info => (
                <>
                    {info.getValue() !== null ? (
                        <>
                            {' '}
                            <p>
                                {info.getValue()} <br />{' '}
                                <span
                                    style={{ color: 'gray', fontSize: 12 }}
                                >{`${OS_VERSION_HEADER()}: ${info.row.original.deviceOsVersion}`}</span>
                            </p>{' '}
                        </>
                    ) : (
                        'N/A'
                    )}
                </>
            )
        },
        {
            header: () => <div>{START_DATE_LABEL()}</div>,
            accessorKey: 'startDate',
            id: 'startDate',
            enableColumnFilter: false,
            cell: info => (
                <>
                    {info.getValue() !== null ? (
                        <>
                            {' '}
                            <p>
                                {moment(info.getValue()).format('DD/MM/YYYY')}{' '}
                                <br />{' '}
                                <span style={{ color: 'gray', fontSize: 12 }}>
                                    {moment(info.getValue()).format('HH:mm:ss')}
                                </span>
                            </p>{' '}
                        </>
                    ) : (
                        'N/A'
                    )}
                </>
            )
        },
        {
            header: () => <div>{END_DATE_LABEL()}</div>,
            accessorKey: 'endDate',
            id: 'endDate',
            enableColumnFilter: false,
            cell: info => (
                <>
                    {info.getValue() !== null ? (
                        <>
                            {' '}
                            <p>
                                {moment(info.getValue()).format('DD/MM/YYYY')}{' '}
                                <br />{' '}
                                <span style={{ color: 'gray', fontSize: 12 }}>
                                    {moment(info.getValue()).format('HH:mm:ss')}
                                </span>
                            </p>{' '}
                        </>
                    ) : (
                        'N/A'
                    )}
                </>
            )
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            enableSorting: false,
            enableColumnFilter: false,
            cell: info => this.generateActionMenu(info)
        }
    ]

    constructor(props) {
        super(props);
        this.state = {
            appiumReportFields: {},
            modalVisibility: false,
            confirmVisibility: false,
            response: {},
            testSessionReports: [],
            selectedResults: [],
            page: 0,
            loading: false,
            pageSize: 10,
            totalPages: 10,
            allParams: {
                page: 0,
                size: 10,
            },
            allUsers: [],
            endDate: null,
            startDate: null,
            username: "",
            udId: "",
            privileges: [],
            id: "",
            sessionId: "",
            sessionName: "",
            visiumManageRedirectLink: '',
            visiumManageInformation: {},
            visiumManageActive: '',
            defectModalStatus: false,
            defectSessionId: '',
            singleUseToken: '',
            summary: '',
            description: '',
            defectsByTsId: [],
            client: null,
            subscriptionId: null
        };

        this.getResults = _.debounce(this.getResults, DEBOUNCE_WAIT.LONG);
    }

    onChangeSearchKeyword = (e, { value, name }) => {
        this.setState({ [name]: value });
        if (value === "") {
            let params = this.state.allParams;
            Object.keys(params).forEach((key) => {
                if (key === name) {
                    delete params[key];
                }
            });
            this.loadTestSessionReport();
        } else {
            if (value.length >= 3) {
                this.getResults(value, name);
            }
        }
    };

    onPaginationChange = (pageIndex,pageSize) => {
        this.setState(
            (prevState) => ({
                allParams: {
                    ...prevState.allParams,
                    page: pageIndex,
                    size: pageSize,
                },
            }),
            () => this.loadTestSessionReport()
        );
    };

    handleDropdown = (e, { value, name }) => {
        this.setState({ [name]: value });
        this.setState(
            (prevState) => ({
                allParams: {
                    ...prevState.allParams,
                    [name]: "eq:" + value,
                },
            }),
            () => this.loadTestSessionReport()
        );
    };

    getResults = (value, name) => {
        this.setState(
            (prevState) => ({
                allParams: {
                    ...prevState.allParams,
                    [name]: "like:" + value,
                },
            }),
            () => this.loadTestSessionReport()
        );
    };

    deleteReport = () => {
        //axios.delete(`/api/test-session/${this.state.appiumReportFields.id}`)
        deleteTestSession(this.state.appiumReportFields.id).then(() => {
                this.setState({
                    confirmVisibility: false,
                    appiumReportFields: {},
                });
                this.loadTestSessionReport();
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip,
                });
            })
            .catch((err) => {
                showError(
                    FAILED_ERROR_MESSAGE() +
                    this.props.t(err?.response?.data?.message ?? err.toString())
                );
            });
    };

    downloadFile = (file) => {
        let { appiumReportFields } = this.state;
        //let urlDownload = `/api/v1/test-sessions/${appiumReportFields.id}/download-file`;
        let fileParam = undefined;
        if (file !== undefined) {
            fileParam = `?file=${file}`;
        }
        //axios.get(urlDownload, { responseType: "blob" })
        downloadReportFile(appiumReportFields.id,fileParam).then((res) => {
                // const extension = file !== undefined ? file === 'video' ? '.avi' : '.log' : '.zip';
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `${file || `${appiumReportFields.id}.zip`}`
                );
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(err => {
                showError(
                  FAILED_ERROR_MESSAGE() +
                  this.props.t(err?.response?.data?.message ?? err.toString())
                );
              });
    };

    deleteFile = (file) => {
        let { appiumReportFields } = this.state;
        //axios.delete(`/api/test-session/${appiumReportFields.id}/delete-file?file=`+file)
        deleteReportFile(appiumReportFields.id,file).then((res) => {
                this.setState({ appiumReportFields: res.data });
                toast.success(SUCCESS_MESSAGE(), {
                    delay: 1000,
                    transition: Flip,
                });
                this.loadTestSessionReport();
            })
            .catch(err => {
                showError(
                  FAILED_ERROR_MESSAGE() +
                  this.props.t(err?.response?.data?.message ?? err.toString())
                );
              });
    };

    loadTestSessionReport = () => {
        this.setState({ loading: true });
        let params = this.state.allParams;
        params.type = "eq:AUTOMATION";

        Object.keys(params).forEach((key) => {
            if (typeof params[key] === "string") {
                if (
                    params[key].slice(-1) === ":" ||
                    params[key].includes("Invalid date")
                ) {
                    delete params[key];
                }
            }
        });
        params = stringify(params);
        //axios.get(this.state.testSessionReportURL + "?" + params)
        getTestSessionList(params).then((res) => {
                this.setState({
                    testSessionReports: res.data.content,
                    pageSize: res.data.size,
                    totalPages: res.data.totalPages,
                    page: res.data.pageable?.pageNumber,
                    loading: false,
                });
            })
            .catch(err => {
              showError(
                automationTestSessionMessages().NOT_FETCH_AUTOMATION_TEST_SESSION +
                    this.props.t(err?.response?.data?.message ?? err.toString())
              );
              this.setState({ loading: false });
          });
    };

    onChangeStartDate = (date) => {
        this.setState({ startDate: date }, () => {
            date = moment(date).format(DATETIME_FORMAT);
            this.setState(
                (prevState) => ({
                    allParams: {
                        ...prevState.allParams,
                        startDate: "gte:" + date,
                    },
                }),
                () => this.loadTestSessionReport()
            );
        });
    };

    onChangeEndDate = (date) => {
        this.setState({ endDate: date }, () => {
            date = moment(date).format(DATETIME_FORMAT);
            this.setState(
                (prevState) => ({
                    allParams: {
                        ...prevState.allParams,
                        endDate: "lte:" + date,
                    },
                }),
                () => this.loadTestSessionReport()
            );
        });
    };

    onSortChange = newSorted => {
        if (newSorted.length > 0) {
            const columnName = newSorted[0].id;
            const orderBy = newSorted[0].desc ? 'DESC' : 'ASC';
            this.setState(
                (prevState) => ({
                    allParams: {
                        ...prevState.allParams,
                        sort: `${columnName},${orderBy}`
                    }
                }),
                () => this.loadTestSessionReport()
            )
        }
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined && row[id] !== null
            ? String(row[id].toString().toLowerCase()).includes(
                  filter.value.toLowerCase()
              )
            : true;
    };

    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({ privileges });
        this.loadTestSessionReport();
        if (privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT)) {
            //TODO: use test session specific endpoint
            getUserSummaryList().then((res) => {
                let allUsers = res.data.sort(function compare(a, b) {
                    const bandA = a.fullName.toUpperCase();
                    const bandB = b.fullName.toUpperCase();

                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                    } else if (bandA < bandB) {
                        comparison = -1;
                    }
                    return comparison;
                });
                allUsers = allUsers.map((user) => {
                    return {
                        key: user.userName,
                        value: user.userName,
                        text:
                            user.fullName === null
                                ? user.userName
                                : user.fullName,
                    };
                });
                this.setState({ allUsers });
            })
            .catch((err) => {
                showError(
                    NOT_FETCH_USERS() +
                        err?.response?.data?.message ?? err.toString()
                );
            });

            //Getting VisiumManage Information 
            //Overall Automation Test Session Manangement Privilege required
            getVisiumManageIntegration()
            .then((res) => {
              this.setState({
                visiumManageActive: res?.data[0]?.active,
                visiumManageInformation: res?.data[0]
              });
            })
            .catch(err => {
                showError(`${this.props.t("Could not fetch Visium Manage integration information")} ${err?.response?.data?.message ?? err.toString()} `);
            }); 
        }
        axios
            .get("/api/devices")
            .then((res) => {
                let allDeviceIds = res.data.map((device) => {
                    return {
                        key: device.deviceId,
                        value: device.deviceId,
                        text: device.deviceId,
                        content: (
                            <Header
                                size="tiny"
                                icon={MOBILE_ICON}
                                content={`${device.brand} ${device.deviceModel}`}
                                subheader={device.deviceId}
                            />
                        ),
                    };
                });
                this.setState({ allDeviceIds });
            })
            .catch((err) => {
                showError(
                    NOT_FETCH_DEVICES()+
                        err?.response?.data?.message ?? err.toString()
                );
            });

            
    }

    handleDefectPopup = id => {
        const tsid = id;

        this.setState({defectModalStatus: true, defectSessionId: `${tsid}`});
      
        getSingleUseToken(tsid)
        .then(res=> {
          this.setState({singleUseToken: res.data})
        })
        .catch(err=>{
          showError(err);
        });
      
        getTestSessionInfo(tsid).then(res=>{
            const data = res.data;
            this.setState({summary: `New Defect with ${data.deviceBrand.toUpperCase()} ${data.deviceModel} @ ${moment(data.startDate)}`});
      this.setState({description: `${data.deviceBrand.toUpperCase()} ${data.deviceOs} (OS version: ${data.deviceOsVersion}) - ${data.appName}(${data.appVersion}) application used between ${moment(data.startDate)} ${moment(data.endDate)}`});
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + ': ' + this.props.t(err?.response?.data?.message ?? err.toString()));
          });
      
        if(validateUrl(this.state.visiumManageRedirectLink)){
            window.location.replace(`${this.state.visiumManageRedirectLink}`);
        }

        getIntegrationDefects(tsid)
          .then(res => {
              this.setState({defectsByTsId: res.data.content});
          }).catch(err => {
              showError(`${NOT_FETCH_DEFECTS()}: ${err?.response?.data}`);
          });

        if(this.props.wsClient){
          const id = uniqueId();
          this.setState({subscriptionId: id});
          this.props.wsClient.subscribe(`/topic/test-session-defects/${tsid}`, message =>{
            const msgObj = JSON.parse(message?.body);
            const defectFromWs = {
            'vmDefectId': msgObj.vmDefectId,
            'vmProjectId': msgObj.vmProjectId,
            'tsid': this.state.defectSessionId,
            'vmProjectName': msgObj.vmProjectName
            }
            
            this.setState({defectsByTsId: [...this.state.defectsByTsId, defectFromWs]})
            },{ 'AccessToken': localStorage.getItem('accessToken'), id})
        }
    }

    openDefectLink = () => {
        const encodedVisiumData = {
            'summary': this.state.summary,
            'description': this.state.description,
            'visiumManageInformation': this.state.visiumManageInformation
        }
        const encodedIntegrationData = Base64.encode(JSON.stringify(encodedVisiumData));

        let farmUrl;
        const { hostname, port, protocol, host } = window.location;
        if (host.includes('127.0.0.1:') || host.includes('localhost:') || process.env.NODE_ENV === 'development') {
            farmUrl = proxy;
        } else {
          farmUrl = `${protocol}//${hostname}:${port}`;
        }

        let defectUrl = `${this.state.visiumManageInformation.url}/3rd/defect`;
        defectUrl = `${defectUrl}?vftsid=${this.state.defectSessionId}&token=${this.state.singleUseToken.token}&data=${encodedIntegrationData}&url=${farmUrl}`;
        
        if(validateUrl(defectUrl)){
            window.open(defectUrl,'Integration Defect Creation',`height=${window.screen.availHeight}`,`width=${window.screen.availWidth}`);
        }
    }

    handleModalClose = () =>
        this.setState({ modalVisibility: false, appiumReportFields: {} });

    deleteSessionPrivilegeCheck = () => {
        return !(this.state.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.DELETE) ||
          this.state.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.DELETE_ANY) || 
          this.state.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT));
      }

    generateActionMenu = props => {
        return (
            <span>
                <Dropdown
                    icon={ELLIPSIS_HORIZONTAL_ICON}
                    button
                    className='icon'
                >
                    <Dropdown.Menu style={{ zIndex: 90 }}>
                        <Dropdown.Item
                            onClick={() => {
                                this.setState({
                                    modalVisibility: true,
                                    appiumReportFields: props.row.original
                                });
                            }}
                        >
                            <Icon name={INFO_ICON} />{DETAILS_LABEL()}
                        </Dropdown.Item>
                        {
                            <Dropdown.Item
                                disabled={this.deleteSessionPrivilegeCheck()}
                                onClick={() => {
                                    this.setState({
                                        confirmVisibility: true,
                                        appiumReportFields: props.row.original
                                    });
                                }}
                            >
                                <Icon name={X_ICON} color='red' />{DELETE_BUTTON()}
                            </Dropdown.Item>
                        }
                        {
                            this.state.visiumManageActive &&
                            (<Dropdown.Item
                                onClick={() => {
                                    this.handleDefectPopup(props.row.original.id);
                                }}
                            >
                                <Icon name={BUG_ICON} color='green' />{DEFECT_BUTTON()}
                            </Dropdown.Item>)
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </span>
        )
    }

    render() {
        let { appiumReportFields } = this.state;

        const  defectModalColumns = [
            {
                header: () => <div>{DEFECT_CODE()}</div>,
                accessorKey: 'vmDefectCode',
                id: 'vmDefectCode',
                cell: info => <span>{info.getValue()}</span>
            },
            {
                header: () => <div>{PROJECT_SUMMARY()}</div>,
                accessorKey: 'vmDefectSummary',
                id: 'vmDefectSummary',
                cell: info => <span>{info.getValue()}</span>
            },
            {
              header: () => <div>{DEFECT_URL_HEADER()}</div>,
              accessorKey: 'defectUrl',
              id: 'defectUrl',
              cell: info => {
                const defectLink = `${this.state?.visiumManageInformation?.url}/defects?projectId=${info.row?.original?.vmProjectId}&defectId=${info.row?.original?.vmDefectId}`;

                return(
                  <div>
                    {this.state.defectsByTsId ? <Button onClick={()=> window.open(defectLink)}>{GO_TO_DEFECT_BUTTON()}</Button> : <></>}
                  </div>
                )
              }
            }
          ]

        return (
            <div className='main-container'>
                <div className="main-left-container">
                    <h1>{TEST_SESSIONS_HEADER()}</h1>
                    <div className="main-left-content">
                        <Form>
                            <Form.Field>
                                <label>{REPORT_ID_HEADER()}</label>
                                <Input
                                    icon={SEARCH_ICON}
                                    name={"id"}
                                    placeholder={automationTestSessionMessages().SEARCH_REPORT_ID_PLACEHOLDER}
                                    value={this.state.id}
                                    onChange={this.onChangeSearchKeyword}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>{SESSION_ID_HEADER()}</label>
                                <Input
                                    icon={SEARCH_ICON}
                                    name={"sessionId"}
                                    placeholder={automationTestSessionMessages().SEARCH_SESSION_ID_PLACEHOLDER}
                                    value={this.state.sessionId}
                                    onChange={this.onChangeSearchKeyword}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>{SESSION_NAME_HEADER()}</label>
                                <Input
                                    icon={SEARCH_ICON}
                                    name="sessionName"
                                    placeholder={SEARCH_SESSION_NAME_PLACEHOLDER()}
                                    value={this.state.sessionName}
                                    onChange={this.onChangeSearchKeyword}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>{DEVICE_HEADER()}</label>
                                <Dropdown
                                    style={{ wordBreak: "break-all" }}
                                    fluid
                                    selection
                                    search
                                    clearable
                                    name={"udId"}
                                    placeholder={SEARCH_ID_PLACEHOLDER()}
                                    options={this.state.allDeviceIds}
                                    value={this.state.udId}
                                    onChange={this.handleDropdown}
                                />
                            </Form.Field>

                            {(this.state.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
                                this.state.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT)) && (
                                <Form.Field>
                                    <label>{USERS_HEADER()}</label>
                                    <Dropdown
                                        style={{ wordBreak: "break-all" }}
                                        fluid
                                        search
                                        selection
                                        clearable
                                        placeholder={SEARCH_USER_PLACEHOLDER()}
                                        name={"username"}
                                        options={this.state.allUsers}
                                        value={this.state.username}
                                        onChange={this.handleDropdown}
                                    />
                                </Form.Field>
                            )}
                            <Form.Field>
                                <label>{START_DATE_LABEL()}</label>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => {
                                        this.onChangeStartDate(date);
                                    }}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption={TIME_LABEL()}
                                    locale={this.props.i18n.language}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{END_DATE_LABEL()}</label>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={(date) => {
                                        this.onChangeEndDate(date);
                                    }}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    minDate={this.state.startDate}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption={TIME_LABEL()}
                                    locale={this.props.i18n.language}
                                />
                            </Form.Field>
                        </Form>
                        <Button
                            style={{ marginTop: 10 }}
                            fluid
                            onClick={() => {
                                this.setState(
                                    {
                                        startDate: null,
                                        endDate: null,
                                        udId: "",
                                        username: "",
                                        id: "",
                                        sessionId: "",
                                        allParams: {
                                            page: this.state.page,
                                            size: this.state
                                                .pageSize,
                                        },
                                    },
                                    () =>
                                        this.loadTestSessionReport()
                                );
                            }}
                        >
                            {RESET_TABLE_BUTTON()}
                        </Button>
                    </div>
                </div>
                <div id='test-sessions-container' className='main-right-container'>
                    <div className='main-right-header'>
                        <h2>{AUTOMATION_TEST_SESSION_HEADER()}</h2>
                    </div>
                    <ReactTableV8
                        //Minimum Requirements for the table
                        data={this.state.testSessionReports}
                        columns={this.columns}
                        loadingProp={this.state.loading}

                        //Server-side sorting
                        onSortedChangeProp={newSorted => this.onSortChange(newSorted)}
                        manualSortingProp={true}

                        //BELOW: Backend-side controlled pagination parameters---
                        pageIndexProp={this.state.page}
                        pageSizeProp={this.state.pageSize}
                        totalPagesProp={this.state.totalPages}
                        onPaginationChangeProp={this.onPaginationChange}
                        manualPaginationProp={true}
                    />
                </div>

                <TestSessionDetailsModal
                    privileges={this.state.privileges}
                    handleModalClose={this.handleModalClose}
                    testSessionFields={appiumReportFields}
                    modalVisibility={this.state.modalVisibility}
                    deleteFile={this.deleteFile}
                    downloadFile={this.downloadFile}
                />
                <Confirm
                    content={automationTestSessionMessages().DELETE_CONFIRM}
                    open={this.state.confirmVisibility}
                    size="mini"
                    onCancel={() => {
                        this.setState({
                            confirmVisibility: false,
                            appiumReportFields: {},
                        });
                    }}
                    onConfirm={this.deleteReport}
                    confirmButton={CONFIRM_BUTTON()}
                    cancelButton={CANCEL_BUTTON()}
                />

                <div className='modal-defect'>
                  <Modal
                    closeIcon
                    open={this.state.defectModalStatus}
                    onClose={()=> {
                        if(this.props.wsClient){
                            this.props.wsClient.unsubscribe(this.state.subscriptionId);
                        }
                        this.setState({defectModalStatus: false, defectsByTsId: []})
                    }}
                    onOpen={() => {this.setState({defectModalStatus: true})}}
                  >
                    <Header>
                    <div className='defect-inspection-headerzone'>
                        <Header icon={BUG_ICON} content={DEFECT_INSPECTION_HEADER()}/>
                        <Button onClick={this.openDefectLink} className='float-right'>{OPEN_NEW_DEFECT_BUTTON()}</Button>
                    </div>
                    </Header>
                    <Modal.Content>
                      <Header style={{textAlign: 'center'}}>{PREVIOUSLY_OPENED_DEFECTS_HEADER()}</Header>
                            <ReactTableV8
                                columns={defectModalColumns}
                                data={this.state.defectsByTsId}
                            />
                    </Modal.Content>
                  </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      wsClient: state.stompClient.client
    };
  };

export default withTranslation()(connect(mapStateToProps)(withRouter(AutomationTestSession)));