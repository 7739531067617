import React, {Component} from 'react';
import {Button, Form, Header, Label, Modal, Segment} from "semantic-ui-react";
import axios from "axios";
import {toast} from "react-toastify";
import {showError} from "../utils/ToastHelpers";
import {WINDOW_RELOAD_TIMEOUT} from '../utils/Constants';
import {FAILED_ERROR_MESSAGE, licenseMessages, UPLOAD_BUTTON } from '../utils/UIMessages';
import { EXCLAMATION_ICON } from '../utils/UiIcons';
class LicenseModal extends Component {

    state = {
        uuid: 'Unknown',
        licenseFile: undefined
    }

    componentDidUpdate(prevProps) {
        if (prevProps.licenseModalVisibility === false && this.props.licenseModalVisibility === true) {
            axios.get('/api/license/uuid', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(res => {
                this.setState({uuid: res.data})
            })
        }
        if (prevProps.licenseModalVisibility === true && this.props.licenseModalVisibility === false) {
            this.setState({licenseFile:undefined})
        }
    }

    onChangeLicenseInput = (e) => {
        const file = e.target.files[0];
        let licenseFile = new FormData();
        licenseFile.append('file', file);
        this.setState({licenseFile});
    }

    onSubmitLicense = () => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        };
        axios.post('/api/license', this.state.licenseFile, config).then(() => {
            toast.success(licenseMessages().SUCCESSFUL)
            setTimeout(() => {
                window.location.reload();
            }, WINDOW_RELOAD_TIMEOUT.EXTRA)
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }

    render() {
        return (
            <div>
                <Modal open={this.props.licenseModalVisibility} size='small' closeIcon
                       onClose={this.props.onClose}>
                    <Header icon={EXCLAMATION_ICON} content={licenseMessages().HEADER} color='red'/>
                    <Modal.Content style={{textAlign: 'center'}}>
                        <Segment>
                            <Header size='medium'>{licenseMessages().UPLOAD_LICENSE_HEADER}</Header>
                        </Segment>
                        <Segment>
                            <Header size='small'>{licenseMessages().DEVICE_ID_HEADER}</Header>
                            <Label circular size='large'>{this.state.uuid}</Label>
                            <Form style={{marginTop: 20}}>
                                <Form.Field>
                                    <Header size='small'>{licenseMessages().NEW_LICENSE_HEADER}</Header>
                                    <input type={'file'} style={{width: 'min-content'}} accept={'.lic'}
                                           onChange={this.onChangeLicenseInput}/>
                                </Form.Field>
                                <Button onClick={this.onSubmitLicense}
                                        disabled={!this.state.licenseFile}>{UPLOAD_BUTTON()}</Button>
                            </Form>
                        </Segment>

                    </Modal.Content>
                    <Modal.Actions>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default LicenseModal;