// Try to get data from the cache, but fall back to fetching it live.
export async function getDataForCache(url, cacheVersion, name) {

    const cacheName = `${name}-${cacheVersion}`;
    let cachedData = await getCachedData(cacheName, url);

    if (cachedData) {
        //Retrieved cached data
        return cachedData;
    }
    //Fetching fresh data
    const accessToken = localStorage.getItem('accessToken');
    const options = { headers: new Headers({ 'Authorization': `Bearer ${accessToken}` }) }

    const cacheStorage = await caches.open(cacheName);
    await cacheStorage.add(new Request(url, options));
    cachedData = await getCachedData(cacheName, url);

    return cachedData;
}

// Get data from the cache.
async function getCachedData(cacheName, url) {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    return await cachedResponse.json();
}

// Delete selected cache to respect user's disk space.
export async function deleteOldCaches(cacheName) {
    const keys = await caches.keys();
    let selectedKey = keys.find((el) => el === cacheName)
    await caches.delete(selectedKey);
}
