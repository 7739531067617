import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import {
    getAllUsersInGroup,
    getReservationUsersInGroup,
    addReservationUsersToGroup,
    deleteReservationUserInGroup
} from '../../../api/apiCalls'
import { Modal,Header, Grid, Table, HeaderContent, Button } from 'semantic-ui-react';
import {showError,showSuccess} from '../../../utils/ToastHelpers'
import { ADD_BUTTON, DELETE_BUTTON, FAILED_ERROR_MESSAGE, groupUsersReservationMessages, NOT_FETCH_GROUP_USERS, SUCCESS_MESSAGE, USER_GROUP_PROPERTIES_HEADER, USER_HEADER } from '../../../utils/UIMessages';
import { CHEVRON_LEFT_ICON, CHEVRON_RIGHT_ICON } from '../../../utils/UiIcons';


const GroupUsersReservationModal = (props) => {

    const [userList,setUserList] = useState([]);
    const [selectedUserList,setSelectedUserList]= useState([]);

    useEffect(()=>{
        if(props.groupUsersReservationModalVisibility){
            getResources();
        }
    },[props.groupUsersReservationModalVisibility])


    const getResources = async() => {
        try {
            const usersInGroup = await getAllUsersInGroup(props.selectedGroup?.id);
            setUserList(usersInGroup.data);
        } catch (error) {
            showError(NOT_FETCH_GROUP_USERS()+ +error?.response?.data?.message ?? error.toString())
        }

        try {
            const reservationUsers = await getReservationUsersInGroup(props.selectedGroup?.id);
            setSelectedUserList(reservationUsers.data);
        } catch (error) {
            showError(groupUsersReservationMessages().NOT_FETCH_RESERVATION_USERS_ERROR_MESSAGE + +error?.response?.data?.message ?? error.toString())
        }
    }

    const onClickAddUser = async(id) => {
        const data = [id]
        const userFormData = new FormData();
        userFormData.append('userIds', data)
        try{
           await addReservationUsersToGroup(props.selectedGroup?.id,userFormData);
           getResources();
        }catch(error){
            showError(FAILED_ERROR_MESSAGE() + error?.response?.data?.message ?? error.toString())
        }
    }

    const onClickDeleteReservationUser = async(userId) =>{
        try{
            await deleteReservationUserInGroup(props.selectedGroup?.id,userId);
            getResources()
            showSuccess(SUCCESS_MESSAGE());
        }catch(error){
            showError(FAILED_ERROR_MESSAGE() +error?.response?.data?.message ?? error.toString())
        }
    }

    return (
        <div>
            <Modal
                open={props.groupUsersReservationModalVisibility}
                onClose={props.onClose}
                closeIcon
                closeOnDimmerClick={true}
            >
                <Header style={{textAlign: 'center'}}>{USER_GROUP_PROPERTIES_HEADER()}</Header>
                <Modal.Content>
                    <div>
                        <Grid columns={2} celled={"internally"} centered>
                            <Grid.Column>
                                <Grid.Row>
                                    {!_.isEmpty(userList) && <>
                                    <Table basic='very' celled compact>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{USER_HEADER()}</Table.HeaderCell>
                                                <Table.HeaderCell>{ADD_BUTTON()}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {_.orderBy(userList,['fullName'],['asc']).map((user,index)=>{
                                            return(
                                                <Table.Row 
                                                    key={index}
                                                     style={! _.isEmpty(selectedUserList) ?
                                                        selectedUserList.some(value => {return value.id === user.id}) ? {display: "none"} : {display: ""}
                                                        :{display: ""}
                                                    }
                                                        >
                                                    <Table.Cell>
                                                        <Header as='h4'>
                                                            <HeaderContent>
                                                                {user.fullName}
                                                            </HeaderContent>
                                                        </Header>
                                                    </Table.Cell>
                                                    <Table.Cell width={'3'}>
                                                        <Button
                                                            icon={CHEVRON_RIGHT_ICON}
                                                            primary
                                                            onClick={()=>onClickAddUser(user.id)}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                        }
                                        </Table.Body>
                                    </Table></>
                                    }
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                <div style={{height:'61vh', overflowY:'auto',marginTop:7}}>
                                    {! _.isEmpty(selectedUserList) && 
                                    <Table basic='very' celled compact>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{DELETE_BUTTON()}</Table.HeaderCell>
                                                <Table.HeaderCell>{USER_HEADER()}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {_.orderBy(selectedUserList,['fullName'],['asc']).map((user)=>{
                                                return(
                                                    <Table.Row key={user.id}>
                                                        <Table.Cell width={3}>
                                                            <Button
                                                                color='red'
                                                                icon={CHEVRON_LEFT_ICON}
                                                                onClick={() => onClickDeleteReservationUser(user.id)}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Header as='h4'>
                                                                <Header.Content>
                                                                {_.capitalize(user.fullName)}
                                                                </Header.Content>
                                                            </Header>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                            
                                            }
                                        </Table.Body>
                                    </Table>
                                    }
                                </div>

                                </Grid.Row>
                            </Grid.Column>
                        </Grid>
                    </div>       
                </Modal.Content>

            </Modal>
        </div>
    )
}

export default GroupUsersReservationModal