import React from 'react'
import { Button, Input } from 'semantic-ui-react'
import { PrivilegeConstants } from '../utils/PrivilegeConstants'
import { SEARCH_ICON, PLUS_ICON } from '../utils/UiIcons';
import { SEARCH_PLACEHOLDER, TOTAL_NUMBER_OF_APPLICATIONS_LABEL, UPLOAD_NEW_APP_LABEL } from '../utils/UIMessages';

const LibraryAppUpload = (props) => {
    return (
        <div>
            <Input
                onChange={props.handleAppSearch}
                value={props.searchKeyword}
                icon={SEARCH_ICON}
                fluid
                placeholder={SEARCH_PLACEHOLDER()}
            />
            {props.privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.UPLOAD_TO_LIB) && (
                <Button
                    style={{ marginTop: 20 }}
                    icon={PLUS_ICON}
                    fluid
                    onClick={props.handleOpenAppUploadModal}
                    content={UPLOAD_NEW_APP_LABEL()}
                />
            )}
            <div className='count-total-number'>{TOTAL_NUMBER_OF_APPLICATIONS_LABEL()}: {props.totalNumOfApps}</div>
        </div>
    )
}

export default LibraryAppUpload
