import React from 'react'
import ContentLoader from 'react-content-loader'

const measures = {
  rx: '5',
  ry: '5',
  width: '220',
  height: '10'
}

const ChatLoader = props => {
  return (
    <ContentLoader viewBox='0 0 400 320' height={320} width={400} {...props}>
      <rect x='0' y='12' {...measures} />
      <rect x='0' y='29' {...measures} />
      <rect x='179' y='76' {...measures} />
      <rect x='179' y='58' {...measures} />
      <rect x='0' y='104' {...measures} />
      <rect x='0' y='122' {...measures} />
      <rect x='179' y='150' {...measures} />
      <rect x='179' y='168' {...measures} />
      <rect x='0' y='196' {...measures} />
      <rect x='0' y='214' {...measures} />
      <rect x='179' y='242' {...measures} />
      <rect x='179' y='270' {...measures} />
    </ContentLoader>
  )
}

export default ChatLoader
