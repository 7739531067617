import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Header, Segment, Portal ,Icon, Dropdown} from 'semantic-ui-react'
import ChatInput from './ChatInput'
import ChatFeeds from './ChatFeeds'
import ContentLoader from './ChatLoader'
import {getDeviceChatMessages} from '../../api/apiCalls'
import {showError} from '../../utils/ToastHelpers'
import { useTranslation } from 'react-i18next'




const ChatPortal = ({open, onClose,type, user, deviceList=[]}) => {

    const [messages,setMessages] = useState([]);
    const [message,setMessage] = useState('');
    const [subId,setSubId] = useState();
    const [messagesLoader,setMessagesLoader] = useState(false);
    const [selectedDevice,setSelectedDevice] = useState({
        key : deviceList[0]?.id,
        value : deviceList[0]?.id,
        text : deviceList[0]?.udId
    })
    const [deviceListOptions,setDeviceListOptions] = useState([]);
    const selectedGroup = parseInt(localStorage.getItem('activeGroup'));
    const {t} = useTranslation();
    const client = useSelector(state => state.stompClient?.client);


    useEffect(() => {

        const fetchData = async() =>{
            try {
                let id = type === 'device' ? selectedDevice?.value : selectedGroup
                setSubId(id);
                let messageList = await getDeviceChatMessages(id);  
                setMessages([...messageList.data])
              } catch (error) {
                showError(t(`Can not fetch chat messages from server. Error ${error?.response?.data?.message ?? error.toString()}`))
              }
            setMessagesLoader(true);
        }

        fetchData();

        setDeviceListOptions(
            deviceList?.map(device=>({
            key : device.id,
            value : device.id,
            text : device.udId
        })))

    },[selectedDevice])


    useEffect(()=>{
        client?.subscribe(`/chat/message`,msg=>{
            const msgObj = JSON.parse(msg?.body)
            if((msgObj.chatType === 'USER' && msgObj.subId === selectedDevice?.value) || (msgObj.chatType === 'GROUP' && msgObj.subId === selectedGroup)){
                setMessages(prevMessages => [...prevMessages, msgObj])
            }
        },{ 'AccessToken': localStorage.getItem('accessToken'),'id' : selectedDevice?.value})
    },[selectedDevice])
    

    const sendMessage = async event => {
        event.preventDefault();
        if(message){
            const outgoingMessage = {
                message,
                subId,
                pubId : user.id,
                pubUserName : user.userName
            }
            await client.send(`/app/ws/chat/${type}`, {}, JSON.stringify(outgoingMessage));
            setMessage('');
        }
    }

    const handleOnChangeDevice = (e,data) => {
        client?.unsubscribe(selectedDevice?.value)
        setSelectedDevice(deviceListOptions.find((device)=>device?.value === data.value))
    }

    return (
        <div>
            <Portal onClose={onClose} open={open}>
                <div>
                    <Segment className='chat-portal-segment' >
                        <Header >
                            <div className='chat-bot-header'>
                                
                                <div className='chat-bot-header-dropdown-div'>   
                                    {type === 'device' && deviceListOptions?.length > 1 ?            
                                        <Dropdown
                                            className='chat-app-device-id-dropdown'
                                            onChange={(e,data)=>handleOnChangeDevice(e,data)}
                                            options={deviceListOptions}
                                            value={selectedDevice?.value}
                                        />:
                                        <div></div>
                                    }
                                </div>

                                <div className='chat-bot-header-icons-div'>
                                    <Icon name='angle down' onClick={onClose} className='chat-bot-header-close' size='large'/>
                                </div>

                            </div>
                            <hr />
                        </Header>                       
                        { messagesLoader ? <ChatFeeds messages={messages} currentUserId={user?.id}/> : <ContentLoader/>}
                        <ChatInput sendMessage={sendMessage} setMessage={setMessage} message={message}/>
                    </Segment>
                </div>
            </Portal>
        </div>
    )
}

export default ChatPortal;
