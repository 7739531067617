import React, {Component} from 'react';
import {Grid, Header, Segment, Icon, List} from "semantic-ui-react";
import DateRangeDropdown from "../../../../components/DateRangeDropdown";
import {ResponsiveBar} from "@nivo/bar";
import _ from "lodash";
import axios from "axios";
import {dataRefresher, secondsToDhms} from '../../../../utils/Methods';
import {withTranslation} from 'react-i18next';
import {showError} from "../../../../utils/ToastHelpers";
import ReactTableV8 from '../../../../components/ReactTableV8/ReactTableV8';
import { DEVICE_STATUS_MULTIPLIER, ONE_HOUR_IN_SECONDS, ONE_MINUTE_IN_SECONDS } from '../../../../utils/Constants';
import { AUTOMATION_LABEL, chartsTopUsersMessages, DEVELOPMENT_LABEL, FAILED_ERROR_MESSAGE, HOURS_LABEL, HOUR_LABEL, MANUAL_LABEL, MINUTES_LABEL, MINUTE_LABEL, RESERVED_LABEL, TOTAL_LABEL, USAGE_LABEL, USERNAME_HEADER, USER_HEADER, USER_ROLES_LABEL } from '../../../../utils/UIMessages';
import { USER_ICON } from '../../../../utils/UiIcons';

class ChartsTu extends Component {

    timeTableColumns = () => [
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'User',
            id: 'User',
            cell: info => (<span className='number'> <Icon name={USER_ICON} /> {info.getValue()}</span>)
        },
        {
            header: () => <div>{USER_ROLES_LABEL()}</div>,
            accessorKey: 'userRoles',
            id: 'userRoles',
            cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue()?.map((role, i) => {
                return (<List.Item key={i} as='li' value='*'>
                    {role}
                </List.Item>)
            })}</List>)
        },
        {
            header: () => <div>{RESERVED_LABEL()}</div>,
            accessorKey: RESERVED_LABEL(),
            id: 'Reserved',
            cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
        },
        {
            header: () => <div>{TOTAL_LABEL()}</div>,
            accessorKey: TOTAL_LABEL(),
            id: 'Total',
            cell: info => (<span className='number'>{secondsToDhms(info.getValue())}</span>)
        }
    ];

    ratioTableColumns = () => [
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'User',
            id: 'User',
            cell: info => (<span className='number'> <Icon name={USER_ICON} /> {info.getValue()}</span>)
        },
        {
            header: () => <div>{USER_ROLES_LABEL()}</div>,
            accessorKey: 'userRoles',
            id: 'userRoles',
            cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue()?.map((role, i) => {
                return (<List.Item key={i} as='li' value='*'>
                    {role}
                </List.Item>)
            })}</List>)
        },
        {
            header: () => <div>{RESERVED_LABEL()}</div>,
            accessorFn: d => ((d[RESERVED_LABEL()] / d[TOTAL_LABEL()]) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
            id: 'Reserved',
            cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
        }
    ];

    

    ratioTableColumnsUsageType2 = () => [
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'User',
            id: 'User',
            cell: info => (<span className='number'> <Icon name={USER_ICON} /> {info.getValue()}</span>)
        },
        {
            header: () => <div>{USER_ROLES_LABEL()}</div>,
            accessorKey: 'userRoles',
            id: 'userRoles',
            cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue()?.map((role, i) => {
                return (<List.Item key={i} as='li' value='*'>
                    {role}
                </List.Item>)
            })}</List>)
        },
        {
            header: () => <div>{MANUAL_LABEL()}</div>,
            accessorFn: d => ((d[MANUAL_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
            id: 'Manual',
            cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
        },
        {
            header: () => <div>{AUTOMATION_LABEL()}</div>,
            accessorFn: d => ((d[AUTOMATION_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
            id: 'Automation',
            cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
        },
        {
            header: () => <div>{DEVELOPMENT_LABEL()}</div>,
            accessorFn: d => ((d[DEVELOPMENT_LABEL()] / (d[MANUAL_LABEL()] + d[AUTOMATION_LABEL()] + d[DEVELOPMENT_LABEL()])) * DEVICE_STATUS_MULTIPLIER).toFixed(0),
            id: 'Development',
            cell: info => (<span className='number'>{isNaN(info.getValue()) ? <>0%</> : <>{info.getValue()}%</>}</span>)
        }
    ];

    state = {
        componentDidUpdateCheck:false,
        dataForBarChart: [],
        dataForBarChartClone: [],
        //pageSize1: 10,
        //pageSize2: 10,
        startDate: new Date(),
        endDate: new Date(),
        size: 10,
        // sorted1: [],
        // sorted2: [],
        excludedUserList: [],
        excludeOptions: [],
        loading: false,
        language: localStorage.getItem('lang') || 'en'
    }

    convertDataForBarChart = async (type, params = "", first) => {
        this.setState({ loading: true });
        try {
            const data = (await axios.get("/api/reports/user-device-usage" + params)).data
            let dataForBarChart = [];
            if (type === "usageType") {
                for (let i = 0; i < data?.users?.length; i++) {
                    dataForBarChart.push({
                        User: data?.users[i]?.fullName,
                        userRoles: data?.users[i]?.userRoles,
                        [MANUAL_LABEL()]: Number(data?.manual?.total[i]),
                        [AUTOMATION_LABEL()]: Number(data?.automation?.total[i]),
                        [DEVELOPMENT_LABEL()]: Number(data?.development?.total[i]),
                    })
                }
            } else {
                for (let i = 0; i < data?.users?.length; i++) {
                    dataForBarChart.push({
                        User: data?.users[i]?.fullName,
                        userRoles: data?.users[i]?.userRoles,
                        [TOTAL_LABEL()]: Number(data[type]?.total[i]),
                        [RESERVED_LABEL()]: Number(data[type]?.reserved[i]),
                    })
                }
            }
            let [startDate, endDate, size, excludeOptions] = [new Date(data?.startDate), new Date(data?.endDate), data?.users?.length, []]
            excludeOptions = data?.users?.map(el => {
                return ({
                    key: el.userName,
                    value: el.fullName,
                    text: el.fullName,
                })
            })
            this.setState({
                dataForBarChart,
                startDate,
                endDate,
                size,
                excludeOptions,
                dataForBarChartClone: dataForBarChart
            }, () => {
                let {dataForBarChartClone} = this.state
                if (first) {
                    let adminData = dataForBarChartClone.find(el => el.User === "Administrator")
                    this.setState({excludedUserList: [adminData?.User]}, ()=>{
                        let dataForBarChart = dataForBarChartClone.filter(el => !(this.state.excludedUserList.includes(el.User)))
                        this.setState({dataForBarChart})
                    })
                }
                if (this.state.excludedUserList.length) {
                    let dataForBarChart = dataForBarChartClone.filter(el => !(this.state.excludedUserList.includes(el.User)))
                    this.setState({dataForBarChart})
                }
            })
        } catch (err) {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString())
        } finally {
          this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.convertDataForBarChart(this.props.type, "", true)
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.setState({
                componentDidUpdateCheck: !this.state.componentDidUpdateCheck
            },
                () => { this.convertDataForBarChart(this.props.type, '', false) })
        }
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.convertDataForBarChart(this.props.type, '', false))
        )
    }

    onChangeDateComponent = link => {
        const params = "?" + link
        return this.convertDataForBarChart(this.props.type, params, false)
    }

    excludeOnChange = (e, {value}) => {
        this.setState({excludedUserList: value}, () => {
            let {dataForBarChartClone} = this.state
            let dataForBarChart = dataForBarChartClone.filter(el => !(value.includes(el.User)))
            this.setState({dataForBarChart})
        })
    }

    barHeightFixer = () => {
        if (this.props.type !== "usageType") {
            const maxValue = Math.max(...this.state.dataForBarChart.map(o => {return o[TOTAL_LABEL()]}))
            return maxValue / 1.15
        } else {
            const maxValue = Math.max(...this.state.dataForBarChart.map(o => {return o[MANUAL_LABEL()]}))
            return maxValue / 1.15
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <DateRangeDropdown
                            isOrderByVisibility={true}
                            isSizeVisibility={true}
                            sizeValue={this.state.size}
                            type={this.props.type}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            callback={(link) => {
                                this.onChangeDateComponent(link)
                            }}
                            isUserRolesVisibility={true}
                            excludeUsers={true}
                            excludedUserList={this.state.excludedUserList}
                            excludeOptions={this.state.excludeOptions}
                            excludeOnChange={this.excludeOnChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={1}>
                    <Grid.Column>
                        <Header textAlign={"center"}>
                        {chartsTopUsersMessages(this.props.type === 'usageType' ? 'All' : _.startCase(this.props.type)).TOP_USERS_BY_HEADER}
                            {/* Top Users by {this.props.type === "usageType" ? "All Usage" : <>{_.startCase(this.props.type)} Usage</>} */}
                        </Header>
                        <Segment placeholder style={{height: 450}} loading={this.state.loading}>
                            <ResponsiveBar
                                data={this.state.dataForBarChart}
                                keys={this.props.type === "usageType" ? [MANUAL_LABEL(), AUTOMATION_LABEL(),DEVELOPMENT_LABEL()]: [TOTAL_LABEL(), RESERVED_LABEL()]}
                                indexBy="User"
                                enableGridY={true}
                                // gridYValues={[0, 900, 1800, 3600, 7200, 14400, 28800, 57600, 115200, 230400]}
                                margin={{top: 30, right: 30, bottom: 120, left: 150}}
                                groupMode="grouped"
                                label={() => null}
                                valueScale={{type: 'linear'}}
                                maxValue={this.barHeightFixer()}
                                indexScale={{type: 'band', round: true}}
                                colors={{scheme: 'pastel2'}}
                                borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                                axisTop={null}
                                axisRight={null}
                                tooltip={(el) => {
                                    return (
                                        <>
                                            {el.id} - {el.indexValue}: {secondsToDhms(el.value)}
                                        </>
                                    )
                                }}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: -40,
                                    legend: USER_HEADER(),
                                    legendPosition: 'middle',
                                    legendOffset: 110
                                }}
                                axisLeft={
                                    {
                                        // tickValues: [0, 900, 1800, 3600, 7200, 14400, 28800, 57600, 115200, 230400],
                                        format: (tick) => {
                                            tick = Number(tick);
                                            var h = Math.floor(tick / ONE_HOUR_IN_SECONDS);
                                            var m = Math.floor(tick % ONE_HOUR_IN_SECONDS / ONE_MINUTE_IN_SECONDS);
                                            var hDisplay = h > 0 ? h + (h === 1 ? ` ${HOUR_LABEL()} `  : ` ${HOURS_LABEL()} `) : "";
                                            var mDisplay = m > 0 ? m + (m === 1 ? ` ${MINUTE_LABEL()}, ` : ` ${MINUTES_LABEL()} `) : "";
                                            return hDisplay + mDisplay;
                                        },
                                        tickSize: 5,
                                        tickPadding: 10,
                                        tickRotation: 0,
                                        legend: USAGE_LABEL(),
                                        legendPosition: 'middle',
                                        legendOffset: -145,
                                    }
                                    // null
                                }
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                // labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: "bottom-right",
                                        direction: "row",
                                        justify: false,
                                        translateX: 40,
                                        translateY: 110,
                                        itemsSpacing: -30,
                                        itemDirection: "left-to-right",
                                        itemWidth: 130,
                                        itemHeight: 0,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: "circle",
                                        // symbolBorderColor: "rgba(0, 0, 0, .5)",
                                        effects: [
                                            {
                                                on: "hover",
                                                style: {
                                                    // itemBackground: "rgba(0, 0, 0, .03)",
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]

                                    }
                                ]}
                                animate={false}
                                motionStiffness={90}
                                motionDamping={15}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Segment placeholder style={{ minHeight: '22rem' }}>
                            <ReactTableV8
                                data={this.state.dataForBarChart}
                                columns={this.props.type === 'usageType' ? this.ratioTableColumnsUsageType2() : this.timeTableColumns()}
                                onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                loadingProp={this.state.loading}
                            />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment placeholder style={{ minHeight: '22rem' }}>
                            <ReactTableV8
                                data={this.state.dataForBarChart}
                                columns={this.props.type === 'usageType' ? this.ratioTableColumnsUsageType2() : this.ratioTableColumns()}
                                onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                loadingProp={this.state.loading}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withTranslation()(ChartsTu);


