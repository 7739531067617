import React from 'react'
import { Button, Header, Modal, Segment, Icon } from "semantic-ui-react";
import { busyDevicePubModal } from '../utils/UIMessages';
import { CHECKMARK_ICON, CANCEL_ICON } from '../utils/UiIcons';
import { useSelector } from 'react-redux'

const BusyDevicePubModal = ({visibility, closeOnDimmerClick, closeOnEscape, onClose, headerIcon, header, content, args}) => {

    const client = useSelector(state => state.stompClient.client)


    const sendResponse = async answer => {
      const outgoingMessage = {
        udId:args[1],
        userId:args[2]
      }
      await client?.send(`/app/busy-device/${answer}`, {}, JSON.stringify(outgoingMessage));
      onClose();
    }

    return (
        <div>
            <Modal 
                open={visibility} 
                size='small' 
                closeOnDimmerClick={closeOnDimmerClick} 
                className='z-index-999'
                closeOnEscape={closeOnEscape}
                onClose={onClose} 
                dimmer='blurring'
            >
                <Header icon={headerIcon} content={header} color='red' />
                <Modal.Content style={{ textAlign: 'center' }}>
                    <Segment>
                        <Header size='medium'>{content}</Header>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={()=>sendResponse('allow')} icon> <Icon name={CHECKMARK_ICON} /> {busyDevicePubModal().ALLOW_BUTTON} </Button>
                    <Button color='red' onClick={()=>sendResponse('reject')} icon><Icon name={CANCEL_ICON} /> {busyDevicePubModal().REJECT_BUTTON} </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default BusyDevicePubModal