import React,{useEffect} from 'react'
import { Button, Header, Modal, ModalActions, Segment} from "semantic-ui-react";
import { ARROW_LEFT_ICON } from '../utils/UiIcons';
import { Link } from 'react-router-dom';

const BusyDeviceSubModal = ({ visibility, headerIcon, closeOnDimmerClick, closeOnEscape, onClose, header, content,
    client, onReject, onConfirm, userId, deviceId, setClientSubscription }) => {
    
    useEffect(() => {
        const subscription = client?.subscribe('/busy-device/response', message => {
            const msg = JSON.parse(message?.body);
            msg?.message === 'reject' ? onReject(msg) : onConfirm(msg);
        }, { token: deviceId + ':' + userId, id: deviceId + ':' + userId });
        setClientSubscription(subscription);
    }, [])

    return (
        <div>
            <Modal 
                open={visibility} 
                size='small' 
                closeOnDimmerClick={closeOnDimmerClick} 
                className='z-index-999'
                closeOnEscape={closeOnEscape}
                onClose={onClose} 
                dimmer='blurring'
            >
                <Header icon={headerIcon} content={header} color='red' />
                <Modal.Content className='text-align-center'>
                    <Segment>
                        <Header size='medium'>{content}</Header>
                    </Segment>
                </Modal.Content>
                <ModalActions>
                    <div style={{textAlign:"center"}}>
                        <Button content={"Return to Device List"} as={Link} to="/device-list" color='red' icon={ARROW_LEFT_ICON} labelPosition='left' />
                    </div>
                </ModalActions>
            </Modal>
        </div>
    );
}

export default BusyDeviceSubModal;
