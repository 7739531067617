import axios from "axios";
import {APPIUM_REQUEST_PREFIX} from "./Constants"

/**
 * This script contains Appium requests used in Inspector.
 * 
 * Please visit the following website for detailed information about requests:
 * http://appium.io/docs/en/about-appium/getting-started/
 */


// fetch screen image 
export const fetchScreenImageViaAppium = (sessionId)=> {
   console.log('Appium screen fetch request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/screenshot`)
}

// swipe by coordinates
export const swipe = (sessionId, x1, y1, x2, y2) => {
   console.log('Appium swipe request is taken.')
   let actions = [
      {action: "press", options: {x: x1, y: y1}}, 
      {action: "wait",  options: {ms: 500}},
      {action: "moveTo", options: {x: x2, y: y2}},
      {action: "release", options: {}},  
   ]
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/touch/perform`, {
      actions
   })
}

// get element properties by element ID
export const getElement = (sessionId, id) => {
   console.log('Appium get element request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/element/${id}`) 
}

// get element location by element ID
export const getElementLocation = (sessionId, elementID) => {
   console.log('Appium get element location request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/element/${elementID}/location`)
}

export const getElementSize = (sessionId, elementID) => {
   console.log('Appium get element size request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/element/${elementID}/size`)
}

// send keys to element
export const sendKeysToElement = (sessionId, elementID, keyToSend) => {
   console.log('Appium send keys to element request is taken.')
   let value = keyToSend
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/element/${elementID}/value`, {
      value
  })
}


// Session Actions

// session capabilities
export const getSessionCapabilities = (sessionId) => { 
   console.log('Appium getSessionCapabilities request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}`)
}

// Timeouts
export const setTimeouts = (sessionId, type, ms) => {  // 400
   console.log('Appium setTimeouts request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/timeouts`, {
      type, ms
   })
}

export const setImplicitTimeout = (sessionId, ms) => { 
   console.log('Appium setImplicitTimeout request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/timeouts/implicit_wait`, {
      ms
   })
}

export const setScriptTimeout = (sessionId, ms) => { // 405 Method not implemented
   console.log('Appium setScriptTimeout request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/timeouts/async_script`, {
      ms
   })
}

// Orientation
export const getOrientation = (sessionId) => { 
   console.log('Appium getOrientation request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/orientation`)
}

export const setOrientation = (sessionId, orientation) => { 
   console.log('Appium setOrientation request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/orientation`, {
      orientation
   })
}

// GeoLocation
export const setGeoLocation = (sessionId, latitude, longitude, altitude) => { 
   console.log('Appium setGeoLocation request is taken.')
   let location = {latitude, longitude, altitude}
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/location`, {
      location
   })
}

export const getGeoLocation = (sessionId) => { 
   console.log('Appium getGeoLocation request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/location`)
}


// Logs
export const getLogs = (sessionId, type) => {  
   console.log('Appium getLogs request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/log`, {
      type
   })
}

export const getLogTypes = (sessionId) => {  
   console.log('Appium getLogTypes request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/log/types`)
}

// Settings

export const getDeviceSettings = (sessionId) => { 
   console.log('Appium getDeviceSettings request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/settings`)
}

export const updateSettings = (sessionId, data) => { 
   console.log('Appium getDeviceSettings request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/settings`,
      data
   )
}


// Device Actions


// System

export const openNotifications = (sessionId) => { 
   console.log('Appium openNotifications request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/open_notifications`)
}

export const getSystemTime = (sessionId) => { 
   console.log('Appium getSystemTime request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/system_time`)
}

export const getSystemBars = (sessionId) => {
   console.log('Appium getSystemBars request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/system_bars`)
}


// Clipboard

export const getClipboard = (sessionId, contentType) => {
   console.log('Appium getClipboard request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/get_clipboard`, {
      contentType
   })
}

export const setClipboard = (sessionId, content, contentType, label) => {
   console.log('Appium setClipboard request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/set_clipboard`, {
      content, contentType, label
   })
}

// Network

export const toggleAirplaneMode = (sessionId) => {
   console.log('Appium toggleAirplaneMode request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/toggle_airplane_mode`)
}

export const toggleData = (sessionId) => {
   console.log('Appium toggleData request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/toggle_data`)
}

export const toggleWifi = (sessionId) => {
   console.log('Appium toggleWifi request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/toggle_wifi`)
}

export const toggleLocationServices = (sessionId) => {
   console.log('Appium toggleLocationServices request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/toggle_location_services`)
}


// Interaction

export const shake = (sessionId) => {
   console.log('Appium shake request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/shake`)
}

export const lock = (sessionId, seconds) => {
   console.log('Appium lock request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/lock`, {
      seconds
   })
}

export const unlock = (sessionId) => {
   console.log('Appium unlock request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/unlock`)
}

export const isLocked = (sessionId) => {
   console.log('Appium isLocked request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/is_locked`)
}

export const rotate = (sessionId, x, y, radius, rotation, touchCount, duration) => {
   console.log('Appium rotate request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/rotate`, {
      x, y, radius, rotation, touchCount, duration
   })
}


// Keys

export const pressKeyCode = (sessionId, keycode, metastate, flags) => {
   console.log('Appium pressKeyCode request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/press_keycode`, {
      keycode, metastate, flags
   })
}

export const longPressKeyCode = (sessionId, keycode, metastate, flags) => {
   console.log('Appium pressKeyCode request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/long_press_keycode`, {
      keycode, metastate, flags
   })
}

export const hideKeyboard = (sessionId, strategy, key, keycode, keyName) => {
   console.log('Appium hideKeyboard request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/hide_keyboard`, {
      strategy, key, keycode, keyName
   })
}

export const isKeyboardShown = (sessionId) => {
   console.log('Appium isKeyboardShown request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/is_keyboard_shown`)
}

// Activity

export const startActivity = (sessionId, appPackage, appActivity, appWaitPackage, intentAction, intentCategory, intentFlags, optionalIntentArguments, dontStopAppOnReset) => {
   console.log('Appium start activity request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/start_activity`, {
      appPackage, appActivity, appWaitPackage, intentAction, intentCategory, intentFlags, optionalIntentArguments, dontStopAppOnReset
   })
}

export const currentActivity = (sessionId) => {
   console.log('Appium current activty request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/current_activity`)
}

export const currentPackage = (sessionId) => {
   console.log('Appium current package request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/current_package`)
}


// App

// export const installApp = (sessionId, appPath) => {
//    console.log('Appium install app request is taken.')
//    return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/install_app`, {
//       appPath
//    })
// }

export const isAppInstalled = (sessionId, bundleId) => {
   console.log('Appium app installed request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/app_installed`, {
      bundleId
   })
}

export const launchApp = (sessionId) => {
   console.log('Appium launch app request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/app/launch`, {})
}

export const backgroundApp = (sessionId, seconds) => {
   console.log('Appium background app request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/app/background`, {
      seconds
   })
}

export const closeApp = (sessionId) => {
   console.log('Appium close app request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/app/close`, {})
}

export const resetApp = (sessionId) => {
   console.log('Appium reset app request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/app/reset`, {})
}

export const removeApp = (sessionId, appId, bundleId) => {
   console.log('Appium remove app request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/remove_app`, {
      appId, bundleId
   })
}

export const activateApp = (sessionId, type, appId, bundleId) => {
   console.log('Appium activate app request is taken.')
   if(type === 'appId'){
      return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/activate_app`, {
         appId
      })
   }
   else{
      return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/activate_app`, {
         bundleId
      })
   }
}

export const terminateApp = (sessionId, type, appId, bundleId, timeout) => {
   console.log('Appium terminate app request is taken.')
   if(type === 'appId'){
      return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/terminate_app`, {
         appId,  options: {timeout}
      })
   }
   else{
      return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/terminate_app`, {
         bundleId,  options: {timeout}
      })
   }
}

export const getAppStrings = (sessionId, language, stringFile) => {
   console.log('Appium getAppStrings request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/app/strings`, {
      language, stringFile
   })
}

export const endTestCoverage = (sessionId, intent, path) => {
   console.log('Appium end test coverage request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/app/end_test_coverage`, {
      intent, path
   })
}

// File

// export const pushFile = (sessionId, path, data) => {
//    console.log('Appium push file command request is taken.')
//    return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/push_file`, {
//       path, data
//    })
// }

// export const pullFile = (sessionId, path) => {
//    console.log('Appium pull file command request is taken.')
//    return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/pull_file`, {
//       path
//    })
// }

// export const pullFolder = (sessionId, path) => {
//    console.log('Appium pull folder command request is taken.')
//    return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/device/pull_folder`, {
//       path
//    })
// }

// Performance Data

export const getPerformanceData = (sessionId, packageName, dataType, dataReadTimeout) => {
   console.log('Appium get performance data command request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/getPerformanceData`, {
      packageName, dataType, dataReadTimeout
   })
}

export const getPerformanceDataTypes = (sessionId) => {
   console.log('Appium get performance data types command request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/appium/performanceData/types`, {})
}

// Execute Mobile Command Actions

export const executeCommand = (sessionId, script, args) => {
   console.log('Appium execute mobile command request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/execute`, {
      script, args
   })
}


// Context

export const getContext = (sessionId) => {
   console.log('Appium get context request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/context`) 
}

export const getAllContext = (sessionId) => {
   console.log('Appium get all context request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/contexts`) 
}

export const setContext = (sessionId, name) => {
   console.log('Appium set context request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/context`, {
      name
   })
}

export const getWindowHandle = (sessionId) => {
   console.log('Appium get window context request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/window`) 
}

export const closeWindow = (sessionId) => {
   console.log('Appium close window context request is taken.')
   return axios.delete(`${APPIUM_REQUEST_PREFIX}/${sessionId}/window`) 
}

export const switchToWindow = (sessionId) => {
   console.log('Appium switch to window request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/window`) 
}

export const getWindowHandles = (sessionId) => {
   console.log('Appium get window handles context request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/window/handles`) 
}

export const createWindow = (sessionId) => {
   console.log('Appium new window context request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/window/new`) 
}

// Web Actions
//Navigation

export const goToUrl = (sessionId, url) => {
   console.log('Appium go to url request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/url`, {
      url
   })
}

export const getUrl = (sessionId) => {
   console.log('Appium get url request is taken.')
   return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/url`)
}

export const webBack = (sessionId) => {
   console.log('Appium back request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/back`)
}

export const forward = (sessionId) => {
   console.log('Appium forward request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/forward`)
}

export const refresh = (sessionId) => {
   console.log('Appium refresh request is taken.')
   return axios.post(`${APPIUM_REQUEST_PREFIX}/${sessionId}/refresh`)
}