import React, {Component} from 'react';
import {Segment, Sidebar, Tab} from "semantic-ui-react";
import SidebarLastDevices from "./SidebarLastDevices";
import SidebarFavDevices from "./SidebarFavDevices";
import { withTranslation } from 'react-i18next';
import { deviceSidebarMessages } from '../utils/UIMessages';


class DeviceSidebar extends Component {

    panes = () => [
        {
            menuItem: deviceSidebarMessages().LAST_USED_DEVICE_MENU_HEADER,
            render: () => <>
                <SidebarLastDevices
                    {...this.props}
                />
            </>
        },
        {
            menuItem: deviceSidebarMessages().FAVORITE_DEVICES_MENU_HEADER,
            render: () => <>
                <SidebarFavDevices
                    {...this.props}
                />
            </>
        },
    ]

    render() {
        return (
            <Sidebar
                as={Segment}
                animation='scale down'
                direction='right'
                onHide={this.props.onHideSidebar}
                vertical
                visible={this.props.visible}
                width='wide'
                className='device-sidebar-component'
            >
                <Tab
                    className='sidebar-tab-component'
                    panes={this.panes()}
                    menu={{ secondary: true, pointing: true }}
                />
            </Sidebar>
        );
    }
}

export default withTranslation()(DeviceSidebar);