import React, {Component} from 'react';
import {Tab} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import RolePage from "./role/RolePage";
import ManageUsers from "./usermanagement/ManageUsers";
import UserGroupsPage from "./usergroup/UserGroupsPage";
import OtherOpsPage from "./other/OtherOpsPage";
import EmailConfig from "./email/EmailConfig";
import Versions from "./version/Versions";
import CertificatesPage from "./certificate/CertificatesPage";
import SystemParametersPage from './sysparameters/SystemParametersPage'
import AppRestrictionsPage from "./apprestrictions/AppRestrictionsPage";
import AgentConfig from "./agent/AgentConfig";
import Locations from './locations/Locations';
import { getPrivileges } from '../../utils/Methods';
import WebhookPage from './webhook/Webhook';
import IntegrationsPage from './integrations/Integrations';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import SlaveConfigPage from './slave/SlaveConfigPage';

import {
    USER_SECRET_ICON, USER_ICON, USERS_ICON,
    ADDRESS_CARD_ICON, MAIL_ICON, SPY_ICON, FILE_ALTERNATE_ICON,
    BROWSER_ICON, OPTIONS_ICON, KEY_ICON, SHIELD_ALTERNATE_ICON,
    LOCATION_ARROW_ICON,SYNC_ALTERNATE_ICON,INFO_ICON,SERVER_ICON
} from '../../utils/UiIcons';
import {
    AGENGT_CONFIGURATION_HEADER, APP_RESTRICTIONS_HEADER, authenticationProvidersMesssages, CERTIFICATES_HEADER,
    INTEGRATIONS_HEADER,
    LOCATIONS_HEADER, MAIL_SERVERS_HEADER, OTHER_LABEL, ROLES_HEADER,
    SYSTEM_PARAMETERS_HEADER, USERS_HEADER, USER_GROUPS_HEADER,
    VERSIONS_LABEL, WEBHOOK_HEADER, SECONDARY_SERVER_CONFIG_HEADER
} from '../../utils/UIMessages';
import AuthenticationProviders from './authenticationproviders/AuthenticationProviders';
class OptionsPage extends Component {
    // RBA checks
    panesRender = () => {
        return [
            this.state.privileges.includes("23") ? {
                menuItem: {key: 'roles', icon: USER_SECRET_ICON, content: ROLES_HEADER()},
                render: () => <div className='main-right-container-settings'><RolePage/></div>
            } : {},
            this.state.privileges.includes("25") ? {
                menuItem: {key: 'users', icon: USER_ICON, content: USERS_HEADER()},
                render: () => <div className='main-right-container-settings'><ManageUsers/></div>
            } : {},
            this.state.privileges.includes("35") ? {
                menuItem: {key: 'group', icon: USERS_ICON, content: USER_GROUPS_HEADER()},
                render: () => <div className='main-right-container-settings'><UserGroupsPage/></div>
            } : {},
            ([PrivilegeConstants.OAUTH2_CONFIGURATION, PrivilegeConstants.LDAP_CONFIGURATION]
                .some(privilege => this.state.privileges.includes(privilege))) ? {
                menuItem: {
                    key: 'ldap', icon: ADDRESS_CARD_ICON,
                    content: authenticationProvidersMesssages().AUTHENTICATION_PROVIDERS_HEADER
                },
                render: () => <div className='main-right-container-settings'><AuthenticationProviders /></div>
            } : {},

            this.state.privileges.includes("30") ? {
                menuItem: {key: 'mail', icon: MAIL_ICON, content: MAIL_SERVERS_HEADER()},
                render: () => <div className='main-right-container-settings'><EmailConfig/></div>
            } : {},
            this.state.privileges.includes(PrivilegeConstants.SECONDARY_SERVER_MANAGEMENT) ? {
                menuItem: {
                    key: 'slaveConfig',
                    icon: SERVER_ICON,
                    content: SECONDARY_SERVER_CONFIG_HEADER()
                },
                render: () => <div className='main-right-container-settings'><SlaveConfigPage/></div>
            } : {},
            this.state.privileges.includes("42") ? {
                menuItem: {key: 'agent', icon: SPY_ICON, content: AGENGT_CONFIGURATION_HEADER()},
                render: () => <div className='main-right-container-settings'><AgentConfig/></div>
            } : {},
            this.state.privileges.includes("34") ? {
                // true ? {
                menuItem: {key: 'certificate', icon: FILE_ALTERNATE_ICON, content: CERTIFICATES_HEADER()},
                render: () => <div className='main-right-container-settings'><CertificatesPage/></div>
            } : {},
            this.state.privileges.includes(PrivilegeConstants.WEBHOOK) ? {
                menuItem: {key: 'webhook', icon: BROWSER_ICON, content: WEBHOOK_HEADER()},
                render: () => <div className='main-right-container-settings'><WebhookPage/></div>
            }:{},
            this.state.privileges.includes("37") ? {
                menuItem: {key: 'sysparameter', icon: OPTIONS_ICON, content: SYSTEM_PARAMETERS_HEADER()},
                render: () => <div className='main-right-container-settings'><SystemParametersPage/></div>
            } : {},
            this.state.privileges.includes(PrivilegeConstants.INTEGRATION) ? {
                menuItem: {key: 'integrations', icon: KEY_ICON, content: INTEGRATIONS_HEADER()},
                render: () => <div className='main-right-container-settings'><IntegrationsPage/></div>
            } : {},
            this.state.privileges.includes("38") ? {
                menuItem: {
                    key: 'appRestrictions',
                    icon: SHIELD_ALTERNATE_ICON,
                    content: APP_RESTRICTIONS_HEADER()
                },
                render: () => <div className='main-right-container-settings'><AppRestrictionsPage/></div>
            } : {},
            {
                menuItem: {key: 'manageLocations', icon: LOCATION_ARROW_ICON, content: LOCATIONS_HEADER()},
                render: () => <div className='main-right-container-settings'><Locations/></div>
            },
            {
                menuItem: {key: 'version', icon: SYNC_ALTERNATE_ICON, content: VERSIONS_LABEL()},
                render: () => <div className='main-right-container-settings'><Versions/></div>
            },
            {
                menuItem: {key: 'other', icon: INFO_ICON, content: OTHER_LABEL()},
                render: () => <div className='main-right-container-settings'><OtherOpsPage/></div>
            }
        ]
    }

    state = {privileges: []}

    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({privileges})
    }

    render() {
        return (
              <Tab 
                panes={this.panesRender()} 
                className='main-container-settings'
                menu={{secondary: true, vertical: true, fluid:true}}
          />
        );
    }
}

export default withTranslation()(OptionsPage);
