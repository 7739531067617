import React, {Component} from 'react';
import {Button, Form, Grid, Header, Icon, Modal} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import {showError, showSuccess} from '../../../utils/ToastHelpers';
import {
    createPasswordRules,
    getPasswordRules
} from '../../../api/apiCalls'
import { getPrivileges } from '../../../utils/Methods';
import { PrivilegeConstants } from '../../../utils/PrivilegeConstants';
import { FAILED_ERROR_MESSAGE, passwordRulesMessages, SAVE_BUTTON, SUCCESS_MESSAGE } from '../../../utils/UIMessages';
import {  CHECKMARK_ICON } from '../../../utils/UiIcons';
class PasswordRules extends Component {

    state = {
        passwordRules: {},
        privileges: []
    }


    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({ privileges }, () => {
            if (this.state.privileges.includes(PrivilegeConstants.SYSTEM_PARAMETER)) {
                this.getPassRules()
            }
        })
    }

    getPassRules = () => {
        //axios.get("/api/user/password-rules")
        getPasswordRules().then(res => {
            this.setState({passwordRules: res.data[0]})
        }).catch(err => {
            showError(`${passwordRulesMessages().NOT_FETCH_PASSWORD_RULES} ${this.props.t(err?.response?.data?.message ?? err.toString())}`)
        })
    }

    handleChange = (e, {name, value}) => this.setState(prevState => ({
            passwordRules: {
                ...prevState.passwordRules,
                [name]: value
            }
        }))


    handleChangeCheck = (e, {name, checked}) => this.setState(prevState => ({
        passwordRules: {
            ...prevState.passwordRules,
            [name]: checked
        }
    }))


    onSubmit = () => {
        let data = this.state.passwordRules
        //axios.post("/api/user/password-rules",data)
        createPasswordRules(data).then(() => {
            showSuccess(SUCCESS_MESSAGE())
            this.props.handleClosePassModel()
        }).catch(err => {
            showError(`${FAILED_ERROR_MESSAGE()} ${this.props.t(err?.response?.data?.message ?? err.toString())}`)
        })
    }

    render() {
        return (
            <div>
                <div>
                    <Modal open={this.props.passModalVisibility} onClose={() => {
                        this.props.handleClosePassModel()
                        if(this.state.privileges.includes(PrivilegeConstants.SYSTEM_PARAMETER)){
                            this.getPassRules()
                        }
                    }} closeIcon
                           closeOnDimmerClick={true}
                    >
                        <Header style={{textAlign: 'center'}}>{passwordRulesMessages().MODAL_HEADER}</Header>
                        <Modal.Content>
                            <Form>
                                <Grid columns={3} divided>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field inline>
                                                <label style={{marginBottom: 15}}>{passwordRulesMessages().LETTER_RULES_LABEL}</label>
                                                <Form.Radio
                                                    name='caseRule'
                                                    label={passwordRulesMessages().LOWERCASE_LETTERS_LABEL}
                                                    value='LOWER'
                                                    checked={this.state.passwordRules?.caseRule === 'LOWER'}
                                                    onChange={this.handleChange}
                                                />
                                                <Form.Radio
                                                    name='caseRule'
                                                    label={passwordRulesMessages().UPPERCASE_LETTERS_LABEL}
                                                    value='UPPER'
                                                    checked={this.state.passwordRules?.caseRule === 'UPPER'}
                                                    onChange={this.handleChange}
                                                />
                                                <Form.Radio
                                                    name='caseRule'
                                                    label={passwordRulesMessages().BOTH_UPPERCASE_LOWERCASE_LETTERS_LABEL}
                                                    value='LOWER_UPPER'
                                                    checked={this.state.passwordRules?.caseRule === 'LOWER_UPPER'}
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field inline>
                                                <label
                                                    style={{marginBottom: 15}}>{passwordRulesMessages().CHARACTER_RULES_LABEL}</label>
                                                <Form.Checkbox
                                                    toggle={true}
                                                    name='numeric'
                                                    label={passwordRulesMessages().NUMBERS_LABEL}
                                                    checked={this.state.passwordRules?.numeric}
                                                    onChange={this.handleChangeCheck}
                                                />
                                                <Form.Checkbox
                                                    toggle={true}
                                                    name='specialCharacters'
                                                    label={passwordRulesMessages().SPECIAL_CHARACTERS_LABEL}
                                                    checked={!!this.state.passwordRules?.specialCharacters}
                                                    onChange={this.handleChangeCheck}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field inline>
                                                <label
                                                    style={{marginBottom: 15}}>{passwordRulesMessages().CHARACTER_LIMIT_LABEL}</label>
                                                <Form.Input inline
                                                            error={+this.state.passwordRules?.minimumPasswordLength <= 0 || +this.state.passwordRules?.minimumPasswordLength>+this.state.passwordRules?.maximumPasswordLength}
                                                            size='large'
                                                            type='number'
                                                            name='minimumPasswordLength'
                                                            label='Min'
                                                            value={this.state.passwordRules?.minimumPasswordLength}
                                                            onChange={this.handleChange}
                                                            className='password-rule-input-box'
                                                />
                                                <Form.Input inline
                                                            size='large'
                                                            error={+this.state.passwordRules?.minimumPasswordLength <= 0 || +this.state.passwordRules?.minimumPasswordLength>+this.state.passwordRules?.maximumPasswordLength}
                                                            type='number'
                                                            name='maximumPasswordLength'
                                                            label='Max'
                                                            value={this.state.passwordRules?.maximumPasswordLength}
                                                            onChange={this.handleChange}
                                                            className='password-rule-input-box'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button.Group>
                                <Button
                                    icon
                                    labelPosition='right'
                                    color='green'
                                    onClick={this.onSubmit}
                                    disabled={+this.state.passwordRules?.minimumPasswordLength <= 0 
                                                || +this.state.passwordRules?.minimumPasswordLength > +this.state.passwordRules?.maximumPasswordLength }>
                                    <Icon
                                        name={CHECKMARK_ICON}/> {SAVE_BUTTON()}</Button>
                            </Button.Group>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PasswordRules);
