import { Modal, Header, Form, Input, Button, Icon, Progress } from "semantic-ui-react";
import { extensionListToAcceptAttribute } from '../utils/Methods';
import FileSelectionForm from './FileSelectionForm';
import { FILE_HEADER, FILE_NAME_HEADER, fileUploadMessages } from '../utils/UIMessages';
import { UPLOAD_ICON } from '../utils/UiIcons';
import { useEffect, useState } from 'react';

const FileUploadModal = (props) => {
  const acceptableExtensions = extensionListToAcceptAttribute(props.acceptableExtensions);

  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    let flag;
    if (props.isContainFileName) {
      flag =
        !(props.name && props.uploadFile) ||
        props.name.trim().length === 0 ||
        props.loadingUploadButton ||
        props.fileExtensionErrorMsg.length > 0;
    } else {
      flag = (!props.uploadFile) || props.loadingUploadButton || props.fileExtensionErrorMsg.length > 0;
    }

    setBtnDisabled(flag);
  }, [props.uploadFile, props.name,props.loadingUploadButton, props.fileExtensionErrorMsg]);

  return (
    <>
      <Modal
        open={props.fileUploadModalVisibility}
        onClose={() => props.handleCloseFileUploadModal()}
        size="small"
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        centered={false}>
        <Header icon={UPLOAD_ICON} content={props.header} />
        <Modal.Content>
          <div>
            {props.description && (
              <div>
                <p>{props.description}</p>
              </div>
            )}
            {props.isContainFileName && (
              <Form>
                <Form.Field required>
                  <label style={{ textAlign: 'left' }}>{FILE_NAME_HEADER()}</label>
                  <Input value={props.name} onChange={(e, { value }) => props.onChangeName(value)} />
                </Form.Field>
              </Form>
            )}
          </div>
          <FileSelectionForm
            headerName={FILE_HEADER()}
            acceptableExtensions={acceptableExtensions}
            errMsg={props.fileExtensionErrorMsg}
            onChangeFileUpload={props.onChangeFileUpload}
          />
          <Progress
            style={{ marginTop: 25 }}
            percent={props.fileUploadPercent}
            disabled={!props.appFile}
            indicating
            progress
          />
          <div style={{ textAlign: 'center', marginTop: 25 }}>
            <Button
              loading={props.loadingUploadButton}
              content={fileUploadMessages().UPLOAD_FILE_BUTTON}
              icon={<Icon name={UPLOAD_ICON} />}
              color="green"
              onClick={props.handleUploadFile}
              disabled={btnDisabled}
            />
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default FileUploadModal;
