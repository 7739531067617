import React, {Component} from 'react';

class HighlighterRect extends Component {

    handleOnMouseOver=(path)=>{
        this.props.selectHoveredElement(path)
    }

    handleClick=(path)=>{
        this.props.selectSelectedElement(path)
    }



    render () {
      const {selectedElement = {}, hoveredElement = {}, element,
             scaleRatio, xOffset, elLocation, elSize, dimensions} = this.props;
             
      const {path: hoveredPath} = hoveredElement;
      const {path: selectedPath} = selectedElement;
  
      let width, height, left, top, key;
      let highlighterClasses = ['elementBox'];
  
      if (element) {
        ({width, height, left, top} = dimensions);
  
        // Add class + special classes to hovered and selected elements
        if (hoveredPath === element.path) {
          highlighterClasses.push('hoveredElementBox');
        }
        if (selectedPath === element.path) {
          highlighterClasses.push('selectedElementBox');
        }
        key = element.path;
      } else if (elLocation && elSize) {
        width = elSize.width / scaleRatio;
        height = elSize.height / scaleRatio;
        top = elLocation.y / scaleRatio;
        left = elLocation.x / scaleRatio + xOffset;
        // Unique keys are assigned to elements by their x & y coordinates
        key = `searchedForElement{x: ${elLocation.x}, y: ${elLocation.y}}`;
        highlighterClasses.push('selectedElementBox');
      }
      return <div className={highlighterClasses.join(' ').trim()}
        onMouseOver={() => this.handleOnMouseOver(element.path)}
        onClick={() => this.handleClick(element.path)}
        
        key={key}
        style={{left: (left || 0), top: (top || 0), width: (width || 0), height: (height || 0)}}>
        <div></div>
      </div>;
    }
    
}

export default HighlighterRect;