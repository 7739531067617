import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L from 'leaflet';
import axios from "axios";
import {Button, Dimmer, Divider, Dropdown, Form, Header, Icon, Input, Modal, Segment} from "semantic-ui-react";
import {Flip, toast} from "react-toastify";
import {validateLocationInput} from "../../utils/Methods";
import {showError} from "../../utils/ToastHelpers";
import {
  createUserLocation,
  getUserAndSystemLocationList
} from '../../api/apiCalls'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import {
    ADD_NEW_LOCATION_LABEL, CANCEL_BUTTON,
    CLICK_TO_MAKE_MARKER_DRAGGABLE_LABEL, LATITUDE_LABEL,
    LOCATION_NAME_LABEL, LONGITUDE_LABEL, manageLocationMessages,
    MARKER_IS_DRAGGABLE_LABEL, MY_LOCATIONS_HEADER,
    NOT_GET_LOCATION_LIST, SAVE_BUTTON, SUCCESS_MESSAGE,
    SYSTEM_LOCATIONS_HEADER
} from '../../utils/UIMessages';
import { LOCATION_ARROW_ICON } from '../../utils/UiIcons';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl
});

const center = [0, 0]
const zoom = 13

function DisplayPosition({
  map, id, setLat, setLng, position, setPosition, setError, getLocationList, locOptions, locationId, setLocationId, locationList
}) {
    const [locDropdownZIndex, setLocDropdownZIndex] = useState(1);
    const [newLocName, setNewLocName] = useState("");
    const [isOpenNewLocModal, setIsOpenNewLocModal] = useState(false);


    const onClickChange = useCallback(() => {
        const data = {
            latitude:position.lat,
            longitude:position.lng
        }
        axios.post('/api/screen-share/set-location?udId=' + id, data).then(() => {
            map.setView([position.lat, position.lng], zoom)
            setLng(position.lng)
            setLat(position.lat)
            toast.success(SUCCESS_MESSAGE(), {
                transition: Flip,
                autoClose: 3000
            });
        }).catch(err=>{
            showError(manageLocationMessages().NOT_SET_LOCATION_ERROR_MESSAGE + ": " + err.toString());
            setError(true)
        })
    }, [map, position])


    const onChangeCoordinates = (e, { value }) => {
      const loc = locationList.find(l => l.id === value);
      if (loc) {
        setLocationId(value);
        setPosition({
          lat: loc.latitude,
          lng: loc.longitude
        });
      } else {
        setLocationId(value);
      }
    }


    const onClickSave = () => {
      if (validateLocationInput(position.lat, position.lng, newLocName)) {
        const data = {
          latitude: position.lat,
          longitude: position.lng,
          name: newLocName
        };

        //axios.post("/api/location/user", data)
        createUserLocation(data).then(() => {
            getLocationList();
            onCloseNewLocModal();
            toast.success(SUCCESS_MESSAGE(), {
              transition: Flip,
              autoClose: 3000
            });
          })
          .catch(err => {
            showError(manageLocationMessages().NOT_ADD_NEW_LOCATION_ERROR_MESSAGE + ": " + err.toString());
          });
      }
    }


    const onCloseNewLocModal = () => {
      setIsOpenNewLocModal(false);
      setNewLocName("");
    }


    useEffect(() => { //initial effect
        getLocationList();

        axios.get('/api/screen-share/get-location?udId=' + id).then(res => {
            const deviceCoordinates = res?.data
            if (!(deviceCoordinates === null || deviceCoordinates === '')) {
                deviceCoordinates.latitude = deviceCoordinates.latitude.replace(/,/g, ".")
                deviceCoordinates.longitude = deviceCoordinates.longitude.replace(/,/g, ".")
                setPosition(prevState => {
                    return ({
                        ...prevState,
                        lat: deviceCoordinates.latitude,
                        lng: deviceCoordinates.longitude
                    })
                })
                setLat(deviceCoordinates.latitude)
                setLng(deviceCoordinates.longitude)
                map.setView([deviceCoordinates.latitude, deviceCoordinates.longitude], zoom)
            } else {
                setError(true)
            }
        }).catch(err=>{
            showError(manageLocationMessages().NOT_GET_LOCATION_ERROR_MESSAGE + ": " + err.toString());
            setError(true)
        })

    }, [map])


    return (
        <p>
            <Form>
                <Form.Field>
                  <label>{manageLocationMessages().SAVED_LOCATIONS_LABEL}</label>
                  <Dropdown search selection clearable selectOnBlur={false} style={{ zIndex: locDropdownZIndex }} onFocus={() => setLocDropdownZIndex(1001)} onBlur={() => setLocDropdownZIndex(1)} 
                    options={locOptions} value={locationId} onChange={onChangeCoordinates} />
                </Form.Field>
                <Form.Group widths="equal" >
                    <Form.Input fluid label={LATITUDE_LABEL()} name='latitude' value={position.lat}
                                onChange={(e, {value}) => {
                                    setPosition(prevState => {
                                        return ({
                                            ...prevState,
                                            lat: value
                                        })
                                    })
                                }}
                    />
                    <Form.Input fluid label={LONGITUDE_LABEL()} name='longitude' value={position.lng}
                                onChange={(e, {value}) => {
                                    setPosition(prevState => {
                                        return ({
                                            ...prevState,
                                            lng: value
                                        })
                                    })
                                }}
                    />
                    
                    <Button fluid style={{height: "fit-content", width: "20%", marginBlockStart: "auto"}}
                            onClick={onClickChange}>{manageLocationMessages().GO_BUTTON}</Button>
                    
                    <Modal trigger={<Button fluid style={{height: "fit-content", width: "20%", marginBlockStart: "auto"}}>{manageLocationMessages().SAVE_AS_BUTTON}</Button>}
                      size="tiny" open={isOpenNewLocModal} onOpen={() => setIsOpenNewLocModal(true)} onClose={onCloseNewLocModal} >
                      <Modal.Header>{ADD_NEW_LOCATION_LABEL()}</Modal.Header>
                      <Modal.Content>
                        <Form>
                          <Form.Field>
                            <label>{LOCATION_NAME_LABEL()}</label>
                            <Input value={newLocName} onChange={(e, {value}) => setNewLocName(value)} />
                          </Form.Field>
                        </Form>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button primary onClick={onClickSave}>{SAVE_BUTTON()}</Button>
                        <Button onClick={onCloseNewLocModal}>{CANCEL_BUTTON()}</Button>
                      </Modal.Actions>
                    </Modal>
                </Form.Group>
            </Form>
        </p>
    )
}


function ManageLocation(props) {
    const markerRef = useRef(null)

    const [map, setMap] = useState(null)
    const [lat, setLat] = useState(0)
    const [lng, setLng] = useState(0)
    const [position, setPosition] = useState({ lat: 0, lng: 0 })
    const [draggable] = useState(true)
    const [error, setError] = useState(false)
    const [locOptions, setLocOptions] = useState([]);
    const [locationId, setLocationId] = useState("");
    const [locationList, setLocationList] = useState([]);

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                }
            },
        }),
        [],
    )


    const getLocationList = useCallback(() => {
      //axios.get("/api/location/all")
      getUserAndSystemLocationList().then(res => {
          const sysLocs = res.data?.system?.map(loc => ({ key: loc.id, value: loc.id, text: loc.name }));
          const userLocs = res.data?.user?.map(loc => ({ key: loc.id, value: loc.id, text: loc.name }));
          setLocOptions([
            { key: "systemLocations", disabled: true, content: <Header as="h5">{SYSTEM_LOCATIONS_HEADER()}</Header> },
            ...sysLocs,
            { key: "divider", disabled: true, content: <Divider style={{ margin: 0 }} />  },
            { key: "userLocations", disabled: true, content: <Header as="h5">{MY_LOCATIONS_HEADER()}</Header> },
            ...userLocs
          ]);
          setLocationList([ ...res.data.system, ...res.data.user ]);
        })
        .catch(() => {
          showError(NOT_GET_LOCATION_LIST());
        });
    }, []);


    const displayMap = useMemo(
        () => (
            <MapContainer
                center={center}
                zoom={zoom}
                scrollWheelZoom={false}
                whenCreated={setMap}
                style={{height: 750}}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> | <a href="https://netas.com.tr/" target="_blank">NETAS</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                    draggable={draggable}
                    eventHandlers={eventHandlers}
                    ref={markerRef}
                    position={[lat, lng]}
                >
                    <Popup minWidth={90}>
                        <span>
                          {draggable
                              ? MARKER_IS_DRAGGABLE_LABEL()
                              : CLICK_TO_MAKE_MARKER_DRAGGABLE_LABEL()}
                        </span>
                    </Popup>
                </Marker>


            </MapContainer>
        ),
        [lat, lng],
    )

    return (
        <Segment basic style={{position: 'sticky'}}>
            <Dimmer active={error} style={{zIndex:9999}}>
                <Header as='h2' icon inverted>
                    <Icon name={LOCATION_ARROW_ICON} />
                    {manageLocationMessages().LCOATION_ERROR_MESSAGE}
                </Header>
            </Dimmer>
            {map ?
                <DisplayPosition
                    map={map}
                    id={props.id}
                    setLat={setLat}
                    setLng={setLng}
                    lat={lat}
                    lng={lng}
                    position={position}
                    error={error}
                    setError={setError}
                    setPosition={setPosition}
                    getLocationList={getLocationList}
                    locOptions={locOptions}
                    locationId={locationId}
                    setLocationId={setLocationId}
                    locationList={locationList} /> : null}
            {displayMap}
        </Segment>
    )

}

export default ManageLocation;