import React from 'react'
import { Card } from 'semantic-ui-react'
import { getIconForFile } from '../utils/Methods';
import fileExtension from 'file-extension';

const LibraryFileList = (props) => {
    const {fileList} = props;
    return (
        <>
            <Card.Group centered>
                {Object.keys(fileList).map((file, i) => {
                    let extension = fileExtension(fileList[file][0].fileName); 

                    return (
                        <Card
                            className='file-card-container'
                            key={i}
                            onClick={() => props.onOpenFileDetail(fileList[file][0])}
                        >
                            <Card.Content>
                                <div className='file-card'>
                                    <div>
                                        {getIconForFile(extension, 'huge')}
                                    </div>
                                    <div
                                        className='file-card-content'
                                        style={{ minHeight: 85 }}
                                    >
                                        <div className='file-card-name'>
                                            {fileList[file][0].name} 
                                        </div>

                                        <span style={{ color: 'black' }}>
                                            {fileList[file][0].fileName}
                                        </span>

                                    </div>
                                </div>
                            </Card.Content>
                        </Card>
                    )
                })}
            </Card.Group>
        </>
    )
}

export default LibraryFileList