import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import { CANCEL_BUTTON, CONNECT_BUTTON, usedDeviceWarningMessages } from '../../utils/UIMessages';


const UsedDeviceWarningModal = props => {
  const { t } = useTranslation();

  const getListItemText = devInfo => {
    let res = `${devInfo.id} ( `;
    devInfo.busyState.forEach(s => {
      res += t(s) + ' ';
    });
    return res + ')';
  };

  return (
    <Modal open={props.open} closeIcon={true} onClose={props.closeModal} size="small" >
      <Modal.Header>{usedDeviceWarningMessages().MODAL_HEADER}</Modal.Header>

      <Modal.Content>
        <ul>
          {
            props.busyStateList.map((devInfo, i) => {
              if (devInfo.busyState.length) {
                return <li key={i}>{getListItemText(devInfo)}</li>;
              } else {
                return null;
              }
            })
          }
        </ul>
        {usedDeviceWarningMessages().MODAL_MESSAGE}
      </Modal.Content>

      <Modal.Actions>
        <Button as={Link} to="/device-list" >
          {CANCEL_BUTTON()}
        </Button>
        <Button color="green" onClick={props.closeModal}>
          {CONNECT_BUTTON()}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UsedDeviceWarningModal;
