import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {connect} from 'react-redux';
import moment from 'moment';
import _, { uniqueId } from 'lodash';
import {Flip, toast} from 'react-toastify';
import {stringify} from 'querystring';
import {Button, Confirm, Dropdown, Form, Header, Icon, Input, Modal} from 'semantic-ui-react';
import DatePicker, {registerLocale} from 'react-datepicker';
import ManualTestSessionModal from './ManualTestSessionModal';
import TestSessionDetailsModal from './TestSessionDetailsModal';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-GB';
import {getPrivileges, formatValuesManualTestSession, validateUrl} from '../../utils/Methods';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import {showError} from '../../utils/ToastHelpers';
import {
  getAppList,
  deleteReportFile,
  downloadReportFile,
  getTestSessionList,
  deleteTestSession,
  startTestSession,
  getUserSummaryList,
  getVisiumManageIntegration,
  getSingleUseToken,
  getTestSessionInfo,
  getIntegrationDefects,
  manageRefreshRequest
} from '../../api/apiCalls'
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8'
import {DEBOUNCE_WAIT} from '../../utils/Constants';
import {proxy} from '../../../package.json';
import {Base64} from 'js-base64';
import { ACTIONS_HEADER, APPLICATION_HEADER, CANCEL_BUTTON, CONFIRM_BUTTON, DEFECT_BUTTON, DEFECT_CODE, PROJECT_SUMMARY, DEFECT_INSPECTION_HEADER, DEFECT_URL_HEADER, DELETE_BUTTON, DETAILS_LABEL, DEVICE_HEADER, END_DATE_LABEL, FAILED_ERROR_MESSAGE, GO_TO_DEFECT_BUTTON, manualTestSessionMessages, MANUAL_TEST_SESSION_HEADER, NOT_FETCH_APPLICATIONS_ERROR_MESSAGE, NOT_FETCH_DEFECTS, NOT_FETCH_DEVICES, NOT_FETCH_USERS, N_A_LABEL, OPEN_NEW_DEFECT_BUTTON, OS_VERSION_HEADER, PREVIOUSLY_OPENED_DEFECTS_HEADER, RECONNECT_BUTTON, RESET_TABLE_BUTTON, SEARCH_APPLICATION_PLACEHOLDER, SEARCH_ID_PLACEHOLDER, SEARCH_SESSION_NAME_PLACEHOLDER, SEARCH_USER_PLACEHOLDER, SESSION_HEADER, SESSION_ID_HEADER, SESSION_NAME_HEADER, START_DATE_LABEL, STATUS_HEADER, SUCCESS_MESSAGE, TEST_SESSIONS_HEADER, TIME_LABEL, USERS_HEADER, USER_HEADER, manualTestSessionModalMessages, integrationMessages } from '../../utils/UIMessages';
import { BUG_ICON, ELLIPSIS_HORIZONTAL_ICON, INFO_ICON, MOBILE_ALTERNATE_ICON, MOBILE_ICON, X_ICON, FOLDER_ICON } from '../../utils/UiIcons';
import ManageSidebar from '../../components/ManageSidebar';
registerLocale('tr', tr);
registerLocale('en', en);

const username = localStorage.getItem('username');

class ManualTestSessions extends Component {

  //React Table V8 Columns
  columns = [
    {
      header: () => <div>{SESSION_HEADER()}</div>,
      accessorKey: 'name',
      id: 'name',
      cell: info => (
        <>
          {info.getValue() !== null ? (
            <>
              {' '}
              <p>
                {info.getValue()} <br/>{' '}
                <span
                  style={{color: 'gray', fontSize: 12}}
                >{`${SESSION_ID_HEADER()}: ${info.row.original.id}`}</span>
              </p>{' '}
            </>
          ) : (
            N_A_LABEL()
          )}
        </>
      )
    },
    {
      header: () => <div>{USER_HEADER()}</div>,
      accessorKey: 'fullName',
      id: 'fullName',
      enableColumnFilter: false,
      cell: info => (
        <>
          {info.getValue() !== null ? (
            <>
              {' '}
              <p>
                {' '}
                {info.getValue()}
                <br/>{' '}
                <span style={{color: 'gray', fontSize: 12}}>
                                    {info.row.original.username}
                                </span>
              </p>{' '}
            </>
          ) : (
            N_A_LABEL()
          )}
        </>
      )
    },
    {
      header: () => <div>{DEVICE_HEADER()}</div>,
      accessorKey: 'udId',
      id: 'udId',
      enableColumnFilter: false,
      cell: info => (
        <>
          {info.getValue() !== null ? (
            <>
              {' '}
              <p>
                {info.getValue()} <br/>{' '}
                <span
                  style={{color: 'gray', fontSize: 12}}
                >{`${OS_VERSION_HEADER()}: ${info.row.original.deviceOsVersion}`}</span>
              </p>{' '}
            </>
          ) : (
            N_A_LABEL()
          )}
        </>
      )
    },
    {
      header: () => <div>{APPLICATION_HEADER()}</div>,
      accessorKey: 'appName',
      id: 'appName',
      enableColumnFilter: false,
      cell: info => (
        <>
          {info.getValue() !== null ? (
            <>
              {' '}
              <p>
                {info.getValue()}
                <br/>
                <span
                  style={{color: 'gray', fontSize: 12}}
                >{`${info.row.original.appVersion} (${info.row.original.appVersionCode})`}</span>{' '}
              </p>{' '}
            </>
          ) : (
            N_A_LABEL()
          )}
        </>
      )
    },
    {
      header: () => <div>{STATUS_HEADER()}</div>,
      accessorKey: 'status',
      id: 'status',
      enableColumnFilter: false,
      cell: info => (<span>{info.getValue() !== null ? formatValuesManualTestSession(info.getValue()) : N_A_LABEL()}</span>)
    },
    {
      header: () => <div>{START_DATE_LABEL()}</div>,
      accessorKey: 'startDate',
      id: 'startDate',
      enableColumnFilter: false,
      cell: info => (
        <>
          {info.getValue() !== null ? (
            <>
              {' '}
              <p>
                {moment(info.getValue()).format('DD/MM/YYYY')}{' '}
                <br/>{' '}
                <span style={{color: 'gray', fontSize: 12}}>
                                    {moment(info.getValue()).format('HH:mm:ss')}
                                </span>
              </p>{' '}
            </>
          ) : (
            N_A_LABEL()
          )}
        </>
      )
    },
    {
      header: () => <div>{END_DATE_LABEL()}</div>,
      accessorKey: 'endDate',
      id: 'endDate',
      enableColumnFilter: false,
      cell: info => (
        <>
          {info.getValue() !== null ? (
            <>
              {' '}
              <p>
                {moment(info.getValue()).format('DD/MM/YYYY')}{' '}
                <br/>{' '}
                <span style={{color: 'gray', fontSize: 12}}>
                                    {moment(info.getValue()).format('HH:mm:ss')}
                                </span>
              </p>{' '}
            </>
          ) : (
            N_A_LABEL()
          )}
        </>
      )
    },
    {
      header: () => <div>{ACTIONS_HEADER()}</div>,
      accessorKey: 'id',
      id: 'id',
      enableColumnFilter: false,
      enableSorting: false,
      cell: info => this.generateActionMenu(info)
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      privileges: [],
      testSessionUrl: '/api/v1/test-sessions',
      sessionType: 'MANUAL',
      testSessions: [],
      testSessionField: {},
      allUsers: [],
      allDevices: [],
      allApps: [],
      allAppsDropdown: [],
      allDevicesDropDown: [],
      freeDevices: [],
      username: '',
      udId: '',
      appId: '',
      startDate: undefined,
      endDate: undefined,
      page: 0,
      pageSize: 10,
      allParams: {
        page: 0,
        size: 10
      },
      loading: false,
      sessionStartModalVisibility: false,
      sessionDetailsModalVisibility: false,
      loadingStartSessionModal: false,
      confirmVisibility: false,
      testSessionId: -1,
      visiumManageRedirectLink: '',
      visiumManageInformation: {},
      visiumManageActive: '',
      defectModalStatus: false,
      defectSessionId: '',
      singleUseToken: '',
      summary: '',
      description: '',
      defectsByTsId: [],
      subscriptionId: null,
      testRunModal: false,
      testRunSessionStarterVisibility: null,
      manageTestRunCode: null,
      manageTestRunSummary: null,
      manageProjects: null
    };
    this.loadData = _.debounce(this.loadData, DEBOUNCE_WAIT.LONG);
  }

  componentDidMount() {
    const privileges = getPrivileges();
    this.setState({privileges});
    this.loadData();

    if (privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT)) {
      //TODO: use test session specific endpoint
      getUserSummaryList().then(res => {
        const allUsers = res.data.map(user => {
          return {
            key: user.userName,
            value: user.userName,
            text:
              user.fullName === null
                ? user.userName
                : user.fullName
          };
        });
        this.setState({allUsers});
      })
        .catch(err => {
          showError(
            NOT_FETCH_USERS() +
            err?.response?.data?.message ?? err.toString()
          );
        });

      //Getting VisiumManage Information 
      //Overall Manual Test Session Manangement Privilege required
      getVisiumManageIntegration()
        .then(res => {
          this.setState({
            visiumManageActive: res?.data[0]?.active,
            visiumManageInformation: res?.data[0]
          });
        })
        .catch(err => {
          showError(`${this.props.t("Could not fetch Visium Manage integration information")} ${err?.response?.data?.message ?? err.toString()} `);
        });
    }

    axios
      .get('/api/devices')
      .then(res => {
        const allDevicesDropDown = res.data.map(device => {
          return {
            key: device.id,
            value: device.deviceId,
            text: device.deviceId,
            content: (
              <Header
                size="tiny"
                icon={MOBILE_ICON}
                content={`${device.brand} ${device.deviceModel}`}
                subheader={device.deviceId}
              />
            )
          };
        });
        this.setState({allDevices: res.data, allDevicesDropDown});
      })
      .catch(err => {
        showError(
          NOT_FETCH_DEVICES() +
          err?.response?.data?.message ?? err.toString()
        );
      });
    //axios.get("/api/apps")
    getAppList().then(res => {
      const allAppsDropdown = [];
      res.data.forEach(app => {
        allAppsDropdown.push({
          key: app.id,
          value: app.id,
          text: `${app.appName} ${app.appVersion} (${app.appVersionCode})`,
          content: (
            <Header size="tiny">
              <img
                style={{
                  objectFit: 'contain',
                  background: 'white'
                }}
                loading="lazy"
                src={'data:image/png;base64,' + app.appIcon}
                alt="appImage"
              />
              <Header.Content>
                {app.appName}
                <Header.Subheader>{`${app.appVersion} (${app.appVersionCode})`}</Header.Subheader>
              </Header.Content>
            </Header>
          )
        });
      });
      this.setState({allAppsDropdown, allApps: res.data});
    })
      .catch(err => {
        showError(
         NOT_FETCH_APPLICATIONS_ERROR_MESSAGE() +
          err?.response?.data?.message ?? err.toString()
        );
      });


    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const manageRefreshToken = params.get('refreshToken');
    const manageAccessToken = params.get('accessToken');

    const manageAccessTokenLocalStorageCheck = (localStorage.getItem('manageAccessToken') !== null);
    const manageRefreshTokenLocalStorageCheck = (localStorage.getItem('manageRefreshToken') !== null);

    if(!manageAccessTokenLocalStorageCheck && !manageRefreshTokenLocalStorageCheck && manageRefreshToken && manageAccessToken){
      localStorage.setItem('manageRefreshToken', manageRefreshToken);
      localStorage.setItem('manageAccessToken', manageAccessToken);
    }
  }

  componentDidUpdate() {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('accessToken');
    const refreshToken = urlParams.get('refreshToken');
    
    if (accessToken && refreshToken) {

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }

  deleteSessionPrivilegeCheck = () => {
    return !(this.state.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.DELETE) ||
      this.state.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.DELETE_ANY) ||
      this.state.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT));
  }

  createSessionPrivilegeCheck = () => {
    return !(this.state.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.CREATE) ||
    this.state.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT))
  }

  generateActionMenu = props => {
    return (
      <span>
        <Dropdown
          icon={ELLIPSIS_HORIZONTAL_ICON}
          button
          className='icon'
        >
          <Dropdown.Menu style={{ zIndex: 90 }}>
            <Dropdown.Item
              onClick={() => {
                this.setState({
                  sessionDetailsModalVisibility: true,
                  testSessionField: props.row.original
                });
              }}
            >
              <Icon name={INFO_ICON} />{DETAILS_LABEL()}
            </Dropdown.Item>

            <Dropdown.Item
              disabled={
                !((props.row.original?.status.toLowerCase() ===
                  'timeout' ||
                  props.row.original?.status.toLowerCase() ===
                  'device_error') &&
                  moment(props.row.original?.endDate).isAfter(
                    moment().subtract(20, 'minutes')
                  )) || username !== props.row.original.username
              }
              onClick={() => {
                this.props.history.push({
                  pathname: `/device-manage`,
                  search: `?sessionId=${props.row.original.id}&device_1=${props.row.original.udId}`,
                  state: {
                    testSessionField: props.row.original,
                    isRecordingVideo:
                      this.state.testSessionField
                        ?.videoRecord ?? false,
                    isRecordingLogs:
                      this.state.testSessionField
                        ?.deviceLog ?? false,
                    isRecordingTraffic:
                      this.state.testSessionField
                        ?.trafficRecord ?? false
                  }
                });
              }}
            >
              <Icon name={MOBILE_ALTERNATE_ICON} color='blue' />{RECONNECT_BUTTON()}
            </Dropdown.Item>

            <Dropdown.Item
              disabled={this.deleteSessionPrivilegeCheck()}
              onClick={() =>
                this.setState({
                  confirmVisibility: true,
                  testSessionId: props.row.original.id
                })
              }
            >
              <Icon name={X_ICON} color='red' />{DELETE_BUTTON()}
            </Dropdown.Item>

            {
              this.state.visiumManageActive &&
              (<Dropdown.Item
                onClick={() => {
                  this.handleDefectPopup(props.row.original.id);
                }}
              >
                <Icon name={BUG_ICON} color='green' />{DEFECT_BUTTON()}
              </Dropdown.Item>)
            }
          </Dropdown.Menu>
        </Dropdown>
      </span>
    )
  }

  loadData = () => {
    this.setState({loading: true});
    let params = this.state.allParams;
    params.type = 'eq:' + this.state.sessionType;
    Object.keys(params).forEach(key => {
      if (typeof params[key] === 'string') {
        if (
          params[key] === '' ||
          params[key].slice(-1) === ':' ||
          params[key].includes('Invalid date')
        ) {
          delete params[key];
        }
      }
    });
    params = stringify(params);

    //axios.get(this.state.testSessionUrl + "/all?" + params)
    getTestSessionList(params).then(res => {
      this.setState({
        testSessions: res.data.content,
        pageSize: res.data.size,
        totalPages: res.data.totalPages,
        page: res.data.pageable?.pageNumber,
        loading: false
      });
    })
      .catch(err => {
        showError(
          manualTestSessionMessages().NOT_FETCH_MANUAL_TEST_SESSION +
          this.props.t(err?.response?.data?.message ?? err.toString())
        );
        this.setState({loading: false});
      });
  };

  handleDropdown = (_e, {value, name}) => {
    if (name === 'appId') {
      this.setState(
        prevState => ({
          [name]: value,
          allParams: {
            ...prevState.allParams,
            [name]: value
          }
        }),
        () => this.loadData()
      );
    } else {
      this.setState(
        prevState => ({
          [name]: value,
          allParams: {
            ...prevState.allParams,
            [name]: 'eq:' + value
          }
        }),
        () => this.loadData()
      );
    }
  };

  handleDropdownModal = (_e, {value, name}) => {
    if (name === 'udId') {
      if (value) {
        const deviceOS = this.state.allDevices.filter(
          device => device.deviceId === value
        )[0]?.os;
        const allAppsDropdown = [];
        this.state.allApps.forEach(app => {
          const appType = app.fileName.substr(
            app.fileName.length - 3
          );
          if (
            deviceOS !== null &&
            appType !== null &&
            ((deviceOS.toLowerCase() === 'android' &&
              appType.toLowerCase() === 'apk') ||
              (deviceOS.toLowerCase() === 'ios' &&
                appType.toLowerCase() === 'ipa'))
          ) {
            allAppsDropdown.push({
              key: app.id,
              value: app.id,
              text: `${app.appName} ${app.appVersion} (${app.appVersionCode})`,
              content: (
                <Header size="tiny">
                  <img
                    style={{
                      objectFit: 'contain',
                      background: 'white'
                    }}
                    loading="lazy"
                    src={
                      'data:image/png;base64,' +
                      app.appIcon
                    }
                    alt="appImage"
                  />
                  <Header.Content>
                    {`${app.appName}`}
                    <Header.Subheader>{`${app.appVersion} (${app.appVersionCode})`}</Header.Subheader>
                  </Header.Content>
                </Header>
              )
            });
          }
        });
        this.setState({allAppsDropdown});
      } else {
        this.setState(prevState => ({
          testSessionField: {
            ...prevState.testSessionField,
            appId: ''
          }
        }));
      }
    }
    this.setState(prevState => ({
      testSessionField: {
        ...prevState.testSessionField,
        [name]: value
      }
    }));
  };

  handleStartSessionModalOpen = () => {
    this.prepareFreeDevices();
    this.setState({
      sessionStartModalVisibility: true
    });
  };

  handleStartSessionModalClose = () => {
    this.setState({
      sessionStartModalVisibility: false,
      testSessionField: {}
    });
  };

  handleSessionDetailsModalClose = () => {
    this.setState({
      sessionDetailsModalVisibility: false,
      testSessionField: {}
    });
  };

  onHandleChange = (_e, {value, name, checked}) => {
    this.setState(prevState => ({
      testSessionField: {
        ...prevState.testSessionField,
        [name]: value
      }
    }));
    if (checked !== undefined) {
      this.setState(prevState => ({
        testSessionField: {
          ...prevState.testSessionField,
          [name]: checked
        }
      }));
    }
  };

  onPaginationChange = (pageIndex, pageSize) => {
    this.setState(
      prevState => ({
        allParams: {
          ...prevState.allParams,
          page: pageIndex,
          size: pageSize
        }
      }),
      () => this.loadData()
    );
  };

  onSortChange = newSorted => {
    if (newSorted.length > 0) {
      const columnName = newSorted[0].id;
      const orderBy = newSorted[0].desc ? 'DESC' : 'ASC';
      this.setState(
        prevState => ({
          allParams: {
            ...prevState.allParams,
            sort: `${columnName},${orderBy}`
          }
        }),
        () => this.loadData()
      );
    }
  };

  onChangeStartDate = date => {
    this.setState({startDate: date}, () => {
      date = moment(date).format('YYYY-MM-DDTHH:mm:ss');
      this.setState(
        prevState => ({
          allParams: {
            ...prevState.allParams,
            startDate: 'gte:' + date
          }
        }),
        () => this.loadData()
      );
    });
  };

  onChangeEndDate = date => {
    this.setState({endDate: date}, () => {
      date = moment(date).format('YYYY-MM-DDTHH:mm:ss');
      this.setState(
        prevState => ({
          allParams: {
            ...prevState.allParams,
            endDate: 'lte:' + date
          }
        }),
        () => this.loadData()
      );
    });
  };

  onChangeSearchKeyword = (_e, {value, name}) => {
    this.setState(
      prevState => ({
        sessionName: value,
        allParams: {
          ...prevState.allParams,
          [name]: 'like:' + value
        }
      }),
      () => this.loadData()
    );
  };

  prepareFreeDevices = () => {
    const freeDevices = [];
    axios
      .get('/api/devices')
      .then(res => {
        res.data.forEach(device => {
          if (
            device.deviceStatus === 0 &&
            device.status.Automation === false &&
            device.status.Manual === false &&
            device.status.Development === false
          ) {
            freeDevices.push({
              key: device.id,
              value: device.deviceId,
              text: device.deviceId,
              content: (
                <Header
                  size="tiny"
                  icon={MOBILE_ICON}
                  content={`${device.brand} ${device.deviceModel}`}
                  subheader={device.deviceId}
                />
              )
            });
          }
        });
      })
      .catch(err => {
        showError(
          NOT_FETCH_DEVICES() +
          err?.response?.data?.message ?? err.toString()
        );
      });
    this.setState({freeDevices});
  };

  onClickStartSession = () => {
    const {testSessionField, sessionType} = this.state;
    testSessionField.type = sessionType;
    this.setState({loadingStartSessionModal: true});
    startTestSession(testSessionField).then(res => {
      this.props.history.push({
        pathname: `/device-manage`,
        search: `?sessionId=${res.data.id}&device_1=${res.data.udId}`,
        state: {
          isRecordingVideo:
            this.state.testSessionField?.videoRecord ?? false,
          isRecordingLogs:
            this.state.testSessionField?.deviceLog ?? false,
          isRecordingTraffic:
            this.state.testSessionField?.trafficRecord ?? false
        }
      });
    })
      .catch(err => {
        this.setState({loadingStartSessionModal: false});
        showError(
          `${manualTestSessionMessages().NOT_START_MANUAL_TEST_SESSION}: ${
            this.props.t(err?.response?.data?.message ?? err.toString())
          }`
        );
      });
  };

  deleteSession = () => {
    //axios.delete(this.state.testSessionUrl + "/" + this.state.testSessionId)
    deleteTestSession(this.state.testSessionId).then(() => {
      toast.success(SUCCESS_MESSAGE(), {
        autoClose: 5000,
        closeOnClick: false,
        draggable: false,
        transition: Flip
      });
      this.loadData();
    })
      .catch(err => {
        showError(
          `${manualTestSessionMessages().NOT_DELETE_TEST_SESSION}: ${
            this.props.t(err?.response?.data?.message ?? err.toString())
          }`
        );
      });
    this.setState({confirmVisibility: false, testSessionId: -1});
  };

  downloadFile = file => {
    const { testSessionField } = this.state;

    let fileParam = null;
    if (file !== undefined) {
      fileParam = `?file=${file}`;
    }

    downloadReportFile(testSessionField.id, fileParam).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      const fileAttribute = file ?? `${testSessionField.id}.zip`;
      link.href = url;
      link.setAttribute(
        'download',
        fileAttribute
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
      .catch(err => {
        showError(
          FAILED_ERROR_MESSAGE() + ': ' + 
          this.props.t(err?.response?.data?.message ?? err.toString())
        );
      });
  };

  deleteFile = file => {
    const {testSessionField} = this.state;
    //axios.delete(`/api/test-session/${testSessionField.id}/delete-file?file=` +file)
    deleteReportFile(testSessionField.id, file).then(res => {
      this.setState({testSessionField: res.data});
      toast.success(SUCCESS_MESSAGE(), {
        delay: 1000,
        transition: Flip
      });
      this.loadData();
    })
      .catch(err => {
        showError(FAILED_ERROR_MESSAGE() + ': ' + this.props.t(err?.response?.data?.message ?? err.toString()));
      });
  };

  handleDefectPopup = id => {
    const tsid = id;

    this.setState({defectModalStatus: true, defectSessionId: `${tsid}`});

    getSingleUseToken(tsid)
      .then(res => {
        this.setState({singleUseToken: res.data})
      })
      .catch(err => {
        showError(err);
      });

    getTestSessionInfo(tsid).then(res => {
      const data = res.data;
      this.setState({summary: `New Defect with ${data.deviceBrand.toUpperCase()} ${data.deviceModel} @ ${moment(data.startDate)}`});
      this.setState({description: `${data.deviceBrand.toUpperCase()} ${data.deviceOs} (OS version: ${data.deviceOsVersion}) - ${data.appName}(${data.appVersion}) application used between ${moment(data.startDate)} ${moment(data.endDate)}`});
    }).catch(err => {
      showError(FAILED_ERROR_MESSAGE() + ': ' + this.props.t(err?.response?.data?.message ?? err.toString()));
    });

    if (validateUrl(this.state.visiumManageRedirectLink)) {
      window.location.replace(`${this.state.visiumManageRedirectLink}`);
    }

    getIntegrationDefects(tsid)
      .then(res => {
        this.setState({defectsByTsId: res.data?.content});
      }).catch(err => {
        showError(`${NOT_FETCH_DEFECTS()}: ${err?.response?.data}`);
      });

    if(this.props.wsClient){
      const id = uniqueId();
      this.setState({subscriptionId: id});
      this.props.wsClient.subscribe(`/topic/test-session-defects/${tsid}`, message =>{
        const msgObj = JSON.parse(message?.body);

        const defectFromWs = {
          'vmDefectId': msgObj?.vmDefectId,
          'vmProjectId': msgObj?.vmProjectId,
          'vmDefectCode': msgObj?.vmDefectCode,
          'vmDefectSummary': msgObj?.vmDefectSummary,
          'tsid': this.state.defectSessionId
        }
            
        this.setState({defectsByTsId: [...this.state.defectsByTsId, defectFromWs]})
      },{ 'AccessToken': localStorage.getItem('accessToken'), id})
    }
  }

  openDefectLink = () => {
    const encodedVisiumData = {
      'summary': this.state.summary,
      'description': this.state.description,
      'visiumManageInformation': this.state.visiumManageInformation
    }
    const encodedIntegrationData = Base64.encode(JSON.stringify(encodedVisiumData));

    let farmUrl;
    const { hostname, port, protocol, host } = window.location;
    if (host.includes('127.0.0.1:') || host.includes('localhost:') || process.env.NODE_ENV === 'development') {
      farmUrl = proxy;
    } else {
      farmUrl = `${protocol}//${hostname}:${port}`;
    }

    let defectUrl = `${this.state.visiumManageInformation.url}/3rd/defect`;
    defectUrl = `${defectUrl}?vftsid=${this.state.defectSessionId}&token=${this.state.singleUseToken.token}&data=${encodedIntegrationData}&url=${farmUrl}`;

    if (validateUrl(defectUrl)) {
      window.open(defectUrl, 'Integration Defect Creation', `height=${window.screen.availHeight}`, `width=${window.screen.availWidth}`);
    }
  }

  onHideManageTestRunBar = () => this.setState({ testRunModal: false });

  onShowManageTestRunBar = () => {
    this.prepareFreeDevices();
    this.setState({ testRunModal: true })
  }

  openTestRunRedirect = async () => {
    this.setState({testRunModal: true})

    if(localStorage.getItem('manageRefreshToken')){
      //If ManageRefreshToken exist send the refresh request !

      await manageRefreshRequest().then(res => {
        localStorage.setItem('manageAccessToken', res.data.accessToken);
        localStorage.setItem('manageRefreshToken', res.data.refreshToken);
      }).catch(err => showError(`${err}: ${manualTestSessionModalMessages().TOKENS_COULD_NOT_RESTORED}`));

      await axios.get('/manage/api/projects', {headers: {
        Authorization: `Bearer ${localStorage.getItem('manageAccessToken')}`
      }}).then(res => {
        let projectList = [];

        res.data.map(project => {
          projectList.push(project);
        })

        this.setState({manageProjects: projectList})
      }).catch(err => showError(`${err}: ${manualTestSessionModalMessages().PROJECTS_COULD_NOT_FETCHED}`));
    }
  }

  openTestSessionWithTestRun = () => {
    this.setState({sessionStartModalVisibility: true});
  }

  closeTestSessionWithTestRun = () => {
    this.setState({sessionStartModalVisibility: false});
  }

  render() {
    const defectModalColumns = [
      {
        header: () => <div>{DEFECT_CODE()}</div>,
        accessorKey: 'vmDefectCode',
        id: 'vmDefectCode',
        cell: info => <span>{info.getValue()}</span>
      },
      {
        header: () => <div>{PROJECT_SUMMARY()}</div>,
        accessorKey: 'vmDefectSummary',
        id: 'vmDefectSummary',
        cell: info => <span>{info.getValue()}</span>
      },
      {
        header: () => <div>{DEFECT_URL_HEADER()}</div>,
        accessorKey: 'defectUrl',
        id: 'defectUrl',
        cell: info => {
          const defectLink = `${this.state?.visiumManageInformation?.url}/defects?projectId=${info.row?.original?.vmProjectId}&defectId=${info.row?.original?.vmDefectId}`;

          return(
            <div>
              {this.state.defectsByTsId ? <Button onClick={()=> window.open(defectLink)}>{GO_TO_DEFECT_BUTTON()}</Button> : <></>}
            </div>
          )
        }
      }
    ]

    return (
      <div className='main-container'>
        <div className="main-left-container">
          <h1>{TEST_SESSIONS_HEADER()}</h1>
          <div className="main-left-content">
            <Form>
              <Form.Field>
                <label>{SESSION_NAME_HEADER()}</label>
                <Input
                  icon="search"
                  name="name"
                  placeholder={SEARCH_SESSION_NAME_PLACEHOLDER()}
                  value={this.state.sessionName}
                  onChange={this.onChangeSearchKeyword}
                />
              </Form.Field>
              {this.state.privileges.includes('39') && (
                <Form.Field>
                  <label>{USERS_HEADER()}</label>
                  <Dropdown
                    style={{wordBreak: 'break-all'}}
                    fluid
                    search
                    selection
                    clearable
                    id="searchUser"
                    placeholder={SEARCH_USER_PLACEHOLDER()}
                    name={'username'}
                    options={this.state.allUsers}
                    value={this.state.username}
                    onChange={this.handleDropdown}
                  />
                </Form.Field>
              )}
              <Form.Field>
                <label>{DEVICE_HEADER()}</label>
                <Dropdown
                  style={{wordBreak: 'break-all'}}
                  fluid
                  selection
                  search
                  clearable
                  id="searchDevice"
                  name={'udId'}
                  placeholder={SEARCH_ID_PLACEHOLDER()}
                  options={this.state.allDevicesDropDown}
                  value={this.state.udId}
                  onChange={this.handleDropdown}
                />
              </Form.Field>

              <Form.Field>
                <label>{APPLICATION_HEADER()}</label>
                <Dropdown
                  style={{wordBreak: 'break-all'}}
                  fluid
                  selection
                  search
                  clearable
                  id="searchApplication"
                  name={'appId'}
                  placeholder={SEARCH_APPLICATION_PLACEHOLDER()}
                  options={this.state.allAppsDropdown}
                  value={this.state.appId}
                  onChange={this.handleDropdown}
                />
              </Form.Field>

              <Form.Field>
                <label>{START_DATE_LABEL()}</label>
                <DatePicker
                  id="startDate"
                  selected={this.state.startDate}
                  onChange={this.onChangeStartDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption={TIME_LABEL()}
                  locale={this.props.i18n.language}
                />
              </Form.Field>
              <Form.Field>
                <label>{END_DATE_LABEL()}</label>
                <DatePicker
                  id="endDate"
                  selected={this.state.endDate}
                  onChange={this.onChangeEndDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption={TIME_LABEL()}
                  locale={this.props.i18n.language}
                />
              </Form.Field>
            </Form>
            <Button
                  style={{marginTop: 10}}
                  fluid
                  id="resetFilter"
                  onClick={() => {
                    this.setState(
                      {
                        startDate: null,
                        endDate: null,
                        udId: '',
                        username: '',
                        appId: '',
                        sessionName: '',
                        allParams: {
                          page: this.state.page,
                          size: this.state.pageSize
                        }
                      },
                      () => {
                        this.loadData();
                      }
                    );
                  }}
                >
                  {RESET_TABLE_BUTTON()}
                </Button>
          </div>
        </div>
        <div id='test-sessions-container' className='main-right-container'>
          <div className='main-right-header'>
            <h2>{MANUAL_TEST_SESSION_HEADER()}</h2>
            <div>
              <Button
                basic
                icon
                onClick={this.handleStartSessionModalOpen}
                disabled={this.createSessionPrivilegeCheck()}
              >
                {' '}
                <Icon name={MOBILE_ALTERNATE_ICON}/>{' '}
                {manualTestSessionMessages().START_NEW_SESSION_BUTTON}
              </Button>

              {
                this.state.visiumManageActive &&
              <Button
                basic
                icon
                onClick={this.openTestRunRedirect}
              >
                <div>
                <Icon id='manage-folder-icon' name={FOLDER_ICON}/>
                {integrationMessages().MANAGE_TEST_RUNS}
                </div>
              </Button>
              }
            </div>
            
          </div>
          <ReactTableV8
            data={this.state.testSessions}
            columns={this.columns}

            //Loading Warning
            loadingProp={this.state.loading}

            //Server-side sorting
            manualSortingProp={true}
            onSortedChangeProp={newSorted => this.onSortChange(newSorted)}

            //BELOW: Backend-side controlled manual pagination parameters---
            pageIndexProp={this.state.page}
            pageSizeProp={this.state.pageSize}
            totalPagesProp={this.state.totalPages}
            onPaginationChangeProp={this.onPaginationChange}
            manualPaginationProp={true}
          />
        </div>

        <ManualTestSessionModal
          onHandleChange={this.onHandleChange}
          handleModalClose={this.handleStartSessionModalClose}
          handleDropdown={this.handleDropdownModal}
          onClickStartSession={this.onClickStartSession}
          allDevices={this.state.freeDevices}
          allAppsDropdown={this.state.allAppsDropdown}
          testSessionField={this.state.testSessionField}
          modalVisibility={this.state.sessionStartModalVisibility}
          loading={this.state.loadingStartSessionModal}
        />
        <TestSessionDetailsModal
          testSessionFields={this.state.testSessionField}
          privileges={this.state.privileges}
          handleModalClose={this.handleSessionDetailsModalClose}
          modalVisibility={this.state.sessionDetailsModalVisibility}
          deleteFile={this.deleteFile}
          downloadFile={this.downloadFile}
        />
        <Confirm
          content={manualTestSessionMessages().DELETE_CONFIRM}
          open={this.state.confirmVisibility}
          size="mini"
          onCancel={() => {
            this.setState({
              confirmVisibility: false,
              testSessionId: -1
            });
          }}
          onClose={() => {
            this.setState({
              confirmVisibility: false,
              testSessionId: -1
            });
          }}
          onConfirm={this.deleteSession}
          confirmButton={CONFIRM_BUTTON()}
          cancelButton={CANCEL_BUTTON()}
        />

      {
        (this.state.visiumManageActive && this.state.privileges.includes(PrivilegeConstants.INTEGRATION)) ?
        <ManageSidebar
          //Props for Sidebar Panel
          manageProjects={this.state.manageProjects}
          visible={this.state.testRunModal}
          onHideSidebar={this.onHideManageTestRunBar}
          onShowSidebar={this.onShowManageTestRunBar}
          onOpenTestSession={this.openTestSessionWithTestRun}
          onCloseTestSession={this.closeTestSessionWithTestRun}

          //Props for Test Session Starter Modal
          onHandleChange={this.onHandleChange}
          handleDropdown={this.handleDropdownModal}
          onClickStartSession={this.onClickStartSession}
          allDevices={this.state.freeDevices}
          allAppsDropdown={this.state.allAppsDropdown}
          testSessionField={this.state.testSessionField}
          modalVisibility={this.state.sessionStartModalVisibility}
          loading={this.state.loadingStartSessionModal}
          sessionType={this.state.sessionType}
          prepareFreeDevices={this.prepareFreeDevices}
          visiumManageUrl={this.state?.visiumManageInformation?.url}
        /> : null
      }

        <div className='modal-defect'>
          <Modal
            closeIcon
            open={this.state.defectModalStatus}
            onClose={() => {
              if(this.props.wsClient){
                this.props.wsClient.unsubscribe(this.state.subscriptionId);
              }
              this.setState({defectModalStatus: false, defectsByTsId: []});
            }}
            onOpen={() => {
              this.setState({defectModalStatus: true})
            }}
          >
            <Header>
            <div className='defect-inspection-headerzone'>
                <Header icon={BUG_ICON} content={DEFECT_INSPECTION_HEADER()}/>
                <Button onClick={this.openDefectLink} className='float-right'>{OPEN_NEW_DEFECT_BUTTON()}</Button>
            </div>
            </Header>
            <Modal.Content>
              <Header style={{textAlign: 'center'}}>{PREVIOUSLY_OPENED_DEFECTS_HEADER()}</Header>
              <ReactTableV8
                columns={defectModalColumns}
                data={this.state.defectsByTsId}
              />
            </Modal.Content>
          </Modal>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    wsClient: state.stompClient.client
  };
};

export default withTranslation()(connect(mapStateToProps)(withRouter(ManualTestSessions)));
