import {Tab} from "semantic-ui-react";
import React from "react";
import ChartsUserRes from "./ChartsUSERRES";
import {useTranslation} from "react-i18next";
import {headerForReports} from "../../../../utils/Methods";
import { DAY_LABEL, MONTH_LABEL } from "../../../../utils/UIMessages";

const UserReservations = () => {
    const { t } = useTranslation();

    const panes = [
        {
            menuItem: DAY_LABEL(),
            render: () => <><ChartsUserRes type={"daily"}/></>,
        },
        {
            menuItem: MONTH_LABEL(),
            render: () => <><ChartsUserRes type={"monthly"}/></>,
        },
    ]
    return (
          <div className='main-container-report'>
            <div className="main-right-header">
                <h2>{t(headerForReports())}</h2>
            </div>

            <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
        </div>
    );
};


export default UserReservations;
