import React, { Component } from 'react';

class LocalDBPage extends Component {
    render() {
        return (
            <>
            </>
        );
    }
}

export default LocalDBPage;