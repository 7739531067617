import {Flip, toast} from "react-toastify";
import React from "react";
import {Label} from "semantic-ui-react";
import moment from "moment";
import packageJSON from '../../package.json'
import {getApplicationVersion} from '../api/apiCalls'
import { DOWNLOAD_BUTTON, SHOW_MORE_BUTTON } from "./UIMessages";

export const showError = (msg) => {
    const toastId = '_' + Math.random().toString(36).substr(2, 9)
    toast.error(errorContentRenderer(msg, toastId), {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        transition: Flip,
        toastId: toastId
    });
}

export const showInfo = (msg, time = 4000) => {
    toast.info(msg, {
        transition: Flip,
        autoClose: time
    });
}

export const showSuccess = (msg, time = 3000) => {
    toast.success(msg, {
        transition: Flip,
        autoClose: time
    });
}

const errorContentRenderer = (fullErrMessage, toastId) => {

    let shortErrMessage = null
    if (fullErrMessage.length > 250) {
        shortErrMessage = fullErrMessage.substring(0, 250) + "..."
    }

    const update = (msg) => {
        toast.update(toastId, {
            render: () => <LongErrContent msg={msg}/>
        })
    };

    const onDownloadErrorText = async () => {
        let backVersion;
        try {
            backVersion = (await getApplicationVersion())?.data?.version // api call is too risky but nice to have
        } catch (e) {
            backVersion = null
        }
        const text = [
            localStorage.getItem('username'), '\t', moment(new Date()).format('dddd, Do MMMM YYYY, HH:mm:ss').toString(),
            '\n \n',
            'Frontend Version: v' + packageJSON.version,
            '\n \n',
            'Backend Version: ' + backVersion,
            '\n \n',
            'Error Details:',
            '\n \n',
            fullErrMessage,
            '\n \n',
            'User Details:',
            '\n \n',
            JSON.stringify(JSON.parse(atob(localStorage.getItem('userDetails'))), null, 2)
        ]
        const element = document.createElement("a");
        const file = new Blob(text, {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "error.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        element.remove();
    }

    const LongErrContent = () => <>
        <span className='error-react-toastify'>
            <div style={{position: 'absolute', bottom: 3, right: 10}}>
                <Label onClick={() => onDownloadErrorText()} color='grey' circular> {DOWNLOAD_BUTTON()} </Label>
            </div>
            {fullErrMessage}
        </span>
    </>

    const ShortErrContent = (props) => <>
    <span className='error-react-toastify'>
            {props.msg} <br/>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Label onClick={() => update(props.msg)} color='grey' circular> {SHOW_MORE_BUTTON()} </Label>
                <Label onClick={() => onDownloadErrorText()} color='grey' circular> {DOWNLOAD_BUTTON()} </Label>
            </div>
        </span>
    </>

    return <>
        {shortErrMessage ? <ShortErrContent msg={shortErrMessage}/> : <LongErrContent msg={fullErrMessage}/>}
    </>
}